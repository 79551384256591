import { SET_PREVIEW_FORM_FIELD_VALUE, SET_PREVIEW_SELECTED_FORM } from '../action/simple-action';

const initialState = {
  selectedForm: null,
  fieldValue: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PREVIEW_SELECTED_FORM:
      return { ...state, selectedForm: action.form };
    case SET_PREVIEW_FORM_FIELD_VALUE:
      return { ...state, fieldValue: action.value };
    default: return state;
  }
};
