import { INITIAL_ORDER_BY_EVENT_OPERATOR_MAPPINGS, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableEventOperatorMapping } from '../../../helper';
import {
  enablingDisablingEventOperatorMapping, setAlertErrorMessage,
  setEventOperatorMappingSelectedOrderBy, setEventOperatorMappingSelectedPageSize,
} from '../simple-action';
import downloadEventOperatorMappingsAsync from './downloadEventOperatorMappingsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingEventOperatorMapping(true));

    const { token } = getState().authentication;
    const { data } = getState().eventOperatorMappings;
    const { tappedId } = getState().uiEventOperatorMapping;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableEventOperatorMapping(tappedId, status, token);

    dispatch(setEventOperatorMappingSelectedPageSize(20));
    dispatch(setEventOperatorMappingSelectedOrderBy(INITIAL_ORDER_BY_EVENT_OPERATOR_MAPPINGS));
    dispatch(downloadEventOperatorMappingsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingEventOperatorMapping(false));
  }
};
