import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { ImageBroken } from 'mdi-material-ui';
import { FunctionalPage, CustomFieldDialog } from '../../component';
import {
  FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_TEXT, FILTER_TYPE_SWITCH,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, RXFIELD_CREATED_DATE,
  RXFIELD_POINT_AMOUNT, RXFIELD_POINT_EXPIRED_POINT_ALREADY_PROCESSED,
  RXFIELD_POINT_IS_VOIDED, RXFIELD_POINT_LABEL, RXFIELD_POINT_MERCHANT_ID,
  RXFIELD_POINT_MERCHANT_NAME, RXFIELD_POINT_MERCHANT_REFERENCE_ID, RXFIELD_POINT_TAGS,
  RXFIELD_POINT_USER_NAME, RXFIELD_POINT_VALID_UNTIL, RXFIELD_POINT_VOID_NOTES,
  RXFIELD_POINT_VOID_REFERENCE_ID, RXFIELD_POINT_VOIDED_BY, RXFIELD_POINT_VOIDED_ON,
  RXFORM_POINT, RXSTATE_POINTS, RXSTATE_POINT_PAGE,
  STATUS_DISABLED, COLOR_DISABLED_ROW, COLOR_BACKGROUND,
  RXFIELD_POINT_USER_ID,
  approval,
  RXFIELD_VOID_POINT_DIALOG_REASON, RXFIELD_VOID_POINT_DIALOG_REFERENCE_ID,
  RXFIELD_VOID_POINT_DIALOG_SEND_NOTIFICATION, COLOR_CUSTOM_FIELD_DIALOG_ICON,
} from '../../constant';
import { renderReduxFormOutlinedTextField, renderReduxFormSimpleDropdownField } from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { SimpleDataShape } from '../../type';
import { rxformValidateVoidPointDialog } from '../../validation';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 500,
  },
  subtitle: {
    fontWeight: 200,
    textAlign: 'center',
  },
  icon: {
    fontSize: 100,
    color: COLOR_CUSTOM_FIELD_DIALOG_ICON,
  },
  titleWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    paddingLeft: 40,
    paddingRight: 40,
  },
}));

const renderDialogContent = () => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_POINT_USER_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderUserId}
          label={LocalizedString.pointPage.placeholderUserId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_USER_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderUserName}
          label={LocalizedString.pointPage.placeholderUserName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_MERCHANT_REFERENCE_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderMerchantReferenceID}
          label={LocalizedString.pointPage.placeholderMerchantReferenceID}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_LABEL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderLabel}
          label={LocalizedString.pointPage.placeholderLabel}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_TAGS}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderTags}
          label={LocalizedString.pointPage.placeholderTags}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_IS_VOIDED}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderIsVoided}
          label={LocalizedString.pointPage.placeholderIsVoided}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_VOID_REFERENCE_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderVoidReferenceId}
          label={LocalizedString.pointPage.placeholderVoidReferenceId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_VOIDED_BY}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderVoidedBy}
          label={LocalizedString.pointPage.placeholderVoidedBy}
          disabled
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_POINT_AMOUNT}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderAmount}
          label={LocalizedString.pointPage.placeholderAmount}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_MERCHANT_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderMerchantId}
          label={LocalizedString.pointPage.placeholderMerchantId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_MERCHANT_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderMerchantName}
          label={LocalizedString.pointPage.placeholderMerchantName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_VALID_UNTIL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderValidUntil}
          label={LocalizedString.pointPage.placeholderValidUntil}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_EXPIRED_POINT_ALREADY_PROCESSED}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderExpiredPointAlreadyProcessed}
          label={LocalizedString.pointPage.placeholderExpiredPointAlreadyProcessed}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_VOIDED_ON}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderVoidedOn}
          label={LocalizedString.pointPage.placeholderVoidedOn}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_VOID_NOTES}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderVoidNotes}
          label={LocalizedString.pointPage.placeholderVoidNotes}
          disabled
        />
      </Grid>
    </Grid>
  </Grid>
);

const PointPage = ({
  users, downloading, isVoidAllowed, loadingUser,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onCancelPressed, onChangePage, onChangePageSize, onChangeUserText, onRefresh,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
  voidingPoint, showVoidDialog, onCloseVoidModal, onVoidPointModalOKPressed,
  selectedPointName, selectedUser, selectedSendNotificationValue,
  onSendNotificationOptionSelected, onVoidPressed, tappedId,
}) => {
  const classes = useStyles();

  return (
    <div>
      <FunctionalPage
        data={RXSTATE_POINTS}
        uiPage={RXSTATE_POINT_PAGE}
        filterColumns={[
          {
            title: LocalizedString.pointPage.placeholderCreatedDate,
            field: RXFIELD_CREATED_DATE,
            type: FILTER_TYPE_DATE_RANGE,
            pickerMode: PICKER_MODE_DATE_TIME,
            format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
          },
          {
            title: LocalizedString.pointPage.placeholderValidUntil,
            field: RXFIELD_POINT_VALID_UNTIL,
            type: FILTER_TYPE_DATE_RANGE,
            pickerMode: PICKER_MODE_DATE_TIME,
            format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
          },
          {
            title: LocalizedString.pointPage.placeholderMerchantName,
            field: RXFIELD_POINT_MERCHANT_NAME,
            type: FILTER_TYPE_TEXT,
          },
          {
            title: LocalizedString.pointPage.placeholderMerchantReferenceID,
            field: RXFIELD_POINT_MERCHANT_REFERENCE_ID,
            type: FILTER_TYPE_TEXT,
          },
          {
            title: LocalizedString.pointPage.placeholderUserName,
            field: RXFIELD_POINT_USER_NAME,
            type: FILTER_TYPE_DROPDOWN,
            data: users,
            loading: loadingUser,
            onChangeFilterText: onChangeUserText,
            useDropdownValue: true,
          },
          {
            title: LocalizedString.pointPage.placeholderIsVoided,
            field: RXFIELD_POINT_IS_VOIDED,
            type: FILTER_TYPE_SWITCH,
          },
          {
            title: LocalizedString.pointPage.placeholderVoidReferenceId,
            field: RXFIELD_POINT_VOID_REFERENCE_ID,
            type: FILTER_TYPE_TEXT,
          },
          {
            title: LocalizedString.pointPage.placeholderExpiredPointAlreadyProcessed,
            field: RXFIELD_POINT_EXPIRED_POINT_ALREADY_PROCESSED,
            type: FILTER_TYPE_SWITCH,
          },
        ]}
        tableColumns={[
          {
            title: LocalizedString.pointPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
          },
          { title: LocalizedString.pointPage.labelUserName, field: 'user.fullName', sorting: !downloading },
          { title: LocalizedString.pointPage.labelAmount, field: 'amount', sorting: !downloading },
          { title: LocalizedString.pointPage.labelLabel, field: 'label', sorting: !downloading },
          { title: LocalizedString.pointPage.labelMerchantName, field: 'merchantName', sorting: !downloading },
          { title: LocalizedString.pointPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
        ]}
        moreMenus={[
          {
            caption: LocalizedString.pointPage.buttonCaptionVoid,
            disabled: !isVoidAllowed,
            onPress: () => onVoidPressed(tappedId),
          },
        ]}
        handleSubmit={handleSubmit}
        onAdvancedFilterPressed={onAdvancedFilterPressed}
        onAppear={onAppear}
        onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
        onCancelPressed={onCancelPressed}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onRefresh={onRefresh}
        onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
        onSearchBarTextChanged={onSearchBarTextChanged}
        onSortPressed={onSortPressed}
        onViewPressed={onViewPressed}
        title={LocalizedString.pointPage.title}
        useFullWidth
        disableCreate
        disableDelete
        disableEdit
        rowStyle={({ status, isVoided }) => ({
          backgroundColor: isVoided || status === STATUS_DISABLED
            ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
        })}
      >
        {renderDialogContent()}
      </FunctionalPage>
      <CustomFieldDialog
        loadingPositiveButton={voidingPoint}
        positiveButtonDisabled={voidingPoint}
        visibility={showVoidDialog}
        onCancelPressed={onCloseVoidModal}
        onClosePressed={onCloseVoidModal}
        onPositiveButtonPressed={onVoidPointModalOKPressed}
        positiveButtonCaption={LocalizedString.pointPage.buttonCaptionOK}
        title=""
        customDialog
        titleComponent={(
          <Typography variant="h4" className={classes.title}>
            {LocalizedString.pointPage.msgVoidPoint}
          </Typography>
        )}
        subtitleComponent={(
          <div className={classes.titleWrapper}>
            <Typography variant="h6" className={classes.subtitle}>
              {LocalizedString.pointPage.msgVoidPointDescription}
              <b>{selectedPointName}</b>
              {LocalizedString.pointPage.msgVoidPointFrom}
              <b>{selectedUser}</b>
            </Typography>
          </div>
        )}
        iconComponent={(<ImageBroken className={classes.icon} />)}
        validate={rxformValidateVoidPointDialog}
      >
        <Field
          name={RXFIELD_VOID_POINT_DIALOG_REASON}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderReason}
          label={LocalizedString.pointPage.placeholderReason}
          disabled={voidingPoint}
          required
          multiline
        />
        <Field
          name={RXFIELD_VOID_POINT_DIALOG_REFERENCE_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderReferenceId}
          label={LocalizedString.pointPage.placeholderReferenceId}
          disabled={voidingPoint}
          required
        />
        <Field
          name={RXFIELD_VOID_POINT_DIALOG_SEND_NOTIFICATION}
          component={renderReduxFormSimpleDropdownField}
          placeholder={LocalizedString.pointPage.placeholderSendNotification}
          label={LocalizedString.pointPage.placeholderSendNotification}
          disabled={voidingPoint}
          data={approval}
          value={selectedSendNotificationValue}
          onOptionSelected={onSendNotificationOptionSelected}
          onBlur={(e) => e.preventDefault()}
          required
        />
      </CustomFieldDialog>
    </div>
  );
};

export default reduxForm({
  form: RXFORM_POINT,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(PointPage);

PointPage.propTypes = {
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  selectedSendNotificationValue: SimpleDataShape,
  downloading: PropTypes.bool.isRequired,
  isVoidAllowed: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  voidingPoint: PropTypes.bool.isRequired,
  showVoidDialog: PropTypes.bool.isRequired,
  selectedPointName: PropTypes.string.isRequired,
  selectedUser: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onCloseVoidModal: PropTypes.func.isRequired,
  onVoidPointModalOKPressed: PropTypes.func.isRequired,
  onSendNotificationOptionSelected: PropTypes.func.isRequired,
  onVoidPressed: PropTypes.func.isRequired,
};

PointPage.defaultProps = {
  selectedSendNotificationValue: null,
};
