import { INITIAL_ORDER_BY_SESSION_REGISTRATIONS } from '../../constant';
import {
  ADDING_SESSION_REGISTRATION, APPROVING_SESSION_REGISTRATION,
  DOWNLOADING_DELETING_SESSION_REGISTRATION, DOWNLOADING_SESSION_REGISTRATIONS,
  REJECTING_SESSION_REGISTRATION, SAVING_SESSION_REGISTRATIONS,
  SET_SESSION_REGISTRATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_SESSION_REGISTRATION_SEARCH_TEXT,
  SET_SESSION_REGISTRATION_SELECTED_ORDER_BY, SET_SESSION_REGISTRATION_SELECTED_PAGE_SIZE,
  SET_SESSION_REGISTRATION_TAPPED_ID,
} from '../action/simple-action';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  approving: false,
  rejecting: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_SESSION_REGISTRATIONS,
  filterString: '',
  saving: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_SESSION_REGISTRATIONS:
      return { ...state, downloading: action.status };
    case ADDING_SESSION_REGISTRATION:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_SESSION_REGISTRATION:
      return { ...state, downloadingDeleting: action.status };
    case SET_SESSION_REGISTRATION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_SESSION_REGISTRATION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_SESSION_REGISTRATION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_SESSION_REGISTRATION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_SESSION_REGISTRATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case APPROVING_SESSION_REGISTRATION:
      return { ...state, approving: action.status };
    case REJECTING_SESSION_REGISTRATION:
      return { ...state, rejecting: action.status };
    case SAVING_SESSION_REGISTRATIONS:
      return { ...state, saving: action.status };
    default: return state;
  }
};
