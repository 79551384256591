import { reset } from 'redux-form';
import { PAGE_MODE_TABLE, RXFORM_DISCUSSION } from '../../../constant';
import { editDiscussion } from '../../../helper';
import {
  addingEditingDiscussion, clearDiscussions, setAlertErrorMessage,
  setDiscussionSelectedAllowDirectComment, setDiscussionSelectedAllowPublicRead,
  setDiscussionSelectedDisableComment, setFunctionalPageMode,
} from '../simple-action';
import downloadDiscussionsAsync from './downloadDiscussionsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(addingEditingDiscussion(true));

    const { token } = getState().authentication;
    const {
      tappedId, selectedAllowDirectComment, selectedAllowPublicRead, selectedDisableComment,
    } = getState().uiDiscussion;
    const { data } = getState().discussions;

    const {
      referenceId, name, description, tags,
    } = data[tappedId];
    const allowDirectUserComment = selectedAllowDirectComment
      ? selectedAllowDirectComment.value : false;
    const allowPublicRead = selectedAllowPublicRead ? selectedAllowPublicRead.value : false;
    const disableComment = selectedDisableComment ? selectedDisableComment.value : false;

    if (tappedId) {
      await editDiscussion(tappedId, allowDirectUserComment, allowPublicRead, disableComment,
        referenceId, name, description, tags, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    }

    dispatch(reset(RXFORM_DISCUSSION));
    dispatch(clearDiscussions());
    dispatch(downloadDiscussionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setDiscussionSelectedAllowDirectComment(''));
    dispatch(setDiscussionSelectedAllowPublicRead(''));
    dispatch(setDiscussionSelectedDisableComment(''));
  } finally {
    dispatch(addingEditingDiscussion(false));
  }
};
