import { reset } from 'redux-form';
import {
  addingEditingBranch, clearBranches, setAlertErrorMessage, setBranchSelectedCityId,
  setFunctionalPageMode, setBranchSelectedCompanyId, setBranchDuplicateMode,
} from '../simple-action';
import { addBranch, editBranch } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_BRANCH } from '../../../constant';
import downloadBranchesAsync from './downloadBranchesAsync';

export default (name, description, latitude, longitude, googleMapUrl, address,
  postalCode, phone, email, startWorkingHour, startWorkingHourMarginBeforeMinutes,
  startWorkingHourMarginAfterMinutes, endWorkingHour, endWorkingHourMarginBeforeMinutes,
  endWorkingHourMarginAfterMinutes, earliestClockInDriftTimeMinutes,
  autoClockOutAfterMinutes) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingBranch(true));

    const { token } = getState().authentication;
    const {
      marker, polygon, selectedCityId, tappedId, selectedCompanyId, duplicateMode,
    } = getState().uiBranch;
    const { data } = getState().branches;

    const found = data[tappedId];
    const city = selectedCityId || found.city.id;
    const company = selectedCompanyId || found.company.id;
    const area = polygon.length > 0 ? polygon
      .map((x) => ({ latitude: x.lat, longitude: x.lng, order: x.order })) : found.area;
    const lat = Object.prototype.hasOwnProperty.call(marker, 'lat') ? marker.lat : latitude;
    const lng = Object.prototype.hasOwnProperty.call(marker, 'lng') ? marker.lng : latitude;

    if (tappedId && !duplicateMode) {
      await editBranch(tappedId, name, description, lat, lng, googleMapUrl, address,
        postalCode, phone, email, city, startWorkingHour, startWorkingHourMarginBeforeMinutes,
        startWorkingHourMarginAfterMinutes, endWorkingHour, endWorkingHourMarginBeforeMinutes,
        endWorkingHourMarginAfterMinutes, area, earliestClockInDriftTimeMinutes,
        autoClockOutAfterMinutes, company, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addBranch(name, description, lat, lng, googleMapUrl, address, postalCode, phone, email,
        city, startWorkingHour, startWorkingHourMarginBeforeMinutes,
        startWorkingHourMarginAfterMinutes, endWorkingHour, endWorkingHourMarginBeforeMinutes,
        endWorkingHourMarginAfterMinutes, area, earliestClockInDriftTimeMinutes,
        autoClockOutAfterMinutes, company, token);
      if (duplicateMode) { dispatch(setBranchDuplicateMode(false)); }
    }

    dispatch(reset(RXFORM_BRANCH));
    dispatch(clearBranches());
    dispatch(downloadBranchesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setBranchSelectedCityId(''));
    dispatch(setBranchSelectedCompanyId(''));
  } finally {
    dispatch(addingEditingBranch(false));
  }
};
