import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, CircularProgress, Grid, IconButton, InputAdornment, Snackbar,
} from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FileCopyOutlined } from '@material-ui/icons';
import { Comment, FunctionalPage, SectionTitle } from '../../component';
import {
  ADDITIONAL_ITEM_TYPE_IMAGE, COLOR_PRIMARY, DATE_TIME_FORMAT_WITHOUT_PIPE,
  IMAGE_SOURCE_URI_PREFIX, PICKER_MODE_DATE_TIME, REST_BASE_URL, REST_URL_IMAGE_STORAGE,
  PAGE_MODE_TABLE,
  RXFIELD_SESSION_OPERATOR_SESSION_ADDITTIONAL_ITEMS,
  RXFIELD_SESSION_OPERATOR_SESSION_CAN_PAY_USING_POINT, RXFIELD_SESSION_OPERATOR_SESSION_CATEGORY,
  RXFIELD_SESSION_OPERATOR_SESSION_CLOCK_IN_QR_CODE,
  RXFIELD_SESSION_OPERATOR_SESSION_CLOCK_INSIDE_AREA,
  RXFIELD_SESSION_OPERATOR_SESSION_COMMENT_ACCESS, RXFIELD_SESSION_OPERATOR_SESSION_DESCRIPTION,
  RXFIELD_SESSION_OPERATOR_SESSION_DISCUSSION_ID,
  RXFIELD_SESSION_OPERATOR_SESSION_EARLIEST_CLOCK_IN_BEFORE_MINUTES,
  RXFIELD_SESSION_OPERATOR_SESSION_ENABLE_COMMENT, RXFIELD_SESSION_OPERATOR_SESSION_END_TIME,
  RXFIELD_SESSION_OPERATOR_SESSION_EVENT_NAME, RXFIELD_SESSION_OPERATOR_SESSION_FEATURED_ORDER,
  RXFIELD_SESSION_OPERATOR_SESSION_IS_FEATURED,
  RXFIELD_SESSION_OPERATOR_SESSION_MAX_CLOCK_IN_MINUTES,
  RXFIELD_SESSION_OPERATOR_SESSION_MEDIA, RXFIELD_SESSION_OPERATOR_SESSION_NAME,
  RXFIELD_SESSION_OPERATOR_SESSION_NEED_APPROVAL, RXFIELD_SESSION_OPERATOR_SESSION_NORMAL_PRICE,
  RXFIELD_SESSION_OPERATOR_SESSION_PRICE, RXFIELD_SESSION_OPERATOR_SESSION_QUOTA,
  RXFIELD_SESSION_OPERATOR_SESSION_REGISTRATION_REWARD_POINT,
  RXFIELD_SESSION_OPERATOR_SESSION_REGISTRATION_REWARD_TYPE,
  RXFIELD_SESSION_OPERATOR_SESSION_REGISTRATION_REWARD_VOUCHERS,
  RXFIELD_SESSION_OPERATOR_SESSION_ROOM_NAME, RXFIELD_SESSION_OPERATOR_SESSION_SHORT_DESCRIPTION,
  RXFIELD_SESSION_OPERATOR_SESSION_SPEAKERS, RXFIELD_SESSION_OPERATOR_SESSION_START_TIME,
  RXFIELD_SESSION_OPERATOR_SESSION_SURVEY_FORM_ID, RXFIELD_SESSION_OPERATOR_SESSION_TYPE,
  RXFIELD_SESSION_OPERATOR_SESSION_VISIBILITY_STATUS,
  RXFIELD_SESSION_OPERATOR_SESSION_WHO_CAN_REGISTER, RXFORM_SESSION_OPERATOR_SESSION,
  RXSTATE_SESSION_OPERATOR_SESSION_PAGE, RXSTATE_SESSION_OPERATOR_SESSIONS,
  SESSION_OPERATOR_SESSION_REWARD_TYPE_BOTH, SESSION_OPERATOR_SESSION_REWARD_TYPE_POINT,
  SESSION_OPERATOR_SESSION_REWARD_TYPE_VOUCHER, SESSION_OPERATOR_SESSION_TYPE_FREE_TIME,
  snackbarDuration, REST_BASE_URL_SESSION, RXFIELD_SESSION_OPERATOR_SESSION_BANNER,
} from '../../constant';
import {
  getAdditionalItemIcon, isAdditionalItemYoutubeUrlType, toMoment,
} from '../../helper';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import {
  renderReduxFormAutocompleteCheckboxField, renderReduxFormAutocompleteTableField,
  renderReduxFormDateTimePickerField, renderReduxFormOutlinedDropdownTextField,
  renderReduxFormEditableTableField, renderReduxFormOutlinedTextField,
  renderReduxFormRichTextEditorField,
  renderReduxFormImageInputField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape } from '../../type';

const renderDialogContent = (initialValues, copying, downloadingDeleting, onCopyPressed) => {
  const isRewardTypeBoth = initialValues
    .registrationRewardType === SESSION_OPERATOR_SESSION_REWARD_TYPE_BOTH;
  const isTypeFreetime = initialValues.type === SESSION_OPERATOR_SESSION_TYPE_FREE_TIME;

  return (
    <Grid>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderName}
              label={LocalizedString.sessionPage.placeholderName}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_SHORT_DESCRIPTION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderShortDescription}
              label={LocalizedString.sessionPage.placeholderShortDescription}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_TYPE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderType}
              label={LocalizedString.sessionPage.placeholderType}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_START_TIME}
              component={renderReduxFormDateTimePickerField}
              pickerMode={PICKER_MODE_DATE_TIME}
              placeholder={LocalizedString.sessionPage.placeholderStartTime}
              label={LocalizedString.sessionPage.placeholderStartTime}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_END_TIME}
              component={renderReduxFormDateTimePickerField}
              pickerMode={PICKER_MODE_DATE_TIME}
              placeholder={LocalizedString.sessionPage.placeholderEndTime}
              label={LocalizedString.sessionPage.placeholderEndTime}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_IS_FEATURED}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.sessionPage.placeholderFeatured}
              label={LocalizedString.sessionPage.placeholderFeatured}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_FEATURED_ORDER}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderFeaturedOrder}
              label={LocalizedString.sessionPage.placeholderFeaturedOrder}
              disabled
              hidden={initialValues.isFeatured?.value === false}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_VISIBILITY_STATUS}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderVisibilityStatus}
              label={LocalizedString.sessionPage.placeholderVisibilityStatus}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_REGISTRATION_REWARD_TYPE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderRegistrationRewardType}
              label={LocalizedString.sessionPage.placeholderRegistrationRewardType}
              disabled
              hidden={isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_REGISTRATION_REWARD_POINT}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderRegistrationRewardPoint}
              label={LocalizedString.sessionPage.placeholderRegistrationRewardPoint}
              disabled
              type="number"
              hidden={initialValues.registrationRewardType
                !== SESSION_OPERATOR_SESSION_REWARD_TYPE_POINT && !isRewardTypeBoth}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_REGISTRATION_REWARD_VOUCHERS}
              component={renderReduxFormAutocompleteCheckboxField}
              placeholder={LocalizedString.sessionPage.placeholderRegistrationRewardVouchers}
              label={LocalizedString.sessionPage.placeholderRegistrationRewardVouchers}
              disabled
              value={initialValues.registrationRewardVouchers}
              hidden={initialValues.registrationRewardType
                !== SESSION_OPERATOR_SESSION_REWARD_TYPE_VOUCHER && !isRewardTypeBoth}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_SURVEY_FORM_ID}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.sessionPage.placeholderSurveyFormId}
              label={LocalizedString.sessionPage.placeholderSurveyFormId}
              disabled
              hidden={isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_ENABLE_COMMENT}
              component={renderReduxFormOutlinedDropdownTextField}
              label={LocalizedString.sessionPage.placeholderEnableComment}
              placeholder={LocalizedString.sessionPage.placeholderEnableComment}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_COMMENT_ACCESS}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.sessionPage.placeholderCommentAccess}
              placeholder={LocalizedString.sessionPage.placeholderCommentAccess}
              disabled
              hidden={initialValues.enableComment?.value === false}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_DISCUSSION_ID}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.sessionPage.placeholderDiscussionId}
              placeholder={LocalizedString.sessionPage.placeholderDiscussionId}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_CLOCK_IN_QR_CODE}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.sessionPage.placeholderClockInQrCode}
              placeholder={LocalizedString.sessionPage.placeholderClockInQrCode}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => onCopyPressed(copying)} edge="end">
                      <CopyToClipboard text={initialValues.clockInQrCode}>
                        <FileCopyOutlined style={{ fill: COLOR_PRIMARY }} />
                      </CopyToClipboard>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_EVENT_NAME}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.sessionPage.placeholderEvent}
              label={LocalizedString.sessionPage.placeholderEvent}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_ROOM_NAME}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.sessionPage.placeholderRoom}
              label={LocalizedString.sessionPage.placeholderRoom}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_CATEGORY}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.sessionPage.placeholderCategory}
              label={LocalizedString.sessionPage.placeholderCategory}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_CLOCK_INSIDE_AREA}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.sessionPage.placeholderClockInsideArea}
              label={LocalizedString.sessionPage.placeholderClockInsideArea}
              disabled
              hidden={isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_EARLIEST_CLOCK_IN_BEFORE_MINUTES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderEarliestClockIn}
              label={LocalizedString.sessionPage.placeholderEarliestClockIn}
              disabled
              type="number"
              hidden={isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_MAX_CLOCK_IN_MINUTES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderMaxClockIn}
              label={LocalizedString.sessionPage.placeholderMaxClockIn}
              disabled
              type="number"
              hidden={isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_WHO_CAN_REGISTER}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderWhoCanRegister}
              label={LocalizedString.sessionPage.placeholderWhoCanRegister}
              disabled
              hidden={isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_NEED_APPROVAL}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.sessionPage.placeholderNeedsApproval}
              label={LocalizedString.sessionPage.placeholderNeedsApproval}
              disabled
              hidden={isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_QUOTA}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderQuota}
              label={LocalizedString.sessionPage.placeholderQuota}
              disabled
              type="number"
              hidden={isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_NORMAL_PRICE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderNormalPrice}
              label={LocalizedString.sessionPage.placeholderNormalPrice}
              disabled
              type="number"
              hidden={isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_PRICE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderPrice}
              label={LocalizedString.sessionPage.placeholderPrice}
              disabled
              type="number"
              hidden={isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_CAN_PAY_USING_POINT}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.sessionPage.placeholderCanPayUsingPoint}
              label={LocalizedString.sessionPage.placeholderCanPayUsingPoint}
              disabled
              hidden={isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_SESSION_BANNER}
              component={renderReduxFormImageInputField}
              label={LocalizedString.sessionPage.placeholderBanner}
              disabled
              loading={downloadingDeleting}
              defaultValue={initialValues.banner}
              useFullWidthImage
              fullImageWidth="100%"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={12}>
        <Field
          name={RXFIELD_SESSION_OPERATOR_SESSION_DESCRIPTION}
          component={renderReduxFormRichTextEditorField}
          label={LocalizedString.sessionPage.placeholderDescription}
          disabled
          loading={downloadingDeleting}
        />
      </Grid>

      <SectionTitle title={LocalizedString.sessionPage.labelMedia} />
      {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_SESSION_OPERATOR_SESSION_MEDIA}
            component={renderReduxFormEditableTableField}
            label={LocalizedString.sessionPage.labelMedia}
            disabled
            disableToolbar
            defaultValue={initialValues.media}
            tableColumns={[
              {
                title: LocalizedString.sessionPage.labelOrder,
                field: 'order',
                sorting: false,
              },
              {
                title: LocalizedString.sessionPage.labelYoutube,
                field: 'link',
                sorting: false,
                render: (prop) => {
                  if (prop) {
                    if (prop.path) {
                      if (prop.type === 'Youtube' && !prop.tableData.editing) {
                        const path = prop.path.includes(`${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}`) ? prop.path.slice(68) : prop.path;
                        return path;
                      }
                    }
                    if (prop.link) {
                      return prop.link.path;
                    }
                    return null;
                  }
                  return null;
                },
              },
              {
                title: LocalizedString.sessionPage.labelImage,
                field: 'content',
                sorting: false,
                render: (prop) => {
                  if (prop) {
                    if (prop.path) {
                      if (prop.type === 'Image' && !prop.tableData.editing) {
                        return (<Avatar variant="square" src={prop.path} />);
                      }
                    }
                    if (prop.content) {
                      return (
                        <Avatar
                          variant="square"
                          src={IMAGE_SOURCE_URI_PREFIX.concat(prop.content.path)}
                        />
                      );
                    }
                    return null;
                  }
                  return null;
                },
              },
            ]}
          />
        </Grid>
      )}

      <SectionTitle title={LocalizedString.sessionPage.labelSpeakers} />
      {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_SESSION_OPERATOR_SESSION_SPEAKERS}
            component={renderReduxFormAutocompleteTableField}
            label={LocalizedString.sessionPage.placeholderSpeaker}
            defaultValue={initialValues.speakers}
            disabled
            disableAdd
            optionPlaceholder={LocalizedString.sessionPage.placeholderSpeaker}
          />
        </Grid>
      )}

      <SectionTitle title={LocalizedString.sessionPage.labelAdditionalItems} />
      {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_SESSION_OPERATOR_SESSION_ADDITTIONAL_ITEMS}
            component={renderReduxFormEditableTableField}
            label={LocalizedString.sessionPage.labelAdditionalItems}
            disabled
            defaultValue={initialValues.additionalItems}
            disableToolbar
            tableColumns={[
              {
                title: LocalizedString.sessionPage.labelOrder, field: 'order', sorting: false, type: 'numeric',
              },
              { title: LocalizedString.sessionPage.labelLabel, field: 'label', sorting: false },
              { title: LocalizedString.sessionPage.labelType, field: 'type', sorting: false },
              { title: LocalizedString.sessionPage.labelViewAccess, field: 'viewAccess', sorting: false },
              { title: LocalizedString.sessionPage.labelVisibilityTime, field: 'visibilityTime', sorting: false },
              {
                title: LocalizedString.sessionPage.labelContent,
                field: 'content',
                sorting: false,
                render: (prop) => {
                  if (prop) {
                    if (!isAdditionalItemYoutubeUrlType(prop)) {
                      if (prop.type === ADDITIONAL_ITEM_TYPE_IMAGE) {
                        if (prop.path) {
                          return (<Avatar variant="square" src={prop.path} />);
                        }
                        if (prop.content) {
                          return (
                            <Avatar
                              variant="square"
                              src={IMAGE_SOURCE_URI_PREFIX.concat(prop.content.content)}
                            />
                          );
                        }
                      } else {
                        const { icon: Icon, color } = getAdditionalItemIcon(prop);
                        return (<Icon style={{ color, width: 40, height: 40 }} />);
                      }
                    }
                    return null;
                  }
                  return null;
                },
              },
              {
                title: LocalizedString.sessionPage.labelUrl,
                field: 'url',
                sorting: false,
                render: (prop) => {
                  if (prop) {
                    if (isAdditionalItemYoutubeUrlType(prop)) {
                      return prop?.url || prop?.path || null;
                    }
                    return null;
                  }
                  return null;
                },
              },
            ]}
          />
        </Grid>
      )}

      <Snackbar
        open={copying}
        autoHideDuration={snackbarDuration}
        message={LocalizedString.sessionPage.labelCopied}
        onClose={() => onCopyPressed(copying)}
      />
    </Grid>
  );
};

const SessionOperatorSessionPage = ({
  initialValues, copying, downloading, downloadingDeleting,
  onAppear, onChangePage, onChangePageSize, onCopyPressed, onRefresh, onSearchBarTextChanged,
  onSortPressed, onViewPressed, pageMode, discusId,
}) => (
  <FunctionalPage
    data={RXSTATE_SESSION_OPERATOR_SESSIONS}
    uiPage={RXSTATE_SESSION_OPERATOR_SESSION_PAGE}
    tableColumns={[
      {
        title: GlobalLocalizedString.common.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.sessionPage.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.sessionPage.labelEventName, field: 'event.name', sorting: !downloading },
      {
        title: LocalizedString.sessionPage.labelStartTime,
        field: 'startTime',
        sorting: !downloading,
        render: ({ startTime }) => (startTime
          ? toMoment(startTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      {
        title: LocalizedString.sessionPage.labelEndTime,
        field: 'endTime',
        sorting: !downloading,
        render: ({ endTime }) => (endTime
          ? toMoment(endTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      { title: LocalizedString.sessionPage.labelRoom, field: 'room.name', sorting: !downloading },
      { title: LocalizedString.sessionPage.labelQuota, field: 'quota', sorting: !downloading },
      {
        title: LocalizedString.sessionPage.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.sessionPage.title}
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
  >
    {renderDialogContent(initialValues, copying, downloadingDeleting, onCopyPressed)}
    {pageMode !== PAGE_MODE_TABLE && (<Comment discusId={discusId} path={REST_BASE_URL_SESSION} />)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_SESSION_OPERATOR_SESSION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SessionOperatorSessionPage);

SessionOperatorSessionPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  copying: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  discusId: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  onAppear: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onCopyPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
