import { loggingOut, clearAllStates } from '../simple-action';
import { logout, removeAllStorage } from '../../../helper';
import { OAUTH_KEY_CLOAK_LOGOUT_URI, ROUTE_NAME_LOGIN } from '../../../constant';

export default (navigateTo) => async (dispatch, getState) => {
  try {
    dispatch(loggingOut(true));
    const { authentication, myConfigItems } = getState();
    const { token } = authentication;
    const { auth } = myConfigItems;

    await logout(token);

    if (auth.oAuthKeyCloakCallLogoutUrl === 'true') {
      const { oAuthKeyCloakServerAddress, oAuthKeyCloakRealm } = auth;
      const url = OAUTH_KEY_CLOAK_LOGOUT_URI
        .replace(/\{serverName\}/, oAuthKeyCloakServerAddress)
        .replace(/\{realmName\}/, oAuthKeyCloakRealm);
      window.open(url, '_self');
    } else {
      navigateTo(ROUTE_NAME_LOGIN);
    }
  } finally {
    await removeAllStorage();
    dispatch(clearAllStates());
    dispatch(loggingOut(false));
  }
};
