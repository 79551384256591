import React from 'react';
import PropTypes from 'prop-types';
import {
  Divider, makeStyles, Typography,
} from '@material-ui/core';
import { COLOR_PRIMARY } from '../constant';
import AccentButton from './accent-button';

const useStyles = makeStyles(() => ({
  divider: {
    height: 2,
    margin: '25px 0px 25px 0px',
    background: COLOR_PRIMARY,
  },
  heading: {
    color: COLOR_PRIMARY,
    fontSize: '16px',
    margin: '0px 0px 8px 10px',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  button: {
    height: 30,
    width: 100,
    margin: '0px 0px 20px 10px',
  },
}));

const SectionTitle = ({
  title, textStyle, haveButton, buttonTitle, onButtonPressed,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        <Typography variant="subtitle1" className={`${classes.heading} ${textStyle}`}>
          {title}
        </Typography>

        {
          haveButton && (
          <AccentButton
            variant="text"
            onClick={() => onButtonPressed()}
            className={classes.button}
            caption={buttonTitle}
          />
          )
      }
      </div>
    </div>
  );
};

export default SectionTitle;

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  textStyle: PropTypes.string,
  haveButton: PropTypes.bool,
  buttonTitle: PropTypes.string,
  onButtonPressed: PropTypes.func,
};

SectionTitle.defaultProps = {
  textStyle: '',
  buttonTitle: '',
  haveButton: false,
  onButtonPressed: () => {},
};
