import {
  downloadingAttendanceSummaryClockInArea, setAttendanceSummaryClockInArea,
} from '../simple-action';
import { downloadAttendanceClockInArea } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAttendanceSummaryClockInArea(true));

    const { authentication } = getState();
    const { token } = authentication;

    const data = await downloadAttendanceClockInArea(token);

    dispatch(setAttendanceSummaryClockInArea(data));
  } finally {
    dispatch(downloadingAttendanceSummaryClockInArea(false));
  }
};
