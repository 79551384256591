import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  clearBanners, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setBannerSearchText, setBannerSelectedPageSize, setBannerSelectedOrderBy,
  setBannerTappedId, setBannerSelectedAddMediaMenu, setBannerSelectedClickAction,
  setBannerShortDescriptionLength, setBannerSelectedCompany,
  addEditBannerAsync, downloadDeleteBannerAsync, downloadBannersAsync,
  setCompanyAdvancedFilterDialogSelectedFilterStringMData,
  setCompanySearchTextMData, clearCompaniesMData, downloadMDataCompaniesAsync,
  setAdvancedFilterDialogSelectedFilterString,
} from '../../redux/action';
import {
  CLICK_ACTION_NONE, DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_BANNERS,
  MENUID_PERSONALIZATION_BANNER, PAGE_MODE_TABLE, RXFIELD_BANNER_BODY, RXFIELD_BANNER_CLICK_ACTION,
  RXFIELD_BANNER_MEDIA, RXFIELD_BANNER_SHORT_DESCRIPTION, RXFORM_BANNER,
  RXFIELD_BANNER_COMPANY,
  ADVANCED_FILTER_MODE,
} from '../../constant';
import {
  localDateToUtc, transformInitialValues, toMoment, transformDropdownData,
} from '../../helper';
import LocalizedString from '../../localization';
import BannerPage from './banner.presentation';

const getInitialValues = (state) => {
  const { banners, uiBanner, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiBanner;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? banners.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      validFrom: found.validFrom ? toMoment(found.validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE)
        : null,
      validUntil: found.validUntil
        ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
      company: { label: found.company?.name || '', value: found.company?.id || '' },
    }) : {
      title: '',
      shortDescription: '',
      clickAction: CLICK_ACTION_NONE,
      validFrom: '',
      validUntil: '',
      viewCount: null,
      androidAppId: null,
      iosAppId: null,
      downloadUrl: null,
      order: null,
    };
  return initVal;
};

const getDiscusId = (state) => {
  const { banners, uiBanner, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiBanner;
  const { pageMode } = uiFunctionalPage;

  return pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? banners.data[tappedId].discusId : '';
};


const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiBanner.addingEditing,
  downloading: state.uiBanner.downloading,
  downloadingDeleting: state.uiBanner.downloadingDeleting,
  selectedAddMediaMenu: state.uiBanner.selectedAddMediaMenu,
  selectedClickAction: state.uiBanner.selectedClickAction,
  shortDescriptionLength: state.uiBanner.shortDescriptionLength,
  initialValues: getInitialValues(state),
  discusId: getDiscusId(state),
  companies: transformDropdownData(state.companiesMdata.data),
  loadingCompanies: state.uiCompany.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAddMediaPressed: (data) => {
    dispatch(change(RXFORM_BANNER, RXFIELD_BANNER_MEDIA, data));
  },
  onAddMediaMenuSelected: (menu) => {
    dispatch(setBannerSelectedAddMediaMenu(menu));
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_PERSONALIZATION_BANNER));
    dispatch(setBannerSearchText(''));
    dispatch(clearBanners());
    dispatch(setBannerSelectedPageSize(20));
    dispatch(setBannerSelectedOrderBy(INITIAL_ORDER_BY_BANNERS));
    dispatch(downloadBannersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(setBannerSelectedCompany(null));
    dispatch(setBannerSelectedClickAction(CLICK_ACTION_NONE));
    dispatch(setBannerShortDescriptionLength(0));
    dispatch(reset(RXFORM_BANNER));
  },
  onChangeBodyText: (text) => {
    dispatch(change(RXFORM_BANNER, RXFIELD_BANNER_BODY, text));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadBannersAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setBannerSelectedPageSize(pageSize));
    dispatch(downloadBannersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeShortDescriptionText: (text) => {
    dispatch(setBannerShortDescriptionLength(text.length));
    dispatch(change(RXFORM_BANNER, RXFIELD_BANNER_SHORT_DESCRIPTION, text));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteBannerAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onClickActionOptionSelected: (option) => {
    if (option) {
      dispatch(setBannerSelectedClickAction(option));
      dispatch(change(RXFORM_BANNER, RXFIELD_BANNER_CLICK_ACTION, option));
    } else {
      dispatch(setBannerSelectedClickAction(''));
      dispatch(change(RXFORM_BANNER, RXFIELD_BANNER_CLICK_ACTION, ''));
    }
  },
  onCreatePressed: () => {
    dispatch(setBannerSelectedCompany(null));
    dispatch(setBannerTappedId(''));
    dispatch(setBannerShortDescriptionLength(0));
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeleteMediaPressed: (data) => {
    dispatch(change(RXFORM_BANNER, RXFIELD_BANNER_MEDIA, data));
  },
  onDeletePressed: (id) => {
    dispatch(setBannerTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setBannerTappedId(id));
    dispatch(downloadDeleteBannerAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setBannerSelectedPageSize(pageSize));
    dispatch(clearBanners());
    dispatch(downloadBannersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    title, body, url, clickAction, validFrom, validUntil, androidAppId, iosAppId,
    downloadUrl, order, media, shortDescription, tags,
  }) => {
    try {
      await dispatch(addEditBannerAsync(title, body, url, clickAction, localDateToUtc(validFrom),
        localDateToUtc(validUntil), androidAppId, iosAppId, downloadUrl, order, media,
        shortDescription, tags));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setBannerSearchText(text));
      dispatch(clearBanners());
      await dispatch(downloadBannersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setBannerSelectedOrderBy(orderBy));
    dispatch(clearBanners());
    dispatch(downloadBannersAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    title, body, url, clickAction, validFrom, validUntil, androidAppId, iosAppId, downloadUrl,
    order, media, shortDescription, tags,
  }) => {
    await dispatch(addEditBannerAsync(title, body, url, clickAction, localDateToUtc(validFrom),
      localDateToUtc(validUntil), androidAppId, iosAppId, downloadUrl, order, media,
      shortDescription, tags));
  },
  onViewPressed: (id) => {
    dispatch(setBannerTappedId(id));
    dispatch(downloadDeleteBannerAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCompanyOptionSelected: (option) => {
    if (option) {
      dispatch(setBannerSelectedCompany(option));
      dispatch(change(RXFORM_BANNER, RXFIELD_BANNER_COMPANY, option));
    } else {
      dispatch(setBannerSelectedCompany(''));
      dispatch(change(RXFORM_BANNER, RXFIELD_BANNER_COMPANY, ''));
    }
  },
  onChangeCompanyText: async (text, mode) => {
    try {
      dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData(
        mode === ADVANCED_FILTER_MODE ? '' : 'status=enabled',
      ));
      dispatch(setCompanySearchTextMData(text));
      dispatch(clearCompaniesMData());
      await dispatch(downloadMDataCompaniesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData(''));
    dispatch(setCompanySearchTextMData(''));
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/company/, 'company.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearBanners());
    dispatch(downloadBannersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearBanners());
    dispatch(setBannerSelectedOrderBy(INITIAL_ORDER_BY_BANNERS));
    dispatch(downloadBannersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BannerPage);
