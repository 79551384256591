import { INITIAL_ORDER_BY_ROOMS } from '../../constant';
import {
  ADDING_EDITING_ROOM, DOWNLOADING_DELETING_ROOM, DOWNLOADING_ROOMS,
  ENABLING_DISABLING_ROOM, SAVING_ROOMS, SET_ROOM_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_ROOM_SEARCH_TEXT, SET_ROOM_SELECTED_LOCATION_ID, SET_ROOM_SELECTED_ORDER_BY,
  SET_ROOM_SELECTED_PAGE_SIZE, SET_ROOM_TAPPED_ID,
} from '../action/simple-action';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  enablingDisabling: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_ROOMS,
  filterString: '',
  saving: false,
  selectedLocationId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_ROOMS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_ROOM:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_ROOM:
      return { ...state, downloadingDeleting: action.status };
    case SET_ROOM_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_ROOM_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_ROOM_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_ROOM_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_ROOM_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case ENABLING_DISABLING_ROOM:
      return { ...state, enablingDisabling: action.status };
    case SAVING_ROOMS:
      return { ...state, saving: action.status };
    case SET_ROOM_SELECTED_LOCATION_ID:
      return { ...state, selectedLocationId: action.id };
    default: return state;
  }
};
