import { downloadSessionOperatorMappings, transformSearchText } from '../../../helper';
import { downloadingSessionOperatorMappings, setSessionOperatorMappings } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, sessionOperatorMappings, uiSessionOperatorMapping } = getState();
  const { token } = authentication;
  const { meta } = sessionOperatorMappings;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiSessionOperatorMapping;

  try {
    dispatch(downloadingSessionOperatorMappings(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadSessionOperatorMappings(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setSessionOperatorMappings(list));
    }
  } finally {
    dispatch(downloadingSessionOperatorMappings(false));
  }
};
