import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_NEWS = 'DOWNLOADING_NEWS';
export const downloadingNews = makeActionCreator(DOWNLOADING_NEWS, 'status');

export const SET_NEWS = 'SET_NEWS';
export const setNews = makeActionCreator(SET_NEWS, 'data');

export const ADD_NEWS = 'ADD_NEWS';
export const addNews = makeActionCreator(ADD_NEWS, 'data');

export const CLEAR_NEWS = 'CLEAR_NEWS';
export const clearNews = makeActionCreator(CLEAR_NEWS);

export const ADDING_EDITING_NEWS = 'ADDING_EDITING_NEWS';
export const addingEditingNews = makeActionCreator(ADDING_EDITING_NEWS, 'status');

export const DOWNLOADING_DELETING_NEWS = 'DOWNLOADING_DELETING_NEWS';
export const downloadingDeletingNews = makeActionCreator(DOWNLOADING_DELETING_NEWS, 'status');

export const SENDING_NEWS_NOTIFICATION = 'SENDING_NEWS_NOTIFICATION';
export const sendingNewsNotification = makeActionCreator(SENDING_NEWS_NOTIFICATION, 'status');

export const COPYING_NEWS_DEEP_LINK = 'COPYING_NEWS_DEEP_LINK';
export const copyingNewsDeepLink = makeActionCreator(COPYING_NEWS_DEEP_LINK, 'status');


export const SET_NEWS_SELECTED_PAGE_SIZE = 'SET_NEWS_SELECTED_PAGE_SIZE';
export const setNewsSelectedPageSize = makeActionCreator(SET_NEWS_SELECTED_PAGE_SIZE, 'size');

export const SET_NEWS_SELECTED_ORDER_BY = 'SET_NEWS_SELECTED_ORDER_BY';
export const setNewsSelectedOrderBy = makeActionCreator(SET_NEWS_SELECTED_ORDER_BY, 'order');

export const SET_NEWS_TAPPED_ID = 'SET_NEWS_TAPPED_ID';
export const setNewsTappedId = makeActionCreator(SET_NEWS_TAPPED_ID, 'id');

export const SET_NEWS_SEARCH_TEXT = 'SET_NEWS_SEARCH_TEXT';
export const setNewsSearchText = makeActionCreator(SET_NEWS_SEARCH_TEXT, 'text');

export const SET_NEWS_SELECTED_CATEGORY = 'SET_NEWS_SELECTED_CATEGORY';
export const setNewsSelectedCategory = makeActionCreator(SET_NEWS_SELECTED_CATEGORY, 'option');

export const SET_NEWS_SELECTED_VISIBILITY_STATUS = 'SET_NEWS_SELECTED_VISIBILITY_STATUS';
export const setNewsSelectedVisibilityStatus = makeActionCreator(SET_NEWS_SELECTED_VISIBILITY_STATUS, 'option');

export const SET_NEWS_SELECTED_ADD_MEDIA_MENU = 'SET_NEWS_SELECTED_ADD_MEDIA_MENU';
export const setNewsSelectedAddMediaMenu = makeActionCreator(SET_NEWS_SELECTED_ADD_MEDIA_MENU, 'text');

export const SET_NEWS_SHORT_DESCRIPTION_LENGTH = 'SET_NEWS_SHORT_DESCRIPTION_LENGTH';
export const setNewsShortDescriptionLength = makeActionCreator(SET_NEWS_SHORT_DESCRIPTION_LENGTH, 'number');

export const SET_NEWS_SELECTED_IS_HEADLINE = 'SET_NEWS_SELECTED_IS_HEADLINE';
export const setNewsSelectedIsHeadline = makeActionCreator(SET_NEWS_SELECTED_IS_HEADLINE, 'option');


export const DOWNLOADING_NEWS_CATEGORIES = 'DOWNLOADING_NEWS_CATEGORIES';
export const downloadingNewsCategories = makeActionCreator(DOWNLOADING_NEWS_CATEGORIES, 'status');

export const SET_NEWS_CATEGORIES = 'SET_NEWS_CATEGORIES';
export const setNewsCategories = makeActionCreator(SET_NEWS_CATEGORIES, 'data');

export const ADD_NEWS_CATEGORY = 'ADD_NEWS_CATEGORY';
export const addNewsCategory = makeActionCreator(ADD_NEWS_CATEGORY, 'data');

export const CLEAR_NEWS_CATEGORIES = 'CLEAR_NEWS_CATEGORIES';
export const clearNewsCategories = makeActionCreator(CLEAR_NEWS_CATEGORIES);

export const ADDING_EDITING_NEWS_CATEGORY = 'ADDING_EDITING_NEWS_CATEGORY';
export const addingEditingNewsCategory = makeActionCreator(ADDING_EDITING_NEWS_CATEGORY, 'status');

export const DOWNLOADING_DELETING_NEWS_CATEGORY = 'DOWNLOADING_DELETING_NEWS_CATEGORY';
export const downloadingDeletingNewsCategory = makeActionCreator(DOWNLOADING_DELETING_NEWS_CATEGORY, 'status');


export const SET_NEWS_CATEGORY_SELECTED_PAGE_SIZE = 'SET_NEWS_CATEGORY_SELECTED_PAGE_SIZE';
export const setNewsCategorySelectedPageSize = makeActionCreator(SET_NEWS_CATEGORY_SELECTED_PAGE_SIZE, 'size');

export const SET_NEWS_CATEGORY_SELECTED_ORDER_BY = 'SET_NEWS_CATEGORY_SELECTED_ORDER_BY';
export const setNewsCategorySelectedOrderBy = makeActionCreator(SET_NEWS_CATEGORY_SELECTED_ORDER_BY, 'order');

export const SET_NEWS_CATEGORY_TAPPED_ID = 'SET_NEWS_CATEGORY_TAPPED_ID';
export const setNewsCategoryTappedId = makeActionCreator(SET_NEWS_CATEGORY_TAPPED_ID, 'id');

export const SET_NEWS_CATEGORY_SEARCH_TEXT = 'SET_NEWS_CATEGORY_SEARCH_TEXT';
export const setNewsCategorySearchText = makeActionCreator(SET_NEWS_CATEGORY_SEARCH_TEXT, 'text');

export const SET_NEWS_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_NEWS_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setNewsCategoryAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_NEWS_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');
