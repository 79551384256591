import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import {
  renderReduxFormOutlinedTextField, renderReduxFormOutlinedDropdownTextField,
  renderReduxFormDateTimePickerField, renderReduxFormAutocompleteCheckboxField,
} from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { FunctionalPage, SectionTitle } from '../../component';
import { rxformValidateSessionInvitation } from '../../validation';
import {
  SESSION_INVITATION_STATUS,
  FILTER_TYPE_DROPDOWN,
  FILTER_TYPE_TEXT,
  PAGE_MODE_VIEW,
  RXFIELD_SESSION_INVITATION_EMAIL, RXFIELD_SESSION_INVITATION_INVITATION_STATUS,
  RXFIELD_SESSION_INVITATION_REGISTERED_ON, RXFIELD_SESSION_INVITATION_RESENT_COUNT,
  RXFORM_SESSION_INVITATION, RXSTATE_SESSION_INVITATIONS, RXSTATE_SESSION_INVITATION_PAGE,
  status, FILTER_TYPE_DATE_RANGE, PICKER_MODE_DATE_TIME,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  RXFIELD_SESSION_INVITATION_SHORT_DESCRIPTION,
  RXFIELD_SESSION_INVITATION_START_TIME,
  RXFIELD_SESSION_INVITATION_END_TIME,
  RXFIELD_SESSION_INVITATION_SESSION_ID,
  RXFIELD_SESSION_INVITATION_USER_ID,
  RXFIELD_SESSION_INVITATION_FULL_NAME,
  RXFIELD_SESSION_INVITATION_PHONE,
  PAGE_MODE_TABLE,
  RXFIELD_SESSION_INVITATION_SESSION,
  RXFIELD_SESSION_INVITATION_RECIPIENTS,
  RXFIELD_SESSION_INVITATION_LOCATION,
  RXFIELD_SESSION_INVITATION_NAME,
  ADVANCED_FILTER_MODE,
} from '../../constant';

const useStyles = makeStyles(() => ({
  dialogHeader: {
    marginBottom: 20,
  },
}));

const renderDialogContent = (addingEditing, pageMode, initialValues,
  profiles, downloadingProfiles, onRecipientsTypeSelected,
  sessions, downloadingSessions, onSessionsTypeSelected, recipientValue, onChangeSessionText,
  onChangeRecipientText) => (
  pageMode === PAGE_MODE_TABLE
    ? (
      <Grid>
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_SESSION_INVITATION_SESSION}
                component={renderReduxFormOutlinedDropdownTextField}
                label={LocalizedString.sessionInvitationPage.labelSession}
                placeholder={LocalizedString.sessionInvitationPage.placeholderSession}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                data={sessions}
                loading={downloadingSessions}
                onChangeText={onChangeSessionText}
                onOptionSelected={onSessionsTypeSelected}
                onBlur={(e) => e.preventDefault()}
                required
              />
            </Grid>

          </Grid>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_SESSION_INVITATION_RECIPIENTS}
                component={renderReduxFormAutocompleteCheckboxField}
                label={LocalizedString.sessionInvitationPage.labelRecipients}
                data={profiles}
                loading={downloadingProfiles}
                onChangeText={onChangeRecipientText}
                onOptionSelected={(option) => onRecipientsTypeSelected(option)}
                onBlur={(e) => e.preventDefault()}
                required={recipientValue.length === 0}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ) : (
      <Grid>
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_SESSION_INVITATION_EMAIL}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.sessionInvitationPage.placeholderEmail}
                label={LocalizedString.sessionInvitationPage.labelEmail}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_SESSION_INVITATION_RESENT_COUNT}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.sessionInvitationPage.placeholderResentCount}
                label={LocalizedString.sessionInvitationPage.labelResentCount}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
          </Grid>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_SESSION_INVITATION_INVITATION_STATUS}
                component={renderReduxFormOutlinedDropdownTextField}
                placeholder={LocalizedString.sessionInvitationPage.placeholderInvitationStatus}
                label={LocalizedString.sessionInvitationPage.labelInvitationStatus}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                data={SESSION_INVITATION_STATUS}
                value={initialValues.invitationStatus}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_SESSION_INVITATION_REGISTERED_ON}
                component={renderReduxFormDateTimePickerField}
                placeholder={LocalizedString.sessionInvitationPage.placeholderRegisteredOn}
                label={LocalizedString.sessionInvitationPage.labelRegisteredOn}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                pickerMode={PICKER_MODE_DATE_TIME}
              />
            </Grid>
          </Grid>
        </Grid>
        <SectionTitle title={LocalizedString.sessionInvitationPage.labelSession} />
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_SESSION_INVITATION_SESSION_ID}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.sessionInvitationPage.placeholderId}
                label={LocalizedString.sessionInvitationPage.labelId}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_SESSION_INVITATION_NAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.sessionInvitationPage.placeholderName}
                label={LocalizedString.sessionInvitationPage.labelName}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_SESSION_INVITATION_SHORT_DESCRIPTION}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.sessionInvitationPage.placeholderShortDescription}
                label={LocalizedString.sessionInvitationPage.labelShortDescription}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
          </Grid>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_SESSION_INVITATION_START_TIME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.sessionInvitationPage.placeholderStartTime}
                label={LocalizedString.sessionInvitationPage.labelStartTime}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_SESSION_INVITATION_END_TIME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.sessionInvitationPage.placeholderEndTime}
                label={LocalizedString.sessionInvitationPage.labelEndTime}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_SESSION_INVITATION_LOCATION}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.sessionInvitationPage.placeholderLocation}
                label={LocalizedString.sessionInvitationPage.labelLocation}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
          </Grid>
        </Grid>
        <SectionTitle title={LocalizedString.sessionInvitationPage.labelUser} />
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_SESSION_INVITATION_USER_ID}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.sessionInvitationPage.placeholderId}
                label={LocalizedString.sessionInvitationPage.labelId}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_SESSION_INVITATION_FULL_NAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.sessionInvitationPage.placeholderFullName}
                label={LocalizedString.sessionInvitationPage.labelFullName}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
          </Grid>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_SESSION_INVITATION_PHONE}
                component={renderReduxFormDateTimePickerField}
                placeholder={LocalizedString.sessionInvitationPage.placeholderPhone}
                label={LocalizedString.sessionInvitationPage.labelPhone}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
);

const SessionInvitationPage = ({
  initialValues, onAdvancedFilterPressed, addingEditing, downloading,
  handleSubmit, onAppear, onApplyAdvancedFilterPressed,
  onCancelPressed, onChangePage, onChangePageSize,
  onConfirmContextMenuPressed, onCreatePressed, onDownloadPressed,
  onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed,
  onSubmitPressed, onViewPressed, sessionInvitations, pageMode, tappedId,
  onDeletePressed, sessions, profiles, downloadingProfiles,
  onRecipientsTypeSelected, downloadingSessions, onSessionsTypeSelected,
  onResendPressed, resending, recipientValue, onChangeSessionText, onChangeRecipientText,
  isResendAllowed,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_SESSION_INVITATIONS}
      uiPage={RXSTATE_SESSION_INVITATION_PAGE}
      moreMenus={[
        {
          caption: LocalizedString.sessionInvitationPage.buttonCaptionResend,
          disabled: downloading || resending || !isResendAllowed,
          onPress: () => onResendPressed(tappedId),
        },
      ]}
      tableColumns={[
        {
          title: GlobalLocalizedString.common.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.sessionInvitationPage.labelRecipient, field: 'email', sorting: !downloading },
        { title: LocalizedString.sessionInvitationPage.labelSessionName, field: 'session.name', sorting: !downloading },
        { title: LocalizedString.sessionInvitationPage.labelInvitationStatus, field: 'invitationStatus', sorting: !downloading },
        { title: LocalizedString.sessionInvitationPage.labelResentCount, field: 'resentCount', sorting: !downloading },
        { title: LocalizedString.sessionInvitationPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
      ]}
      filterColumns={[
        {
          title: LocalizedString.sessionInvitationPage.labelSession,
          field: 'session',
          type: FILTER_TYPE_DROPDOWN,
          data: sessions,
          loading: downloadingSessions,
          onChangeFilterText: (text) => onChangeSessionText(text, ADVANCED_FILTER_MODE),
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sessionInvitationPage.labelRecipient,
          field: 'email',
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.sessionInvitationPage.labelCreatedDate,
          field: 'createdDate',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.sessionInvitationPage.labelInvitationStatus,
          field: 'invitationStatus',
          type: FILTER_TYPE_DROPDOWN,
          data: SESSION_INVITATION_STATUS,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sessionInvitationPage.labelStatus,
          field: 'status',
          type: FILTER_TYPE_DROPDOWN,
          data: status,
          useDropdownValue: true,
        },
      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmContextMenuPressed}
      onCreatePressed={onCreatePressed}
      onDownloadPressed={onDownloadPressed}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onSubmitPressed={onSubmitPressed}
      onViewPressed={(id) => onViewPressed(id, sessionInvitations)}
      createNewButtonCaption={
        LocalizedString.sessionInvitationPage.buttonCaptionCreateNewSessionInvitation
      }
      deleteButtonCaption={
        LocalizedString.sessionInvitationPage.buttonCaptionDeleteSessionInvitation
      }
      title={LocalizedString.sessionInvitationPage.title}
      addDialogHeaderContainerStyle={classes.dialogHeader}
      onDeletePressed={onDeletePressed}
      disableEdit
      useFullWidth
      usefullWidthDialog
      createPermissionName="EVENT_CREATE_SESSION_INVITATION"
      deletePermissionName="EVENT_DELETE_SESSION_INVITATION"
      savePermissionName="EVENT_GET_SESSION_INVITATION"
    >
      {renderDialogContent(addingEditing, pageMode, initialValues,
        profiles, downloadingProfiles, onRecipientsTypeSelected,
        sessions, downloadingSessions, onSessionsTypeSelected, recipientValue, onChangeSessionText,
        onChangeRecipientText)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_SESSION_INVITATION,
  validate: rxformValidateSessionInvitation,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SessionInvitationPage);

SessionInvitationPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  sessions: PropTypes.arrayOf(PropTypes.object).isRequired,
  sessionInvitations: PropTypes.objectOf(PropTypes.object).isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  recipientValue: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingSessions: PropTypes.bool.isRequired,
  downloadingProfiles: PropTypes.bool.isRequired,
  isResendAllowed: PropTypes.bool.isRequired,
  resending: PropTypes.bool.isRequired,
  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeSessionText: PropTypes.func.isRequired,
  onChangeRecipientText: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onSessionsTypeSelected: PropTypes.func.isRequired,
  onRecipientsTypeSelected: PropTypes.func.isRequired,
  onResendPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
