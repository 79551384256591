import {
  addForm, clearForms, downloadingDeletingForm,
  setFormTappedId, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { deleteForm, downloadForm } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadFormsAsync from './downloadFormsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingForm(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiForm;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteForm(tappedId, token);
      dispatch(setFormTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearForms());
      dispatch(downloadFormsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const eventCategory = await downloadForm(tappedId, token);
      dispatch(addForm(eventCategory));
    }
  } finally {
    dispatch(downloadingDeletingForm(false));
  }
};
