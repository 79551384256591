import {
  addField, clearFields, downloadingDeletingField,
  setAlertErrorMessage, setFunctionalPageMode, setFieldTappedId,
  setFieldSelectedFieldType, setFieldSelectedParent,
} from '../simple-action';
import { downloadField, deleteField } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadFieldsAsync from './downloadFieldsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingField(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiField;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteField(tappedId, token);
      dispatch(setFieldTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearFields());
      dispatch(downloadFieldsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const additionalField = await downloadField(tappedId, token);
      const { fieldType, parent } = additionalField;
      dispatch(setFieldSelectedFieldType(fieldType));
      dispatch(setFieldSelectedParent(parent ? { label: parent.name, value: parent.id } : ''));
      dispatch(addField(additionalField));
    }
  } finally {
    dispatch(downloadingDeletingField(false));
  }
};
