import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  PAGE_MODE_TABLE,
  INITIAL_ORDER_BY_DAILY_CHECKINS,
  RXFORM_DAILY_CHECKIN,
  MENUID_ACTIVITY_REWARD_DAILY_CHECKIN,
} from '../../constant';
import {
  transformInitialValues,
  transformUserDropdownData,
  toCurrency,
  debounceSearch,
} from '../../helper';
import {
  setActiveSideMenuItem,
  setAlertErrorMessage,
  setDailyCheckinSearchText,
  clearDailyCheckins,
  setDailyCheckinSelectedPageSize,
  setDailyCheckinSelectedOrderBy,
  setDailyCheckinAdvancedFilterDialogSelectedFilterString,
  setDailyCheckinTappedId,
  downloadDailyCheckinsAsync,
  downloadDailyCheckinAsync,
  setProfileAdvancedFilterDialogSelectedFilterString,
  setProfileSearchText,
  clearProfiles,
  downloadProfilesAsync,
} from '../../redux/action';
import DailyCheckinPage from './daily-checkin.presentation';

const getInitialValues = (state) => {
  const {
    dailyCheckins,
    uiFunctionalPage,
    uiDailyCheckin,
  } = state;
  const { data } = dailyCheckins;
  const { downloadingDeleting, tappedId } = uiDailyCheckin;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};

  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      pointAmount: toCurrency(found.pointAmount),
    })
    : {};
  return result;
};

const searchUserDebounce = debounceSearch((dispatch) => {
  dispatch(clearProfiles());
  dispatch(downloadProfilesAsync(1))
    .catch((err) => dispatch(setAlertErrorMessage(err)));
});

const mapStateToProps = (state) => ({
  downloading: state.uiDailyCheckin.downloading,
  downloadingDeleting: state.uiDailyCheckin.downloadingDeleting,
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.profiles.data),
  loadingUsers: state.uiProfile.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_ACTIVITY_REWARD_DAILY_CHECKIN));
    dispatch(setDailyCheckinSearchText(''));
    dispatch(clearDailyCheckins());
    dispatch(setDailyCheckinSelectedPageSize(20));
    dispatch(
      setDailyCheckinSelectedOrderBy(
        INITIAL_ORDER_BY_DAILY_CHECKINS,
      ),
    );
    dispatch(downloadDailyCheckinsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('createdDate', 'createdDate')
      .replace('registeredUser', 'profile.id');

    dispatch(
      setDailyCheckinAdvancedFilterDialogSelectedFilterString(text),
    );
    dispatch(clearDailyCheckins());
    dispatch(downloadDailyCheckinsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadDailyCheckinsAsync(pageNo + 1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setDailyCheckinSelectedPageSize(pageSize));
    dispatch(downloadDailyCheckinsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setDailyCheckinSelectedPageSize(pageSize));
    dispatch(clearDailyCheckins());
    dispatch(downloadDailyCheckinsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearDailyCheckins());
    dispatch(
      setDailyCheckinAdvancedFilterDialogSelectedFilterString(''),
    );
    dispatch(
      setDailyCheckinSelectedOrderBy(
        INITIAL_ORDER_BY_DAILY_CHECKINS,
      ),
    );
    dispatch(downloadDailyCheckinsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setDailyCheckinSearchText(text));
      dispatch(clearDailyCheckins());
      await dispatch(downloadDailyCheckinsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setDailyCheckinSelectedOrderBy(orderBy));
    dispatch(clearDailyCheckins());
    dispatch(downloadDailyCheckinsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setDailyCheckinTappedId(id));
    dispatch(downloadDailyCheckinAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_DAILY_CHECKIN));
  },
  onChangeUserText: async (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DailyCheckinPage);
