import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_PAYMENTS = '/payment/api/v1/Payment?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|paymentCode|transactionId';
export const REST_URL_VIEW_PAYMENT = '/payment/api/v1/Payment/{id}';
export const REST_URL_REPUBLISH_PAYMENT = '/payment/api/v1/Payment/Republish';

export const ROUTE_NAME_PAYMENT_MENU = '/payment';
export const ROUTE_NAME_PAYMENT = '/payment';

export const RXFORM_PAYMENT = 'paymentPage';

export const RXFIELD_PAYMENT_TRANSACTION_ID = 'transactionId';
export const RXFIELD_PAYMENT_VALID_UNTIL = 'validUntil';
export const RXFIELD_PAYMENT_TOTAL = 'total';
export const RXFIELD_PAYMENT_LAST_CHANGED_STATUS = 'lastChangedStatus';
export const RXFIELD_PAYMENT_LAST_STATUS_CHECK = 'lastStatusCheck';
export const RXFIELD_PAYMENT_PAYMENT_CHANNEL = 'paymentChannel';
export const RXFIELD_PAYMENT_PAYMENT_STATUS = 'paymentStatus';
export const RXFIELD_PAYMENT_EXTERNAL_TRANSACTION_ID = 'externalTransactionId';
export const RXFIELD_PAYMENT_PAYMENT_STATUS_NOTES = 'paymentStatusNotes';
export const RXFIELD_PAYMENT_PAYMENT_URL = 'url';
export const RXFIELD_PAYMENT_PAYMENT_CODE = 'paymentCode';
export const RXFIELD_PAYMENT_PROVIDER = 'provider';
export const RXFIELD_PAYMENT_RAW_REQUEST = 'paymentRawRequest';
export const RXFIELD_PAYMENT_RAW_CALLBACK = 'paymentRawCallback';
export const RXFIELD_PAYMENT_RAW_RESPONSE = 'paymentRawResponse';

export const RXSTATE_PAYMENTS = 'payments';
export const RXSTATE_PAYMENT_PAGE = 'uiPayment';

export const INITIAL_ORDER_BY_PAYMENTS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_PAYMENT = 'MENUID_PAYMENT';
export const MENUID_PAYMENT_PAYMENT = 'MENUID_PAYMENT_PAYMENT';
