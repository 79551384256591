import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_POINTS, MENUID_POINT_POINT, PAGE_MODE_TABLE,
  RXFORM_POINT, RXFORM_CUSTOM_FIELD_DIALOG, RXFIELD_VOID_POINT_DIALOG_SEND_NOTIFICATION,
  RXFIELD_VOID_POINT_DIALOG_REASON, RXFIELD_VOID_POINT_DIALOG_REFERENCE_ID,
} from '../../constant';
import {
  debounceSearch, getPermission, transformInitialValues, transformUserDropdownData, toMoment,
  toCurrency,
} from '../../helper';
import {
  clearPoints, setActiveSideMenuItem, setAdvancedFilterDialogSelectedFilterString,
  setAlertErrorMessage, setPointSearchText, setPointSelectedPageSize, setPointSelectedOrderBy,
  setPointTappedId, downloadPointAsync, downloadPointsAsync,
  setPointAdvancedFilterDialogSelectedFilterString, showVoidPointDialog,
  setVoidPointDialogSelectedSendNotification, voidPointAsync,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText, clearProfiles,
} from '../../redux/action';
import { downloadProfilesAsync } from '../../../../redux/action';
import PointPage from './point.presentation';

const getInitialValues = (state) => {
  const {
    points, uiFunctionalPage, uiPoint,
  } = state;
  const { data } = points;
  const { downloadingDeleting, tappedId } = uiPoint;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    fullName: found.user?.fullName || '',
    validUntil: found.validUntil ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    voidedOn: found.voidedOn ? toMoment(found.voidedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
  }) : {
    tags: '',
    amount: '',
    validUntil: '',
    expiredPointAlreadyProcessed: false,
    label: '',
    merchantReferenceId: '',
    merchantId: '',
    merchantName: '',
    isVoided: false,
    voidedOn: null,
    voidReferenceId: null,
    voidNotes: null,
    voidedBy: null,
    fullName: '',
  };
  return result;
};

const getSelectedPointName = (state) => {
  const {
    points, uiPoint,
  } = state;
  const { data } = points;
  const { tappedId } = uiPoint;
  if (tappedId) {
    const found = Object.values(data).find((x) => x.id === tappedId);
    if (found) {
      return found?.label ? `${toCurrency(found?.amount || 0)} (${found?.label})` : '';
    }
    return '';
  }
  return '';
};

const getSelectedUser = (state) => {
  const {
    points, uiPoint,
  } = state;
  const { data } = points;
  const { tappedId } = uiPoint;
  if (tappedId) {
    const found = Object.values(data).find((x) => x.id === tappedId);
    if (found) {
      return found?.user?.fullName ?? '';
    }
    return '';
  }
  return '';
};

const mapStateToProps = (state) => ({
  downloading: state.uiPoint.downloading,
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.profiles.data),
  isVoidAllowed: getPermission(state, 'POINT_CREATE_VOID_POINT_REQUEST'),
  loadingUser: state.uiProfile.downloading,
  selectedPointName: getSelectedPointName(state),
  selectedUser: getSelectedUser(state),
  showVoidDialog: state.uiPoint.showVoidDialog,
  voidingPoint: state.uiPoint.voidingPoint,
  selectedSendNotificationValue: state.uiPoint.selectedSendNotification,
  tappedId: state.uiFunctionalPage.tappedId || '',
});

const searchUserDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_POINT_POINT));
    dispatch(setPointSearchText(''));
    dispatch(setPointAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearPoints());
    dispatch(setPointSelectedPageSize(20));
    dispatch(setPointSelectedOrderBy(INITIAL_ORDER_BY_POINTS));
    dispatch(downloadPointsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/fullName/, 'user.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(setPointAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearPoints());
    dispatch(downloadPointsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_POINT));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadPointsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setPointSelectedPageSize(pageSize));
    dispatch(downloadPointsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setPointSelectedPageSize(pageSize));
    dispatch(clearPoints());
    dispatch(downloadPointsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setPointAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearPoints());
    dispatch(setPointSelectedOrderBy(INITIAL_ORDER_BY_POINTS));
    dispatch(downloadPointsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setPointSearchText(text));
      dispatch(clearPoints());
      await dispatch(downloadPointsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setPointSelectedOrderBy(orderBy));
    dispatch(clearPoints());
    dispatch(downloadPointsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setPointTappedId(id));
    dispatch(downloadPointAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onVoidPressed: (id) => {
    dispatch(showVoidPointDialog(true));
    dispatch(setPointTappedId(id));
    dispatch(setVoidPointDialogSelectedSendNotification(null));
    dispatch(change(RXFORM_CUSTOM_FIELD_DIALOG, RXFIELD_VOID_POINT_DIALOG_SEND_NOTIFICATION, null));
    dispatch(change(RXFORM_CUSTOM_FIELD_DIALOG, RXFIELD_VOID_POINT_DIALOG_REASON, null));
    dispatch(change(RXFORM_CUSTOM_FIELD_DIALOG, RXFIELD_VOID_POINT_DIALOG_REFERENCE_ID, null));
  },
  onCloseVoidModal: () => {
    dispatch(showVoidPointDialog(false));
    dispatch(setVoidPointDialogSelectedSendNotification(null));
    dispatch(change(RXFORM_CUSTOM_FIELD_DIALOG, RXFIELD_VOID_POINT_DIALOG_SEND_NOTIFICATION, null));
    dispatch(change(RXFORM_CUSTOM_FIELD_DIALOG, RXFIELD_VOID_POINT_DIALOG_REASON, null));
    dispatch(change(RXFORM_CUSTOM_FIELD_DIALOG, RXFIELD_VOID_POINT_DIALOG_REFERENCE_ID, null));
  },
  onVoidPointModalOKPressed: (values) => {
    dispatch(voidPointAsync(values))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSendNotificationOptionSelected: (option) => {
    if (option) {
      dispatch(setVoidPointDialogSelectedSendNotification(option));
      dispatch(change(
        RXFORM_CUSTOM_FIELD_DIALOG, RXFIELD_VOID_POINT_DIALOG_SEND_NOTIFICATION, option,
      ));
    } else {
      dispatch(setVoidPointDialogSelectedSendNotification(null));
      dispatch(change(
        RXFORM_CUSTOM_FIELD_DIALOG, RXFIELD_VOID_POINT_DIALOG_SEND_NOTIFICATION, null,
      ));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PointPage);
