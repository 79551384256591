import { downloadingClaimers, setClaimers } from '../simple-action';
import { downloadProfiles, transformSearchText } from '../../../helper';
import { INITIAL_ORDER_BY_USERS } from '../../../constant';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, claimers, uiVoucherToken } = getState();
  const { token } = authentication;
  const { meta } = claimers;
  const { claimerSearchBarText, claimerFilterString } = uiVoucherToken;

  try {
    dispatch(downloadingClaimers(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadProfiles(
      pageToBeDownloaded,
      20,
      INITIAL_ORDER_BY_USERS,
      transformSearchText(claimerSearchBarText),
      claimerFilterString,
      token,
    );

    if (list) {
      dispatch(setClaimers(list));
    }
  } finally {
    dispatch(downloadingClaimers(false));
  }
};
