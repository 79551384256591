/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, Grid, CircularProgress, Avatar,
  TextField,
  InputAdornment,
  IconButton,
  Snackbar,
} from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FileCopyOutlined } from '@material-ui/icons';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import {
  renderReduxFormDateTimePickerField,
  renderReduxFormOutlinedDropdownTextField,
  renderReduxFormOutlinedTextField,
  renderReduxFormSimpleDropdownField,
  renderReduxFormEditableTableField,
  renderReduxFormAutocompleteCheckboxField,
  renderReduxFormRichTextEditorField,
  renderReduxFormAutocompleteTableField,
  renderReduxFormDropzonePicker,
} from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import {
  FunctionalPage, SectionTitle, NumberTextFieldWithoutArrowDial,
  ImageInputField,
  FilePickerField,
  Comment,
  AccentButton,
} from '../../component';
import { rxformValidateSession } from '../../validation';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_SWITCH,
  FILTER_TYPE_DATE_RANGE, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  PAGE_MODE_VIEW,
  PICKER_MODE_DATE_TIME, RXFIELD_SESSION_NAME,
  RXFIELD_SESSION_START_TIME, RXFIELD_SESSION_END_TIME,
  RXFIELD_SESSION_EARLIEST_CLOCK, RXFIELD_SESSION_MAX_CLOCK,
  RXFIELD_SESSION_FEATURED, RXFIELD_SESSION_FEATURED_ORDER,
  RXFIELD_SESSION_VISIBILITY_STATUS, RXFIELD_SESSION_REGISTRATION_REWARD_TYPE,
  RXFIELD_SESSION_REGISTRATION_REWARD_POINT,
  RXFIELD_SESSION_EVENT_NAME, RXFIELD_SESSION_ROOM_NAME, RXFIELD_SESSION_CATEGORY,
  RXFIELD_SESSION_DESCRIPTION, RXFIELD_SESSION_SHORT_DESCRIPTION,
  RXFIELD_SESSION_CLOCK_INSIDE_AREA, RXFIELD_SESSION_WHO_CAN_REGISTER,
  RXFIELD_SESSION_NEED_APPROVAL, RXFIELD_SESSION_QUOTA,
  RXFIELD_SESSION_PRICE, RXFIELD_SESSION_CAN_PAY_USING_POINT,
  RXFIELD_SESSION_REGISTRATION_REWARD_VOUCHERS,
  RXFORM_SESSION, RXFIELD_SESSION_STATUS, RXFIELD_SESSION_MEDIA,
  RXSTATE_SESSIONS, RXSTATE_SESSION_PAGE, RXFIELD_SESSION_DEEP_LINK,
  status, visibilityStatuses,
  SESSION_REGISTRATION_REWARD_TYPE_DATA,
  SESSION_WHO_CAN_REGISTER_DATA, REST_BASE_URL, REST_URL_IMAGE_STORAGE,
  IMAGE_SOURCE_URI_PREFIX,
  approval, SESSION_REWARD_TYPE_VOUCHER, SESSION_REWARD_TYPE_POINT,
  SESSION_REWARD_TYPE_BOTH,
  RXFIELD_SESSION_SPEAKERS,
  RXFIELD_SESSION_NORMAL_PRICE,
  RXFIELD_SESSION_TYPE,
  SESSION_TYPE,
  RXFIELD_SESSION_SURVEY_FORM_ID,
  ADDITIONAL_ITEMS_VISIBILITY_TIMES,
  ADDITIONAL_ITEMS_VIEW_ACCESSES,
  ADDITIONAL_ITEMS_TYPES,
  ADDITIONAL_ITEM_TYPE_IMAGE,
  RXFIELD_SESSION_ADDITTIONAL_ITEMS,
  RXFIELD_SESSION_ENABLE_COMMENT,
  RXFIELD_SESSION_COMMENT_ACCESS,
  RXFIELD_SESSION_DISCUSSION_ID,
  COMMENT_ACCESSES,
  RXFIELD_SESSION_CLOCK_IN_QR_CODE,
  PAGE_MODE_TABLE,
  COLOR_PRIMARY,
  snackbarDuration,
  STATUS_ENABLED, STATUS_DISABLED, REST_BASE_URL_SESSION, FILTER_TYPE_TEXT, RXFIELD_SESSION_BANNER,
  IMAGE_FILE_EXTENSIONS,
} from '../../constant';
import {
  getAdditionalItemFileExtension, getAdditionalItemIcon, isAdditionalItemYoutubeUrlType, toMoment,
} from '../../helper';

const useStyles = makeStyles(() => ({
  dialogHeader: {
    marginBottom: 20,
  },
  buttonContainer: {
    margin: '10px 0px 10px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  italicButton: {
    fontStyle: 'italic',
    color: COLOR_PRIMARY,
    marginRight: 10,
  },
}));

const renderDialogContent = (initialValues, downloadingDeleting, addingEditing,
  pageMode, onDeleteAdditionalItemsPressed, onAddAdditionalItemsPressed,
  sessionRooms, loadingSessionRooms, onAddMediaMenuSelected, selectedAddMediaMenu,
  onAddMediaPressed, onDeleteMediaPressed, onCopyPressed, copying,
  vouchers, loadingVoucher, onChangeVoucherText, onCopyDeepLinkPressed, copyingDeepLink,
  onVoucherOptionSelected, onIsFeaturedOptionSelected, onVisibilityStatusOptionSelected,
  onRewardTypeOptionSelected, onChangeEventNameText, onEventNameOptionSelected,
  onChangeRoomOptionSelected, sessionCategories, loadingSessionCategory,
  onChangeCategoryNameText, onCategoryNameOptionSelected, onClockInsideAreaOptionSelected,
  onWhoCanRegisterOptionSelected, onNeedApprovalOptionSelected, onCanPayPointOptionSelected,
  valueRewardType, onChangeDescriptionText, formSelectedRewardType, events, loadingEvent,
  onAddSpeakerPressed, loadingSpeaker, speaker,
  tableDataSpeakers, onAddSpeakersPressed, onChangeSpeakersText, onDeleteSpeakersPressed,
  onSpeakersOptionSelected, selectedSpeakers, tappedSession, formSelectedIsFeatured,
  addingSessionSpeaker, voucherValues, mediaValues,
  onTypeOptionSelected, surveyFormData, loadingSurveyForm, onChangeSurveyFormText,
  onChangeSurveyFormOptionSelected, typeValues,
  onEnableCommentOptionSelected, onCommentAccessOptionSelected, selectedEnableComment,
  discusId, onBannerImageSelected, selectedBannerImage) => {
  const rewardTypePoin = formSelectedRewardType === SESSION_REWARD_TYPE_POINT;
  const initialRewardTypePoin = initialValues.rewardType === SESSION_REWARD_TYPE_POINT;
  const rewardTypeVoucher = formSelectedRewardType === SESSION_REWARD_TYPE_VOUCHER;
  const initialRewardTypeVoucher = initialValues.rewardType === SESSION_REWARD_TYPE_VOUCHER;
  const rewardTypeBoth = formSelectedRewardType === SESSION_REWARD_TYPE_BOTH;
  const initialRewardTypeBoth = initialValues.rewardType === SESSION_REWARD_TYPE_BOTH;
  const isTypeFreetime = typeValues === SESSION_TYPE[1];

  return (
    <Grid>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderName}
              label={LocalizedString.sessionPage.placeholderName}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_SHORT_DESCRIPTION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderShortDescription}
              label={LocalizedString.sessionPage.placeholderShortDescription}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_TYPE}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.sessionPage.placeholderType}
              label={LocalizedString.sessionPage.placeholderType}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              data={SESSION_TYPE}
              value={initialValues.type}
              onOptionSelected={onTypeOptionSelected}
              onBlur={(e) => e.preventDefault()}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_START_TIME}
              component={renderReduxFormDateTimePickerField}
              pickerMode={PICKER_MODE_DATE_TIME}
              placeholder={LocalizedString.sessionPage.placeholderStartTime}
              label={LocalizedString.sessionPage.placeholderStartTime}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_END_TIME}
              component={renderReduxFormDateTimePickerField}
              pickerMode={PICKER_MODE_DATE_TIME}
              placeholder={LocalizedString.sessionPage.placeholderEndTime}
              label={LocalizedString.sessionPage.placeholderEndTime}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_FEATURED}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.sessionPage.placeholderFeatured}
              label={LocalizedString.sessionPage.placeholderFeatured}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              data={approval}
              value={initialValues.isFeatured}
              onOptionSelected={onIsFeaturedOptionSelected}
              onBlur={(e) => e.preventDefault()}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_FEATURED_ORDER}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderFeaturedOrder}
              label={LocalizedString.sessionPage.placeholderFeaturedOrder}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              hidden={!formSelectedIsFeatured?.value}
              type="number"
              required={formSelectedIsFeatured?.value}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_VISIBILITY_STATUS}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.sessionPage.placeholderVisibilityStatus}
              label={LocalizedString.sessionPage.placeholderVisibilityStatus}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              data={visibilityStatuses}
              value={initialValues.visibilityStatus}
              onOptionSelected={onVisibilityStatusOptionSelected}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_REGISTRATION_REWARD_TYPE}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.sessionPage.placeholderRegistrationRewardType}
              label={LocalizedString.sessionPage.placeholderRegistrationRewardType}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              data={SESSION_REGISTRATION_REWARD_TYPE_DATA}
              value={initialValues.registrationRewardType}
              onOptionSelected={onRewardTypeOptionSelected}
              hidden={isTypeFreetime}
              required={!isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_REGISTRATION_REWARD_POINT}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderRegistrationRewardPoint}
              label={LocalizedString.sessionPage.placeholderRegistrationRewardPoint}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              type="number"
              hidden={!rewardTypePoin && !initialRewardTypePoin
                && !rewardTypeBoth && !initialRewardTypeBoth}
              required={rewardTypePoin || rewardTypeBoth}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_REGISTRATION_REWARD_VOUCHERS}
              component={renderReduxFormAutocompleteCheckboxField}
              placeholder={LocalizedString.sessionPage.placeholderRegistrationRewardVouchers}
              label={`${LocalizedString.sessionPage.placeholderRegistrationRewardVouchers} ${voucherValues.length === 0 ? '' : ' *'}`}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              data={vouchers}
              loading={loadingVoucher}
              onOptionSelected={onVoucherOptionSelected}
              onBlur={(e) => e.preventDefault()}
              onChangeText={onChangeVoucherText}
              value={initialValues.registrationRewardVouchers}
              hidden={!rewardTypeVoucher && !initialRewardTypeVoucher
                && !rewardTypeBoth && !initialRewardTypeBoth}
              required={voucherValues.length === 0 ? rewardTypeVoucher || rewardTypeBoth : false}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_SURVEY_FORM_ID}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.sessionPage.placeholderSurveyForm}
              label={LocalizedString.sessionPage.placeholderSurveyForm}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              data={surveyFormData}
              value={initialValues.surveyFormId}
              loading={loadingSurveyForm}
              onChangeText={onChangeSurveyFormText}
              onOptionSelected={onChangeSurveyFormOptionSelected}
              hidden={isTypeFreetime}
              required={!isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_ENABLE_COMMENT}
              component={renderReduxFormSimpleDropdownField}
              label={LocalizedString.sessionPage.placeholderEnableComment}
              placeholder={LocalizedString.sessionPage.placeholderEnableComment}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={approval}
              value={initialValues.enableComment}
              onOptionSelected={onEnableCommentOptionSelected}
              required
              onBlur={(e) => e.preventDefault()}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_COMMENT_ACCESS}
              component={renderReduxFormSimpleDropdownField}
              label={LocalizedString.sessionPage.placeholderCommentAccess}
              placeholder={LocalizedString.sessionPage.placeholderCommentAccess}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={COMMENT_ACCESSES}
              onOptionSelected={onCommentAccessOptionSelected}
              required={selectedEnableComment?.value === approval[0].value}
              hidden={selectedEnableComment?.value === approval[1].value}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_DISCUSSION_ID}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.sessionPage.placeholderDiscussionId}
              placeholder={LocalizedString.sessionPage.placeholderDiscussionId}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              hidden={pageMode !== PAGE_MODE_VIEW}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_CLOCK_IN_QR_CODE}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.sessionPage.placeholderClockInQrCode}
              placeholder={LocalizedString.sessionPage.placeholderClockInQrCode}
              disabled
              hidden={pageMode === PAGE_MODE_TABLE}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => onCopyPressed(copying)} edge="end">
                      <CopyToClipboard text={initialValues.clockInQrCode}>
                        <FileCopyOutlined style={{ fill: COLOR_PRIMARY }} />
                      </CopyToClipboard>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_DEEP_LINK}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderDeepLink}
              label={LocalizedString.sessionPage.placeholderDeepLink}
              disabled
              hidden={pageMode !== PAGE_MODE_VIEW}
              multiline
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => onCopyDeepLinkPressed(copyingDeepLink)} edge="end">
                      <CopyToClipboard text={initialValues.deepLink}>
                        <FileCopyOutlined style={{ fill: COLOR_PRIMARY }} />
                      </CopyToClipboard>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_EVENT_NAME}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.sessionPage.placeholderEvent}
              label={LocalizedString.sessionPage.placeholderEvent}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              data={events}
              value={initialValues.eventName}
              loading={loadingEvent}
              onChangeText={onChangeEventNameText}
              onOptionSelected={onEventNameOptionSelected}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_ROOM_NAME}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.sessionPage.placeholderRoom}
              label={LocalizedString.sessionPage.placeholderRoom}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              data={sessionRooms}
              value={initialValues.rooms}
              loading={loadingSessionRooms}
              onOptionSelected={onChangeRoomOptionSelected}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_CATEGORY}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.sessionPage.placeholderCategory}
              label={LocalizedString.sessionPage.placeholderCategory}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              data={sessionCategories}
              value={initialValues.categoryName}
              loading={loadingSessionCategory}
              onChangeText={onChangeCategoryNameText}
              onOptionSelected={onCategoryNameOptionSelected}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_CLOCK_INSIDE_AREA}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.sessionPage.placeholderClockInsideArea}
              label={LocalizedString.sessionPage.placeholderClockInsideArea}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              data={approval}
              value={initialValues.clockInMustBeInsideArea}
              onOptionSelected={onClockInsideAreaOptionSelected}
              onBlur={(e) => e.preventDefault()}
              hidden={isTypeFreetime}
              required={!isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_EARLIEST_CLOCK}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderEarliestClockIn}
              label={LocalizedString.sessionPage.placeholderEarliestClockIn}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              type="number"
              hidden={isTypeFreetime}
              required={!isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_MAX_CLOCK}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderMaxClockIn}
              label={LocalizedString.sessionPage.placeholderMaxClockIn}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              type="number"
              hidden={isTypeFreetime}
              required={!isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_WHO_CAN_REGISTER}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.sessionPage.placeholderWhoCanRegister}
              label={LocalizedString.sessionPage.placeholderWhoCanRegister}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              data={SESSION_WHO_CAN_REGISTER_DATA}
              value={initialValues.whoCanRegister}
              onOptionSelected={onWhoCanRegisterOptionSelected}
              hidden={isTypeFreetime}
              required={!isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_NEED_APPROVAL}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.sessionPage.placeholderNeedsApproval}
              label={LocalizedString.sessionPage.placeholderNeedsApproval}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              data={approval}
              value={initialValues.needApproval}
              onOptionSelected={onNeedApprovalOptionSelected}
              onBlur={(e) => e.preventDefault()}
              hidden={isTypeFreetime}
              required={!isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_QUOTA}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderQuota}
              label={LocalizedString.sessionPage.placeholderQuota}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              type="number"
              hidden={isTypeFreetime}
              required={!isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_NORMAL_PRICE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderNormalPrice}
              label={LocalizedString.sessionPage.placeholderNormalPrice}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              type="number"
              hidden={isTypeFreetime}
              required={!isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_PRICE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.sessionPage.placeholderPrice}
              label={LocalizedString.sessionPage.placeholderPrice}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              type="number"
              hidden={isTypeFreetime}
              required={!isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_CAN_PAY_USING_POINT}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.sessionPage.placeholderCanPayUsingPoint}
              label={LocalizedString.sessionPage.placeholderCanPayUsingPoint}
              disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
              data={approval}
              value={initialValues.canPayUsingPoint}
              onOptionSelected={onCanPayPointOptionSelected}
              onBlur={(e) => e.preventDefault()}
              hidden={isTypeFreetime}
              required={!isTypeFreetime}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_BANNER}
              component={renderReduxFormDropzonePicker}
              label={LocalizedString.sessionPage.placeholderBanner}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              loading={downloadingDeleting || addingEditing}
              onImageSelected={onBannerImageSelected}
              onBlur={(e) => e.preventDefault()}
              defaultValue={initialValues.banner}
              result={selectedBannerImage}
              imageFileExtension={IMAGE_FILE_EXTENSIONS}
            />
          </Grid>
        </Grid>
      </Grid>

      {!downloadingDeleting && (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_SESSION_DESCRIPTION}
            component={renderReduxFormRichTextEditorField}
            label={LocalizedString.sessionPage.placeholderDescription}
            disabled={downloadingDeleting || addingEditing || pageMode === PAGE_MODE_VIEW}
            onChangeText={onChangeDescriptionText}
            loading={addingEditing}
          />
        </Grid>
      )}

      <SectionTitle title={LocalizedString.sessionPage.labelMedia} />
      {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_SESSION_MEDIA}
            component={renderReduxFormEditableTableField}
            label={LocalizedString.sessionPage.labelMedia}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            loading={downloadingDeleting}
            onAddPressed={onAddMediaPressed}
            onDeletePressed={onDeleteMediaPressed}
            defaultValue={mediaValues}
            addMenuList={[
              {
                caption: LocalizedString.sessionPage.buttonCaptionYoutube,
                onPress: () => onAddMediaMenuSelected('Youtube'),
              },
              {
                caption: LocalizedString.sessionPage.buttonCaptionImage,
                onPress: () => onAddMediaMenuSelected('Image'),
              },
            ]}
            requiredTableColumnField={['order']}
            optionalTableColumnField={['content', 'link']}
            disableEdit
            disableToolbar={pageMode === PAGE_MODE_VIEW}
            tableColumns={[
              {
                title: LocalizedString.sessionPage.labelOrder,
                field: 'order',
                sorting: false,
                editable: 'onAdd',
                // eslint-disable-next-line react/prop-types
                editComponent: ({ value, onChange }) => (
                  <NumberTextFieldWithoutArrowDial
                    placeholder={LocalizedString.sessionPage.labelOrder}
                    label={LocalizedString.sessionPage.labelOrder}
                    variant="outlined"
                    type="number"
                    defaultValue={value}
                    onChange={(e) => onChange(e.target.value)}
                    fullWidth
                  />
                ),
              },
              {
                title: LocalizedString.sessionPage.labelYoutube,
                field: 'link',
                sorting: false,
                editable: selectedAddMediaMenu === 'Youtube' ? 'onAdd' : 'never',
                render: (prop) => {
                  if (prop) {
                    if (prop.path) {
                      if (prop.type === 'Youtube' && !prop.tableData.editing) {
                        const path = prop.path.includes(`${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}`) ? prop.path.slice(68) : prop.path;
                        return path;
                      }
                      if ((prop.type === 'Youtube' || Object.prototype.hasOwnProperty.call(prop, 'path')) && prop.tableData.editing) {
                        onAddMediaMenuSelected('Youtube');
                      }
                    }
                    if (prop.link) {
                      return prop.link.path;
                    }
                    return null;
                  }
                  return null;
                },
                // eslint-disable-next-line react/prop-types
                editComponent: ({ value, onChange }) => (
                  <TextField
                    placeholder={LocalizedString.sessionPage.labelYoutube}
                    label={LocalizedString.sessionPage.labelYoutube}
                    variant="outlined"
                    defaultValue={value}
                    onChange={(e) => onChange({ path: e.target.value, type: 'Youtube' })}
                    fullWidth
                  />
                ),
              },
              {
                title: LocalizedString.sessionPage.labelImage,
                field: 'content',
                sorting: false,
                editable: selectedAddMediaMenu === 'Image' ? 'onAdd' : 'never',
                render: (prop) => {
                  if (prop) {
                    if (prop.path) {
                      if (prop.type === 'Image' && !prop.tableData.editing) {
                        return (<Avatar variant="square" src={prop.path} />);
                      }
                      if ((prop.type === 'Image' || Object.prototype.hasOwnProperty.call(prop, 'path')) && prop.tableData.editing) {
                        onAddMediaMenuSelected('Image');
                      }
                    }
                    if (prop.content) {
                      return (
                        <Avatar
                          variant="square"
                          src={IMAGE_SOURCE_URI_PREFIX.concat(prop.content.path)}
                        />
                      );
                    }
                    return null;
                  }
                  return null;
                },
                // eslint-disable-next-line react/prop-types
                editComponent: ({ onChange }) => (
                  <ImageInputField
                    useCropper={false}
                    onImageSelected={(path) => onChange({ path, type: 'Image' })}
                  />
                ),
              },
            ]}
            required
          />
        </Grid>
      )}

      <SectionTitle title={LocalizedString.sessionPage.labelSpeakers} />
      {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_SESSION_SPEAKERS}
            component={renderReduxFormAutocompleteTableField}
            label={LocalizedString.sessionPage.placeholderSpeaker}
            currentTableData={tableDataSpeakers}
            defaultValue={initialValues.speakers}
            options={speaker}
            adding={addingSessionSpeaker}
            disableAdd={pageMode === PAGE_MODE_VIEW}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            loadingOptions={loadingSpeaker}
            onAddPressed={(defaultValue, newInitialValue, selectedOption,
              currentData) => onAddSpeakersPressed(defaultValue,
              newInitialValue, selectedOption, currentData)}
            onChangeOptionText={(text) => onChangeSpeakersText(text)}
            onDeletePressed={(newValue, currentData) => onDeleteSpeakersPressed(
              newValue, currentData,
            )}
            onOptionSelected={onSpeakersOptionSelected}
            selectedOption={selectedSpeakers}
            optionPlaceholder={LocalizedString.sessionPage.placeholderSpeaker}
            currentTappedData={tappedSession}
            onBlur={(e) => e.preventDefault()}
            required
          />
        </Grid>
      )}

      <SectionTitle title={LocalizedString.sessionPage.labelAdditionalItems} />
      {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_SESSION_ADDITTIONAL_ITEMS}
            component={renderReduxFormEditableTableField}
            label={LocalizedString.sessionPage.labelAdditionalItems}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            onAddPressed={onAddAdditionalItemsPressed}
            onDeletePressed={onDeleteAdditionalItemsPressed}
            defaultValue={initialValues.additionalItems}
            requiredTableColumnField={['order', 'label', 'type', 'viewAccess', 'visibilityTime']}
            optionalTableColumnField={['content', 'url']}
            disableToolbar={pageMode === PAGE_MODE_VIEW}
            tableColumns={[
              {
                title: LocalizedString.sessionPage.labelOrder, field: 'order', sorting: false, type: 'numeric',
              },
              { title: LocalizedString.sessionPage.labelLabel, field: 'label', sorting: false },
              {
                title: LocalizedString.sessionPage.labelType, field: 'type', sorting: false, lookup: ADDITIONAL_ITEMS_TYPES,
              },
              {
                title: LocalizedString.sessionPage.labelViewAccess, field: 'viewAccess', sorting: false, lookup: ADDITIONAL_ITEMS_VIEW_ACCESSES,
              },
              {
                title: LocalizedString.sessionPage.labelVisibilityTime, field: 'visibilityTime', sorting: false, lookup: ADDITIONAL_ITEMS_VISIBILITY_TIMES,
              },
              {
                title: LocalizedString.sessionPage.labelContent,
                field: 'content',
                sorting: false,
                render: (prop) => {
                  if (prop) {
                    if (!isAdditionalItemYoutubeUrlType(prop)) {
                      if (prop.type === ADDITIONAL_ITEM_TYPE_IMAGE) {
                        if (prop.path) {
                          return (<Avatar variant="square" src={prop.path} />);
                        }
                        if (prop.content) {
                          return (
                            <Avatar
                              variant="square"
                              src={IMAGE_SOURCE_URI_PREFIX.concat(prop.content.content)}
                            />
                          );
                        }
                      } else {
                        const { icon: Icon, color } = getAdditionalItemIcon(prop);
                        return (<Icon style={{ color, width: 40, height: 40 }} />);
                      }
                    }
                    return null;
                  }
                  return null;
                },
                editComponent: (props) => {
                  const { onChange, rowData } = props;
                  if (rowData.type && !isAdditionalItemYoutubeUrlType(rowData)) {
                    return (
                      <FilePickerField
                        acceptedFileExtension={getAdditionalItemFileExtension(rowData)}
                        onFileSelected={(url) => onChange({ content: url })}
                        disabled={isAdditionalItemYoutubeUrlType(rowData)}
                      />
                    );
                  }
                  return null;
                },
              },
              {
                title: LocalizedString.sessionPage.labelUrl,
                field: 'url',
                sorting: false,
                render: (prop) => {
                  if (prop) {
                    if (isAdditionalItemYoutubeUrlType(prop)) {
                      return prop?.url || prop?.path || null;
                    }
                    return null;
                  }
                  return null;
                },
                editComponent: (props) => {
                  const { onChange, rowData } = props;
                  if (rowData.type && isAdditionalItemYoutubeUrlType(rowData)) {
                    return (
                      <TextField
                        onChange={(e) => onChange(e.target.value)}
                        defaultValue={rowData?.url || rowData?.path || null}
                      />
                    );
                  }
                  return null;
                },
              },
            ]}
          />
        </Grid>
      )}
      {pageMode !== PAGE_MODE_TABLE && (
        <Comment discusId={discusId} path={REST_BASE_URL_SESSION} />
      )}
      <Snackbar
        open={copying}
        autoHideDuration={snackbarDuration}
        message={LocalizedString.sessionPage.labelCopied}
        onClose={() => onCopyPressed(copying)}
      />
      <Snackbar
        open={copyingDeepLink}
        autoHideDuration={snackbarDuration}
        message={LocalizedString.sessionPage.labelCopied}
        onClose={() => onCopyDeepLinkPressed(copyingDeepLink)}
      />
    </Grid>
  );
};

const SessionPage = ({
  initialValues, addingEditing, downloading, downloadingDeleting,
  handleSubmit, onAppear, onApplyAdvancedFilterPressed, onCancelPressed,
  onChangePage, onChangePageSize, onConfirmContextMenuPressed, onCreatePressed,
  onEditPressed, onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged,
  onSortPressed, onSubmitPressed, onViewPressed, rooms, loadingRooms,
  pageMode, onDeletePressed, onSavePressed, onAdvancedFilterPressed, onChangeRoomText,
  onAddMediaMenuSelected, selectedAddMediaMenu, onAddMediaPressed,
  onDeleteMediaPressed, events, loadingEvent, onCopyPressed, copying,
  vouchers, loadingVoucher, onChangeVoucherText, onCopyDeepLinkPressed, copyingDeepLink,
  onVoucherOptionSelected, onIsFeaturedOptionSelected, onVisibilityStatusOptionSelected,
  onRewardTypeOptionSelected, onChangeEventNameText, onEventNameOptionSelected,
  onChangeRoomOptionSelected, sessionCategories, loadingSessionCategory,
  onChangeCategoryNameText, onCategoryNameOptionSelected, onClockInsideAreaOptionSelected,
  onWhoCanRegisterOptionSelected, onNeedApprovalOptionSelected, onCanPayPointOptionSelected,
  valueRewardType, onChangeDescriptionText, formSelectedRewardType,
  onAddSpeakerPressed, loadingSpeaker, speaker,
  tableDataSpeakers, onAddSpeakersPressed, onChangeSpeakersText, onDeleteSpeakersPressed,
  onSpeakersOptionSelected, selectedSpeakers, tappedSession, formSelectedIsFeatured,
  addingSessionSpeaker, voucherValues, mediaValues,
  onTypeOptionSelected, surveyFormData, loadingSurveyForm, onChangeSurveyFormText,
  onChangeSurveyFormOptionSelected, typeValues,
  onDeleteAdditionalItemsPressed, onAddAdditionalItemsPressed,
  onEnableCommentOptionSelected, onCommentAccessOptionSelected, selectedEnableComment,
  currentSessionStatus, enablingDisabling, onContextMenuPressed, tappedId, discusId,
  onSendToMePressed, sendingNotification, sendingNotifications, sessionRooms, loadingSessionRooms,
  onBannerImageSelected, selectedBannerImage,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_SESSIONS}
      uiPage={RXSTATE_SESSION_PAGE}
      moreMenus={[
        {
          caption: GlobalLocalizedString.common.buttonCaptionEnable,
          disabled: currentSessionStatus === STATUS_ENABLED || enablingDisabling,
          onPress: () => onContextMenuPressed(tappedId,
            GlobalLocalizedString.common.msgEnableConfirmation),
        },
        {
          caption: GlobalLocalizedString.common.buttonCaptionDisable,
          disabled: currentSessionStatus === STATUS_DISABLED || enablingDisabling,
          onPress: () => onContextMenuPressed(tappedId,
            GlobalLocalizedString.common.msgDisableConfirmation),
        },
      ]}
      tableColumns={[
        {
          title: GlobalLocalizedString.common.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.sessionPage.labelName, field: 'name', sorting: !downloading },
        { title: LocalizedString.sessionPage.labelEventName, field: 'event.name', sorting: !downloading },
        {
          title: LocalizedString.sessionPage.labelStartTime,
          field: 'startTime',
          sorting: !downloading,
          render: ({ startTime }) => (startTime
            ? toMoment(startTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        {
          title: LocalizedString.sessionPage.labelEndTime,
          field: 'endTime',
          sorting: !downloading,
          render: ({ endTime }) => (endTime
            ? toMoment(endTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        { title: LocalizedString.sessionPage.labelRoom, field: 'room.name', sorting: !downloading },
        { title: LocalizedString.sessionPage.labelQuota, field: 'quota', sorting: !downloading },
        {
          title: LocalizedString.sessionPage.labelCreatedDate,
          field: 'createdDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
      ]}
      filterColumns={[
        {
          title: LocalizedString.sessionPage.labelEvent,
          field: 'event',
          type: FILTER_TYPE_DROPDOWN,
          data: events,
          loading: loadingEvent,
          onChangeFilterText: (text) => onChangeEventNameText(text, true),
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sessionPage.placeholderCategory,
          field: RXFIELD_SESSION_CATEGORY,
          type: FILTER_TYPE_DROPDOWN,
          data: sessionCategories,
          loading: loadingSessionCategory,
          onChangeFilterText: (text) => onChangeCategoryNameText(text, true),
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sessionPage.labelType,
          field: RXFIELD_SESSION_TYPE,
          type: FILTER_TYPE_DROPDOWN,
          data: SESSION_TYPE,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sessionPage.labelRoom,
          field: RXFIELD_SESSION_ROOM_NAME,
          type: FILTER_TYPE_DROPDOWN,
          data: rooms,
          loading: loadingRooms,
          onChangeFilterText: (text) => onChangeRoomText(text, true),
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sessionPage.labelStartTime,
          field: RXFIELD_SESSION_START_TIME,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
          useTimeZone: true,
        },
        {
          title: LocalizedString.sessionPage.labelEndTime,
          field: RXFIELD_SESSION_END_TIME,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
          useTimeZone: true,
        },
        {
          title: LocalizedString.sessionPage.labelWhoCanRegister,
          field: RXFIELD_SESSION_WHO_CAN_REGISTER,
          type: FILTER_TYPE_DROPDOWN,
          data: SESSION_WHO_CAN_REGISTER_DATA,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sessionPage.labelClockInsideArea,
          field: RXFIELD_SESSION_CLOCK_INSIDE_AREA,
          type: FILTER_TYPE_SWITCH,
          data: approval,
        },
        {
          title: LocalizedString.sessionPage.labelNeedsApproval,
          field: RXFIELD_SESSION_NEED_APPROVAL,
          type: FILTER_TYPE_SWITCH,
          data: approval,
        },
        {
          title: LocalizedString.sessionPage.labelFeatured,
          field: RXFIELD_SESSION_FEATURED,
          type: FILTER_TYPE_SWITCH,
          data: approval,
        },
        {
          title: LocalizedString.sessionPage.labelCanPayUsingPoint,
          field: RXFIELD_SESSION_CAN_PAY_USING_POINT,
          type: FILTER_TYPE_SWITCH,
          data: approval,
        },
        {
          title: LocalizedString.sessionPage.placeholderRegistrationRewardType,
          field: RXFIELD_SESSION_REGISTRATION_REWARD_TYPE,
          type: FILTER_TYPE_DROPDOWN,
          data: SESSION_REGISTRATION_REWARD_TYPE_DATA,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sessionPage.placeholderEnableComment,
          field: 'enableComment',
          type: FILTER_TYPE_SWITCH,
        },
        {
          title: LocalizedString.sessionPage.placeholderCommentAccess,
          field: 'commentAccess',
          type: FILTER_TYPE_DROPDOWN,
          data: COMMENT_ACCESSES,
        },
        {
          title: LocalizedString.sessionPage.placeholderDiscussionId,
          field: 'discussionId',
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.sessionPage.labelVisibilityStatus,
          field: RXFIELD_SESSION_VISIBILITY_STATUS,
          type: FILTER_TYPE_DROPDOWN,
          data: visibilityStatuses,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sessionPage.placeholderSurveyForm,
          field: RXFIELD_SESSION_SURVEY_FORM_ID,
          type: FILTER_TYPE_DROPDOWN,
          data: surveyFormData,
          loading: loadingSurveyForm,
          onChangeFilterText: (text) => onChangeSurveyFormText(text, true),
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sessionPage.labelStatus,
          field: RXFIELD_SESSION_STATUS,
          type: FILTER_TYPE_DROPDOWN,
          data: status,
          useDropdownValue: true,
        },
      ]}
      handleSubmit={handleSubmit}
      onAppear={onAppear}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmContextMenuPressed}
      onCreatePressed={onCreatePressed}
      onEditPressed={onEditPressed}
      onRefresh={onRefresh}
      onSavePressed={onSavePressed}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSendToMePressed={onSendToMePressed}
      onSortPressed={onSortPressed}
      onSubmitPressed={onSubmitPressed}
      onViewPressed={onViewPressed}
      createNewButtonCaption={LocalizedString.sessionPage.buttonCaptionCreateNewSession}
      deleteButtonCaption={LocalizedString.sessionPage.buttonCaptionDeleteSession}
      editButtonCaption={LocalizedString.sessionPage.buttonCaptionEditSession}
      title={LocalizedString.sessionPage.title}
      addDialogHeaderContainerStyle={classes.dialogHeader}
      onDeletePressed={onDeletePressed}
      useFullWidth
      useFullWidthDialog
      createPermissionName="EVENT_CREATE_SESSION"
      deletePermissionName="EVENT_DELETE_SESSION"
      editPermissionName="EVENT_EDIT_SESSION"
    >
      {pageMode === PAGE_MODE_VIEW && (
        <div className={classes.buttonContainer}>
          <AccentButton
            variant="text"
            className={classes.italicButton}
            disabled={downloadingDeleting || addingEditing || sendingNotifications}
            loading={sendingNotification}
            disableElevation
            caption={LocalizedString.eventPage.buttonCaptionSendToMe}
            onClick={onSendToMePressed}
            size="small"
          />
        </div>
      )}
      {renderDialogContent(initialValues, downloadingDeleting, addingEditing,
        pageMode, onDeleteAdditionalItemsPressed, onAddAdditionalItemsPressed,
        sessionRooms, loadingSessionRooms, onAddMediaMenuSelected, selectedAddMediaMenu,
        onAddMediaPressed, onDeleteMediaPressed, onCopyPressed, copying,
        vouchers, loadingVoucher, onChangeVoucherText, onCopyDeepLinkPressed, copyingDeepLink,
        onVoucherOptionSelected, onIsFeaturedOptionSelected, onVisibilityStatusOptionSelected,
        onRewardTypeOptionSelected, onChangeEventNameText, onEventNameOptionSelected,
        onChangeRoomOptionSelected, sessionCategories, loadingSessionCategory,
        onChangeCategoryNameText, onCategoryNameOptionSelected, onClockInsideAreaOptionSelected,
        onWhoCanRegisterOptionSelected, onNeedApprovalOptionSelected, onCanPayPointOptionSelected,
        valueRewardType, onChangeDescriptionText, formSelectedRewardType, events, loadingEvent,
        onAddSpeakerPressed, loadingSpeaker, speaker,
        tableDataSpeakers, onAddSpeakersPressed, onChangeSpeakersText, onDeleteSpeakersPressed,
        onSpeakersOptionSelected, selectedSpeakers, tappedSession, formSelectedIsFeatured,
        addingSessionSpeaker, voucherValues, mediaValues, onTypeOptionSelected,
        surveyFormData, loadingSurveyForm, onChangeSurveyFormText,
        onChangeSurveyFormOptionSelected, typeValues,
        onEnableCommentOptionSelected, onCommentAccessOptionSelected, selectedEnableComment,
        discusId, onBannerImageSelected, selectedBannerImage)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_SESSION,
  validate: rxformValidateSession,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SessionPage);

SessionPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  rooms: PropTypes.arrayOf(SimpleDataShape).isRequired,
  sessionRooms: PropTypes.arrayOf(SimpleDataShape).isRequired,
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  sessionCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  vouchers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  speaker: PropTypes.arrayOf(PropTypes.object).isRequired,
  surveyFormData: PropTypes.arrayOf(PropTypes.object).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  formSelectedRewardType: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  formSelectedIsFeatured: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  mediaValues: PropTypes.arrayOf(SimpleDataShape),
  copying: PropTypes.bool.isRequired,
  copyingDeepLink: PropTypes.bool.isRequired,
  loadingEvent: PropTypes.bool.isRequired,
  loadingSessionCategory: PropTypes.bool.isRequired,
  loadingRooms: PropTypes.bool.isRequired,
  loadingSessionRooms: PropTypes.bool.isRequired,
  loadingSpeaker: PropTypes.bool.isRequired,
  loadingVoucher: PropTypes.bool.isRequired,
  loadingSurveyForm: PropTypes.bool.isRequired,
  pageMode: PropTypes.string.isRequired,
  onAppear: PropTypes.func.isRequired,
  onAddMediaPressed: PropTypes.func.isRequired,
  onAddSpeakerPressed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onAddAdditionalItemsPressed: PropTypes.func.isRequired,
  onAddMediaMenuSelected: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onCanPayPointOptionSelected: PropTypes.func.isRequired,
  onCategoryNameOptionSelected: PropTypes.func.isRequired,
  onChangeCategoryNameText: PropTypes.func.isRequired,
  onChangeDescriptionText: PropTypes.func.isRequired,
  onChangeEventNameText: PropTypes.func.isRequired,
  onChangeRoomOptionSelected: PropTypes.func.isRequired,
  onChangeRoomText: PropTypes.func.isRequired,
  onChangeSurveyFormOptionSelected: PropTypes.func.isRequired,
  onChangeSurveyFormText: PropTypes.func.isRequired,
  onChangeVoucherText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onCopyPressed: PropTypes.func.isRequired,
  onCopyDeepLinkPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onClockInsideAreaOptionSelected: PropTypes.func.isRequired,
  onCommentAccessOptionSelected: PropTypes.func.isRequired,
  onDeleteAdditionalItemsPressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onDeleteMediaPressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onEnableCommentOptionSelected: PropTypes.func.isRequired,
  onEventNameOptionSelected: PropTypes.func.isRequired,
  onIsFeaturedOptionSelected: PropTypes.func.isRequired,
  onNeedApprovalOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onRewardTypeOptionSelected: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onTypeOptionSelected: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onVoucherOptionSelected: PropTypes.func.isRequired,
  onVisibilityStatusOptionSelected: PropTypes.func.isRequired,
  onWhoCanRegisterOptionSelected: PropTypes.func.isRequired,
  selectedAddMediaMenu: PropTypes.string,
  valueRewardType: PropTypes.string,
  onAddSpeakersPressed: PropTypes.func.isRequired,
  onChangeSpeakersText: PropTypes.func.isRequired,
  onDeleteSpeakersPressed: PropTypes.func.isRequired,
  onSpeakersOptionSelected: PropTypes.func.isRequired,
  addingSessionSpeaker: PropTypes.bool.isRequired,
  selectedSpeakers: PropTypes.oneOfType([SimpleDataShape, PropTypes.string]),
  tappedSession: FormInitialValueShape,
  tableDataSpeakers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  voucherValues: PropTypes.arrayOf(SimpleDataShape).isRequired,
  typeValues: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  selectedEnableComment: PropTypes.oneOfType([SimpleDataShape, PropTypes.string]),
  currentSessionStatus: PropTypes.string.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  tappedId: PropTypes.string.isRequired,
  discusId: PropTypes.string.isRequired,
  onBannerImageSelected: PropTypes.func.isRequired,
  selectedBannerImage: PropTypes.string.isRequired,
};

SessionPage.defaultProps = {
  selectedAddMediaMenu: null,
  valueRewardType: '',
  formSelectedRewardType: null,
  selectedSpeakers: null,
  tappedSession: {},
  formSelectedIsFeatured: null,
  mediaValues: [],
  typeValues: '',
  selectedEnableComment: '',
};
