import {
  enablingDisablingField, setAlertErrorMessage, setFieldSelectedOrderBy,
  setFieldSelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_FIELDS, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableField } from '../../../helper';
import downloadFieldsAsync from './downloadFieldsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingField(true));

    const { token } = getState().authentication;
    const { data } = getState().fields;
    const { tappedId } = getState().uiField;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableField(tappedId, status, token);

    dispatch(setFieldSelectedPageSize(20));
    dispatch(setFieldSelectedOrderBy(INITIAL_ORDER_BY_FIELDS));
    dispatch(downloadFieldsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingField(false));
  }
};
