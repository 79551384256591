import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_DISCUSSION_ALLOW_DIRECT_USER_COMMENT, RXFIELD_DISCUSSION_ALLOW_PUBLIC_READ,
  RXFIELD_DISCUSSION_DISABLE_COMMENT,
} from './constant';

// eslint-disable-next-line import/prefer-default-export
export const rxformValidateDiscussion = (values) => {
  const requiredFields = [
    RXFIELD_DISCUSSION_ALLOW_DIRECT_USER_COMMENT,
    RXFIELD_DISCUSSION_ALLOW_PUBLIC_READ,
    RXFIELD_DISCUSSION_DISABLE_COMMENT,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
