import { ADD_EVENT_OPERATOR_MAPPING, CLEAR_EVENT_OPERATOR_MAPPINGS, SET_EVENT_OPERATOR_MAPPINGS } from '../action';

const initialState = {
  data: {},
  meta: {
    pageSize: 0,
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_EVENT_OPERATOR_MAPPINGS: {
      return {
        data: { ...action.data.data },
        meta: action.data.meta,
      };
    }
    case ADD_EVENT_OPERATOR_MAPPING: {
      return {
        ...state,
        data: { ...state.data, [action.data.id]: action.data },
      };
    }
    case CLEAR_EVENT_OPERATOR_MAPPINGS:
      return initialState;
    default:
      return state;
  }
};
