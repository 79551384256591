import { connect } from 'react-redux';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_EVENT_OPERATOR_REGISTRATIONS,
  MENUID_EVENT_OPERATOR_REGISTRATION, PAGE_MODE_TABLE,
} from '../../constant';
import {
  debounceSearch, getPermission, getTimeSymbol, toMoment, transformDropdownData,
  transformInitialValues, transformUserDropdownData,
} from '../../helper';
import LocalizedString from '../../localization';
import {
  clearProfiles, clearEventOperatorRegistrations, setActiveSideMenuItem, setAlertErrorMessage,
  setAlertInputMessage, setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
  setEventOperatorRegistrationAdvancedFilterDialogSelectedFilterString,
  setEventOperatorRegistrationSearchText, setEventOperatorRegistrationSelectedOrderBy,
  setEventOperatorRegistrationSelectedPageSize, setEventOperatorRegistrationTappedId,
  approveEventOperatorRegistrationAsync, downloadEventOperatorRegistrationAsync,
  downloadEventOperatorRegistrationsAsync, rejectEventOperatorRegistrationAsync,
  saveEventOperatorRegistrationsAsync,
} from '../../redux/action';
import { downloadProfilesAsync } from '../../../../redux/action';
import EventOperatorRegistrationPage from './event-operator-registration.presentation';
import {
  clearEvents, downloadEventsAsync, setEventAdvancedFilterDialogSelectedFilterString,
  setEventSearchText,
} from '../../../event/redux/action';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getApproveRejectedBy = (approvedRejectedBy) => (approvedRejectedBy.email
  ? `${approvedRejectedBy?.fullName} (${approvedRejectedBy?.email})`
  : approvedRejectedBy?.fullName);

const getInitialValues = (state) => {
  const { eventOperatorRegistrations, uiEventOperatorRegistration, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiEventOperatorRegistration;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? eventOperatorRegistrations.data[tappedId] : {};

  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    approvedRejectedOn: found.approvedRejectedOn ? toMoment(found.approvedRejectedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    clockInTime: found.clockInTime ? toMoment(found.clockInTime, found.event?.location.city?.province?.timeZone) : '',
    clockInStatus: found?.clockInStatus ? found?.clockInStatus : '',
    paymentChannel: found?.payment?.paymentChannel ? found?.payment?.paymentChannel : '',
    paymentStatus: found?.payment?.paymentStatus ? found?.payment?.paymentStatus : '',
    eventStartTime: found?.event?.startTime ? toMoment(found?.event?.startTime, found.event?.location.city?.province?.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    eventEndTime: found?.event?.endTime ? toMoment(found?.event?.endTime, found.event?.location.city?.province?.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    price: found?.price === 0
      ? LocalizedString.eventOperatorRegistrationPage.labelFree : found?.price,
    approvedRejectedBy: found?.approvedRejectedBy ? getApproveRejectedBy(found?.approvedRejectedBy) : '',
    eventTimeZone: getTimeSymbol(found.event?.location?.city?.province?.timeZone),
  }) : {
    clockInTime: '',
    eventStartTime: '',
    eventEndTime: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  profiles: transformUserDropdownData(state.profiles.data),
  events: transformDropdownData(state.events.data),
  approving: state.uiEventOperatorRegistration.approving,
  downloading: state.uiEventOperatorRegistration.downloading,
  downloadingDeleting: state.uiEventOperatorRegistration.downloadingDeleting,
  downloadingProfiles: state.uiProfile.downloading,
  downloadingEvents: state.uiEvent.downloading,
  isApprovalAllowed: getPermission(state, 'EVENT_APPROVAL_EVENT_REGISTRATION_FOR_OPERATOR'),
  rejecting: state.uiEventOperatorRegistration.rejecting,
});

const searchProfileDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchEventDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setEventAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setEventSearchText(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearEvents());
    dispatch(clearProfiles());
    dispatch(downloadEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAppear: () => {
    dispatch(setEventOperatorRegistrationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_EVENT_OPERATOR_REGISTRATION));
    dispatch(setEventOperatorRegistrationSearchText(''));
    dispatch(clearEventOperatorRegistrations());
    dispatch(setEventOperatorRegistrationSelectedPageSize(20));
    dispatch(setEventOperatorRegistrationSelectedOrderBy(
      INITIAL_ORDER_BY_EVENT_OPERATOR_REGISTRATIONS,
    ));
    dispatch(downloadEventOperatorRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/event/, 'event.id').replace(/participant/, 'user.id');
    dispatch(setEventOperatorRegistrationAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearEventOperatorRegistrations());
    dispatch(downloadEventOperatorRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApprovePressed: () => {
    dispatch(setAlertInputMessage(LocalizedString.eventOperatorRegistrationPage
      .msgApproveEventOperatorRegistration,
    LocalizedString.eventOperatorRegistrationPage.placeholderNotes));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadEventOperatorRegistrationsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setEventOperatorRegistrationSelectedPageSize(pageSize));
    dispatch(downloadEventOperatorRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeEventText: (text) => {
    dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setEventSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchEventDebounce(dispatch);
    }
  },
  onChangeParticipantText: (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchProfileDebounce(dispatch);
    }
  },
  onConfirmApproveRejectPressed: (reason, message) => {
    switch (message) {
      case LocalizedString.eventOperatorRegistrationPage.msgApproveEventOperatorRegistration:
        dispatch(approveEventOperatorRegistrationAsync(reason))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      case LocalizedString.eventOperatorRegistrationPage.msgRejectEventOperatorRegistration:
        dispatch(rejectEventOperatorRegistrationAsync(reason))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default: break;
    }
  },
  onDownloadPressed: () => {
    dispatch(saveEventOperatorRegistrationsAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setEventOperatorRegistrationSelectedPageSize(pageSize));
    dispatch(clearEventOperatorRegistrations());
    dispatch(downloadEventOperatorRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRejectPressed: () => {
    dispatch(setAlertInputMessage(LocalizedString.eventOperatorRegistrationPage
      .msgRejectEventOperatorRegistration,
    LocalizedString.eventOperatorRegistrationPage.placeholderNotes));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setEventOperatorRegistrationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearEventOperatorRegistrations());
    dispatch(setEventOperatorRegistrationSelectedOrderBy(
      INITIAL_ORDER_BY_EVENT_OPERATOR_REGISTRATIONS,
    ));
    dispatch(downloadEventOperatorRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setEventOperatorRegistrationSearchText(text));
      dispatch(clearEventOperatorRegistrations());
      await dispatch(downloadEventOperatorRegistrationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setEventOperatorRegistrationSelectedOrderBy(orderBy));
    dispatch(clearEventOperatorRegistrations());
    dispatch(downloadEventOperatorRegistrationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setEventOperatorRegistrationTappedId(id));
    dispatch(downloadEventOperatorRegistrationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EventOperatorRegistrationPage);
