import { connect } from 'react-redux';
import { change, getFormValues, reset } from 'redux-form';
import {
  addCompanyMData, clearCities, clearCompaniesMData, clearProvinces, setActiveSideMenuItem,
  setAlertConfirmationMessage, setAlertErrorMessage,
  setCityAdvancedFilterDialogSelectedFilterString, setCitySearchText,
  setCompanySelectedPageSizeMData, setCompanySelectedOrderByMData,
  setCompanyAdvancedFilterDialogSelectedFilterStringMData, setCompanySearchTextMData,
  setCompanyTappedIdMData, setCompanySelectedCityIdMData, setCompanyMapCenterMData,
  setCompanyMapMarkerMData, setProvinceAdvancedFilterDialogSelectedFilterString,
  setProvinceSearchText,
  addEditCompanyAsync, downloadCitiesAsync, downloadCompanyMdataAsync, downloadMDataCompaniesAsync,
  downloadProvincesAsync, enableDisableCompanyAsync, resyncCompanyAsync, saveCompaniesAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_COMPANIES, PAGE_MODE_TABLE,
  MENUID_MASTERDATA_COMPANY, RXFORM_COMPANY,
  RXFIELD_COMPANY_CITY, RXFIELD_COMPANY_LATITUDE,
  RXFIELD_COMPANY_GOOGLE_MAP_URL, GOOGLE_MAP_URL_PREFIX,
  RXFIELD_COMPANY_LONGITUDE, DEFAULT_MAP_DRAWER_CENTER_MARKER,
  DEFAULT_GOOGLE_MAP_URL, RXFIELD_COMPANY_LOGO, REST_BASE_URL,
  REST_URL_IMAGE_STORAGE, ADVANCED_FILTER_MODE,
  RXFIELD_COMPANY_BANNER,
} from '../../constant';
import {
  getPermission,
  toDecimal, toNumber, transformDropdownData, transformInitialValues,
} from '../../helper';
import LocalizedString from '../../localization';
import CompanyPage from './company.presentation';
import {
  setCompanyBannerImage, setCompanyClearLogoMData, setCompanyLogoImage,
  setDownloadRequestTimeoutDialogVisibility,
} from '../../redux/action/simple-action';
import { downloadMyConfigItemsAsync } from '../../module/configuration/redux/action';

const getInitialValues = (state) => {
  const { companiesMdata, uiCompany, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiCompany;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? companiesMdata.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      {
        city: { label: found.city.name, value: found.city.id },
        province: { label: found.city.province.name, value: found.city.province.id },
        logo: found.logo ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.logo}` : null,
        banner: found.banner ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.banner}` : null,
      })
    : {
      name: '',
      city: { label: '', value: '' },
      description: '',
      phone: '',
      address: '',
      postalCode: '',
      googleMapUrl: '',
      longitude: '',
      latitude: '',
      logo: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  center: state.uiCompany.center,
  marker: state.uiCompany.marker,
  provinces: transformDropdownData(state.provinces.data),
  cities: transformDropdownData(state.cities.data),
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiCompany.addingEditing,
  downloadingDeleting: state.uiCompany.downloadingDeleting,
  downloading: state.uiCompany.downloading,
  loadingCity: state.uiCity.downloading,
  loadingProvince: state.uiProvince.downloading,
  enablingDisabling: state.uiCompany.enablingDisabling,
  resyncing: state.uiCompany.resyncing,
  companiesMdata: state.companiesMdata.data,
  tappedData: state.companiesMdata.data[state.uiCompany.tappedId],
  tappedId: state.uiFunctionalPage.tappedId || '',
  currentCompanyStatus: state.companiesMdata.data[state.uiFunctionalPage.tappedId]
    ? state.companiesMdata.data[state.uiFunctionalPage.tappedId].status : '',
  currentFormValues: getFormValues(RXFORM_COMPANY)(state) || {},
  initialValues: getInitialValues(state),
  isResyncAllowed: getPermission(state, 'MDATA_RESYNC_MASTERDATA'),
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setProvinceAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setCityAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearProvinces());
    dispatch(clearCities());
    dispatch(downloadProvincesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData(''));
    dispatch(setActiveSideMenuItem(MENUID_MASTERDATA_COMPANY));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(setCompanySelectedPageSizeMData(20));
    dispatch(setCompanySelectedOrderByMData(INITIAL_ORDER_BY_COMPANIES));
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/city/, 'city.id').replace(/province/, 'city.province.id').replace(/timeZone/, 'city.province.timeZone');
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData(text));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(setCompanyLogoImage(''));
    dispatch(setCompanyBannerImage(''));
    dispatch(reset(RXFORM_COMPANY));
  },
  onChangeCityText: async (text, mode) => {
    try {
      dispatch(setCityAdvancedFilterDialogSelectedFilterString(
        mode === ADVANCED_FILTER_MODE ? '' : 'status=enabled',
      ));
      dispatch(setCitySearchText(text));
      dispatch(clearCities());
      await dispatch(downloadCitiesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onCityOptionSelected: (option) => {
    if (option) {
      dispatch(setCompanySelectedCityIdMData(option.value));
      dispatch(change(RXFORM_COMPANY, RXFIELD_COMPANY_CITY, option));
    } else {
      dispatch(setCompanySelectedCityIdMData(''));
      dispatch(change(RXFORM_COMPANY, RXFIELD_COMPANY_CITY, ''));
    }
  },
  onChangeLatitudeText: (e, currLng) => {
    const latitude = e.target.value ? toDecimal(e.target.value) : '';
    dispatch(setCompanyMapCenterMData({ latitude }));
    dispatch(setCompanyMapMarkerMData({ latitude }));
    dispatch(change(RXFORM_COMPANY, RXFIELD_COMPANY_LATITUDE, latitude));
    if (latitude && currLng) {
      dispatch(change(RXFORM_COMPANY, RXFIELD_COMPANY_GOOGLE_MAP_URL, GOOGLE_MAP_URL_PREFIX
        .replace(/\{latitude\}/, e.target.value).replace(/\{longitude\}/, currLng)));
    } else {
      dispatch(change(RXFORM_COMPANY, RXFIELD_COMPANY_GOOGLE_MAP_URL, ''));
    }
  },
  onChangeLongitudeText: (e, currLat) => {
    const longitude = e.target.value ? toDecimal(e.target.value) : '';
    dispatch(setCompanyMapCenterMData({ longitude }));
    dispatch(setCompanyMapMarkerMData({ longitude }));
    dispatch(change(RXFORM_COMPANY, RXFIELD_COMPANY_LONGITUDE, longitude));
    if (currLat && longitude) {
      dispatch(change(RXFORM_COMPANY, RXFIELD_COMPANY_GOOGLE_MAP_URL, GOOGLE_MAP_URL_PREFIX
        .replace(/\{latitude\}/, currLat).replace(/\{longitude\}/, e.target.value)));
    } else {
      dispatch(change(RXFORM_COMPANY, RXFIELD_COMPANY_GOOGLE_MAP_URL, ''));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadMDataCompaniesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCompanySelectedPageSizeMData(pageSize));
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeProvinceText: async (text) => {
    try {
      dispatch(setProvinceAdvancedFilterDialogSelectedFilterString(''));
      dispatch(setProvinceSearchText(text));
      dispatch(clearProvinces());
      await dispatch(downloadProvincesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onCreatePressed: () => {
    dispatch(setCompanyTappedIdMData(''));
    dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCitySearchText(''));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setCompanyClearLogoMData(true));
    dispatch(setCompanyMapCenterMData(DEFAULT_MAP_DRAWER_CENTER_MARKER));
    dispatch(setCompanyMapMarkerMData(DEFAULT_MAP_DRAWER_CENTER_MARKER));
    dispatch(change(RXFORM_COMPANY, RXFIELD_COMPANY_LATITUDE,
      DEFAULT_MAP_DRAWER_CENTER_MARKER.latitude));
    dispatch(change(RXFORM_COMPANY, RXFIELD_COMPANY_LONGITUDE,
      DEFAULT_MAP_DRAWER_CENTER_MARKER.longitude));
    dispatch(change(RXFORM_COMPANY, RXFIELD_COMPANY_GOOGLE_MAP_URL, DEFAULT_GOOGLE_MAP_URL));
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case LocalizedString.common.msgResyncConfirmation:
        dispatch(resyncCompanyAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableCompanyAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setCompanyTappedIdMData(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onDrawingCompleted: (data, drawingMode, tappedData) => {
    dispatch(setCompanyMapMarkerMData(data));
    dispatch(change(RXFORM_COMPANY, RXFIELD_COMPANY_LATITUDE, data.latitude));
    dispatch(change(RXFORM_COMPANY, RXFIELD_COMPANY_LONGITUDE, data.longitude));
    dispatch(change(RXFORM_COMPANY, RXFIELD_COMPANY_GOOGLE_MAP_URL, data.url));
    if (tappedData) {
      dispatch(addCompanyMData({ ...tappedData, area: [] }));
    }
  },
  onDownloadPressed: () => {
    dispatch(saveCompaniesAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onEditPressed: (id, data) => {
    dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCitySearchText(''));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadCompanyMdataAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setCompanyTappedIdMData(id));
    const { latitude, longitude, logo } = data[id];
    if (latitude && longitude) {
      dispatch(setCompanyMapCenterMData({ latitude, longitude }));
      dispatch(setCompanyMapMarkerMData({ latitude, longitude }));
    } else {
      dispatch(setCompanyMapCenterMData(null));
      dispatch(setCompanyMapMarkerMData(null));
    }
    if (logo) {
      dispatch(setCompanyClearLogoMData(false));
    } else {
      dispatch(setCompanyClearLogoMData(true));
    }
  },
  onLogoImageSelected: (image, fileName, url) => {
    dispatch(setCompanyClearLogoMData(false));
    dispatch(setCompanyLogoImage(url));
    dispatch(change(RXFORM_COMPANY, RXFIELD_COMPANY_LOGO, image));
  },
  onBannerImageSelected: (image, fileName, url) => {
    dispatch(setCompanyBannerImage(url));
    dispatch(change(RXFORM_COMPANY, RXFIELD_COMPANY_BANNER, image));
  },
  onRefresh: (pageSize) => {
    dispatch(setCompanySelectedPageSizeMData(pageSize));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData(''));
    dispatch(clearCompaniesMData());
    dispatch(setCompanySelectedOrderByMData(INITIAL_ORDER_BY_COMPANIES));
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    name, description, latitude, longitude, googleMapUrl, address, postalCode, phone, tags, email,
    website, logo, banner, appStoreUrl, playStoreUrl,
  }) => {
    try {
      await dispatch(addEditCompanyAsync(name, description, toNumber(latitude), toNumber(longitude),
        googleMapUrl, address, postalCode, phone, tags, email, website, logo, banner, appStoreUrl,
        playStoreUrl));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCompanySearchTextMData(text));
      dispatch(clearCompaniesMData());
      await dispatch(downloadMDataCompaniesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setCompanySelectedOrderByMData(orderBy));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    name, description, latitude, longitude, googleMapUrl, address, postalCode, phone, tags, email,
    website, logo, banner, appStoreUrl, playStoreUrl,
  }) => {
    await dispatch(addEditCompanyAsync(name, description, toNumber(latitude), toNumber(longitude),
      googleMapUrl, address, postalCode, phone, tags, email, website, logo, banner, appStoreUrl,
      playStoreUrl));
  },
  onViewPressed: (id, data) => {
    dispatch(setCompanyTappedIdMData(id));
    const { latitude, longitude, logo } = data[id];
    if (latitude && longitude) {
      dispatch(setCompanyMapCenterMData({ latitude, longitude }));
      dispatch(setCompanyMapMarkerMData({ latitude, longitude }));
    } else {
      dispatch(setCompanyMapCenterMData(null));
      dispatch(setCompanyMapMarkerMData(null));
    }
    if (logo) {
      dispatch(setCompanyClearLogoMData(false));
    } else {
      dispatch(setCompanyClearLogoMData(true));
    }
    dispatch(downloadCompanyMdataAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPage);
