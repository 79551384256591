import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  FormControl, FormControlLabel, FormHelperText, makeStyles, Radio, RadioGroup, Typography,
} from '@material-ui/core';
import {
  COLOR_BACKGROUND, COLOR_PRIMARY, COLOR_BODY_TEXT, COLOR_DANGEROUS,
} from '../constant';
import { SimpleDataShape } from '../type';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  form: {
    margin: theme.spacing(1),
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_PRIMARY,
      },
    },
  },
  helperText: {
    fontStyle: 'italic',
    color: COLOR_DANGEROUS,
    marginLeft: 14,
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: COLOR_BACKGROUND,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: `2px auto ${COLOR_PRIMARY}`,
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: COLOR_PRIMARY,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: COLOR_PRIMARY,
    },
  },
}));

const RadioButtonField = ({
  data,
  disabled, error, hidden,
  onOptionSelected,
  defaultValue, label, helperText, value,
  ...props
}) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const handleChange = (x) => {
    setSelectedValue(x);
    onOptionSelected(x);
  };

  const getCheckedValue = (x) => {
    if (selectedValue || selectedValue === false) {
      return typeof selectedValue === 'object' ? x.value === selectedValue.value : x.value === selectedValue;
    }
    return null;
  };

  if (!hidden) {
    return (
      <div className={classes.container}>
        <FormControl className={classes.form} variant="outlined" error={error}>
          <Typography>{label}</Typography>
          <RadioGroup value={selectedValue}>
            {data.map((x) => (
              <FormControlLabel
                key={x.value}
                value={x.value}
                disabled={disabled}
                control={(
                  <Radio
                    className={classes.root}
                    checked={getCheckedValue(x)}
                    onChange={() => handleChange(x)}
                    disableRipple
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                    {...props}
                  />
)}
                label={x.label}
              />
            ))}
          </RadioGroup>
          <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
        </FormControl>
      </div>
    );
  }
  return null;
};
export default RadioButtonField;

RadioButtonField.propTypes = {
  data: PropTypes.oneOfType([PropTypes.arrayOf(SimpleDataShape),
    PropTypes.arrayOf(PropTypes.object)]).isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hidden: PropTypes.bool,
  onOptionSelected: PropTypes.func,
  defaultValue: PropTypes.oneOfType([SimpleDataShape, PropTypes.object]),
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

RadioButtonField.defaultProps = {
  disabled: false,
  error: false,
  hidden: false,
  onOptionSelected: () => {},
  defaultValue: null,
  helperText: null,
  value: null,
};
