import { downloadSessionInvitation, resendSessionInvitation } from '../../../helper';
import { addSessionInvitation, resendingSessionInvitation } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resendingSessionInvitation(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSessionInvitation;

    await resendSessionInvitation(tappedId, token);
    const result = await downloadSessionInvitation(tappedId, token);

    dispatch(addSessionInvitation(result));
  } finally {
    dispatch(resendingSessionInvitation(false));
  }
};
