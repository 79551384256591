import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { AccentButton, FunctionalPage } from '../../component';
import {
  CSV_FILE_EXTENSIONS, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  PAGE_MODE_TABLE, PAGE_MODE_EDIT, PAGE_MODE_VIEW, PICKER_MODE_DATE_TIME,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, RXFIELD_CREATED_DATE, RXFIELD_TOKEN_IMPORT_CODE,
  RXFIELD_TOKEN_IMPORT_CSV_FILE, RXFIELD_TOKEN_IMPORT_IMPORT_STATUS,
  RXFIELD_TOKEN_IMPORT_STATUS_NOTES, RXFIELD_TOKEN_IMPORT_MERCHANT_NAME,
  RXFIELD_TOKEN_IMPORT_VALID_FROM, RXFIELD_TOKEN_IMPORT_VALID_UNTIL, RXFIELD_TOKEN_IMPORT_VOUCHER,
  RXFIELD_TOKEN_IMPORT_MERCHANT, RXFIELD_TOKEN_IMPORT_VOUCHER_ID, RXFIELD_TOKEN_IMPORT_VOUCHER_NAME,
  RXFORM_TOKEN_IMPORT, RXSTATE_TOKEN_IMPORTS, RXSTATE_TOKEN_IMPORT_PAGE, TOKEN_IMPORT_IMPORT_STATUS,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormDropzonePicker,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { SimpleDataShape } from '../../type';
import { rxformValidateTokenImport } from '../../validation';

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: 10,
  },
}));

const renderDialogContent = (vouchers, addingEditing, downloadingDeleting, loadingVoucher,
  onChangeVoucherText, onCsvFileSelected, onVoucherOptionSelected, pageMode) => (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_TOKEN_IMPORT_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.tokenImportPage.placeholderCode}
            label={LocalizedString.tokenImportPage.placeholderCode}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            secureTextEntry
            hidden={pageMode === PAGE_MODE_TABLE}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TOKEN_IMPORT_VALID_FROM}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.tokenImportPage.placeholderValidFrom}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW || downloadingDeleting}
            required={pageMode === PAGE_MODE_EDIT}
            hidden={pageMode === PAGE_MODE_TABLE}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TOKEN_IMPORT_VALID_UNTIL}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.tokenImportPage.placeholderValidUntil}
            disabled={
            addingEditing || pageMode === PAGE_MODE_VIEW || downloadingDeleting
            || pageMode === PAGE_MODE_TABLE
          }
            required={pageMode === PAGE_MODE_EDIT}
            hidden={pageMode === PAGE_MODE_TABLE}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TOKEN_IMPORT_IMPORT_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.tokenImportPage.placeholderImportStatus}
            label={LocalizedString.tokenImportPage.placeholderImportStatus}
            disabled
            hidden={pageMode === PAGE_MODE_TABLE}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TOKEN_IMPORT_STATUS_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.tokenImportPage.placeholderStatusNotes}
            label={LocalizedString.tokenImportPage.placeholderStatusNotes}
            disabled
            multiline
            hidden={pageMode === PAGE_MODE_TABLE}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TOKEN_IMPORT_VOUCHER}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.tokenImportPage.labelVoucher}
            label={LocalizedString.tokenImportPage.labelVoucher}
            data={vouchers}
            loading={loadingVoucher}
            onChangeText={onChangeVoucherText}
            onOptionSelected={(item) => onVoucherOptionSelected(item)}
            disabled={addingEditing}
            required={pageMode === PAGE_MODE_TABLE}
            hidden={pageMode !== PAGE_MODE_TABLE}
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_TOKEN_IMPORT_VOUCHER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.tokenImportPage.placeholderVoucherId}
            label={LocalizedString.tokenImportPage.placeholderVoucherId}
            disabled
            hidden={pageMode === PAGE_MODE_TABLE}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TOKEN_IMPORT_VOUCHER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.tokenImportPage.placeholderVoucherName}
            label={LocalizedString.tokenImportPage.placeholderVoucherName}
            disabled
            hidden={pageMode === PAGE_MODE_TABLE}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TOKEN_IMPORT_MERCHANT_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.tokenImportPage.placeholderMerchantName}
            label={LocalizedString.tokenImportPage.placeholderMerchantName}
            disabled
            hidden={pageMode === PAGE_MODE_TABLE}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TOKEN_IMPORT_CSV_FILE}
            component={renderReduxFormDropzonePicker}
            label={LocalizedString.tokenImportPage.labelCsvFile}
            onFileSelected={onCsvFileSelected}
            disabled={addingEditing}
            acceptedFileExtension={CSV_FILE_EXTENSIONS}
            required={pageMode === PAGE_MODE_TABLE}
            hidden={pageMode !== PAGE_MODE_TABLE}
          />
        </Grid>
      </Grid>
    </Grid>
);

const TokenImportPage = ({
  merchants, vouchers,
  addingEditing, downloading, downloadingDeleting, isRetryAllowed, loadingMerchant, loadingVoucher,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed, onCancelPressed,
  onChangeMerchantText, onChangePage, onChangePageSize, onChangeVoucherText,
  onConfirmContextMenuPressed, onCreatePressed, onCsvFileSelected, onDeleteFilteredData,
  onDeletePressed, onDownloadPressed, onEditPressed, onRefresh, onResetAdvancedFilterPressed,
  onRetryPressed, onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed,
  onViewPressed, onVoucherOptionSelected,
  pageMode, tappedId,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_TOKEN_IMPORTS}
      uiPage={RXSTATE_TOKEN_IMPORT_PAGE}
      moreMenus={[
        {
          caption: LocalizedString.tokenImportPage.buttonCaptionRetry,
          disabled: addingEditing || !isRetryAllowed,
          onPress: () => onRetryPressed(tappedId),
        },
      ]}
      filterColumns={[
        {
          title: LocalizedString.tokenImportPage.placeholderCreatedDate,
          field: RXFIELD_CREATED_DATE,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.tokenImportPage.labelVoucher,
          field: RXFIELD_TOKEN_IMPORT_VOUCHER,
          type: FILTER_TYPE_DROPDOWN,
          data: vouchers,
          loading: loadingVoucher,
          onChangeFilterText: (text) => onChangeVoucherText(text, true),
          useDropdownValue: true,
        },
        {
          title: LocalizedString.tokenImportPage.labelMerchant,
          field: RXFIELD_TOKEN_IMPORT_MERCHANT,
          type: FILTER_TYPE_DROPDOWN,
          data: merchants,
          loading: loadingMerchant,
          onChangeFilterText: onChangeMerchantText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.tokenImportPage.placeholderImportStatus,
          field: RXFIELD_TOKEN_IMPORT_IMPORT_STATUS,
          type: FILTER_TYPE_DROPDOWN,
          data: TOKEN_IMPORT_IMPORT_STATUS,
          useDropdownValue: true,
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.tokenImportPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        {
          title: LocalizedString.tokenImportPage.labelCode,
          field: 'code',
          sorting: !downloading,
          render: ({ code }) => {
            const firstFourDigit = code.slice(0, 4);
            const restOfDigitAsAsterisk = code.slice(4).replace(/./g, '*');
            return `${firstFourDigit}${restOfDigitAsAsterisk}`;
          },
        },
        {
          title: LocalizedString.tokenImportPage.labelValidFrom,
          field: 'validFrom',
          sorting: !downloading,
        },
        {
          title: LocalizedString.tokenImportPage.labelValidUntil,
          field: 'validUntil',
          sorting: !downloading,
        },
        {
          title: LocalizedString.tokenImportPage.labelVoucher, field: 'voucher.title', sorting: !downloading,
        },
        { title: LocalizedString.tokenImportPage.labelMerchant, field: 'voucher.merchant.name', sorting: !downloading },
        { title: LocalizedString.tokenImportPage.labelStatus, field: 'importStatus', sorting: !downloading },

      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onCreatePressed={onCreatePressed}
      onConfirmDeletePressed={onConfirmContextMenuPressed}
      onDeletePressed={onDeletePressed}
      onDownloadPressed={onDownloadPressed}
      onEditPressed={onEditPressed}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSavePressed={onSavePressed}
      onSubmitPressed={onSubmitPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      addDialogSubmitButtonCaption={LocalizedString.tokenImportPage.buttonCaptionImport}
      createNewButtonCaption={LocalizedString.tokenImportPage.buttonCaptionImportToken}
      deleteButtonCaption={LocalizedString.tokenImportPage.buttonCaptionDeleteTokenImport}
      editButtonCaption={LocalizedString.tokenImportPage.buttonCaptionEditTokenImport}
      title={LocalizedString.tokenImportPage.title}
      renderCustomUpperButton={() => (
        <AccentButton
          onClick={onDeleteFilteredData}
          caption={LocalizedString.tokenImportPage.buttonCaptionDeleteFilteredData}
          variant="outlined"
          style={classes.button}
        />
      )}
      useFullWidth
      createPermissionName="VOUCHER_CREATE_VOUCHER_IMPORT"
      deletePermissionName="VOUCHER_DELETE_VOUCHER_IMPORT"
      editPermissionName="VOUCHER_EDIT_VOUCHER_IMPORT"
      savePermissionName="VOUCHER_GET_VOUCHER_IMPORT"
    >
      {renderDialogContent(vouchers, addingEditing, downloadingDeleting, loadingVoucher,
        onChangeVoucherText, onCsvFileSelected, onVoucherOptionSelected, pageMode)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_TOKEN_IMPORT,
  validate: rxformValidateTokenImport,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(TokenImportPage);

TokenImportPage.propTypes = {
  merchants: PropTypes.arrayOf(SimpleDataShape).isRequired,
  vouchers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  isRetryAllowed: PropTypes.bool.isRequired,
  loadingMerchant: PropTypes.bool.isRequired,
  loadingVoucher: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeMerchantText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeVoucherText: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onCsvFileSelected: PropTypes.func.isRequired,
  onDeleteFilteredData: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onRetryPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onVoucherOptionSelected: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
};
