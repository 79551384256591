import { INITIAL_ORDER_BY_SESSION_OPERATOR_SESSIONS } from '../../constant';
import {
  COPYING_SESSION_OPERATOR_SESSION_QR_CODE, DOWNLOADING_DELETING_SESSION_OPERATOR_SESSION,
  DOWNLOADING_SESSION_OPERATOR_SESSIONS,
  SET_SESSION_OPERATOR_SESSION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_SESSION_OPERATOR_SESSION_SELECTED_ORDER_BY, SET_SESSION_OPERATOR_SESSION_SEARCH_TEXT,
  SET_SESSION_OPERATOR_SESSION_SELECTED_PAGE_SIZE, SET_SESSION_OPERATOR_SESSION_TAPPED_ID,
} from '../action/simple-action';

const initialState = {
  copying: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_SESSION_OPERATOR_SESSIONS,
  filterString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_SESSION_OPERATOR_SESSIONS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_SESSION_OPERATOR_SESSION:
      return { ...state, downloadingDeleting: action.status };
    case SET_SESSION_OPERATOR_SESSION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_SESSION_OPERATOR_SESSION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_SESSION_OPERATOR_SESSION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_SESSION_OPERATOR_SESSION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_SESSION_OPERATOR_SESSION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case COPYING_SESSION_OPERATOR_SESSION_QR_CODE:
      return { ...state, copying: action.status };
    default: return state;
  }
};
