import { connect } from 'react-redux';
import {
  launchingImagePicker, setAlertErrorMessage,
  setImagePickerDialogLaunchMode, setImagePickerDialogVisibility, setIsSelfieModalOpen,
} from '../../redux/action';
import ImagePickerDialog from './image-picker-dialog.presentation';

const mapStateToProps = (state) => ({
  launching: state.uiImagePickerDialog.launching,
  visibility: state.uiImagePickerDialog.visibility,
  isSelfieModalOpen: state.uiProfileEditData.isSelfieModalOpen,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setImagePickerDialogLaunchMode(''));
  },
  onCancelPressed: () => {
    dispatch(setImagePickerDialogLaunchMode(''));
    dispatch(setImagePickerDialogVisibility(false));
  },
  onCancelSelfieModalPressed: () => {
    dispatch(setIsSelfieModalOpen(false));
  },
  onPickFromGallerySelected: async () => {
    try {
      const uploadElement = document.getElementById('uploadImage');
      if (uploadElement) uploadElement.click();
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    } finally {
      dispatch(launchingImagePicker(false));
    }
  },
  onTakePhotoSelected: async () => {
    try {
      dispatch(setIsSelfieModalOpen(true));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    } finally {
      dispatch(launchingImagePicker(false));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ImagePickerDialog);
