import {
  addHistory, downloadingAttendanceSummaryClockInAreaHistory,
} from '../simple-action';
import { downloadHistory } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAttendanceSummaryClockInAreaHistory(true));

    const { token } = getState().authentication;
    const { tappedClockInAreaId } = getState().uiAttendanceSummary;

    const history = await downloadHistory(tappedClockInAreaId, token);
    dispatch(addHistory(history));
  } finally {
    dispatch(downloadingAttendanceSummaryClockInAreaHistory(false));
  }
};
