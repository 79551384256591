import { reset } from 'redux-form';
import {
  addingEditingPopUpBanner, clearPopUpBanners, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addPopUpBanner, editPopUpBanner, transformLocalImagePath } from '../../../helper';
import {
  PAGE_MODE_TABLE, RXFORM_POPUP_BANNER,
} from '../../../constant';
import downloadPopUpBannersAsync from './downloadPopUpBannersAsync';

export default (
  title, description, url, order, validFrom, validUntil, image,
) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingPopUpBanner(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPopUpBanner;

    if (tappedId) {
      await editPopUpBanner(
        tappedId,
        title,
        description,
        url,
        order,
        validFrom,
        validUntil,
        transformLocalImagePath(image),
        token,
      );
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addPopUpBanner(
        title,
        description,
        url,
        order,
        validFrom,
        validUntil,
        transformLocalImagePath(image),
        token,
      );
    }

    dispatch(reset(RXFORM_POPUP_BANNER));
    dispatch(clearPopUpBanners());
    dispatch(downloadPopUpBannersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingPopUpBanner(false));
  }
};
