import { INITIAL_ORDER_BY_EVENT_CATEGORIES } from '../../constant';
import {
  ADDING_EDITING_EVENT_CATEGORY, DOWNLOADING_DELETING_EVENT_CATEGORY,
  DOWNLOADING_DELETING_MULTIPLE_EVENT_CATEGORY,
  DOWNLOADING_EVENT_CATEGORIES, ENABLING_DISABLING_EVENT_CATEGORY,
  ENABLING_DISABLING_MULTIPLE_EVENT_CATEGORY,
  SET_EVENT_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_EVENT_CATEGORY_SEARCH_TEXT, SET_EVENT_CATEGORY_SELECTED_CLEAR_IMAGE,
  SET_EVENT_CATEGORY_SELECTED_ORDER_BY,
  SET_EVENT_CATEGORY_SELECTED_PAGE_SIZE, SET_EVENT_CATEGORY_TAPPED_ID,
} from '../action/simple-action';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloadingDeletingMultiple: false,
  downloading: false,
  enablingDisabling: false,
  enablingDisablingMultiple: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_EVENT_CATEGORIES,
  filterString: '',
  clearImage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_EVENT_CATEGORIES:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_EVENT_CATEGORY:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_EVENT_CATEGORY:
      return { ...state, downloadingDeleting: action.status };
    case DOWNLOADING_DELETING_MULTIPLE_EVENT_CATEGORY:
      return { ...state, downloadingDeletingMultiple: action.status };
    case SET_EVENT_CATEGORY_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_EVENT_CATEGORY_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_EVENT_CATEGORY_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_EVENT_CATEGORY_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_EVENT_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case ENABLING_DISABLING_EVENT_CATEGORY:
      return { ...state, enablingDisabling: action.status };
    case ENABLING_DISABLING_MULTIPLE_EVENT_CATEGORY:
      return { ...state, enablingDisablingMultiple: action.status };
    case SET_EVENT_CATEGORY_SELECTED_CLEAR_IMAGE:
      return { ...state, clearImage: action.text };
    default: return state;
  }
};
