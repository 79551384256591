import {
  resyncingProvince, setAlertErrorMessage, setProvinceSelectedOrderBy, setProvinceSelectedPageSize,
} from '../simple-action';
import { resyncMasterDataIndividually } from '../../../helper';
import { INITIAL_ORDER_BY_PROVINCES, MASTER_DATA_ENTITY_NAME_PROVINCE } from '../../../constant';
import downloadProvincesAsync from './downloadProvincesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resyncingProvince(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiProvince;

    await resyncMasterDataIndividually(MASTER_DATA_ENTITY_NAME_PROVINCE, tappedId, token);

    dispatch(setProvinceSelectedPageSize(20));
    dispatch(setProvinceSelectedOrderBy(INITIAL_ORDER_BY_PROVINCES));
    dispatch(downloadProvincesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(resyncingProvince(false));
  }
};
