import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FormInitialValueShape } from '../../type';
import { renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { FunctionalPage } from '../../component';
import { rxformValidateRoom } from '../../validation';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_DROPDOWN, PAGE_MODE_EDIT, PAGE_MODE_TABLE, PAGE_MODE_VIEW,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, PICKER_MODE_DATE_TIME, RXFIELD_ROOM_DESCRIPTION,
  RXFIELD_ROOM_LOCATION,
  RXFIELD_ROOM_LOCATION_ID,
  RXFIELD_ROOM_LOCATION_NAME, RXFIELD_ROOM_NAME, RXFORM_ROOM, RXSTATE_ROOMS, RXSTATE_ROOM_PAGE,
  status, STATUS_DISABLED, STATUS_ENABLED, ADVANCED_FILTER_MODE,
} from '../../constant';
import { toMoment } from '../../helper';

const useStyles = makeStyles(() => ({
  dialogHeader: {
    marginBottom: 20,
  },
}));

const renderDialogContent = (addingEditing, pageMode, initLocation, locations,
  loadingLocation, onChangeLocationText, onLocationOptionSelected) => (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_ROOM_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.roomPage.placeholderName}
            label={LocalizedString.roomPage.labelName}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ROOM_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.roomPage.placeholderDescription}
            label={LocalizedString.roomPage.labelDescription}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            multiline
            required={false}
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        {
          pageMode === PAGE_MODE_TABLE || pageMode === PAGE_MODE_EDIT
            ? (
              <Grid item>
                <Field
                  name={RXFIELD_ROOM_LOCATION}
                  component={renderReduxFormOutlinedDropdownTextField}
                  placeholder={LocalizedString.roomPage.placeholderLocation}
                  label={LocalizedString.roomPage.labelLocation}
                  disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                  data={locations}
                  value={initLocation}
                  loading={loadingLocation}
                  onChangeText={onChangeLocationText}
                  onOptionSelected={onLocationOptionSelected}
                  required
                />
              </Grid>
            ) : (
              <Grid>
                <Grid item>
                  <Field
                    name={RXFIELD_ROOM_LOCATION_ID}
                    component={renderReduxFormOutlinedTextField}
                    placeholder={LocalizedString.roomPage.placeholderLocationId}
                    label={LocalizedString.roomPage.labelLocationId}
                    disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                  />
                </Grid>
                <Grid item>
                  <Field
                    name={RXFIELD_ROOM_LOCATION_NAME}
                    component={renderReduxFormOutlinedTextField}
                    placeholder={LocalizedString.roomPage.placeholderLocationName}
                    label={LocalizedString.roomPage.labelLocationName}
                    disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                  />
                </Grid>
              </Grid>
            )
        }
      </Grid>
    </Grid>
);

const RoomPage = ({
  initialValues,
  addingEditing, downloading, enablingDisabling,
  handleSubmit, onAppear, onApplyAdvancedFilterPressed,
  onCancelPressed,
  onChangePage, onChangePageSize,
  onConfirmContextMenuPressed, onCreatePressed,
  onEditPressed, onContextMenuPressed,
  onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed,
  onSubmitPressed, onViewPressed,
  currentRoomStatus, pageMode, tappedId,
  onDeletePressed, onSavePressed, locations, onAdvancedFilterPressed, onChangeLocationText,
  loadingLocation, onLocationOptionSelected, onDownloadPressed,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_ROOMS}
      uiPage={RXSTATE_ROOM_PAGE}
      moreMenus={[
        {
          caption: GlobalLocalizedString.common.buttonCaptionEnable,
          disabled: currentRoomStatus === STATUS_ENABLED || enablingDisabling,
          onPress: () => onContextMenuPressed(tappedId,
            GlobalLocalizedString.common.msgEnableConfirmation),
        },
        {
          caption: GlobalLocalizedString.common.buttonCaptionDisable,
          disabled: currentRoomStatus === STATUS_DISABLED || enablingDisabling,
          onPress: () => onContextMenuPressed(tappedId,
            GlobalLocalizedString.common.msgDisableConfirmation),
        },
      ]}
      tableColumns={[
        {
          title: GlobalLocalizedString.common.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.roomPage.labelName, field: 'name', sorting: !downloading },
        { title: LocalizedString.roomPage.labelDescription, field: 'description', sorting: !downloading },
        { title: LocalizedString.roomPage.labelLocation, field: 'location.name', sorting: !downloading },
        {
          title: GlobalLocalizedString.common.placeholderCreatedDate,
          field: 'createdDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
      ]}
      filterColumns={[
        {
          title: LocalizedString.eventRegistrationPage.labelCreatedDate,
          field: 'createdDate',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.roomPage.labelLocation,
          field: 'location',
          type: FILTER_TYPE_DROPDOWN,
          data: locations,
          loading: loadingLocation,
          onChangeFilterText: (text) => onChangeLocationText(text, ADVANCED_FILTER_MODE),
          useDropdownValue: true,
        },
        {
          title: LocalizedString.roomPage.labelStatus, field: 'status', type: FILTER_TYPE_DROPDOWN, data: status, useDropdownValue: true,
        },
      ]}
      handleSubmit={handleSubmit}
      onAppear={onAppear}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmContextMenuPressed}
      onCreatePressed={onCreatePressed}
      onDownloadPressed={onDownloadPressed}
      onEditPressed={(id) => onEditPressed(id)}
      onRefresh={onRefresh}
      onSavePressed={onSavePressed}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onSubmitPressed={onSubmitPressed}
      onViewPressed={(id) => onViewPressed(id)}
      createNewButtonCaption={
        LocalizedString.roomPage.buttonCaptionCreateNewRoom
      }
      deleteButtonCaption={LocalizedString.roomPage.buttonCaptionDeleteRoom}
      editButtonCaption={LocalizedString.roomPage.buttonCaptionEditRoom}
      title={LocalizedString.roomPage.title}
      addDialogHeaderContainerStyle={classes.dialogHeader}
      onDeletePressed={onDeletePressed}
      useFullWidth
      createPermissionName="EVENT_CREATE_ROOM"
      deletePermissionName="EVENT_DELETE_ROOM"
      editPermissionName="EVENT_EDIT_ROOM"
      savePermissionName="EVENT_GET_ROOM"
    >
      {renderDialogContent(addingEditing, pageMode, initialValues.location, locations,
        loadingLocation, onChangeLocationText, onLocationOptionSelected)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_ROOM,
  validate: rxformValidateRoom,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(RoomPage);

RoomPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  loadingLocation: PropTypes.bool.isRequired,
  currentRoomStatus: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
  onAppear: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeLocationText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onLocationOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
