import {
  ADD_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS,
} from '../action';

const initialState = {
  data: {},
  meta: {
    currentPage: 0,
    totalPages: 0,
    totalCount: 0,
  },
  count: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATIONS:
      return {
        ...state,
        data: { ...state.data, ...action.notification.data },
        meta: { ...action.notification.meta },
        count: { ...action.notification.count },
      };
    case CLEAR_NOTIFICATIONS:
      return initialState;
    default: return state;
  }
};
