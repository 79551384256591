import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  PAGE_MODE_VIEW, RXFIELD_SERVICE_NAME, RXFORM_SERVICE,
  RXSTATE_SERVICES, RXSTATE_SERVICE_PAGE, RXFIELD_SERVICE_DESCRIPTION,
} from '../../constant';
import { renderReduxFormOutlinedTextField } from '../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateService } from '../../validation';

const renderDialogContent = (addingEditing, pageMode) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Field
        name={RXFIELD_SERVICE_NAME}
        component={renderReduxFormOutlinedTextField}
        placeholder={LocalizedString.serviceScreen.placeholderServiceName}
        label={LocalizedString.serviceScreen.placeholderServiceName}
        disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
        required
      />
    </Grid>
    <Grid item sm md>
      <Field
        name={RXFIELD_SERVICE_DESCRIPTION}
        component={renderReduxFormOutlinedTextField}
        placeholder={LocalizedString.serviceScreen.placeholderServiceDescription}
        label={LocalizedString.serviceScreen.placeholderServiceDescription}
        disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
        required
        multiline
      />
    </Grid>
  </Grid>
);

const ServicePage = ({
  addingEditing, downloading,
  handleSubmit, onAppear, onCancelPressed, onChangePage, onChangePageSize,
  onConfirmDeletePressed, onCreatePressed, onDeletePressed, onEditPressed, onRefresh,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed,
  onViewPressed, pageMode,
}) => (
  <FunctionalPage
    data={RXSTATE_SERVICES}
    uiPage={RXSTATE_SERVICE_PAGE}
    tableColumns={[
      {
        title: LocalizedString.serviceScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.serviceScreen.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.serviceScreen.labelDescription, field: 'description', sorting: !downloading },
      { title: LocalizedString.serviceScreen.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.serviceScreen.buttonCaptionCreateNewService}
    deleteButtonCaption={LocalizedString.serviceScreen.buttonCaptionDeleteService}
    editButtonCaption={LocalizedString.serviceScreen.buttonCaptionEditService}
    title={LocalizedString.serviceScreen.title}
    useFullWidth
    createPermissionName="AUTH_CREATE_SERVICE"
    deletePermissionName="AUTH_DELETE_SERVICE"
    editPermissionName="AUTH_EDIT_SERVICE"
  >
    {renderDialogContent(addingEditing, pageMode)}
  </FunctionalPage>
);


export default reduxForm({
  form: RXFORM_SERVICE,
  validate: rxformValidateService,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ServicePage);

ServicePage.propTypes = {
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
