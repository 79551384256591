import { isEmpty } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  change, formValueSelector, reset, touch, untouch,
} from 'redux-form';
import {
  ENUM_FIELD_DATE_TIME, ENUM_FIELD_PHOTO, ENUM_FIELD_TIME, HOUR_FORMAT, RXFORM_DYNAMIC_FORM,
} from '../../constant';
import { toMomentUtc } from '../../helper';
import {
  downloadDynamicFormAsync, setAlertErrorMessage, setDynamicFormBody, setDynamicFormViewedImage,
  setDynamicFormViewImageModal,
} from '../../redux/action';
import DynamicForm from './dynamic-form.presentation';

const selector = formValueSelector(RXFORM_DYNAMIC_FORM);

const getFields = (fields, isChildFields = false) => {
  if (!isEmpty(fields)) {
    const visibleFields = fields.filter((x) => x.visible === true);
    const parentFields = visibleFields.filter((field) => !field.parent);
    const childFields = visibleFields.filter((field) => field.parent);
    const arr = isChildFields ? childFields : parentFields;
    const categorizedFields = Object.values(arr.reduce((obj, item) => {
      if (isChildFields) {
        if (Object.getOwnPropertyNames(obj).includes(item.parent.id)) {
          return ({ ...obj, ...obj[item.parent.id].push(item) });
        }
        return ({ ...obj, [item.parent.id]: [item] });
      }
      if (Object.getOwnPropertyNames(obj).includes(item.sectionId)) {
        return ({ ...obj, ...obj[item.sectionId].push(item) });
      }
      return ({ ...obj, [item.sectionId]: [item] });
    }, {}));

    const modifyCategorizedFields = categorizedFields.map((data) => {
      if (isChildFields) {
        return ({
          parentId: data[0].parent.id,
          data,
        });
      }
      return ({
        sectionId: data[0].sectionId,
        sectionLabel: data[0].sectionLabel,
        data,
      });
    });

    return modifyCategorizedFields;
  }
  return [];
};

const getSelectors = (state, ownProps) => {
  const additionalFields = ownProps?.additionalFields || [];
  const fields = state.dynamicForm?.fields || [];
  const allFields = [...fields, ...additionalFields];
  return (allFields.length ? allFields.map((field) => ({
    fieldId: field?.id || '',
    fieldType: field.fieldType,
    fieldName: field.name,
    value: selector(state, field.name),
    dateTimeMinValue: field?.dateTimeMinValue || '',
    dateTimeMaxValue: field?.dateTimeMaxValue || '',
    visible: field?.visible || true,
  })) : []);
};

const getInitVal = (data) => (data.length ? data
  .map((x) => ({ [x.name]: x.defaultValue ? x.defaultValue : null }))
  .reduce((obj, item) => ({ ...obj, ...item }), {}) : {});

const mapStateToProps = (state, ownProps) => ({
  initialValues: getInitVal([
    ...state.dynamicForm?.fields || [],
    ...ownProps.additionalFields || [],
  ]),
  data: getFields(state.dynamicForm?.fields || []),
  childFields: getFields(state.dynamicForm?.fields || [], true),
  selectors: getSelectors(state, ownProps),
  downloading: state.uiDynamicForm.downloading,
  viewImageModal: state.uiDynamicForm.viewImageModal,
  viewedImage: state.uiDynamicForm.viewedImage,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: (id, serverAddress) => {
    dispatch(reset(RXFORM_DYNAMIC_FORM));
    dispatch(setDynamicFormBody({}));
    dispatch(downloadDynamicFormAsync(id, serverAddress))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onCloseViewImage: () => {
    dispatch(setDynamicFormViewImageModal(false));
    dispatch(setDynamicFormViewedImage(''));
  },
  onResetField: (fieldName, defaultValue) => {
    dispatch(change(RXFORM_DYNAMIC_FORM, fieldName, defaultValue));
    dispatch(untouch(RXFORM_DYNAMIC_FORM, fieldName));
  },
  onSubmitPressed: (selectors, onSubmit, pictureFields) => {
    const body = selectors.map((x) => {
      let value = null;
      if (x.value || typeof x.value === 'boolean') {
        if (x.fieldType === ENUM_FIELD_TIME) {
          value = moment(x.value).format(HOUR_FORMAT);
        } else if (x.fieldType === ENUM_FIELD_DATE_TIME) {
          value = toMomentUtc(x.value).format();
        } else if (x.fieldType === ENUM_FIELD_PHOTO) {
          value = x.value.replace(/^.+base64,/, '');
        } else {
          // eslint-disable-next-line prefer-destructuring
          value = x.value.toString();
        }
      }
      const currentPictureField = pictureFields
        .find((pictureField) => x.fieldName === pictureField.fieldName);
      const fileExtension = currentPictureField?.fileName?.split('.').pop().toLowerCase() || null;

      return ({
        fieldId: x.fieldId,
        fieldName: x.fieldName,
        value,
        ...(x.fieldType === ENUM_FIELD_PHOTO && { fileExtension }),
      });
    });

    dispatch(setDynamicFormBody(body));
    onSubmit(body);
  },
  onTouchField: (fieldName) => {
    dispatch(touch(RXFORM_DYNAMIC_FORM, fieldName));
  },
  onViewImage: (image) => {
    dispatch(setDynamicFormViewImageModal(true));
    dispatch(setDynamicFormViewedImage(image));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DynamicForm);
