import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  clearDistricts, clearSubdistricts, setActiveSideMenuItem, setAlertConfirmationMessage,
  setAlertErrorMessage, setDistrictAdvancedFilterDialogSelectedFilterString, setDistrictSearchText,
  setSubdistrictAdvancedFilterDialogSelectedFilterString, setSubdistrictSearchText,
  setSubdistrictSelectedDistrictId, setSubdistrictSelectedOrderBy, setSubdistrictSelectedPageSize,
  setSubdistrictTappedId,
  addEditSubdistrictAsync, downloadDistrictsAsync, downloadSubdistrictsAsync,
  enableDisableSubdistrictAsync, resyncSubdistrictAsync, saveSubdistrictsAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_SUBDISTRICTS, PAGE_MODE_TABLE, RXFORM_SUBDISTRICT, RXFIELD_SUBDISTRICT_DISTRICT,
  MENUID_MASTERDATA_SUBDISTRICT,
  ADVANCED_FILTER_MODE,
} from '../../constant';
import { getPermission, transformDropdownData, transformInitialValues } from '../../helper';
import LocalizedString from '../../localization';
import SubdistrictPage from './subdistrict.presentation';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../module/configuration/redux/action';

const getInitialValues = (state) => {
  const { subdistricts, uiSubdistrict, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiSubdistrict;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? subdistricts.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      { district: { label: found.district.name, value: found.district.id } })
    : {
      name: '',
      district: { label: '', value: '' },
      code: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  districts: transformDropdownData(state.districts.data),
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiSubdistrict.addingEditing,
  downloading: state.uiSubdistrict.downloading,
  loadingDistrict: state.uiDistrict.downloading,
  enablingDisabling: state.uiSubdistrict.enablingDisabling,
  resyncing: state.uiSubdistrict.resyncing,
  tappedId: state.uiFunctionalPage.tappedId || '',
  currentSubdistrictStatus: state.subdistricts.data[state.uiFunctionalPage.tappedId]
    ? state.subdistricts.data[state.uiFunctionalPage.tappedId].status : '',
  initialValues: getInitialValues(state),
  isResyncAllowed: getPermission(state, 'MDATA_RESYNC_MASTERDATA'),
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setDistrictAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setDistrictSearchText(''));
    dispatch(clearDistricts());
    dispatch(downloadDistrictsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setSubdistrictAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_MASTERDATA_SUBDISTRICT));
    dispatch(setSubdistrictSearchText(''));
    dispatch(clearSubdistricts());
    dispatch(setSubdistrictSelectedPageSize(20));
    dispatch(setSubdistrictSelectedOrderBy(INITIAL_ORDER_BY_SUBDISTRICTS));
    dispatch(downloadSubdistrictsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/district/, 'district.id');
    dispatch(setSubdistrictAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearSubdistricts());
    dispatch(downloadSubdistrictsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_SUBDISTRICT));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadSubdistrictsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setSubdistrictSelectedPageSize(pageSize));
    dispatch(downloadSubdistrictsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeDistrictText: async (text, mode) => {
    try {
      dispatch(setDistrictAdvancedFilterDialogSelectedFilterString(mode === ADVANCED_FILTER_MODE ? '' : 'status=enabled'));
      dispatch(setDistrictSearchText(text));
      dispatch(clearDistricts());
      await dispatch(downloadDistrictsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case LocalizedString.common.msgResyncConfirmation:
        dispatch(resyncSubdistrictAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableSubdistrictAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setSubdistrictTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onCreatePressed: () => {
    dispatch(setSubdistrictTappedId(''));
    dispatch(setDistrictAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setDistrictSearchText(''));
    dispatch(clearDistricts());
    dispatch(downloadDistrictsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDownloadPressed: () => {
    dispatch(saveSubdistrictsAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onEditPressed: (id) => {
    dispatch(setDistrictAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setDistrictSearchText(''));
    dispatch(clearDistricts());
    dispatch(downloadDistrictsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setSubdistrictTappedId(id));
  },
  onDistrictOptionSelected: (option) => {
    if (option) {
      dispatch(setSubdistrictSelectedDistrictId(option.value));
      dispatch(change(RXFORM_SUBDISTRICT, RXFIELD_SUBDISTRICT_DISTRICT, option));
    } else {
      dispatch(setSubdistrictSelectedDistrictId(''));
      dispatch(change(RXFORM_SUBDISTRICT, RXFIELD_SUBDISTRICT_DISTRICT, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setSubdistrictSelectedPageSize(pageSize));
    dispatch(clearSubdistricts());
    dispatch(downloadSubdistrictsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setSubdistrictAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearSubdistricts());
    dispatch(setSubdistrictSelectedOrderBy(INITIAL_ORDER_BY_SUBDISTRICTS));
    dispatch(downloadSubdistrictsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ name, code }) => {
    try {
      await dispatch(addEditSubdistrictAsync(name, code));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setSubdistrictSearchText(text));
      dispatch(clearSubdistricts());
      await dispatch(downloadSubdistrictsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setSubdistrictSelectedOrderBy(orderBy));
    dispatch(clearSubdistricts());
    dispatch(downloadSubdistrictsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ name, code }) => {
    await dispatch(addEditSubdistrictAsync(name, code));
  },
  onViewPressed: (id) => {
    dispatch(setSubdistrictTappedId(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SubdistrictPage);
