import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_SESSION_OPERATOR_INVITATIONS, MENUID_SESSION_OPERATOR_INVITATION,
  PAGE_MODE_TABLE, RXFIELD_SESSION_OPERATOR_INVITATION_SESSION,
  RXFIELD_SESSION_OPERATOR_INVITATION_RECIPIENTS, RXFORM_SESSION_OPERATOR_INVITATION,
} from '../../constant';
import {
  debounceSearch, getPermission, getTimeRegion, localDateToUtc, toMoment, transformDropdownData,
  transformEmailDropdownData, transformInitialValues,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import {
  clearSessionOperatorInvitations, clearProfiles, setActiveSideMenuItem,
  setAlertConfirmationMessage, setAlertErrorMessage,
  setSessionOperatorInvitationAdvancedFilterDialogSelectedFilterString,
  setSessionOperatorInvitationSearchText, setSessionOperatorInvitationSelectedSessionId,
  setSessionOperatorInvitationSelectedOrderBy, setSessionOperatorInvitationSelectedPageSize,
  setSessionOperatorInvitationSelectedRecipient, setSessionOperatorInvitationTappedId,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
  addSessionOperatorInvitationAsync, downloadDeleteSessionOperatorInvitationAsync,
  downloadSessionOperatorInvitationsAsync, resendSessionOperatorInvitationAsync,
  saveSessionOperatorInvitationsAsync,
} from '../../redux/action';
import { downloadProfilesAsync } from '../../../../redux/action';
import SessionOperatorInvitationPage from './session-operator-invitation.presentation';
import {
  clearSessions, downloadSessionsAsync, setSessionAdvancedFilterDialogSelectedFilterString,
  setSessionSearchText,
} from '../../../event/redux/action';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getInitialValues = (state) => {
  const { sessionOperatorInvitations, uiSessionOperatorInvitation, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiSessionOperatorInvitation;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? sessionOperatorInvitations.data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    endTime: found.session?.endTime ? toMoment(found?.session.endTime, found.session.location.city.province.timeZone) : '',
    sessionId: found.session?.id || '',
    fullName: found.user?.fullName || '',
    location: found.session?.location?.name || '',
    name: found.session?.name || '',
    phone: found.user?.phone || '',
    registeredOn: found.registeredOn ? localDateToUtc(found.registeredOn, found.session.location.city.province.timeZone) : '',
    shortDescription: found.session?.shortDescription || '',
    startTime: found.session?.startTime ? toMoment(found.session.startTime, found.session.location.city.province.timeZone) : '',
    userId: found?.user?.id || '',
    timeZone: found.session?.startTime || found.session?.endTime ? getTimeRegion(found.session.location.city.province.timeZone) : '',
  }) : {
    endTime: '',
    email: '',
    session: { label: '', value: '' },
    sessionId: '',
    fullname: '',
    invitationStatus: { label: '', value: '' },
    name: '',
    phone: '',
    recipients: [],
    resentCount: 0,
    registeredOn: '',
    shortDescription: '',
    startTime: '',
    userId: '',
    timeZone: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  sessions: transformDropdownData(state.sessions.data),
  profiles: transformEmailDropdownData(state.profiles.data),
  addingEditing: state.uiSessionOperatorInvitation.addingEditing,
  downloading: state.uiSessionOperatorInvitation.downloading,
  downloadingSessions: state.uiSession.downloading,
  downloadingProfiles: state.uiProfile.downloading,
  isResendAllowed: getPermission(state, 'EVENT_RESEND_SESSION_INVITATION_FOR_OPERATOR'),
  resending: state.uiSessionOperatorInvitation.resending,
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
});

const searchSessionDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearSessions());
    dispatch(downloadSessionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchProfileDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setSessionAdvancedFilterDialogSelectedFilterString('status=enabled|visibilityStatus=visible'));
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setSessionSearchText(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearSessions());
    dispatch(clearProfiles());
    dispatch(downloadSessionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAppear: () => {
    dispatch(setSessionOperatorInvitationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_SESSION_OPERATOR_INVITATION));
    dispatch(setSessionOperatorInvitationSearchText(''));
    dispatch(clearSessionOperatorInvitations());
    dispatch(setSessionOperatorInvitationSelectedPageSize(20));
    dispatch(setSessionOperatorInvitationSelectedOrderBy(
      INITIAL_ORDER_BY_SESSION_OPERATOR_INVITATIONS,
    ));
    dispatch(downloadSessionOperatorInvitationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/session/, 'session.id');
    dispatch(setSessionOperatorInvitationAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearSessionOperatorInvitations());
    dispatch(downloadSessionOperatorInvitationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_SESSION_OPERATOR_INVITATION));
    dispatch(setSessionOperatorInvitationSelectedRecipient([]));
    dispatch(setSessionOperatorInvitationSelectedSessionId(''));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadSessionOperatorInvitationsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setSessionOperatorInvitationSelectedPageSize(pageSize));
    dispatch(downloadSessionOperatorInvitationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeSessionText: (text) => {
    dispatch(setSessionAdvancedFilterDialogSelectedFilterString('status=enabled|visibilityStatus=visible'));
    dispatch(setSessionSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchSessionDebounce(dispatch, true);
    }
  },
  onChangeRecipientText: (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchProfileDebounce(dispatch, true);
    }
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(downloadDeleteSessionOperatorInvitationAsync())
          .catch((error) => dispatch(setAlertErrorMessage(error))); break;
      default:
        dispatch(resendSessionOperatorInvitationAsync())
          .catch((error) => dispatch(setAlertErrorMessage(error))); break;
    }
  },
  onCreatePressed: () => {
    dispatch(setSessionOperatorInvitationTappedId(''));
    dispatch(setSessionAdvancedFilterDialogSelectedFilterString('status=enabled|visibilityStatus=visible'));
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setSessionSearchText(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearSessions());
    dispatch(clearProfiles());
    dispatch(downloadSessionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onDeletePressed: (id) => {
    dispatch(setSessionOperatorInvitationTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onDownloadPressed: () => {
    dispatch(saveSessionOperatorInvitationsAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onSessionOptionSelected: (option) => {
    if (option) {
      dispatch(setSessionOperatorInvitationSelectedSessionId(option.value));
      dispatch(change(RXFORM_SESSION_OPERATOR_INVITATION,
        RXFIELD_SESSION_OPERATOR_INVITATION_SESSION, option));
    } else {
      dispatch(setSessionOperatorInvitationSelectedSessionId(''));
      dispatch(change(RXFORM_SESSION_OPERATOR_INVITATION, RXFIELD_SESSION_OPERATOR_INVITATION_SESSION, ''));
    }
  },
  onRecipientsOptionSelected: (option) => {
    if (option) {
      dispatch(setSessionOperatorInvitationSelectedRecipient(option));
      dispatch(change(RXFORM_SESSION_OPERATOR_INVITATION,
        RXFIELD_SESSION_OPERATOR_INVITATION_RECIPIENTS, option));
    } else {
      dispatch(setSessionOperatorInvitationSelectedRecipient([]));
      dispatch(change(RXFORM_SESSION_OPERATOR_INVITATION,
        RXFIELD_SESSION_OPERATOR_INVITATION_RECIPIENTS, []));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setSessionOperatorInvitationSelectedPageSize(pageSize));
    dispatch(clearSessionOperatorInvitations());
    dispatch(downloadSessionOperatorInvitationsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onResendPressed: (id) => {
    dispatch(setSessionOperatorInvitationTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.sessionInvitationPage
      .msgResendConfirmation));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setSessionOperatorInvitationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearSessionOperatorInvitations());
    dispatch(setSessionOperatorInvitationSelectedOrderBy(
      INITIAL_ORDER_BY_SESSION_OPERATOR_INVITATIONS,
    ));
    dispatch(downloadSessionOperatorInvitationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setSessionOperatorInvitationSearchText(text));
      dispatch(clearSessionOperatorInvitations());
      await dispatch(downloadSessionOperatorInvitationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setSessionOperatorInvitationSelectedOrderBy(orderBy));
    dispatch(clearSessionOperatorInvitations());
    dispatch(downloadSessionOperatorInvitationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ session, recipients }) => {
    await dispatch(addSessionOperatorInvitationAsync(session, recipients));
  },
  onViewPressed: (id) => {
    dispatch(setSessionOperatorInvitationTappedId(id));
    dispatch(downloadDeleteSessionOperatorInvitationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionOperatorInvitationPage);
