import { reset } from 'redux-form';
import {
  addingEditingProfile, clearProfiles, setAlertErrorMessage, setProfileSelectedBloodType,
  setProfileSelectedBranchId, setProfileSelectedGender, setFunctionalPageMode,
  setProfileSelectedWorkingShiftId,
} from '../simple-action';
import { editProfile } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_PROFILE } from '../../../constant';
import downloadProfilesAsync from './downloadProfilesAsync';

export default (startWorkingHour, startWorkingHourMarginBeforeMinutes,
  startWorkingHourMarginAfterMinutes, endWorkingHour, endWorkingHourMarginBeforeMinutes,
  endWorkingHourMarginAfterMinutes, earliestClockInDriftTimeMinutes,
  autoClockOutAfterMinutes, workingHourTimeZone) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingProfile(true));

    const { token } = getState().authentication;
    const {
      tappedId,
      selectedBranchId,
      selectedWorkingShift,
    } = getState().uiProfile;
    const { data } = getState().profiles;

    const {
      employeeId, dateOfBirth, gender, jobTitle, department, division, userCompany, manager,
      profilePicture, branch,
    } = data[tappedId];

    const STORAGE_URL_PREFIX = 67;

    const managerId = manager ? manager.id : null;
    const picture = profilePicture ? profilePicture.slice(STORAGE_URL_PREFIX) : null;
    const newBranch = selectedBranchId !== '' ? selectedBranchId : branch.id;
    const workingShiftId = selectedWorkingShift || null;
    const checkWorkingHour = workingHourTimeZone || '';
    const company = userCompany?.id || null;

    await editProfile(tappedId, employeeId, dateOfBirth, gender, jobTitle, department, division,
      company, managerId, picture, startWorkingHour, startWorkingHourMarginBeforeMinutes,
      startWorkingHourMarginAfterMinutes, endWorkingHour, endWorkingHourMarginBeforeMinutes,
      endWorkingHourMarginAfterMinutes, checkWorkingHour, newBranch,
      earliestClockInDriftTimeMinutes,
      autoClockOutAfterMinutes, workingShiftId, token);
    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));

    dispatch(reset(RXFORM_PROFILE));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setProfileSelectedBloodType(''));
    dispatch(setProfileSelectedBranchId(''));
    dispatch(setProfileSelectedGender(''));
    dispatch(setProfileSelectedWorkingShiftId(''));
  } finally {
    dispatch(addingEditingProfile(false));
  }
};
