import { downloadEventOperatorInvitation, resendEventOperatorInvitation } from '../../../helper';
import { addEventOperatorInvitation, resendingEventOperatorInvitation } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resendingEventOperatorInvitation(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiEventOperatorInvitation;

    await resendEventOperatorInvitation(tappedId, token);
    const result = await downloadEventOperatorInvitation(tappedId, token);

    dispatch(addEventOperatorInvitation(result));
  } finally {
    dispatch(resendingEventOperatorInvitation(false));
  }
};
