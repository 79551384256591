import { reset } from 'redux-form';
import { PAGE_MODE_TABLE, RXFORM_SPONSOR_CATEGORY } from '../../../constant';
import { addSponsorCategory, editSponsorCategory } from '../../../helper';
import {
  addingEditingSponsorCategory, clearSponsorCategories, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import downloadSponsorCategoriesAsync from './downloadSponsorCategoriesAsync';

export default (name, description, order) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingSponsorCategory(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSponsorCategory;

    if (tappedId) {
      await editSponsorCategory(tappedId, name, description, order, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addSponsorCategory(name, description, order, token);
    }

    dispatch(reset(RXFORM_SPONSOR_CATEGORY));
    dispatch(clearSponsorCategories());
    dispatch(downloadSponsorCategoriesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingSponsorCategory(false));
  }
};
