import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_GIFT_VOUCHER_REDEMPTIONS,
  MENUID_VOUCHER_GIFT_VOUCHER_REDEMPTION, PAGE_MODE_TABLE, RXFORM_GIFT_VOUCHER_REDEMPTION,
} from '../../constant';
import { transformInitialValues, transformUserDropdownData, debounceSearch } from '../../helper';
import {
  clearGiftVoucherRedemptions, clearProfiles, setActiveSideMenuItem, setAlertErrorMessage,
  setGiftVoucherRedemptionAdvancedFilterDialogSelectedFilterString,
  setGiftVoucherRedemptionSearchText,
  setGiftVoucherRedemptionSelectedPageSize, setGiftVoucherRedemptionSelectedOrderBy,
  setGiftVoucherRedemptionTappedId, setProfileSearchText,
  setProfileAdvancedFilterDialogSelectedFilterString,
  downloadGiftVoucherRedemptionAsync, downloadGiftVoucherRedemptionsAsync,
} from '../../redux/action';
import { downloadProfilesAsync } from '../../../../redux/action';
import GiftVoucherRedemptionPage from './gift-voucher-redemption.presentation';

const getInitialValues = (state) => {
  const {
    giftVoucherRedemptions, uiFunctionalPage, uiGiftVoucherRedemption,
  } = state;
  const { data } = giftVoucherRedemptions;
  const { downloadingDeleting, tappedId } = uiGiftVoucherRedemption;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    merchantName: found.voucherToken?.voucher?.merchant?.name || '',
    voucherId: found.voucherToken?.voucher?.id || '',
    voucherName: found.voucherToken?.voucher?.title || '',
    redeemedById: found.user?.id || '',
    redeemedByName: found.user?.fullName || '',
    voucherTokenId: found.voucherToken?.id || '',
    giftVoucherId: found.voucherTokenAssignment?.id || '',
  }) : {};
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiGiftVoucherRedemption.downloading,
  initialValues: getInitialValues(state),
  profiles: transformUserDropdownData(state.profiles.data),
  loadingProfile: state.uiProfile.downloading,
});

const searchProfileDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setGiftVoucherRedemptionAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_VOUCHER_GIFT_VOUCHER_REDEMPTION));
    dispatch(setGiftVoucherRedemptionSearchText(''));
    dispatch(clearGiftVoucherRedemptions());
    dispatch(setGiftVoucherRedemptionSelectedPageSize(20));
    dispatch(setGiftVoucherRedemptionSelectedOrderBy(INITIAL_ORDER_BY_GIFT_VOUCHER_REDEMPTIONS));
    dispatch(downloadGiftVoucherRedemptionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/redeemedBy/, 'user.id')
      .replace(/giftVoucherId/, 'voucherTokenAssignment.id');
    dispatch(setGiftVoucherRedemptionAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearGiftVoucherRedemptions());
    dispatch(downloadGiftVoucherRedemptionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_GIFT_VOUCHER_REDEMPTION));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadGiftVoucherRedemptionsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setGiftVoucherRedemptionSelectedPageSize(pageSize));
    dispatch(downloadGiftVoucherRedemptionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeProfileText: (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchProfileDebounce(dispatch);
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setGiftVoucherRedemptionSelectedPageSize(pageSize));
    dispatch(clearGiftVoucherRedemptions());
    dispatch(downloadGiftVoucherRedemptionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setGiftVoucherRedemptionAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearGiftVoucherRedemptions());
    dispatch(setGiftVoucherRedemptionSelectedOrderBy(INITIAL_ORDER_BY_GIFT_VOUCHER_REDEMPTIONS));
    dispatch(downloadGiftVoucherRedemptionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setGiftVoucherRedemptionSearchText(text));
      dispatch(clearGiftVoucherRedemptions());
      await dispatch(downloadGiftVoucherRedemptionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setGiftVoucherRedemptionSelectedOrderBy(orderBy));
    dispatch(clearGiftVoucherRedemptions());
    dispatch(downloadGiftVoucherRedemptionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setGiftVoucherRedemptionTappedId(id));
    dispatch(downloadGiftVoucherRedemptionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftVoucherRedemptionPage);
