import {
  DOWNLOADING_PROFILE_SYNCS, ADDING_EDITING_PROFILE_SYNC, DOWNLOADING_DELETING_PROFILE_SYNC,
  SET_PROFILE_SYNC_SELECTED_PAGE_SIZE, SET_PROFILE_SYNC_TAPPED_ID, SET_PROFILE_SYNC_SEARCH_TEXT,
  SET_PROFILE_SYNC_SELECTED_ORDER_BY, SET_PROFILE_SYNC_SELECTED_CONFIG_GROUP,
  SET_PROFILE_SYNC_SELECTED_DATA_TYPE, SET_PROFILE_SYNC_SELECTED_USED_ON,
  SET_PROFILE_SYNC_DULICATE_MODE, ENABLING_DISABLING_PROFILE_SYNC,
} from '../action';
import { INITIAL_ORDER_BY_PROFILE_SYNC } from '../../constant';

const initialState = {
  filterString: '',
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  enablingDisabling: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_PROFILE_SYNC,
  selectedDataType: '',
  selectedUsedOn: '',
  duplicateMode: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_PROFILE_SYNCS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_PROFILE_SYNC:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_PROFILE_SYNC:
      return { ...state, downloadingDeleting: action.status };
    case SET_PROFILE_SYNC_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_PROFILE_SYNC_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_PROFILE_SYNC_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_PROFILE_SYNC_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_PROFILE_SYNC_SELECTED_USED_ON:
      return { ...state, selectedUsedOn: action.option };
    case SET_PROFILE_SYNC_SELECTED_DATA_TYPE:
      return { ...state, selectedDataType: action.option };
    case SET_PROFILE_SYNC_SELECTED_CONFIG_GROUP:
      return { ...state, selectedConfigGroup: action.option };
    case SET_PROFILE_SYNC_DULICATE_MODE:
      return { ...state, duplicateMode: action.status };
    case ENABLING_DISABLING_PROFILE_SYNC:
      return { ...state, enablingDisabling: action.status };
    default: return state;
  }
};
