import { downloadingDynamicForm, setDynamicForm, clearDynamicForm } from '../simple-action';
import { downloadDynamicForm } from '../../../helper';

export default (id, serverAddress) => async (dispatch, getState) => {
  try {
    dispatch(clearDynamicForm());

    dispatch(downloadingDynamicForm(true));

    const { token } = getState().authentication;

    const result = await downloadDynamicForm(id, serverAddress, token);

    dispatch(setDynamicForm(result));
  } finally {
    dispatch(downloadingDynamicForm(false));
  }
};
