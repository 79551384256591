import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  CLOCK_IN_KEY, CLOCK_IN_OUT_STATUS_TREND_EARLY, CLOCK_IN_OUT_STATUS_TREND_LATE,
  CLOCK_IN_OUT_STATUS_TREND_ONTIME, CLOCK_OUT_KEY, DATE_MONTH_FORMAT,
  DATE_TIME_FORMAT_WITHOUT_PIPE, ENUM_FIELD_PHOTO, REGEX_UTC_DATE_FORMAT,
  REGEX_UTC_DATE_TIME_FORMAT, SIMPLE_DATE_FORMAT, TIME_FORMAT,
} from '../../constant';
import {
  getChartPercentage, sortAsc, toKFormat, toMoment, toPascalCase,
} from '../../helper';
import LocalizedString from '../../localization';
import {
  clearSummaryTotalUsers, clearAttendanceSummaryClockInLocationTrend,
  clearAttendanceSummaryTodayClockInLocationStatus, clearAttendanceSummaryTotalClockInToday,
  clearAttendanceSummaryTodayClockInStatus, clearAttendanceSummaryClockInStatusTrend,
  clearAttendanceSummaryClockOutLocationTrend, clearAttendanceSummaryTodayClockOutLocationStatus,
  clearAttendanceSummaryTotalClockOutToday, clearAttendanceSummaryTodayClockOutStatus,
  clearAttendanceSummaryClockOutStatusTrend, setAlertErrorMessage,
  clearAttendanceSummaryClockInArea,
  setAttendanceClockInLocationTrendTooltipTarget, setAttendanceClockInStatusTrendTooltipTarget,
  setAttendanceClockOutLocationTrendTooltipTarget, setAttendanceClockOutStatusTrendTooltipTarget,
  downloadClockInLocationTrendAsync, downloadTodayClockInLocationStatusAsync,
  downloadTodayClockInStatusAsync, downloadClockInStatusTrendAsync, downloadTotalClockInTodayAsync,
  downloadTodayClockOutLocationStatusAsync, downloadClockOutLocationTrendAsync,
  downloadTodayClockOutStatusAsync, downloadClockOutStatusTrendAsync,
  downloadTotalClockOutTodayAsync, downloadAttendanceSummaryTotalUsersAsync,
  downloadClockInAreaAsync, downloadClockOutAreaAsync, downloadClockInAreaHistoryAsync,
  downloadClockOutAreaHistoryAsync,
} from '../../redux/action';
import AttendanceSummaryPage from './attendance-summary.presentation';
import { setAttendanceSummaryTappedClockInAreaId, setAttendanceSummaryTappedClockOutAreaId } from '../../redux/action/simple-action';

const ARGUMENT_FIELD = 'name';
const VALUE_FIELD = 'count';

const getTrends = (state) => {
  if (Object.values(state).length < 1) {
    return [];
  }
  const formatGroupData = Object.values(state)
    .sort((a, b) => sortAsc(a.groupName, b.groupName))
    .map((item) => ({
      date: toMoment(item.groupName).format(DATE_MONTH_FORMAT),
      [item.name]: item.count,
    }));
  let currentDateIteration;
  const trends = formatGroupData.reduce((previous, current) => {
    if (!isEmpty(previous) && currentDateIteration === current.date) {
      const currentDateIterationIndex = previous.findIndex((item) => item.date === current.date);
      const newTrendItem = {
        ...previous[currentDateIterationIndex],
        ...current,
      };
      const newTrend = { ...previous, [currentDateIterationIndex]: newTrendItem };
      const newTrendArray = Object.values(newTrend);
      return newTrendArray;
    } if (!isEmpty(previous) && currentDateIteration !== current.date) {
      currentDateIteration = current.date;
      const newTrendArray = [...previous, current];
      return newTrendArray;
    }
    currentDateIteration = current.date;
    return [current];
  }, []);

  const trendsPadded = trends.map((item) => {
    const isEarlyKeyExist = Object.keys(item).find((key) => key
      === CLOCK_IN_OUT_STATUS_TREND_EARLY);
    const isOnTimeKeyExist = Object.keys(item).find((key) => key
      === CLOCK_IN_OUT_STATUS_TREND_ONTIME);
    const isLateKeyExist = Object.keys(item).find((key) => key === CLOCK_IN_OUT_STATUS_TREND_LATE);
    let newItem;
    if (isEarlyKeyExist && isOnTimeKeyExist && isLateKeyExist) {
      return item;
    }
    if (!isEarlyKeyExist) {
      newItem = { ...item, [CLOCK_IN_OUT_STATUS_TREND_EARLY]: 0 };
    }
    if (!isOnTimeKeyExist) {
      newItem = { ...item, ...newItem, [CLOCK_IN_OUT_STATUS_TREND_ONTIME]: 0 };
    }
    if (!isLateKeyExist) {
      newItem = { ...item, ...newItem, [CLOCK_IN_OUT_STATUS_TREND_LATE]: 0 };
    }
    return newItem;
  });

  return trendsPadded;
};

const getClockInOutArea = (state, key) => {
  const downloading = state.uiAttendanceSummary[`downloading${toPascalCase(key)}Area`];

  const dataState = state[`${key}Area`];

  if (!downloading && dataState) {
    const data = Object.values(dataState);
    return data.length ? data.map((x) => ({
      ...x,
      title: x?.profile?.fullName,
      position: {
        lat: x[`${key}Latitude`],
        lng: x[`${key}Longitude`],
      },
    })) : [];
  }
  return [];
};

const getClockInOutAreaProfile = (state, key) => {
  const {
    histories: { data },
    uiAttendanceSummary,
    uiProfile: { downloadingDeleting },
  } = state;
  const tappedId = uiAttendanceSummary[`tapped${toPascalCase(key)}AreaId`];
  const found = data[tappedId];
  if (!downloadingDeleting && found) {
    return {
      ...found,
      workingHour: `${found.startWorkingHour ? found.startWorkingHour : LocalizedString.historyScreen.labelNA} - ${found.endWorkingHour ? found.endWorkingHour : LocalizedString.historyScreen.labelNA}`,
      clockInTime: found.clockInTime ? toMoment(found.clockInTime, found.startWorkingHourTimeZone).format(TIME_FORMAT) : '',
      clockOutTime: found.clockOutTime ? toMoment(found.clockOutTime, found.endWorkingHourTimeZone).format(TIME_FORMAT) : '',
      attendanceDate: found.clockInTime ? toMoment(found.clockInTime, found.startWorkingHourTimeZone).format(SIMPLE_DATE_FORMAT) : '',
      additionalFields: found.additionalFields && found.additionalFields.length > 0
        ? [
          ...found.additionalFields.filter((x) => x.field.fieldType !== ENUM_FIELD_PHOTO)
            .map((x) => {
              if (x.value.match(REGEX_UTC_DATE_TIME_FORMAT)) {
                return {
                  label: x.field.name,
                  value: toMoment(x.value).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
                };
              }
              if (x.value.match(REGEX_UTC_DATE_FORMAT)) {
                return { label: x.field.name, value: toMoment(x.value).format(SIMPLE_DATE_FORMAT) };
              }
              return { label: x.field.name, value: x.value };
            }).sort((a, b) => sortAsc(a.label, b.label)),
          ...found.additionalFields.filter((x) => x.field.fieldType === ENUM_FIELD_PHOTO)
            .map((x) => ({ label: x.field.name, value: x.value, type: x.field.fieldType }))
            .sort((a, b) => sortAsc(a.label, b.label)),
        ] : [],
    };
  }
  return { additionalFields: [] };
};

const mapStateToProps = (state) => ({
  downloadingTotalUsers: state.uiAttendanceSummary.downloadingTotalUsers,
  downloadingTotalClockInToday: state.uiAttendanceSummary.downloadingTotalClockInToday,
  downloadingTotalClockOutToday: state.uiAttendanceSummary.downloadingTotalClockOutToday,
  downloadingTodayClockInStatus: state.uiAttendanceSummary.downloadingTodayClockInStatus,
  downloadingTodayClockOutStatus: state.uiAttendanceSummary.downloadingTodayClockOutStatus,
  downloadingTodayClockInLocationStatus:
    state.uiAttendanceSummary.downloadingTodayClockInLocationStatus,
  downloadingTodayClockOutLocationStatus:
    state.uiAttendanceSummary.downloadingTodayClockOutLocationStatus,
  downloadingClockInStatusTrend: state.uiAttendanceSummary.downloadingClockInStatusTrend,
  downloadingClockOutStatusTrend: state.uiAttendanceSummary.downloadingClockOutStatusTrend,
  downloadingClockInLocationTrend: state.uiAttendanceSummary.downloadingClockInLocationTrend,
  downloadingClockOutLocationTrend: state.uiAttendanceSummary.downloadingClockOutLocationTrend,
  downloadingClockInArea: state.uiAttendanceSummary.downloadingClockInArea,
  downloadingClockOutArea: state.uiAttendanceSummary.downloadingClockOutArea,
  totalUsers: Object.keys(state.attendanceSummaryTotalUsers).length > 0
    ? toKFormat(state.attendanceSummaryTotalUsers.count) : 0,
  totalClockInToday: state.totalClockInToday.count,
  totalClockOutToday: state.totalClockOutToday.count,
  todayClockInStatus: getChartPercentage(state.todayClockInStatus, ARGUMENT_FIELD, VALUE_FIELD),
  todayClockOutStatus: getChartPercentage(state.todayClockOutStatus, ARGUMENT_FIELD, VALUE_FIELD),
  todayClockInLocationStatus: getChartPercentage(state.todayClockInLocationStatus,
    ARGUMENT_FIELD, VALUE_FIELD),
  todayClockOutLocationStatus: getChartPercentage(state.todayClockOutLocationStatus,
    ARGUMENT_FIELD, VALUE_FIELD),
  clockInStatusTrend: getTrends(state.clockInStatusTrend),
  clockOutStatusTrend: getTrends(state.clockOutStatusTrend),
  clockInStatusTrendTargetItem: state.uiAttendanceSummary.clockInStatusTrendTargetItem,
  clockOutStatusTrendTargetItem: state.uiAttendanceSummary.clockOutStatusTrendTargetItem,
  clockInLocationTrend: getTrends(state.clockInLocationTrend),
  clockOutLocationTrend: getTrends(state.clockOutLocationTrend),
  clockInLocationTrendTargetItem: state.uiAttendanceSummary.clockInLocationTrendTargetItem,
  clockOutLocationTrendTargetItem: state.uiAttendanceSummary.clockOutLocationTrendTargetItem,
  clockInArea: getClockInOutArea(state, CLOCK_IN_KEY),
  clockOutArea: getClockInOutArea(state, CLOCK_OUT_KEY),
  selectedClockInAreaProfile: getClockInOutAreaProfile(state, CLOCK_IN_KEY),
  selectedClockOutAreaProfile: getClockInOutAreaProfile(state, CLOCK_OUT_KEY),
  downloadingClockInAreaProfile: state.uiAttendanceSummary.downloadingClockInAreaHistory,
  downloadingClockOutAreaProfile: state.uiAttendanceSummary.downloadingClockOutAreaHistory,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(clearAttendanceSummaryClockInArea());
    dispatch(clearSummaryTotalUsers());
    dispatch(clearAttendanceSummaryTotalClockInToday());
    dispatch(clearAttendanceSummaryTotalClockOutToday());
    dispatch(clearAttendanceSummaryTodayClockInStatus());
    dispatch(clearAttendanceSummaryTodayClockOutStatus());
    dispatch(clearAttendanceSummaryTodayClockInLocationStatus());
    dispatch(clearAttendanceSummaryTodayClockOutLocationStatus());
    dispatch(clearAttendanceSummaryClockInStatusTrend());
    dispatch(clearAttendanceSummaryClockOutStatusTrend());
    dispatch(clearAttendanceSummaryClockInLocationTrend());
    dispatch(clearAttendanceSummaryClockOutLocationTrend());
    dispatch(downloadClockInAreaAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadClockOutAreaAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadAttendanceSummaryTotalUsersAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadTotalClockInTodayAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadTotalClockOutTodayAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadTodayClockInStatusAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadTodayClockOutStatusAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadTodayClockInLocationStatusAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadTodayClockOutLocationStatusAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadClockInStatusTrendAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadClockOutStatusTrendAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadClockInLocationTrendAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadClockOutLocationTrendAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onClockInOutAreaPressed: ({ id }, key) => {
    if (key === CLOCK_IN_KEY) {
      dispatch(setAttendanceSummaryTappedClockInAreaId(id));
      dispatch(downloadClockInAreaHistoryAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else {
      dispatch(setAttendanceSummaryTappedClockOutAreaId(id));
      dispatch(downloadClockOutAreaHistoryAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }
  },
  onClockInStatusTrendTargetItemChange: (targetItem) => {
    dispatch(setAttendanceClockInStatusTrendTooltipTarget(targetItem));
  },
  onClockOutStatusTrendTargetItemChange: (targetItem) => {
    dispatch(setAttendanceClockOutStatusTrendTooltipTarget(targetItem));
  },
  onClockInLocationTrendTargetItemChange: (targetItem) => {
    dispatch(setAttendanceClockInLocationTrendTooltipTarget(targetItem));
  },
  onClockOutLocationTrendTargetItemChange: (targetItem) => {
    dispatch(setAttendanceClockOutLocationTrendTooltipTarget(targetItem));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceSummaryPage);
