import React, { useEffect, useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import ImageViewer from 'react-simple-image-viewer';
import {
  COLOR_BACKGROUND, COLOR_PRIMARY, COLOR_TEXT_PRIMARY,
  DEFAULT_STRING_SEPARATOR, ENUM_FIELD_BOOLEAN, ENUM_FIELD_DATE, ENUM_FIELD_DATE_TIME,
  ENUM_FIELD_FLOAT, ENUM_FIELD_INTEGER, ENUM_FIELD_OPTIONS, ENUM_FIELD_PHOTO, ENUM_FIELD_PICKER,
  ENUM_FIELD_TEXT, ENUM_FIELD_TIME, ENUM_OPTIONS_MODE_BUTTON,
  ENUM_OPTIONS_MODE_CHECKBOX, ENUM_PICKER_MODE_BUTTON, PICKER_MODE_DATE, ENUM_PICKER_MODE_DROPDOWN,
  ENUM_PICKER_MODE_RADIO, PICKER_MODE_TIME, REST_BASE_URL, RXFORM_DYNAMIC_FORM,
} from '../../constant';
import { isMatchArrOfString } from '../../helper';
import LocalizedString from '../../localization';
import {
  renderReduxFormDateTimePickerField, renderReduxFormOutlinedTextField,
  renderReduxFormSimplePicker, renderReduxFormRadioPicker,
  renderReduxFormButtonPicker, renderReduxFormButtonOption, renderReduxFormCheckboxOption,
  renderReduxFormPhotoUpload,
} from '../../redux-form-rendererer';
import {
  AdditionalFieldShape, DynamicFormShape, SectionAdditionalFieldShape, StyleShape,
} from '../../type';
import { rxformValidateDynamicForm } from '../../validation';
import { Body, BodyLarge } from '../labels';
import AccentButton from '../accent-button';
import HorizontalLine from '../horizontal-line';
import LoadingDialog from '../loading-dialog';
import { BOOLEAN_OPTION } from '../../module/dform';

const styles = {
  container: {
    flex: 1,
    padding: '32px 16px',
    backgroundColor: COLOR_BACKGROUND,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    borderRadius: '4px',
  },
  labelText: {
    color: COLOR_TEXT_PRIMARY,
  },
  textFieldContainer: {
    marginTop: '12px',
  },
  sectionContainer: {
    margin: '8px 0',
  },
  sectionLabel: {
    marginTop: '8px',
    fontWeight: 'bold',
  },
  activityIndicator: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px 0',
  },
  divider: {
    marginTop: '24px',
  },
};

const renderLabel = (label, required, marginBottom = 8) => (
  <Body style={{ ...styles.labelText, marginBottom }}>
    {`${label}${required ? '*' : ''}`}
  </Body>
);

const renderSection = (label) => (
  <div style={styles.sectionContainer}>
    <HorizontalLine />
    {label && (<BodyLarge style={styles.sectionLabel}>{label}</BodyLarge>)}
  </div>
);

const renderPickerField = (field) => {
  switch (field.pickerMode) {
    case ENUM_PICKER_MODE_DROPDOWN:
      return (
        <Field
          name={field.name}
          component={renderReduxFormSimplePicker}
          label=""
          placeholder={field.placeHolder}
          disabled={field.readOnly}
          required={field.required}
          data={field.optionValues}
          style={{ marginTop: 0 }}
          showClearButton
          returnString
          hideLabel
        />
      );
    case ENUM_PICKER_MODE_RADIO:
      return (
        <Field
          name={field.name}
          component={renderReduxFormRadioPicker}
          disabled={field.readOnly}
          data={field.optionValues}
        />
      );
    case ENUM_PICKER_MODE_BUTTON:
      return (
        <Field
          name={field.name}
          component={renderReduxFormButtonPicker}
          disabled={field.readOnly}
          data={field.optionValues}
        />
      );
    default: return null;
  }
};

const renderOptionsField = (field) => {
  switch (field.optionMode) {
    case ENUM_OPTIONS_MODE_BUTTON:
      return (
        <Field
          name={field.name}
          component={renderReduxFormButtonOption}
          disabled={field.readOnly}
          data={field.optionValues}
          optionValueSeparator={field.optionValueSeparator}
        />
      );
    case ENUM_OPTIONS_MODE_CHECKBOX:
      return (
        <Field
          name={field.name}
          component={renderReduxFormCheckboxOption}
          disabled={field.readOnly}
          data={field.optionValues}
          optionValueSeparator={field.optionValueSeparator}
        />
      );
    default: return null;
  }
};

const renderFieldByType = (
  field,
  onViewImage,
  setFloatField,
  setIntegerField,
  setTextField,
  floatField,
  integerField,
  textField,
  pictureFields,
  setPictureFields,
) => {
  switch (field.fieldType) {
    case ENUM_FIELD_TEXT:
      return (
        <Field
          name={field.name}
          component={renderReduxFormOutlinedTextField}
          placeholder={field.placeHolder}
          editable={field.readOnly}
          required={field.required}
          multiline={field.textMultiline}
          maxLength={field.textMaxLength}
          value={textField}
          onChange={(text) => setTextField(text)}
        />
      );
    case ENUM_FIELD_DATE:
      return (
        <Field
          pickerMode={PICKER_MODE_DATE}
          name={field.name}
          component={renderReduxFormDateTimePickerField}
          label=""
          disabled={field.readOnly}
          maximumDate={field.dateTimeMaxValue}
          minimumDate={field.dateTimeMinValue}
          hideLabel
        />
      );
    case ENUM_FIELD_DATE_TIME:
      return (
        <Field
          name={field.name}
          component={renderReduxFormDateTimePickerField}
          label=""
          disabled={field.readOnly}
          maximumDate={field.dateTimeMaxValue}
          minimumDate={field.dateTimeMinValue}
          hideLabel
        />
      );
    case ENUM_FIELD_TIME:
      return (
        <Field
          pickerMode={PICKER_MODE_TIME}
          name={field.name}
          component={renderReduxFormDateTimePickerField}
          label=""
          disabled={field.readOnly}
          maximumDate={field.dateTimeMaxValue}
          minimumDate={field.dateTimeMinValue}
          hideLabel
        />
      );
    case ENUM_FIELD_INTEGER:
      return (
        <Field
          name={field.name}
          component={renderReduxFormOutlinedTextField}
          placeholder={field.placeHolder}
          editable={field.readOnly}
          required={field.required}
          value={integerField}
          onChange={(text) => setIntegerField(text)}
        />
      );
    case ENUM_FIELD_FLOAT:
      return (
        <Field
          name={field.name}
          component={renderReduxFormOutlinedTextField}
          placeholder={field.placeHolder}
          editable={field.readOnly}
          required={field.required}
          value={floatField}
          onChange={(text) => setFloatField(text)}
        />
      );
    case ENUM_FIELD_PICKER:
      return renderPickerField(field);
    case ENUM_FIELD_BOOLEAN:
      return (
        <Field
          name={field.name}
          component={renderReduxFormButtonPicker}
          disabled={field.readOnly}
          data={BOOLEAN_OPTION}
          returnByLabel={false}
          booleanMode
        />
      );
    case ENUM_FIELD_OPTIONS:
      return renderOptionsField(field);
    case ENUM_FIELD_PHOTO:
      return (
        <Field
          name={field.name}
          component={renderReduxFormPhotoUpload}
          buttonCaption={field.label}
          disabled={field.readOnly}
          onView={onViewImage}
          mustUseCamera={field.mustUseCamera}
          onValueSelected={(value, fileName) => {
            const fieldName = field.name;
            const existingValue = pictureFields.find((x) => x.fieldName === fieldName);
            if (existingValue) {
              const newPictureFields = pictureFields
                .map((x) => {
                  if (x.fieldName === fieldName) {
                    return { ...x, value, fileName };
                  }
                  return x;
                });
              setPictureFields(newPictureFields);
            } else {
              const newPictureFields = [...pictureFields, { value, fileName, fieldName }];
              setPictureFields(newPictureFields);
            }
          }}
          onDeleteButtonPressed={() => {
            const newPictureFields = pictureFields.filter((x) => x.fieldName !== field.name);
            setPictureFields(newPictureFields);
          }}
        />
      );
    default: return null;
  }
};

const renderLabelAndField = (
  field,
  selectors,
  onTouchField,
  onViewImage,
  setFloatField,
  setIntegerField,
  setTextField,
  floatField,
  integerField,
  textField,
  pictureFields,
  setPictureFields,
) => {
  const value = selectors.find((x) => x.fieldId === field.id)?.value || null;

  if (value === '') {
    onTouchField(field.name);
  }

  return (
    <div style={styles.textFieldContainer} key={field.id}>
      {renderLabel(field.label, field.required)}

      {renderFieldByType(
        field,
        onViewImage,
        setFloatField,
        setIntegerField,
        setTextField,
        floatField,
        integerField,
        textField,
        pictureFields,
        setPictureFields,
      )}
    </div>
  );
};

const resetChildHasChild = (child, childFields, onResetField) => {
  const hasChild = childFields.find((x) => x.parentId === child.id);
  if (hasChild) {
    hasChild.data.forEach((x) => {
      onResetField(x.name, x.defaultValue);
      resetChildHasChild(x, childFields, onResetField);
    });
  }
};

const renderParentChildField = (
  field,
  childFields,
  selectors,
  onResetField,
  onTouchField,
  onViewImage,
  setFloatField,
  setIntegerField,
  setTextField,
  floatField,
  integerField,
  textField,
  pictureFields,
  setPictureFields,
) => {
  const hasChild = childFields.find((child) => child.parentId === field.id);
  const parentValue = selectors.find((x) => x.fieldId === field.id)?.value || null;

  return (
    <div key={field.id}>
      {renderLabelAndField(
        field,
        selectors,
        onTouchField,
        onViewImage,
        setFloatField,
        setIntegerField,
        setTextField,
        floatField,
        integerField,
        textField,
        pictureFields,
        setPictureFields,
      )}

      {!!hasChild && hasChild.data.map((child) => {
        const showIfParentValueArr = child.showIfParentValue
          .split(DEFAULT_STRING_SEPARATOR).sort();

        const parentValueArr = parentValue
          ? parentValue.split(field.optionValueSeparator).sort() : [];

        if (isMatchArrOfString(
          showIfParentValueArr,
          parentValueArr,
          child.showIfParentValueLogicalOperator,
        )) {
          return renderParentChildField(
            child,
            childFields,
            selectors,
            onResetField,
            onTouchField,
            onViewImage,
            setFloatField,
            setIntegerField,
            setTextField,
            floatField,
            integerField,
            textField,
            pictureFields,
            setPictureFields,
          );
        }

        onResetField(child.name, child.defaultValue);

        const childHasChild = childFields.find((x) => x.parentId === child.id);
        if (childHasChild) {
          childHasChild.data.forEach((x) => {
            onResetField(x.name, x.defaultValue);
            resetChildHasChild(x, childFields, onResetField);
          });
        }

        return null;
      })}
    </div>
  );
};

const DynamicForm = ({
  data, childFields, selectors, downloading, disabled, viewImageModal,
  handleSubmit, buttonCaption, formId, serverAddress, viewedImage,
  buttonLocations, buttonStyle, containerStyle,
  onAppear, onCloseViewImage, onResetField, onSubmit, onSubmitPressed, onTouchField,
  onViewImage, additionalFields,
}) => {
  const [integerField, setIntegerField] = useState('');
  const [floatField, setFloatField] = useState('');
  const [textField, setTextField] = useState('');
  const [pictureFields, setPictureFields] = useState([]);

  useEffect(() => onAppear(formId, serverAddress), [formId, serverAddress, onAppear]);

  const formatSelectedImage = [viewedImage];

  const allData = () => {
    const additionalSection = {
      sectionId: 'additionalSection',
      sectionLabel: '',
      data: additionalFields,
    };
    let arr = [];
    if (additionalFields.length) {
      arr = [...arr, additionalSection];
    }
    if (data.length) {
      arr = [...arr, ...data];
    }
    return arr;
  };

  if (downloading) {
    return (
      <div style={styles.activityIndicator}>
        <CircularProgress sx={{ color: COLOR_PRIMARY }} size={20} />
      </div>
    );
  }
  return (
    <div
      style={{ ...styles.container, ...containerStyle }}
    >
      {allData().map((section) => (
        <div key={section.sectionId}>
          {section.sectionId ? renderSection(section.sectionLabel) : null}
          {section.data.map((field) => renderParentChildField(
            field,
            childFields,
            selectors,
            onResetField,
            onTouchField,
            onViewImage,
            setFloatField,
            setIntegerField,
            setTextField,
            floatField,
            integerField,
            textField,
            pictureFields,
            setPictureFields,
          ))}
        </div>
      ))}

      <div style={styles.divider} />

      <AccentButton
        onClick={handleSubmit(() => onSubmitPressed(selectors, onSubmit, pictureFields))}
        caption={buttonCaption || LocalizedString.common.buttonCaptionSubmit}
        locations={buttonLocations}
        containerStyle={buttonStyle}
        loading={disabled}
      />

      {
        viewImageModal && (
          <ImageViewer
            src={formatSelectedImage}
            currentIndex={0}
            disableScroll={false}
            closeOnClickOutside
            onClose={onCloseViewImage}
            backgroundStyle={{ zIndex: 5 }}
          />
        )
      }

      <LoadingDialog visible={disabled} />
    </div>
  );
};

export default reduxForm({
  form: RXFORM_DYNAMIC_FORM,
  destroyOnUnmount: false,
  enableReinitialize: true,
  validate: (values, allValues) => rxformValidateDynamicForm(values, [
    ...allValues.childFields.map((x) => [...x.data]),
    ...allValues.data.map((x) => [...x.data]),
    ...allValues.additionalFields,
  ].flat()),
})(DynamicForm);

DynamicForm.propTypes = {
  data: PropTypes.arrayOf(DynamicFormShape).isRequired,
  childFields: PropTypes.arrayOf(SectionAdditionalFieldShape).isRequired,
  selectors: PropTypes.arrayOf(DynamicFormShape).isRequired,
  additionalFields: PropTypes.arrayOf(AdditionalFieldShape),
  downloading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  viewImageModal: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCloseViewImage: PropTypes.func.isRequired,
  onResetField: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onSubmitPressed: PropTypes.func.isRequired,
  onTouchField: PropTypes.func.isRequired,
  onViewImage: PropTypes.func.isRequired,
  buttonCaption: PropTypes.string,
  formId: PropTypes.string.isRequired,
  serverAddress: PropTypes.string,
  viewedImage: PropTypes.string.isRequired,
  buttonLocations: PropTypes.arrayOf(PropTypes.number),
  buttonStyle: StyleShape,
  containerStyle: StyleShape,
};

DynamicForm.defaultProps = {
  additionalFields: [],
  onSubmit: () => {},
  buttonCaption: null,
  serverAddress: REST_BASE_URL,
  buttonLocations: [],
  buttonStyle: {},
  containerStyle: {},
};
