import { connect } from 'react-redux';
import {
  INITIAL_ORDER_BY_VOUCHER_TOKENS, MENUID_VOUCHER_TOKEN, PAGE_MODE_TABLE,
  DATE_TIME_FORMAT_WITHOUT_PIPE, REST_BASE_URL, REST_URL_IMAGE_STORAGE,
} from '../../constant';
import {
  transformInitialValues, toMoment, transformDropdownData, transformUserDropdownData, getPermission,
} from '../../helper';
import {
  clearVoucherTokens, downloadVoucherTokenAsync, downloadVoucherTokensAsync,
  setActiveSideMenuItem, setAlertErrorMessage,
  setVoucherTokenSearchText, setVoucherTokenSelectedOrderBy,
  setVoucherTokenSelectedPageSize, setVoucherTokenTappedId,
  setVoucherSearchText, clearVouchers, downloadVouchersAsync,
  setAdvancedFilterDialogSelectedFilterString,
  setVoucherAdvancedFilterDialogSelectedFilterString,
  clearCompaniesMData, setCompanyAdvancedFilterDialogSelectedFilterStringMData,
  setCompanySearchTextMData,
  clearPurchasers, clearRevokers, clearClaimers,
  downloadPurchasersAsync, downloadRevokersAsync, downloadClaimersAsync,
  setPurchaserSearchText, setRevokerSearchText, setClaimerSearchText,
  revokeVoucherTokenAsync,
  saveVoucherTokensAsync,
} from '../../redux/action';
import VoucherTokenPage from './voucher-token.presentation';
import LocalizedString from '../../../../localization';
import { downloadMDataCompaniesAsync, setAlertInputMessage } from '../../../../redux/action';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getDropdownValue = (value) => (value ? { label: LocalizedString.common.labelYes, value: true }
  : { label: LocalizedString.common.labelNo, value: false });

const getInitialValues = (state) => {
  const { uiFunctionalPage, uiVoucherToken, voucherTokens } = state;
  const { data } = voucherTokens;
  const { downloadingDeleting, tappedId } = uiVoucherToken;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    selectedVoucher: found.voucher?.title || '',
    merchant: found.voucher?.merchant?.name || '',
    validUntil: found.validUntil ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    validFrom: found.validFrom ? toMoment(found.validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    purchasedOn: found.purchasedOn ? toMoment(found.purchasedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    revokedOn: found.revokedOn ? toMoment(found.revokedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    claimedOn: found.claimedOn ? toMoment(found.claimedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    isPurchased: getDropdownValue(found.isPurchased),
    isRevoked: getDropdownValue(found.isRevoked),
    isClaimed: getDropdownValue(found.isClaimed),
    purchasedById: found.purchasedBy?.id || '',
    revokedById: found.revokedBy?.id || '',
    claimedById: found.claimedBy?.id || '',
    purchasedBy: found.purchasedBy?.fullName || '',
    revokedBy: found.revokedBy?.fullName || '',
    claimedBy: found.claimedBy?.fullName || '',
    voucherImage: found.voucher ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.voucher.image}` : null,
    voucherName: found.voucher?.title || '',
    merchantName: found.voucher?.merchant?.name || '',
  }) : {
    code: '',
    validFrom: '',
    validUntil: '',
    isPurchased: null,
    purchasedOn: '',
    purchasedBy: '',
    isRevoked: null,
    revokedOn: '',
    revokedBy: '',
    revocationNotes: '',
    selectedVoucher: '',
    merchant: '',
    isClaimed: null,
    claimedOn: '',
    claimedBy: '',
    claimNotes: '',
    voucherName: '',
    merchantName: '',
    purchasedPoint: '',
    purchaseNotes: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiVoucherToken.downloading,
  downloadingDeleting: state.uiVoucherToken.downloadingDeleting,
  tappedId: state.uiFunctionalPage.tappedId || '',
  initialValues: getInitialValues(state),
  vouchers: Object.values(state.vouchers.data)
    .map((item) => ({ label: item.title, value: item.id })),
  merchants: transformDropdownData(state.companiesMdata.data),
  purchasers: transformUserDropdownData(state.purchasers.data),
  revokers: transformUserDropdownData(state.revokers.data),
  claimers: transformUserDropdownData(state.claimers.data),
  loadingVoucher: state.uiVoucher.downloading,
  loadingPurchasers: state.uiVoucherToken.downloadingPurchasers,
  loadingRevokers: state.uiVoucherToken.downloadingRevokers,
  loadingClaimers: state.uiVoucherToken.downloadingClaimers,
  loadingMerchant: state.uiCompany.downloading,
  revoking: state.uiVoucherToken.revoking,
  isRevokeAllowed: getPermission(state, 'VOUCHER_REVOKE_TOKEN'),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_VOUCHER_TOKEN));
    dispatch(setVoucherTokenSearchText(''));
    dispatch(clearVoucherTokens());
    dispatch(setVoucherTokenSelectedPageSize(20));
    dispatch(setVoucherTokenSelectedOrderBy(INITIAL_ORDER_BY_VOUCHER_TOKENS));
    dispatch(downloadVoucherTokensAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadVoucherTokensAsync(pageNo + 1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setVoucherTokenSelectedPageSize(pageSize));
    dispatch(downloadVoucherTokensAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onRefresh: (pageSize) => {
    dispatch(setVoucherTokenSelectedPageSize(pageSize));
    dispatch(clearVoucherTokens());
    dispatch(downloadVoucherTokensAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSearchBarTextChanged: (text) => {
    dispatch(setVoucherTokenSearchText(text));
    dispatch(clearVoucherTokens());
    dispatch(downloadVoucherTokensAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSortPressed: (orderBy) => {
    dispatch(setVoucherTokenSelectedOrderBy(orderBy));
    dispatch(clearVoucherTokens());
    dispatch(downloadVoucherTokensAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onViewPressed: (id) => {
    dispatch(setVoucherTokenTappedId(id));
    dispatch(downloadVoucherTokenAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangeVoucherText: async (text) => {
    try {
      dispatch(setVoucherAdvancedFilterDialogSelectedFilterString(''));
      dispatch(setVoucherSearchText(text));
      dispatch(clearVouchers());
      await dispatch(downloadVouchersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeMerchantText: async (text) => {
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData(''));
    dispatch(setCompanySearchTextMData(text));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePurchaserText: async (text) => {
    dispatch(setPurchaserSearchText(text));
    dispatch(clearPurchasers());
    dispatch(downloadPurchasersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeRevokerText: async (text) => {
    dispatch(setRevokerSearchText(text));
    dispatch(clearRevokers());
    dispatch(downloadRevokersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeClaimerText: async (text) => {
    dispatch(setClaimerSearchText(text));
    dispatch(clearClaimers());
    dispatch(downloadClaimersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDownloadPressed: () => {
    dispatch(saveVoucherTokensAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onAdvancedFilterPressed: () => {
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData(''));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setPurchaserSearchText(''));
    dispatch(clearPurchasers());
    dispatch(downloadPurchasersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setRevokerSearchText(''));
    dispatch(clearRevokers());
    dispatch(downloadRevokersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setClaimerSearchText(''));
    dispatch(clearClaimers());
    dispatch(downloadClaimersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/voucherId/, 'voucher.id')
      .replace(/selectedVoucher/, 'voucher.id')
      .replace(/merchant/, 'voucher.merchant.id')
      .replace(/purchasedBy/, 'purchasedBy.id')
      .replace(/revokedBy/, 'revokedBy.id')
      .replace(/claimedBy/, 'claimedBy.id');

    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearVoucherTokens());
    dispatch(downloadVoucherTokensAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearVoucherTokens());
    dispatch(setVoucherTokenSelectedOrderBy(INITIAL_ORDER_BY_VOUCHER_TOKENS));
    dispatch(downloadVoucherTokensAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRevokePressed: (id, message) => {
    dispatch(setVoucherTokenTappedId(id));
    dispatch(setAlertInputMessage(message, LocalizedString.common.labelReason));
  },
  onConfirmPressed: async (reason) => {
    try {
      await dispatch(revokeVoucherTokenAsync(reason));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VoucherTokenPage);
