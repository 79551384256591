import React, { useEffect } from 'react';
import {
  IconButton,
  Paper, Snackbar, Typography, makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Close } from 'mdi-material-ui';
import { useHistory } from 'react-router-dom';
import AccentButton from '../accent-button';
import LocalizedString from '../../localization';
import { COLOR_PRIMARY, COLOR_TEXT_ON_PRIMARY } from '../../constant';

const useStyles = makeStyles(() => ({
  notificationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '10px',
    position: 'relative',
    width: '100%',
    padding: '10px',
  },
  notificationPropmtContainer: {
    padding: '16px',
  },
  notificationCloseButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  notificationImageContainer: {
    width: '100px',
    height: '100px',
  },
  notificationImage: {
    width: 'inherit',
    height: 'inherit',
  },
  notificationTextContainer: {
    alignSelf: 'center',
  },
  p10: {
    padding: '10px',
  },
  notificationTitle: {
    fontWeight: '600',
  },
  notificationPromptButtons: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px',
    justifyContent: 'flex-end',
    gap: '10px',
  },
  button: {
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
    borderRadius: 50,
  },
}));

const NotificationComponent = ({
  message, isSnackbarOpen, isNotificationPromptOpen, useCaptcha,
  onAppear, onChangeSnackbarVisibility, onChangeNotificationPromptVisibility,
  onNotificationPressed, onNotificationPromptAllowButtonPressed,
}) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    onAppear();
  }, [onAppear]);

  useEffect(() => {
    if (message.body) {
      onChangeSnackbarVisibility(true);
    }
  }, [message, onChangeSnackbarVisibility]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        open={isNotificationPromptOpen}
        onClose={() => onChangeNotificationPromptVisibility(false)}
        style={{ minWidth: 300, maxWidth: 500 }}
        ClickAwayListenerProps={{ onClickAway: () => null }}
      >
        <Paper
          className={classes.notificationPropmtContainer}
          onClick={() => onNotificationPressed(message?.url, history)}
        >
          <Typography variant="body1" className={classes.notificationTitle}>{LocalizedString.notification.labelNotificationPromptTitle}</Typography>
          <Typography variant="body1">{LocalizedString.notification.labelNotificationPromptMessage}</Typography>
          <div className={classes.notificationPromptButtons}>
            <AccentButton
              type="button"
              className={classes.button}
              disableElevation
              caption={LocalizedString.common.buttonCaptionNo}
              onClick={() => onChangeNotificationPromptVisibility(false)}
            />
            <AccentButton
              type="button"
              className={classes.button}
              disableElevation
              caption={LocalizedString.notification.buttonCaptionAllow}
              onClick={() => onNotificationPromptAllowButtonPressed(useCaptcha)}
            />
          </div>
        </Paper>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        open={isSnackbarOpen}
        onClose={() => onChangeSnackbarVisibility(false)}
        autoHideDuration={3000}
        style={{ minWidth: 300, maxWidth: 500 }}
      >
        <Paper
          className={classes.notificationContainer}
          onClick={() => onNotificationPressed(message?.url, history)}
        >
          <IconButton aria-label="close" className={classes.notificationCloseButton} onClick={() => onChangeSnackbarVisibility(false)}>
            <Close />
          </IconButton>
          {message.image && (
          <div className={classes.notificationImageContainer}>
            <img src={message.image} alt="Notification" className={classes.notificationImage} />
          </div>
          )}
          <div className={`${classes.notificationTextContainer} ${message.image ? null : classes.p10}`}>
            { message.title && <Typography variant="body1" className={classes.notificationTitle}>{message.title}</Typography> }
            <Typography variant="body2">{message.body}</Typography>
          </div>
        </Paper>
      </Snackbar>
    </>
  );
};

export default NotificationComponent;

NotificationComponent.propTypes = {
  message: PropTypes.object,
  isSnackbarOpen: PropTypes.bool.isRequired,
  isNotificationPromptOpen: PropTypes.bool.isRequired,
  useCaptcha: PropTypes.bool.isRequired,

  onAppear: PropTypes.func.isRequired,
  onChangeSnackbarVisibility: PropTypes.func.isRequired,
  onChangeNotificationPromptVisibility: PropTypes.func.isRequired,
  onNotificationPressed: PropTypes.func.isRequired,
  onNotificationPromptAllowButtonPressed: PropTypes.func.isRequired,
};

NotificationComponent.defaultProps = {
  message: { title: '', body: '', image: '' },
};
