import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import {
  COLOR_ERROR, COLOR_PRIMARY, COLOR_TEXT_PRIMARY,
  COLOR_TEXT_SECONDARY,
} from '../constant';
import { Body, BodySmall } from './labels';
import MyFlatList from './my-flat-list';
import VerticalSpacer from './vertical-spacer';

const styles = {
  labelText: {
    color: COLOR_TEXT_PRIMARY,
  },
  labelAdditionalText: {
    color: COLOR_TEXT_SECONDARY,
    marginTop: '5px',
  },
  optionContainer: {
    borderRadius: '50px',
    padding: '8px',
    height: '32px',
    alignItems: 'center',
    marginVertical: '4px 0',
    marginRight: '8px',
  },
  flatlist: {
    display: 'flex',
    flexDirection: 'column',
  },
  circleOutter: {
    height: '24px',
    width: '24px',
    borderRadius: '12px',
    border: `2px solid ${COLOR_PRIMARY}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 5,
  },
  circleInner: {
    height: '12px',
    width: '12px',
    borderRadius: '6px',
    backgroundColor: COLOR_PRIMARY,
  },
  errorTextStyle: {
    color: COLOR_ERROR,
  },
};

const RadioPicker = ({
  data, onValueSelected, required,
  disabled, error,
  radioSize, selectedValue, ...props
}) => {
  const [selectedValueTemp, setSelectedValueTemp] = useState(selectedValue);

  const onSelect = (item) => {
    const selected = item.label === selectedValue ? '' : item.value;
    onValueSelected(selected);
    setSelectedValueTemp(selected);
  };

  const renderRadioButton = (selected) => (
    <div style={{
      ...styles.circleOutter,
      height: radioSize,
      width: radioSize,
      borderRadius: radioSize / 2,
    }}
    >
      {selected && (
      <div style={{
        ...styles.circleInner,
        height: radioSize / 2,
        width: radioSize / 2,
        borderRadius: radioSize / 4,
      }}
      />
      )}
    </div>
  );

  const renderOptions = (item) => {
    const selected = selectedValueTemp === item.value;
    return (
      <div key={item.label}>
        <Button
          onClick={() => onSelect(item)}
          disabled={disabled}
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          {renderRadioButton(selected, radioSize)}
          <BodySmall style={
          { color: selected ? COLOR_PRIMARY : COLOR_TEXT_PRIMARY }
        }
          >
            {item.label}
          </BodySmall>
        </Button>
        <VerticalSpacer height={5} />
      </div>
    );
  };

  const renderError = () => {
    if (error) {
      return (<Body style={styles.errorTextStyle}>{error}</Body>);
    }
    return null;
  };

  return (
    <div>
      <MyFlatList
        data={data}
        renderItem={(item) => renderOptions(item)}
        {...props}
      />

      <VerticalSpacer height={5} />

      {renderError()}
    </div>
  );
};

export default RadioPicker;

const pickerItemShape = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
});

const arrayShape = PropTypes.arrayOf(pickerItemShape);

RadioPicker.propTypes = {
  data: arrayShape.isRequired,
  onValueSelected: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  radioSize: PropTypes.number,
  selectedValue: PropTypes.oneOfType([
    pickerItemShape,
    PropTypes.string,
  ]),
};

RadioPicker.defaultProps = {
  onValueSelected: () => {},
  required: false,
  disabled: false,
  error: '',
  radioSize: 24,
  selectedValue: undefined,
};
