import { INITIAL_ORDER_BY_SPONSORS } from '../../constant';
import {
  ADDING_EDITING_SPONSOR, DOWNLOADING_DELETING_SPONSOR,
  DOWNLOADING_SPONSORS,
  SET_SPONSOR_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_SPONSOR_SEARCH_TEXT, SET_SPONSOR_SELECTED_ORDER_BY,
  SET_SPONSOR_SELECTED_PAGE_SIZE, SET_SPONSOR_TAPPED_ID,
  SET_SPONSOR_SELECTED_EVENT_ID,
  SET_SPONSOR_SELECTED_COMPANY,
  SET_SPONSOR_SELECTED_SPONSOR_CATEGORY,
} from '../action/simple-action';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_SPONSORS,
  filterString: '',
  sponsorCategory: '',
  company: '',
  eventId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_SPONSORS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_SPONSOR:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_SPONSOR:
      return { ...state, downloadingDeleting: action.status };
    case SET_SPONSOR_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_SPONSOR_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_SPONSOR_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_SPONSOR_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_SPONSOR_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_SPONSOR_SELECTED_EVENT_ID:
      return { ...state, eventId: action.id };
    case SET_SPONSOR_SELECTED_COMPANY:
      return { ...state, company: action.data };
    case SET_SPONSOR_SELECTED_SPONSOR_CATEGORY:
      return { ...state, sponsorCategory: action.data };
    default: return state;
  }
};
