import {
  enablingDisablingCity, setAlertErrorMessage, setCitySelectedOrderBy, setCitySelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_CITIES, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableCity } from '../../../helper';
import downloadCitiesAsync from './downloadCitiesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingCity(true));

    const { token } = getState().authentication;
    const { data } = getState().cities;
    const { tappedId } = getState().uiCity;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableCity(tappedId, status, token);

    dispatch(setCitySelectedPageSize(20));
    dispatch(setCitySelectedOrderBy(INITIAL_ORDER_BY_CITIES));
    dispatch(downloadCitiesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingCity(false));
  }
};
