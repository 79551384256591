import { sendingEventNotification } from '../simple-action';
import { sendEventNotification } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(sendingEventNotification(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiEvent;

    await sendEventNotification(tappedId, token);
  } finally {
    dispatch(sendingEventNotification(false));
  }
};
