import { reset } from 'redux-form';
import { PAGE_MODE_TABLE, RXFORM_FORM } from '../../../constant';
import { addForm, editForm, localDateToUtc } from '../../../helper';
import {
  addingEditingForm, clearForms,
  setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import downloadFormsAsync from './downloadFormsAsync';


export default (
  name, description, title, tag, visibilityStatus, validFrom, validUntil,
  closeFormResponse, allowDirectUserResponse, detectDuplicatedResponseBasedOnReferenceId,
) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingForm(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiForm;

    if (tappedId) {
      await editForm(
        tappedId, name, description, title, tag, visibilityStatus,
        localDateToUtc(validFrom),
        localDateToUtc(validUntil),
        closeFormResponse.value, allowDirectUserResponse.value,
        detectDuplicatedResponseBasedOnReferenceId.value,
        token,
      );
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addForm(
        name, description, title, tag, visibilityStatus,
        localDateToUtc(validFrom),
        localDateToUtc(validUntil),
        closeFormResponse.value, allowDirectUserResponse.value,
        detectDuplicatedResponseBasedOnReferenceId.value,
        token,
      );
    }

    dispatch(reset(RXFORM_FORM));
    dispatch(clearForms());
    dispatch(downloadFormsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingForm(false));
  }
};
