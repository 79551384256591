import { downloadingDeletingProfileSync, addProfileSync } from '../simple-action';
import { downloadProfileSync } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingProfileSync(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiProfileSync;
    const { tappedId: setAchievementTappedId } = getState().uiAchievement;

    const result = await downloadProfileSync(setAchievementTappedId || tappedId, token);

    dispatch(addProfileSync(result));
  } finally {
    dispatch(downloadingDeletingProfileSync(false));
  }
};
