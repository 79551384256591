import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardActionArea, Dialog, makeStyles } from '@material-ui/core';
import {
  COLOR_BACKGROUND, COLOR_BACKGROUND_OVERLAY,
  COLOR_FOUNDATION_BLUE_NORMAL,
} from '../../constant';
import LocalizedString from '../../localization';
import { Body } from '../labels';
import HorizontalLine from '../horizontal-line';
import SelfieModal from '../selfie-modal';

const useStyles = makeStyles(() => ({
  paper: {
    width: '300px',
  },
}));

const styles = {
  modalContainer: {
    backgroundColor: COLOR_BACKGROUND_OVERLAY,
  },
  modalInnerContainer: {
    padding: '8px 16px',
    backgroundColor: COLOR_BACKGROUND,
  },
  menuButton: {
    padding: '12px 0',
    textAlign: 'center',
  },
  menuButtonCancel: {
    padding: '12px 0',
    textAlign: 'center',
  },
  colorBlueNormal: {
    color: COLOR_FOUNDATION_BLUE_NORMAL,
  },
};

const ImagePickerDialog = ({
  launching, visibility,
  onAppear, onCancelPressed, onPickFromGallerySelected, onTakePhotoSelected,
  onPictureSelected, onPhotoTaken, isSelfieModalOpen, onCancelSelfieModalPressed,
  uploading,
}) => {
  const classes = useStyles();

  useEffect(onAppear, []);

  return (
    <div>
      <SelfieModal
        isOpen={isSelfieModalOpen}
        onCancelPressed={onCancelSelfieModalPressed}
        onPhotoTaken={onPhotoTaken}
        uploading={uploading}
      />
      <Dialog
        open={visibility}
        aria-labelledby="responsive-dialog-title"
        classes={{ paper: classes.paper }}
        PaperProps={{ style: { borderRadius: 16 } }}
      >
        <div style={styles.modalContainer}>
          <div style={styles.modalInnerContainer}>
            <CardActionArea
              style={styles.menuButton}
              onClick={onTakePhotoSelected}
              disabled={launching}
            >
              <Body style={styles.colorBlueNormal}>
                {LocalizedString.common.buttonCaptionTakePhoto}
              </Body>
            </CardActionArea>
            <HorizontalLine />
            <CardActionArea
              style={styles.menuButton}
              onClick={onPickFromGallerySelected}
              disabled={launching}
            >
              <input
                accept="image/*"
                type="file"
                id="uploadImage"
                name="uploadImage"
                style={{ visibility: 'hidden', display: 'none' }}
                onChange={(e) => onPictureSelected(e.target)}
              />
              <Body style={styles.colorBlueNormal}>
                {LocalizedString.common.buttonCaptionChooseFromLibrary}
              </Body>
            </CardActionArea>

            <HorizontalLine />

            <CardActionArea
              style={styles.menuButtonCancel}
              onClick={onCancelPressed}
            >
              <Body style={{ color: COLOR_FOUNDATION_BLUE_NORMAL }}>
                {LocalizedString.common.buttonCaptionCancel}
              </Body>
            </CardActionArea>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ImagePickerDialog;

ImagePickerDialog.propTypes = {
  launching: PropTypes.bool.isRequired,
  uploading: PropTypes.bool,
  visibility: PropTypes.bool.isRequired,
  isSelfieModalOpen: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onPickFromGallerySelected: PropTypes.func.isRequired,
  onPictureSelected: PropTypes.func.isRequired,
  onPhotoTaken: PropTypes.func.isRequired,
  onTakePhotoSelected: PropTypes.func.isRequired,
  onCancelSelfieModalPressed: PropTypes.func.isRequired,
};

ImagePickerDialog.defaultProps = {
  uploading: false,
};
