import {
  addHistory, downloadingAttendanceSummaryClockOutAreaHistory,
} from '../simple-action';
import { downloadHistory } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAttendanceSummaryClockOutAreaHistory(true));

    const { token } = getState().authentication;
    const { tappedClockOutAreaId } = getState().uiAttendanceSummary;

    const history = await downloadHistory(tappedClockOutAreaId, token);
    dispatch(addHistory(history));
  } finally {
    dispatch(downloadingAttendanceSummaryClockOutAreaHistory(false));
  }
};
