import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, makeStyles, Paper, Typography,
} from '@material-ui/core';
import {
  Group, ImportantDevices, VerifiedUser, FlashOn,
} from '@material-ui/icons';
import { BasePage, PieChart, TotalCountSection } from '../../component';
import { COLOR_PRIMARY } from '../../constant';
import LocalizedString from '../../localization';
import { SummaryCountShape } from '../../type';

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 23,
  },
  title: {
    color: COLOR_PRIMARY,
  },
  bodyContainer: {
    marginTop: 60,
  },
  chartContainer: {
    padding: 24,
    margin: '30px 0px 30px 0px',
    justifyContent: 'space-between',
  },
}));

const renderPieChartField = (data) => data.map((row) => (
  <Grid container alignItems="stretch" key={row.id}>
    {row.children.map((item) => (
      item.title ? (
        <Grid item xs={12} md key={item.id}>
          <PieChart
            data={item.data}
            loading={item.downloading}
            argumentField="name"
            title={item.title}
            valueField="count"
          />
        </Grid>
      ) : (
        <Grid item xs={12} md key={item.id} />
      )
    ))}
  </Grid>
));

const SummaryPage = ({
  androidVersionDistribution, iosVersionDistribution, loginUsing, osDistribution,
  downloadingActiveDevices, downloadingActiveUsers, downloadingAndroidVersionDistribution,
  downloadingIosVersionDistribution, downloadingLoginUsing, downloadingOsDistribution,
  downloadingTotalUsers, onAppear, activeDevices, activeUsers, totalUsers,
  downloadingBrowserDistribution, downloadingTimezoneDistribution,
  browserDistribution, timezoneDistribution, downloadingAppVersionDistribution,
  appVersionDistribution, downloadingDeviceManufacturerDistribution, deviceManufactureDistribution,
  downloadingAndroidDeviceModelDistribution, androidDeviceModelDistribution,
  downloadingIosDeviceModelDistribution, iosDeviceModelDistribution,
  onlineUsers, downloadingOnlineUsers,
}) => {
  const classes = useStyles();
  useEffect(onAppear, []);

  const dataChart = [
    {
      id: '1a',
      children: [
        {
          id: '1aa',
          downloading: downloadingOsDistribution,
          data: osDistribution,
          title: LocalizedString.summaryScreen.labelOsDistribution,
        },
        {
          id: '1ab',
          downloading: downloadingLoginUsing,
          data: loginUsing,
          title: LocalizedString.summaryScreen.labelAccessUsing,
        },
      ],
    },
    {
      id: '2a',
      children: [
        {
          id: '2aa',
          downloading: downloadingAndroidVersionDistribution,
          data: androidVersionDistribution,
          title: LocalizedString.summaryScreen.labelAndroidVersionDistribution,
        },
        {
          id: '2ab',
          downloading: downloadingIosVersionDistribution,
          data: iosVersionDistribution,
          title: LocalizedString.summaryScreen.labelIosVersionDistribution,
        },
      ],
    },
    {
      id: '3a',
      children: [
        {
          id: '3aa',
          downloading: downloadingAppVersionDistribution,
          data: appVersionDistribution,
          title: LocalizedString.summaryScreen.labelAppVersionDistribution,
        },
        {
          id: '3ab',
          downloading: downloadingDeviceManufacturerDistribution,
          data: deviceManufactureDistribution,
          title: LocalizedString.summaryScreen.labelDeviceManufacturerDistribution,
        },
      ],
    },
    {
      id: '4a',
      children: [
        {
          id: '4aa',
          downloading: downloadingAndroidDeviceModelDistribution,
          data: androidDeviceModelDistribution,
          title: LocalizedString.summaryScreen.labelAndroidDeviceModelDistribution,
        },
        {
          id: '4ab',
          downloading: downloadingIosDeviceModelDistribution,
          data: iosDeviceModelDistribution,
          title: LocalizedString.summaryScreen.labelIosDeviceModelDistribution,
        },
      ],
    },
    {
      id: '5a',
      children: [
        {
          id: '5aa',
          downloading: downloadingBrowserDistribution,
          data: browserDistribution,
          title: LocalizedString.summaryScreen.labelBrowserDistribution,
        },
        {
          id: '5ab',
          downloading: downloadingTimezoneDistribution,
          data: timezoneDistribution,
          title: LocalizedString.summaryScreen.labelTimezoneDistribution,
        },
      ],
    },
  ];

  return (
    <BasePage>
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.title}>{LocalizedString.summaryScreen.title}</Typography>
      </div>

      <div className={classes.bodyContainer}>
        <TotalCountSection
          data={[
            {
              downloading: downloadingTotalUsers,
              count: totalUsers,
              icon: <Group />,
              label: LocalizedString.summaryScreen.labelTotalUser,
            },
            {
              downloading: downloadingOnlineUsers,
              count: onlineUsers,
              icon: <FlashOn />,
              label: LocalizedString.summaryScreen.labelOnlineUsers,
            },
            {
              downloading: downloadingActiveUsers,
              count: activeUsers,
              icon: <VerifiedUser />,
              label: LocalizedString.summaryScreen.labelActiveUser,
            },
            {
              downloading: downloadingActiveDevices,
              count: activeDevices,
              icon: <ImportantDevices />,
              label: LocalizedString.summaryScreen.labelActiveDevice,
            },
          ]}
        />

        <Paper elevation={3} className={classes.chartContainer}>
          {renderPieChartField(dataChart)}
        </Paper>
      </div>
    </BasePage>
  );
};

export default SummaryPage;

SummaryPage.propTypes = {
  androidVersionDistribution: PropTypes.arrayOf(SummaryCountShape).isRequired,
  iosVersionDistribution: PropTypes.arrayOf(SummaryCountShape).isRequired,
  loginUsing: PropTypes.arrayOf(SummaryCountShape).isRequired,
  osDistribution: PropTypes.arrayOf(SummaryCountShape).isRequired,
  browserDistribution: PropTypes.arrayOf(SummaryCountShape).isRequired,
  timezoneDistribution: PropTypes.arrayOf(SummaryCountShape).isRequired,
  appVersionDistribution: PropTypes.arrayOf(SummaryCountShape).isRequired,
  deviceManufactureDistribution: PropTypes.arrayOf(SummaryCountShape).isRequired,
  androidDeviceModelDistribution: PropTypes.arrayOf(SummaryCountShape).isRequired,
  iosDeviceModelDistribution: PropTypes.arrayOf(SummaryCountShape).isRequired,
  downloadingActiveUsers: PropTypes.bool.isRequired,
  downloadingActiveDevices: PropTypes.bool.isRequired,
  downloadingAndroidVersionDistribution: PropTypes.bool.isRequired,
  downloadingIosVersionDistribution: PropTypes.bool.isRequired,
  downloadingLoginUsing: PropTypes.bool.isRequired,
  downloadingOsDistribution: PropTypes.bool.isRequired,
  downloadingTotalUsers: PropTypes.bool.isRequired,
  downloadingBrowserDistribution: PropTypes.bool.isRequired,
  downloadingTimezoneDistribution: PropTypes.bool.isRequired,
  downloadingAppVersionDistribution: PropTypes.bool.isRequired,
  downloadingDeviceManufacturerDistribution: PropTypes.bool.isRequired,
  downloadingAndroidDeviceModelDistribution: PropTypes.bool.isRequired,
  downloadingIosDeviceModelDistribution: PropTypes.bool.isRequired,
  downloadingOnlineUsers: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  activeDevices: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  activeUsers: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  totalUsers: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onlineUsers: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
