import { store } from '../redux/store';
import { downloadNotificationsAsync, setAlertErrorMessage, setNotificationMessage } from '../redux/action';

// eslint-disable-next-line import/prefer-default-export
export const onNotificationReceived = async (notification) => {
  const { data } = notification;
  const authenticatedUserOnly = data && data.authenticatedUserOnly;
  const { token } = store.getState().authentication;

  if (authenticatedUserOnly === `${true}` && !token) { return; }
  store.dispatch(setNotificationMessage({ ...notification.notification, ...notification.data }));
  store.dispatch(downloadNotificationsAsync(true))
    .catch((err) => setAlertErrorMessage(err));
};
