import {
  RXFIELD_SESSION_OPERATOR_MAPPING_USER, RXFIELD_SESSION_OPERATOR_MAPPING_SESSION,
  RXFIELD_SESSION_OPERATOR_MAPPING_VALID_UNTIL, RXFIELD_SESSION_OPERATOR_INVITATION_SESSION,
  RXFIELD_SESSION_OPERATOR_INVITATION_RECIPIENTS,
} from './constant';
import { rxFormValidateRequiredFields } from '../../validation';

export const rxformValidateSessionOperatorMapping = (values) => {
  const requiredFields = [
    RXFIELD_SESSION_OPERATOR_MAPPING_USER,
    RXFIELD_SESSION_OPERATOR_MAPPING_SESSION,
    RXFIELD_SESSION_OPERATOR_MAPPING_VALID_UNTIL,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateSessionOperatorInvitation = (values) => {
  const requiredFields = [
    RXFIELD_SESSION_OPERATOR_INVITATION_SESSION,
    RXFIELD_SESSION_OPERATOR_INVITATION_RECIPIENTS,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
