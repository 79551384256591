import moment from 'moment';
import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_SESSION_CATEGORY_DESCRIPTION, RXFIELD_SESSION_CATEGORY_NAME,
  RXFIELD_LOCATION_ADDRESS, RXFIELD_LOCATION_AREA, RXFIELD_LOCATION_CITY,
  RXFIELD_LOCATION_GOOGLE_MAP_URL, RXFIELD_LOCATION_LATITUDE,
  RXFIELD_LOCATION_LONGITUDE, RXFIELD_LOCATION_NAME, RXFIELD_EVENT_CATEGORY_NAME,
  RXFIELD_EVENT_CATEGORY_DESCRIPTION,
  RXFIELD_SPEAKER_JOB_TITLE,
  RXFIELD_SPEAKER_NAME,
  RXFIELD_SPEAKER_DESCRIPTION,
  RXFIELD_SPONSOR_CATEGORY_ORDER,
  RXFIELD_SPONSOR_CATEGORY_DESCRIPTION,
  RXFIELD_SPONSOR_CATEGORY_NAME,
  RXFIELD_ROOM_LOCATION_ID,
  RXFIELD_ROOM_NAME,
  RXFIELD_SESSION_NAME, RXFIELD_SESSION_START_TIME, RXFIELD_SESSION_END_TIME,
  RXFIELD_SESSION_EARLIEST_CLOCK, RXFIELD_SESSION_MAX_CLOCK, RXFIELD_SESSION_FEATURED,
  RXFIELD_SESSION_FEATURED_ORDER, RXFIELD_SESSION_VISIBILITY_STATUS,
  RXFIELD_SESSION_REGISTRATION_REWARD_TYPE, RXFIELD_SESSION_EVENT_NAME,
  RXFIELD_SESSION_ROOM_NAME, RXFIELD_SESSION_SHORT_DESCRIPTION,
  RXFIELD_SESSION_REGISTRATION_REWARD_POINT, RXFIELD_SESSION_SPEAKERS,
  RXFIELD_SESSION_REGISTRATION_REWARD_VOUCHERS,
  RXFIELD_SESSION_CLOCK_INSIDE_AREA, RXFIELD_SESSION_WHO_CAN_REGISTER,
  RXFIELD_SESSION_NEED_APPROVAL, RXFIELD_SESSION_QUOTA,
  RXFIELD_SESSION_PRICE, RXFIELD_SESSION_CAN_PAY_USING_POINT,
  SESSION_REWARD_TYPE_VOUCHER, SESSION_REWARD_TYPE_POINT, SESSION_REWARD_TYPE_BOTH,
  RXFIELD_SESSION_MEDIA, RXFIELD_SESSION_NORMAL_PRICE,
  RXFIELD_EVENT_NAME,
  RXFIELD_EVENT_SHORT_DESCRIPTION, RXFIELD_EVENT_START_TIME, RXFIELD_EVENT_END_TIME,
  RXFIELD_EVENT_EARLIEST_CLOCK_IN, RXFIELD_EVENT_MAX_CLOCK_IN,
  RXFIELD_EVENT_CLOCK_IN_MUST_INSIDE_AREA, RXFIELD_EVENT_REGISTRATION_REWARD_TYPE,
  RXFIELD_EVENT_EVENT_TYPE, RXFIELD_EVENT_CATEGORY, RXFIELD_EVENT_LOCATION, RXFIELD_EVENT_FEATURED,
  RXFIELD_EVENT_WHO_CAN_REGISTER, RXFIELD_EVENT_NEEDS_APPROVAL, RXFIELD_EVENT_QUOTA,
  RXFIELD_EVENT_PRICE,
  RXFIELD_EVENT_CAN_PAY_USING_POINT,
  RXFIELD_EVENT_VIISIBILITY_STATUS,
  RXFIELD_EVENT_INVITATION_EVENT,
  RXFIELD_EVENT_INVITATION_RECIPIENTS,
  RXFIELD_EVENT_MEDIA,
  RXFIELD_SPONSOR_EVENT_ID,
  RXFIELD_SPONSOR_SPONSOR_CATEGORY_ID,
  RXFIELD_SPONSOR_COMPANY_ID,
  RXFIELD_SPONSOR_ORDER,
  RXFIELD_SESSION_INVITATION_SESSION,
  RXFIELD_SESSION_INVITATION_RECIPIENTS,
  RXFIELD_EVENT_NORMAL_PRICE,
  RXFIELD_EVENT_REGISTRATION_REWARD_VOUCHER,
  RXFIELD_EVENT_REGISTRATION_REWARD_POINT,
  REWARDS_TYPE, status,
  RXFIELD_EVENT_ENABLE_COMMENT,
  RXFIELD_EVENT_COMMENT_ACCESS,
  RXFIELD_SESSION_TYPE, SESSION_TYPE,
  RXFIELD_SESSION_CATEGORY,
  RXFIELD_EVENT_SPEAKERS,
  EVENT_TYPE_SIMPLE,
} from './constant';
import GlobalLocalizedString from '../../localization';

export const rxformValidateLocation = (values) => {
  const requiredFields = [
    RXFIELD_LOCATION_ADDRESS,
    RXFIELD_LOCATION_AREA,
    RXFIELD_LOCATION_CITY,
    RXFIELD_LOCATION_GOOGLE_MAP_URL,
    RXFIELD_LOCATION_LATITUDE,
    RXFIELD_LOCATION_LONGITUDE,
    RXFIELD_LOCATION_NAME,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateEventCategory = (values) => {
  const requiredFields = [
    RXFIELD_EVENT_CATEGORY_NAME,
    RXFIELD_EVENT_CATEGORY_DESCRIPTION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateSessionCategory = (values) => {
  const requiredFields = [
    RXFIELD_SESSION_CATEGORY_NAME,
    RXFIELD_SESSION_CATEGORY_DESCRIPTION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateSponsorCategory = (values) => {
  const requiredFields = [
    RXFIELD_SPONSOR_CATEGORY_NAME,
    RXFIELD_SPONSOR_CATEGORY_DESCRIPTION,
    RXFIELD_SPONSOR_CATEGORY_ORDER,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateSpeaker = (values) => {
  const requiredFields = [
    RXFIELD_SPEAKER_NAME,
    RXFIELD_SPEAKER_DESCRIPTION,
    RXFIELD_SPEAKER_JOB_TITLE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateRoom = (values) => {
  const requiredFields = [
    RXFIELD_ROOM_LOCATION_ID,
    RXFIELD_ROOM_NAME,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateSession = (values) => {
  let requiredFields = [
    RXFIELD_SESSION_NAME,
    RXFIELD_SESSION_START_TIME,
    RXFIELD_SESSION_END_TIME,
    RXFIELD_SESSION_FEATURED,
    RXFIELD_SESSION_VISIBILITY_STATUS,
    RXFIELD_SESSION_EVENT_NAME,
    RXFIELD_SESSION_ROOM_NAME,
    RXFIELD_SESSION_SHORT_DESCRIPTION,
    RXFIELD_SESSION_TYPE,
    RXFIELD_SESSION_MEDIA,
    RXFIELD_SESSION_SPEAKERS,
    RXFIELD_SESSION_CATEGORY,
  ];
  const isFeaturedOrderRequired = values.isFeatured?.value
    ? [...requiredFields, RXFIELD_SESSION_FEATURED_ORDER] : requiredFields;
  requiredFields = isFeaturedOrderRequired;

  const isTypeRegular = values.type === SESSION_TYPE[0] ? [
    ...requiredFields,
    RXFIELD_SESSION_EARLIEST_CLOCK,
    RXFIELD_SESSION_MAX_CLOCK,
    RXFIELD_SESSION_CLOCK_INSIDE_AREA,
    RXFIELD_SESSION_WHO_CAN_REGISTER,
    RXFIELD_SESSION_NEED_APPROVAL,
    RXFIELD_SESSION_QUOTA,
    RXFIELD_SESSION_NORMAL_PRICE,
    RXFIELD_SESSION_PRICE,
    RXFIELD_SESSION_CAN_PAY_USING_POINT,
    RXFIELD_SESSION_REGISTRATION_REWARD_TYPE,
  ] : requiredFields;
  requiredFields = isTypeRegular;

  const voucherRewardPoint = values.registrationRewardType === SESSION_REWARD_TYPE_POINT
    ? [...requiredFields, RXFIELD_SESSION_REGISTRATION_REWARD_POINT] : requiredFields;
  requiredFields = voucherRewardPoint;

  const voucherRewardVoucher = values.registrationRewardType === SESSION_REWARD_TYPE_VOUCHER
    ? [...requiredFields, RXFIELD_SESSION_REGISTRATION_REWARD_VOUCHERS] : requiredFields;
  requiredFields = voucherRewardVoucher;

  const voucherRewardBoth = values.registrationRewardType === SESSION_REWARD_TYPE_BOTH
    ? [...requiredFields, RXFIELD_SESSION_REGISTRATION_REWARD_POINT,
      RXFIELD_SESSION_REGISTRATION_REWARD_VOUCHERS] : requiredFields;
  requiredFields = voucherRewardBoth;

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!moment(values.endTime).isAfter(moment(values.startTime))) {
    errors.endTime = GlobalLocalizedString.common.errMsgStartEndTime;
  }

  return errors;
};
export const rxformValidateEvent = (values) => {
  let requiredFields = [
    RXFIELD_EVENT_NAME,
    RXFIELD_EVENT_SHORT_DESCRIPTION,
    RXFIELD_EVENT_START_TIME,
    RXFIELD_EVENT_END_TIME,
    RXFIELD_EVENT_EARLIEST_CLOCK_IN,
    RXFIELD_EVENT_MAX_CLOCK_IN,
    RXFIELD_EVENT_CLOCK_IN_MUST_INSIDE_AREA,
    RXFIELD_EVENT_REGISTRATION_REWARD_TYPE,
    RXFIELD_EVENT_EVENT_TYPE,
    RXFIELD_EVENT_CATEGORY,
    RXFIELD_EVENT_LOCATION,
    RXFIELD_EVENT_FEATURED,
    RXFIELD_EVENT_WHO_CAN_REGISTER,
    RXFIELD_EVENT_NEEDS_APPROVAL,
    RXFIELD_EVENT_QUOTA,
    RXFIELD_EVENT_NORMAL_PRICE,
    RXFIELD_EVENT_PRICE,
    RXFIELD_EVENT_CAN_PAY_USING_POINT,
    RXFIELD_EVENT_VIISIBILITY_STATUS,
    RXFIELD_EVENT_MEDIA,
    RXFIELD_EVENT_ENABLE_COMMENT,
  ];

  const pointRewardTypeRequired = values.registrationRewardType === REWARDS_TYPE[0]
    ? [...requiredFields, RXFIELD_EVENT_REGISTRATION_REWARD_POINT] : requiredFields;
  requiredFields = pointRewardTypeRequired;

  const voucherRewardTypeRequired = values.registrationRewardType === REWARDS_TYPE[1]
    ? [...requiredFields, RXFIELD_EVENT_REGISTRATION_REWARD_VOUCHER] : requiredFields;
  requiredFields = voucherRewardTypeRequired;

  const bothRewardTypeRequired = values.registrationRewardType === REWARDS_TYPE[2]
    ? [...requiredFields, RXFIELD_EVENT_REGISTRATION_REWARD_POINT,
      RXFIELD_EVENT_REGISTRATION_REWARD_VOUCHER] : requiredFields;
  requiredFields = bothRewardTypeRequired;

  const enableCommentRequired = values.enableComment?.value === status[0].value
    ? [...requiredFields, RXFIELD_EVENT_COMMENT_ACCESS] : requiredFields;
  requiredFields = enableCommentRequired;

  const speakersRequired = values.eventType === EVENT_TYPE_SIMPLE
    ? [...requiredFields, RXFIELD_EVENT_SPEAKERS] : requiredFields;
  requiredFields = speakersRequired;

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!moment(values.endTime).isAfter(moment(values.startTime))) {
    errors.endTime = GlobalLocalizedString.common.errMsgStartEndTime;
  }

  return errors;
};

export const rxformValidateEventInvitation = (values) => {
  const requiredFields = [
    RXFIELD_EVENT_INVITATION_EVENT,
    RXFIELD_EVENT_INVITATION_RECIPIENTS,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateSponsor = (values) => {
  const requiredFields = [
    RXFIELD_SPONSOR_COMPANY_ID,
    RXFIELD_SPONSOR_EVENT_ID,
    RXFIELD_SPONSOR_SPONSOR_CATEGORY_ID,
    RXFIELD_SPONSOR_ORDER,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateSessionInvitation = (values) => {
  const requiredFields = [
    RXFIELD_SESSION_INVITATION_SESSION,
    RXFIELD_SESSION_INVITATION_RECIPIENTS,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
