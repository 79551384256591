import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  CircularProgress, Divider, Grid, makeStyles, Typography,
} from '@material-ui/core';
import { FunctionalPage } from '../../../../component';
import {
  COLOR_PRIMARY, COLOR_SECONDARY, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_TEXT,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, RXFIELD_CREATED_DATE,
  RXFIELD_PURCHASE_REQUEST_AMOUNT, RXFIELD_PURCHASE_REQUEST_CALLBACK_URL,
  RXFIELD_PURCHASE_REQUEST_FULL_NAME, RXFIELD_PURCHASE_REQUEST_PURCHASE_ITEMS,
  RXFIELD_PURCHASE_REQUEST_LABEL, RXFIELD_PURCHASE_REQUEST_MERCHANT_ID,
  RXFIELD_PURCHASE_REQUEST_MERCHANT_NAME, RXFIELD_PURCHASE_REQUEST_MERCHANT_REF_ID,
  RXFIELD_PURCHASE_REQUEST_REQUEST_STATUS, RXFIELD_PURCHASE_REQUEST_REQUEST_STATUS_NOTES,
  RXFIELD_PURCHASE_REQUEST_TAGS, RXFIELD_PURCHASE_REQUEST_USER_ID, RXFORM_PURCHASE_REQUEST,
  RXSTATE_POINT_PURCHASE_REQUESTS, RXSTATE_POINT_PURCHASE_REQUEST_PAGE, SUBMISSION_STATUS_VALUES,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormEditableTableField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';

const useStyles = makeStyles(() => ({
  heading: {
    color: COLOR_SECONDARY,
    fontWeight: 'bold',
  },
  divider: {
    height: 2,
    margin: '25px 0px 25px 0px',
    background: COLOR_PRIMARY,
  },
}));

const renderDialogContent = (
  downloadingDeleting, totalCurrentPage, tokenTotalCount,
  defaultActiveSessionsValue, pageMode, classes,
) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PURCHASE_REQUEST_LABEL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.purchaseRequestPage.placeholderLabel}
            label={LocalizedString.purchaseRequestPage.placeholderLabel}
            multiline
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PURCHASE_REQUEST_AMOUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.purchaseRequestPage.placeholderAmount}
            label={LocalizedString.purchaseRequestPage.placeholderAmount}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PURCHASE_REQUEST_MERCHANT_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.purchaseRequestPage.placeholderMerchantID}
            label={LocalizedString.purchaseRequestPage.placeholderMerchantID}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PURCHASE_REQUEST_MERCHANT_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.purchaseRequestPage.placeholderMerchantName}
            label={LocalizedString.purchaseRequestPage.placeholderMerchantName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PURCHASE_REQUEST_MERCHANT_REF_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.purchaseRequestPage.placeholderMerchantReferenceID}
            label={LocalizedString.purchaseRequestPage.placeholderMerchantReferenceID}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PURCHASE_REQUEST_CALLBACK_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.purchaseRequestPage.placeholderCallbackUrl}
            label={LocalizedString.purchaseRequestPage.placeholderCallbackUrl}
            multiline
            disabled
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PURCHASE_REQUEST_REQUEST_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.purchaseRequestPage.placeholderRequestStatus}
            label={LocalizedString.purchaseRequestPage.placeholderRequestStatus}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PURCHASE_REQUEST_REQUEST_STATUS_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.purchaseRequestPage.placeholderRequestStatusNotes}
            label={LocalizedString.purchaseRequestPage.placeholderRequestStatusNotes}
            multiline
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PURCHASE_REQUEST_USER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.purchaseRequestPage.placeholderUserID}
            label={LocalizedString.purchaseRequestPage.placeholderUserID}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PURCHASE_REQUEST_FULL_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.purchaseRequestPage.placeholderUserFullName}
            label={LocalizedString.purchaseRequestPage.placeholderUserFullName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PURCHASE_REQUEST_TAGS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.purchaseRequestPage.placeholderTags}
            label={LocalizedString.purchaseRequestPage.placeholderTags}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>

    <Divider className={classes.divider} />

    {downloadingDeleting ? (<CircularProgress />) : (
      <Grid item>
        <Typography variant="subtitle1" className={classes.heading}>
          {LocalizedString.purchaseRequestPage.placeholderItems}
        </Typography>
        <Field
          name={RXFIELD_PURCHASE_REQUEST_PURCHASE_ITEMS}
          component={renderReduxFormEditableTableField}
          disabled
          loading={downloadingDeleting}
          currentPage={totalCurrentPage}
          totalCount={tokenTotalCount}
          defaultValue={defaultActiveSessionsValue}
          tableColumns={[
            {
              title: LocalizedString.purchaseRequestPage.labelNo, field: 'no', sorting: false, width: 40,
            },
            { title: LocalizedString.purchaseRequestPage.labelProductCode, field: 'productCode', sorting: false },
            { title: LocalizedString.purchaseRequestPage.labelProductName, field: 'productName', sorting: false },
            { title: LocalizedString.purchaseRequestPage.labelQuantity, field: 'quantity', sorting: false },
            { title: LocalizedString.purchaseRequestPage.labelPrice, field: 'individualPrice', sorting: false },
            { title: LocalizedString.purchaseRequestPage.labelTotalPrice, field: 'totalPrice', sorting: false },
          ]}
          disableEdit
          disableDelete
          disableToolbar
        />
      </Grid>
    )}
  </Grid>
);

const PurchaseRequestPage = ({
  initialValues, profiles, downloading, downloadingDeleting, loadingProfile,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onCancelPressed, onChangePage, onChangePageSize, onChangeProfileText, onRefresh,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
  totalCurrentPage, totalCount, pageMode,
}) => {
  const classes = useStyles();
  return (
    <FunctionalPage
      data={RXSTATE_POINT_PURCHASE_REQUESTS}
      uiPage={RXSTATE_POINT_PURCHASE_REQUEST_PAGE}
      filterColumns={[
        {
          title: LocalizedString.purchaseRequestPage.placeholderCreatedDate,
          field: RXFIELD_CREATED_DATE,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.purchaseRequestPage.placeholderMerchantName,
          field: RXFIELD_PURCHASE_REQUEST_MERCHANT_NAME,
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.purchaseRequestPage.placeholderMerchantReferenceID,
          field: RXFIELD_PURCHASE_REQUEST_MERCHANT_REF_ID,
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.purchaseRequestPage.placeholderUserFullName,
          field: RXFIELD_PURCHASE_REQUEST_FULL_NAME,
          type: FILTER_TYPE_DROPDOWN,
          data: profiles,
          loading: loadingProfile,
          onChangeFilterText: onChangeProfileText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.purchaseRequestPage.placeholderRequestStatus,
          field: RXFIELD_PURCHASE_REQUEST_REQUEST_STATUS,
          type: FILTER_TYPE_DROPDOWN,
          data: SUBMISSION_STATUS_VALUES,
          useDropdownValue: true,
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.purchaseRequestPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.purchaseRequestPage.labelUser, field: 'user.fullName', sorting: !downloading },
        { title: LocalizedString.purchaseRequestPage.labelAmount, field: 'amount', sorting: !downloading },
        { title: LocalizedString.purchaseRequestPage.labelLabel, field: 'label', sorting: !downloading },
        { title: LocalizedString.purchaseRequestPage.labelMerchantName, field: 'merchantName', sorting: !downloading },
        { title: LocalizedString.purchaseRequestPage.labelRequestStatus, field: 'requestStatus', sorting: !downloading },
        { title: LocalizedString.purchaseRequestPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      title={LocalizedString.purchaseRequestPage.title}
      useFullWidth
      disableCreate
      disableDelete
      disableEdit
    >
      {renderDialogContent(downloadingDeleting, totalCurrentPage, totalCount,
        initialValues.items, pageMode, classes)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_PURCHASE_REQUEST,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(PurchaseRequestPage);

PurchaseRequestPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  profiles: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingProfile: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeProfileText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  totalCurrentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageMode: PropTypes.string.isRequired,
};
