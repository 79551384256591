import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  RXFIELD_FORUM_CATEGORY_PARENT_CATEGORY, RXFIELD_FORUM_CATEGORY_STATUS,
  RXFIELD_FORUM_CATEGORY_NAME, RXFIELD_FORUM_CATEGORY_DESCRIPTION,
  RXSTATE_FORUM_CATEGORIES, RXSTATE_FORUM_CATEGORY_PAGE, FILTER_TYPE_DROPDOWN,
  status, RXFORM_FORUM_CATEGORY, PAGE_MODE_VIEW,
  RXFIELD_FORUM_CATEGORY_IMAGE, IMAGE_FILE_EXTENSIONS,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField, renderReduxFormOutlinedDropdownTextField,
  renderReduxFormDropzonePicker,
} from '../../../../redux-form-rendererer';
import { SimpleDataShape, FormInitialValueShape } from '../../type';
import { rxformValidateFormCategory } from '../../validation';

const renderDialogContent = (
  addingEditing, pageMode, loading, forumParentCategories,
  loadingForumParentCategories, forumCategoryDefaultValue,
  onChangeForumParentCategoryText, onParentCategoryOptionSelected,
  initialImage, onImageSelected,
) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid>
        <Field
          name={RXFIELD_FORUM_CATEGORY_NAME}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.forumCategoryPage.placeholderName}
          placeholder={LocalizedString.forumCategoryPage.placeholderName}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_FORUM_CATEGORY_DESCRIPTION}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.forumCategoryPage.placeholderDescription}
          placeholder={LocalizedString.forumCategoryPage.placeholderDescription}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid>
        <Field
          name={RXFIELD_FORUM_CATEGORY_PARENT_CATEGORY}
          component={renderReduxFormOutlinedDropdownTextField}
          placeholder={LocalizedString.forumCategoryPage.placeholderParentCategory}
          label={LocalizedString.forumCategoryPage.placeholderParentCategory}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          data={forumParentCategories}
          loading={loadingForumParentCategories}
          value={forumCategoryDefaultValue}
          onChangeText={onChangeForumParentCategoryText}
          onOptionSelected={onParentCategoryOptionSelected}
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_FORUM_CATEGORY_IMAGE}
          component={renderReduxFormDropzonePicker}
          label={LocalizedString.forumCategoryPage.placeholderForumCategoryImage}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          onImageSelected={onImageSelected}
          onBlur={(e) => e.preventDefault()}
          defaultValue={initialImage}
          imageFileExtension={IMAGE_FILE_EXTENSIONS}
          loading={addingEditing}
          required
        />
      </Grid>
    </Grid>
  </Grid>
);

const ForumCategoryPage = ({
  forumParentCategories,
  downloading, loadingForumParentCategories, addingEditing,
  handleSubmit, onAppear, onAdvancedFilterPressed, onApplyAdvancedFilterPressed,
  onChangeForumParentCategoryText, onChangePage, onChangePageSize, onRefresh,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
  onCreatePressed, onDeletePressed, onEditPressed, onConfirmDeletePressed,
  onCancelPressed, onSavePressed, onSubmitPressed, pageMode,
  initialValues, onParentCategoryOptionSelected, onImageSelected,
}) => (
  <FunctionalPage
    data={RXSTATE_FORUM_CATEGORIES}
    uiPage={RXSTATE_FORUM_CATEGORY_PAGE}
    filterColumns={[
      {
        title: LocalizedString.forumCategoryPage.placeholderParentCategory,
        field: RXFIELD_FORUM_CATEGORY_PARENT_CATEGORY,
        type: FILTER_TYPE_DROPDOWN,
        data: forumParentCategories,
        loading: loadingForumParentCategories,
        onChangeFilterText: onChangeForumParentCategoryText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.forumCategoryPage.placeholderStatus,
        field: RXFIELD_FORUM_CATEGORY_STATUS,
        type: FILTER_TYPE_DROPDOWN,
        data: status,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.forumCategoryPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.forumCategoryPage.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.forumCategoryPage.labelDescription, field: 'description', sorting: !downloading },
      { title: LocalizedString.forumCategoryPage.labelParent, field: 'parentCategory.name', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.forumCategoryPage.title}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onSubmitPressed={onSubmitPressed}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCancelPressed={onCancelPressed}
    onSavePressed={onSavePressed}
    createNewButtonCaption={LocalizedString.forumCategoryPage.buttonCaptionCreateNewForumCategory}
    deleteButtonCaption={LocalizedString.forumCategoryPage.buttonCaptionDeleteForumCategory}
    editButtonCaption={LocalizedString.forumCategoryPage.buttonCaptionEditForumCategory}
    useFullWidth
    createPermissionName="FORUM_CREATE_FORUM_CATEGORY"
    deletePermissionName="FORUM_DELETE_FORUM_CATEGORY"
    editPermissionName="FORUM_EDIT_FORUM_CATEGORY"
  >
    {renderDialogContent(
      addingEditing, pageMode, downloading, forumParentCategories, loadingForumParentCategories,
      initialValues.parentCategory, onChangeForumParentCategoryText, onParentCategoryOptionSelected,
      initialValues.image, onImageSelected,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_FORUM_CATEGORY,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: rxformValidateFormCategory,
})(ForumCategoryPage);

ForumCategoryPage.propTypes = {
  forumParentCategories: PropTypes.arrayOf(SimpleDataShape).isRequired,
  loadingForumParentCategories: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  pageMode: PropTypes.string.isRequired,
  initialValues: FormInitialValueShape.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeForumParentCategoryText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onParentCategoryOptionSelected: PropTypes.func.isRequired,
  onImageSelected: PropTypes.func.isRequired,
};
