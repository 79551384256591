import {
  setAlertErrorMessage,
  enablingDisablingLocation, setLocationSelectedOrderBy, setLocationSelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_LOCATIONS, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableLocation } from '../../../helper';
import downloadLocationsAsync from './downloadLocationsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingLocation(true));

    const { token } = getState().authentication;
    const { data } = getState().locations;
    const { tappedId } = getState().uiLocation;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableLocation(tappedId, status, token);

    dispatch(setLocationSelectedPageSize(20));
    dispatch(setLocationSelectedOrderBy(INITIAL_ORDER_BY_LOCATIONS));
    dispatch(downloadLocationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingLocation(false));
  }
};
