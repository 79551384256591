import {
  resyncingUser, setAlertErrorMessage, setUserSelectedOrderBy, setUserSelectedPageSize,
} from '../simple-action';
import { resyncUserIndividually } from '../../../helper';
import { INITIAL_ORDER_BY_USERS } from '../../../constant';
import downloadUsersAsync from './downloadUsersAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resyncingUser(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiUser;

    await resyncUserIndividually(tappedId, token);

    dispatch(setUserSelectedPageSize(20));
    dispatch(setUserSelectedOrderBy(INITIAL_ORDER_BY_USERS));
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(resyncingUser(false));
  }
};
