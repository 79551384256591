import { INITIAL_ORDER_BY_SPONSOR_CATEGORIES, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableSponsorCategory } from '../../../helper';
import {
  enablingDisablingSponsorCategory, setAlertErrorMessage,
  setSponsorCategorySelectedOrderBy, setSponsorCategorySelectedPageSize,
} from '../simple-action';
import downloadSponsorCategoriesAsync from './downloadSponsorCategoriesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingSponsorCategory(true));

    const { token } = getState().authentication;
    const { data } = getState().sponsorCategories;
    const { tappedId } = getState().uiSponsorCategory;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableSponsorCategory(tappedId, status, token);

    dispatch(setSponsorCategorySelectedPageSize(20));
    dispatch(setSponsorCategorySelectedOrderBy(INITIAL_ORDER_BY_SPONSOR_CATEGORIES));
    dispatch(downloadSponsorCategoriesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingSponsorCategory(false));
  }
};
