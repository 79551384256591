import { connect } from 'react-redux';
import { reset, change } from 'redux-form';
import {
  setAlertConfirmationMessage, setAlertErrorMessage, setActiveSideMenuItem,
  setSponsorAdvancedFilterDialogSelectedFilterString, setSponsorSearchText,
  clearSponsors, setSponsorSelectedPageSize, setSponsorSelectedOrderBy,
  downloadSponsorsAsync, downloadDeleteSponsorAsync,
  setSponsorTappedId, addEditSponsorAsync,
  setEventAdvancedFilterDialogSelectedFilterString, setEventSearchText,
  clearEvents, downloadEventsAsync, clearCompaniesMData,
  setCompanyAdvancedFilterDialogSelectedFilterStringMData, setCompanySearchTextMData,
  setSponsorSelectedEventId, setSponsorSelectedCompany, clearSponsorCategories,
  downloadSponsorCategoriesAsync, setSponsorCategoryAdvancedFilterDialogSelectedFilterString,
  setSponsorCategorySearchText, setSponsorSelectedSponsorCategory,
} from '../../redux/action';
import {
  MENUID_EVENT_SPONSOR,
  RXFORM_SPONSOR,
  RXFIELD_SPONSOR_EVENT_ID,
  RXFIELD_SPONSOR_COMPANY_ID,
  RXFIELD_SPONSOR_SPONSOR_CATEGORY_ID,
  PAGE_MODE_TABLE,
  ADVANCED_FILTER_MODE,
} from '../../constant';
import { transformDropdownData, debounceSearch, transformInitialValues } from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import SponsorPage from './sponsor.presentation';
import { downloadMDataCompaniesAsync } from '../../../../redux/action';
import LocalizedString from '../../localization';

const getInitialValues = (state) => {
  const { sponsors, uiSponsor, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiSponsor;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? sponsors.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      {
        eventId: { label: found.event.name, value: found.event.id },
        companyId: { label: found.company.name, value: found.company.id },
        sponsorCategoryId: { label: found.category.name, value: found.category.id },
        order: found.order,
      })
    : {
      eventId: { label: '', value: '' },
      province: { label: '', value: '' },
      sponsorCategoryId: { label: '', value: '' },
      order: '',
    };
  return initVal;
};

const searchEventDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
);

const searchCompanyDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
);

const searchSponsorCategoryDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearSponsorCategories());
    dispatch(downloadSponsorCategoriesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
);

const mapStateToProps = (state) => ({
  addingEditing: state.uiSponsor.addingEditing,
  downloading: state.uiSponsor.downloading,
  sponsors: state.sponsors.data,
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  events: transformDropdownData(state.events.data),
  downloadingEvents: state.uiEvent.downloading,
  companiesMdata: transformDropdownData(state.companiesMdata.data),
  downloadingCompanies: state.uiCompany.downloading,
  sponsorCategories: transformDropdownData(state.sponsorCategories.data),
  downloadingSponsorCategories: state.uiSponsorCategory.downloading,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData(''));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setEventAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setEventSearchText(''));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setSponsorCategoryAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setSponsorCategorySearchText(''));
    dispatch(clearSponsorCategories());
    dispatch(downloadSponsorCategoriesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onAppear: () => {
    dispatch(setSponsorAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_EVENT_SPONSOR));
    dispatch(setSponsorSearchText(''));
    dispatch(clearSponsors());
    dispatch(setSponsorSelectedPageSize(20));
    dispatch(downloadSponsorsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace('sponsorCategory', 'category.id')
      .replace('event', 'event.id').replace('company', 'company.id');
    dispatch(setSponsorAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearSponsors());
    dispatch(downloadSponsorsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_SPONSOR));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadSponsorsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setSponsorSelectedPageSize(pageSize));
    dispatch(downloadSponsorsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmContextMenuPressed: (reason, message) => {
    if (message === GlobalLocalizedString.common.msgDeleteConfirmation) {
      dispatch(downloadDeleteSponsorAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else if (message === LocalizedString.eventInvitationPage.msgResendConfirmation) {
      dispatch(setAlertErrorMessage);
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setSponsorTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onDeletePressed: (id) => {
    dispatch(setSponsorTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setSponsorTappedId(id));

    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled|visibilityStatus=visible'));
    dispatch(setEventSearchText(''));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setSponsorCategoryAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setSponsorCategorySearchText(''));
    dispatch(clearSponsorCategories());
    dispatch(downloadSponsorCategoriesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setSponsorSelectedPageSize(pageSize));
    dispatch(clearSponsors());
    dispatch(downloadSponsorsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setSponsorAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearSponsors());
    dispatch(downloadSponsorsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setSponsorSearchText(text));
      dispatch(clearSponsors());
      await dispatch(downloadSponsorsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSavePressed: async ({
    eventId, sponsorCategoryId, companyId, order,
  }) => {
    try {
      await dispatch(addEditSponsorAsync(eventId, sponsorCategoryId, companyId, order));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setSponsorSelectedOrderBy(orderBy));
    dispatch(clearSponsors());
    dispatch(downloadSponsorsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    eventId, sponsorCategoryId, companyId, order,
  }) => {
    await dispatch(addEditSponsorAsync(eventId, sponsorCategoryId, companyId, order));
  },
  onViewPressed: (id) => {
    dispatch(setSponsorTappedId(id));
    dispatch(downloadDeleteSponsorAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeEventText: (text, mode) => {
    dispatch(setEventAdvancedFilterDialogSelectedFilterString(
      mode === ADVANCED_FILTER_MODE
        ? '' : 'status=enabled|visibilityStatus=visible',
    ));
    dispatch(setEventSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchEventDebounce(dispatch);
    }
  },
  onChangeCompanyText: (text, mode) => {
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData(
      mode === ADVANCED_FILTER_MODE
        ? '' : 'status=enabled',
    ));
    dispatch(setCompanySearchTextMData(text));
    if (text.length >= 3 || text.length === 0) {
      searchCompanyDebounce(dispatch);
    }
  },
  onChangeSponsorCategoryText: (text, mode) => {
    dispatch(setSponsorCategoryAdvancedFilterDialogSelectedFilterString(
      mode === ADVANCED_FILTER_MODE
        ? '' : 'status=enabled',
    ));
    dispatch(setSponsorCategorySearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchSponsorCategoryDebounce(dispatch);
    }
  },
  onEventsTypeSelected: (option) => {
    if (option) {
      dispatch(setSponsorSelectedEventId(option.value));
      dispatch(change(RXFORM_SPONSOR, RXFIELD_SPONSOR_EVENT_ID, option));
    } else {
      dispatch(setSponsorSelectedEventId(''));
      dispatch(change(RXFORM_SPONSOR, RXFIELD_SPONSOR_EVENT_ID, ''));
    }
  },
  onCompaniesTypeSelected: (option) => {
    if (option) {
      dispatch(setSponsorSelectedCompany(option));
      dispatch(change(RXFORM_SPONSOR, RXFIELD_SPONSOR_COMPANY_ID, option));
    } else {
      dispatch(setSponsorSelectedCompany([]));
      dispatch(change(RXFORM_SPONSOR, RXFIELD_SPONSOR_COMPANY_ID, []));
    }
  },
  onSponsorCategoriesTypeSelected: (option) => {
    if (option) {
      dispatch(setSponsorSelectedSponsorCategory(option));
      dispatch(change(RXFORM_SPONSOR, RXFIELD_SPONSOR_SPONSOR_CATEGORY_ID, option));
    } else {
      dispatch(setSponsorSelectedSponsorCategory([]));
      dispatch(change(RXFORM_SPONSOR, RXFIELD_SPONSOR_SPONSOR_CATEGORY_ID, []));
    }
  },
  onCreatePressed: () => {
    dispatch(setSponsorTappedId(''));

    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled|visibilityStatus=visible'));
    dispatch(setEventSearchText(''));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setSponsorCategoryAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setSponsorCategorySearchText(''));
    dispatch(clearSponsorCategories());
    dispatch(downloadSponsorCategoriesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SponsorPage);
