import {
  addSessionCategory,
  clearSessionCategories,
  downloadingDeletingSessionCategory,
  setAlertErrorMessage, setSessionCategoryTappedId, setFunctionalPageMode,
} from '../simple-action';
import { PAGE_MODE_TABLE } from '../../../constant';
import { deleteSessionCategory, downloadSessionCategory } from '../../../helper';
import downloadSessionCategoriesAsync from './downloadSessionCategoriesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingSessionCategory(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSessionCategory;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteSessionCategory(tappedId, token);
      dispatch(setSessionCategoryTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearSessionCategories());
      dispatch(downloadSessionCategoriesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const sessionCategory = await downloadSessionCategory(tappedId, token);
      dispatch(addSessionCategory(sessionCategory));
    }
  } finally {
    dispatch(downloadingDeletingSessionCategory(false));
  }
};
