import {
  RXFIELD_EVENT_OPERATOR_MAPPING_USER,
  RXFIELD_EVENT_OPERATOR_MAPPING_EVENT,
  RXFIELD_EVENT_OPERATOR_MAPPING_VALID_UNTIL,
  RXFIELD_EVENT_OPERATOR_INVITATION_RECIPIENTS,
  RXFIELD_EVENT_OPERATOR_INVITATION_EVENT,
} from './constant';
import { rxFormValidateRequiredFields } from '../../validation';

export const rxformValidateEventOperatorMapping = (values) => {
  const requiredFields = [
    RXFIELD_EVENT_OPERATOR_MAPPING_USER,
    RXFIELD_EVENT_OPERATOR_MAPPING_EVENT,
    RXFIELD_EVENT_OPERATOR_MAPPING_VALID_UNTIL,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateEventOperatorInvitation = (values) => {
  const requiredFields = [
    RXFIELD_EVENT_OPERATOR_INVITATION_EVENT,
    RXFIELD_EVENT_OPERATOR_INVITATION_RECIPIENTS,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
