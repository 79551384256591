import { ORDER_SORT_ASCENDING, ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_BASE_URL_EVENT = '/event/api/v1/Event';
export const REST_BASE_URL_SESSION = '/event/api/v1/Session';
export const REST_URL_EVENT_CATEGORIES = '/event/api/v1/Category?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|name|description';
export const REST_URL_VIEW_DELETE_EVENT_CATEGORY = '/event/api/v1/Category/{id}';
export const REST_URL_ADD_EDIT_EVENT_CATEGORY = '/event/api/v1/Category';
export const REST_URL_ENABLE_DISABLE_EVENT_CATEGORY = '/event/api/v1/Category/Status';
export const REST_URL_SESSION_CATEGORIES = '/event/api/v1/SessionCategory?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|name|description';
export const REST_URL_VIEW_DELETE_SESSION_CATEGORY = '/event/api/v1/SessionCategory/{id}';
export const REST_URL_ADD_EDIT_SESSION_CATEGORY = '/event/api/v1/SessionCategory';
export const REST_URL_ENABLE_DISABLE_SESSION_CATEGORY = '/event/api/v1/SessionCategory/Status';
export const REST_URL_SPONSOR_CATEGORIES = '/event/api/v1/SponsorCategory?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|name|description';
export const REST_URL_VIEW_DELETE_SPONSOR_CATEGORY = '/event/api/v1/SponsorCategory/{id}';
export const REST_URL_ADD_EDIT_SPONSOR_CATEGORY = '/event/api/v1/SponsorCategory';
export const REST_URL_ENABLE_DISABLE_SPONSOR_CATEGORY = '/event/api/v1/SponsorCategory/Status';
export const REST_URL_SPEAKERS = '/event/api/v1/Speaker?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|name|companyName|jobTitle';
export const REST_URL_VIEW_DELETE_SPEAKER = '/event/api/v1/Speaker/{id}';
export const REST_URL_ADD_EDIT_SPEAKER = '/event/api/v1/Speaker';
export const REST_URL_ENABLE_DISABLE_SPEAKER = '/event/api/v1/Speaker/Status';
export const REST_URL_LOCATIONS = '/event/api/v1/Location?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|name|address';
export const REST_URL_VIEW_DELETE_LOCATION = '/event/api/v1/Location/{id}';
export const REST_URL_ADD_EDIT_LOCATION = '/event/api/v1/Location';
export const REST_URL_ENABLE_DISABLE_LOCATION = '/event/api/v1/Location/Status';
export const REST_URL_ROOMS = '/event/api/v1/Room?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|name';
export const REST_URL_VIEW_DELETE_ROOM = '/event/api/v1/Room/{id}';
export const REST_URL_ADD_EDIT_ROOM = '/event/api/v1/Room';
export const REST_URL_ENABLE_DISABLE_ROOM = '/event/api/v1/Room/Status';
export const REST_URL_SESSIONS = '/event/api/v1/Session?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|name';
export const REST_URL_VIEW_DELETE_SESSION = '/event/api/v1/Session/{id}';
export const REST_URL_ADD_EDIT_SESSION = '/event/api/v1/Session';
export const REST_URL_ENABLE_DISABLE_SESSION = '/event/api/v1/Session/Status';
export const REST_URL_SEND_SESSION_TO_ME = '/event/api/v1/Session/SendToMe/{id}';
export const REST_URL_EVENTS = '/event/api/v1/Event?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|name';
export const REST_URL_VIEW_DELETE_EVENT = '/event/api/v1/Event/{id}';
export const REST_URL_ADD_EDIT_EVENT = '/event/api/v1/Event';
export const REST_URL_ENABLE_DISABLE_EVENT = '/event/api/v1/Event/Status';
export const REST_URL_SEND_EVENT_TO_ME = '/event/api/v1/Event/SendToMe/{id}';
export const REST_URL_EVENT_INVITATIONS = '/event/api/v1/EventInvitation?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|email';
export const REST_URL_VIEW_DELETE_EVENT_INVITATION = '/event/api/v1/EventInvitation/{id}';
export const REST_URL_ADD_EDIT_EVENT_INVITATION = '/event/api/v1/EventInvitation';
export const REST_URL_RESEND_EVENT_INVITATION = '/event/api/v1/ResendEventInvitation';
export const REST_URL_SPONSORS = '/event/api/v1/Sponsor?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id';
export const REST_URL_VIEW_DELETE_SPONSOR = '/event/api/v1/Sponsor/{id}';
export const REST_URL_ADD_EDIT_SPONSOR = '/event/api/v1/Sponsor';
export const REST_URL_SESSION_INVITATIONS = '/event/api/v1/SessionInvitation?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|email';
export const REST_URL_VIEW_DELETE_SESSION_INVITATION = '/event/api/v1/SessionInvitation/{id}';
export const REST_URL_ADD_EDIT_SESSION_INVITATION = '/event/api/v1/SessionInvitation';
export const REST_URL_RESEND_SESSION_INVITATION = '/event/api/v1/ResendSessionInvitation';
export const REST_URL_EVENT_REGISTRATIONS = '/event/api/v1/EventRegistration?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|registrationCode';
export const REST_URL_VIEW_EVENT_REGISTRATION = '/event/api/v1/EventRegistration/{id}';
export const REST_URL_APPROVE_REJECT_EVENT_REGISTRATION = '/event/api/v1/EventRegistrationApproval';
export const REST_URL_SESSION_REGISTRATIONS = '/event/api/v1/SessionRegistration?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|user.email|registrationCode';
export const REST_URL_VIEW_SESSION_REGISTRATION = '/event/api/v1/SessionRegistration/{id}';
export const REST_URL_APPROVE_REJECT_SESSION_REGISTRATION = '/event/api/v1/SessionRegistrationApproval';
export const REST_URL_SESSION_ROOMS = '/event/api/v1/Event/SessionRoom/{eventId}';

export const ROUTE_NAME_EVENT = '/event';
export const ROUTE_NAME_EVENT_CATEGORY = '/event-category';
export const ROUTE_NAME_SESSION_CATEGORY = '/session-category';
export const ROUTE_NAME_SPONSOR_CATEGORY = '/sponsor-category';
export const ROUTE_NAME_SPEAKER = '/speaker';
export const ROUTE_NAME_LOCATION = '/location';
export const ROUTE_NAME_SESSION = '/session';
export const ROUTE_NAME_ROOM = '/room';
export const ROUTE_NAME_EVENT_INVITATION = '/event-invitation';
export const ROUTE_NAME_SPONSOR = '/sponsor';
export const ROUTE_NAME_SESSION_INVITATION = '/session-invitation';
export const ROUTE_NAME_EVENT_REGISTRATION = '/event-registration';
export const ROUTE_NAME_SESSION_REGISTRATION = '/session-registration';

export const RXFORM_EVENT_CATEGORY = 'eventCategoryPage';
export const RXFORM_SESSION_CATEGORY = 'sessionCategoryPage';
export const RXFORM_SPONSOR_CATEGORY = 'sponsorCategoryPage';
export const RXFORM_SPEAKER = 'speakerPage';
export const RXFORM_LOCATION = 'locationPage';
export const RXFORM_ROOM = 'roomPage';
export const RXFORM_SESSION = 'sessionPage';
export const RXFORM_EVENT = 'eventPage';
export const RXFORM_EVENT_INVITATION = 'eventInvitationPage';
export const RXFORM_SPONSOR = 'sponsorPage';
export const RXFORM_SESSION_INVITATION = 'sessionInvitationPage';
export const RXFORM_EVENT_REGISTRATION = 'eventRegistrationPage';
export const RXFORM_SESSION_REGISTRATION = 'sessionRegistrationPage';

export const RXFIELD_EVENT_CATEGORY_DESCRIPTION = 'description';
export const RXFIELD_EVENT_CATEGORY_NAME = 'name';
export const RXFIELD_EVENT_CATEGORY_IMAGE = 'image';
export const RXFIELD_EVENT_CATEGORY_CLEAR_IMAGE = 'clearImage';
export const RXFIELD_SESSION_CATEGORY_DESCRIPTION = 'description';
export const RXFIELD_SESSION_CATEGORY_NAME = 'name';
export const RXFIELD_SPONSOR_CATEGORY_DESCRIPTION = 'description';
export const RXFIELD_SPONSOR_CATEGORY_NAME = 'name';
export const RXFIELD_SPONSOR_CATEGORY_ORDER = 'order';
export const RXFIELD_SPEAKER_COMPANY = 'companyName';
export const RXFIELD_SPEAKER_DESCRIPTION = 'description';
export const RXFIELD_SPEAKER_IMAGE = 'image';
export const RXFIELD_SPEAKER_JOB_TITLE = 'jobTitle';
export const RXFIELD_SPEAKER_NAME = 'name';
export const RXFIELD_LOCATION_NAME = 'name';
export const RXFIELD_LOCATION_CITY = 'city';
export const RXFIELD_LOCATION_ADDRESS = 'address';
export const RXFIELD_LOCATION_DESCRIPTION = 'description';
export const RXFIELD_LOCATION_POSTAL_CODE = 'postalCode';
export const RXFIELD_LOCATION_PHONE = 'phone';
export const RXFIELD_LOCATION_EMAIL = 'email';
export const RXFIELD_LOCATION_GOOGLE_MAP_URL = 'googleMapUrl';
export const RXFIELD_LOCATION_LATITUDE = 'latitude';
export const RXFIELD_LOCATION_LONGITUDE = 'longitude';
export const RXFIELD_LOCATION_AREA = 'area';
export const RXFIELD_ROOM_DESCRIPTION = 'description';
export const RXFIELD_ROOM_LOCATION = 'location';
export const RXFIELD_ROOM_LOCATION_ID = 'locationId';
export const RXFIELD_ROOM_LOCATION_NAME = 'locationName';
export const RXFIELD_ROOM_NAME = 'name';
export const RXFIELD_SESSION_NAME = 'name';
export const RXFIELD_SESSION_START_TIME = 'startTime';
export const RXFIELD_SESSION_END_TIME = 'endTime';
export const RXFIELD_SESSION_EARLIEST_CLOCK = 'earliestClockInBeforeMinutes';
export const RXFIELD_SESSION_MAX_CLOCK = 'maxClockInMinutes';
export const RXFIELD_SESSION_FEATURED = 'isFeatured';
export const RXFIELD_SESSION_FEATURED_ORDER = 'featuredOrder';
export const RXFIELD_SESSION_VISIBILITY_STATUS = 'visibilityStatus';
export const RXFIELD_SESSION_REGISTRATION_REWARD_TYPE = 'registrationRewardType';
export const RXFIELD_SESSION_REGISTRATION_REWARD_POINT = 'registrationRewardPoint';
export const RXFIELD_SESSION_REGISTRATION_REWARD_VOUCHERS = 'registrationRewardVouchers';
export const RXFIELD_SESSION_EVENT_NAME = 'eventName';
export const RXFIELD_SESSION_ROOM_NAME = 'roomName';
export const RXFIELD_SESSION_CATEGORY = 'categoryName';
export const RXFIELD_SESSION_DESCRIPTION = 'description';
export const RXFIELD_SESSION_SHORT_DESCRIPTION = 'shortDescription';
export const RXFIELD_SESSION_CLOCK_INSIDE_AREA = 'clockInMustBeInsideArea';
export const RXFIELD_SESSION_WHO_CAN_REGISTER = 'whoCanRegister';
export const RXFIELD_SESSION_NEED_APPROVAL = 'needApproval';
export const RXFIELD_SESSION_QUOTA = 'quota';
export const RXFIELD_SESSION_NORMAL_PRICE = 'normalPrice';
export const RXFIELD_SESSION_PRICE = 'price';
export const RXFIELD_SESSION_CAN_PAY_USING_POINT = 'canPayUsingPoint';
export const RXFIELD_SESSION_STATUS = 'status';
export const RXFIELD_SESSION_MEDIA = 'media';
export const RXFIELD_SESSION_SPEAKERS = 'speakers';
export const RXFIELD_SESSION_TYPE = 'type';
export const RXFIELD_SESSION_SURVEY_FORM_ID = 'surveyForm';
export const RXFIELD_SESSION_ADDITTIONAL_ITEMS = 'additionalItems';
export const RXFIELD_SESSION_ENABLE_COMMENT = 'enableComment';
export const RXFIELD_SESSION_COMMENT_ACCESS = 'commentAccess';
export const RXFIELD_SESSION_DISCUSSION_ID = 'discussionId';
export const RXFIELD_SESSION_CLOCK_IN_QR_CODE = 'clockInQrCode';
export const RXFIELD_SESSION_DEEP_LINK = 'deepLink';
export const RXFIELD_SESSION_BANNER = 'banner';
export const RXFIELD_EVENT_BANNER = 'banner';
export const RXFIELD_EVENT_CAN_PAY_USING_POINT = 'canPayUsingPoint';
export const RXFIELD_EVENT_CATEGORY = 'category';
export const RXFIELD_EVENT_CLOCK_IN_MUST_INSIDE_AREA = 'clockInMustBeInsideArea';
export const RXFIELD_EVENT_DESCRIPTION = 'description';
export const RXFIELD_EVENT_EARLIEST_CLOCK_IN = 'earliestClockInBeforeMinutes';
export const RXFIELD_EVENT_END_TIME = 'endTime';
export const RXFIELD_EVENT_EVENT_TYPE = 'eventType';
export const RXFIELD_EVENT_FEATURED = 'isFeatured';
export const RXFIELD_EVENT_FEATURED_ORDER = 'featuredOrder';
export const RXFIELD_EVENT_LOCATION = 'location';
export const RXFIELD_EVENT_MAX_CLOCK_IN = 'maxClockInMinutes';
export const RXFIELD_EVENT_MEDIA = 'media';
export const RXFIELD_EVENT_NAME = 'name';
export const RXFIELD_EVENT_NEEDS_APPROVAL = 'needApproval';
export const RXFIELD_EVENT_NORMAL_PRICE = 'normalPrice';
export const RXFIELD_EVENT_PRICE = 'price';
export const RXFIELD_EVENT_QUOTA = 'quota';
export const RXFIELD_EVENT_REGISTRATION_REWARD_POINT = 'registrationRewardPoint';
export const RXFIELD_EVENT_REGISTRATION_REWARD_TYPE = 'registrationRewardType';
export const RXFIELD_EVENT_REGISTRATION_REWARD_VOUCHER = 'registrationRewardVoucherIds';
export const RXFIELD_EVENT_SHORT_DESCRIPTION = 'shortDescription';
export const RXFIELD_EVENT_START_TIME = 'startTime';
export const RXFIELD_EVENT_VIISIBILITY_STATUS = 'visibilityStatus';
export const RXFIELD_EVENT_WHO_CAN_REGISTER = 'whoCanRegister';
export const RXFIELD_EVENT_ENABLE_COMMENT = 'enableComment';
export const RXFIELD_EVENT_COMMENT_ACCESS = 'commentAccess';
export const RXFIELD_EVENT_DISCUSSION_ID = 'discussionId';
export const RXFIELD_EVENT_CLOCK_IN_QR_CODE = 'clockInQrCode';
export const RXFIELD_EVENT_DEEP_LINK = 'deepLink';
export const RXFIELD_EVENT_ADDITTIONAL_ITEMS = 'additionalItems';
export const RXFIELD_EVENT_LANDING_PAGE = 'landingPage';
export const RXFIELD_EVENT_INVITATION_EVENT = 'event';
export const RXFIELD_EVENT_INVITATION_RECIPIENTS = 'recipients';
export const RXFIELD_EVENT_INVITATION_EMAIL = 'email';
export const RXFIELD_EVENT_INVITATION_END_TIME = 'endTime';
export const RXFIELD_EVENT_INVITATION_FULL_NAME = 'fullName';
export const RXFIELD_EVENT_INVITATION_EVENT_ID = 'eventId';
export const RXFIELD_EVENT_INVITATION_INVITATION_STATUS = 'invitationStatus';
export const RXFIELD_EVENT_INVITATION_LOCATION = 'location';
export const RXFIELD_EVENT_INVITATION_NAME = 'name';
export const RXFIELD_EVENT_INVITATION_PHONE = 'phone';
export const RXFIELD_EVENT_INVITATION_REGISTERED_ON = 'registeredOn';
export const RXFIELD_EVENT_INVITATION_RESENT_COUNT = 'resentCount';
export const RXFIELD_EVENT_INVITATION_SHORT_DESCRIPTION = 'shortDescription';
export const RXFIELD_EVENT_INVITATION_START_TIME = 'startTime';
export const RXFIELD_EVENT_INVITATION_USER_ID = 'userId';
export const RXFIELD_EVENT_INVITATION_TIME_ZONE = 'timeZone';
export const RXFIELD_EVENT_SPEAKERS = 'speakers';
export const RXFIELD_EVENT_SURVEY_FORM = 'surveyForm';
export const RXFIELD_SPONSOR_EVENT_ID = 'eventId';
export const RXFIELD_SPONSOR_SPONSOR_CATEGORY_ID = 'sponsorCategoryId';
export const RXFIELD_SPONSOR_COMPANY_ID = 'companyId';
export const RXFIELD_SPONSOR_ORDER = 'order';
export const RXFIELD_SESSION_INVITATION_SESSION = 'session';
export const RXFIELD_SESSION_INVITATION_RECIPIENTS = 'recipients';
export const RXFIELD_SESSION_INVITATION_EMAIL = 'email';
export const RXFIELD_SESSION_INVITATION_END_TIME = 'endTime';
export const RXFIELD_SESSION_INVITATION_FULL_NAME = 'fullName';
export const RXFIELD_SESSION_INVITATION_SESSION_ID = 'sessionId';
export const RXFIELD_SESSION_INVITATION_INVITATION_STATUS = 'invitationStatus';
export const RXFIELD_SESSION_INVITATION_LOCATION = 'location';
export const RXFIELD_SESSION_INVITATION_NAME = 'name';
export const RXFIELD_SESSION_INVITATION_PHONE = 'phone';
export const RXFIELD_SESSION_INVITATION_REGISTERED_ON = 'registeredOn';
export const RXFIELD_SESSION_INVITATION_RESENT_COUNT = 'resentCount';
export const RXFIELD_SESSION_INVITATION_SHORT_DESCRIPTION = 'shortDescription';
export const RXFIELD_SESSION_INVITATION_START_TIME = 'startTime';
export const RXFIELD_SESSION_INVITATION_USER_ID = 'userId';
export const RXFIELD_SESSION_INVITATION_TIME_ZONE = 'timeZone';
export const RXFIELD_EVENT_REGISTRATION_REGISTRATION_CODE = 'registrationCode';
export const RXFIELD_EVENT_REGISTRATION_REGISTRATION_STATUS = 'registrationStatus';
export const RXFIELD_EVENT_REGISTRATION_REGISTRATION_STATUS_NOTES = 'registrationStatusNotes';
export const RXFIELD_EVENT_REGISTRATION_APPROVED_ON = 'approvedRejectedOn';
export const RXFIELD_EVENT_REGISTRATION_REJECTED_ON = 'approvedRejectedOn';
export const RXFIELD_EVENT_REGISTRATION_APPROVED_BY = 'approvedRejectedBy';
export const RXFIELD_EVENT_REGISTRATION_REJECTED_BY = 'approvedRejectedBy';
export const RXFIELD_EVENT_REGISTRATION_CLOCK_IN_TIME = 'clockInTime';
export const RXFIELD_EVENT_REGISTRATION_CLOCK_IN_DRIFT = 'clockInDriftTimeMinutes';
export const RXFIELD_EVENT_REGISTRATION_CLOCK_IN_LATITUDE = 'clockInLatitude';
export const RXFIELD_EVENT_REGISTRATION_CLOCK_IN_LONGTITUDE = 'clockInLongitude';
export const RXFIELD_EVENT_REGISTRATION_CLOCK_IN_LOCATION_STATUS = 'clockInLocationStatus';
export const RXFIELD_EVENT_REGISTRATION_USER_ID = 'user.id';
export const RXFIELD_EVENT_REGISTRATION_USER_FULLNAME = 'user.fullName';
export const RXFIELD_EVENT_REGISTRATION_USER_EMAIL = 'user.email';
export const RXFIELD_EVENT_REGISTRATION_USER_PHONE = 'user.phoneNumber';
export const RXFIELD_EVENT_REGISTRATION_EVENT_ID = 'event.id';
export const RXFIELD_EVENT_REGISTRATION_EVENT_NAME = 'event.name';
export const RXFIELD_EVENT_REGISTRATION_EVENT_SHORT_DESCRIPTION = 'event.shortDescription';
export const RXFIELD_EVENT_REGISTRATION_EVENT_START_TIME = 'eventStartTime';
export const RXFIELD_EVENT_REGISTRATION_EVENT_END_TIME = 'eventEndTime';
export const RXFIELD_EVENT_REGISTRATION_EVENT_TIMEZONE = 'eventTimeZone';
export const RXFIELD_EVENT_REGISTRATION_PAYMENT_ID = 'payment.id';
export const RXFIELD_EVENT_REGISTRATION_PAYMENT_PRICE = 'price';
export const RXFIELD_EVENT_REGISTRATION_PAYMENT_PAYMENT_DATE = 'payment.createdDate';
export const RXFIELD_EVENT_REGISTRATION_PAYMENT_TAX = 'tax';
export const RXFIELD_EVENT_REGISTRATION_PAYMENT_TRANSACTION_ID = 'payment.transactionId';
export const RXFIELD_EVENT_REGISTRATION_PAYMENT_SERVICE_FEE = 'serviceFee';
export const RXFIELD_EVENT_REGISTRATION_PAYMENT_PAYMENT_CODE = 'payment.paymentCode';
export const RXFIELD_EVENT_REGISTRATION_PAYMENT_DISCOUNT = 'discount';
export const RXFIELD_EVENT_REGISTRATION_PAYMENT_PAYMENT_CHANNEL = 'payment.paymentChannel';
export const RXFIELD_EVENT_REGISTRATION_PAYMENT_DISCOUNT_NOTES = 'discountNotes';
export const RXFIELD_EVENT_REGISTRATION_PAYMENT_PAYMENT_STATUS = 'payment.paymentStatus';
export const RXFIELD_EVENT_REGISTRATION_PAYMENT_POINT_DISCOUNT = 'pointDiscount';
export const RXFIELD_EVENT_REGISTRATION_PAYMENT_PAYMENT_STATUS_NOTES = 'payment.paymentStatusNotes';
export const RXFIELD_EVENT_REGISTRATION_PAYMENT_POINT_DISCOUNT_ID = 'pointDiscountId';
export const RXFIELD_EVENT_REGISTRATION_PAYMENT_LAST_CHANGED_STATUS = 'payment.lastChangedStatus';
export const RXFIELD_EVENT_REGISTRATION_PAYMENT_TOTAL = 'total';
export const RXFIELD_SESSION_REGISTRATION_REGISTRATION_CODE = 'registrationCode';
export const RXFIELD_SESSION_REGISTRATION_REGISTRATION_STATUS = 'registrationStatus';
export const RXFIELD_SESSION_REGISTRATION_REGISTRATION_STATUS_NOTES = 'registrationStatusNotes';
export const RXFIELD_SESSION_REGISTRATION_APPROVED_ON = 'approvedRejectedOn';
export const RXFIELD_SESSION_REGISTRATION_REJECTED_ON = 'approvedRejectedOn';
export const RXFIELD_SESSION_REGISTRATION_APPROVED_BY = 'approvedRejectedBy';
export const RXFIELD_SESSION_REGISTRATION_REJECTED_BY = 'approvedRejectedBy';
export const RXFIELD_SESSION_REGISTRATION_CLOCK_IN_TIME = 'clockInTime';
export const RXFIELD_SESSION_REGISTRATION_CLOCK_IN_DRIFT = 'clockInDriftTimeMinutes';
export const RXFIELD_SESSION_REGISTRATION_CLOCK_IN_LATITUDE = 'clockInLatitude';
export const RXFIELD_SESSION_REGISTRATION_CLOCK_IN_LONGTITUDE = 'clockInLongitude';
export const RXFIELD_SESSION_REGISTRATION_CLOCK_IN_LOCATION_STATUS = 'clockInLocationStatus';
export const RXFIELD_SESSION_REGISTRATION_USER_ID = 'user.id';
export const RXFIELD_SESSION_REGISTRATION_USER_FULLNAME = 'user.fullName';
export const RXFIELD_SESSION_REGISTRATION_USER_EMAIL = 'user.email';
export const RXFIELD_SESSION_REGISTRATION_USER_PHONE = 'user.phoneNumber';
export const RXFIELD_SESSION_REGISTRATION_SESSION_ID = 'session.id';
export const RXFIELD_SESSION_REGISTRATION_SESSION_NAME = 'session.name';
export const RXFIELD_SESSION_REGISTRATION_SESSION_SHORT_DESCRIPTION = 'session.shortDescription';
export const RXFIELD_SESSION_REGISTRATION_SESSION_START_TIME = 'sessionStartTime';
export const RXFIELD_SESSION_REGISTRATION_SESSION_END_TIME = 'sessionEndTime';
export const RXFIELD_SESSION_REGISTRATION_SESSION_TIMEZONE = 'sessionTimeZone';
export const RXFIELD_SESSION_REGISTRATION_PAYMENT_ID = 'payment.id';
export const RXFIELD_SESSION_REGISTRATION_PAYMENT_PRICE = 'price';
export const RXFIELD_SESSION_REGISTRATION_PAYMENT_PAYMENT_DATE = 'payment.createdDate';
export const RXFIELD_SESSION_REGISTRATION_PAYMENT_TAX = 'tax';
export const RXFIELD_SESSION_REGISTRATION_PAYMENT_TRANSACTION_ID = 'payment.transactionId';
export const RXFIELD_SESSION_REGISTRATION_PAYMENT_SERVICE_FEE = 'serviceFee';
export const RXFIELD_SESSION_REGISTRATION_PAYMENT_PAYMENT_CODE = 'payment.paymentCode';
export const RXFIELD_SESSION_REGISTRATION_PAYMENT_DISCOUNT = 'discount';
export const RXFIELD_SESSION_REGISTRATION_PAYMENT_PAYMENT_CHANNEL = 'payment.paymentChannel';
export const RXFIELD_SESSION_REGISTRATION_PAYMENT_DISCOUNT_NOTES = 'discountNotes';
export const RXFIELD_SESSION_REGISTRATION_PAYMENT_PAYMENT_STATUS = 'payment.paymentStatus';
export const RXFIELD_SESSION_REGISTRATION_PAYMENT_POINT_DISCOUNT = 'pointDiscount';
export const RXFIELD_SESSION_REGISTRATION_PAYMENT_PAYMENT_STATUS_NOTES = 'payment.paymentStatusNotes';
export const RXFIELD_SESSION_REGISTRATION_PAYMENT_POINT_DISCOUNT_ID = 'pointDiscountId';
export const RXFIELD_SESSION_REGISTRATION_PAYMENT_LAST_CHANGED_STATUS = 'payment.lastChangedStatus';
export const RXFIELD_SESSION_REGISTRATION_PAYMENT_TOTAL = 'total';

export const RXSTATE_EVENT_CATEGORIES = 'eventCategories';
export const RXSTATE_EVENT_CATEGORY_PAGE = 'uiEventCategory';
export const RXSTATE_SESSION_CATEGORIES = 'sessionCategories';
export const RXSTATE_SESSION_CATEGORY_PAGE = 'uiSessionCategory';
export const RXSTATE_SPONSOR_CATEGORIES = 'sponsorCategories';
export const RXSTATE_SPONSOR_CATEGORY_PAGE = 'uiSponsorCategory';
export const RXSTATE_SPEAKERS = 'speakers';
export const RXSTATE_SPEAKER_PAGE = 'uiSpeaker';
export const RXSTATE_LOCATIONS = 'locations';
export const RXSTATE_LOCATION_PAGE = 'uiLocation';
export const RXSTATE_ROOMS = 'rooms';
export const RXSTATE_ROOM_PAGE = 'uiRoom';
export const RXSTATE_SESSIONS = 'sessions';
export const RXSTATE_SESSION_PAGE = 'uiSession';
export const RXSTATE_EVENTS = 'events';
export const RXSTATE_EVENT_PAGE = 'uiEvent';
export const RXSTATE_EVENT_INVITATIONS = 'eventInvitations';
export const RXSTATE_EVENT_INVITATION_PAGE = 'uiEventInvitation';
export const RXSTATE_SPONSORS = 'sponsors';
export const RXSTATE_SPONSOR_PAGE = 'uiSponsor';
export const RXSTATE_SESSION_INVITATIONS = 'sessionInvitations';
export const RXSTATE_SESSION_INVITATION_PAGE = 'uiSessionInvitation';
export const RXSTATE_EVENT_REGISTRATIONS = 'eventRegistrations';
export const RXSTATE_EVENT_REGISTRATION_PAGE = 'uiEventRegistration';
export const RXSTATE_SESSION_REGISTRATIONS = 'sessionRegistrations';
export const RXSTATE_SESSION_REGISTRATION_PAGE = 'uiSessionRegistration';

export const INITIAL_ORDER_BY_EVENT_CATEGORIES = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_SESSION_CATEGORIES = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_SPONSOR_CATEGORIES = `${RXFIELD_SPONSOR_CATEGORY_ORDER} ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_SPEAKERS = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_LOCATIONS = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_ROOMS = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_SESSIONS = `startTime ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_EVENTS = `startTime ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_EVENT_INVITATIONS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_SPONSORS = `createdDate ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_EVENT_REGISTRATIONS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_SESSION_INVITATIONS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_SESSION_REGISTRATIONS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_EVENT = 'MENUID_EVENT';
export const MENUID_EVENT_CATEGORY = 'MENUID_EVENT_CATEGORY';
export const MENUID_EVENT_SESSION_CATEGORY = 'MENUID_EVENT_SESSION_CATEGORY';
export const MENUID_EVENT_SPONSOR_CATEGORY = 'MENUID_EVENT_SPONSOR_CATEGORY';
export const MENUID_EVENT_SPEAKER = 'MENUID_EVENT_SPEAKER';
export const MENUID_EVENT_LOCATION = 'MENUID_EVENT_LOCATION';
export const MENUID_EVENT_ROOM = 'MENUID_EVENT_ROOM';
export const MENUID_EVENT_SESSION = 'MENUID_EVENT_SESSION';
export const MENUID_EVENT_EVENT = 'MENUID_EVENT_EVENT';
export const MENUID_EVENT_EVENT_INVITATION = 'MENUID_EVENT_EVENT_INVITATION';
export const MENUID_EVENT_SPONSOR = 'MENUID_EVENT_SPONSOR';
export const MENUID_EVENT_SESSION_INVITATION = 'MENUID_EVENT_SESSION_INVITATION';
export const MENUID_EVENT_EVENT_REGISTRATION = 'MENUID_EVENT_EVENT_REGISTRATION';
export const MENUID_EVENT_SESSION_REGISTRATION = 'MENUID_EVENT_SESSION_REGISTRATION';

export const SESSION_REGISTRATION_REWARD_TYPE_DATA = ['Point', 'Voucher', 'Both', 'None'];
export const SESSION_WHO_CAN_REGISTER_DATA = ['Everyone', 'InviteOnly', 'None'];
export const SESSION_TYPE = ['Regular', 'FreeTime'];

export const SESSION_REWARD_TYPE_VOUCHER = 'Voucher';
export const SESSION_REWARD_TYPE_POINT = 'Point';
export const SESSION_REWARD_TYPE_BOTH = 'Both';
export const COMMENT_ACCESS_EVERYONE = 'Everyone';
export const COMMENT_ACCESS_REGISTERED = 'Registered';

export const ADDITIONAL_ITEM_VISIBILITY_TIME_ALL_TIME = 'AllTime';
export const ADDITIONAL_ITEM_VISIBILITY_TIME_AFTER_STARTED = 'AfterStarted';
export const ADDITIONAL_ITEM_VISIBILITY_TIME_AFTER_FINISHED = 'AfterFinished';

export const REWARDS_TYPE = ['Point', 'Voucher', 'Both', 'None'];
export const EVENT_TYPE = ['Simple', 'Regular'];
export const WHO_CAN_REGISTER = ['Everyone', 'InviteOnly', 'None'];
export const FILTER_EVENT_TYPE = ['Simple', 'Regular'];
export const COMMENT_ACCESSES = [COMMENT_ACCESS_EVERYONE, COMMENT_ACCESS_REGISTERED];
export const ADDITIONAL_ITEMS_VIEW_ACCESSES = {
  [COMMENT_ACCESS_EVERYONE]: COMMENT_ACCESS_EVERYONE,
  [COMMENT_ACCESS_REGISTERED]: COMMENT_ACCESS_REGISTERED,
};
export const ADDITIONAL_ITEMS_VISIBILITY_TIMES = {
  [ADDITIONAL_ITEM_VISIBILITY_TIME_ALL_TIME]: ADDITIONAL_ITEM_VISIBILITY_TIME_ALL_TIME,
  [ADDITIONAL_ITEM_VISIBILITY_TIME_AFTER_STARTED]: ADDITIONAL_ITEM_VISIBILITY_TIME_AFTER_STARTED,
  [ADDITIONAL_ITEM_VISIBILITY_TIME_AFTER_FINISHED]: ADDITIONAL_ITEM_VISIBILITY_TIME_AFTER_FINISHED,
};

export const EVENT_INVITATION_STATUS = [
  { label: 'Registered', value: 'Registered' },
  { label: 'Pending', value: 'Pending' },
];

export const SESSION_INVITATION_STATUS = [
  { label: 'Registered', value: 'Registered' },
  { label: 'Pending', value: 'Pending' },
];

export const REGISTRATION_STATUS_APPROVED = 'Approved';
export const REGISTRATION_STATUS_REJECTED = 'Rejected';
export const REGISTRATION_STATUS_CANCELED = 'Canceled';
export const REGISTRATION_STATUS_WAITING_APPROVAL = 'WaitingApproval';
export const REGISTRATION_STATUS_WAITING_PAYMENT = 'WaitingPayment';

export const REGISTRATION_STATUS_OPTIONS = [
  { label: REGISTRATION_STATUS_APPROVED, value: REGISTRATION_STATUS_APPROVED },
  { label: REGISTRATION_STATUS_REJECTED, value: REGISTRATION_STATUS_REJECTED },
  { label: REGISTRATION_STATUS_CANCELED, value: REGISTRATION_STATUS_CANCELED },
  { label: 'Waiting Approval', value: REGISTRATION_STATUS_WAITING_APPROVAL },
  { label: 'Waiting Payment', value: REGISTRATION_STATUS_WAITING_PAYMENT },
];

export const CLOCK_IN_LOCATION_STATUS_INSIDE_AREA = 'InsideArea';
export const CLOCK_IN_LOCATION_STATUS_OUTSIDE_AREA = 'OutsideArea';

export const CLOCK_IN_LOCATION_STATUS_OPTIONS = [
  {
    label: 'Inside Area',
    value: CLOCK_IN_LOCATION_STATUS_INSIDE_AREA,
  },
  {
    label: 'Outside Area',
    value: CLOCK_IN_LOCATION_STATUS_OUTSIDE_AREA,
  },
];

export const EVENT_TYPE_SIMPLE = 'Simple';
export const EVENT_TYPE_REGULAR = 'Regular';
