import { ORDER_SORT_DESCENDING } from '../../constant';
import LocalizedString from './localization';

export * from '../../constant';

export const REST_URL_GAMEQR_CATEGORIES = '/gameqr/api/v1/ChallengeCategory?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DELETE_GAMEQR_CATEGORY = '/gameqr/api/v1/ChallengeCategory/{id}';
export const REST_URL_ADD_EDIT_GAMEQR_CATEGORY = '/gameqr/api/v1/ChallengeCategory';
export const REST_URL_CHALLENGES = '/gameqr/api/v1/Challenge?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=category.name|title&filterString=';
export const REST_URL_ADD_EDIT_CHALLENGE = '/gameqr/api/v1/Challenge';
export const REST_URL_VIEW_DELETE_CHALLENGE = '/gameqr/api/v1/Challenge/{id}';
export const REST_URL_SCANNED_CODES = '/gameqr/api/v1/ScannedCode?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|label';
export const REST_URL_VIEW_DELETE_SCANNED_CODE = '/gameqr/api/v1/ScannedCode/{id}';
export const REST_URL_COMPLETED_CHALLENGES = '/gameqr/api/v1/CompletedChallenge?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=user.fullName|challenge.title&filterString=';
export const REST_URL_VIEW_DELETE_COMPLETED_CHALLENGE = '/gameqr/api/v1/CompletedChallenge/{id}';

export const ROUTE_NAME_GAMEQR = '/gameqr';
export const ROUTE_NAME_GAMEQR_CATEGORY = '/gameqr-category';
export const ROUTE_NAME_GAMEQR_CHALLENGE = '/challenge';
export const ROUTE_NAME_GAMEQR_SCANNED_CODE = '/scanned-code';
export const ROUTE_NAME_GAMEQR_COMPLETED_CHALLENGE = '/completed-challenge';

export const RXFORM_GAMEQR_CATEGORY = 'gameQRCategoryPage';
export const RXFORM_CHALLENGE = 'challengePage';
export const RXFORM_SCANNED_CODE = 'scannedCodePage';
export const RXFORM_COMPLETED_CHALLENGE = 'completedChallengePage';

export const RXFIELD_GAMEQR_CATEGORY_PARENT_CATEGORY = 'parentCategory';
export const RXFIELD_GAMEQR_CATEGORY_STATUS = 'status';
export const RXFIELD_GAMEQR_CATEGORY_NAME = 'name';
export const RXFIELD_GAMEQR_CATEGORY_DESCRIPTION = 'description';
export const RXFIELD_GAMEQR_CATEGORY_IMAGE = 'image';
export const RXFIELD_CHALLENGE_TITLE = 'title';
export const RXFIELD_CHALLENGE_CONTENT = 'content';
export const RXFIELD_CHALLENGE_VALID_FROM = 'validFrom';
export const RXFIELD_CHALLENGE_VALID_UNTIL = 'validUntil';
export const RXFIELD_CHALLENGE_CATEGORY = 'category';
export const RXFIELD_CHALLENGE_VISIBILITY_STATUS = 'visibilityStatus';
export const RXFIELD_CHALLENGE_MEDIA = 'media';
export const RXFIELD_CHALLENGE_CODES = 'codes';
export const RXFIELD_CHALLENGE_REACTION_ID = 'reactionId';
export const RXFIELD_CHALLENGE_TAGS = 'tags';
export const RXFIELD_CHALLENGE_SCAN_LIMIT_MODE = 'scanLimitMode';
export const RXFIELD_CHALLENGE_SCAN_LIMIT_VALUE = 'scanLimitAbsoluteValue';
export const RXFIELD_CHALLENGE_SCAN_LIMIT_TIMEZONE = 'scanLimitTimeZone';
export const RXFIELD_CHALLENGE_SCAN_LIMIT_HOUR = 'scanLimitPerHour';
export const RXFIELD_CHALLENGE_SCAN_LIMIT_DAY = 'scanLimitPerDay';
export const RXFIELD_CHALLENGE_SCAN_LIMIT_WEEK = 'scanLimitPerWeek';
export const RXFIELD_CHALLENGE_SCAN_LIMIT_MONTH = 'scanLimitPerMonth';
export const RXFIELD_CHALLENGE_SCAN_POLICY = 'scanPolicy';
export const RXFIELD_CHALLENGE_IS_PROMOTED = 'isPromoted';
export const RXFIELD_CHALLENGE_ORDER = 'order';
export const RXFIELD_CHALLENGE_DEEP_LINK = 'deepLink';
export const RXFIELD_CHALLENGE_COMPLETE_REWARD_TYPE = 'completedRewardType';
export const RXFIELD_CHALLENGE_VOUCHER = 'vouchers';
export const RXFIELD_CHALLENGE_BYPASS_MAX_PURCHASE = 'bypassMaxPurchaseValidation';
export const RXFIELD_CHALLENGE_BYPASS_ACHIEVEMENT_VALIDATION = 'bypassAchievementValidation';
export const RXFIELD_CHALLENGE_COINS_AMOUNT = 'pointAmount';
export const RXFIELD_CHALLENGE_BUDGET = 'budget';
export const RXFIELD_CHALLENGE_SPENDING = 'spending';
export const RXFIELD_CHALLENGE_ENABLE_BUDGET = 'enableBudget';
export const RXFIELD_SCANNED_CODE_CHALLENGE = 'challenge';
export const RXFIELD_SCANNED_CODE_CHALLENGE_CODE = 'challengeCode';
export const RXFIELD_SCANNED_CODE_USER = 'user';
export const RXFIELD_SCANNED_CODE_CHALLENGE_ID = 'challengeId';
export const RXFIELD_SCANNED_CODE_CHALLENGE_NAME = 'challengeName';
export const RXFIELD_SCANNED_CODE_CHALLENGE_LABEL = 'challengeLabel';
export const RXFIELD_SCANNED_CODE_USER_ID = 'userId';
export const RXFIELD_SCANNED_CODE_FULL_NAME = 'fullName';
export const RXFIELD_SCANNED_CODE_POINT = 'point';
export const RXFIELD_COMPLETED_CHALLENGE_CHALLENGE = 'challenge';
export const RXFIELD_COMPLETED_CHALLENGE_USER = 'user';
export const RXFIELD_COMPLETED_CHALLENGE_FULL_NAME = 'user.fullName';
export const RXFIELD_COMPLETED_CHALLENGE_CHALLENGE_ID = 'challenge.id';
export const RXFIELD_COMPLETED_CHALLENGE_CHALLENGE_NAME = 'challenge.title';
export const RXFIELD_COMPLETED_CHALLENGE_USER_ID = 'user.id';
export const RXFIELD_COMPLETED_CHALLENGE_REWARD_TYPE = 'completedRewardType';
export const RXFIELD_COMPLETED_CHALLENGE_POINT_AMOUNT = 'pointAmount';
export const RXFIELD_COMPLETED_CHALLENGE_VOUCHER_NAME = 'voucherRewards[0].voucherName';
export const RXFIELD_COMPLETED_CHALLENGE_SCANNED_CODES = 'scannedCodes';

export const RXSTATE_COMPLETED_CHALLENGES = 'completedChallenges';
export const RXSTATE_COMPLETED_CHALLENGE_PAGE = 'uiCompletedChallenge';

export const RXSTATE_GAMEQR_CATEGORIES = 'gameQRCategories';
export const RXSTATE_GAMEQR_CATEGORY_PAGE = 'uiGameQRCategory';
export const RXSTATE_CHALLENGES = 'challenges';
export const RXSTATE_CHALLENGE_PAGE = 'uiChallenge';
export const RXSTATE_SCANNED_CODES = 'scannedCodes';
export const RXSTATE_SCANNED_CODE_PAGE = 'uiScannedCode';

export const INITIAL_ORDER_BY_GAMEQR_CATEGORIES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_CHALLENGES = `validUntil ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_SCANNED_CODES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_COMPLETED_CHALLENGES = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_GAMEQR = 'MENUID_GAMEQR';
export const MENUID_GAMEQR_CATEGORY = 'MENUID_GAMEQR_CATEGORY';
export const MENUID_GAMEQR_CHALLENGE = 'MENUID_GAMEQR_CHALLENGE';
export const MENUID_GAMEQR_SCANNED_CODE = 'MENUID_GAMEQR_SCANNED_CODE';
export const MENUID_GAMEQR_COMPLETED_CHALLENGE = 'MENUID_GAMEQR_COMPLETED_CHALLENGE';

export const DROPDOWN_MENU_ABSOLUTE = 'Absolute';
export const DROPDOWN_MENU_TIME_BASED = 'TimeBased';

export const COMPLETED_REWARD_TYPE_POINT = 'Point';
export const COMPLETED_REWARD_TYPE_VOUCHER = 'Voucher';

export const SCAN_POLICIES = [
  { label: LocalizedString.challengePage.labelSequential, value: 'Sequential' },
  { label: LocalizedString.challengePage.labelRandom, value: 'Random' },
];

export const SCAN_LIMIT_MODES = [
  { label: LocalizedString.challengePage.labelAbsolute, value: DROPDOWN_MENU_ABSOLUTE },
  { label: LocalizedString.challengePage.labelTimeBased, value: DROPDOWN_MENU_TIME_BASED },
];

export const SCAN_LIMIT_TIMEZONES = [
  { label: LocalizedString.challengePage.labelWIB, value: '+0700' },
  { label: LocalizedString.challengePage.labelWITA, value: '+0800' },
  { label: LocalizedString.challengePage.labelWIT, value: '+0900' },
];

export const REWARD_TYPES = [
  { label: 'None', value: 'None' },
  { label: COMPLETED_REWARD_TYPE_POINT, value: COMPLETED_REWARD_TYPE_POINT },
  { label: COMPLETED_REWARD_TYPE_VOUCHER, value: COMPLETED_REWARD_TYPE_VOUCHER },
];
