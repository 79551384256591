import { INITIAL_ORDER_BY_ROOMS, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableRoom } from '../../../helper';
import {
  enablingDisablingRoom, setAlertErrorMessage, setRoomSelectedOrderBy, setRoomSelectedPageSize,
} from '../simple-action';
import downloadRoomsAsync from './downloadRoomsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingRoom(true));

    const { token } = getState().authentication;
    const { data } = getState().rooms;
    const { tappedId } = getState().uiRoom;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableRoom(tappedId, status, token);

    dispatch(setRoomSelectedPageSize(20));
    dispatch(setRoomSelectedOrderBy(INITIAL_ORDER_BY_ROOMS));
    dispatch(downloadRoomsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingRoom(false));
  }
};
