import { reset } from 'redux-form';
import { PAGE_MODE_TABLE, RXFORM_LOCATION } from '../../../constant';
import { addLocation, editLocation } from '../../../helper';
import {
  addingEditingLocation, clearLocations, setAlertErrorMessage, setFunctionalPageMode,
  setLocationSelectedCityId,
} from '../simple-action';
import downloadLocationsAsync from './downloadLocationsAsync';

export default (name, description, latitude, longitude, googleMapUrl, address,
  postalCode, phone, email) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingLocation(true));

    const { token } = getState().authentication;
    const {
      marker, polygon, selectedCityId, tappedId,
    } = getState().uiLocation;
    const { data } = getState().locations;

    const found = data[tappedId];
    const city = selectedCityId || found.city.id;
    const area = polygon.length > 0 ? polygon
      .map((x) => ({ latitude: x.lat, longitude: x.lng, order: x.order })) : found.area;
    const lat = Object.prototype.hasOwnProperty.call(marker, 'lat') ? marker.lat : latitude;
    const lng = Object.prototype.hasOwnProperty.call(marker, 'lng') ? marker.lng : latitude;

    if (tappedId) {
      await editLocation(tappedId, name, description, lat, lng, googleMapUrl, address,
        postalCode, phone, email, city, area, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addLocation(name, description, lat, lng, googleMapUrl, address, postalCode, phone,
        email, selectedCityId, area, token);
    }

    dispatch(reset(RXFORM_LOCATION));
    dispatch(clearLocations());
    dispatch(downloadLocationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setLocationSelectedCityId(''));
  } finally {
    dispatch(addingEditingLocation(false));
  }
};
