import {
  buildQueryParams, sendDeleteRequest, sendGetRequest, sendPostRequest,
  sendPutRequest, transformMediaData, transformQueryResult,
} from '../../helper';
import {
  REST_URL_NEWS, REST_URL_ADD_EDIT_NEWS, REST_URL_VIEW_DELETE_NEWS,
  REST_URL_SEND_NEWS_TO_ME, REST_URL_NEWS_CATEGORIES, REST_URL_ADD_EDIT_NEWS_CATEGORY,
  REST_URL_VIEW_DELETE_NEWS_CATEGORY,
} from './constant';

export * from '../../helper';

export const downloadNewsList = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_NEWS.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_NEWS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const transformData = transformMediaData(response.data);
  return transformQueryResult(response, transformData);
};

export const addNews = async (title, content, publishedDate, categoryId, visibilityStatus,
  media, sourceName, sourceUrl, shortDescription, isHeadline, tags, token) => {
  const body = {
    title,
    content,
    publishedDate,
    categoryId,
    visibilityStatus,
    media,
    sourceName,
    sourceUrl,
    shortDescription,
    isHeadline,
    tags,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_NEWS, body, token);
};

export const editNews = async (id, title, content, publishedDate, categoryId, visibilityStatus,
  media, sourceName, sourceUrl, shortDescription, isHeadline, tags, token) => {
  const body = {
    id,
    title,
    content,
    publishedDate,
    categoryId,
    visibilityStatus,
    media,
    sourceName,
    sourceUrl,
    shortDescription,
    isHeadline,
    tags,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_NEWS, body, token);
};

export const downloadNews = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_NEWS.replace(/\{id\}/, id), token);
  const transformData = transformMediaData(response);
  return transformData;
};

export const deleteNews = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_NEWS.replace(/\{id\}/, id), token);
};

export const sendNewsNotification = async (id, token) => {
  await sendPostRequest(REST_URL_SEND_NEWS_TO_ME.replace(/\{id\}/, id), null, token);
};

export const downloadNewsCategories = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = buildQueryParams(
    REST_URL_NEWS_CATEGORIES.replace(/\{filterString\}/, filterString),
    pageNumber, pageSize, orderBy, searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addNewsCategory = async (name, description, image, token) => {
  const body = { name, description, image };
  await sendPostRequest(REST_URL_ADD_EDIT_NEWS_CATEGORY, body, token);
};

export const editNewsCategory = async (id, name, description, image, token) => {
  const body = {
    id, name, description, image,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_NEWS_CATEGORY, body, token);
};

export const downloadNewsCategory = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_NEWS_CATEGORY.replace(/\{id\}/, id), token);
  return response;
};

export const deleteNewsCategory = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_NEWS_CATEGORY.replace(/\{id\}/, id), token);
};
