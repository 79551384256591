import { downloadingWorkingShifts, setWorkingShifts } from '../simple-action';
import { downloadWorkingShifts, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, workingShifts, uiWorkingShift } = getState();
  const { token } = authentication;
  const { meta } = workingShifts;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiWorkingShift;

  try {
    dispatch(downloadingWorkingShifts(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadWorkingShifts(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setWorkingShifts(list));
    }
  } finally {
    dispatch(downloadingWorkingShifts(false));
  }
};
