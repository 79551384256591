import {
  downloadingDeletingPayment,
  addPayment,
} from '../simple-action';
import { downloadPayment } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication, uiPayment } = getState();

  const { token } = authentication;
  const { tappedId } = uiPayment;

  try {
    dispatch(downloadingDeletingPayment(true));

    const payment = await downloadPayment(
      tappedId,
      token,
    );

    dispatch(addPayment(payment));
  } finally {
    dispatch(downloadingDeletingPayment(false));
  }
};
