import {
  reprocessingPointSubmission, setAlertErrorMessage, setPointSubmissionSelectedOrderBy,
  setPointSubmissionSelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_POINT_SUBMISSIONS } from '../../../constant';
import { reprocessPointSubmission } from '../../../helper';
import downloadPointSubmissionsAsync from './downloadPointSubmissionsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(reprocessingPointSubmission(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPointSubmission;

    await reprocessPointSubmission(tappedId, token);

    dispatch(setPointSubmissionSelectedPageSize(20));
    dispatch(setPointSubmissionSelectedOrderBy(INITIAL_ORDER_BY_POINT_SUBMISSIONS));
    dispatch(downloadPointSubmissionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(reprocessingPointSubmission(false));
  }
};
