import { downloadingPopUpBanners, setPopUpBanners } from '../simple-action';
import { downloadPopUpBanners, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, banners, uiPopUpBanner, uiFunctionalPage,
  } = getState();
  const { token } = authentication;
  const { meta } = banners;
  const { selectedPageSize, searchBarText, orderBy } = uiPopUpBanner;
  const { filterString } = uiFunctionalPage;

  try {
    dispatch(downloadingPopUpBanners(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadPopUpBanners(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );
    if (list) {
      dispatch(setPopUpBanners(list));
    }
  } finally {
    dispatch(downloadingPopUpBanners(false));
  }
};
