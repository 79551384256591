import { change, reset } from 'redux-form';
import { isEmpty } from 'lodash';
import {
  addingEditingVoucherTokenAssignment, setAlertErrorMessage, clearVoucherTokenAssignments,
  setVoucherTokenAssignmentSelectedUser, setFunctionalPageMode,
  setVoucherTokenAssignmentSelectedAssignmentMode, setVoucherTokenAssignmentSelectedBypassPayment,
  setVoucherTokenAssignmentSelectedBypassMaxPurchase,
  setVoucherTokenAssignmentSelectedBypassAchievement,
  setVoucherTokenAssignmentAssignmentValidUntil, setVoucherTokenAssignmentAssignmentValidFrom,
} from '../simple-action';
import { addVoucherTokenAssignment, editVoucherTokenAssignment, localDateToUtc } from '../../../helper';
import {
  RXFORM_VOUCHER_TOKEN_ASSIGNMENT, RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_USERS, PAGE_MODE_TABLE,
} from '../../../constant';
import downloadVoucherTokenAssignmentsAsync from './downloadVoucherTokenAssignmentsAsync';

const getValue = (selectedOption, found) => {
  if (isEmpty(selectedOption)) return found;
  return selectedOption.value;
};

export default (codeAssignmentMode, code, validFrom, validUntil,
  notes, notificationMessageTitle, notificationMessageBody,
  assignmentValidFrom, assignmentValidUntil,
  assignmentMethod) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingVoucherTokenAssignment(true));
    const { token } = getState().authentication;
    const {
      tappedId, selectedVoucher, selectedUser, selectedAssignmentMode,
      selectedBypassPayment, selectedBypassMaxPurchase, selectedBypassAchievement,
    } = getState().uiVoucherTokenAssignment;

    if (tappedId) {
      const { data } = getState().voucherTokenAssignments;
      const found = data[tappedId];

      const editAssignmentMode = selectedAssignmentMode || found.codeAssignmentMode;
      const editBypassPayment = getValue(selectedBypassPayment, found.bypassPayment);
      const editBypassMaxPurchase = getValue(selectedBypassMaxPurchase,
        found.bypassMaxPurchaseValidation);
      const editBypassAchievement = getValue(selectedBypassAchievement,
        found.bypassAchievementValidation);

      await editVoucherTokenAssignment(code,
        validFrom ? localDateToUtc(validFrom) : null,
        validUntil ? localDateToUtc(validUntil) : null,
        editAssignmentMode, editBypassPayment, editBypassMaxPurchase, editBypassAchievement,
        notes, notificationMessageTitle, notificationMessageBody,
        assignmentValidFrom ? localDateToUtc(assignmentValidFrom) : null,
        assignmentValidUntil ? localDateToUtc(assignmentValidUntil) : null,
        tappedId, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addVoucherTokenAssignment(selectedVoucher.value, code,
        validFrom ? localDateToUtc(validFrom) : null,
        validUntil ? localDateToUtc(validUntil) : null,
        codeAssignmentMode, selectedBypassPayment.value, selectedBypassMaxPurchase.value,
        selectedBypassAchievement.value,
        selectedUser?.value || null, notes, notificationMessageTitle, notificationMessageBody,
        assignmentMethod,
        assignmentValidFrom ? localDateToUtc(assignmentValidFrom) : null,
        assignmentValidUntil ? localDateToUtc(assignmentValidUntil) : null,
        token);
    }
    dispatch(reset(RXFORM_VOUCHER_TOKEN_ASSIGNMENT));
    dispatch(clearVoucherTokenAssignments());
    dispatch(downloadVoucherTokenAssignmentsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
      RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_USERS, ''));
    dispatch(setVoucherTokenAssignmentSelectedUser(''));
    dispatch(setVoucherTokenAssignmentSelectedAssignmentMode(''));
    dispatch(setVoucherTokenAssignmentSelectedBypassPayment(''));
    dispatch(setVoucherTokenAssignmentSelectedBypassAchievement(''));
    dispatch(setVoucherTokenAssignmentSelectedBypassMaxPurchase(''));
    dispatch(setVoucherTokenAssignmentAssignmentValidUntil(''));
    dispatch(setVoucherTokenAssignmentAssignmentValidFrom(''));
  } finally {
    dispatch(addingEditingVoucherTokenAssignment(false));
  }
};
