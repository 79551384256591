import {
  ORDER_SORT_ASCENDING, ORDER_SORT_DESCENDING,
} from '../../constant';

export * from '../../constant';

export const REST_BASE_URL_EVENT = '/event/api/v1/Event';
export const REST_URL_EVENT_OPERATOR_MAPPINGS = '/event/api/v1/EventOperator/Mapping?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|event.name|user.fullName';
export const REST_URL_VIEW_DELETE_EVENT_OPERATOR_MAPPING = '/event/api/v1/EventOperator/Mapping/{id}';
export const REST_URL_EVENT_OPERATOR_EVENTS = '/event/api/v1/EventOperator/Event?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|name';
export const REST_URL_VIEW_EVENT_OPERATOR_EVENT = '/event/api/v1/EventOperator/Event/{id}';
export const REST_URL_ADD_EDIT_EVENT_OPERATOR_MAPPING = '/event/api/v1/EventOperator/Mapping';
export const REST_URL_ENABLE_DISABLE_EVENT_OPERATOR_MAPPING = '/event/api/v1/EventOperator/Mapping/Status';
export const REST_URL_EVENT_OPERATOR_INVITATIONS = '/event/api/v1/EventOperator/EventInvitation?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|email|event.name|user.fullName';
export const REST_URL_VIEW_DELETE_EVENT_OPERATOR_INVITATION = '/event/api/v1/EventOperator/EventInvitation/{id}';
export const REST_URL_ADD_EVENT_OPERATOR_INVITATION = '/event/api/v1/EventOperator/EventInvitation';
export const REST_URL_RESEND_EVENT_OPERATOR_INVITATION = '/event/api/v1/EventOperator/ResendEventInvitation';
export const REST_URL_EVENT_OPERATOR_REGISTRATIONS = '/event/api/v1/EventOperator/EventRegistration?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|user.email|registrationCode';
export const REST_URL_EVENT_OPERATOR_REGISTRATION = '/event/api/v1/EventOperator/EventRegistration/{id}';
export const REST_URL_APPROVE_REJECT_EVENT_OPERATOR_REGISTRATION = '/event/api/v1/EventOperator/EventRegistrationApproval ';

export const ROUTE_NAME_EVENT_OPERATOR = '/event-operator';
export const ROUTE_NAME_EVENT_OPERATOR_MAPPING = '/event-operator-mapping';
export const ROUTE_NAME_EVENT_OPERATOR_EVENT = '/event-operator-event';
export const ROUTE_NAME_EVENT_OPERATOR_INVITATION = '/event-operator-invitation';
export const ROUTE_NAME_EVENT_OPERATOR_REGISTRATION = '/event-operator-registration';

export const RXFORM_EVENT_OPERATOR_MAPPING = 'eventOperatorMappingPage';
export const RXFORM_EVENT_OPERATOR_EVENT = 'eventOperatorEventPage';
export const RXFORM_EVENT_OPERATOR_INVITATION = 'eventOperatorInvitationPage';
export const RXFORM_EVENT_OPERATOR_REGISTRATION = 'eventOperatorRegistrationPage';

export const RXFIELD_EVENT_OPERATOR_MAPPING_USER = 'userId';
export const RXFIELD_EVENT_OPERATOR_MAPPING_USER_FULL_NAME = 'user.fullName';
export const RXFIELD_EVENT_OPERATOR_MAPPING_USER_ID = 'user.id';
export const RXFIELD_EVENT_OPERATOR_MAPPING_USER_EMAIL = 'user.email';
export const RXFIELD_EVENT_OPERATOR_MAPPING_EVENT = 'eventId';
export const RXFIELD_EVENT_OPERATOR_MAPPING_EVENT_ID = 'event.id';
export const RXFIELD_EVENT_OPERATOR_MAPPING_EVENT_NAME = 'event.name';
export const RXFIELD_EVENT_OPERATOR_MAPPING_VALID_UNTIL = 'validUntil';
export const RXFIELD_EVENT_OPERATOR_EVENT_CAN_PAY_USING_POINT = 'canPayUsingPoint';
export const RXFIELD_EVENT_OPERATOR_EVENT_CATEGORY = 'category';
export const RXFIELD_EVENT_OPERATOR_EVENT_CLOCK_INSIDE_AREA = 'clockInMustBeInsideArea';
export const RXFIELD_EVENT_OPERATOR_EVENT_DESCRIPTION = 'description';
export const RXFIELD_EVENT_OPERATOR_EVENT_EARLIEST_CLOCK = 'earliestClockInBeforeMinutes';
export const RXFIELD_EVENT_OPERATOR_EVENT_END_TIME = 'endTime';
export const RXFIELD_EVENT_OPERATOR_EVENT_EVENT_TYPE = 'eventType';
export const RXFIELD_EVENT_OPERATOR_EVENT_FEATURED = 'isFeatured';
export const RXFIELD_EVENT_OPERATOR_EVENT_FEATURED_ORDER = 'featuredOrder';
export const RXFIELD_EVENT_OPERATOR_EVENT_LOCATION = 'location';
export const RXFIELD_EVENT_OPERATOR_EVENT_MAX_CLOCK = 'maxClockInMinutes';
export const RXFIELD_EVENT_OPERATOR_EVENT_MEDIA = 'media';
export const RXFIELD_EVENT_OPERATOR_EVENT_NAME = 'name';
export const RXFIELD_EVENT_OPERATOR_EVENT_EVENT_NAME = 'event.name';
export const RXFIELD_EVENT_OPERATOR_EVENT_NEEDS_APPROVAL = 'needApproval';
export const RXFIELD_EVENT_OPERATOR_EVENT_NORMAL_PRICE = 'normalPrice';
export const RXFIELD_EVENT_OPERATOR_EVENT_PRICE = 'price';
export const RXFIELD_EVENT_OPERATOR_EVENT_QUOTA = 'quota';
export const RXFIELD_EVENT_OPERATOR_EVENT_SPEAKERS = 'speakers';
export const RXFIELD_EVENT_OPERATOR_EVENT_SURVEY_FORM_ID = 'surveyFormId';
export const RXFIELD_EVENT_OPERATOR_EVENT_REGISTRATION_REWARD_POINT = 'registrationRewardPoint';
export const RXFIELD_EVENT_OPERATOR_EVENT_REGISTRATION_REWARD_TYPE = 'registrationRewardType';
export const RXFIELD_EVENT_OPERATOR_EVENT_REGISTRATION_REWARD_VOUCHERS = 'registrationRewardVoucherIds';
export const RXFIELD_EVENT_OPERATOR_EVENT_ROOM_NAME = 'roomName';
export const RXFIELD_EVENT_OPERATOR_EVENT_SHORT_DESCRIPTION = 'shortDescription';
export const RXFIELD_EVENT_OPERATOR_EVENT_START_TIME = 'startTime';
export const RXFIELD_EVENT_OPERATOR_EVENT_VISIBILITY_STATUS = 'visibilityStatus';
export const RXFIELD_EVENT_OPERATOR_EVENT_WHO_CAN_REGISTER = 'whoCanRegister';
export const RXFIELD_EVENT_OPERATOR_EVENT_ENABLE_COMMENT = 'enableComment';
export const RXFIELD_EVENT_OPERATOR_EVENT_COMMENT_ACCESS = 'commentAccess';
export const RXFIELD_EVENT_OPERATOR_EVENT_DISCUSSION_ID = 'discussionId';
export const RXFIELD_EVENT_OPERATOR_EVENT_CLOCK_IN_QR_CODE = 'clockInQrCode';
export const RXFIELD_EVENT_OPERATOR_EVENT_ADDITTIONAL_ITEMS = 'additionalItems';
export const RXFIELD_EVENT_OPERATOR_EVENT_BANNER = 'banner';
export const RXFIELD_EVENT_OPERATOR_INVITATION_EVENT = 'event';
export const RXFIELD_EVENT_OPERATOR_INVITATION_RECIPIENTS = 'recipients';
export const RXFIELD_EVENT_OPERATOR_INVITATION_EMAIL = 'email';
export const RXFIELD_EVENT_OPERATOR_INVITATION_END_TIME = 'endTime';
export const RXFIELD_EVENT_OPERATOR_INVITATION_FULL_NAME = 'fullName';
export const RXFIELD_EVENT_OPERATOR_INVITATION_EVENT_ID = 'eventId';
export const RXFIELD_EVENT_OPERATOR_INVITATION_INVITATION_STATUS = 'invitationStatus';
export const RXFIELD_EVENT_OPERATOR_INVITATION_LOCATION = 'location';
export const RXFIELD_EVENT_OPERATOR_INVITATION_NAME = 'name';
export const RXFIELD_EVENT_OPERATOR_INVITATION_PHONE = 'phone';
export const RXFIELD_EVENT_OPERATOR_INVITATION_REGISTERED_ON = 'registeredOn';
export const RXFIELD_EVENT_OPERATOR_INVITATION_RESENT_COUNT = 'resentCount';
export const RXFIELD_EVENT_OPERATOR_INVITATION_SHORT_DESCRIPTION = 'shortDescription';
export const RXFIELD_EVENT_OPERATOR_INVITATION_START_TIME = 'startTime';
export const RXFIELD_EVENT_OPERATOR_INVITATION_USER_ID = 'userId';
export const RXFIELD_EVENT_OPERATOR_INVITATION_TIME_ZONE = 'timeZone';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_REGISTRATION_CODE = 'registrationCode';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_REGISTRATION_STATUS = 'registrationStatus';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_REGISTRATION_STATUS_NOTES = 'registrationStatusNotes';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_APPROVED_ON = 'approvedRejectedOn';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_REJECTED_ON = 'approvedRejectedOn';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_APPROVED_BY = 'approvedRejectedBy';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_REJECTED_BY = 'approvedRejectedBy';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_CLOCK_IN_TIME = 'clockInTime';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_CLOCK_IN_DRIFT = 'clockInDriftTimeMinutes';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_CLOCK_IN_LATITUDE = 'clockInLatitude';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_CLOCK_IN_LONGITUDE = 'clockInLongitude';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_CLOCK_IN_LOCATION_STATUS = 'clockInLocationStatus';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_USER_ID = 'user.id';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_USER_FULLNAME = 'user.fullName';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_USER_EMAIL = 'user.email';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_USER_PHONE = 'user.phoneNumber';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_EVENT_ID = 'event.id';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_EVENT_NAME = 'event.name';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_EVENT_SHORT_DESCRIPTION = 'event.shortDescription';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_EVENT_START_TIME = 'eventStartTime';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_EVENT_END_TIME = 'eventEndTime';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_EVENT_TIMEZONE = 'eventTimeZone';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_ID = 'payment.id';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_PRICE = 'price';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_DATE = 'payment.createdDate';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_TAX = 'tax';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_TRANSACTION_ID = 'payment.transactionId';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_SERVICE_FEE = 'serviceFee';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_CODE = 'payment.paymentCode';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_DISCOUNT = 'discount';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_CHANNEL = 'payment.paymentChannel';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_DISCOUNT_NOTES = 'discountNotes';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_STATUS = 'payment.paymentStatus';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_POINT_DISCOUNT = 'pointDiscount';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_STATUS_NOTES = 'payment.paymentStatusNotes';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_POINT_DISCOUNT_ID = 'pointDiscountId';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_LAST_CHANGED_STATUS = 'payment.lastChangedStatus';
export const RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_TOTAL = 'total';

export const RXSTATE_EVENT_OPERATOR_MAPPINGS = 'eventOperatorMappings';
export const RXSTATE_EVENT_OPERATOR_MAPPING_PAGE = 'uiEventOperatorMapping';
export const RXSTATE_EVENT_OPERATOR_EVENTS = 'eventOperatorEvents';
export const RXSTATE_EVENT_OPERATOR_EVENT_PAGE = 'uiEventOperatorEvent';
export const RXSTATE_EVENT_OPERATOR_INVITATIONS = 'eventOperatorInvitations';
export const RXSTATE_EVENT_OPERATOR_INVITATION_PAGE = 'uiEventOperatorInvitation';
export const RXSTATE_EVENT_OPERATOR_REGISTRATIONS = 'eventOperatorRegistrations';
export const RXSTATE_EVENT_OPERATOR_REGISTRATION_PAGE = 'uiEventOperatorRegistration';

export const INITIAL_ORDER_BY_EVENT_OPERATOR_MAPPINGS = `validUntil ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_EVENT_OPERATOR_EVENTS = `startTime ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_EVENT_OPERATOR_INVITATIONS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_EVENT_OPERATOR_REGISTRATIONS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_EVENT_OPERATOR = 'MENUID_EVENT_OPERATOR';
export const MENUID_EVENT_OPERATOR_MAPPING = 'MENUID_EVENT_OPERATOR_MAPPING';
export const MENUID_EVENT_OPERATOR_EVENT = 'MENUID_EVENT_OPERATOR_EVENT';
export const MENUID_EVENT_OPERATOR_INVITATION = 'MENUID_EVENT_OPERATOR_INVITATION';
export const MENUID_EVENT_OPERATOR_REGISTRATION = 'MENUID_EVENT_OPERATOR_REGISTRATION';

export const EVENT_TYPE_SIMPLE = 'Simple';
export const EVENT_TYPE_REGULAR = 'Regular';

export const EVENT_OPERATOR_EVENT_REWARD_TYPE_VOUCHER = 'Voucher';
export const EVENT_OPERATOR_EVENT_REWARD_TYPE_POINT = 'Point';
export const EVENT_OPERATOR_EVENT_REWARD_TYPE_BOTH = 'Both';
export const EVENT_OPERATOR_EVENT_REWARD_TYPE_NONE = 'None';

export const EVENT_OPERATOR_INVITATION_STATUS = [
  { label: 'Registered', value: 'Registered' },
  { label: 'Pending', value: 'Pending' },
];

export const EVENT_OPERATOR_REGISTRATION_STATUS = [
  { label: 'Approved', value: 'Approved' },
  { label: 'Rejected', value: 'Rejected' },
  { label: 'WaitingApproval', value: 'WaitingApproval' },
];
