import {
  ADDING_EDITING_DISCUSSION, DOWNLOADING_DELETING_DISCUSSION, DOWNLOADING_DISCUSSIONS,
  SET_DISCUSSION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, SET_DISCUSSION_SEARCH_TEXT,
  SET_DISCUSSION_SELECTED_ALLOW_DIRECT_COMMENT, SET_DISCUSSION_SELECTED_ALLOW_PUBLIC_READ,
  SET_DISCUSSION_SELECTED_DISABLE_COMMENT, SET_DISCUSSION_SELECTED_ORDER_BY,
  SET_DISCUSSION_SELECTED_PAGE_SIZE, SET_DISCUSSION_TAPPED_ID,
} from '../action';
import { INITIAL_ORDER_BY_DISCUSSIONS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_DISCUSSIONS,
  filterString: '',
  selectedAllowDirectComment: '',
  selectedAllowPublicRead: '',
  selectedDisableComment: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADDING_EDITING_DISCUSSION:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_DISCUSSION:
      return { ...state, downloadingDeleting: action.status };
    case DOWNLOADING_DISCUSSIONS:
      return { ...state, downloading: action.status };
    case SET_DISCUSSION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_DISCUSSION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_DISCUSSION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_DISCUSSION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_DISCUSSION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_DISCUSSION_SELECTED_ALLOW_DIRECT_COMMENT:
      return { ...state, selectedAllowDirectComment: action.option };
    case SET_DISCUSSION_SELECTED_ALLOW_PUBLIC_READ:
      return { ...state, selectedAllowPublicRead: action.option };
    case SET_DISCUSSION_SELECTED_DISABLE_COMMENT:
      return { ...state, selectedDisableComment: action.option };
    default: return state;
  }
};
