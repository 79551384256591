import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  PICKER_MODE_DATE_TIME,
  RXFIELD_SESSION_CATEGORY_DESCRIPTION, RXFIELD_SESSION_CATEGORY_NAME,
  RXFORM_SESSION_CATEGORY, RXSTATE_SESSION_CATEGORIES,
  RXSTATE_SESSION_CATEGORY_PAGE, status,
  STATUS_DISABLED, STATUS_ENABLED,
} from '../../constant';
import { FormInitialValueShape } from '../../type';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { FunctionalPage } from '../../component';
import { rxformValidateSessionCategory } from '../../validation';
import { toMoment } from '../../helper';

const useStyles = makeStyles(() => ({
  dialogHeader: {
    marginBottom: 20,
  },
}));

const renderDialogContent = (addingEditing, pageMode) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_SESSION_CATEGORY_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.sessionCategoryPage.placeholderName}
          label={LocalizedString.sessionCategoryPage.labelName}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_SESSION_CATEGORY_DESCRIPTION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.sessionCategoryPage.placeholderDescription}
          label={LocalizedString.sessionCategoryPage.labelDescription}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          multiline
          required
        />
      </Grid>
    </Grid>
  </Grid>
);

const SessionCategoryPage = ({
  initialValues,
  addingEditing, downloading, enablingDisabling,
  handleSubmit, onAppear, onApplyAdvancedFilterPressed,
  onCancelPressed,
  onChangePage, onChangePageSize,
  onConfirmContextMenuPressed, onCreatePressed,
  onEditPressed, onContextMenuPressed,
  onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed,
  onSubmitPressed, onViewPressed,
  sessionCategories, currentCategoryStatus, pageMode, tappedId,
  onDeletePressed, onSavePressed,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_SESSION_CATEGORIES}
      uiPage={RXSTATE_SESSION_CATEGORY_PAGE}
      moreMenus={[
        {
          caption: GlobalLocalizedString.common.buttonCaptionEnable,
          disabled: currentCategoryStatus === STATUS_ENABLED || enablingDisabling,
          onPress: () => onContextMenuPressed(tappedId,
            GlobalLocalizedString.common.msgEnableConfirmation),
        },
        {
          caption: GlobalLocalizedString.common.buttonCaptionDisable,
          disabled: currentCategoryStatus === STATUS_DISABLED || enablingDisabling,
          onPress: () => onContextMenuPressed(tappedId,
            GlobalLocalizedString.common.msgDisableConfirmation),
        },
      ]}
      tableColumns={[
        {
          title: GlobalLocalizedString.common.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.sessionCategoryPage.labelName, field: 'name', sorting: !downloading },
        { title: LocalizedString.sessionCategoryPage.labelDescription, field: 'description', sorting: !downloading },
        {
          title: GlobalLocalizedString.common.placeholderCreatedDate,
          field: 'createdDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
      ]}
      filterColumns={[
        {
          title: LocalizedString.eventRegistrationPage.labelCreatedDate,
          field: 'createdDate',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.sessionCategoryPage.labelStatus, field: 'status', type: FILTER_TYPE_DROPDOWN, data: status, useDropdownValue: true,
        },
      ]}
      handleSubmit={handleSubmit}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmContextMenuPressed}
      onCreatePressed={onCreatePressed}
      onEditPressed={(id) => onEditPressed(id, sessionCategories)}
      onRefresh={onRefresh}
      onSavePressed={onSavePressed}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onSubmitPressed={onSubmitPressed}
      onViewPressed={(id) => onViewPressed(id, sessionCategories)}
      createNewButtonCaption={
        LocalizedString.sessionCategoryPage.buttonCaptionCreateNewSessionCategory
      }
      deleteButtonCaption={LocalizedString.sessionCategoryPage.buttonCaptionDeleteSessionCategory}
      editButtonCaption={LocalizedString.sessionCategoryPage.buttonCaptionEditSessinCategory}
      title={LocalizedString.sessionCategoryPage.title}
      addDialogHeaderContainerStyle={classes.dialogHeader}
      onDeletePressed={onDeletePressed}
      useFullWidth
      createPermissionName="EVENT_CREATE_SESSION_CATEGORY"
      deletePermissionName="EVENT_DELETE_SESSION_CATEGORY"
      editPermissionName="EVENT_EDIT_SESSION_CATEGORY"
    >
      {renderDialogContent(addingEditing, pageMode, initialValues)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_SESSION_CATEGORY,
  validate: rxformValidateSessionCategory,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SessionCategoryPage);

SessionCategoryPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  sessionCategories: PropTypes.objectOf(PropTypes.object).isRequired,
  currentCategoryStatus: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
};
