import { addSessionRegistration, rejectingSessionRegistration } from '../simple-action';
import { approveRejectSessionRegistration, downloadSessionRegistration } from '../../../helper';
import { REGISTRATION_STATUS_REJECTED } from '../../../constant';

export default (notes) => async (dispatch, getState) => {
  try {
    dispatch(rejectingSessionRegistration(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSessionRegistration;

    await approveRejectSessionRegistration(tappedId, REGISTRATION_STATUS_REJECTED, notes, token);
    const result = await downloadSessionRegistration(tappedId, token);

    dispatch(addSessionRegistration(result));
  } finally {
    dispatch(rejectingSessionRegistration(false));
  }
};
