import { addMyProfile, downloadingMyProfile } from '../simple-action';
import { downloadMyProfile, downloadMyRole } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingMyProfile(true));

    const { token } = getState().authentication;

    const result = await downloadMyProfile(token);
    const resultRole = await downloadMyRole(token);

    dispatch(addMyProfile({ ...result, roles: resultRole }));
  } finally {
    dispatch(downloadingMyProfile(false));
  }
};
