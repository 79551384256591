import {
  REST_URL_DAILY_CHECKINS,
  REST_URL_VIEW_DAILY_CHECKIN,
  REST_URL_REGISTRATION_REFERRALS,
  REST_URL_VIEW_REGISTRATION_REFERRAL,
  REST_URL_SAVE_FILE,
} from './constant';
import {
  buildQueryParams, sendGetRequest, transformQueryResult, sendGetFileRequest,
} from '../../helper';

export * from '../../helper';

export const downloadDailyCheckins = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_DAILY_CHECKINS.concat(filterString),
      pageNumber, pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_DAILY_CHECKINS, pageNumber, pageSize,
      orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadDailyCheckin = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DAILY_CHECKIN.replace(/\{id\}/, id), token);
  return response;
};

export const downloadRegistrationReferrals = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_REGISTRATION_REFERRALS.concat(filterString),
      pageNumber, pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_REGISTRATION_REFERRALS, pageNumber, pageSize,
      orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadRegistrationReferral = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_REGISTRATION_REFERRAL.replace(/\{id\}/, id), token);
  return response;
};

export const saveRegistrationReferrals = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const downloadUrl = REST_URL_REGISTRATION_REFERRALS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};
