import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    paymentMenu: {
      title: 'Payment',
    },
    paymentPage: {
      title: 'Payment',

      labelNo: 'No.',
      labelTransactionId: 'Transaction ID',
      labelTotal: 'Total',
      labelPaymentChannel: 'Payment Channel',
      labelPaymentStatus: 'Payment Status',
      labelPaymentStatusNotes: 'Payment Status Notes',
      labelPaymentCode: 'Payment Code',
      labelPaymentRawRequest: 'Payment Raw Request',
      labelPaymentRawResponse: 'Payment Raw Response',
      labelPaymentRawCallback: 'Payment Raw Callback',
      labelValidUntil: 'Valid Until',
      labelLastChangedStatus: 'Last Changed Status',
      labelLastStatusCheck: 'Last Status Check',
      labelExternalTransactionId: 'External Transaction ID',
      labelUrl: 'URL',
      labelProvider: 'Provider',
      labelCreatedDate: 'Created Date',

      buttonCaptionRepublish: 'Republish',

      msgConfirmationRepublish: 'Do you want to republish this payment to queue?',
    },
  },
  id: {
    paymentMenu: {
      title: 'Pembayaran',
    },
    paymentPage: {
      title: 'Pembayaran',

      labelNo: 'No.',
      labelTransactionId: 'ID Transaksi',
      labelTotal: 'Total',
      labelPaymentChannel: 'Kanal Pembayaran',
      labelPaymentStatus: 'Status Pembayaran',
      labelPaymentStatusNotes: 'Catatan Status Pembayaran',
      labelPaymentCode: 'Kode Pembayaran',
      labelPaymentRawRequest: 'Permohonan Mentah Pembayaran',
      labelPaymentRawResponse: 'Tanggapan Mentah Pembayaran',
      labelPaymentRawCallback: 'Balikan Mentah Pembayaran',
      labelValidUntil: 'Berlaku Sampai',
      labelLastChangedStatus: 'Status Perubahan Terakhir',
      labelLastStatusCheck: 'Cek Status Terakhir',
      labelExternalTransactionId: 'ID Transaksi Eksternal',
      labelUrl: 'URL',
      labelProvider: 'Penyedia',
      labelCreatedDate: 'Tanggal Dibuat',

      buttonCaptionRepublish: 'Terbit Ulang',

      msgConfirmationRepublish: 'Apakah Anda ingin menerbitkan ulang pembayaran ini ke antrian?',
    },
  },
});

export default LocalizedString;
