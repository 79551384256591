import { connect } from 'react-redux';
import {
  approval, DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_SESSION_OPERATOR_SESSIONS,
  MENUID_SESSION_OPERATOR_SESSION, PAGE_MODE_TABLE, REST_BASE_URL, REST_URL_IMAGE_STORAGE,
  SESSION_QR_CODE_PATH,
} from '../../constant';
import {
  isAdditionalItemYoutubeUrlType, sortAsc, toMoment, transformInitialValues,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  clearSessionOperatorSessions, copyingSessionOperatorSessionQrCode, setActiveSideMenuItem,
  setAlertErrorMessage, setSessionOperatorSessionAdvancedFilterDialogSelectedFilterString,
  setSessionOperatorSessionSearchText, setSessionOperatorSessionSelectedOrderBy,
  setSessionOperatorSessionSelectedPageSize, setSessionOperatorSessionTappedId,
  downloadSessionOperatorSessionsAsync, downloadSessionOperatorSessionAsync,
} from '../../redux/action';
import SessionOperatorSessionPage from './session-operator-session.presentation';

const transformVoucherDropdownData = (data) => Object.values(data).map((item) => ({
  label: item.voucher.voucherName,
  value: item.voucher.id,
}
));

const transformSpeakers = (data) => (data.length > 0
  ? data.map((x) => (x.speaker.id
    ? { label: x.speaker.name, value: x.speaker.id, order: x.speaker.order } : x))
    .sort((a, b) => sortAsc(a.order, b.order))
  : []);

const getDiscusId = (state) => {
  const { sessionOperatorSessions, uiSessionOperatorSession, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiSessionOperatorSession;
  const { pageMode } = uiFunctionalPage;

  return pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? sessionOperatorSessions.data[tappedId].discussionId : '';
};

const getInitialValues = (state) => {
  const { sessionOperatorSessions, uiSessionOperatorSession, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiSessionOperatorSession;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? sessionOperatorSessions.data[tappedId] : {};
  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      enableComment: found.enableComment ? approval[0].label : approval[1],
      registrationRewardVouchers: found?.registrationRewardVouchers
        ? transformVoucherDropdownData(found?.registrationRewardVouchers) : [],
      speakers: transformSpeakers(found.speakers),
      clockInMustBeInsideArea: found?.clockInMustBeInsideArea
        ? { label: GlobalLocalizedString.common.labelYes, value: true }
        : { label: GlobalLocalizedString.common.labelNo, value: false },
      isFeatured: found?.isFeatured
        ? { label: GlobalLocalizedString.common.labelYes, value: true }
        : { label: GlobalLocalizedString.common.labelNo, value: false },
      needApproval: found?.needApproval
        ? { label: GlobalLocalizedString.common.labelYes, value: true }
        : { label: GlobalLocalizedString.common.labelNo, value: false },
      canPayUsingPoint: found?.canPayUsingPoint
        ? { label: GlobalLocalizedString.common.labelYes, value: true }
        : { label: GlobalLocalizedString.common.labelNo, value: false },
      startTime: found?.startTime ? toMoment(found?.startTime, found.room.location?.city?.province.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
      endTime: found?.endTime ? toMoment(found?.endTime, found.room.location?.city?.province.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
      additionalItems: found.additionalItems ? found.additionalItems.map((x) => ({
        ...x,
        url: isAdditionalItemYoutubeUrlType(x) ? x.path : undefined,
      })) : [],
      clockInQrCode: found.randomId ? `${SESSION_QR_CODE_PATH}${found.randomId}` : null,
      banner: found.banner ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.banner}` : null,
    }) : {};
  return result;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  copying: state.uiSessionOperatorSession.copying,
  downloading: state.uiSessionOperatorSession.downloading,
  downloadingDeleting: state.uiSessionOperatorSession.downloadingDeleting,
  pageMode: state.uiFunctionalPage.pageMode,
  discusId: getDiscusId(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setSessionOperatorSessionAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_SESSION_OPERATOR_SESSION));
    dispatch(setSessionOperatorSessionSearchText(''));
    dispatch(clearSessionOperatorSessions());
    dispatch(setSessionOperatorSessionSelectedPageSize(20));
    dispatch(setSessionOperatorSessionSelectedOrderBy(INITIAL_ORDER_BY_SESSION_OPERATOR_SESSIONS));
    dispatch(downloadSessionOperatorSessionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadSessionOperatorSessionsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setSessionOperatorSessionSelectedPageSize(pageSize));
    dispatch(downloadSessionOperatorSessionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCopyPressed: (status) => {
    dispatch(copyingSessionOperatorSessionQrCode(!status));
  },
  onRefresh: (pageSize) => {
    dispatch(setSessionOperatorSessionSelectedPageSize(pageSize));
    dispatch(clearSessionOperatorSessions());
    dispatch(downloadSessionOperatorSessionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setSessionOperatorSessionSearchText(text));
      dispatch(clearSessionOperatorSessions());
      await dispatch(downloadSessionOperatorSessionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setSessionOperatorSessionSelectedOrderBy(orderBy));
    dispatch(clearSessionOperatorSessions());
    dispatch(downloadSessionOperatorSessionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setSessionOperatorSessionTappedId(id));
    dispatch(downloadSessionOperatorSessionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionOperatorSessionPage);
