import { DOWNLOADING_MY_CONFIG_ITEMS } from '../../module/configuration/redux/action';
import {
  LOGGING_IN, LOGGING_IN_WITH_GOOGLE, LOGGING_IN_WITH_KEY_CLOAK, LOGGING_IN_WITH_MICROSOFT,
  LOGGING_OUT,
} from '../action';

const initialState = {
  downloadingMyConfigItems: false,
  loggingIn: false,
  loggingInWithGoogle: false,
  loggingInWithKeyCloak: false,
  loggingInWithMicrosoft: false,
  loggingOut: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGGING_IN:
      return { ...state, loggingIn: action.status };
    case LOGGING_OUT:
      return { ...state, loggingOut: action.status };
    case DOWNLOADING_MY_CONFIG_ITEMS:
      return { ...state, downloadingMyConfigItems: action.status };
    case LOGGING_IN_WITH_GOOGLE:
      return { ...state, loggingInWithGoogle: action.status };
    case LOGGING_IN_WITH_MICROSOFT:
      return { ...state, loggingInWithMicrosoft: action.status };
    case LOGGING_IN_WITH_KEY_CLOAK:
      return { ...state, loggingInWithKeyCloak: action.status };
    default: return state;
  }
};
