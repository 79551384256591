import { downloadingRevokers, setRevokers } from '../simple-action';
import { downloadProfiles, transformSearchText } from '../../../helper';
import { INITIAL_ORDER_BY_USERS } from '../../../constant';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, revokers, uiVoucherToken } = getState();
  const { token } = authentication;
  const { meta } = revokers;
  const { revokerSearchBarText } = uiVoucherToken;

  try {
    dispatch(downloadingRevokers(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadProfiles(
      pageToBeDownloaded,
      20,
      INITIAL_ORDER_BY_USERS,
      transformSearchText(revokerSearchBarText),
      '',
      token,
    );

    if (list) {
      dispatch(setRevokers(list));
    }
  } finally {
    dispatch(downloadingRevokers(false));
  }
};
