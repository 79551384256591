import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    captchaMenu: {
      title: 'Captcha',
    },
    captchaRequestScreen: {
      title: 'Captcha Request',

      labelNo: 'No',
      labelRequestTag: 'Request Tag',
      labelCaptchaText: 'Captcha Text',
      labelValidUntil: 'Valid Until',
      labelVerifiedOn: 'Verified On',
      labelDeviceId: 'Device ID',
      labelCreatedDate: 'Created Date',
      labelImage: 'Image',
      labelReferenceId: 'Reference ID',
      labelTag: 'Tag',
      labelFCMToken: 'FCM Token',
      labelLanguage: 'Language',
      labelUserAgent: 'User Agent',
      labelManufacturer: 'Manufacturer',
      labelModel: 'Model',
      labelVerified: 'Verified',
      labelOSName: 'OS Name',
      labelOSVersion: 'OS Version',
      labelVerifiedForUserId: 'Verified For (User ID)',
      labelAppVersion: 'App Version',
      labelVerifiedForFullName: 'Verified For (Full Name)',
    },
  },
  id: {
    captchaMenu: {
      title: 'Captcha',
    },
    captchaRequestScreen: {
      title: 'Permintaan Captcha',

      labelNo: 'No',
      labelRequestTag: 'Tag Permintaan',
      labelCaptchaText: 'Teks Captcha',
      labelValidUntil: 'Berlaku Sampai',
      labelVerifiedOn: 'Diverifikasi Pada',
      labelDeviceId: 'ID Perangkat',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelImage: 'Gambar',
      labelReferenceId: 'ID Referensi',
      labelTag: 'Tag',
      labelFCMToken: 'Token FCM',
      labelLanguage: 'Bahasa',
      labelUserAgent: 'Agen Pengguna',
      labelManufacturer: 'Manufaktur',
      labelModel: 'Model',
      labelVerified: 'Terverifikasi',
      labelOSName: 'Nama OS',
      labelOSVersion: 'Versi OS',
      labelVerifiedForUserId: 'Terverifikasi Untuk (ID Pengguna)',
      labelAppVersion: 'Versi Aplikasi',
      labelVerifiedForFullName: 'Terverifikasi Untuk (Nama Lengkap)',
    },
  },
});

export default LocalizedString;
