import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_TEXT, PAGE_MODE_VIEW,
  RXFORM_RESPONSE,
  RXSTATE_DFORM_RESPONSE, RXSTATE_DFORM_RESPONSE_PAGE,
  RXFIELD_DFORM_RESPONSE_FORM_ID,
  RXFIELD_DFORM_RESPONSE_FORM_NAME,
  RXFIELD_DFORM_RESPONSE_USER_ID,
  RXFIELD_DFORM_RESPONSE_USER_NAME,
  RXFIELD_DFORM_RESPONSE_REFERENCE_ID,
  RXFIELD_DFORM_RESPONSE_REFERENCE_LABEL,
  RXFIELD_DFORM_RESPONSE_CREATED_DATE,
  PICKER_MODE_DATE_TIME,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  FILTER_TYPE_DROPDOWN,
  ENUM_FIELD_PHOTO,
} from '../../constant';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import {
  renderReduxFormImageInputField,
  renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { rxformValidateForm } from '../../validation';
import { FunctionalPage, SectionTitle } from '../../component';
import { toMoment } from '../../helper';

const useStyles = makeStyles(() => ({
  dialogHeader: {
    marginBottom: 20,
  },
}));

const renderFields = (splitArr, classes, isImageMoreThanOne) => splitArr.map((x) => (
  <Grid item key={x.field.name} className={classes.gridWrapper}>
    {x.field.fieldType === ENUM_FIELD_PHOTO ? (
      <Field
        name={x.field.label}
        component={renderReduxFormImageInputField}
        label={x.field.label}
        defaultValue={x.value}
        disabled
        useFullWidthImage
        fullImageWidth={isImageMoreThanOne ? '100%' : '48%'}
      />
    ) : (
      <Field
        name={x.field.label}
        component={renderReduxFormOutlinedTextField}
        placeholder={x.field.label}
        label={x.field.label}
        disabled
        defaultValue={x.value}
      />
    )}
  </Grid>
));

const renderAdditionalFields = (defaultAdditionalFieldsValue, classes) => {
  const hasAdditionalFields = defaultAdditionalFieldsValue.length > 0;
  const additionalOtherFields = hasAdditionalFields
    ? defaultAdditionalFieldsValue.filter((x) => x.field.fieldType !== ENUM_FIELD_PHOTO) : [];
  const additionalOtherFieldsLength = additionalOtherFields.length;
  const additionalPhotoFields = hasAdditionalFields
    ? defaultAdditionalFieldsValue.filter((x) => x.field.fieldType === ENUM_FIELD_PHOTO) : [];
  const additionalPhotoFieldsLength = additionalPhotoFields.length;

  return hasAdditionalFields && (
    <Grid item sm={12}>
      {additionalOtherFieldsLength > 0 && (
      <Grid container spacing={1}>
        <Grid item sm md>
          {renderFields(additionalOtherFields
            .slice(0, Math.round(additionalOtherFieldsLength / 2)),
          classes)}
        </Grid>

        {additionalOtherFieldsLength > 1 && (
        <Grid item sm md>
          {renderFields(
            additionalOtherFields.slice(Math.round(additionalOtherFieldsLength / 2)),
            classes,
          )}
        </Grid>
        )}
      </Grid>
      )}

      {additionalPhotoFieldsLength > 0 && (
      <Grid container spacing={1}>
        <Grid item sm md>
          {renderFields(additionalPhotoFields
            .slice(0, Math.round(additionalPhotoFieldsLength / 2)),
          classes,
          additionalPhotoFieldsLength > 1)}
        </Grid>

        {additionalPhotoFieldsLength > 1 && (
        <Grid item sm md>
          {renderFields(
            additionalPhotoFields.slice(Math.round(additionalPhotoFieldsLength / 2)),
            classes,
          )}
        </Grid>
        )}
      </Grid>
      )}
    </Grid>
  );
};

const renderDialogContent = (
  classes, pageMode, defaultAdditionalFieldsValue,
) => (
  <div>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_DFORM_RESPONSE_FORM_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.responsePage.placeholderFormID}
            label={LocalizedString.responsePage.placeholderFormID}
            disabled={pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DFORM_RESPONSE_FORM_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.responsePage.placeholderFormName}
            label={LocalizedString.responsePage.placeholderFormName}
            disabled={pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DFORM_RESPONSE_USER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.responsePage.placeholderUserID}
            label={LocalizedString.responsePage.placeholderUserID}
            disabled={pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DFORM_RESPONSE_USER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.responsePage.placeholderUserName}
            label={LocalizedString.responsePage.placeholderUserName}
            disabled={pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_DFORM_RESPONSE_REFERENCE_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.responsePage.placeholderReferenceID}
            label={LocalizedString.responsePage.placeholderReferenceID}
            disabled={pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DFORM_RESPONSE_REFERENCE_LABEL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.responsePage.placeholderReferenceLabel}
            label={LocalizedString.responsePage.placeholderReferenceLabel}
            disabled={pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>
    </Grid>
    <SectionTitle title={LocalizedString.responsePage.labelResponse} />

    {renderAdditionalFields(defaultAdditionalFieldsValue, classes)}
  </div>
);
const ResponsePage = ({
  initialValues, downloading,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onCancelPressed,
  onChangePage, onChangePageSize,
  onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed,
  onViewPressed, pageMode,
  forms, loadingForm, onChangeFormText,
  users, loadingUser, onChangeUserText,
}) => {
  const classes = useStyles();
  return (
    <FunctionalPage
      data={RXSTATE_DFORM_RESPONSE}
      uiPage={RXSTATE_DFORM_RESPONSE_PAGE}
      tableColumns={[
        {
          title: GlobalLocalizedString.common.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.responsePage.labelForm, field: 'form.name', sorting: !downloading },
        { title: LocalizedString.responsePage.labelUser, field: 'user.fullName', sorting: !downloading },
        { title: LocalizedString.responsePage.labelReferenceLabel, field: 'referenceLabel', sorting: !downloading },
        {
          title: GlobalLocalizedString.common.placeholderCreatedDate,
          field: 'createdDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
      ]}
      filterColumns={[
        {
          title: LocalizedString.responsePage.labelCreatedDate,
          field: RXFIELD_DFORM_RESPONSE_CREATED_DATE,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.responsePage.labelForm,
          field: 'form',
          type: FILTER_TYPE_DROPDOWN,
          data: forms,
          loading: loadingForm,
          onChangeFilterText: onChangeFormText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.responsePage.labelUser,
          field: 'user',
          type: FILTER_TYPE_DROPDOWN,
          data: users,
          loading: loadingUser,
          onChangeFilterText: onChangeUserText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.responsePage.placeholderReferenceID,
          field: RXFIELD_DFORM_RESPONSE_REFERENCE_ID,
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.responsePage.placeholderReferenceLabel,
          field: RXFIELD_DFORM_RESPONSE_REFERENCE_LABEL,
          type: FILTER_TYPE_TEXT,
        },
      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={(id) => onViewPressed(id)}
      title={LocalizedString.responsePage.title}
      disableCreate
      disableDelete
      disableEdit
      useFullWidth
      useFullWidthDialog
    >
      {renderDialogContent(
        classes, pageMode, initialValues.responses,
      )}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_RESPONSE,
  validate: rxformValidateForm,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ResponsePage);

ResponsePage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  forms: PropTypes.arrayOf(SimpleDataShape).isRequired,

  downloading: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  loadingForm: PropTypes.bool.isRequired,

  pageMode: PropTypes.string.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onChangeFormText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
