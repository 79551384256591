import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage, MapDrawer, SectionTitle } from '../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  DRAW_MODE_MARKER, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  PICKER_MODE_DATE_TIME,
  RXFIELD_COMPANY_ADDRESS, RXFIELD_COMPANY_APP_STORE_URL, RXFIELD_COMPANY_BANNER,
  RXFIELD_COMPANY_CITY, RXFIELD_COMPANY_DESCRIPTION, RXFIELD_COMPANY_EMAIL,
  RXFIELD_COMPANY_GOOGLE_MAP_URL, RXFIELD_COMPANY_LATITUDE, RXFIELD_COMPANY_LOGO,
  RXFIELD_COMPANY_LONGITUDE, RXFIELD_COMPANY_NAME, RXFIELD_COMPANY_PHONE,
  RXFIELD_COMPANY_PLAY_STORE_URL, RXFIELD_COMPANY_POSTAL_CODE, RXFIELD_COMPANY_TAG,
  RXFIELD_COMPANY_WEBSITE, RXFIELD_CREATED_DATE, RXFORM_COMPANY, RXSTATE_COMPANIES_MDATA,
  RXSTATE_COMPANY_PAGE, status,
  STATUS_DISABLED, STATUS_ENABLED, timezoneOffset, ADVANCED_FILTER_MODE,
  IMAGE_FILE_EXTENSIONS,
} from '../../constant';
import { FormInitialValueShape, PointShape, SimpleDataShape } from '../../type';
import {
  renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField,
  renderReduxFormDropzonePicker,
} from '../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateCompanyMdata } from '../../validation';
import { toMoment } from '../../helper';

const useStyles = makeStyles(() => ({
  dialogHeader: {
    marginBottom: 20,
  },
  heading: {
    margin: 0,
  },
}));

const renderDialogContent = (
  currentFormValues, tappedData, center, marker, defaultAreaValue,
  defaultCityValue, cities, addingEditing, downloadingDeleting,
  loadingCity, onChangeCityText, onChangeLatitudeText,
  onChangeLongitudeText, onLogoImageSelected, onBannerImageSelected,
  onCityOptionSelected, onDrawingCompleted, pageMode,
  initialLogo, initialBanner,
) => (
  <div>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_COMPANY_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.companyScreen.placeholderCompanyName}
            label={LocalizedString.companyScreen.placeholderCompanyName}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_COMPANY_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.companyScreen.placeholderDescription}
            label={LocalizedString.companyScreen.placeholderDescription}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_COMPANY_TAG}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.companyScreen.placeholderTag}
            label={LocalizedString.companyScreen.placeholderTag}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_COMPANY_EMAIL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.companyScreen.placeholderEmail}
            label={LocalizedString.companyScreen.placeholderEmail}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_COMPANY_PHONE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.companyScreen.placeholderPhone}
            label={LocalizedString.companyScreen.placeholderPhone}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_COMPANY_APP_STORE_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.companyScreen.placeholderAppStoreUrl}
            label={LocalizedString.companyScreen.placeholderAppStoreUrl}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_COMPANY_PLAY_STORE_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.companyScreen.placeholderPlayStoreUrl}
            label={LocalizedString.companyScreen.placeholderPlayStoreUrl}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            multiline
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_COMPANY_ADDRESS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.companyScreen.placeholderAddress}
            label={LocalizedString.companyScreen.placeholderAddress}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            multiline
          />
        </Grid>
        <Grid item container>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_COMPANY_CITY}
                component={renderReduxFormOutlinedDropdownTextField}
                placeholder={LocalizedString.companyScreen.placeholderCityName}
                label={LocalizedString.companyScreen.placeholderCityName}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                data={cities}
                value={defaultCityValue}
                loading={loadingCity}
                onChangeText={onChangeCityText}
                onOptionSelected={onCityOptionSelected}
                required
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_COMPANY_POSTAL_CODE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.companyScreen.placeholderPostalCode}
                label={LocalizedString.companyScreen.placeholderPostalCode}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                type="number"
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_COMPANY_WEBSITE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.companyScreen.placeholderWebsite}
                label={LocalizedString.companyScreen.placeholderWebsite}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_COMPANY_LOGO}
                component={renderReduxFormDropzonePicker}
                label={LocalizedString.companyScreen.placeholderLogo}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                loading={addingEditing}
                onImageSelected={onLogoImageSelected}
                onBlur={(e) => e.preventDefault()}
                defaultValue={initialLogo}
                imageFileExtension={IMAGE_FILE_EXTENSIONS}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_COMPANY_BANNER}
                component={renderReduxFormDropzonePicker}
                label={LocalizedString.companyScreen.placeholderBanner}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                loading={addingEditing}
                onImageSelected={onBannerImageSelected}
                onBlur={(e) => e.preventDefault()}
                defaultValue={initialBanner}
                imageFileExtension={IMAGE_FILE_EXTENSIONS}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    <SectionTitle title={LocalizedString.companyScreen.labelGoogleMap} />

    <Grid container spacing={3}>
      <Grid item sm md>
        <Field
          name={RXFIELD_COMPANY_GOOGLE_MAP_URL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.companyScreen.placeholderGoogleMapUrl}
          label={LocalizedString.companyScreen.placeholderGoogleMapUrl}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item sm md>
        <Field
          name={RXFIELD_COMPANY_LATITUDE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.companyScreen.placeholderLatitude}
          label={LocalizedString.companyScreen.placeholderLatitude}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          type="number"
          onChangeText={(e) => onChangeLatitudeText(e, currentFormValues.longitude)}
        />
      </Grid>
      <Grid item sm md>
        <Field
          name={RXFIELD_COMPANY_LONGITUDE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.companyScreen.placeholderLongitude}
          label={LocalizedString.companyScreen.placeholderLongitude}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          onChangeText={(e) => onChangeLongitudeText(e, currentFormValues.latitude)}
          type="number"
        />
      </Grid>
    </Grid>

    <MapDrawer
      drawingMode={DRAW_MODE_MARKER}
      center={center}
      marker={marker}
      onDrawingCompleted={(data, drawingMode) => onDrawingCompleted(data, drawingMode,
        tappedData)}
      loading={downloadingDeleting}
      disabled={pageMode === PAGE_MODE_VIEW}
    />
  </div>
);

const CompanyPage = ({
  currentFormValues, tappedData, center, marker, initialValues, cities, provinces,
  addingEditing, downloadingDeleting, downloading, enablingDisabling, loadingCity, loadingProvince,
  isResyncAllowed, resyncing,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed, onCancelPressed,
  onChangeCityText, onChangeLatitudeText, onChangeLongitudeText, onChangePage, onChangePageSize,
  onChangeProvinceText, onCityOptionSelected, onConfirmContextMenuPressed, onContextMenuPressed,
  onCreatePressed, onDownloadPressed, onDrawingCompleted, onEditPressed, onLogoImageSelected,
  onRefresh, onResetAdvancedFilterPressed, onSavePressed, onSearchBarTextChanged, onSortPressed,
  onSubmitPressed, onViewPressed, onBannerImageSelected, companiesMdata, currentCompanyStatus,
  pageMode, tappedId,
}) => {
  const classes = useStyles();
  return (
    <FunctionalPage
      data={RXSTATE_COMPANIES_MDATA}
      uiPage={RXSTATE_COMPANY_PAGE}
      moreMenus={[
        {
          caption: LocalizedString.common.buttonCaptionEnable,
          disabled: currentCompanyStatus === STATUS_ENABLED || enablingDisabling,
          onPress: () => onContextMenuPressed(tappedId,
            LocalizedString.common.msgEnableConfirmation),
        },
        {
          caption: LocalizedString.common.buttonCaptionDisable,
          disabled: currentCompanyStatus === STATUS_DISABLED || enablingDisabling,
          onPress: () => onContextMenuPressed(tappedId,
            LocalizedString.common.msgDisableConfirmation),
        },
        {
          caption: LocalizedString.common.buttonCaptionResync,
          disabled: resyncing || !isResyncAllowed,
          onPress: () => onContextMenuPressed(tappedId,
            LocalizedString.common.msgResyncConfirmation),
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.companyScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.companyScreen.labelName, field: 'name', sorting: !downloading },
        { title: LocalizedString.companyScreen.labelCity, field: 'city.name', sorting: !downloading },
        { title: LocalizedString.companyScreen.labelAddress, field: 'address', sorting: !downloading },
        {
          title: LocalizedString.companyScreen.labelCreatedDate,
          field: 'createdDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        {
          title: LocalizedString.companyScreen.labelLastModifiedDate,
          field: 'lastModifiedDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        { title: LocalizedString.companyScreen.labelStatus, field: 'status', sorting: !downloading },
      ]}
      filterColumns={[
        {
          title: LocalizedString.companyScreen.labelTimeZone, field: 'timeZone', type: FILTER_TYPE_DROPDOWN, data: timezoneOffset,
        },
        {
          title: LocalizedString.companyScreen.labelCity,
          field: 'city',
          type: FILTER_TYPE_DROPDOWN,
          data: cities,
          loading: loadingCity,
          onChangeFilterText: (text) => onChangeCityText(text, ADVANCED_FILTER_MODE),
          useDropdownValue: true,
        },
        {
          title: LocalizedString.companyScreen.labelProvince,
          field: 'province',
          type: FILTER_TYPE_DROPDOWN,
          data: provinces,
          loading: loadingProvince,
          onChangeFilterText: onChangeProvinceText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.companyScreen.labelCreatedDate,
          field: RXFIELD_CREATED_DATE,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.companyScreen.labelStatus, field: 'status', type: FILTER_TYPE_DROPDOWN, data: status, useDropdownValue: true,
        },
      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmContextMenuPressed}
      onCreatePressed={onCreatePressed}
      onDownloadPressed={onDownloadPressed}
      onEditPressed={(id) => onEditPressed(id, companiesMdata)}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSavePressed={onSavePressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onSubmitPressed={onSubmitPressed}
      onViewPressed={(id) => onViewPressed(id, companiesMdata)}
      createNewButtonCaption={LocalizedString.companyScreen.buttonCaptionCreateNewCompany}
      editButtonCaption={LocalizedString.companyScreen.buttonCaptionEditCompany}
      title={LocalizedString.companyScreen.title}
      addDialogHeaderContainerStyle={classes.dialogHeader}
      useFullWidth
      disableDelete
      createPermissionName="MDATA_CREATE_COMPANY"
      editPermissionName="MDATA_EDIT_COMPANY"
      savePermissionName="MDATA_GET_COMPANY"
    >
      {renderDialogContent(currentFormValues, tappedData, center, marker, initialValues.area,
        initialValues.city.label, cities, addingEditing, downloadingDeleting, loadingCity,
        onChangeCityText, onChangeLatitudeText, onChangeLongitudeText, onLogoImageSelected,
        onBannerImageSelected, onCityOptionSelected, onDrawingCompleted,
        pageMode, initialValues.logo, initialValues.banner)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_COMPANY,
  validate: rxformValidateCompanyMdata,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CompanyPage);

CompanyPage.propTypes = {
  currentFormValues: FormInitialValueShape.isRequired,
  tappedData: FormInitialValueShape,
  center: PointShape,
  marker: PointShape,
  initialValues: FormInitialValueShape.isRequired,
  cities: PropTypes.arrayOf(SimpleDataShape).isRequired,
  provinces: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  loadingCity: PropTypes.bool.isRequired,
  loadingProvince: PropTypes.bool.isRequired,
  isResyncAllowed: PropTypes.bool.isRequired,
  resyncing: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeCityText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeLatitudeText: PropTypes.func.isRequired,
  onChangeLongitudeText: PropTypes.func.isRequired,
  onChangeProvinceText: PropTypes.func.isRequired,
  onCityOptionSelected: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onDrawingCompleted: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onLogoImageSelected: PropTypes.func.isRequired,
  onBannerImageSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  companiesMdata: PropTypes.objectOf(PropTypes.object).isRequired,
  currentCompanyStatus: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
};

CompanyPage.defaultProps = {
  tappedData: null,
  center: null,
  marker: null,
};
