import { connect } from 'react-redux';
import { change, reset, formValueSelector } from 'redux-form';
import {
  clearChallenges, clearGameQRCategories, setActiveSideMenuItem,
  setAlertConfirmationMessage,
  setAlertErrorMessage, setChallengeSearchText, setChallengeSelectedPageSize,
  setChallengeSelectedOrderBy, setChallengeTappedId, setChallengeSelectedAddMediaMenu,
  setChallengeSelectedCategory, setChallengeSelectedScanPolicy,
  setChallengeSelectedVisibilityStatus, setGameQRCategorySearchText,
  addEditChallengeAsync, downloadChallengesAsync, downloadDeleteChallengeAsync,
  downloadGameQRCategoriesAsync, setChallengeSelectedIsPromoted, setChallengeScanLimitMode,
  setChallengeScanLimitTimezone, copyingChallengeDeepLink,
  setChallengeAdvancedFilterDialogSelectedFilterString,
  setChallengeSelectedCompleteReward,
  setChallengeSelectedVoucher,
  setChallengeSelectedBypassMaxPurchase,
  setChallengeSelectedBypassAchievement,
  setChallengeSelectedEnableBudget,
  setGameQRCategoryAdvancedFilterDialogSelectedFilterString,
} from '../../redux/action';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_CHALLENGES, MENUID_GAMEQR_CHALLENGE,
  PAGE_MODE_TABLE, RXFIELD_CHALLENGE_CODES, RXFIELD_CHALLENGE_CONTENT, RXFIELD_CHALLENGE_MEDIA,
  RXFIELD_CHALLENGE_SCAN_POLICY, RXFIELD_CHALLENGE_VISIBILITY_STATUS, RXFORM_CHALLENGE,
  RXFIELD_CHALLENGE_IS_PROMOTED, approval, RXFIELD_CHALLENGE_SCAN_LIMIT_MODE,
  RXFIELD_CHALLENGE_SCAN_LIMIT_TIMEZONE, RXFIELD_CHALLENGE_COMPLETE_REWARD_TYPE,
  RXFIELD_CHALLENGE_VOUCHER,
  RXFIELD_CHALLENGE_BYPASS_MAX_PURCHASE,
  RXFIELD_CHALLENGE_BYPASS_ACHIEVEMENT_VALIDATION,
  RXFIELD_CHALLENGE_COINS_AMOUNT,
  RXFIELD_CHALLENGE_ENABLE_BUDGET, COMPLETED_REWARD_TYPE_POINT, ADVANCED_FILTER_MODE,
} from '../../constant';
import {
  getTimeSymbol, localDateToUtc, sortAsc, transformDropdownData, transformInitialValues, toMoment,
} from '../../helper';
import LocalizedString from '../../../../localization';
import ChallengePage from './challenge.presentation';
import {
  downloadVouchersAsync,
  setVoucherAdvancedFilterDialogSelectedFilterString,
  setVoucherSearchText, clearVouchers,
} from '../../../voucher';

const getInitialValues = (state) => {
  const { challenges, uiChallenge, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiChallenge;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? challenges.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      category: found.category ? { label: found.category.name, value: found.category.id } : { label: '', value: '' },
      validFrom: found.validFrom
        ? toMoment(found.validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
      validUntil: found.validUntil
        ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
      codes: found.codes.length > 0 ? found.codes.sort((a, b) => sortAsc(a.order, b.order))
        : [],
      isPromoted: found.isPromoted ? approval[0] : approval[1],
      order: found.order || 0,
      scanLimitMode: found.scanLimitMode || '',
      scanLimitAbsoluteValue: found.scanLimitAbsoluteValue || 0,
      scanLimitPerDay: found.scanLimitPerDay || 0,
      scanLimitPerHour: found.scanLimitPerHour || 0,
      scanLimitPerMonth: found.scanLimitPerMonth || 0,
      scanLimitPerWeek: found.scanLimitPerWeek || 0,
      scanLimitTimeZone: found.scanLimitTimeZone ? { label: getTimeSymbol(found.scanLimitTimeZone), value: found.scanLimitTimeZone } : { label: '', value: '' },
      vouchers: found.vouchers.length > 0
        ? { label: found.vouchers[0].voucherName, value: found.vouchers[0].voucherId }
        : { label: '', value: '' },
      completeRewardType: found.completedRewardType || '',
      enableBudget: found.enableBudget ? approval[0] : approval[1],
      budget: found.budget || 0,
    }) : {
      title: '',
      content: '',
      validFrom: null,
      validUntil: null,
      category: { label: '', value: '' },
      visibilityStatus: '',
      scanPolicy: '',
      reactionId: '',
      tags: '',
      isPromoted: { label: '', value: '' },
      order: 0,
      scanLimitMode: '',
      scanLimitTimeZone: { label: '', value: '' },
      enableBudget: { label: '', value: '' },
      budget: 0,
    };
  return initVal;
};

const selector = formValueSelector(RXFORM_CHALLENGE);

const mapStateToProps = (state) => ({
  categories: transformDropdownData(state.gameQRCategories.data),
  addingEditing: state.uiChallenge.addingEditing,
  downloading: state.uiChallenge.downloading,
  downloadingDeleting: state.uiChallenge.downloadingDeleting,
  loadingCategory: state.uiGameQRCategory.downloading,
  pageMode: state.uiFunctionalPage.pageMode,
  selectedAddMediaMenu: state.uiChallenge.selectedAddMediaMenu,
  selectedScanLimitMode: state.uiChallenge.selectedScanLimitMode,
  initialValues: getInitialValues(state),
  scanLimitMode: selector(state, RXFIELD_CHALLENGE_SCAN_LIMIT_MODE)?.value
  || selector(state, RXFIELD_CHALLENGE_SCAN_LIMIT_MODE),
  isPromoted: !!selector(state, RXFIELD_CHALLENGE_IS_PROMOTED)?.value || false,
  copying: state.uiChallenge.copying,
  vouchers: Object.values(state.vouchers.data)
    .map((item) => ({ label: item.title, value: item.id })),
  formSelectedRewardType: selector(state, RXFIELD_CHALLENGE_COMPLETE_REWARD_TYPE),
  loadingVouchers: state.uiVoucher.downloading,
  isEnableBudget: state.uiChallenge.selectedEnableBudget?.value || false,
});

const mapDispatchToProps = (dispatch) => ({
  onAddCodePressed: (data) => {
    dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_CODES, data));
  },
  onAddMediaPressed: (data) => {
    dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_MEDIA, data));
  },
  onAddMediaMenuSelected: (menu) => {
    dispatch(setChallengeSelectedAddMediaMenu(menu));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setChallengeSelectedCategory(''));
    dispatch(clearGameQRCategories());
    dispatch(downloadGameQRCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setChallengeAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_GAMEQR_CHALLENGE));
    dispatch(setChallengeSearchText(''));
    dispatch(setChallengeScanLimitMode(''));
    dispatch(clearChallenges());
    dispatch(setChallengeSelectedPageSize(20));
    dispatch(setChallengeSelectedOrderBy(INITIAL_ORDER_BY_CHALLENGES));
    dispatch(downloadChallengesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/category/, 'category.id');
    dispatch(setChallengeAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearChallenges());
    dispatch(downloadChallengesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(setChallengeSelectedCategory(''));
    dispatch(setChallengeSelectedScanPolicy(''));
    dispatch(setChallengeSelectedVisibilityStatus(''));
    dispatch(setChallengeSelectedVisibilityStatus(''));
    dispatch(setChallengeScanLimitTimezone(''));
    dispatch(setChallengeSelectedVoucher(null));
    dispatch(setChallengeSelectedCompleteReward(null));
    dispatch(setChallengeSelectedBypassMaxPurchase(null));
    dispatch(setChallengeSelectedBypassAchievement(null));
    dispatch(setChallengeSelectedEnableBudget(null));
    dispatch(reset(RXFORM_CHALLENGE));
  },
  onCategoryOptionSelected: (option) => {
    if (option) {
      dispatch(setChallengeSelectedCategory(option.value));
    } else {
      dispatch(setChallengeSelectedCategory(''));
    }
  },
  onChangeCategoryText: async (text, mode) => {
    try {
      dispatch(setGameQRCategoryAdvancedFilterDialogSelectedFilterString(mode === ADVANCED_FILTER_MODE ? '' : 'status=enabled'));
      dispatch(setGameQRCategorySearchText(text));
      dispatch(clearGameQRCategories());
      await dispatch(downloadGameQRCategoriesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeContentText: (text) => {
    dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_CONTENT, text));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadChallengesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setChallengeSelectedPageSize(pageSize));
    dispatch(downloadChallengesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteChallengeAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCopyPressed: (status) => {
    dispatch(copyingChallengeDeepLink(!status));
  },
  onCreatePressed: () => {
    dispatch(setChallengeTappedId(''));
    dispatch(setGameQRCategoryAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setChallengeSelectedScanPolicy(''));
    dispatch(setChallengeSelectedVisibilityStatus(''));
    dispatch(setGameQRCategorySearchText(''));
    dispatch(setChallengeScanLimitMode(''));
    dispatch(clearGameQRCategories());
    dispatch(downloadGameQRCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setChallengeSelectedVoucher(null));
    dispatch(setChallengeSelectedCompleteReward(null));
    dispatch(setChallengeSelectedBypassMaxPurchase({
      label: approval[1].label,
      value: approval[1].value,
    }));
    dispatch(setChallengeSelectedBypassAchievement({
      label: approval[1].label,
      value: approval[1].value,
    }));
    dispatch(setChallengeSelectedEnableBudget(null));

    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeleteCodePressed: (data) => {
    dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_CODES, data));
  },
  onDeleteMediaPressed: (data) => {
    dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_MEDIA, data));
  },
  onDeletePressed: (id) => {
    dispatch(setChallengeTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setGameQRCategorySearchText(''));
    dispatch(setChallengeScanLimitMode(''));
    dispatch(clearGameQRCategories());
    dispatch(downloadGameQRCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setChallengeTappedId(id));
    dispatch(downloadDeleteChallengeAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onEnableBudgetSelected: (option) => {
    if (option) {
      dispatch(setChallengeSelectedEnableBudget(option));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_ENABLE_BUDGET, option));
    } else {
      dispatch(setChallengeSelectedEnableBudget(''));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_ENABLE_BUDGET, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setChallengeSelectedPageSize(pageSize));
    dispatch(clearChallenges());
    dispatch(downloadChallengesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setChallengeAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearChallenges());
    dispatch(setChallengeSelectedOrderBy(INITIAL_ORDER_BY_CHALLENGES));
    dispatch(downloadChallengesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    title, content, validFrom, validUntil, tags, media, codes, order,
    scanLimitMode, scanLimitAbsoluteValue, scanLimitPerHour, scanLimitPerDay,
    scanLimitPerWeek, scanLimitPerMonth,
    bypassMaxPurchaseValidation,
    bypassAchievementValidation,
    pointAmount,
    budget,
  }) => {
    try {
      await dispatch(addEditChallengeAsync(title, content, localDateToUtc(validFrom),
        localDateToUtc(validUntil), tags, media, codes, order,
        scanLimitMode, scanLimitAbsoluteValue, scanLimitPerHour, scanLimitPerDay,
        scanLimitPerWeek, scanLimitPerMonth,
        bypassMaxPurchaseValidation,
        bypassAchievementValidation,
        pointAmount,
        budget));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onScanPolicyOptionSelected: (option) => {
    if (option) {
      dispatch(setChallengeSelectedScanPolicy(option.value));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_SCAN_POLICY, option));
    } else {
      dispatch(setChallengeSelectedScanPolicy(''));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_SCAN_POLICY, ''));
    }
  },
  onScanLimitModeOptionSelected: (option) => {
    if (option) {
      dispatch(setChallengeScanLimitMode(option.value));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_SCAN_LIMIT_MODE, option));
    } else {
      dispatch(setChallengeScanLimitMode(''));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_SCAN_LIMIT_MODE, ''));
    }
  },
  onScanLimitTimezoneOptionSelected: (option) => {
    if (option) {
      dispatch(setChallengeScanLimitTimezone(option.value));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_SCAN_LIMIT_TIMEZONE, option));
    } else {
      dispatch(setChallengeScanLimitTimezone(''));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_SCAN_LIMIT_TIMEZONE, ''));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setChallengeSearchText(text));
      dispatch(clearChallenges());
      await dispatch(downloadChallengesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setChallengeSelectedOrderBy(orderBy));
    dispatch(clearChallenges());
    dispatch(downloadChallengesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    title, content, validFrom, validUntil, tags, media, codes, order,
    scanLimitMode, scanLimitAbsoluteValue, scanLimitPerHour, scanLimitPerDay,
    scanLimitPerWeek, scanLimitPerMonth,
    bypassMaxPurchaseValidation,
    bypassAchievementValidation,
    pointAmount,
    budget,
  }) => {
    await dispatch(addEditChallengeAsync(title, content, localDateToUtc(validFrom),
      localDateToUtc(validUntil), tags, media, codes, order,
      scanLimitMode, scanLimitAbsoluteValue, scanLimitPerHour, scanLimitPerDay,
      scanLimitPerWeek, scanLimitPerMonth,
      bypassMaxPurchaseValidation,
      bypassAchievementValidation,
      pointAmount,
      budget));
  },
  onViewPressed: (id) => {
    dispatch(setChallengeTappedId(id));
    dispatch(setChallengeScanLimitMode(''));
    dispatch(downloadDeleteChallengeAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onVisibilityStatusOptionSelected: (option) => {
    if (option) {
      dispatch(setChallengeSelectedVisibilityStatus(option));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_VISIBILITY_STATUS, option));
    } else {
      dispatch(setChallengeSelectedVisibilityStatus(''));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_VISIBILITY_STATUS, ''));
    }
  },
  onIsPromotedSelected: (option) => {
    if (option) {
      dispatch(setChallengeSelectedIsPromoted(option));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_IS_PROMOTED, option));
    } else {
      dispatch(setChallengeSelectedIsPromoted(''));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_IS_PROMOTED, ''));
    }
  },
  onCompleteRewardOptionSelected: async (option) => {
    if (option) {
      const rewardTypeCoin = option.value === COMPLETED_REWARD_TYPE_POINT;
      const rewardTypeNone = option.value === 'None';
      dispatch(setChallengeSelectedCompleteReward(option));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_COMPLETE_REWARD_TYPE, option));
      if (rewardTypeCoin || rewardTypeNone) {
        dispatch(setChallengeSelectedVoucher(null));
        dispatch(setChallengeSelectedBypassMaxPurchase(null));
        dispatch(setChallengeSelectedBypassAchievement(null));
        dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_VOUCHER, null));
        dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_BYPASS_MAX_PURCHASE, null));
        dispatch(change(
          RXFORM_CHALLENGE, RXFIELD_CHALLENGE_BYPASS_ACHIEVEMENT_VALIDATION, null,
        ));

        if (rewardTypeNone) {
          dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_COINS_AMOUNT, '0'));
        }
      } else {
        try {
          dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
          dispatch(setVoucherSearchText(''));
          dispatch(clearVouchers());
          await dispatch(downloadVouchersAsync(1));
        } catch (error) {
          dispatch(setAlertErrorMessage(error));
        }

        dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_COINS_AMOUNT, '0'));
      }
    } else {
      dispatch(setChallengeSelectedCompleteReward(''));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_COMPLETE_REWARD_TYPE, ''));
    }
  },
  onVoucherOptionSelected: (option) => {
    if (option) {
      dispatch(setChallengeSelectedVoucher(option));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_VOUCHER, option));
    } else {
      dispatch(setChallengeSelectedVoucher(null));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_VOUCHER, ''));
    }
  },
  onChangeVoucherText: async (text) => {
    try {
      dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setVoucherSearchText(text));
      dispatch(clearVouchers());
      await dispatch(downloadVouchersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onBypassMaxPurchaseOptionSelected: (option) => {
    if (option) {
      dispatch(setChallengeSelectedBypassMaxPurchase(option));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_BYPASS_MAX_PURCHASE, option));
    } else {
      dispatch(setChallengeSelectedBypassMaxPurchase(''));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_BYPASS_MAX_PURCHASE, ''));
    }
  },
  onBypassAchievementValidationOptionSelected: (option) => {
    if (option) {
      dispatch(setChallengeSelectedBypassAchievement(option));
      dispatch(change(
        RXFORM_CHALLENGE, RXFIELD_CHALLENGE_BYPASS_ACHIEVEMENT_VALIDATION, option,
      ));
    } else {
      dispatch(setChallengeSelectedBypassAchievement(''));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_BYPASS_ACHIEVEMENT_VALIDATION, ''));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChallengePage);
