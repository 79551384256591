import { CLEAR_ATTENDANCE_SUMMARY_TOTAL_USERS, SET_ATTENDANCE_SUMMARY_TOTAL_USERS } from '../../module/attendance/redux/action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_ATTENDANCE_SUMMARY_TOTAL_USERS: {
      return action.data;
    }
    case CLEAR_ATTENDANCE_SUMMARY_TOTAL_USERS:
      return state;
    default:
      return state;
  }
};
