import {
  enablingDisablingWorkingShift, setAlertErrorMessage,
  setWorkingShiftSelectedOrderBy, setWorkingShiftSelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_WORKING_SHIFTS, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableWorkingShift } from '../../../helper';
import downloadWorkingShiftsAsync from './downloadWorkingShiftsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingWorkingShift(true));

    const { token } = getState().authentication;
    const { data } = getState().workingShifts;
    const { tappedId } = getState().uiWorkingShift;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableWorkingShift(tappedId, status, token);

    dispatch(setWorkingShiftSelectedPageSize(20));
    dispatch(setWorkingShiftSelectedOrderBy(INITIAL_ORDER_BY_WORKING_SHIFTS));
    dispatch(downloadWorkingShiftsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingWorkingShift(false));
  }
};
