import { addSessionOperatorRegistration, approvingSessionOperatorRegistration } from '../simple-action';
import { approveRejectSessionOperatorRegistration, downloadSessionOperatorRegistration } from '../../../helper';
import { REGISTRATION_STATUS_APPROVED } from '../../../constant';

export default (notes) => async (dispatch, getState) => {
  try {
    dispatch(approvingSessionOperatorRegistration(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSessionOperatorRegistration;

    await approveRejectSessionOperatorRegistration(tappedId, REGISTRATION_STATUS_APPROVED, notes,
      token);
    const result = await downloadSessionOperatorRegistration(tappedId, token);

    dispatch(addSessionOperatorRegistration(result));
  } finally {
    dispatch(approvingSessionOperatorRegistration(false));
  }
};
