import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  setAlertErrorMessage, setActiveSideMenuItem,
  setResponseAdvancedFilterDialogSelectedFilterString, setResponseSearchText,
  clearResponses, setResponseSelectedPageSize, setResponseSelectedOrderBy,
  downloadResponsesAsync, downloadResponseAsync,
  setResponseTappedId,
  setFormAdvancedFilterDialogSelectedFilterString,
  setFormSearchText,
  clearForms,
  downloadFormsAsync,
  setProfileAdvancedFilterDialogSelectedFilterString,
  setProfileSearchText,
  clearProfiles,
} from '../../redux/action';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, ENUM_FIELD_PHOTO, INITIAL_ORDER_BY_RESPONSES,
  MENUID_DFORM_RESPONSE, PAGE_MODE_TABLE, REGEX_UTC_DATE_FORMAT, REGEX_UTC_DATE_TIME_FORMAT,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE, RXFORM_RESPONSE, SIMPLE_DATE_FORMAT,
} from '../../constant';
import {
  transformInitialValues, toMoment, sortAsc, debounceSearch,
  transformUserDropdownData, transformDropdownData,
} from '../../helper';
import ResponsePage from './response.presentation';
import { downloadProfilesAsync } from '../../../forum/redux/action';

const getInitialValues = (state) => {
  const { responses, uiResponse, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiResponse;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? responses.data[tappedId] : {};

  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      {
        responses: found.responses && found.responses.length > 0
          ? [
            ...found.responses.filter((x) => x.field.fieldType !== ENUM_FIELD_PHOTO)
              .map((x) => {
                if (x.value.match(REGEX_UTC_DATE_TIME_FORMAT)) {
                  return { ...x, value: toMoment(x.value).format(DATE_TIME_FORMAT_WITHOUT_PIPE) };
                }
                if (x.value.match(REGEX_UTC_DATE_FORMAT)) {
                  return { ...x, value: toMoment(x.value).format(SIMPLE_DATE_FORMAT) };
                }
                return x;
              }).sort((a, b) => sortAsc(a.field.label, b.field.label)),
            ...found.responses.filter((x) => x.field.fieldType === ENUM_FIELD_PHOTO)
              .map((x) => ({ ...x, value: `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${x.value}` }))
              .sort((a, b) => sortAsc(a.field.label, b.field.label)),
          ] : [],
      })
    : {
      responses: [],
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.profiles.data),
  forms: transformDropdownData(state.forms.data),

  downloading: state.uiResponse.downloading,
  loadingUser: state.uiProfile.downloading,
  loadingForm: state.uiForm.downloading,

  pageMode: state.uiFunctionalPage.pageMode,
});

const searchProfileDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchFormDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearForms());
    dispatch(downloadFormsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setFormAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setFormSearchText(''));
    dispatch(clearForms());
    dispatch(downloadFormsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setResponseAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_DFORM_RESPONSE));
    dispatch(setResponseSearchText(''));
    dispatch(clearResponses());
    dispatch(setResponseSelectedPageSize(20));
    dispatch(setResponseSelectedOrderBy(INITIAL_ORDER_BY_RESPONSES));
    dispatch(downloadResponsesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace(/form/, 'form.id')
      .replace(/user/, 'user.id');
    dispatch(setResponseAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearResponses());
    dispatch(downloadResponsesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_RESPONSE));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadResponsesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setResponseSelectedPageSize(pageSize));
    dispatch(downloadResponsesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchProfileDebounce(dispatch);
    }
  },
  onChangeFormText: async (text) => {
    dispatch(setFormSearchText(text));
    dispatch(setFormAdvancedFilterDialogSelectedFilterString(''));
    if (text.length >= 3 || text.length === 0) {
      searchFormDebounce(dispatch);
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setResponseSelectedPageSize(pageSize));
    dispatch(clearResponses());
    dispatch(downloadResponsesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setResponseAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearResponses());
    dispatch(setResponseSelectedOrderBy(INITIAL_ORDER_BY_RESPONSES));
    dispatch(downloadResponsesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setResponseSearchText(text));
      dispatch(clearResponses());
      await dispatch(downloadResponsesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setResponseSelectedOrderBy(orderBy));
    dispatch(clearResponses());
    dispatch(downloadResponsesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setResponseTappedId(id));
    dispatch(downloadResponseAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsePage);
