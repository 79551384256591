import moment from 'moment-timezone';
import { FILTERED_RECIPIENTS_STATUS_ENABLED_TAG, FILTERED_RECIPIENTS_VALID_UNTIL_TAG, RECIPIENT_TYPE_DEVICE_TOKEN } from '../../../constant';
import { sendNotification, toMoment, toUtcMoment } from '../../../helper';
import { sendingTokenNotification, setTotalPersonCount } from '../simple-action';

export default (values, filterString = '') => async (dispatch, getState) => {
  try {
    dispatch(sendingTokenNotification(true));

    const { token } = getState().authentication;
    const {
      title, body, url, image,
    } = values;

    const currentUtcTime = toUtcMoment(toMoment(), moment.tz.guess());
    const filteredRecipients = `${FILTERED_RECIPIENTS_STATUS_ENABLED_TAG}${FILTERED_RECIPIENTS_VALID_UNTIL_TAG}${currentUtcTime}${filterString}`;

    await sendNotification(RECIPIENT_TYPE_DEVICE_TOKEN, filteredRecipients, title, body, url,
      image, token);

    dispatch(setTotalPersonCount(0));
  } finally {
    dispatch(sendingTokenNotification(false));
  }
};
