import { PAGE_MODE_TABLE } from '../../../constant';
import { deleteSessionOperatorMapping, downloadSessionOperatorMapping } from '../../../helper';
import {
  addSessionOperatorMapping, clearSessionOperatorMappings,
  downloadingDeletingSessionOperatorMapping, setAlertErrorMessage,
  setSessionOperatorMappingSelectedSession, setSessionOperatorMappingSelectedUser,
  setSessionOperatorMappingTappedId, setFunctionalPageMode,
} from '../simple-action';
import downloadSessionOperatorMappingsAsync from './downloadSessionOperatorMappingsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingSessionOperatorMapping(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSessionOperatorMapping;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteSessionOperatorMapping(tappedId, token);
      dispatch(setSessionOperatorMappingTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearSessionOperatorMappings());
      dispatch(downloadSessionOperatorMappingsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const result = await downloadSessionOperatorMapping(tappedId, token);
      dispatch(addSessionOperatorMapping(result));

      const { session, user } = result;
      dispatch(setSessionOperatorMappingSelectedSession({
        label: session.name,
        value: session.id,
      }));
      dispatch(setSessionOperatorMappingSelectedUser({ label: user.fullName, value: user.id }));
    }
  } finally {
    dispatch(downloadingDeletingSessionOperatorMapping(false));
  }
};
