import { addEventOperatorRegistration, rejectingEventOperatorRegistration } from '../simple-action';
import { approveRejectEventOperatorRegistration, downloadEventOperatorRegistration } from '../../../helper';
import { EVENT_OPERATOR_REGISTRATION_STATUS } from '../../../constant';

export default (notes) => async (dispatch, getState) => {
  try {
    dispatch(rejectingEventOperatorRegistration(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiEventOperatorRegistration;

    await approveRejectEventOperatorRegistration(tappedId, EVENT_OPERATOR_REGISTRATION_STATUS[1],
      notes, token);
    const result = await downloadEventOperatorRegistration(tappedId, token);

    dispatch(addEventOperatorRegistration(result));
  } finally {
    dispatch(rejectingEventOperatorRegistration(false));
  }
};
