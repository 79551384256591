import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, InputAdornment, IconButton, Snackbar,
} from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage } from '../../component';
import {
  COLOR_PRIMARY, FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW,
  RXFORM_PARTNER_MERCHANT, RXSTATE_PARTNER_MERCHANTS, snackbarDuration,
  RXSTATE_PARTNER_MERCHANT_PAGE, RXFIELD_PARTNER_MERCHANT_DEEP_LINK,
  RXFIELD_PARTNER_MERCHANT_COMPANY, RXFIELD_PARTNER_MERCHANT_STATUS,
  RXFIELD_PARTNER_MERCHANT_CITY, RXFIELD_PARTNER_MERCHANT_NAME,
  RXFIELD_PARTNER_MERCHANT_ADDRESS, RXFIELD_PARTNER_MERCHANT_WEBSITE,
  RXFIELD_PARTNER_MERCHANT_PLAY_STORE_URL, RXFIELD_PARTNER_MERCHANT_APP_STORE_URL,
  RXFIELD_PARTNER_MERCHANT_SHORT_NAME, RXFIELD_PARTNER_MERCHANT_COMPANY_LOGO,
  RXFIELD_PARTNER_MERCHANT_COMPANY_BANNER, status, RXFIELD_PARTNER_MERCHANT_DESCRIPTION,
  RXFIELD_PARTNER_MERCHANT_TERMS_AND_CONDITIONS, RXFIELD_PARTNER_MERCHANT_TAG_LINE,
  PAGE_MODE_EDIT, PAGE_MODE_TABLE,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField, renderReduxFormImageInputField,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormRichTextEditorField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxFormValidatePartnerMerchant } from '../../validation';

const renderDialogContent = (copying, onCopyPressed,
  initialValues, addingEditing, downloadingDeleting, pageMode,
  companies, loadingCompanies,
  onChangeCompanyText, onCompanyOptionSelected,
  onChangeDescriptionText, onChangeTermsAndConditionsText) => {
  const createEditCondition = pageMode === PAGE_MODE_TABLE || pageMode === PAGE_MODE_EDIT;
  return (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_MERCHANT_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerMerchantPage.placeholderName}
            label={LocalizedString.partnerMerchantPage.placeholderName}
            disabled
            hidden={createEditCondition}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_MERCHANT_ADDRESS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerMerchantPage.placeholderAddress}
            label={LocalizedString.partnerMerchantPage.placeholderAddress}
            multiline
            disabled
            hidden={createEditCondition}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_MERCHANT_CITY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerMerchantPage.placeholderCity}
            label={LocalizedString.partnerMerchantPage.placeholderCity}
            disabled
            hidden={createEditCondition}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_MERCHANT_WEBSITE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerMerchantPage.placeholderWebsite}
            label={LocalizedString.partnerMerchantPage.placeholderWebsite}
            disabled
            hidden={createEditCondition}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_MERCHANT_PLAY_STORE_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerMerchantPage.placeholderPlayStoreUrl}
            label={LocalizedString.partnerMerchantPage.placeholderPlaystoreUrl}
            disabled
            hidden={createEditCondition}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_MERCHANT_APP_STORE_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerMerchantPage.placeholderAppStoreUrl}
            label={LocalizedString.partnerMerchantPage.placeholderAppstoreUrl}
            disabled
            hidden={createEditCondition}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_MERCHANT_DEEP_LINK}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerMerchantPage.placeholderDeepLink}
            label={LocalizedString.partnerMerchantPage.placeholderDeepLink}
            disabled
            hidden={pageMode !== PAGE_MODE_VIEW}
            multiline
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => onCopyPressed(copying)} edge="end">
                    <CopyToClipboard text={initialValues.deepLink}>
                      <FileCopyOutlined style={{ fill: COLOR_PRIMARY }} />
                    </CopyToClipboard>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_MERCHANT_COMPANY_LOGO}
            component={renderReduxFormImageInputField}
            label={LocalizedString.partnerMerchantPage.placeholderCompanyLogo}
            disabled
            defaultValue={initialValues.logo}
            useFullWidthImage
            fullImageWidth="100%"
            loading={downloadingDeleting}
            hidden={createEditCondition}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_MERCHANT_COMPANY_BANNER}
            component={renderReduxFormImageInputField}
            label={LocalizedString.partnerMerchantPage.placeholderCompanyBanner}
            disabled
            defaultValue={initialValues.banner}
            useFullWidthImage
            fullImageWidth="100%"
            loading={downloadingDeleting}
            hidden={createEditCondition}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_MERCHANT_COMPANY}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.partnerMerchantPage.placeholderCompany}
            label={LocalizedString.partnerMerchantPage.placeholderCompany}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={companies}
            value={initialValues.company}
            loading={loadingCompanies}
            onChangeText={onChangeCompanyText}
            onOptionSelected={onCompanyOptionSelected}
            hidden={pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        {createEditCondition ? (
          <>
            <Grid item>
              <Field
                name={RXFIELD_PARTNER_MERCHANT_SHORT_NAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.partnerMerchantPage.placeholderShortName}
                label={LocalizedString.partnerMerchantPage.placeholderShortName}
                disabled={pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_PARTNER_MERCHANT_TAG_LINE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.partnerMerchantPage.placeholderTagLine}
                label={LocalizedString.partnerMerchantPage.placeholderTagLine}
                disabled={pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_PARTNER_MERCHANT_TERMS_AND_CONDITIONS}
                component={renderReduxFormRichTextEditorField}
                label={LocalizedString.partnerMerchantPage.placeholderTermsAndConditions}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                onChangeText={onChangeTermsAndConditionsText}
                loading={downloadingDeleting}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
      <Grid item sm md>
        {!createEditCondition ? (
          <>
            <Grid item>
              <Field
                name={RXFIELD_PARTNER_MERCHANT_SHORT_NAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.partnerMerchantPage.placeholderShortName}
                label={LocalizedString.partnerMerchantPage.placeholderShortName}
                disabled={pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_PARTNER_MERCHANT_TAG_LINE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.partnerMerchantPage.placeholderTagLine}
                label={LocalizedString.partnerMerchantPage.placeholderTagLine}
                disabled={pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
          </>
        ) : null}
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_MERCHANT_DESCRIPTION}
            component={renderReduxFormRichTextEditorField}
            label={LocalizedString.partnerMerchantPage.placeholderDescription}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            onChangeText={onChangeDescriptionText}
            loading={downloadingDeleting}
          />
        </Grid>
        {
          !createEditCondition ? (
            <Grid item>
              <Field
                name={RXFIELD_PARTNER_MERCHANT_TERMS_AND_CONDITIONS}
                component={renderReduxFormRichTextEditorField}
                label={LocalizedString.partnerMerchantPage.placeholderTermsAndConditions}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                onChangeText={onChangeTermsAndConditionsText}
                loading={downloadingDeleting}
              />
            </Grid>
          ) : null
        }
      </Grid>

      <Snackbar
        open={copying}
        autoHideDuration={snackbarDuration}
        message={LocalizedString.partnerMerchantPage.labelCopied}
        onClose={() => onCopyPressed(copying)}
      />
    </Grid>
  );
};

const PartnerMerchantPage = ({
  downloading, copying, onCopyPressed,
  handleSubmit, onAppear, onChangePage,
  onChangePageSize, onRefresh,
  onSearchBarTextChanged, onSortPressed,
  onViewPressed, initialValues,
  onAdvancedFilterPressed, onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  cities, loadingCity, onChangeCityText, addingEditing, downloadingDeleting, pageMode,
  onSavePressed, onEditPressed, onCancelPressed,
  onSubmitPressed, onDeletePressed, onConfirmDeletePressed, onCreatePressed,
  companies, loadingCompanies, onChangeCompanyText, onCompanyOptionSelected,
  onChangeDescriptionText, onChangeTermsAndConditionsText,
}) => (
  <FunctionalPage
    data={RXSTATE_PARTNER_MERCHANTS}
    uiPage={RXSTATE_PARTNER_MERCHANT_PAGE}
    filterColumns={[
      {
        title: LocalizedString.partnerMerchantPage.labelCity,
        field: RXFIELD_PARTNER_MERCHANT_CITY,
        type: FILTER_TYPE_DROPDOWN,
        data: cities,
        loading: loadingCity,
        onChangeFilterText: onChangeCityText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.partnerMerchantPage.placeholderStatus,
        field: RXFIELD_PARTNER_MERCHANT_STATUS,
        type: FILTER_TYPE_DROPDOWN,
        data: status,
      },
    ]}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    tableColumns={[
      {
        title: LocalizedString.partnerMerchantPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.partnerMerchantPage.labelName, field: 'company.name', sorting: !downloading },
      { title: LocalizedString.partnerMerchantPage.labelCity, field: 'company.city.name', sorting: !downloading },
      { title: LocalizedString.partnerMerchantPage.labelShortName, field: 'shortName', sorting: !downloading },
      { title: LocalizedString.partnerMerchantPage.labelTagLine, field: 'tagLine', sorting: !downloading },
      { title: LocalizedString.partnerMerchantPage.labelStatus, field: 'status', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.partnerMerchantPage.title}
    createNewButtonCaption={LocalizedString.partnerMerchantPage.buttonCaptionCreatePartnerMerchant}
    onCreatePressed={onCreatePressed}
    onSubmitPressed={onSubmitPressed}
    editButtonCaption={LocalizedString.partnerMerchantPage.buttonCaptionEditPartnerMerchant}
    onEditPressed={onEditPressed}
    onSavePressed={onSavePressed}
    deleteButtonCaption={LocalizedString.partnerMerchantPage.buttonCaptionDeletePartnerMerchant}
    onDeletePressed={onDeletePressed}
    onConfirmDeletePressed={onConfirmDeletePressed}
    useFullWidth
    onCancelPressed={onCancelPressed}
    createPermissionName="MERCHANT_CREATE_MERCHANT"
    deletePermissionName="MERCHANT_DELETE_MERCHANT"
    editPermissionName="MERCHANT_EDIT_MERCHANT"
  >
    {renderDialogContent(copying, onCopyPressed,
      initialValues, addingEditing, downloadingDeleting, pageMode,
      companies, loadingCompanies,
      onChangeCompanyText, onCompanyOptionSelected,
      onChangeDescriptionText, onChangeTermsAndConditionsText)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_PARTNER_MERCHANT,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: rxFormValidatePartnerMerchant,
})(PartnerMerchantPage);

PartnerMerchantPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  cities: PropTypes.arrayOf(SimpleDataShape).isRequired,
  companies: PropTypes.arrayOf(SimpleDataShape).isRequired,
  loadingCity: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingCompanies: PropTypes.bool.isRequired,
  copying: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onCopyPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeCityText: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onCompanyOptionSelected: PropTypes.func.isRequired,
  onChangeCompanyText: PropTypes.func.isRequired,
  onChangeDescriptionText: PropTypes.func.isRequired,
  onChangeTermsAndConditionsText: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
