import { createCaptcha } from '../../../helper';
import { creatingCaptcha, setCaptcha } from '../simple-action';

export default () => async (dispatch) => {
  try {
    dispatch(creatingCaptcha(true));

    const response = await createCaptcha();

    dispatch(setCaptcha(response));
  } finally {
    dispatch(creatingCaptcha(false));
  }
};
