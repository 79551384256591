import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, FormHelperText, makeStyles, Typography,
} from '@material-ui/core';
import {
  File, FileExcel, FilePdfBox, FilePowerpoint, FileWord, ZipBox,
} from 'mdi-material-ui';
import {
  COLOR_BACKGROUND_SECONDARY, COLOR_BODY_TEXT, COLOR_DANGEROUS, COLOR_POLYGON, COLOR_PRIMARY,
  COLOR_SECONDARY, COLOR_TERTIARY, FILE_EXTENSION_PDF, FILE_EXTENSION_CSV, FILE_EXTENSION_ALL,
  FILE_EXTENSION_SLIDESHOW, FILE_EXTENSION_SPREADSHEET, FILE_EXTENSION_DOCUMENT, FILE_EXTENSION_ZIP,
} from '../../constant';
import LocalizedString from '../../localization';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  form: {
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_PRIMARY,
      },
    },
  },
  innerContainer: {
    padding: 20,
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: COLOR_BACKGROUND_SECONDARY,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  primaryText: {
    fontWeight: 'bold',
    color: COLOR_PRIMARY,
  },
  helperText: {
    fontStyle: 'italic',
    color: COLOR_DANGEROUS,
  },
  mb5: {
    marginBottom: '5px',
  },
}));

const getIcon = (acceptedFileExtension) => {
  switch (acceptedFileExtension) {
    case FILE_EXTENSION_PDF: return { icon: FilePdfBox, color: COLOR_POLYGON };
    case FILE_EXTENSION_SPREADSHEET: return { icon: FileExcel, color: COLOR_SECONDARY };
    case FILE_EXTENSION_SLIDESHOW: return { icon: FilePowerpoint, color: COLOR_DANGEROUS };
    case FILE_EXTENSION_DOCUMENT: return { icon: FileWord, color: COLOR_PRIMARY };
    case FILE_EXTENSION_ZIP: return { icon: ZipBox, color: COLOR_TERTIARY };
    default: return { icon: File, color: COLOR_BODY_TEXT };
  }
};

const renderFile = (value, fileName, classes, acceptedFileExtension) => {
  const { icon: Icon, color } = getIcon(acceptedFileExtension);

  if (value && value.length > 0) {
    return (
      <div className={classes.rowContainer}>
        <Icon src={getIcon().icon} variant="square" style={{ color, marginRight: 12 }} />

        {typeof value === 'string' && (
        <a href={value} rel="noopener noreferrer">
          <Typography className={classes.primaryText}>
            {fileName || LocalizedString.common.buttonCaptionDownloadFile}
          </Typography>
        </a>
        )}
      </div>
    );
  }
  return null;
};

const renderField = (
  disabled, onFileChange, onFileSelected, value, fileName, classes,
  acceptedFileExtension,
) => {
  if (disabled) {
    return renderFile(value, fileName, classes);
  }
  return (
    <div className={classes.innerContainer}>
      <input
        type="file"
        onChange={(e) => onFileChange(e, onFileSelected)}
        accept={acceptedFileExtension}
        style={{ width: '100%' }}
      />

      {renderFile(value, fileName, classes, acceptedFileExtension)}
    </div>
  );
};

const FilePickerField = ({
  disabled, error, hidden,
  onFileChange, onFileSelected,
  value,
  fileName, helperText, label,
  acceptedFileExtension,
  ...props
}) => {
  const classes = useStyles();

  if (!hidden) {
    return (
      <div className={classes.container}>
        <FormControl
          className={classes.form}
          variant="outlined"
          error={error}
          {...props}
        >
          <Typography className={classes.mb5}>{label}</Typography>
          {renderField(
            disabled, onFileChange, onFileSelected, value, fileName, classes,
            acceptedFileExtension,
          )}
          <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
        </FormControl>
      </div>
    );
  }
  return null;
};

export default FilePickerField;

FilePickerField.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hidden: PropTypes.bool,
  onFileChange: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fileName: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  acceptedFileExtension: PropTypes.oneOf([
    FILE_EXTENSION_PDF,
    FILE_EXTENSION_CSV,
    FILE_EXTENSION_SPREADSHEET,
    FILE_EXTENSION_SLIDESHOW,
    FILE_EXTENSION_DOCUMENT,
    FILE_EXTENSION_ZIP,
    FILE_EXTENSION_ALL,
  ]),
};

FilePickerField.defaultProps = {
  error: false,
  disabled: false,
  hidden: false,
  fileName: null,
  label: null,
  helperText: null,
  value: null,
  acceptedFileExtension: FILE_EXTENSION_PDF,
};
