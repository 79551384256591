import { PAGE_MODE_TABLE } from '../../../constant';
import { deleteEventInvitation, downloadEventInvitation } from '../../../helper';
import {
  addEventInvitation, clearEventInvitations, downloadingDeletingEventInvitation,
  setAlertErrorMessage, setEventInvitationTappedId, setFunctionalPageMode,
} from '../simple-action';
import downloadEventInvitationsAsync from './downloadEventInvitationsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingEventInvitation(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiEventInvitation;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteEventInvitation(tappedId, token);
      dispatch(setEventInvitationTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearEventInvitations());
      dispatch(downloadEventInvitationsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const eventInvitation = await downloadEventInvitation(tappedId, token);
      dispatch(addEventInvitation(eventInvitation));
    }
  } finally {
    dispatch(downloadingDeletingEventInvitation(false));
  }
};
