import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    notificationMenu: {
      title: 'Notification',

      buttonCaptionResend: 'Resend',

      msgResendConfirmation: 'Are you sure want to resend this item?',
    },
    emailScreen: {
      title: 'Email',

      labelNo: 'No',
      labelTo: 'To',
      labelSubject: 'Subject',
      labelProcessingStatus: 'Processing Status',
      labelCreatedDate: 'Created Date',
      labelFileName: 'File Name',
      labelAttachment: 'Attachment',
      labelDownload: 'Download',

      placeholderTo: 'To',
      placeholderCc: 'cc',
      placeholderBcc: 'bcc',
      placeholderSubject: 'Subject',
      placeholderBody: 'Body',
      placeholderFormat: 'Format',
      placeholderProcessingStatus: 'Processing Status',
      placeholderNotes: 'Notes',
    },
    smsScreen: {
      title: 'SMS',

      labelNo: 'No',
      labelTo: 'To',
      labelMessage: 'Message',
      labelProcessingStatus: 'Processing Status',
      labelCreatedDate: 'Created Date',

      placeholderTo: 'To',
      placeholderMessage: 'Message',
      placeholderProcessingStatus: 'Processing Status',
      placeholderNotes: 'Notes',
    },
    whatsappScreen: {
      title: 'Whatsapp',

      labelNo: 'No',
      labelTo: 'To',
      labelMessage: 'Message',
      labelProcessingStatus: 'Processing Status',
      labelCreatedDate: 'Created Date',

      placeholderTo: 'To',
      placeholderMessage: 'Message',
      placeholderProcessingStatus: 'Processing Status',
      placeholderNotes: 'Notes',
    },
    pushNotificationScreen: {
      title: 'Push Notification',

      labelNo: 'No',
      labelTo: 'To',
      labelTitle: 'Title',
      labelBody: 'Body',
      labelProcessingStatus: 'Processing Status',
      labelCreatedDate: 'Created Date',

      placeholderTo: 'To',
      placeholderTos: 'To(s)',
      placeholderTitle: 'Title',
      placeholderBody: 'Body',
      placeholderFlag: 'Flag',
      placeholderData: 'Data',
      placeholderProcessingStatus: 'Processing Status',
      placeholderNotes: 'Notes',
    },
    notificationTaskScreen: {
      title: 'Notification Task',

      labelNo: 'No',
      labelTitle: 'Title',
      labelStartTime: 'Start Time',
      labelProgress: 'Progress',
      labelNotes: 'Notes',
      labelStatus: 'Status',
      labelEndTime: 'End Time',
      labelRecipientType: 'Recipient Type',
      labelCreatedDate: 'Created Date',
      labelDuplicateTask: 'Duplicate Task',
      labelImageRatio1To1: 'The image aspect ratio should be 1:1 (square)',

      buttonCaptionCancelTask: 'Cancel Task',
      buttonCaptionDuplicate: 'Duplicate',

      placeholderFilteredRecipients: 'Filtered Recipients',
      placeholderTitle: 'Title',
      placeholderBody: 'Body',
      placeholderUrl: 'URL',
      placeholderTotal: 'Total',
      placeholderProcessed: 'Processed',
      placeholderSent: 'Sent',
      placeholderError: 'Error',
      placeholderNotes: 'Notes',
      placeholderImage: 'Image',
      placeholderTaskStatus: 'Task Status',
      placeholderProgress: 'Progress',
      placeholderProgressNotes: 'Progress Notes',
      placeholderStartTime: 'Start Time',
      placeholderEndTime: 'End Time',
      placeholderRecipientType: 'Recipient Type',
    },
    inboxPage: {
      title: 'Inbox',

      labelNo: 'No.',
      labelFullName: 'Full Name',
      labelTitle: 'Title',
      labelRead: 'Read',
      labelCreatedDate: 'Created Date',
      labelUser: 'User',

      placeholderTitle: 'Title',
      placeholderBody: 'Body',
      placeholderRead: 'Read',
      placeholderFlag: 'Flag',
      placeholderPayload: 'Payload',
      placeholderUserID: 'User ID',
      placeholderFullName: 'Full Name',
      placeholderAndroidAppId: 'Android App ID',
      placeholderIosAppId: 'Ios App ID',
      placeholderDownloadUrl: 'Download URL',
    },
  },
  id: {
    notificationMenu: {
      title: 'Notifikasi',

      buttonCaptionResend: 'Kirim Ulang',

      msgResendConfirmation: 'Apa Anda yakin ingin mengirim ulang pilihan ini?',
    },
    emailScreen: {
      title: 'Email',

      labelNo: 'No',
      labelTo: 'Kepada',
      labelSubject: 'Subyek',
      labelProcessingStatus: 'Status Pemrosesan',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelFileName: 'Nama File',
      labelAttachment: 'Lampiran',
      labelDownload: 'Unduh',

      placeholderTo: 'Kepada',
      placeholderCc: 'cc',
      placeholderBcc: 'bcc',
      placeholderSubject: 'Subyek',
      placeholderBody: 'Isi',
      placeholderFormat: 'Format',
      placeholderProcessingStatus: 'Status Pemrosesan',
      placeholderNotes: 'Catatan',
    },
    smsScreen: {
      title: 'SMS',

      labelNo: 'No',
      labelTo: 'Kepada',
      labelMessage: 'Pesan',
      labelProcessingStatus: 'Status Pemrosesan',
      labelCreatedDate: 'Tanggal Pembuatan',

      placeholderTo: 'To',
      placeholderMessage: 'Pesan',
      placeholderProcessingStatus: 'Status Pemrosesan',
      placeholderNotes: 'Catatan',
    },
    whatsappScreen: {
      title: 'Whatsapp',

      labelNo: 'No',
      labelTo: 'Kepada',
      labelMessage: 'Pesan',
      labelProcessingStatus: 'Status Pemrosesan',
      labelCreatedDate: 'Tanggal Pembuatan',

      placeholderTo: 'To',
      placeholderMessage: 'Pesan',
      placeholderProcessingStatus: 'Status Pemrosesan',
      placeholderNotes: 'Catatan',
    },
    pushNotificationScreen: {
      title: 'Notifikasi Paksa',

      labelNo: 'No',
      labelTo: 'Kepada',
      labelTitle: 'Judul',
      labelBody: 'Isi',
      labelProcessingStatus: 'Status Pemrosesan',
      labelCreatedDate: 'Tanggal Pembuatan',

      placeholderTo: 'Kepada',
      placeholderTos: 'Kepada yang Lain',
      placeholderTitle: 'Judul',
      placeholderBody: 'Isi',
      placeholderFlag: 'Penanda',
      placeholderData: 'Data',
      placeholderProcessingStatus: 'Status Pemrosesan',
      placeholderNotes: 'Catatan',
    },
    notificationTaskScreen: {
      title: 'Tugas Notifikasi',

      labelNo: 'No',
      labelTitle: 'Judul',
      labelStartTime: 'Waktu Mulai',
      labelProgress: 'Kemajuan',
      labelNotes: 'Catatan',
      labelStatus: 'Status',
      labelEndTime: 'Waktu Selesai',
      labelRecipientType: 'Tipe Penerima',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelDuplicateTask: 'Duplikasi Tugas',
      labelImageRatio1To1: 'Rasio gambar yang dipilih harus 1:1 (persegi)',

      buttonCaptionCancelTask: 'Batalkan Tugas',
      buttonCaptionDuplicate: 'Duplikasi',

      placeholderFilteredRecipients: 'Penerima Tersaring',
      placeholderTitle: 'Judul',
      placeholderBody: 'Badan',
      placeholderUrl: 'URL',
      placeholderTotal: 'Total',
      placeholderProcessed: 'Diproses',
      placeholderSent: 'Dikirim',
      placeholderError: 'Kesalahan',
      placeholderNotes: 'Catatan',
      placeholderImage: 'Gambar',
      placeholderTaskStatus: 'Status Tugas',
      placeholderProgress: 'Kemajuan',
      placeholderProgressNotes: 'Catatan Kemajuan',
      placeholderStartTime: 'Waktu Mulai',
      placeholderEndTime: 'Waktu Selesai',
      placeholderRecipientType: 'Tipe Penerima',
    },
    inboxPage: {
      title: 'Kotak Masuk',

      labelNo: 'No.',
      labelFullName: 'Nama Lengkap',
      labelTitle: 'Judul',
      labelRead: 'Dibaca',
      labelCreatedDate: 'Tanggal Dibuat',
      labelUser: 'Pengguna',

      placeholderTitle: 'Judul',
      placeholderBody: 'Isi',
      placeholderRead: 'Dibaca',
      placeholderFlag: 'Penanda',
      placeholderPayload: 'Muatan',
      placeholderUserID: 'ID Pengguna',
      placeholderFullName: 'Nama Lengkap',
      placeholderAndroidAppId: 'App ID Android',
      placeholderIosAppId: 'App ID Ios',
      placeholderDownloadUrl: 'URL Unduh',
    },
  },
});

export default LocalizedString;
