import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, ENUM_FIELD_PHOTO, GET_PROFILE_PERMISSION,
  INITIAL_ORDER_BY_HISTORIES, MENUID_ATTENDANCE_MANAGEMENT_HISTORY, PAGE_MODE_TABLE,
  REGEX_UTC_DATE_FORMAT, REGEX_UTC_DATE_TIME_FORMAT, SIMPLE_DATE_FORMAT, TIME_FORMAT,
} from '../../constant';
import {
  isDecimal, sortAsc, transformUserDropdownData, transformInitialValues, toMoment,
} from '../../helper';
import LocalizedString from '../../localization';
import {
  clearCompanies, clearDepartments, clearDivisions, clearEmployees, clearHistories,
  clearHistoryProfileDetail, setActiveSideMenuItem, setAdvancedFilterDialogSelectedFilterString,
  setAlertErrorMessage, setCompanySearchText, setDepartmentSearchText, setDivisionSearchText,
  setHistoryEmployeeSearchText, setHistorySearchText, setHistorySelectedPageSize,
  setHistorySelectedCompany, setHistorySelectedDepartment, setHistorySelectedDivision,
  setHistorySelectedEmployee, setHistorySelectedOrderBy, setHistoryTappedId,
  downloadAttendanceHistoryProfileDetailAsync, downloadEmployeesAsync, downloadHistoriesAsync,
  saveHistoriesAsync, setBranchSearchText, clearBranches, setHistorySelectedBranch,
} from '../../redux/action';
import {
  downloadBranchesAsync, downloadCompaniesAsync, downloadDepartmentsAsync, downloadDivisionsAsync,
} from '../../../../redux/action';
import HistoryPage from './history.presentation';
import { ROUTE_NAME_MY_JOB } from '../../../user-job';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getInitialValues = (state) => {
  const { histories, uiHistory, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId, profileDetail } = uiHistory;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? histories.data[tappedId] : {};
  const profile = Object.keys(found).length > 0 && found.profile
  && Object.keys(found.profile).length > 0
    ? Object.assign({}, ...(function _flatten(obj) { return [].concat(...Object.keys(obj).map((key) => (typeof obj[key] === 'object' ? _flatten(obj[key]) : ({ [key]: obj[key] })))); }(found.profile)))
    : {
      profilePicture: '',
      employeeId: '',
      fullName: '',
      email: '',
    };
  const profileWithDetail = isEmpty(profileDetail) ? {
    employeeId: profile.employeeId,
    fullName: profile.fullName,
  } : {
    employeeId: profile.employeeId,
    fullName: profile.fullName,
    jobTitle: profileDetail.jobTitle,
    department: profileDetail.department,
    division: profileDetail.division,
    company: profileDetail.company,
    manager: profileDetail.manager,
    profilePicture: profileDetail.profilePicture,
    dateOfBirth: profileDetail.dateOfBirth
      ? toMoment(profileDetail.dateOfBirth).format(SIMPLE_DATE_FORMAT) : null,
    currentBranchName: profileDetail.branch.name,
  };
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      ...profileWithDetail,
      startWorkingHourTimeZone: found.startWorkingHourTimeZone,
      endWorkingHourTimeZone: found.endWorkingHourTimeZone,
      workingHour: `${found.startWorkingHour ? found.startWorkingHour : LocalizedString.historyScreen.labelNA} - ${found.endWorkingHour ? found.endWorkingHour : LocalizedString.historyScreen.labelNA}`,
      clockInTime: found.clockInTime ? toMoment(found.clockInTime, found.startWorkingHourTimeZone).format(TIME_FORMAT) : '',
      clockOutTime: found.clockOutTime ? toMoment(found.clockOutTime, found.endWorkingHourTimeZone).format(TIME_FORMAT) : '',
      attendanceDate: found.clockInTime ? toMoment(found.clockInTime, found.startWorkingHourTimeZone).format(SIMPLE_DATE_FORMAT) : '',
      clockInCenter: found.clockInUserReportedLatitude && found.clockInUserReportedLongitude
      && isDecimal(found.clockInUserReportedLatitude)
      && isDecimal(found.clockInUserReportedLongitude) ? {
          lat: found.clockInUserReportedLatitude,
          lng: found.clockInUserReportedLongitude,
        } : null,
      clockInMarker: found.clockInUserReportedLatitude && found.clockInUserReportedLongitude
      && isDecimal(found.clockInUserReportedLatitude)
      && isDecimal(found.clockInUserReportedLongitude) ? {
          lat: found.clockInUserReportedLatitude,
          lng: found.clockInUserReportedLongitude,
        } : null,
      clockOutCenter: found.clockOutUserReportedLatitude && found.clockOutUserReportedLongitude
      && isDecimal(found.clockOutUserReportedLatitude)
      && isDecimal(found.clockOutUserReportedLongitude) ? {
          lat: found.clockOutUserReportedLatitude,
          lng: found.clockOutUserReportedLongitude,
        } : null,
      clockOutMarker: found.clockOutUserReportedLatitude && found.clockOutUserReportedLongitude
      && isDecimal(found.clockOutUserReportedLatitude)
      && isDecimal(found.clockOutUserReportedLongitude) ? {
          lat: found.clockOutUserReportedLatitude,
          lng: found.clockOutUserReportedLongitude,
        } : null,
      additionalFields: found.additionalFields && found.additionalFields.length > 0
        ? [
          ...found.additionalFields.filter((x) => x.field.fieldType !== ENUM_FIELD_PHOTO)
            .map((x) => {
              if (x.value.match(REGEX_UTC_DATE_TIME_FORMAT)) {
                return { ...x, value: toMoment(x.value).format(DATE_TIME_FORMAT_WITHOUT_PIPE) };
              }
              if (x.value.match(REGEX_UTC_DATE_FORMAT)) {
                return { ...x, value: toMoment(x.value).format(SIMPLE_DATE_FORMAT) };
              }
              return x;
            }).sort((a, b) => sortAsc(a.field.label, b.field.label)),
          ...found.additionalFields.filter((x) => x.field.fieldType === ENUM_FIELD_PHOTO)
            .sort((a, b) => sortAsc(a.field.label, b.field.label)),
        ] : [],
    }) : {
      profilePicture: '',
      employeeId: '',
      fullName: '',
      email: '',
      workingHour: '',
      attendanceDate: '',
      clockInTime: '',
      clockInStatus: '',
      clockInLocationName: '',
      clockInLocationStatus: '',
      clockInNotes: '',
      clockInUserReportedLatitude: '',
      clockInUserReportedLongitude: '',
      clockOutTime: '',
      clockOutStatus: '',
      clockOutLocationName: '',
      clockOutLocationStatus: '',
      clockOutNotes: '',
      clockOutUserReportedLatitude: '',
      clockOutUserReportedLongitude: '',
      clockInCenter: null,
      clockInMarker: null,
      clockOutCenter: null,
      clockOutMarker: null,
      additionalFields: [],
    };
  return initVal;
};

const isProfileAccessAllowed = (state) => {
  const { currentUser } = state;
  const { permissions } = currentUser;
  return !!Object.keys(permissions).find((x) => x === GET_PROFILE_PERMISSION);
};

const transformMultipleDropdownValue = (data) => Object
  .values(data)
  .map((item) => item.fullName || item.name);

const mapStateToProps = (state) => ({
  companies: transformMultipleDropdownValue(state.companies.data),
  departments: transformMultipleDropdownValue(state.departments.data),
  divisions: transformMultipleDropdownValue(state.divisions.data),
  employees: transformUserDropdownData(state.employees.data),
  branches: transformMultipleDropdownValue(state.branches.data),
  downloading: state.uiHistory.downloading,
  loadingCompanies: state.notification.downloadingCompanies,
  loadingDepartments: state.notification.downloadingDivisions,
  loadingDivisions: state.notification.downloadingDepartments,
  loadingEmployees: state.uiHistory.downloadingEmployees,
  loadingBranches: state.uiBranch.downloading,
  initialValues: getInitialValues(state),
  isProfileAccessAllowed: isProfileAccessAllowed(state),
  downloadingProfileDetail: state.uiProfile.downloadingDeleting,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setHistorySelectedCompany(''));
    dispatch(setHistorySelectedDepartment(''));
    dispatch(setHistorySelectedDivision(''));
    dispatch(setHistorySelectedEmployee(''));
    dispatch(clearCompanies());
    dispatch(clearDepartments());
    dispatch(clearDivisions());
    dispatch(clearEmployees());
    dispatch(downloadCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDepartmentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDivisionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadEmployeesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_ATTENDANCE_MANAGEMENT_HISTORY));
    dispatch(setHistorySearchText(''));
    dispatch(clearHistories());
    dispatch(setHistorySelectedPageSize(20));
    dispatch(setHistorySelectedOrderBy(INITIAL_ORDER_BY_HISTORIES));
    dispatch(downloadHistoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/employee/, 'profile.id').replace(/department/, 'profile.department')
      .replace(/division/, 'profile.division').replace(/company/, 'profile.company')
      .replace(/branch/, 'branch.name');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearHistories());
    dispatch(downloadHistoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(setHistorySelectedCompany(''));
    dispatch(setHistorySelectedDepartment(''));
    dispatch(setHistorySelectedDivision(''));
    dispatch(setHistorySelectedEmployee(''));
  },
  onChangeCompanyText: async (text) => {
    try {
      dispatch(setCompanySearchText(text));
      dispatch(clearCompanies());
      await dispatch(downloadCompaniesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeDepartmentText: async (text) => {
    try {
      dispatch(setDepartmentSearchText(text));
      dispatch(clearDepartments());
      await dispatch(downloadDepartmentsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeDivisionText: async (text) => {
    try {
      dispatch(setDivisionSearchText(text));
      dispatch(clearDivisions());
      await dispatch(downloadDivisionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeEmployeeText: async (text) => {
    try {
      dispatch(setHistoryEmployeeSearchText(text));
      dispatch(clearEmployees());
      await dispatch(downloadEmployeesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeBranchText: async (text) => {
    try {
      dispatch(setBranchSearchText(text));
      dispatch(clearBranches());
      await dispatch(downloadBranchesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadHistoriesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setHistorySelectedPageSize(pageSize));
    dispatch(downloadHistoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDownloadPressed: () => {
    dispatch(saveHistoriesAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onErrorConfirmPressed: () => {
    ownProps.history.push(ROUTE_NAME_MY_JOB);
  },
  onRefresh: (pageSize) => {
    dispatch(setHistorySelectedPageSize(pageSize));
    dispatch(clearHistories());
    dispatch(downloadHistoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setHistorySelectedCompany(''));
    dispatch(setHistorySelectedDepartment(''));
    dispatch(setHistorySelectedDivision(''));
    dispatch(setHistorySelectedEmployee(''));
    dispatch(setHistorySelectedBranch(''));
    dispatch(clearHistories());
    dispatch(setHistorySelectedOrderBy(INITIAL_ORDER_BY_HISTORIES));
    dispatch(downloadHistoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setHistorySearchText(text));
      dispatch(clearHistories());
      await dispatch(downloadHistoriesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setHistorySelectedOrderBy(orderBy));
    dispatch(clearHistories());
    dispatch(downloadHistoriesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id, isAllowed) => {
    dispatch(setHistoryTappedId(id));
    dispatch(clearHistoryProfileDetail());
    if (isAllowed) {
      dispatch(downloadAttendanceHistoryProfileDetailAsync(id))
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryPage);
