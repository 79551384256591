import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Avatar, CircularProgress, Grid, InputAdornment, IconButton, makeStyles, Snackbar, TextField,
} from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  AccentButton, Comment, FunctionalPage, NumberTextFieldWithoutArrowDial,
  SectionTitle, SendNotificationDialog, DropzonePicker,
} from '../../component';
import {
  approval, COLOR_PRIMARY, DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_DROPDOWN, FILTER_TYPE_NUMBER_RANGE, FILTER_TYPE_SWITCH, IMAGE_SOURCE_URI_PREFIX,
  PAGE_MODE_TABLE, PAGE_MODE_VIEW, PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE, RXFIELD_NEWS_AUTHOR, RXFIELD_NEWS_CATEGORY,
  RXFIELD_NEWS_CONTENT, RXFIELD_NEWS_DEEP_LINK, RXFIELD_NEWS_DISCUSSION_ID,
  RXFIELD_NEWS_IS_HEADLINE, RXFIELD_NEWS_MEDIA, RXFIELD_NEWS_PUBLISHED_DATE,
  RXFIELD_NEWS_REACTION_ID, RXFIELD_NEWS_SHORT_DESCRIPTION, RXFIELD_NEWS_SHORT_URL,
  RXFIELD_NEWS_SOURCE_NAME, RXFIELD_NEWS_SOURCE_URL, RXFIELD_NEWS_TAGS, RXFIELD_NEWS_TITLE,
  RXFIELD_NEWS_VIEW_COUNT, RXFIELD_NEWS_VISIBILITY_STATUS, RXFORM_NEWS, RXSTATE_NEWS,
  RXSTATE_NEWS_PAGE, snackbarDuration, visibilityStatuses,
  ADVANCED_FILTER_MODE,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import {
  renderReduxFormEditableTableField, renderReduxFormOutlinedTextField,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormDateTimePickerField,
  renderReduxFormRichTextEditorField, renderReduxFormSimpleDropdownField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxformValidateNews } from '../../validation';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    margin: '10px 0px 10px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  italicButton: {
    fontStyle: 'italic',
    color: COLOR_PRIMARY,
    marginRight: 10,
  },
}));

const renderDialogContent = (initialValues, categories, addingEditing, copying, downloadingDeleting,
  loadingCategory, onAddMediaPressed, onAddMediaMenuSelected, onCategoryOptionSelected,
  onChangeCategoryText, onChangeContentText, onChangeShortDescriptionText, onCopyPressed,
  onDeleteMediaPressed, onHeadlineOptionSelected, onVisibilityStatusOptionSelected,
  shortDescriptionLength, discusId, pageMode, selectedAddMediaMenu) => (
    <Grid>
      <Grid container spacing={3}>
        <Grid item xs sm md>
          <Grid item>
            <Field
              name={RXFIELD_NEWS_TITLE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.newsScreen.placeholderTitle}
              label={LocalizedString.newsScreen.placeholderTitle}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_NEWS_PUBLISHED_DATE}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.newsScreen.placeholderPublishedDate}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              pickerMode={PICKER_MODE_DATE_TIME}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_NEWS_CATEGORY}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.newsScreen.placeholderCategory}
              label={LocalizedString.newsScreen.placeholderCategory}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={categories}
              value={initialValues.category.label}
              loading={loadingCategory}
              onChangeText={onChangeCategoryText}
              onOptionSelected={onCategoryOptionSelected}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_NEWS_VISIBILITY_STATUS}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.newsScreen.placeholderVisibilityStatus}
              label={LocalizedString.newsScreen.placeholderVisibilityStatus}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={visibilityStatuses}
              value={initialValues.visibilityStatus}
              onOptionSelected={onVisibilityStatusOptionSelected}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_NEWS_IS_HEADLINE}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.newsScreen.placeholderHeadline}
              label={LocalizedString.newsScreen.placeholderHeadline}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={approval}
              value={initialValues.isHeadline}
              onOptionSelected={onHeadlineOptionSelected}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_NEWS_SOURCE_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.newsScreen.placeholderSourceName}
              label={LocalizedString.newsScreen.placeholderSourceName}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_NEWS_SOURCE_URL}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.newsScreen.placeholderSourceLink}
              label={LocalizedString.newsScreen.placeholderSourceLink}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_NEWS_DEEP_LINK}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.newsScreen.placeholderDeepLink}
              label={LocalizedString.newsScreen.placeholderDeepLink}
              disabled
              hidden={pageMode !== PAGE_MODE_VIEW}
              multiline
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => onCopyPressed(copying)} edge="end">
                      <CopyToClipboard text={initialValues.deepLink}>
                        <FileCopyOutlined style={{ fill: COLOR_PRIMARY }} />
                      </CopyToClipboard>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs sm md>
          <Grid item>
            <Field
              name={RXFIELD_NEWS_VIEW_COUNT}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.newsScreen.placeholderViewCount}
              label={LocalizedString.newsScreen.placeholderViewCount}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_NEWS_SHORT_URL}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.newsScreen.placeholderShortUrl}
              label={LocalizedString.newsScreen.placeholderShortUrl}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_NEWS_REACTION_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.newsScreen.placeholderReactionId}
              label={LocalizedString.newsScreen.placeholderReactionId}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_NEWS_DISCUSSION_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.newsScreen.placeholderDiscussionId}
              label={LocalizedString.newsScreen.placeholderDiscussionId}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_NEWS_AUTHOR}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.newsScreen.placeholderAuthor}
              label={LocalizedString.newsScreen.placeholderAuthor}
              disabled
              hidden={pageMode !== PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_NEWS_TAGS}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.newsScreen.placeholderTags}
              label={LocalizedString.newsScreen.placeholderTags}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_NEWS_SHORT_DESCRIPTION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.newsScreen.placeholderShortDescription}
              helperText={`${shortDescriptionLength} ${GlobalLocalizedString.common.labelCharacter}`}
              label={LocalizedString.newsScreen.placeholderShortDescription}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              onChangeText={(e) => onChangeShortDescriptionText(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={12}>
        <Field
          name={RXFIELD_NEWS_CONTENT}
          component={renderReduxFormRichTextEditorField}
          label={LocalizedString.newsScreen.placeholderContent}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          onChangeText={onChangeContentText}
          loading={downloadingDeleting}
          required
        />
      </Grid>

      <SectionTitle title={LocalizedString.newsScreen.labelMedia} />

      {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_NEWS_MEDIA}
            component={renderReduxFormEditableTableField}
            label={LocalizedString.newsScreen.labelMedia}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            onAddPressed={onAddMediaPressed}
            onDeletePressed={onDeleteMediaPressed}
            defaultValue={initialValues.media}
            addMenuList={[
              {
                caption: LocalizedString.newsScreen.buttonCaptionYoutube,
                onPress: () => onAddMediaMenuSelected('Youtube'),
              },
              {
                caption: LocalizedString.newsScreen.buttonCaptionImage,
                onPress: () => onAddMediaMenuSelected('Image'),
              },
            ]}
            requiredTableColumnField={['order']}
            optionalTableColumnField={['content', 'link']}
            disableEdit
            disableToolbar={pageMode === PAGE_MODE_VIEW}
            tableColumns={[
              {
                title: LocalizedString.newsScreen.labelOrder,
                field: 'order',
                sorting: false,
                editable: 'onAdd',
                // eslint-disable-next-line react/prop-types
                editComponent: ({ value, onChange }) => (
                  <NumberTextFieldWithoutArrowDial
                    placeholder={LocalizedString.newsScreen.labelOrder}
                    label={LocalizedString.newsScreen.labelOrder}
                    variant="outlined"
                    type="number"
                    defaultValue={value}
                    onChange={(e) => onChange(e.target.value)}
                    fullWidth
                  />
                ),
              },
              {
                title: LocalizedString.newsScreen.labelYoutube,
                field: 'link',
                sorting: false,
                editable: selectedAddMediaMenu === 'Youtube' ? 'onAdd' : 'never',
                render: (prop) => {
                  if (prop) {
                    if (prop.path) {
                      if (prop.type === 'Youtube' && !prop.tableData.editing) {
                        const path = prop.path.includes(`${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}`) ? prop.path.slice(68) : prop.path;
                        return path;
                      }
                      if ((prop.type === 'Youtube' || Object.prototype.hasOwnProperty.call(prop, 'path')) && prop.tableData.editing) {
                        onAddMediaMenuSelected('Youtube');
                      }
                    }
                    if (prop.link) {
                      return prop.link.path;
                    }
                    return null;
                  }
                  return null;
                },
                // eslint-disable-next-line react/prop-types
                editComponent: ({ value, onChange }) => (
                  <TextField
                    placeholder={LocalizedString.newsScreen.labelYoutube}
                    label={LocalizedString.newsScreen.labelYoutube}
                    variant="outlined"
                    defaultValue={value}
                    onChange={(e) => onChange({ path: e.target.value, type: 'Youtube' })}
                    fullWidth
                  />
                ),
              },
              {
                title: LocalizedString.newsScreen.labelImage,
                field: 'content',
                sorting: false,
                editable: selectedAddMediaMenu === 'Image' ? 'onAdd' : 'never',
                render: (prop) => {
                  if (prop) {
                    if (prop.path) {
                      if (prop.type === 'Image' && !prop.tableData.editing) {
                        return (<Avatar variant="square" src={prop.path} />);
                      }
                      if ((prop.type === 'Image' || Object.prototype.hasOwnProperty.call(prop, 'path')) && prop.tableData.editing) {
                        onAddMediaMenuSelected('Image');
                      }
                    }
                    if (prop.content) {
                      return (
                        <Avatar
                          variant="square"
                          src={IMAGE_SOURCE_URI_PREFIX.concat(prop.content.path)}
                        />
                      );
                    }
                    return null;
                  }
                  return null;
                },
                // eslint-disable-next-line react/prop-types
                editComponent: ({ onChange }) => (
                  <DropzonePicker
                    onImageSelected={(path) => onChange({ path, type: 'Image' })}
                  />
                ),
              },
            ]}
            required
          />
        </Grid>
      )}

      {pageMode !== PAGE_MODE_TABLE && (<Comment discusId={discusId} />)}

      <Snackbar
        open={copying}
        autoHideDuration={snackbarDuration}
        message={LocalizedString.newsScreen.labelCopied}
        onClose={() => onCopyPressed(copying)}
      />
    </Grid>
);

const NewsPage = ({
  initialValues, categories, addingEditing, copying, downloading, downloadingDeleting,
  loadingCategory, sendingNotification, sendingNotifications,
  handleSubmit, onAddMediaPressed, onAddMediaMenuSelected, onAdvancedFilterPressed, onAppear,
  onApplyAdvancedFilterPressed, onCancelAdvancedFilterPressed, onCancelPressed,
  onCategoryOptionSelected, onChangeCategoryText, onChangeContentText, onChangePage,
  onChangePageSize, onChangeShortDescriptionText, onConfirmDeletePressed, onCopyPressed,
  onCreatePressed, onDeleteMediaPressed, onDeletePressed, onEditPressed, onHeadlineOptionSelected,
  onOpenSendNotificationDialog, onRefresh, onResetAdvancedFilterPressed, onSavePressed,
  onSearchBarTextChanged, onSendToMePressed, onSendNotificationsPressed, onSortPressed,
  onSubmitPressed, onViewPressed, onVisibilityStatusOptionSelected,
  shortDescriptionLength,
  discusId, pageMode, selectedAddMediaMenu,
}) => {
  const classes = useStyles();

  return (
    <div>
      {pageMode === PAGE_MODE_VIEW && (
      <SendNotificationDialog
        initialValues={initialValues.notification}
        loading={sendingNotification}
        useFilterSection
        recipient={initialValues.title}
        sendingToLabel={LocalizedString.newsScreen.labelSendNotificationTo}
        disabledUrlField
        additionalUrlPlaceholder={GlobalLocalizedString.common.labelAdditionalUrlPlaceholders}
      />
      )}

      <FunctionalPage
        data={RXSTATE_NEWS}
        uiPage={RXSTATE_NEWS_PAGE}
        filterColumns={[
          {
            title: LocalizedString.newsScreen.labelPublishedDate, field: 'publishedDate', type: FILTER_TYPE_DATE_RANGE, pickerMode: PICKER_MODE_DATE_TIME, format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
          },
          {
            title: LocalizedString.newsScreen.labelCategory,
            field: 'categoryName',
            type: FILTER_TYPE_DROPDOWN,
            data: categories,
            loading: loadingCategory,
            onChangeFilterText: (text) => onChangeCategoryText(text, ADVANCED_FILTER_MODE),
            onFilterOptionSelected: onCategoryOptionSelected,
          },
          {
            title: LocalizedString.newsScreen.labelStatus, field: 'visibilityStatus', type: FILTER_TYPE_DROPDOWN, data: visibilityStatuses,
          },
          { title: LocalizedString.newsScreen.labelViewCount, field: 'viewCount', type: FILTER_TYPE_NUMBER_RANGE },
          { title: LocalizedString.newsScreen.labelHeadline, field: 'isHeadline', type: FILTER_TYPE_SWITCH },
        ]}
        tableColumns={[
          {
            title: LocalizedString.newsScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
          },
          { title: LocalizedString.newsScreen.labelTitle, field: 'title', sorting: !downloading },
          { title: LocalizedString.newsScreen.labelCategory, field: 'category.name', sorting: !downloading },
          {
            title: LocalizedString.newsScreen.labelPublishedDate,
            field: 'publishedDate',
            render: ({ publishedDate }) => (
              toMoment(publishedDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE)),
            sorting: !downloading,
          },
          { title: LocalizedString.newsScreen.labelViewCount, field: 'viewCount', sorting: !downloading },
        ]}
        handleSubmit={handleSubmit}
        onAdvancedFilterPressed={onAdvancedFilterPressed}
        onAppear={onAppear}
        onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
        onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
        onCancelPressed={onCancelPressed}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onConfirmDeletePressed={onConfirmDeletePressed}
        onCreatePressed={onCreatePressed}
        onDeletePressed={onDeletePressed}
        onEditPressed={onEditPressed}
        onOpenSendNotificationDialog={onOpenSendNotificationDialog}
        onRefresh={onRefresh}
        onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
        onSavePressed={onSavePressed}
        onSearchBarTextChanged={onSearchBarTextChanged}
        onSendToMePressed={onSendToMePressed}
        onSortPressed={onSortPressed}
        onSubmitPressed={onSubmitPressed}
        onViewPressed={onViewPressed}
        createNewButtonCaption={LocalizedString.newsScreen.buttonCaptionCreateNewNews}
        deleteButtonCaption={LocalizedString.newsScreen.buttonCaptionDeleteNews}
        editButtonCaption={LocalizedString.newsScreen.buttonCaptionEditNews}
        title={LocalizedString.newsScreen.title}
        useFullWidth
        useSendToMeAndUserNotification
        createPermissionName="NEWS_CREATE_NEWS"
        deletePermissionName="NEWS_DELETE_NEWS"
        editPermissionName="NEWS_EDIT_NEWS"
      >
        {pageMode === PAGE_MODE_VIEW && (
        <div className={classes.buttonContainer}>
          <AccentButton
            variant="text"
            className={classes.italicButton}
            disabled={downloadingDeleting || addingEditing || sendingNotifications}
            loading={sendingNotification}
            disableElevation
            caption={LocalizedString.newsScreen.buttonCaptionSendToMe}
            onClick={onSendToMePressed}
            size="small"
          />
          <AccentButton
            variant="text"
            className={classes.italicButton}
            disabled={downloadingDeleting || addingEditing || sendingNotification}
            loading={sendingNotifications}
            disableElevation
            caption={LocalizedString.newsScreen.buttonCaptionSendToUsers}
            onClick={() => onSendNotificationsPressed(initialValues.visibilityStatus)}
            size="small"
          />
        </div>
        )}

        {renderDialogContent(initialValues, categories, addingEditing, copying, downloadingDeleting,
          loadingCategory, onAddMediaPressed, onAddMediaMenuSelected, onCategoryOptionSelected,
          onChangeCategoryText, onChangeContentText, onChangeShortDescriptionText, onCopyPressed,
          onDeleteMediaPressed, onHeadlineOptionSelected, onVisibilityStatusOptionSelected,
          shortDescriptionLength, discusId, pageMode, selectedAddMediaMenu)}
      </FunctionalPage>
    </div>
  );
};

export default reduxForm({
  form: RXFORM_NEWS,
  validate: rxformValidateNews,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(NewsPage);

NewsPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  categories: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  copying: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingCategory: PropTypes.bool.isRequired,
  sendingNotification: PropTypes.bool.isRequired,
  sendingNotifications: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAddMediaPressed: PropTypes.func.isRequired,
  onAddMediaMenuSelected: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onCategoryOptionSelected: PropTypes.func.isRequired,
  onChangeCategoryText: PropTypes.func.isRequired,
  onChangeContentText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeShortDescriptionText: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCopyPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeleteMediaPressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onHeadlineOptionSelected: PropTypes.func.isRequired,
  onOpenSendNotificationDialog: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSendNotificationsPressed: PropTypes.func.isRequired,
  onSendToMePressed: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onVisibilityStatusOptionSelected: PropTypes.func.isRequired,
  shortDescriptionLength: PropTypes.number.isRequired,
  discusId: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  selectedAddMediaMenu: PropTypes.string,
};

NewsPage.defaultProps = {
  selectedAddMediaMenu: null,
};
