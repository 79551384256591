import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_SESSION_OPERATOR_MAPPINGS = 'DOWNLOADING_SESSION_OPERATOR_MAPPINGS';
export const downloadingSessionOperatorMappings = makeActionCreator(DOWNLOADING_SESSION_OPERATOR_MAPPINGS, 'status');

export const SET_SESSION_OPERATOR_MAPPINGS = 'SET_SESSION_OPERATOR_MAPPINGS';
export const setSessionOperatorMappings = makeActionCreator(SET_SESSION_OPERATOR_MAPPINGS, 'data');

export const ADD_SESSION_OPERATOR_MAPPING = 'ADD_SESSION_OPERATOR_MAPPING';
export const addSessionOperatorMapping = makeActionCreator(ADD_SESSION_OPERATOR_MAPPING, 'data');

export const CLEAR_SESSION_OPERATOR_MAPPINGS = 'CLEAR_SESSION_OPERATOR_MAPPINGS';
export const clearSessionOperatorMappings = makeActionCreator(CLEAR_SESSION_OPERATOR_MAPPINGS);

export const ADDING_EDITING_SESSION_OPERATOR_MAPPING = 'ADDING_EDITING_SESSION_OPERATOR_MAPPING';
export const addingEditingSessionOperatorMapping = makeActionCreator(ADDING_EDITING_SESSION_OPERATOR_MAPPING, 'status');

export const DOWNLOADING_DELETING_SESSION_OPERATOR_MAPPING = 'DOWNLOADING_DELETING_SESSION_OPERATOR_MAPPING';
export const downloadingDeletingSessionOperatorMapping = makeActionCreator(DOWNLOADING_DELETING_SESSION_OPERATOR_MAPPING, 'status');

export const ENABLING_DISABLING_SESSION_OPERATOR_MAPPING = 'ENABLING_DISABLING_SESSION_OPERATOR_MAPPING';
export const enablingDisablingSessionOperatorMapping = makeActionCreator(ENABLING_DISABLING_SESSION_OPERATOR_MAPPING, 'status');


export const SET_SESSION_OPERATOR_MAPPING_SELECTED_PAGE_SIZE = 'SET_SESSION_OPERATOR_MAPPING_SELECTED_PAGE_SIZE';
export const setSessionOperatorMappingSelectedPageSize = makeActionCreator(SET_SESSION_OPERATOR_MAPPING_SELECTED_PAGE_SIZE, 'size');

export const SET_SESSION_OPERATOR_MAPPING_SELECTED_ORDER_BY = 'SET_SESSION_OPERATOR_MAPPING_SELECTED_ORDER_BY';
export const setSessionOperatorMappingSelectedOrderBy = makeActionCreator(SET_SESSION_OPERATOR_MAPPING_SELECTED_ORDER_BY, 'order');

export const SET_SESSION_OPERATOR_MAPPING_TAPPED_ID = 'SET_SESSION_OPERATOR_MAPPING_TAPPED_ID';
export const setSessionOperatorMappingTappedId = makeActionCreator(SET_SESSION_OPERATOR_MAPPING_TAPPED_ID, 'id');

export const SET_SESSION_OPERATOR_MAPPING_SEARCH_TEXT = 'SET_SESSION_OPERATOR_MAPPING_SEARCH_TEXT';
export const setSessionOperatorMappingSearchText = makeActionCreator(SET_SESSION_OPERATOR_MAPPING_SEARCH_TEXT, 'text');

export const SET_SESSION_OPERATOR_MAPPING_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_SESSION_OPERATOR_MAPPING_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setSessionOperatorMappingAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_SESSION_OPERATOR_MAPPING_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_SESSION_OPERATOR_MAPPING_SELECTED_SESSION = 'SET_SESSION_OPERATOR_MAPPING_SELECTED_SESSION';
export const setSessionOperatorMappingSelectedSession = makeActionCreator(SET_SESSION_OPERATOR_MAPPING_SELECTED_SESSION, 'option');

export const SET_SESSION_OPERATOR_MAPPING_SELECTED_USER = 'SET_SESSION_OPERATOR_MAPPING_SELECTED_USER';
export const setSessionOperatorMappingSelectedUser = makeActionCreator(SET_SESSION_OPERATOR_MAPPING_SELECTED_USER, 'option');


export const DOWNLOADING_SESSION_OPERATOR_INVITATIONS = 'DOWNLOADING_SESSION_OPERATOR_INVITATIONS';
export const downloadingSessionOperatorInvitations = makeActionCreator(DOWNLOADING_SESSION_OPERATOR_INVITATIONS, 'status');

export const SET_SESSION_OPERATOR_INVITATIONS = 'SET_SESSION_OPERATOR_INVITATIONS';
export const setSessionOperatorInvitations = makeActionCreator(SET_SESSION_OPERATOR_INVITATIONS, 'data');

export const ADD_SESSION_OPERATOR_INVITATION = 'ADD_SESSION_OPERATOR_INVITATION';
export const addSessionOperatorInvitation = makeActionCreator(ADD_SESSION_OPERATOR_INVITATION, 'data');

export const CLEAR_SESSION_OPERATOR_INVITATIONS = 'CLEAR_SESSION_OPERATOR_INVITATIONS';
export const clearSessionOperatorInvitations = makeActionCreator(
  CLEAR_SESSION_OPERATOR_INVITATIONS,
);

export const ADDING_SESSION_OPERATOR_INVITATION = 'ADDING_SESSION_OPERATOR_INVITATION';
export const addingSessionOperatorInvitation = makeActionCreator(ADDING_SESSION_OPERATOR_INVITATION, 'status');

export const DOWNLOADING_DELETING_SESSION_OPERATOR_INVITATION = 'DOWNLOADING_DELETING_SESSION_OPERATOR_INVITATION';
export const downloadingDeletingSessionOperatorInvitation = makeActionCreator(DOWNLOADING_DELETING_SESSION_OPERATOR_INVITATION, 'status');

export const SAVING_SESSION_OPERATOR_INVITATIONS = 'SAVING_SESSION_OPERATOR_INVITATIONS';
export const savingSessionOperatorInvitations = makeActionCreator(SAVING_SESSION_OPERATOR_INVITATIONS, 'status');

export const RESENDING_SESSION_OPERATOR_INVITATION = 'RESENDING_SESSION_OPERATOR_INVITATION';
export const resendingSessionOperatorInvitation = makeActionCreator(RESENDING_SESSION_OPERATOR_INVITATION, 'status');


export const SET_SESSION_OPERATOR_INVITATION_SELECTED_PAGE_SIZE = 'SET_SESSION_OPERATOR_INVITATION_SELECTED_PAGE_SIZE';
export const setSessionOperatorInvitationSelectedPageSize = makeActionCreator(SET_SESSION_OPERATOR_INVITATION_SELECTED_PAGE_SIZE, 'size');

export const SET_SESSION_OPERATOR_INVITATION_SELECTED_ORDER_BY = 'SET_SESSION_OPERATOR_INVITATION_SELECTED_ORDER_BY';
export const setSessionOperatorInvitationSelectedOrderBy = makeActionCreator(SET_SESSION_OPERATOR_INVITATION_SELECTED_ORDER_BY, 'order');

export const SET_SESSION_OPERATOR_INVITATION_TAPPED_ID = 'SET_SESSION_OPERATOR_INVITATION_TAPPED_ID';
export const setSessionOperatorInvitationTappedId = makeActionCreator(SET_SESSION_OPERATOR_INVITATION_TAPPED_ID, 'id');

export const SET_SESSION_OPERATOR_INVITATION_SEARCH_TEXT = 'SET_SESSION_OPERATOR_INVITATION_SEARCH_TEXT';
export const setSessionOperatorInvitationSearchText = makeActionCreator(SET_SESSION_OPERATOR_INVITATION_SEARCH_TEXT, 'text');

export const SET_SESSION_OPERATOR_INVITATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_SESSION_OPERATOR_INVITATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setSessionOperatorInvitationAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_SESSION_OPERATOR_INVITATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_SESSION_OPERATOR_INVITATION_SELECTED_SESSION_ID = 'SET_SESSION_OPERATOR_INVITATION_SELECTED_SESSION_ID';
export const setSessionOperatorInvitationSelectedSessionId = makeActionCreator(SET_SESSION_OPERATOR_INVITATION_SELECTED_SESSION_ID, 'id');

export const SET_SESSION_OPERATOR_INVITATION_SELECTED_RECIPIENT = 'SET_SESSION_OPERATOR_INVITATION_SELECTED_RECIPIENT';
export const setSessionOperatorInvitationSelectedRecipient = makeActionCreator(SET_SESSION_OPERATOR_INVITATION_SELECTED_RECIPIENT, 'data');


export const DOWNLOADING_SESSION_OPERATOR_SESSIONS = 'DOWNLOADING_SESSION_OPERATOR_SESSIONS';
export const downloadingSessionOperatorSessions = makeActionCreator(DOWNLOADING_SESSION_OPERATOR_SESSIONS, 'status');

export const SET_SESSION_OPERATOR_SESSIONS = 'SET_SESSION_OPERATOR_SESSIONS';
export const setSessionOperatorSessions = makeActionCreator(SET_SESSION_OPERATOR_SESSIONS, 'data');

export const ADD_SESSION_OPERATOR_SESSION = 'ADD_SESSION_OPERATOR_SESSION';
export const addSessionOperatorSession = makeActionCreator(ADD_SESSION_OPERATOR_SESSION, 'data');

export const CLEAR_SESSION_OPERATOR_SESSIONS = 'CLEAR_SESSION_OPERATOR_SESSIONS';
export const clearSessionOperatorSessions = makeActionCreator(CLEAR_SESSION_OPERATOR_SESSIONS);

export const DOWNLOADING_DELETING_SESSION_OPERATOR_SESSION = 'DOWNLOADING_DELETING_SESSION_OPERATOR_SESSION';
export const downloadingDeletingSessionOperatorSession = makeActionCreator(DOWNLOADING_DELETING_SESSION_OPERATOR_SESSION, 'status');

export const COPYING_SESSION_OPERATOR_SESSION_QR_CODE = 'COPYING_SESSION_OPERATOR_SESSION_QR_CODE';
export const copyingSessionOperatorSessionQrCode = makeActionCreator(COPYING_SESSION_OPERATOR_SESSION_QR_CODE, 'status');


export const SET_SESSION_OPERATOR_SESSION_SELECTED_PAGE_SIZE = 'SET_SESSION_OPERATOR_SESSION_SELECTED_PAGE_SIZE';
export const setSessionOperatorSessionSelectedPageSize = makeActionCreator(SET_SESSION_OPERATOR_SESSION_SELECTED_PAGE_SIZE, 'size');

export const SET_SESSION_OPERATOR_SESSION_SELECTED_ORDER_BY = 'SET_SESSION_OPERATOR_SESSION_SELECTED_ORDER_BY';
export const setSessionOperatorSessionSelectedOrderBy = makeActionCreator(SET_SESSION_OPERATOR_SESSION_SELECTED_ORDER_BY, 'order');

export const SET_SESSION_OPERATOR_SESSION_TAPPED_ID = 'SET_SESSION_OPERATOR_SESSION_TAPPED_ID';
export const setSessionOperatorSessionTappedId = makeActionCreator(SET_SESSION_OPERATOR_SESSION_TAPPED_ID, 'id');

export const SET_SESSION_OPERATOR_SESSION_SEARCH_TEXT = 'SET_SESSION_OPERATOR_SESSION_SEARCH_TEXT';
export const setSessionOperatorSessionSearchText = makeActionCreator(SET_SESSION_OPERATOR_SESSION_SEARCH_TEXT, 'text');

export const SET_SESSION_OPERATOR_SESSION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_SESSION_OPERATOR_SESSION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setSessionOperatorSessionAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_SESSION_OPERATOR_SESSION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_SESSION_OPERATOR_REGISTRATIONS = 'DOWNLOADING_SESSION_OPERATOR_REGISTRATIONS';
export const downloadingSessionOperatorRegistrations = makeActionCreator(DOWNLOADING_SESSION_OPERATOR_REGISTRATIONS, 'status');

export const SET_SESSION_OPERATOR_REGISTRATIONS = 'SET_SESSION_OPERATOR_REGISTRATIONS';
export const setSessionOperatorRegistrations = makeActionCreator(SET_SESSION_OPERATOR_REGISTRATIONS, 'data');

export const ADD_SESSION_OPERATOR_REGISTRATION = 'ADD_SESSION_OPERATOR_REGISTRATION';
export const addSessionOperatorRegistration = makeActionCreator(ADD_SESSION_OPERATOR_REGISTRATION, 'data');

export const CLEAR_SESSION_OPERATOR_REGISTRATIONS = 'CLEAR_SESSION_OPERATOR_REGISTRATIONS';
export const clearSessionOperatorRegistrations = makeActionCreator(
  CLEAR_SESSION_OPERATOR_REGISTRATIONS,
);

export const DOWNLOADING_DELETING_SESSION_OPERATOR_REGISTRATION = 'DOWNLOADING_DELETING_SESSION_OPERATOR_REGISTRATION';
export const downloadingDeletingSessionOperatorRegistration = makeActionCreator(DOWNLOADING_DELETING_SESSION_OPERATOR_REGISTRATION, 'status');

export const APPROVING_SESSION_OPERATOR_REGISTRATION = 'APPROVING_SESSION_OPERATOR_REGISTRATION';
export const approvingSessionOperatorRegistration = makeActionCreator(APPROVING_SESSION_OPERATOR_REGISTRATION, 'status');

export const REJECTING_SESSION_OPERATOR_REGISTRATION = 'REJECTING_SESSION_OPERATOR_REGISTRATION';
export const rejectingSessionOperatorRegistration = makeActionCreator(REJECTING_SESSION_OPERATOR_REGISTRATION, 'status');

export const SAVING_SESSION_OPERATOR_REGISTRATIONS = 'SAVING_SESSION_OPERATOR_REGISTRATIONS';
export const savingSessionOperatorRegistrations = makeActionCreator(SAVING_SESSION_OPERATOR_REGISTRATIONS, 'status');


export const SET_SESSION_OPERATOR_REGISTRATION_SELECTED_PAGE_SIZE = 'SET_SESSION_OPERATOR_REGISTRATION_SELECTED_PAGE_SIZE';
export const setSessionOperatorRegistrationSelectedPageSize = makeActionCreator(SET_SESSION_OPERATOR_REGISTRATION_SELECTED_PAGE_SIZE, 'size');

export const SET_SESSION_OPERATOR_REGISTRATION_SELECTED_ORDER_BY = 'SET_SESSION_OPERATOR_REGISTRATION_SELECTED_ORDER_BY';
export const setSessionOperatorRegistrationSelectedOrderBy = makeActionCreator(SET_SESSION_OPERATOR_REGISTRATION_SELECTED_ORDER_BY, 'order');

export const SET_SESSION_OPERATOR_REGISTRATION_TAPPED_ID = 'SET_SESSION_OPERATOR_REGISTRATION_TAPPED_ID';
export const setSessionOperatorRegistrationTappedId = makeActionCreator(SET_SESSION_OPERATOR_REGISTRATION_TAPPED_ID, 'id');

export const SET_SESSION_OPERATOR_REGISTRATION_SEARCH_TEXT = 'SET_SESSION_OPERATOR_REGISTRATION_SEARCH_TEXT';
export const setSessionOperatorRegistrationSearchText = makeActionCreator(SET_SESSION_OPERATOR_REGISTRATION_SEARCH_TEXT, 'text');

export const SET_SESSION_OPERATOR_REGISTRATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_SESSION_OPERATOR_REGISTRATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setSessionOperatorRegistrationAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_SESSION_OPERATOR_REGISTRATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');
