import { connect } from 'react-redux';
import {
  PAGE_MODE_TABLE,
  INITIAL_ORDER_BY_PAYMENTS,
  MENUID_PAYMENT_PAYMENT,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
} from '../../constant';
import {
  getPermission,
  toMoment,
  transformInitialValues,
} from '../../helper';
import {
  setActiveSideMenuItem,
  setAlertErrorMessage,
  setPaymentSearchText,
  clearPayments,
  setPaymentSelectedPageSize,
  setPaymentSelectedOrderBy,
  setPaymentAdvancedFilterDialogSelectedFilterString,
  setPaymentTappedId,
  downloadPaymentsAsync,
  downloadPaymentAsync,
  republishPaymentAsync,
  setAlertConfirmationMessage,
} from '../../redux/action';
import PaymentPage from './payment.presentation';
import LocalizedString from '../../localization';

const getInitialValues = (state) => {
  const {
    payments,
    uiFunctionalPage,
    uiPayment,
  } = state;
  const { data } = payments;
  const { downloadingDeleting, tappedId } = uiPayment;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      validUntil: found?.validUntil ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
      lastChangedStatus: found?.lastChangedStatus ? toMoment(found.lastChangedStatus).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    })
    : {};
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiPayment.downloading,
  downloadingDeleting: state.uiPayment.downloadingDeleting,
  isRepublishAllowed: getPermission(state, 'PAYMENT_REPUBLISH_PAYMENT'),
  initialValues: getInitialValues(state),
  tappedId: state.uiFunctionalPage.tappedId || '',
  republishing: state.uiPayment.republishing,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_PAYMENT_PAYMENT));
    dispatch(setPaymentSearchText(''));
    dispatch(clearPayments());
    dispatch(setPaymentSelectedPageSize(20));
    dispatch(
      setPaymentSelectedOrderBy(
        INITIAL_ORDER_BY_PAYMENTS,
      ),
    );
    dispatch(downloadPaymentsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('registeredUser', 'profile.id');

    dispatch(
      setPaymentAdvancedFilterDialogSelectedFilterString(text),
    );
    dispatch(clearPayments());
    dispatch(downloadPaymentsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadPaymentsAsync(pageNo + 1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setPaymentSelectedPageSize(pageSize));
    dispatch(downloadPaymentsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setPaymentSelectedPageSize(pageSize));
    dispatch(clearPayments());
    dispatch(downloadPaymentsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearPayments());
    dispatch(
      setPaymentAdvancedFilterDialogSelectedFilterString(''),
    );
    dispatch(
      setPaymentSelectedOrderBy(
        INITIAL_ORDER_BY_PAYMENTS,
      ),
    );
    dispatch(downloadPaymentsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setPaymentSearchText(text));
      dispatch(clearPayments());
      await dispatch(downloadPaymentsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setPaymentSelectedOrderBy(orderBy));
    dispatch(clearPayments());
    dispatch(downloadPaymentsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setPaymentTappedId(id));
    dispatch(downloadPaymentAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRepublishPressed: (id) => {
    dispatch(setPaymentTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.paymentPage.msgConfirmationRepublish));
  },
  onConfirmPressed: () => {
    dispatch(republishPaymentAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentPage);
