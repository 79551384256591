import { downloadSessionRooms } from '../../../helper';
import { downloadingSessionRooms, setSessionRooms } from '../simple-action';

export default (eventId) => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingSessionRooms(true));

    const list = await downloadSessionRooms(eventId, token);

    if (list) {
      dispatch(setSessionRooms(list));
    }
  } finally {
    dispatch(downloadingSessionRooms(false));
  }
};
