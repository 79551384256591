import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_VOUCHER_USAGES, MENUID_VOUCHER_USAGE, PAGE_MODE_TABLE,
  RXFORM_VOUCHER_USAGE,
} from '../../constant';
import {
  transformInitialValues, transformDropdownData,
} from '../../helper';
import {
  clearVoucherUsages,
  setActiveSideMenuItem,
  setAlertErrorMessage,
  setVoucherUsageAdvancedFilterDialogSelectedFilterString,
  setVoucherUsageSearchText, setVoucherUsageSelectedOrderBy,
  setVoucherUsageSelectedPageSize, downloadVoucherUsagesAsync,
  setCompanyAdvancedFilterDialogSelectedFilterStringMData,
  setCompanySearchTextMData, setVoucherCategorySearchText,
  clearCompaniesMData, clearVoucherCategories, downloadVoucherCategoriesAsync,
  setVoucherUsageSelectedMerchantId, setVoucherUsageSelectedVoucherCategoryId,
  setVoucherUsageSelectedApplicableBranches, setVoucherUsageTableDataApplicableBranches,
  setVoucherUsageSelectedPaymentType, setVoucherUsageSelectedTags,
  setVoucherUsageSelectedTanameraFlag, setVoucherUsageSelectedGarudaFlag,
  saveVoucherUsagesAsync, setVoucherCategoryAdvancedFilterDialogSelectedFilterString,
} from '../../redux/action';
import {
  downloadMDataCompaniesAsync, setBranchSearchText, clearBranches,
} from '../../../../redux/action';
import VoucherUsagePage from './voucher-usage.presentation';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getInitialValues = (state) => {
  const {
    voucherUsages, uiVoucherUsage, uiFunctionalPage,
  } = state;
  const { data } = voucherUsages;
  const { tappedId } = uiVoucherUsage;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
  }) : {
    title: '',
    tokenCount: '',
    availableTokenCount: '',
    purchasedTokenCount: '',
    claimedTokenCount: '',
    revokedTokenCount: '',
    expiredTokenCount: '',
    merchant: { label: '', value: '' },
    status: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  merchants: transformDropdownData(state.companiesMdata.data),
  voucherCategories: transformDropdownData(state.voucherCategories.data),
  initialValues: getInitialValues(state),
  selectedPageSize: state.uiVoucherUsage.selectedPageSize,
  pageMode: state.uiFunctionalPage.pageMode,
  downloading: state.uiVoucherUsage.downloading,
  downloadingDeleting: state.uiVoucherUsage.downloadingDeleting,
  tappedId: state.uiFunctionalPage.tappedId || '',
  loadingMerchant: state.uiCompany.downloading,
  loadingVoucherCategory: state.uiVoucherCategory.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData(''));
    dispatch(setVoucherCategoryAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setCompanySearchTextMData(''));
    dispatch(setVoucherCategorySearchText(''));
    dispatch(clearCompaniesMData());
    dispatch(clearVoucherCategories());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadVoucherCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_VOUCHER_USAGE));
    dispatch(setVoucherUsageSearchText(''));
    dispatch(setVoucherUsageAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearVoucherUsages());
    dispatch(setVoucherUsageSelectedPageSize(20));
    dispatch(setVoucherUsageSelectedOrderBy(INITIAL_ORDER_BY_VOUCHER_USAGES));
    dispatch(downloadVoucherUsagesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/merchant/, 'merchant.id').replace(/category/, 'category.id');
    dispatch(setVoucherUsageAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearVoucherUsages());
    dispatch(downloadVoucherUsagesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_VOUCHER_USAGE));
    dispatch(setVoucherUsageSelectedMerchantId(''));
    dispatch(setVoucherUsageSelectedVoucherCategoryId(''));
    dispatch(setVoucherUsageSelectedApplicableBranches(null));
    dispatch(setVoucherUsageTableDataApplicableBranches([]));
    dispatch(setVoucherUsageSelectedPaymentType(''));
    dispatch(setVoucherUsageSelectedTags(''));
    dispatch(setVoucherUsageSelectedTanameraFlag(''));
    dispatch(setVoucherUsageSelectedGarudaFlag(''));
  },
  onChangeCategoryText: async (text) => {
    try {
      dispatch(setVoucherCategoryAdvancedFilterDialogSelectedFilterString(''));
      dispatch(setVoucherCategorySearchText(text));
      dispatch(clearVoucherCategories());
      await dispatch(downloadVoucherCategoriesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeMerchantText: async (text) => {
    try {
      if (!text) {
        dispatch(setBranchSearchText(''));
        dispatch(clearBranches());
        dispatch(setVoucherUsageSelectedApplicableBranches(null));
      }
      dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData(''));
      dispatch(setCompanySearchTextMData(text));
      dispatch(clearCompaniesMData());
      await dispatch(downloadMDataCompaniesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadVoucherUsagesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setVoucherUsageSelectedPageSize(pageSize));
    dispatch(downloadVoucherUsagesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDownloadPressed: () => {
    dispatch(saveVoucherUsagesAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setVoucherUsageSelectedPageSize(pageSize));
    dispatch(clearVoucherUsages());
    dispatch(downloadVoucherUsagesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setVoucherUsageAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearVoucherUsages());
    dispatch(setVoucherUsageSelectedOrderBy(INITIAL_ORDER_BY_VOUCHER_USAGES));
    dispatch(downloadVoucherUsagesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setVoucherUsageSearchText(text));
      dispatch(clearVoucherUsages());
      await dispatch(downloadVoucherUsagesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setVoucherUsageSelectedOrderBy(orderBy));
    dispatch(clearVoucherUsages());
    dispatch(downloadVoucherUsagesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VoucherUsagePage);
