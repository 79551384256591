import { downloadingReferrers, setReferrers } from '../simple-action';
import { downloadUsers, transformSearchText } from '../../../helper';
import { INITIAL_ORDER_BY_USERS } from '../../../constant';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, referrers, uiUser } = getState();
  const { token } = authentication;
  const { meta } = referrers;
  const { searchBarTextReferrer } = uiUser;

  try {
    dispatch(downloadingReferrers(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadUsers(
      pageToBeDownloaded,
      20,
      INITIAL_ORDER_BY_USERS,
      transformSearchText(searchBarTextReferrer),
      '',
      token,
    );

    if (list) {
      dispatch(setReferrers(list));
    }
  } finally {
    dispatch(downloadingReferrers(false));
  }
};
