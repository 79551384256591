import {
  addSponsor, clearSponsors, downloadingDeletingSponsor,
  setSponsorTappedId, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { deleteSponsor, downloadSponsor } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadSponsorsAsync from './downloadSponsorsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingSponsor(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSponsor;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteSponsor(tappedId, token);
      dispatch(setSponsorTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearSponsors());
      dispatch(downloadSponsorsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const sponsor = await downloadSponsor(tappedId, token);
      dispatch(addSponsor(sponsor));
    }
  } finally {
    dispatch(downloadingDeletingSponsor(false));
  }
};
