import { Dialog, makeStyles } from '@material-ui/core';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Webcam from 'react-webcam';
import PropTypes from 'prop-types';
import AccentButton from './accent-button';
import {
  BUTTON_TYPE_CONTAINED, BUTTON_TYPE_TEXT, CAMERA_COMPONENT_FACING_MODE_USER,
  CAMERA_COMPONENT_HEIGHT, CAMERA_COMPONENT_SCREENSHOT_FORMAT_JPEG, CAMERA_COMPONENT_WIDTH,
} from '../constant';
import LocalizedString from '../localization';

const useStyles = makeStyles(() => ({
  paper: {
    width: '400px',
    padding: '16px',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const styles = {
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '5px',
    marginTop: '20px',
  },
};

const SelfieModal = ({
  isOpen, onPhotoTaken, onCancelPressed, uploading,
}) => {
  const webcamRef = React.useRef(null);
  const classes = useStyles();

  const videoConstraints = {
    width: CAMERA_COMPONENT_WIDTH,
    height: CAMERA_COMPONENT_HEIGHT,
    facingMode: CAMERA_COMPONENT_FACING_MODE_USER,
  };

  const onCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    onPhotoTaken(imageSrc);
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="responsive-dialog-title"
      classes={{ paper: classes.paper }}
      PaperProps={{ style: { borderRadius: 16 } }}
    >
      <Webcam
        audio={false}
        height={CAMERA_COMPONENT_HEIGHT}
        screenshotFormat={CAMERA_COMPONENT_SCREENSHOT_FORMAT_JPEG}
        width={CAMERA_COMPONENT_WIDTH}
        videoConstraints={videoConstraints}
        ref={webcamRef}
      />

      <div style={styles.column}>
        <AccentButton
          variant={BUTTON_TYPE_CONTAINED}
          onClick={onCapture}
          caption={LocalizedString.common.buttonCaptionTakePhoto}
          loading={uploading}
        />
        <AccentButton
          variant={BUTTON_TYPE_TEXT}
          onClick={onCancelPressed}
          caption={LocalizedString.common.buttonCaptionCancel}
        />
      </div>
    </Dialog>
  );
};

export default SelfieModal;

SelfieModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  uploading: PropTypes.bool,
  onPhotoTaken: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
};

SelfieModal.defaultProps = {
  uploading: false,
};
