import { reset } from 'redux-form';
import {
  COMMENT_ACCESS_EVERYONE, EVENT_TYPE_SIMPLE, PAGE_MODE_TABLE, RXFORM_EVENT,
} from '../../../constant';
import {
  addEvent, editEvent, localDateToUtc, toNumber, transformAdditionalItemsPayload,
  transformLocalImagePath,
} from '../../../helper';
import {
  addingEditingEvent, clearEvents, setAlertErrorMessage, setEventBannerImage,
  setEventCanPayUsingPointSelected,
  setEventCategorySelected, setEventClockInAreaSelected, setEventEventTypeSelected,
  setEventFeatureSelected, setEventLocationSelected, setEventNeedApprovalSelected,
  setEventRewardTypeSelected, setEventSelectedSpeakers, setEventTableDataSpeakers,
  setEventVisibilityStatusSelected, setEventVoucherSelected,
  setEventWhoCanRegisterSelected, setFunctionalPageMode,
} from '../simple-action';
import downloadEventsAsync from './downloadEventsAsync';

const transformMedia = (data) => data.map((x) => {
  if (Object.prototype.hasOwnProperty.call(x, 'type')) {
    return ({
      id: x.id || '',
      order: toNumber(x.order),
      type: x.type,
      url: x.type === 'Youtube' ? x.path : '',
    });
  }
  return ({
    id: x.id || '',
    order: toNumber(x.order),
    type: Object.prototype.hasOwnProperty.call(x, 'content') ? x.content.type : x.link.type,
    url: Object.prototype.hasOwnProperty.call(x, 'link') && x.link.type === 'Youtube' ? x.link.path : '',
    image: Object.prototype.hasOwnProperty.call(x, 'content') && x.content.type === 'Image' ? x.content.path : '',
  });
});

const getSpeakers = (speakers, found) => (speakers?.length > 0 ? speakers.map((x, i) => (
  {
    order: i + 1,
    id: x.value || x.speaker?.id,
  }
)) : found.speakers.map((x) => ({
  order: x.order,
  id: x.speaker.id,
})));

export default (name,
  shortDescription,
  description,
  startTime,
  endTime,
  earliestClockInBeforeMinutes,
  maxClockInMinutes,
  categoryId,
  location,
  clockInMustBeInsideArea,
  eventType,
  visibilityStatus,
  whoCanRegister,
  needApproval,
  quota,
  isFeatured,
  featuredOrder,
  normalPrice,
  price,
  canPayUsingPoint,
  registrationRewardType,
  registrationRewardPoint,
  registrationRewardVoucherIds,
  media,
  commentAccess,
  additionalItems,
  speakers = [],
  surveyForm = '',
  banner) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingEvent(true));

    const { token } = getState().authentication;
    const { data } = getState().events;
    const {
      tappedId, locationIds, selectedEnableComment,
    } = getState().uiEvent;
    const found = data && data[tappedId];

    const registrationRewardVoucherId = registrationRewardVoucherIds.reduce((acc, obj) => {
      acc.push(obj.value);
      return acc;
    }, []);
    const medias = media?.length > 0 ? transformMedia(media) : [];
    const selectedCategoryId = categoryId || found?.category.id;
    const selectedCommentAccess = selectedEnableComment.value
      ? commentAccess : COMMENT_ACCESS_EVERYONE;
    const selectedAdditionalItems = transformAdditionalItemsPayload(additionalItems);
    const speakersName = eventType === EVENT_TYPE_SIMPLE ? getSpeakers(speakers, found) : [];
    const surveyFormId = eventType === EVENT_TYPE_SIMPLE ? surveyForm?.value || '' : '';

    if (tappedId) {
      await editEvent(tappedId,
        name,
        shortDescription,
        description,
        localDateToUtc(startTime),
        localDateToUtc(endTime),
        toNumber(earliestClockInBeforeMinutes),
        toNumber(maxClockInMinutes),
        selectedCategoryId,
        locationIds,
        clockInMustBeInsideArea,
        eventType,
        visibilityStatus,
        whoCanRegister,
        needApproval,
        toNumber(quota),
        isFeatured,
        toNumber(featuredOrder),
        toNumber(normalPrice),
        price,
        canPayUsingPoint,
        registrationRewardType,
        toNumber(registrationRewardPoint),
        registrationRewardVoucherId,
        medias, selectedEnableComment.value,
        selectedCommentAccess,
        selectedAdditionalItems,
        speakersName,
        surveyFormId,
        transformLocalImagePath(banner),
        token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addEvent(name,
        shortDescription,
        description,
        localDateToUtc(startTime),
        localDateToUtc(endTime),
        toNumber(earliestClockInBeforeMinutes),
        toNumber(maxClockInMinutes),
        selectedCategoryId,
        locationIds,
        clockInMustBeInsideArea,
        eventType,
        visibilityStatus,
        whoCanRegister,
        needApproval,
        toNumber(quota),
        isFeatured,
        toNumber(featuredOrder),
        toNumber(normalPrice),
        price,
        canPayUsingPoint,
        registrationRewardType,
        toNumber(registrationRewardPoint),
        registrationRewardVoucherId,
        medias,
        selectedEnableComment.value,
        selectedCommentAccess,
        selectedAdditionalItems,
        speakersName,
        surveyFormId,
        transformLocalImagePath(banner),
        token);
    }

    dispatch(reset(RXFORM_EVENT));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setEventBannerImage(''));
    dispatch(setEventCanPayUsingPointSelected(false));
    dispatch(setEventCategorySelected(''));
    dispatch(setEventClockInAreaSelected(false));
    dispatch(setEventEventTypeSelected(''));
    dispatch(setEventFeatureSelected(false));
    dispatch(setEventLocationSelected(''));
    dispatch(setEventNeedApprovalSelected(false));
    dispatch(setEventRewardTypeSelected(''));
    dispatch(setEventVisibilityStatusSelected(''));
    dispatch(setEventVoucherSelected([]));
    dispatch(setEventWhoCanRegisterSelected(''));
    dispatch(setEventTableDataSpeakers([]));
    dispatch(setEventSelectedSpeakers(null));
  } finally {
    dispatch(addingEditingEvent(false));
  }
};
