import {
  REST_URL_POINTS, REST_URL_VIEW_DELETE_POINT, REST_URL_POINT_SUBMISSIONS,
  REST_URL_VIEW_DELETE_POINT_SUBMISSION, REST_URL_POINT_TRANSACTIONS,
  REST_URL_VIEW_DELETE_POINT_TRANSACTION, REST_URL_BALANCE, REST_URL_ACHIEVEMENT,
  REST_URL_VIEW_PURCHASE_REQUEST,
  REST_URL_PURCHASE_REQUESTS, REST_URL_VOID_POINT_REQUESTS, REST_URL_VIEW_VOID_POINT_REQUEST,
  REST_URL_ADD_VOID_POINT_REQUEST, REST_URL_LEADER_BOARD, REST_URL_REPROCESS_POINT_SUBMISSION,
  POINT_SUBMISSION_REQUEST_STATUS_ERROR, REST_URL_ADD_POINT_SUBMISSION,
} from './constant';
import {
  buildQueryParams, localDateToUtc, sendGetRequest,
  sendPostRequest, transformQueryResult,
} from '../../helper';

export * from '../../helper';

export const downloadPoints = async (pageNumber, pageSize, orderBy, searchText, filterString = '',
  token) => {
  const url = buildQueryParams(REST_URL_POINTS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const responseDataWithHideContextMenu = response.data.map((x) => ({
    ...x,
    hideContextMenu: x.isVoided !== false,
  }));
  return transformQueryResult({ ...response, data: responseDataWithHideContextMenu });
};

export const downloadPoint = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_POINT.replace(/\{id\}/, id), token);
  return response;
};

export const downloadPointSubmissions = async (pageNumber, pageSize, orderBy, searchText,
  filterString = '', token) => {
  const url = buildQueryParams(REST_URL_POINT_SUBMISSIONS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);

  const responseDataWithHideContextMenu = response.data.map((x) => ({
    ...x,
    hideContextMenu: x.requestStatus !== POINT_SUBMISSION_REQUEST_STATUS_ERROR,
  }));
  return transformQueryResult({ ...response, data: responseDataWithHideContextMenu });
};

export const downloadPointSubmission = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_POINT_SUBMISSION.replace(/\{id\}/, id),
    token);
  return response;
};

export const reprocessPointSubmission = async (id, token) => {
  await sendPostRequest(REST_URL_REPROCESS_POINT_SUBMISSION.replace(/{id}/, id), '', token);
};

export const addPointSubmission = async (
  merchantReferenceId,
  tags,
  amount,
  validUntil,
  label,
  userId,
  callbackUrl,
  sendNotification, token,
) => {
  const body = {
    merchantReferenceId,
    tags,
    amount,
    validUntil,
    label,
    userId,
    callbackUrl,
    sendNotification,
  };
  await sendPostRequest(REST_URL_ADD_POINT_SUBMISSION, body, token);
};

export const downloadPointTransactions = async (pageNumber, pageSize, orderBy, searchText,
  filterString = '', token) => {
  const url = buildQueryParams(REST_URL_POINT_TRANSACTIONS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadPointTransaction = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_POINT_TRANSACTION.replace(/\{id\}/, id),
    token);
  return response;
};

export const downloadAchievement = async (id, token) => {
  const response = await sendGetRequest(REST_URL_ACHIEVEMENT.replace(/\{id\}/, id), token);
  return response;
};

export const downloadBalance = async (id, token) => {
  const response = await sendGetRequest(REST_URL_BALANCE.replace(/\{id\}/, id), token);
  return response;
};

export const downloadLeaderBoard = async (startRank, count, startPeriod, endPeriod, token) => {
  const START_PERIOD_KEY = startPeriod ? `&startPeriod=${localDateToUtc(startPeriod)}` : '';
  const END_PERIOD_KEY = endPeriod ? `&endPeriod=${localDateToUtc(endPeriod)}` : '';
  const url = REST_URL_LEADER_BOARD.replace(/\{startRank\}/, startRank).replace(/\{count\}/, count)
    .concat(START_PERIOD_KEY).concat(END_PERIOD_KEY);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadPurchaseRequests = async (pageNumber, pageSize, orderBy, searchText,
  filterString = '', token) => {
  const url = buildQueryParams(REST_URL_PURCHASE_REQUESTS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadPurchaseRequest = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_PURCHASE_REQUEST.replace(/\{id\}/, id), token);
  return response;
};

export const downloadVoidPointRequests = async (pageNumber, pageSize, orderBy, searchText,
  filterString = '', token) => {
  const url = buildQueryParams(REST_URL_VOID_POINT_REQUESTS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadVoidPointRequest = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_VOID_POINT_REQUEST.replace(/\{id\}/, id), token);
  return response;
};

export const addVoidPointRequest = async (reason, merchantReferenceId, callbackUrl, pointId,
  sendNotification = false, voidingCoupon, token) => {
  const body = {
    reason,
    merchantReferenceId,
    callbackUrl,
    pointId,
  };
  const newBody = voidingCoupon ? { ...body, sendNotification } : body;
  await sendPostRequest(REST_URL_ADD_VOID_POINT_REQUEST, newBody, token);
};
