import { reset } from 'redux-form';
import { PAGE_MODE_TABLE, RXFORM_SPEAKER } from '../../../constant';
import { addSpeaker, editSpeaker, transformLocalImagePath } from '../../../helper';
import {
  addingEditingSpeaker, clearSpeakers, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import downloadSpeakersAsync from './downloadSpeakersAsync';

export default (name, description, jobTitle, companyName, image) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingSpeaker(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSpeaker;

    if (tappedId) {
      await editSpeaker(tappedId, name, description, jobTitle, companyName,
        transformLocalImagePath(image), token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addSpeaker(name, description, jobTitle, companyName,
        transformLocalImagePath(image), token);
    }

    dispatch(reset(RXFORM_SPEAKER));
    dispatch(clearSpeakers());
    dispatch(downloadSpeakersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingSpeaker(false));
  }
};
