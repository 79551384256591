import { connect } from 'react-redux';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_SESSION_OPERATOR_REGISTRATIONS,
  MENUID_SESSION_OPERATOR_REGISTRATION, PAGE_MODE_TABLE,
} from '../../constant';
import {
  debounceSearch, getPermission, getTimeSymbol, toMoment, transformDropdownData,
  transformInitialValues, transformUserDropdownData,
} from '../../helper';
import LocalizedString from '../../localization';
import {
  clearProfiles, clearSessionOperatorRegistrations, setActiveSideMenuItem, setAlertErrorMessage,
  setAlertInputMessage, setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
  setSessionOperatorRegistrationAdvancedFilterDialogSelectedFilterString,
  setSessionOperatorRegistrationSearchText, setSessionOperatorRegistrationSelectedOrderBy,
  setSessionOperatorRegistrationSelectedPageSize, setSessionOperatorRegistrationTappedId,
  approveSessionOperatorRegistrationAsync, downloadSessionOperatorRegistrationAsync,
  downloadSessionOperatorRegistrationsAsync, rejectSessionOperatorRegistrationAsync,
  saveSessionOperatorRegistrationsAsync,
} from '../../redux/action';
import { downloadProfilesAsync } from '../../../../redux/action';
import sessionOperatorRegistrationPage from './session-operator-registration.presentation';
import {
  clearSessions, downloadSessionsAsync, setSessionAdvancedFilterDialogSelectedFilterString,
  setSessionSearchText,
} from '../../../event/redux/action';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getApproveRejectedBy = (approvedRejectedBy) => (approvedRejectedBy.email
  ? `${approvedRejectedBy?.fullName} (${approvedRejectedBy?.email})`
  : approvedRejectedBy?.fullName);

const getInitialValues = (state) => {
  const { sessionOperatorRegistrations, uiSessionOperatorRegistration, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiSessionOperatorRegistration;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? sessionOperatorRegistrations.data[tappedId] : {};

  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    approvedRejectedOn: found.approvedRejectedOn ? toMoment(found.approvedRejectedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    clockInTime: found.clockInTime ? toMoment(found.clockInTime, found.session?.room?.location?.city?.province?.timeZone) : '',
    clockInStatus: found?.clockInStatus ? found?.clockInStatus : '',
    paymentChannel: found?.payment?.paymentChannel ? found?.payment?.paymentChannel : '',
    paymentStatus: found?.payment?.paymentStatus ? found?.payment?.paymentStatus : '',
    sessionStartTime: found.session.startTime ? toMoment(found.session.startTime, found.session?.room?.location?.city?.province?.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    sessionEndTime: found.session.endTime ? toMoment(found.session.endTime, found.session?.room?.location?.city?.province?.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    price: found?.price === 0
      ? LocalizedString.sessionRegistrationPage.labelFree : found?.price,
    approvedRejectedBy: found?.approvedRejectedBy ? getApproveRejectedBy(found?.approvedRejectedBy) : '',
    sessionTimeZone: getTimeSymbol(found.session?.room?.location?.city?.province?.timeZone),
  }) : {};
  return result;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  profiles: transformUserDropdownData(state.profiles.data),
  sessions: transformDropdownData(state.sessions.data),
  approving: state.uiSessionOperatorRegistration.approving,
  downloading: state.uiSessionOperatorRegistration.downloading,
  downloadingDeleting: state.uiSessionOperatorRegistration.downloadingDeleting,
  downloadingProfiles: state.uiProfile.downloading,
  downloadingSessions: state.uiSession.downloading,
  isApprovalAllowed: getPermission(state, 'EVENT_APPROVAL_SESSION_REGISTRATION_FOR_OPERATOR'),
  rejecting: state.uiSessionOperatorRegistration.rejecting,
});

const searchProfileDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchSessionDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearSessions());
    dispatch(downloadSessionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setSessionAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setSessionSearchText(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearSessions());
    dispatch(clearProfiles());
    dispatch(downloadSessionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAppear: () => {
    dispatch(setSessionOperatorRegistrationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_SESSION_OPERATOR_REGISTRATION));
    dispatch(setSessionOperatorRegistrationSearchText(''));
    dispatch(clearSessionOperatorRegistrations());
    dispatch(setSessionOperatorRegistrationSelectedPageSize(20));
    dispatch(setSessionOperatorRegistrationSelectedOrderBy(
      INITIAL_ORDER_BY_SESSION_OPERATOR_REGISTRATIONS,
    ));
    dispatch(downloadSessionOperatorRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/session/, 'session.id').replace(/participant/, 'user.id');
    dispatch(setSessionOperatorRegistrationAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearSessionOperatorRegistrations());
    dispatch(downloadSessionOperatorRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApprovePressed: () => {
    dispatch(setAlertInputMessage(LocalizedString.sessionRegistrationPage
      .msgApproveSessionOperatorRegistration,
    LocalizedString.sessionRegistrationPage.placeholderNotes));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadSessionOperatorRegistrationsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setSessionOperatorRegistrationSelectedPageSize(pageSize));
    dispatch(downloadSessionOperatorRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeSessionText: (text) => {
    dispatch(setSessionAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setSessionSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchSessionDebounce(dispatch);
    }
  },
  onChangeParticipantText: (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchProfileDebounce(dispatch);
    }
  },
  onConfirmApproveRejectPressed: (reason, message) => {
    switch (message) {
      case LocalizedString.sessionRegistrationPage.msgApproveSessionOperatorRegistration:
        dispatch(approveSessionOperatorRegistrationAsync(reason))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      case LocalizedString.sessionRegistrationPage.msgRejectSessionOperatorRegistration:
        dispatch(rejectSessionOperatorRegistrationAsync(reason))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default: break;
    }
  },
  onDownloadPressed: () => {
    dispatch(saveSessionOperatorRegistrationsAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setSessionOperatorRegistrationSelectedPageSize(pageSize));
    dispatch(clearSessionOperatorRegistrations());
    dispatch(downloadSessionOperatorRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRejectPressed: () => {
    dispatch(setAlertInputMessage(LocalizedString.sessionRegistrationPage
      .msgRejectSessionOperatorRegistration,
    LocalizedString.sessionRegistrationPage.placeholderNotes));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setSessionOperatorRegistrationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearSessionOperatorRegistrations());
    dispatch(setSessionOperatorRegistrationSelectedOrderBy(
      INITIAL_ORDER_BY_SESSION_OPERATOR_REGISTRATIONS,
    ));
    dispatch(downloadSessionOperatorRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setSessionOperatorRegistrationSearchText(text));
      dispatch(clearSessionOperatorRegistrations());
      await dispatch(downloadSessionOperatorRegistrationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setSessionOperatorRegistrationSelectedOrderBy(orderBy));
    dispatch(clearSessionOperatorRegistrations());
    dispatch(downloadSessionOperatorRegistrationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setSessionOperatorRegistrationTappedId(id));
    dispatch(downloadSessionOperatorRegistrationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(sessionOperatorRegistrationPage);
