import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  CircularProgress, Grid, InputAdornment, makeStyles, Paper, Typography,
} from '@material-ui/core';
import { Email, Person, Smartphone } from '@material-ui/icons';
import { Seal, Trophy, Wallet } from 'mdi-material-ui';
import { BasePage, TotalCountSection } from '../../component';
import {
  COLOR_PRIMARY, DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE, RXFIELD_ACHIEVEMENT_EMAIL,
  RXFIELD_ACHIEVEMENT_FULLNAME, RXFIELD_ACHIEVEMENT_PHONE, RXFIELD_ACHIEVEMENT_USER,
  RXFIELD_ACHIEVEMENT_USERNAME, RXFIELD_ACHIEVEMENT_PROFILE_PICTURE,
  RXFIELD_ACHIEVEMENT_TRANSACTION_HISTORY, RXFORM_ACHIEVEMENT,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import {
  renderReduxFormEditableTableField, renderReduxFormImageInputField,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 23,
  },
  title: {
    color: COLOR_PRIMARY,
  },
  bodyContainer: {
    padding: '13px 35px 13px 35px',
    borderRadius: 4,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
    justifyContent: 'space-between',
    marginBottom: 80,
  },
  rowContainer: {
    flexDirection: 'row',
  },
  columnContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  activityIndicatorContainer: {
    margin: 20,
    display: 'flex',
    justifyContent: 'center',
  },
  totalCounSectionContainer: {
    marginTop: 10,
  },
}));

const renderContent = (initialValues, downloadingAchievement, downloadingBalance,
  downloadingProfile, downloadingTransactionHistory, currentTransactionHistoryPage,
  totalTransactionHistoryCount, achievement, balance, rank, tappedId, classes) => {
  if (tappedId) {
    if (downloadingProfile) {
      return (<div className={classes.activityIndicatorContainer}><CircularProgress /></div>);
    }
    return (
      <Grid>
        <Grid container spacing={3}>
          <Grid item sm={2}>
            <Field
              name={RXFIELD_ACHIEVEMENT_PROFILE_PICTURE}
              component={renderReduxFormImageInputField}
              label={LocalizedString.achievementPage.placeholderProfilePicture}
              defaultValue={initialValues.profilePicture}
              disabled
            />
          </Grid>

          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_ACHIEVEMENT_USERNAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.achievementPage.placeholderUsername}
                label={LocalizedString.achievementPage.placeholderUsername}
                disabled
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_ACHIEVEMENT_FULLNAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.achievementPage.placeholderFullName}
                label={LocalizedString.achievementPage.placeholderFullName}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Person />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_ACHIEVEMENT_PHONE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.achievementPage.placeholderPhone}
                label={LocalizedString.achievementPage.placeholderPhone}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Smartphone />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_ACHIEVEMENT_EMAIL}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.achievementPage.placeholderEmail}
                label={LocalizedString.achievementPage.placeholderEmail}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <div className={classes.totalCounSectionContainer}>
          <TotalCountSection
            data={[
              {
                downloading: downloadingAchievement,
                count: achievement,
                icon: <Seal />,
                label: LocalizedString.achievementPage.labelAchievement,
              },
              {
                downloading: downloadingAchievement,
                count: rank,
                icon: <Trophy />,
                label: LocalizedString.achievementPage.labelRank,
              },
              {
                downloading: downloadingBalance,
                count: balance,
                icon: <Wallet />,
                label: LocalizedString.achievementPage.labelBalance,
              },
            ]}
          />
        </div>

        <Grid item>
          <Field
            name={RXFIELD_ACHIEVEMENT_TRANSACTION_HISTORY}
            component={renderReduxFormEditableTableField}
            label={LocalizedString.achievementPage.labelTransactionHistory}
            disabled
            loading={downloadingTransactionHistory}
            currentPage={currentTransactionHistoryPage}
            totalCount={totalTransactionHistoryCount}
            defaultValue={initialValues.transactionHistory}
            tableColumns={[
              {
                title: LocalizedString.achievementPage.labelNo, field: 'no', sorting: false, width: 40,
              },
              { title: LocalizedString.achievementPage.labelTransactionNumber, field: 'transactionNumber', sorting: false },
              { title: LocalizedString.achievementPage.labelAmount, field: 'amount', sorting: false },
              { title: LocalizedString.achievementPage.labelLabel, field: 'label', sorting: false },
              { title: LocalizedString.achievementPage.labelMerchantName, field: 'merchantName', sorting: false },
              { title: LocalizedString.achievementPage.labelTransactionType, field: 'type', sorting: false },
              {
                title: LocalizedString.achievementPage.labelDate,
                field: 'effectiveDate',
                render: ({ effectiveDate }) => effectiveDate
            && toMoment(effectiveDate).format(DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE),
                sorting: false,
              },
            ]}
          />
        </Grid>
      </Grid>
    );
  }
  return null;
};

const AchievementPage = ({
  initialValues, profiles,
  downloadingAchievement, downloadingBalance, downloadingProfiles, downloadingProfile,
  downloadingTransactionHistory,
  onAppear, onChangeUserText, onUserOptionSelected,
  currentTransactionHistoryPage, totalTransactionHistoryCount,
  achievement, balance, rank,
  tappedId,
}) => {
  const classes = useStyles();
  useEffect(onAppear, []);

  return (
    <BasePage>
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.title}>{LocalizedString.achievementPage.title}</Typography>
      </div>

      <Paper elevation={3} className={classes.bodyContainer}>
        <Grid container spacing={3}>
          <Grid item className={classes.columnContainer}>
            <Typography variant="body1">
              {LocalizedString.achievementPage.labelSelectUser}
            </Typography>
          </Grid>
          <Grid item sm md>
            <Field
              name={RXFIELD_ACHIEVEMENT_USER}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.achievementPage.placeholderFullName}
              label={LocalizedString.achievementPage.placeholderFullName}
              disabled={downloadingProfiles}
              data={profiles}
              value={initialValues.user}
              loading={downloadingProfiles}
              onChangeText={onChangeUserText}
              onOptionSelected={onUserOptionSelected}
            />
          </Grid>
        </Grid>

        {renderContent(initialValues, downloadingAchievement, downloadingBalance,
          downloadingProfile, downloadingTransactionHistory, currentTransactionHistoryPage,
          totalTransactionHistoryCount, achievement, balance, rank, tappedId, classes)}
      </Paper>
    </BasePage>
  );
};

export default reduxForm({
  form: RXFORM_ACHIEVEMENT,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AchievementPage);

AchievementPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  profiles: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloadingAchievement: PropTypes.bool.isRequired,
  downloadingBalance: PropTypes.bool.isRequired,
  downloadingProfiles: PropTypes.bool.isRequired,
  downloadingProfile: PropTypes.bool.isRequired,
  downloadingTransactionHistory: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onUserOptionSelected: PropTypes.func.isRequired,
  currentTransactionHistoryPage: PropTypes.number.isRequired,
  totalTransactionHistoryCount: PropTypes.number.isRequired,
  achievement: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  balance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  rank: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  tappedId: PropTypes.string.isRequired,
};
