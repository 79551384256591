import { sendingProfileNotification } from '../simple-action';
import { FILTERED_RECIPIENTS_ID_TAG, RECIPIENT_TYPE_PROFILE } from '../../../constant';
import { sendNotification } from '../../../helper';

export default (values) => async (dispatch, getState) => {
  try {
    dispatch(sendingProfileNotification(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiProfile;
    const {
      title, body, url, image,
    } = values;

    const filteredRecipients = `${FILTERED_RECIPIENTS_ID_TAG}${tappedId}`;

    await sendNotification(RECIPIENT_TYPE_PROFILE, encodeURIComponent(filteredRecipients), title,
      body, url, image, token);
  } finally {
    dispatch(sendingProfileNotification(false));
  }
};
