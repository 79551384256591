import { PAGE_MODE_TABLE, approval } from '../../../constant';
import { deleteChallenge, downloadChallenge } from '../../../helper';
import {
  addChallenge, clearChallenges, downloadingDeletingChallenge, setAlertErrorMessage,
  setChallengeTappedId, setFunctionalPageMode, setChallengeScanLimitTimezone,
  setChallengeSelectedCompleteReward,
  setChallengeSelectedBypassMaxPurchase,
  setChallengeSelectedBypassAchievement,
  setChallengeSelectedVoucher,
  setChallengeSelectedEnableBudget,
} from '../simple-action';
import downloadChallengesAsync from './downloadChallengesAsync';
import { getDeepLinkScheme } from '../../../../configuration/helper';

const DEEP_LINK_PATH = 'app/the-club/challenge/detail/';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingChallenge(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiChallenge;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteChallenge(tappedId, token);
      dispatch(setChallengeTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearChallenges());
      dispatch(downloadChallengesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const challenge = await downloadChallenge(tappedId, token);

      const result = await getDeepLinkScheme(challenge, DEEP_LINK_PATH, tappedId, token);

      dispatch(addChallenge(result));
      dispatch(setChallengeScanLimitTimezone(result.scanLimitTimeZone));
      dispatch(setChallengeSelectedCompleteReward(
        { label: result.completedRewardType || '', value: result.completedRewardType || '' },
      ));
      dispatch(setChallengeSelectedBypassMaxPurchase(
        {
          label: result.bypassMaxPurchaseValidation
            ? approval[0].label : approval[1].label,
          value: result.bypassMaxPurchaseValidation
            ? approval[0].value : approval[1].value,
        },
      ));
      dispatch(setChallengeSelectedBypassAchievement(
        {
          label: result.bypassAchievementValidation
            ? approval[0].label : approval[1].label,
          value: result.bypassAchievementValidation
            ? approval[0].value : approval[1].value,
        },
      ));
      dispatch(setChallengeSelectedVoucher(
        result.vouchers.length
          ? { label: result.vouchers[0].voucherName, value: result.vouchers[0].voucherId }
          : '',
      ));
      dispatch(setChallengeSelectedEnableBudget({
        label: result?.enableBudget,
        value: result?.enableBudget,
      }));
    }
  } finally {
    dispatch(downloadingDeletingChallenge(false));
  }
};
