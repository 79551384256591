import { downloadEventOperatorRegistration } from '../../../helper';
import {
  addEventOperatorRegistration, downloadingDeletingEventOperatorRegistration,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingEventOperatorRegistration(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiEventOperatorRegistration;

    const EventOperatorRegistration = await downloadEventOperatorRegistration(tappedId, token);
    dispatch(addEventOperatorRegistration(EventOperatorRegistration));
  } finally {
    dispatch(downloadingDeletingEventOperatorRegistration(false));
  }
};
