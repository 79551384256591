import { INITIAL_ORDER_BY_PAYMENTS } from '../../constant';
import {
  DOWNLOADING_PAYMENTS,
  DOWNLOADING_DELETING_PAYMENT,
  SET_PAYMENT_SELECTED_PAGE_SIZE,
  SET_PAYMENT_TAPPED_ID,
  SET_PAYMENT_SEARCH_TEXT,
  SET_PAYMENT_SELECTED_ORDER_BY,
  SET_PAYMENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  REPUBLISHING_PAYMENT,
} from '../action';

const initialState = {
  downloading: false,
  downloadingDeleting: false,
  republishing: false,
  selectedPageSize: 20,
  filterString: '',
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_PAYMENTS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_PAYMENTS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_PAYMENT:
      return { ...state, downloadingDeleting: action.status };
    case SET_PAYMENT_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_PAYMENT_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_PAYMENT_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_PAYMENT_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_PAYMENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case REPUBLISHING_PAYMENT:
      return { ...state, republishing: action.status };
    default:
      return state;
  }
};
