import { downloadForm } from '../../../../dform/helper';
import { downloadSessionOperatorSession } from '../../../helper';
import {
  addSessionOperatorSession, downloadingDeletingSessionOperatorSession,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingSessionOperatorSession(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSessionOperatorSession;

    const session = await downloadSessionOperatorSession(tappedId, token);
    dispatch(addSessionOperatorSession(session));

    if (session.surveyFormId) {
      const form = await downloadForm(session.surveyFormId, token);
      dispatch(addSessionOperatorSession({
        ...session,
        surveyFormId: { label: form.name, value: form.id },
      }));
    }
  } finally {
    dispatch(downloadingDeletingSessionOperatorSession(false));
  }
};
