import {
  DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_IN_AREA_HISTORY,
  DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_IN_AREA,
  DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_IN_LOCATION_TREND,
  DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_IN_STATUS_TREND,
  DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_OUT_AREA_HISTORY,
  DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_OUT_AREA,
  DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_OUT_LOCATION_TREND,
  DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_OUT_STATUS_TREND,
  DOWNLOADING_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_STATUS,
  DOWNLOADING_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_STATUS,
  DOWNLOADING_ATTENDANCE_SUMMARY_TOTAL_CLOCK_IN_TODAY,
  DOWNLOADING_ATTENDANCE_SUMMARY_TOTAL_CLOCK_OUT_TODAY,
  DOWNLOADING_ATTENDANCE_SUMMARY_TOTAL_USERS,
  SET_ATTENDANCE_CLOCK_IN_LOCATION_TREND_TOOLTIP_TARGET,
  SET_ATTENDANCE_CLOCK_IN_STATUS_TREND_TOOLTIP_TARGET,
  SET_ATTENDANCE_CLOCK_OUT_LOCATION_TREND_TOOLTIP_TARGET,
  SET_ATTENDANCE_CLOCK_OUT_STATUS_TREND_TOOLTIP_TARGET,
  SET_ATTENDANCE_SUMMARY_TAPPED_CLOCK_IN_AREA_ID,
  SET_ATTENDANCE_SUMMARY_TAPPED_CLOCK_OUT_AREA_ID,
} from '../action';

const initialState = {
  downloadingClockInArea: false,
  downloadingClockInAreaHistory: false,
  downloadingClockInLocationTrend: false,
  downloadingClockInStatusTrend: false,
  downloadingClockOutArea: false,
  downloadingClockOutAreaHistory: false,
  downloadingClockOutLocationTrend: false,
  downloadingClockOutStatusTrend: false,
  downloadingTodayClockInStatus: false,
  downloadingTodayClockOutStatus: false,
  downloadingTotalClockInToday: false,
  downloadingTotalClockOutToday: false,
  downloadingTotalUsers: false,
  clockInLocationTrendTargetItem: null,
  clockInStatusTrendTargetItem: null,
  clockOutLocationTrendTargetItem: null,
  clockOutStatusTrendTargetItem: null,
  tappedClockInAreaId: null,
  tappedClockOutAreaId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_ATTENDANCE_SUMMARY_TOTAL_CLOCK_IN_TODAY:
      return { ...state, downloadingTotalClockInToday: action.status };
    case DOWNLOADING_ATTENDANCE_SUMMARY_TOTAL_CLOCK_OUT_TODAY:
      return { ...state, downloadingTotalClockOutToday: action.status };
    case DOWNLOADING_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_STATUS:
      return { ...state, downloadingTodayClockInStatus: action.status };
    case DOWNLOADING_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_STATUS:
      return { ...state, downloadingTodayClockOutStatus: action.status };
    case DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_IN_STATUS_TREND:
      return { ...state, downloadingClockInStatusTrend: action.status };
    case DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_OUT_STATUS_TREND:
      return { ...state, downloadingClockInLocationTrend: action.status };
    case DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_IN_LOCATION_TREND:
      return { ...state, downloadingClockInLocationTrend: action.status };
    case DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_OUT_LOCATION_TREND:
      return { ...state, downloadingClockOutLocationTrend: action.status };
    case DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_IN_AREA:
      return { ...state, downloadingClockInArea: action.status };
    case DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_OUT_AREA:
      return { ...state, downloadingClockOutArea: action.status };
    case DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_IN_AREA_HISTORY:
      return { ...state, downloadingClockInAreaHistory: action.status };
    case DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_OUT_AREA_HISTORY:
      return { ...state, downloadingClockOutAreaHistory: action.status };
    case DOWNLOADING_ATTENDANCE_SUMMARY_TOTAL_USERS:
      return { ...state, downloadingTotalUsers: action.status };
    case SET_ATTENDANCE_CLOCK_IN_STATUS_TREND_TOOLTIP_TARGET:
      return { ...state, clockInStatusTrendTargetItem: action.targetItem };
    case SET_ATTENDANCE_CLOCK_OUT_STATUS_TREND_TOOLTIP_TARGET:
      return { ...state, clockOutStatusTrendTargetItem: action.targetItem };
    case SET_ATTENDANCE_CLOCK_IN_LOCATION_TREND_TOOLTIP_TARGET:
      return { ...state, clockInLocationTrendTargetItem: action.targetItem };
    case SET_ATTENDANCE_CLOCK_OUT_LOCATION_TREND_TOOLTIP_TARGET:
      return { ...state, clockOutLocationTrendTargetItem: action.targetItem };
    case SET_ATTENDANCE_SUMMARY_TAPPED_CLOCK_IN_AREA_ID:
      return { ...state, tappedClockInAreaId: action.id };
    case SET_ATTENDANCE_SUMMARY_TAPPED_CLOCK_OUT_AREA_ID:
      return { ...state, tappedClockOutAreaId: action.id };
    default: return state;
  }
};
