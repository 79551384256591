import {
  INITIAL_ORDER_BY_SESSIONS,
  STATUS_DISABLED, STATUS_ENABLED,
} from '../../../constant';
import { enableDisableSession } from '../../../helper';
import {
  enablingDisablingSession,
  setAlertErrorMessage,
  setSessionSelectedOrderBy,
  setSessionSelectedPageSize,
} from '../simple-action';
import downloadSessionsAsync from './downloadSessionsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingSession(true));

    const { token } = getState().authentication;
    const { data } = getState().sessions;
    const { tappedId } = getState().uiSession;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableSession(tappedId, status, token);

    dispatch(setSessionSelectedPageSize(20));
    dispatch(setSessionSelectedOrderBy(INITIAL_ORDER_BY_SESSIONS));
    dispatch(downloadSessionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingSession(false));
  }
};
