import { addEventRegistration, rejectingEventRegistration } from '../simple-action';
import { approveRejectEventRegistration, downloadEventRegistration } from '../../../helper';
import { REGISTRATION_STATUS_REJECTED } from '../../../constant';

export default (notes) => async (dispatch, getState) => {
  try {
    dispatch(rejectingEventRegistration(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiEventRegistration;

    await approveRejectEventRegistration(tappedId, REGISTRATION_STATUS_REJECTED, notes, token);
    const result = await downloadEventRegistration(tappedId, token);

    dispatch(addEventRegistration(result));
  } finally {
    dispatch(rejectingEventRegistration(false));
  }
};
