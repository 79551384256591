import { PAGE_MODE_TABLE } from '../../../constant';
import { deleteSpeaker, downloadSpeaker } from '../../../helper';
import {
  addSpeaker, clearSpeakers, downloadingDeletingSpeaker,
  setAlertErrorMessage, setFunctionalPageMode, setSpeakerTappedId,
} from '../simple-action';
import downloadSpeakersAsync from './downloadSpeakersAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingSpeaker(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSpeaker;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteSpeaker(tappedId, token);
      dispatch(setSpeakerTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearSpeakers());
      dispatch(downloadSpeakersAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const speaker = await downloadSpeaker(tappedId, token);
      dispatch(addSpeaker(speaker));
    }
  } finally {
    dispatch(downloadingDeletingSpeaker(false));
  }
};
