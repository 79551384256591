import {
  REST_URL_ADD_EDIT_SESSION_OPERATOR_MAPPING, REST_URL_ADD_SESSION_OPERATOR_INVITATION,
  REST_URL_APPROVE_REJECT_SESSION_OPERATOR_REGISTRATION,
  REST_URL_ENABLE_DISABLE_SESSION_OPERATOR_MAPPING, REST_URL_RESEND_SESSION_OPERATOR_INVITATION,
  REST_URL_SAVE_FILE, REST_URL_SESSION_OPERATOR_INVITATIONS, REST_URL_SESSION_OPERATOR_MAPPINGS,
  REST_URL_SESSION_OPERATOR_REGISTRATIONS,
  REST_URL_SESSION_OPERATOR_SESSIONS, REST_URL_VIEW_DELETE_SESSION_OPERATOR_INVITATION,
  REST_URL_VIEW_DELETE_SESSION_OPERATOR_MAPPING, REST_URL_VIEW_SESSION_OPERATOR_REGISTRATION,
  REST_URL_VIEW_SESSION_OPERATOR_SESSION,
} from './constant';
import {
  buildQueryParams, sendDeleteRequest, sendGetFileRequest, sendGetRequest, sendPostRequest,
  sendPutRequest, transformCompleteUrl, transformEventSessionData, transformQueryResult,
} from '../../helper';

export * from '../../helper';

export const downloadSessionOperatorMappings = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(transformCompleteUrl(REST_URL_SESSION_OPERATOR_MAPPINGS, searchText,
    filterString), pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addSessionOperatorMapping = async (sessionId, userId, validUntil, token) => {
  const body = { sessionId, userId, validUntil };
  await sendPostRequest(REST_URL_ADD_EDIT_SESSION_OPERATOR_MAPPING, body, token);
};

export const editSessionOperatorMapping = async (id, sessionId, userId, validUntil, token) => {
  const body = {
    id, sessionId, userId, validUntil,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_SESSION_OPERATOR_MAPPING, body, token);
};

export const downloadSessionOperatorMapping = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_SESSION_OPERATOR_MAPPING.replace(/\{id\}/, id), token);
  return response;
};

export const deleteSessionOperatorMapping = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_SESSION_OPERATOR_MAPPING.replace(/\{id\}/, id), token);
};

export const enableDisableSessionOperatorMapping = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_SESSION_OPERATOR_MAPPING, body, token);
};

export const downloadSessionOperatorInvitations = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_SESSION_OPERATOR_INVITATIONS, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addSessionOperatorInvitation = async (sessionId, emails, token) => {
  const body = { sessionId, emails };
  await sendPostRequest(REST_URL_ADD_SESSION_OPERATOR_INVITATION, body, token);
};

export const downloadSessionOperatorInvitation = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_SESSION_OPERATOR_INVITATION.replace(/\{id\}/, id), token);
  return response;
};

export const deleteSessionOperatorInvitation = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_SESSION_OPERATOR_INVITATION.replace(/\{id\}/, id), token);
};

export const resendSessionOperatorInvitation = async (invitationId, token) => {
  const body = { invitationId };
  await sendPostRequest(REST_URL_RESEND_SESSION_OPERATOR_INVITATION, body, token);
};

export const saveSessionOperatorInvitations = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const downloadUrl = REST_URL_SESSION_OPERATOR_INVITATIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = buildQueryParams(
    transformCompleteUrl(downloadUrl, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadSessionOperatorSessions = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_SESSION_OPERATOR_SESSIONS, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadSessionOperatorSession = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_SESSION_OPERATOR_SESSION.replace(/\{id\}/, id), token);
  const transformData = transformEventSessionData(response);
  return transformData;
};

export const downloadSessionOperatorRegistrations = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_SESSION_OPERATOR_REGISTRATIONS, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadSessionOperatorRegistration = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_SESSION_OPERATOR_REGISTRATION.replace(/\{id\}/, id), token);
  return response;
};

export const approveRejectSessionOperatorRegistration = async (id, status, notes, token) => {
  const body = { id, status, notes };
  await sendPutRequest(REST_URL_APPROVE_REJECT_SESSION_OPERATOR_REGISTRATION, body, token);
};

export const saveSessionOperatorRegistrations = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const downloadUrl = REST_URL_SESSION_OPERATOR_REGISTRATIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = buildQueryParams(
    transformCompleteUrl(downloadUrl, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetFileRequest(url, token);
  return response;
};
