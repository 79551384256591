import {
  INITIAL_ORDER_BY_FORMS, STATUS_DISABLED, STATUS_ENABLED,
} from '../../../constant';
import { enableDisableForm } from '../../../helper';
import {
  enablingDisablingForm, setAlertErrorMessage, setFormSelectedOrderBy,
  setFormSelectedPageSize,
} from '../simple-action';
import downloadFormsAsync from './downloadFormsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingForm(true));

    const { token } = getState().authentication;
    const { data } = getState().forms;
    const { tappedId } = getState().uiForm;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableForm(tappedId, status, token);

    dispatch(setFormSelectedPageSize(20));
    dispatch(setFormSelectedOrderBy(INITIAL_ORDER_BY_FORMS));
    dispatch(downloadFormsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingForm(false));
  }
};
