import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  PAGE_MODE_VIEW, RXFIELD_CAPTCHA_REQUEST_REFERENCE_ID,
  RXSTATE_CAPTCHA_REQUESTS, RXSTATE_CAPTCHA_REQUEST_PAGE,
  RXFORM_CAPTCHA_REQUEST, DATE_TIME_FORMAT_WITHOUT_PIPE,
  RXFIELD_CAPTCHA_REQUEST_TAG, RXFIELD_CAPTCHA_REQUEST_CAPTCHA_TEXT,
  RXFIELD_CAPTCHA_REQUEST_IMAGE, RXFIELD_CAPTCHA_REQUEST_VALID_UNTIL,
  RXFIELD_CAPTCHA_REQUEST_VERIFIED, RXFIELD_CAPTCHA_REQUEST_VERIFIED_ON,
  RXFIELD_CAPTCHA_REQUEST_VERIFIED_FOR_USER_ID, RXFIELD_CAPTCHA_REQUEST_VERIFIED_FOR_FULL_NAME,
  RXFIELD_CAPTCHA_REQUEST_DEVICE_ID, RXFIELD_CAPTCHA_REQUEST_FCM_TOKEN,
  RXFIELD_CAPTCHA_REQUEST_LANGUAGE, RXFIELD_CAPTCHA_REQUEST_USER_AGENT,
  RXFIELD_CAPTCHA_REQUEST_MANUFACTURER, RXFIELD_CAPTCHA_REQUEST_MODEL,
  RXFIELD_CAPTCHA_REQUEST_OS_NAME, RXFIELD_CAPTCHA_REQUEST_OS_VERSION,
  RXFIELD_CAPTCHA_REQUEST_APP_VERSION, RXFIELD_CAPTCHA_REQUEST_REQUEST_TAG,
  RXFIELD_CREATED_DATE, approval,
  FILTER_TYPE_TEXT, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_SWITCH,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, PICKER_MODE_DATE_TIME,
} from '../../constant';
import { renderReduxFormImageInputField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { toMoment } from '../../helper';
import { FormInitialValueShape, SimpleDataShape } from '../../../../type';

const renderDialogContent = (downloadingDeleting, pageMode, initialImage) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_CAPTCHA_REQUEST_REQUEST_TAG}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.captchaRequestScreen.labelRequestTag}
          label={LocalizedString.captchaRequestScreen.labelRequestTag}
          disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAPTCHA_REQUEST_CAPTCHA_TEXT}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.captchaRequestScreen.labelCaptchaText}
          label={LocalizedString.captchaRequestScreen.labelCaptchaText}
          disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAPTCHA_REQUEST_IMAGE}
          component={renderReduxFormImageInputField}
          label={LocalizedString.captchaRequestScreen.labelImage}
          disabled={pageMode === PAGE_MODE_VIEW}
          onImageSelected={() => {}}
          onBlur={(e) => e.preventDefault()}
          defaultValue={initialImage}
          useFullWidthImage
          useAvatarWithoutCropper
          fullImageWidth="100%"
          useCropper={false}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAPTCHA_REQUEST_REFERENCE_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.captchaRequestScreen.labelReferenceId}
          label={LocalizedString.captchaRequestScreen.labelReferenceId}
          disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAPTCHA_REQUEST_TAG}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.captchaRequestScreen.labelTag}
          label={LocalizedString.captchaRequestScreen.labelTag}
          disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAPTCHA_REQUEST_VALID_UNTIL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.captchaRequestScreen.labelValidUntil}
          label={LocalizedString.captchaRequestScreen.labelValidUntil}
          disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAPTCHA_REQUEST_VERIFIED}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.captchaRequestScreen.labelVerified}
          label={LocalizedString.captchaRequestScreen.labelVerified}
          disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAPTCHA_REQUEST_VERIFIED_ON}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.captchaRequestScreen.labelVerifiedOn}
          label={LocalizedString.captchaRequestScreen.labelVerifiedOn}
          disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAPTCHA_REQUEST_VERIFIED_FOR_USER_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.captchaRequestScreen.labelVerifiedForUserId}
          label={LocalizedString.captchaRequestScreen.labelVerifiedForUserId}
          disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAPTCHA_REQUEST_VERIFIED_FOR_FULL_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.captchaRequestScreen.labelVerifiedForFullName}
          label={LocalizedString.captchaRequestScreen.labelVerifiedForFullName}
          disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_CAPTCHA_REQUEST_DEVICE_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.captchaRequestScreen.labelDeviceId}
          label={LocalizedString.captchaRequestScreen.labelDeviceId}
          disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAPTCHA_REQUEST_FCM_TOKEN}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.captchaRequestScreen.labelFCMToken}
          label={LocalizedString.captchaRequestScreen.labelFCMToken}
          disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          multiline
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAPTCHA_REQUEST_LANGUAGE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.captchaRequestScreen.labelLanguage}
          label={LocalizedString.captchaRequestScreen.labelLanguage}
          disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAPTCHA_REQUEST_USER_AGENT}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.captchaRequestScreen.labelUserAgent}
          label={LocalizedString.captchaRequestScreen.labelUserAgent}
          disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAPTCHA_REQUEST_MANUFACTURER}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.captchaRequestScreen.labelManufacturer}
          label={LocalizedString.captchaRequestScreen.labelManufacturer}
          disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAPTCHA_REQUEST_MODEL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.captchaRequestScreen.labelModel}
          label={LocalizedString.captchaRequestScreen.labelModel}
          disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAPTCHA_REQUEST_OS_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.captchaRequestScreen.labelOSName}
          label={LocalizedString.captchaRequestScreen.labelOSName}
          disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAPTCHA_REQUEST_OS_VERSION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.captchaRequestScreen.labelOSVersion}
          label={LocalizedString.captchaRequestScreen.labelOSVersion}
          disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAPTCHA_REQUEST_APP_VERSION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.captchaRequestScreen.labelAppVersion}
          label={LocalizedString.captchaRequestScreen.labelAppVersion}
          disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
    </Grid>
  </Grid>
);

const CaptchaRequestPage = ({
  downloading, downloadingDeleting,
  handleSubmit, onAppear, onCancelPressed, onChangePage, onChangePageSize,
  onRefresh, onSearchBarTextChanged, onSortPressed, onViewPressed,
  pageMode, initialValues, onResetAdvancedFilterPressed, onChangeUserText,
  onApplyAdvancedFilterPressed, onAdvancedFilterPressed, loadingUser, users,
}) => (
  <FunctionalPage
    data={RXSTATE_CAPTCHA_REQUESTS}
    uiPage={RXSTATE_CAPTCHA_REQUEST_PAGE}
    tableColumns={[
      {
        title: LocalizedString.captchaRequestScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.captchaRequestScreen.labelRequestTag, field: 'requestTag', sorting: !downloading },
      { title: LocalizedString.captchaRequestScreen.labelCaptchaText, field: 'captchaText', sorting: !downloading },
      {
        title: LocalizedString.captchaRequestScreen.labelValidUntil,
        field: 'validUntil',
        sorting: !downloading,
        render: ({ validUntil }) => (validUntil
          ? toMoment(validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      {
        title: LocalizedString.captchaRequestScreen.labelVerifiedOn,
        field: 'verifiedOn',
        sorting: !downloading,
        render: ({ verifiedOn }) => (verifiedOn
          ? toMoment(verifiedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      { title: LocalizedString.captchaRequestScreen.labelDeviceId, field: 'deviceId', sorting: !downloading },
      {
        title: LocalizedString.captchaRequestScreen.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.captchaRequestScreen.labelCreatedDate,
        field: RXFIELD_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.captchaRequestScreen.labelValidUntil,
        field: RXFIELD_CAPTCHA_REQUEST_VALID_UNTIL,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.captchaRequestScreen.labelVerified,
        field: RXFIELD_CAPTCHA_REQUEST_VERIFIED,
        type: FILTER_TYPE_SWITCH,
        data: approval,
      },
      {
        title: LocalizedString.captchaRequestScreen.labelVerifiedForFullName,
        field: 'fullName',
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.captchaRequestScreen.labelRequestTag,
        field: RXFIELD_CAPTCHA_REQUEST_REQUEST_TAG,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.captchaRequestScreen.labelReferenceId,
        field: RXFIELD_CAPTCHA_REQUEST_REFERENCE_ID,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.captchaRequestScreen.labelTag,
        field: RXFIELD_CAPTCHA_REQUEST_TAG,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.captchaRequestScreen.labelDeviceId,
        field: RXFIELD_CAPTCHA_REQUEST_DEVICE_ID,
        type: FILTER_TYPE_TEXT,
      },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.captchaRequestScreen.title}
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
  >
    {renderDialogContent(downloadingDeleting, pageMode, initialValues.captchaImage)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_CAPTCHA_REQUEST,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CaptchaRequestPage);

CaptchaRequestPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
