import {
  CREATING_CAPTCHA, SET_CAPTCHA, CLEAR_CAPTCHA,
} from '../action';

const initialState = {
  creating: false,
  data: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATING_CAPTCHA:
      return { ...state, creating: action.status };
    case SET_CAPTCHA:
      return { ...state, data: action.data };
    case CLEAR_CAPTCHA:
      return initialState;
    default: return state;
  }
};
