import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_PURCHASE_REQUESTS, MENUID_POINT_PURCHASE_REQUEST,
  PAGE_MODE_TABLE, RXFORM_PURCHASE_REQUEST,
} from '../../constant';
import { transformInitialValues, transformUserDropdownData, toMoment } from '../../helper';
import {
  clearPurchaseRequests, clearProfiles, setAdvancedFilterDialogSelectedFilterString,
  setActiveSideMenuItem, setAlertErrorMessage, setPurchaseRequestSearchText,
  setPurchaseRequestSelectedPageSize, setPurchaseRequestSelectedOrderBy,
  setPurchaseRequestTappedId, setProfileSearchText,
  downloadPurchaseRequestAsync, downloadPurchaseRequestsAsync,
} from '../../redux/action';
import { downloadProfilesAsync, setProfileAdvancedFilterDialogSelectedFilterString } from '../../../../redux/action';
import PurchaseRequestPage from './purchase-request.presentation';

const getInitialValues = (state) => {
  const {
    purchaseRequests, uiFunctionalPage, uiPurchaseRequest,
  } = state;
  const { data, meta } = purchaseRequests;
  const { downloadingDeleting, tappedId, selectedPageSize } = uiPurchaseRequest;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    fullName: found.user.fullName,
    validUntil: toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
    items: Object.values(found.items).length > 0 && !downloadingDeleting
      ? Object.values(found.items).map((x, i) => ({
        ...x,
        no: i + 1 + ((meta.currentPage - 1) * selectedPageSize),
        productCode: x.productCode,
        productName: x.productName,
        quantity: x.quantity,
        individualPrice: x.individualPrice,
        totalPrice: x.totalPrice,
      })) : [],
  }) : {
    tags: '',
    amount: '',
    validUntil: '',
    label: '',
    requestStatus: '',
    requestStatusNotes: null,
    merchantReferenceId: '',
    merchantName: '',
    callbackUrl: '',
    fullName: '',
    items: [],
  };
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiPurchaseRequest.downloading,
  downloadingDeleting: state.uiPurchaseRequest.downloadingDeleting,
  initialValues: getInitialValues(state),
  profiles: transformUserDropdownData(state.profiles.data),
  totalCurrentPage: state.purchaseRequests.meta.currentPage,
  totalCount: state.purchaseRequests.meta.totalCount,
  loadingProfile: state.uiProfile.downloading,
  pageMode: state.uiFunctionalPage.pageMode,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_POINT_PURCHASE_REQUEST));
    dispatch(setPurchaseRequestSearchText(''));
    dispatch(clearPurchaseRequests());
    dispatch(setPurchaseRequestSelectedPageSize(20));
    dispatch(setPurchaseRequestSelectedOrderBy(INITIAL_ORDER_BY_PURCHASE_REQUESTS));
    dispatch(downloadPurchaseRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/fullName/, 'user.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearPurchaseRequests());
    dispatch(downloadPurchaseRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_PURCHASE_REQUEST));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadPurchaseRequestsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setPurchaseRequestSelectedPageSize(pageSize));
    dispatch(downloadPurchaseRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeProfileText: async (text) => {
    try {
      dispatch(setProfileSearchText(text));
      dispatch(clearProfiles());
      dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
      await dispatch(downloadProfilesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setPurchaseRequestSelectedPageSize(pageSize));
    dispatch(clearPurchaseRequests());
    dispatch(downloadPurchaseRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearPurchaseRequests());
    dispatch(setPurchaseRequestSelectedOrderBy(INITIAL_ORDER_BY_PURCHASE_REQUESTS));
    dispatch(downloadPurchaseRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setPurchaseRequestSearchText(text));
      dispatch(clearPurchaseRequests());
      await dispatch(downloadPurchaseRequestsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setPurchaseRequestSelectedOrderBy(orderBy));
    dispatch(clearPurchaseRequests());
    dispatch(downloadPurchaseRequestsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setPurchaseRequestTappedId(id));
    dispatch(downloadPurchaseRequestAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseRequestPage);
