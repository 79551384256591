import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    attendanceManagementMenu: {
      title: 'Attendance',
    },
    attendanceSummaryScreen: {
      title: 'Summary',

      labelTotalUser: 'Total User',
      labelTotalClockInToday: 'Total Clock In Today',
      labelTotalClockOutToday: 'Total Clock Out Today',
      labelTodayClockInStatus: 'Today Clock In Status',
      labelTodayClockOutStatus: 'Today Clock Out Status',
      labelTodayClockInLocationStatus: 'Today Clock In Location Status',
      labelTodayClockOutLocationStatus: 'Today Clock Out Location Status',
      labelClockInStatusTrend: 'Clock In Status Trend',
      labelClockOutStatusTrend: 'Clock Out Status Trend',
      labelClockInLocationTrend: 'Clock In Location Trend',
      labelClockOutLocationTrend: 'Clock Out Location Trend',
      labelClockInArea: 'Today Clock In Area',
      labelClockOutArea: 'Today Clock Out Area',
      labelClockInInformation: 'Clock In Information',
    },
    historyScreen: {
      title: 'History',

      labelNo: 'No',
      labelEmployeeId: 'Employee ID',
      labelFullName: 'Full Name',
      labelClockIn: 'Clock In',
      labelClockInStatus: 'Clock In Status',
      labelClockOut: 'Clock Out',
      labelClockOutStatus: 'Clock Out Status',
      labelDate: 'Date',
      labelEmployee: 'Employee',
      labelDepartment: 'Department',
      labelDivision: 'Division',
      labelCompany: 'Company',
      labelActivities: 'Activities',
      labelActivityName: 'Activity Name',
      labelCategory: 'Category',
      labelTime: 'Time',
      labelNA: 'n/a',
      labelClockInLocationStatus: 'Clock In Location Status',
      labelClockOutLocationStatus: 'Clock Out Location Status',
      labelAdditionalFields: 'Additional Fields',
      labelLabel: 'Label:',
      labelName: 'Name:',
      labelValue: 'Value:',

      placeholderEmployeeId: 'Employee ID',
      placeholderName: 'Name',
      placeholderWorkingHour: 'Working Hour',
      placeholderAttendanceDate: 'Attendance Date',
      placeholderClockInTime: 'Clock In Time',
      placeholderClockInStatus: 'Clock In Status',
      placeholderClockInLocationStatus: 'Location Status',
      placeholderLocation: 'Location',
      placeholderZone: 'Zone',
      placeholderNotes: 'Notes',
      placeholderLatitutde: 'Latitude',
      placeholderLongitude: 'Longitude',
      placeholderMap: 'Map',
      placeholderClockOutTime: 'Clock Out Time',
      placeholderClockOutStatus: 'Clock Out Status',
      placeholderClockOutLocationStatus: 'Location Status',
      placeholderJobTitle: 'Job Title',
      placeholderDepartment: 'Department',
      placeholderDivision: 'Division',
      placeholderCompanyName: 'Company',
      placeholderBranch: 'Branch',
      placeholderManager: 'Manager',
      placeholderProfilePicture: 'Profile Picture',
    },
    additionalFieldScreen: {
      title: 'Additional Field',

      labelNo: 'No',
      labelName: 'Name',
      labelLabel: 'Label',
      labelAppliedOn: 'Applied On',
      labelFieldType: 'Field Type',
      labelOrder: 'Order',
      labelVisible: 'Visible',
      labelReadOnly: 'Read Only',
      labelRequired: 'Required',
      labelOptionValues: 'Option Values',
      labelOptionValuesHelper: 'Write each value separated by |',
      labelLogicalOperatorHelper: 'You can specify multiple values separated by |',
      labelStatus: 'Status',

      buttonCaptionCreateNewAdditionalField: 'Create New Additional Field',
      buttonCaptionEditAdditionalField: 'Edit Additional Field',
      buttonCaptionDeleteAdditionalField: 'Delete Additional Field',
      buttonCaptionEnable: 'Enable',
      buttonCaptionDisable: 'Disable',

      placeholderName: 'Name',
      placeholderLabel: 'Label',
      placeholderPlaceholder: 'Placeholder',
      placeholderValidationMessage: 'Validation Message',
      placeholderDefaultValue: 'Default Value',
      placeholderOrder: 'Order',
      placeholderRequired: 'Required',
      placeholderVisible: 'Visible',
      placeholderReadOnly: 'Read Only',
      placeholderTextMultiline: 'Text Multiline',
      placeholderTextKeyboardType: 'Text Keyboard Type',
      placeholderFieldType: 'Field Type',
      placeholderPickerMode: 'Picker Mode',
      placeholderOptionMode: 'Option Mode',
      placeholderOptionValues: 'Option A|Option B|Option C',
      placeholderOptionValueSeparator: 'Option Value Separator',
      placeholderSectionId: 'Section ID',
      placeholderSectionLabel: 'Section Label',
      placeholderAppliedOn: 'Applied On',
      placeholderTextMinLength: 'Text Min Length',
      placeholderTextMaxLength: 'Text Max Length',
      placeholderTextRegexValidation: 'Text Regex Validation',
      placeholderIntMinValue: 'Int Min Value',
      placeholderIntMaxValue: 'Int Max Value',
      placeholderFloatMinValue: 'Float Min Value',
      placeholderFloatMaxValue: 'Float Max Value',
      placeholderDateTimeMinValue: 'Date/Time Min Value',
      placeholderDateTimeMaxValue: 'Date/Time Max Value',
      placeholderParent: 'Parent',
      placeholderShowIfParentValue: 'Show If Parent Value',
      placeholderLogicalOperator: 'Logical Operator',
      placeholderMustUseCamera: 'Must Use Camera',

      msgEnableConfirmation: 'Are you sure want to enable this item?',
      msgDisableConfirmation: 'Are you sure want to disable this item?',
    },
  },
  id: {
    attendanceManagementMenu: {
      title: 'Manajemen Kehadiran',
    },
    attendanceSummaryScreen: {
      title: 'Rangkuman',

      labelTotalUser: 'Total Pengguna',
      labelTotalClockInToday: 'Total Absen Masuk Hari Ini',
      labelTotalClockOutToday: 'Total Absen Keluar Hari Ini',
      labelTodayClockInStatus: 'Status Absen Masuk Hari Ini',
      labelTodayClockOutStatus: 'Status Absen Keluar Hari Ini',
      labelTodayClockInLocationStatus: 'Status Lokasi Absen Masuk Hari Ini',
      labelTodayClockOutLocationStatus: 'Status Lokasi Absen Keluar Hari Ini',
      labelClockInStatusTrend: 'Trend Status Absen Masuk',
      labelClockOutStatusTrend: 'Trend Status Absen Keluar',
      labelClockInLocationTrend: 'Trend Lokasi Absen Masuk',
      labelClockOutLocationTrend: 'Trend Lokasi Absen Keluar',
      labelClockInArea: 'Area Absen Masuk Hari Ini',
      labelClockOutArea: 'Area Absen Keluar Hari Ini',
      labelClockInInformation: 'Informasi Absen Masuk',
    },
    historyScreen: {
      title: 'Riwayat',

      labelNo: 'No',
      labelEmployeeId: 'ID Karyawan',
      labelFullName: 'Nama Lengkap',
      labelClockIn: 'Absen Masuk',
      labelClockInStatus: 'Status Absen Masuk',
      labelClockOut: 'Absen Keluar',
      labelClockOutStatus: 'Status Absen Keluar',
      labelDate: 'Tanggal',
      labelEmployee: 'Karyawan',
      labelDepartment: 'Departemen',
      labelDivision: 'Divisi',
      labelCompany: 'Perusahaan',
      labelActivities: 'Aktivitas',
      labelActivityName: 'Nama Aktivitas',
      labelCategory: 'Kategori',
      labelTime: 'Waktu',
      labelNA: 'n/a',
      labelClockInLocationStatus: 'Status Lokasi Absen Masuk',
      labelClockOutLocationStatus: 'Status Lokasi Absen Keluar',
      labelAdditionalFields: 'Field Tambahan',
      labelLabel: 'Label:',
      labelName: 'Nama:',
      labelValue: 'Nilai:',

      placeholderEmployeeId: 'ID Karyawan',
      placeholderName: 'Nama',
      placeholderWorkingHour: 'Jam Kerja',
      placeholderAttendanceDate: 'Tanggal Kehadiran',
      placeholderClockInTime: 'Waktu Masuk',
      placeholderClockInStatus: 'Status Masuk',
      placeholderClockInLocationStatus: 'Lokasi Status',
      placeholderLocation: 'Lokasi',
      placeholderZone: 'Zona',
      placeholderNotes: 'Catatan',
      placeholderLatitutde: 'Garis Lintang',
      placeholderLongitude: 'Garis Bujur',
      placeholderMap: 'Peta',
      placeholderClockOutTime: 'Waktu Keluar',
      placeholderClockOutStatus: 'Status Keluar',
      placeholderClockOutLocationStatus: 'Lokasi Status',
      placeholderJobTitle: 'Jabatan',
      placeholderDepartment: 'Departemen',
      placeholderDivision: 'Divisi',
      placeholderCompanyName: 'Nama Perusahaan',
      placeholderBranch: 'Cabang',
      placeholderManager: 'Manajer',
      placeholderProfilePicture: 'Foto Profil',
    },
    additionalFieldScreen: {
      title: 'Field Tambahan',

      labelNo: 'No',
      labelLabel: 'Label',
      labelAppliedOn: 'Diterapkan Pada',
      labelFieldType: 'Tipe Field',
      labelOrder: 'Urutan',
      labelVisible: 'Terlihat',
      labelReadOnly: 'Hanya Dibaca',
      labelRequired: 'Harus Diisi',
      labelOptionValues: 'Nilai-nilai Opsi',
      labelOptionValuesHelper: 'Tulis setiap nilai dipisahkan dengan |',
      labelLogicalOperatorHelper: 'Anda bisa menulis lebih dari satu pilihan yang dipisahkan dengan |',
      labelStatus: 'Status',

      buttonCaptionCreateNewAdditionalField: 'Buat Field Tambahan Baru',
      buttonCaptionEditAdditionalField: 'Ubah Field Tambahan',
      buttonCaptionDeleteAdditionalField: 'Hapus Field Tambahan',
      buttonCaptionEnable: 'Aktifkan',
      buttonCaptionDisable: 'Nonaktifkan',

      placeholderName: 'Nama',
      placeholderLabel: 'Label',
      placeholderPlaceholder: 'Placeholder',
      placeholderValidationMessage: 'Pesan Validasi',
      placeholderDefaultValue: 'Nilai Default',
      placeholderOrder: 'Urutan',
      placeholderRequired: 'Harus Diisi',
      placeholderVisible: 'Terlihat',
      placeholderReadOnly: 'Hanya Dibaca',
      placeholderTextMultiline: 'Teks Multi baris',
      placeholderTextKeyboardType: 'Tipe Keyboard Teks',
      placeholderFieldType: 'Tipe Field',
      placeholderPickerMode: 'Mode Pemilihan',
      placeholderOptionMode: 'Mode Opsi',
      placeholderOptionValues: 'Opsi A|Opsi B|Opsi C',
      placeholderOptionValueSeparator: 'Pemisah Nilai Opsi',
      placeholderSectionId: 'ID Bagian',
      placeholderSectionLabel: 'Label Bagian',
      placeholderAppliedOn: 'Diterapkan Pada',
      placeholderTextMinLength: 'Panjang Teks Min.',
      placeholderTextMaxLength: 'Panjang Teks Maks.',
      placeholderTextRegexValidation: 'Validasi Regex Teks',
      placeholderIntMinValue: 'Nilai Min. Int',
      placeholderIntMaxValue: 'Nilai Maks. Int',
      placeholderFloatMinValue: 'Nilai Min Float',
      placeholderFloatMaxValue: 'Nilai Maks.Float',
      placeholderDateTimeMinValue: 'Nilai Min. Tanggal/Waktu',
      placeholderDateTimeMaxValue: 'Nilai Maks. Tanggal/Waktu',
      placeholderParent: 'Induk',
      placeholderShowIfParentValue: 'Tunjukkan Jika Nilai Induk',
      placeholderLogicalOperator: 'Operator Logika',
      placeholderMustUseCamera: 'Harus Menggunakan Kamera',

      msgEnableConfirmation: 'Apa Anda yakin ingin mengaktifkan pilihan ini?',
      msgDisableConfirmation: 'Apa Anda yakin ingin menonaktifkan pilihan ini?',
    },
  },
});

export default LocalizedString;
