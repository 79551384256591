import {
  resyncingDistrict, setAlertErrorMessage, setDistrictSelectedOrderBy, setDistrictSelectedPageSize,
} from '../simple-action';
import { resyncMasterDataIndividually } from '../../../helper';
import { INITIAL_ORDER_BY_DISTRICTS, MASTER_DATA_ENTITY_NAME_DISTRICT } from '../../../constant';
import downloadDistrictsAsync from './downloadDistrictsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resyncingDistrict(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiDistrict;

    await resyncMasterDataIndividually(MASTER_DATA_ENTITY_NAME_DISTRICT, tappedId, token);

    dispatch(setDistrictSelectedPageSize(20));
    dispatch(setDistrictSelectedOrderBy(INITIAL_ORDER_BY_DISTRICTS));
    dispatch(downloadDistrictsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(resyncingDistrict(false));
  }
};
