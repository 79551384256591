/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, CardActionArea, Dialog, InputAdornment, makeStyles,
} from '@material-ui/core';
import FlatList from 'flatlist-react';
import { ExpandMore } from '@material-ui/icons';
import OutlinedTextField from './outlined-text-field';
import LocalizedString from '../localization';
import { COLOR_PRIMARY } from '../constant';
import { StyleShape } from '../type';

const useStyles = makeStyles(() => ({
  paper: {
    width: '300px',
  },
}));

const styles = {
  modalContainer: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '20px 10px 5px 10px',
  },
  pickerItemAndroid: {
    width: '100%',
    textAlign: 'center',
    borderBottom: '1px solid',
  },
  flatListAndroid: {
    alignSelf: 'stretch',
  },
  footerAndroid: {
    marginTop: '10px',
  },
  columnFooterAndroid: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
};

const SimplePicker = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState(props.selectedValue);
  const classes = useStyles();

  useEffect(() => {
    if (props.selectedValue !== selectedValue) {
      setSelectedValue(props.selectedValue);
    }
  }, [props.selectedValue, selectedValue]);

  const onClearButtonPressed = () => {
    setShowModal(false);
    props.onClearPressed();
  };

  const onTextFieldPressed = () => {
    if (!props.disabled) {
      setShowModal(true);
    }
  };

  const onValueSelect = (item) => {
    if (props.returnString) {
      const selectedItem = item || props.data[0];

      props.onValueSelected(selectedItem.value || selectedItem);
    } else {
      const selectedItem = (typeof item === 'object' && !item.value) || !item ? props.data[0] : item;
      props.onValueSelected(selectedItem);
    }
    setShowModal(false);
  };

  const onCancelPicker = () => {
    setShowModal(false);
  };

  const renderTextField = () => {
    const displayedSelectedValue = () => {
      if (props.returnString) {
        if (props.selectedValue) {
          const selectedValueLabel = props.data.find((x) => x.value === props.selectedValue).label;
          return selectedValueLabel;
        } return '';
      } if (typeof props.selectedValue === 'object') {
        return props.selectedValue.label;
      } return (props.selectedValue || '');
    };

    return (
      <div
        style={{ ...{ marginTop: 15 }, ...props.style }}
        disabled={props.disabled}
        onClick={onTextFieldPressed}
        role="presentation"
      >
        <OutlinedTextField
          variant="outlined"
          label={props.label}
          placeholder={props.placeholder}
          value={displayedSelectedValue()}
          required={props.required}
          error={props.error}
          editable={false}
          fullWidth
          helperText={props.helperText}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ExpandMore sx={{ color: COLOR_PRIMARY }} />
              </InputAdornment>
            ),
            readOnly: true,
          }}
          textFieldBorderColor={props.textFieldBorderColor}
          textFieldBorderWidth={props.textFieldBorderWidth}
        />
      </div>
    );
  };

  const onPickerItemAndroidPressed = (item) => {
    const selected = props.returnString ? item.value : item;
    setSelectedValue(selected);
    onValueSelect(selected);
  };

  const renderPickerItemAndroid = (item, index) => (
    <CardActionArea
      style={{ ...styles.pickerItemAndroid, ...{ borderColor: props.separatorColor } }}
      onClick={() => onPickerItemAndroidPressed(item)}
      key={index}
    >
      <p
        style={props.itemTextStyle}
      >
        {item.label || item.value ? item.label : item}
      </p>
    </CardActionArea>
  );

  const renderAndroidPicker = () => {
    const { data, cancelCaption, textFieldOuterContainerStyle } = props;
    const flatListHeight = data && data.length > 3 ? {} : { height: undefined };
    return (
      <div style={textFieldOuterContainerStyle}>
        {renderTextField()}
        <Dialog
          open={showModal}
          aria-labelledby="responsive-dialog-title"
          classes={{ paper: classes.paper }}
        >
          <div style={styles.modalContainer}>
            <div style={styles.modalInnerContainer}>
              <FlatList
                list={props.data}
                renderItem={(item, index) => renderPickerItemAndroid(item, index)}
                style={{ ...styles.flatListAndroid, ...flatListHeight }}
              />
              {props.showClearButton ? (
                <div style={{ ...styles.footerAndroid, ...styles.columnFooterAndroid }}>
                  <Button
                    variant="contained"
                    sx={{ width: '100%' }}
                    onClick={onCancelPicker}
                  >
                    {cancelCaption}
                  </Button>
                  <Button
                    variant="text"
                    sx={{ marginTop: '10px' }}
                    onClick={onClearButtonPressed}
                  >
                    {LocalizedString.common.buttonCaptionClear}
                  </Button>
                </div>
              ) : (
                <div style={styles.footerAndroid}>
                  <Button
                    variant="text"
                    onClick={onCancelPicker}
                  >
                    {cancelCaption}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Dialog>
      </div>
    );
  };

  return renderAndroidPicker();
};

export default SimplePicker;

const pickerItemShape = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
});

const arrayShape = PropTypes.arrayOf(pickerItemShape);

SimplePicker.propTypes = {
  loading: PropTypes.bool,
  showClearButton: PropTypes.bool,
  onClearPressed: PropTypes.func,
  animationType: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  separatorColor: PropTypes.string,
  cancelCaption: PropTypes.string,
  okCaption: PropTypes.string,
  textFieldBorderColor: PropTypes.string,
  textFieldBorderWidth: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  returnString: PropTypes.bool,
  itemTextStyle: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  style: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  textFieldOuterContainerStyle: StyleShape,
  selectedValue: PropTypes.oneOfType([
    pickerItemShape,
    PropTypes.string,
  ]),
  onValueSelected: PropTypes.func,
  data: PropTypes.oneOfType([
    arrayShape,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  ]).isRequired,
};

SimplePicker.defaultProps = {
  loading: false,
  showClearButton: false,
  animationType: 'fade',
  cancelCaption: 'Cancel',
  okCaption: 'OK',
  label: '',
  textFieldBorderColor: '',
  textFieldBorderWidth: '',
  error: false,
  separatorColor: '#EEEEEE',
  disabled: false,
  required: false,
  returnString: false,
  selectedValue: undefined,
  itemTextStyle: { fontSize: 14 },
  style: {},
  textFieldOuterContainerStyle: {},
  onValueSelected: () => {},
  onClearPressed: () => {},
};
