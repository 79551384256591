import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, FormGroup, FormHelperText, Typography, makeStyles,
} from '@material-ui/core';
import {
  COLOR_BACKGROUND, COLOR_BLACK_INACTIVE, COLOR_DANGEROUS, COLOR_PRIMARY,
} from '../constant';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  optionContainer: {
    borderRadius: 50,
    padding: 8,
    height: 32,
    alignItems: 'center',
    marginVertical: 4,
    marginRight: 8,
  },
  backgroundActive: {
    backgroundColor: COLOR_PRIMARY,
  },
  backgroundInactive: {
    backgroundColor: COLOR_BLACK_INACTIVE,
  },
  errorTextStyle: {
    fontStyle: 'italic',
    color: COLOR_DANGEROUS,
    marginLeft: 14,
  },
}));

const ButtonOption = ({
  data, onValueSelected,
  disabled, optionValueSeparator, error,
  selectedValue, label, defaultValue,
}) => {
  const [selectedValueTemp, setSelectedValueTemp] = useState(selectedValue || defaultValue);

  const classes = useStyles();

  const onSelect = (item, isSelectedAlready) => {
    const optionValueSeparatorTemp = selectedValue ? optionValueSeparator : '';
    const selectedStr = isSelectedAlready
      ? selectedValue.split(optionValueSeparator).filter((x) => x !== item.label).toString()
      : selectedValue + optionValueSeparatorTemp + item.label;
    onValueSelected(selectedStr);
    setSelectedValueTemp(selectedStr);
  };

  const renderOptions = (item) => {
    const selected = selectedValueTemp.split(optionValueSeparator).includes(item.label);
    return (
      <Button
        onClick={() => onSelect(item, selected)}
        disabled={disabled}
        key={item.label}
        className={`${classes.optionContainer} ${selected ? classes.backgroundActive : classes.backgroundInactive}`}
        variant="contained"
        color="primary"
      >
        <Typography style={{ color: COLOR_BACKGROUND, fontSize: 12 }}>
          {item.label}
        </Typography>
      </Button>
    );
  };

  const renderError = () => {
    if (error) {
      return (<FormHelperText className={classes.errorTextStyle}>{error}</FormHelperText>);
    }
    return null;
  };

  return (
    <div className={classes.container}>
      <Typography style={{ marginBottom: '5px' }}>{label}</Typography>
      <FormGroup>
        <div className={classes.buttonsContainer}>
          {data.map((option) => renderOptions(option))}
        </div>
        {renderError()}
      </FormGroup>
    </div>
  );
};

export default ButtonOption;

const pickerItemShape = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
});

const arrayShape = PropTypes.arrayOf(pickerItemShape);

ButtonOption.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  data: arrayShape.isRequired,
  onValueSelected: PropTypes.func,
  disabled: PropTypes.bool,
  optionValueSeparator: PropTypes.string,
  error: PropTypes.string,
  selectedValue: PropTypes.oneOfType([
    pickerItemShape,
    PropTypes.string,
  ]),
};

ButtonOption.defaultProps = {
  label: '',
  defaultValue: '',
  onValueSelected: () => {},
  disabled: false,
  optionValueSeparator: ',',
  error: '',
  selectedValue: undefined,
};
