import { connect } from 'react-redux';
import { reset, change } from 'redux-form';
import moment from 'moment';
import { isEmpty } from 'lodash';
import {
  ENUM_FIELD_DATE, ENUM_FIELD_DATE_TIME, ENUM_FIELD_TIME, DATE_TIME_FORMAT_WITHOUT_PIPE,
  INITIAL_ORDER_BY_FIELDS, MENUID_DFORM_FIELD,
  PAGE_MODE_TABLE, RXFIELD_DFORM_FIELD_FIELD_TYPE,
  RXFIELD_DFORM_FIELD_SHOW_IF_PARENT_VALUE_LOGICAL_OPERATOR,
  RXFIELD_DFORM_FIELD_MUST_USE_CAMERA, RXFIELD_DFORM_FIELD_OPTION_MODE,
  RXFIELD_DFORM_FIELD_OPTION_VALUE_SEPARATOR, RXFIELD_DFORM_FIELD_PARENT,
  RXFIELD_DFORM_FIELD_PICKER_MODE, RXFIELD_DFORM_FIELD_READ_ONLY,
  RXFIELD_DFORM_FIELD_REQUIRED, RXFIELD_DFORM_FIELD_TEXT_KEYBOARD_TYPE,
  RXFIELD_DFORM_FIELD_TEXT_MULTILINE, RXFIELD_DFORM_FIELD_VISIBLE,
  RXFORM_FIELD, SIMPLE_DATE_FORMAT, TIME_FORMAT, RXFIELD_DFORM_FIELD_FORM,
  DFORM_FIELD_PARENT_DEFAULT_FILTER_STRING,
} from '../../constant';
import {
  sortAsc, transformDropdownData, transformInitialValues, toMoment, debounceSearch,
} from '../../../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  clearFieldParents, clearFields, setActiveSideMenuItem,
  setAdvancedFilterDialogSelectedFilterString, setAlertConfirmationMessage, setAlertErrorMessage,
  setFieldSearchText, setFieldParentSearchText,
  setFieldSelectedPageSize,
  setFieldSelectedFieldType, setFieldSelectedLogicalOperator,
  setFieldSelectedMustUseCamera, setFieldSelectedOptionMode,
  setFieldSelectedOptionValueSeparator, setFieldSelectedOrderBy,
  setFieldSelectedParent, setFieldSelectedPickerMode,
  setFieldSelectedTextKeyboardType, setFieldSelectedTextMultiline,
  setFieldTappedId,
  addEditFieldAsync, downloadFieldParentsAsync, downloadFieldsAsync,
  downloadDeleteFieldAsync, enableDisableFieldAsync, clearForms, downloadFormsAsync,
  setFormSearchText, setFieldSelectedForm, setFormAdvancedFilterDialogSelectedFilterString,
  setFieldParentFilterString,
} from '../../redux/action';
import FieldPage from './field.presentation';

const getDateTimeFormat = (fieldType, dateTime) => {
  switch (fieldType) {
    case ENUM_FIELD_DATE: return moment.utc(dateTime).format(SIMPLE_DATE_FORMAT);
    case ENUM_FIELD_DATE_TIME: return toMoment(dateTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE);
    case ENUM_FIELD_TIME: return moment.utc(dateTime).format(TIME_FORMAT);
    default: return null;
  }
};

const getInitialValues = (state) => {
  const { fields, uiFunctionalPage, uiField } = state;
  const { data } = fields;
  const { downloadingDeleting, tappedId } = uiField;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    parent: found.parent ? { label: found.parent.name, value: found.parent.id } : { label: '', value: '' },
    dateTimeMaxValue: found.dateTimeMaxValue
      ? getDateTimeFormat(found.fieldType, found.dateTimeMaxValue) : null,
    dateTimeMinValue: found.dateTimeMinValue
      ? getDateTimeFormat(found.fieldType, found.dateTimeMinValue) : null,
    showIfParentValue: found.parent ? found.showIfParentValue : '',
    textMultiline: found.textMultiline
      ? { label: GlobalLocalizedString.common.labelYes, value: true }
      : { label: GlobalLocalizedString.common.labelNo, value: false },
    mustUseCamera: found.mustUseCamera
      ? { label: GlobalLocalizedString.common.labelYes, value: true }
      : { label: GlobalLocalizedString.common.labelNo, value: false },
    form: !isEmpty(found?.form) ? {
      label: found.form.name,
      value: found.form.id,
    } : '',
  }) : {
    name: '',
    label: '',
    placeHolder: '',
    validationMessage: '',
    defaultValue: '',
    order: '',
    required: false,
    visible: true,
    readOnly: false,
    textMultiline: false,
    textKeyboardType: null,
    fieldType: '',
    pickerMode: '',
    optionMode: null,
    optionValues: '',
    optionValueSeparator: null,
    sectionId: '',
    sectionLabel: '',
    textMinLength: '',
    textMaxLength: '',
    textRegexValidation: null,
    intMinValue: '',
    intMaxValue: '',
    floatMinValue: '',
    floatMaxValue: '',
    dateTimeMinValue: null,
    dateTimeMaxValue: null,
    parent: null,
    showIfParentValue: null,
    showIfParentValueLogicalOperator: null,
  };
  return result;
};

const getFormsData = (state) => {
  const { forms } = state;
  const { data } = forms;
  if (!isEmpty(data)) {
    return Object.values(data).map((x) => ({
      label: x.name,
      value: x.id,
    }));
  }
  return [];
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiField.addingEditing,
  downloading: state.uiField.downloading,
  downloadingDeleting: state.uiField.downloadingDeleting,
  enablingDisabling: state.uiField.enablingDisabling,
  initialValues: getInitialValues(state),
  tappedId: state.uiFunctionalPage.tappedId || '',
  parents: Object.keys(state.fieldParents.data).length > 0
  && !state.uiField.downloadingParents
    ? transformDropdownData(state.fieldParents.data)
      .filter((x) => x.value !== state.uiField.tappedId)
      .sort((a, b) => sortAsc(a.label.toLowerCase(), b.label.toLowerCase())) : [],
  selectedParent: state.uiField.selectedParent,
  selectedFieldType: state.uiField.selectedFieldType,
  loadingParents: state.uiField.downloadingParents,
  currentFieldStatus: state.fields.data[state.uiFunctionalPage.tappedId]
    ? state.fields.data[state.uiFunctionalPage.tappedId].status : '',
  forms: getFormsData(state),
  loadingForms: state.uiForm.downloading,
});

const searchFieldFormDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearForms());
    dispatch(downloadFormsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_DFORM_FIELD));
    dispatch(setFieldSearchText(''));
    dispatch(clearFields());
    dispatch(setFieldSelectedPageSize(20));
    dispatch(setFieldSelectedOrderBy(INITIAL_ORDER_BY_FIELDS));
    dispatch(downloadFieldsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/form/, 'form.id').replace(/parent/, 'parent.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearFields());
    dispatch(downloadFieldsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_FIELD));
  },
  onCancelPressed: () => {
    dispatch(setFieldParentSearchText(''));
    dispatch(setFieldSelectedFieldType(''));
    dispatch(setFieldSelectedLogicalOperator(''));
    dispatch(setFieldSelectedMustUseCamera(''));
    dispatch(setFieldSelectedOptionMode(''));
    dispatch(setFieldSelectedOptionValueSeparator(''));
    dispatch(setFieldSelectedParent(''));
    dispatch(setFieldSelectedPickerMode(''));
    dispatch(setFieldSelectedTextKeyboardType(''));
    dispatch(setFieldSelectedTextMultiline(''));
    dispatch(reset(RXFORM_FIELD));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadFieldsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setFieldSelectedPageSize(pageSize));
    dispatch(downloadFieldsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeParentText: async (text) => {
    try {
      dispatch(setFieldParentSearchText(text));
      dispatch(clearFieldParents());
      await dispatch(downloadFieldParentsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onConfirmPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(downloadDeleteFieldAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableFieldAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onCreatePressed: () => {
    dispatch(setFieldTappedId(''));
    dispatch(reset(RXFORM_FIELD));
    dispatch(setFieldParentSearchText(''));
    dispatch(setFieldSelectedFieldType(''));
    dispatch(setFieldSelectedLogicalOperator(''));
    dispatch(setFieldSelectedMustUseCamera(''));
    dispatch(setFieldSelectedOptionMode(''));
    dispatch(setFieldSelectedOptionValueSeparator(''));
    dispatch(setFieldSelectedParent(''));
    dispatch(setFieldSelectedPickerMode(''));
    dispatch(setFieldSelectedTextKeyboardType(''));
    dispatch(setFieldSelectedTextMultiline(''));
    dispatch(setFieldParentSearchText(''));
    dispatch(clearFieldParents());
    dispatch(downloadFieldParentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setFormSearchText(''));
    dispatch(setFormAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearForms());
    dispatch(downloadFormsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setFieldTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setFieldTappedId(id));
    dispatch(downloadDeleteFieldAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setFormSearchText(''));
    dispatch(setFormAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearFieldParents());
    dispatch(downloadFieldParentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(clearForms());
    dispatch(downloadFormsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onEnableDisablePressed: (id, message) => {
    dispatch(setFieldTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onFieldTypeOptionSelected: (option) => {
    if (option) {
      dispatch(setFieldSelectedFieldType(option));
      dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_FIELD_TYPE, option));
    } else {
      dispatch(setFieldSelectedFieldType(''));
      dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_FIELD_TYPE, ''));
    }
  },
  onLogicalOperatorOptionSelected: (option) => {
    if (option) {
      dispatch(setFieldSelectedLogicalOperator(option));
      dispatch(change(RXFORM_FIELD,
        RXFIELD_DFORM_FIELD_SHOW_IF_PARENT_VALUE_LOGICAL_OPERATOR, option));
    } else {
      dispatch(setFieldSelectedLogicalOperator(''));
      dispatch(change(RXFORM_FIELD,
        RXFIELD_DFORM_FIELD_SHOW_IF_PARENT_VALUE_LOGICAL_OPERATOR, ''));
    }
  },
  onMustUseCameraOptionSelected: (option) => {
    if (option) {
      dispatch(setFieldSelectedMustUseCamera(option));
      dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_MUST_USE_CAMERA, option));
    } else {
      dispatch(setFieldSelectedMustUseCamera(''));
      dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_MUST_USE_CAMERA, ''));
    }
  },

  onOptionModeOptionSelected: (option) => {
    if (option) {
      dispatch(setFieldSelectedOptionMode(option));
      dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_OPTION_MODE, option));
    } else {
      dispatch(setFieldSelectedOptionMode(''));
      dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_OPTION_MODE, ''));
    }
  },
  onOptionValueSeparatorOptionSelected: (option) => {
    if (option) {
      dispatch(setFieldSelectedOptionValueSeparator(option));
      dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_OPTION_VALUE_SEPARATOR,
        option));
    } else {
      dispatch(setFieldSelectedOptionValueSeparator(''));
      dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_OPTION_VALUE_SEPARATOR, ''));
    }
  },
  onParentOptionSelected: (option) => {
    if (option) {
      dispatch(setFieldSelectedParent(option));
      dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_PARENT, option));
    } else {
      dispatch(setFieldSelectedParent(''));
      dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_PARENT, ''));
    }
  },
  onPickerModeOptionSelected: (option) => {
    if (option) {
      dispatch(setFieldSelectedPickerMode(option));
      dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_PICKER_MODE, option));
    } else {
      dispatch(setFieldSelectedPickerMode(''));
      dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_PICKER_MODE, ''));
    }
  },
  onReadOnlyOptionSelected: (option) => {
    dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_READ_ONLY, option));
  },
  onRefresh: (pageSize) => {
    dispatch(setFieldSelectedPageSize(pageSize));
    dispatch(clearFields());
    dispatch(downloadFieldsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRequiredOptionSelected: (option) => {
    dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_REQUIRED, option));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearFields());
    dispatch(setFieldSelectedOrderBy(INITIAL_ORDER_BY_FIELDS));
    dispatch(downloadFieldsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async (values) => {
    try {
      await dispatch(addEditFieldAsync(values));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setFieldSearchText(text));
      dispatch(clearFields());
      await dispatch(downloadFieldsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setFieldSelectedOrderBy(orderBy));
    dispatch(clearFields());
    dispatch(downloadFieldsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async (values) => {
    await dispatch(addEditFieldAsync(values));
  },
  onTextKeyboardTypeOptionSelected: (option) => {
    if (option) {
      dispatch(setFieldSelectedTextKeyboardType(option));
      dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_TEXT_KEYBOARD_TYPE,
        option));
    } else {
      dispatch(setFieldSelectedTextKeyboardType(''));
      dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_TEXT_KEYBOARD_TYPE, ''));
    }
  },
  onTextMultilineOptionSelected: (option) => {
    if (option) {
      dispatch(setFieldSelectedTextMultiline(option));
      dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_TEXT_MULTILINE, option));
    } else {
      dispatch(setFieldSelectedTextMultiline(''));
      dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_TEXT_MULTILINE, ''));
    }
  },
  onViewPressed: (id) => {
    dispatch(setFieldTappedId(id));
    dispatch(downloadDeleteFieldAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onVisibleOptionSelected: (option) => {
    dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_VISIBLE, option));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setFieldParentSearchText(''));
    dispatch(setFieldParentFilterString(DFORM_FIELD_PARENT_DEFAULT_FILTER_STRING));
    dispatch(clearFieldParents());
    dispatch(downloadFieldParentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setFormSearchText(''));
    dispatch(setFormAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearForms());
    dispatch(downloadFormsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeFormText: (text) => {
    dispatch(setFormSearchText(text));
    dispatch(setFormAdvancedFilterDialogSelectedFilterString(''));
    if (text.length >= 3 || text.length === 0) {
      searchFieldFormDebounce(dispatch, true);
    }
  },
  onFormOptionSelected: (option) => {
    if (option) {
      dispatch(setFieldSelectedForm(option));
      dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_FORM, option));
    } else {
      dispatch(setFieldSelectedForm(''));
      dispatch(change(RXFORM_FIELD, RXFIELD_DFORM_FIELD_FORM, ''));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FieldPage);
