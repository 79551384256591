import { downloadSessionRegistrations, transformSearchText } from '../../../helper';
import { downloadingSessionRegistrations, setSessionRegistrations } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, sessionRegistrations, uiSessionRegistration } = getState();
  const { token } = authentication;
  const { meta } = sessionRegistrations;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiSessionRegistration;

  try {
    dispatch(downloadingSessionRegistrations(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadSessionRegistrations(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setSessionRegistrations(list));
    }
  } finally {
    dispatch(downloadingSessionRegistrations(false));
  }
};
