import {
  REST_URL_FORMS,
  REST_URL_VIEW_DELETE_FORM,
  REST_URL_ADD_EDIT_FORM,
  REST_URL_ENABLE_DISABLE_FORM,
  REST_URL_FIELDS,
  REST_URL_VIEW_DELETE_FIELD,
  REST_URL_ADD_EDIT_FIELD,
  REST_URL_ENABLE_DISABLE_FIELD,
  REST_URL_RESPONSES,
  REST_URL_VIEW_RESPONSE,
  REST_URL_DUPLICATE_FORM,
  REST_URL_UPLOAD_PREVIEW_FORM_RESPONSE,
} from './constant';
import {
  buildQueryParams, sendDeleteRequest, sendGetRequest,
  sendPostRequest, sendPutRequest, transformQueryResult,
} from '../../helper';

export * from '../../helper';

export const downloadForms = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const filterStringParam = filterString ? `&filterString=${filterString}` : '';
  const searchTextParam = searchText ? `&searchString=${searchText}` : '';
  const url = buildQueryParams(REST_URL_FORMS, pageNumber, pageSize, orderBy, '')
    .concat(filterStringParam)
    .concat(searchTextParam);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadForm = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_FORM.replace(/\{id\}/, id), token);
  return response;
};

export const deleteForm = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_FORM.replace(/\{id\}/, id), token);
};

export const addForm = async (
  name, description, title, tag, visibilityStatus, validFrom, validUntil,
  closeFormResponse, allowDirectUserResponse, detectDuplicatedResponseBasedOnReferenceId,
  token,
) => {
  const body = {
    name,
    description,
    title,
    tag,
    visibilityStatus,
    validFrom,
    validUntil,
    closeFormResponse,
    allowDirectUserResponse,
    detectDuplicatedResponseBasedOnReferenceId,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_FORM, body, token);
};

export const editForm = async (
  id, name, description, title, tag, visibilityStatus, validFrom, validUntil,
  closeFormResponse, allowDirectUserResponse, detectDuplicatedResponseBasedOnReferenceId,
  token,
) => {
  const body = {
    id,
    name,
    description,
    title,
    tag,
    visibilityStatus,
    validFrom,
    validUntil,
    closeFormResponse,
    allowDirectUserResponse,
    detectDuplicatedResponseBasedOnReferenceId,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_FORM, body, token);
};

export const duplicateForm = async (id, newName, token) => {
  const body = {
    id,
    newName,
  };
  await sendPostRequest(REST_URL_DUPLICATE_FORM, body, token);
};

export const enableDisableForm = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_FORM, body, token);
};

export const downloadFields = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const filterStringParam = filterString ? `&filterString=${filterString}` : '';
  const searchTextParam = searchText ? `&searchString=${searchText}` : '';
  const url = buildQueryParams(REST_URL_FIELDS, pageNumber, pageSize, orderBy, '')
    .concat(filterStringParam)
    .concat(searchTextParam);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addField = async (body, token) => {
  await sendPostRequest(REST_URL_ADD_EDIT_FIELD, body, token);
};

export const editField = async (body, token) => {
  await sendPutRequest(REST_URL_ADD_EDIT_FIELD, body, token);
};

export const downloadField = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_FIELD.replace(/\{id\}/, id), token);
  return response;
};

export const deleteField = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_FIELD.replace(/\{id\}/, id), token);
};

export const enableDisableField = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_FIELD, body, token);
};

export const downloadResponses = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const filterStringParam = filterString ? `&filterString=${filterString}` : '';
  const searchTextParam = searchText ? `&searchString=${searchText}` : '';
  const url = buildQueryParams(REST_URL_RESPONSES, pageNumber, pageSize, orderBy, '')
    .concat(filterStringParam)
    .concat(searchTextParam);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadResponse = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_RESPONSE.replace(/\{id\}/, id), token);
  return response;
};

export const uploadPreviewFormResponse = async (token, payload) => (
  sendPostRequest(REST_URL_UPLOAD_PREVIEW_FORM_RESPONSE, payload, token)
);
