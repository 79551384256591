import { PAGE_MODE_TABLE } from '../../../constant';
import { deleteRoom, downloadRoom } from '../../../helper';
import {
  addRoom, clearRooms, downloadingDeletingRoom, setAlertErrorMessage, setFunctionalPageMode,
  setRoomSelectedLocationId,
  setRoomTappedId,
} from '../simple-action';
import downloadRoomsAsync from './downloadRoomsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingRoom(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiRoom;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteRoom(tappedId, token);
      dispatch(setRoomTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearRooms());
      dispatch(downloadRoomsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const room = await downloadRoom(tappedId, token);
      if (room?.location?.id) {
        dispatch(setRoomSelectedLocationId(room.location.id));
      }
      dispatch(addRoom(room));
    }
  } finally {
    dispatch(downloadingDeletingRoom(false));
  }
};
