import { reset } from 'redux-form';
import { PAGE_MODE_TABLE, RXFORM_SESSION_CATEGORY } from '../../../constant';
import { addSessionCategory, editSessionCategory } from '../../../helper';
import {
  addingEditingSessionCategory,
  clearSessionCategories,
  setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import downloadSessionCategoriesAsync from './downloadSessionCategoriesAsync';

export default (name, description) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingSessionCategory(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSessionCategory;

    if (tappedId) {
      await editSessionCategory(tappedId, name, description, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addSessionCategory(name, description, token);
    }

    dispatch(reset(RXFORM_SESSION_CATEGORY));
    dispatch(clearSessionCategories());
    dispatch(downloadSessionCategoriesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingSessionCategory(false));
  }
};
