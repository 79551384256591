import {
  setUploadingPreviewFormResponse, setAlertSuccessMessage,
} from '../simple-action';
import { uploadPreviewFormResponse } from '../../../helper';
import { ENUM_FIELD_PHOTO } from '../../../constant';
import LocalizedString from '../../../localization';

export default (valArray, form, referenceData) => async (dispatch, getState) => {
  try {
    dispatch(setUploadingPreviewFormResponse(true));
    const { authentication, currentUser } = getState();
    const { token } = authentication;
    const { id: userId } = currentUser;

    const responseArray = form.fields.map((field) => {
      const findIdx = valArray.findIndex((e) => e.fieldId === field.id);
      return {
        fieldId: field.id,
        value: findIdx !== -1 ? valArray[findIdx].value : null,
        ...(field.fieldType === ENUM_FIELD_PHOTO && {
          fileExtension: findIdx !== -1 ? valArray[findIdx].fileExtension : null,
        }),
      };
    });

    const payload = {
      formId: form.id,
      userId,
      referenceId: referenceData.referenceId,
      referenceLabel: referenceData.referenceLabel,
      responses: responseArray,
    };

    await uploadPreviewFormResponse(token, payload);
    dispatch(setAlertSuccessMessage(LocalizedString.previewPage.labelResponseSubmitted));
  } finally {
    dispatch(setUploadingPreviewFormResponse(false));
  }
};
