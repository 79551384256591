import { INITIAL_ORDER_BY_SESSION_OPERATOR_MAPPINGS, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableSessionOperatorMapping } from '../../../helper';
import {
  enablingDisablingSessionOperatorMapping, setAlertErrorMessage,
  setSessionOperatorMappingSelectedOrderBy, setSessionOperatorMappingSelectedPageSize,
} from '../simple-action';
import downloadSessionOperatorMappingsAsync from './downloadSessionOperatorMappingsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingSessionOperatorMapping(true));

    const { token } = getState().authentication;
    const { data } = getState().sessionOperatorMappings;
    const { tappedId } = getState().uiSessionOperatorMapping;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableSessionOperatorMapping(tappedId, status, token);

    dispatch(setSessionOperatorMappingSelectedPageSize(20));
    dispatch(setSessionOperatorMappingSelectedOrderBy(INITIAL_ORDER_BY_SESSION_OPERATOR_MAPPINGS));
    dispatch(downloadSessionOperatorMappingsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingSessionOperatorMapping(false));
  }
};
