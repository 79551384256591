import { change } from 'redux-form';
import { setPreviewSelectedForm, downloadingPreviewSelectedForm, setPreviewFormFieldValue } from '../simple-action';
import { downloadForm } from '../../../helper';
import { RXFORM_PREVIEW } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingPreviewSelectedForm(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiForm;

    const formDetail = await downloadForm(tappedId, token);
    dispatch(setPreviewSelectedForm({ ...formDetail, formKey: Date.now() }));
    let fieldValue = {};
    formDetail.fields.forEach((field) => {
      if (field.defaultValue || field.defaultValue === false) {
        dispatch(change(RXFORM_PREVIEW, field.name, field.defaultValue));
        fieldValue = {
          ...fieldValue,
          [field.name]: {
            fieldId: field.id,
            value: field.defaultValue,
          },
        };
      }
    });
    dispatch(setPreviewFormFieldValue(fieldValue));
  } finally {
    dispatch(downloadingPreviewSelectedForm(false));
  }
};
