import {
  DOWNLOADING_PROFILES, ADDING_EDITING_PROFILE, DOWNLOADING_DELETING_PROFILE,
  SENDING_PROFILE_NOTIFICATION, SET_PROFILE_SELECTED_PAGE_SIZE,
  SET_PROFILE_TAPPED_ID, SET_PROFILE_SEARCH_TEXT, SET_PROFILE_SELECTED_ORDER_BY,
  SET_PROFILE_SELECTED_BLOOD_TYPE, SET_PROFILE_SELECTED_BRANCH_ID, SET_PROFILE_SELECTED_GENDER,
  SET_PROFILE_SELECTED_TIMEZONE, SET_PROFILE_SELECTED_COMPANY, SET_PROFILE_SELECTED_DEPARTMENT,
  SET_PROFILE_SELECTED_DIVISION, SET_PROFILE_SELECTED_MANAGER, SET_PROFILE_MANAGER_SEARCH_TEXT,
  DOWNLOADING_MANAGERS, ENABLING_DISABLING_PROFILE, SAVING_PROFILES,
  SET_PROFILE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_PROFILE_SELECTED_WORKING_SHIFT_ID, SET_PROFILE_SELECTED_SHIFTING_USER,
} from '../action';
import { INITIAL_ORDER_BY_PROFILES } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  downloadingManagers: false,
  enablingDisabling: false,
  saving: false,
  sendingNotification: false,
  selectedPageSize: 20,
  filterString: '',
  tappedId: '',
  searchBarText: '',
  selectedBloodType: '',
  selectedBranchId: '',
  selectedGender: '',
  selectedTimezone: '',
  selectedCompany: '',
  selectedDepartment: '',
  selectedDivision: '',
  selectedManager: '',
  selectedWorkingShift: '',
  selectedShiftingUser: null,
  managerSearchText: '',
  orderBy: INITIAL_ORDER_BY_PROFILES,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_PROFILES:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_PROFILE:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_PROFILE:
      return { ...state, downloadingDeleting: action.status };
    case SENDING_PROFILE_NOTIFICATION:
      return { ...state, sendingNotification: action.status };
    case SET_PROFILE_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_PROFILE_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_PROFILE_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_PROFILE_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_PROFILE_SELECTED_BLOOD_TYPE:
      return { ...state, selectedBloodType: action.name };
    case SET_PROFILE_SELECTED_BRANCH_ID:
      return { ...state, selectedBranchId: action.id };
    case SET_PROFILE_SELECTED_GENDER:
      return { ...state, selectedGender: action.name };
    case SET_PROFILE_SELECTED_TIMEZONE:
      return { ...state, selectedTimezone: action.option };
    case SET_PROFILE_SELECTED_COMPANY:
      return { ...state, selectedCompany: action.option };
    case SET_PROFILE_SELECTED_DEPARTMENT:
      return { ...state, selectedDepartment: action.option };
    case SET_PROFILE_SELECTED_DIVISION:
      return { ...state, selectedDivision: action.option };
    case SET_PROFILE_SELECTED_MANAGER:
      return { ...state, selectedEmployee: action.option };
    case SET_PROFILE_SELECTED_SHIFTING_USER:
      return { ...state, selectedShiftingUser: action.option };
    case SET_PROFILE_MANAGER_SEARCH_TEXT:
      return { ...state, managerSearchText: action.text };
    case DOWNLOADING_MANAGERS:
      return { ...state, downloadingManagers: action.status };
    case ENABLING_DISABLING_PROFILE:
      return { ...state, enablingDisabling: action.status };
    case SAVING_PROFILES:
      return { ...state, saving: action.status };
    case SET_PROFILE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_PROFILE_SELECTED_WORKING_SHIFT_ID:
      return { ...state, selectedWorkingShift: action.option };
    default: return state;
  }
};
