import { downloadSessionOperatorRegistration } from '../../../helper';
import {
  addSessionOperatorRegistration, downloadingDeletingSessionOperatorRegistration,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingSessionOperatorRegistration(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSessionOperatorRegistration;

    const SessionOperatorRegistration = await downloadSessionOperatorRegistration(tappedId, token);
    dispatch(addSessionOperatorRegistration(SessionOperatorRegistration));
  } finally {
    dispatch(downloadingDeletingSessionOperatorRegistration(false));
  }
};
