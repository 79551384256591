import {
  ADDING_EDITING_VOUCHER, DOWNLOADING_VOUCHERS, DOWNLOADING_DELETING_VOUCHER,
  ENABLING_DISABLING_VOUCHER, SET_VOUCHER_SELECTED_PAGE_SIZE, SET_VOUCHER_TAPPED_ID,
  SET_VOUCHER_SEARCH_TEXT, SET_VOUCHER_SELECTED_ORDER_BY, SET_VOUCHER_SELECTED_MERCHANT_ID,
  SET_VOUCHER_SELECTED_VOUCHER_CATEGORY_ID, ADDING_VOUCHER_APPLICABLE_BRANCHES,
  SET_VOUCHER_SELECTED_APPLICABLE_BRANCHES, SET_VOUCHER_TABLE_DATA_APPLICABLE_BRANCHES,
  SET_VOUCHER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, COPYING_VOUCHER_DEEP_LINK,
  SET_VOUCHER_SELECTED_IS_PROMOTED, SAVING_VOUCHERS,
} from '../action';
import { INITIAL_ORDER_BY_VOUCHERS } from '../../constant';

const initialState = {
  addingApplicableBranches: false,
  addingEditing: false,
  copying: false,
  downloadingDeleting: false,
  downloading: false,
  enablingDisabling: false,
  saving: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_VOUCHERS,
  selectedApplicableBranches: null,
  selectedMerchantId: '',
  selectedVoucherCategoryId: '',
  tableDataApplicableBranches: [],
  filterString: '',
  selectedIsPromoted: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_VOUCHERS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_VOUCHER:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_VOUCHER:
      return { ...state, downloadingDeleting: action.status };
    case ENABLING_DISABLING_VOUCHER:
      return { ...state, enablingDisabling: action.status };
    case SAVING_VOUCHERS:
      return { ...state, saving: action.status };
    case ADDING_VOUCHER_APPLICABLE_BRANCHES:
      return { ...state, addingApplicableBranches: action.status };
    case SET_VOUCHER_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_VOUCHER_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_VOUCHER_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_VOUCHER_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_VOUCHER_SELECTED_MERCHANT_ID:
      return { ...state, selectedMerchantId: action.id };
    case SET_VOUCHER_SELECTED_VOUCHER_CATEGORY_ID:
      return { ...state, selectedVoucherCategoryId: action.id };
    case SET_VOUCHER_SELECTED_APPLICABLE_BRANCHES:
      return { ...state, selectedApplicableBranches: action.option };
    case SET_VOUCHER_TABLE_DATA_APPLICABLE_BRANCHES:
      return { ...state, tableDataApplicableBranches: action.data };
    case SET_VOUCHER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case COPYING_VOUCHER_DEEP_LINK:
      return { ...state, copying: action.status };
    case SET_VOUCHER_SELECTED_IS_PROMOTED:
      return { ...state, selectedIsPromoted: action.data };
    default: return state;
  }
};
