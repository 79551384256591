import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, FormGroup, FormHelperText, Typography, makeStyles,
} from '@material-ui/core';
import {
  COLOR_BACKGROUND, COLOR_BLACK_INACTIVE, COLOR_DANGEROUS, COLOR_PRIMARY,
} from '../constant';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  optionContainer: {
    borderRadius: 50,
    padding: 8,
    height: 32,
    alignItems: 'center',
    marginVertical: 4,
    marginRight: 8,
  },
  backgroundActive: {
    backgroundColor: COLOR_PRIMARY,
  },
  backgroundInactive: {
    backgroundColor: COLOR_BLACK_INACTIVE,
  },
  errorTextStyle: {
    fontStyle: 'italic',
    color: COLOR_DANGEROUS,
    marginLeft: 14,
  },
}));

const ButtonPicker = ({
  data, onValueSelected,
  disabled, error,
  selectedValue, booleanMode, label,
  defaultValue,
}) => {
  const [selectedValueTemp, setSelectedValueTemp] = useState(selectedValue || defaultValue);

  const classes = useStyles();

  const onSelect = (item) => {
    const comparedValue = booleanMode ? item.value : item.label;
    const returnVal = selectedValue === comparedValue ? '' : comparedValue;
    onValueSelected(returnVal);
    setSelectedValueTemp(returnVal);
  };

  const renderOptions = (item) => {
    const selected = booleanMode
      ? selectedValueTemp === item.value
      : selectedValueTemp === item.label;
    return (
      <Button
        onClick={() => onSelect(item)}
        disabled={disabled}
        key={item.label}
        className={`${classes.optionContainer} ${selected ? classes.backgroundActive : classes.backgroundInactive}`}
        variant="contained"
        color="primary"
      >
        <Typography style={{ color: COLOR_BACKGROUND, fontSize: 12 }}>
          {item.label}
        </Typography>
      </Button>
    );
  };

  const renderError = () => {
    if (error) {
      return (<FormHelperText className={classes.errorTextStyle}>{error}</FormHelperText>);
    }
    return null;
  };

  return (
    <div className={classes.container}>
      <Typography style={{ marginBottom: '5px' }}>{label}</Typography>
      <FormGroup>
        <div className={classes.buttonsContainer}>
          {data.map((option) => renderOptions(option))}
        </div>
        {renderError()}
      </FormGroup>
    </div>
  );
};

export default ButtonPicker;

const pickerItemShape = PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  label: PropTypes.string,
});

const arrayShape = PropTypes.arrayOf(pickerItemShape);

ButtonPicker.propTypes = {
  data: arrayShape.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  onValueSelected: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  selectedValue: PropTypes.oneOfType([
    pickerItemShape,
    PropTypes.string,
    PropTypes.bool,
  ]),
  booleanMode: PropTypes.bool,
};

ButtonPicker.defaultProps = {
  label: '',
  defaultValue: '',
  onValueSelected: () => {},
  booleanMode: false,
  disabled: false,
  error: '',
  selectedValue: undefined,
};
