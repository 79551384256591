import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const SIMPLE_ACTION_NAME = 'SIMPLE_ACTION_NAME';
export const simpleActionName = makeActionCreator(SIMPLE_ACTION_NAME, 'actionValueName');


export const DOWNLOADING_CAPTCHA_REQUESTS = 'DOWNLOADING_CAPTCHA_REQUESTS';
export const downloadingCaptchaRequests = makeActionCreator(DOWNLOADING_CAPTCHA_REQUESTS, 'status');

export const SET_CAPTCHA_REQUESTS = 'SET_CAPTCHA_REQUESTS';
export const setCaptchaRequests = makeActionCreator(SET_CAPTCHA_REQUESTS, 'data');

export const ADD_CAPTCHA_REQUEST = 'ADD_CAPTCHA_REQUEST';
export const addCaptchaRequest = makeActionCreator(ADD_CAPTCHA_REQUEST, 'data');

export const CLEAR_CAPTCHA_REQUESTS = 'CLEAR_CAPTCHA_REQUESTS';
export const clearCaptchaRequests = makeActionCreator(CLEAR_CAPTCHA_REQUESTS);

export const DOWNLOADING_DELETING_CAPTCHA_REQUEST = 'DOWNLOADING_DELETING_CAPTCHA_REQUEST';
export const downloadingDeletingCaptchaRequest = makeActionCreator(DOWNLOADING_DELETING_CAPTCHA_REQUEST, 'status');


export const SET_CAPTCHA_REQUEST_SELECTED_PAGE_SIZE = 'SET_CAPTCHA_REQUEST_SELECTED_PAGE_SIZE';
export const setCaptchaRequestSelectedPageSize = makeActionCreator(SET_CAPTCHA_REQUEST_SELECTED_PAGE_SIZE, 'size');

export const SET_CAPTCHA_REQUEST_SELECTED_ORDER_BY = 'SET_CAPTCHA_REQUEST_SELECTED_ORDER_BY';
export const setCaptchaRequestSelectedOrderBy = makeActionCreator(SET_CAPTCHA_REQUEST_SELECTED_ORDER_BY, 'order');

export const SET_CAPTCHA_REQUEST_TAPPED_ID = 'SET_CAPTCHA_REQUEST_TAPPED_ID';
export const setCaptchaRequestTappedId = makeActionCreator(SET_CAPTCHA_REQUEST_TAPPED_ID, 'id');

export const SET_CAPTCHA_REQUEST_SEARCH_TEXT = 'SET_CAPTCHA_REQUEST_SEARCH_TEXT';
export const setCaptchaRequestSearchText = makeActionCreator(SET_CAPTCHA_REQUEST_SEARCH_TEXT, 'text');

export const SET_CAPTCHA_REQUEST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_CAPTCHA_REQUEST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setCaptchaRequestAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_CAPTCHA_REQUEST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');
