import { connect } from 'react-redux';
import {
  approval, DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_EVENT_OPERATOR_EVENTS,
  MENUID_EVENT_OPERATOR_EVENT, PAGE_MODE_TABLE, EVENT_QR_CODE_PATH,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE,
} from '../../constant';
import {
  isAdditionalItemYoutubeUrlType, sortAsc, toMoment, transformInitialValues,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  clearEventOperatorEvents, copyingEventOperatorEventQrCode, setActiveSideMenuItem,
  setAlertErrorMessage,
  setEventOperatorEventSearchText, setEventOperatorEventSelectedOrderBy,
  setEventOperatorEventSelectedPageSize, setEventOperatorEventTappedId,
  downloadEventOperatorEventsAsync, downloadEventOperatorEventAsync,
} from '../../redux/action';
import EventOperatorEventPage from './event-operator-event.presentation';

const transformVoucherDropdownData = (data) => Object.values(data).map((item) => ({
  label: item.voucher.voucherName,
  value: item.voucher.id,
}
));

const transformSpeakers = (data) => (data.length > 0
  ? data.map((x) => (x.speaker.id
    ? { label: x.speaker.name, value: x.speaker.id, order: x.speaker.order } : x))
    .sort((a, b) => sortAsc(a.order, b.order))
  : []);

const getDiscusId = (state) => {
  const { eventOperatorEvents, uiEventOperatorEvent, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiEventOperatorEvent;
  const { pageMode } = uiFunctionalPage;

  return pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? eventOperatorEvents.data[tappedId].discussionId : '';
};

const getInitialValues = (state) => {
  const { eventOperatorEvents, uiEventOperatorEvent, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiEventOperatorEvent;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? eventOperatorEvents.data[tappedId] : {};
  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      enableComment: found.enableComment ? approval[0].label : approval[1],
      registrationRewardVouchers: found?.registrationRewardVouchers
        ? transformVoucherDropdownData(found?.registrationRewardVouchers) : [],
      speakers: transformSpeakers(found.speakers),
      category: found?.category ? { label: found.category.name, value: found.category.id } : '',
      location: found?.location ? { label: found.location.name, value: found.location.id } : '',
      clockInMustBeInsideArea: found?.clockInMustBeInsideArea
        ? { label: GlobalLocalizedString.common.labelYes, value: true }
        : { label: GlobalLocalizedString.common.labelNo, value: false },
      isFeatured: found?.isFeatured
        ? { label: GlobalLocalizedString.common.labelYes, value: true }
        : { label: GlobalLocalizedString.common.labelNo, value: false },
      needApproval: found?.needApproval
        ? { label: GlobalLocalizedString.common.labelYes, value: true }
        : { label: GlobalLocalizedString.common.labelNo, value: false },
      canPayUsingPoint: found?.canPayUsingPoint
        ? { label: GlobalLocalizedString.common.labelYes, value: true }
        : { label: GlobalLocalizedString.common.labelNo, value: false },
      startTime: found?.startTime ? toMoment(found?.startTime, found.location?.city?.province.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
      endTime: found?.endTime ? toMoment(found?.endTime, found.location?.city?.province.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
      registrationRewardVoucherIds: found?.registrationRewardVouchers
        ? transformVoucherDropdownData(found?.registrationRewardVouchers) : [],
      additionalItems: found.additionalItems ? found.additionalItems.map((x) => ({
        ...x,
        url: isAdditionalItemYoutubeUrlType(x) ? x.path : undefined,
      })) : [],
      clockInQrCode: found.randomId ? `${EVENT_QR_CODE_PATH}${found.randomId}` : null,
      banner: found.banner ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.banner}` : null,
    }) : {};
  return result;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  copying: state.uiEventOperatorEvent.copying,
  downloading: state.uiEventOperatorEvent.downloading,
  downloadingDeleting: state.uiEventOperatorEvent.downloadingDeleting,
  discusId: getDiscusId(state),
  pageMode: state.uiFunctionalPage.pageMode,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_EVENT_OPERATOR_EVENT));
    dispatch(setEventOperatorEventSearchText(''));
    dispatch(clearEventOperatorEvents());
    dispatch(setEventOperatorEventSelectedPageSize(20));
    dispatch(setEventOperatorEventSelectedOrderBy(INITIAL_ORDER_BY_EVENT_OPERATOR_EVENTS));
    dispatch(downloadEventOperatorEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadEventOperatorEventsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setEventOperatorEventSelectedPageSize(pageSize));
    dispatch(downloadEventOperatorEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCopyPressed: (status) => {
    dispatch(copyingEventOperatorEventQrCode(!status));
  },
  onRefresh: (pageSize) => {
    dispatch(setEventOperatorEventSelectedPageSize(pageSize));
    dispatch(clearEventOperatorEvents());
    dispatch(downloadEventOperatorEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setEventOperatorEventSearchText(text));
      dispatch(clearEventOperatorEvents());
      await dispatch(downloadEventOperatorEventsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setEventOperatorEventSelectedOrderBy(orderBy));
    dispatch(clearEventOperatorEvents());
    dispatch(downloadEventOperatorEventsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setEventOperatorEventTappedId(id));
    dispatch(downloadEventOperatorEventAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EventOperatorEventPage);
