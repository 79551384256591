import {
  REST_URL_PAYMENTS,
  REST_URL_VIEW_PAYMENT,
  REST_URL_REPUBLISH_PAYMENT,
} from './constant';
import {
  buildQueryParams, sendGetRequest, transformQueryResult, sendPostRequest,
} from '../../helper';

export * from '../../helper';

export const downloadPayments = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const filterStringParam = filterString ? `&filterString=${filterString}` : '';
  const searchTextParam = searchText ? `&searchString=${searchText}` : '';
  const url = buildQueryParams(REST_URL_PAYMENTS, pageNumber, pageSize, orderBy, '')
    .concat(filterStringParam)
    .concat(searchTextParam);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadPayment = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_PAYMENT.replace(/\{id\}/, id), token);
  return response;
};

export const republishPayment = async (id, token) => {
  const body = { id };
  await sendPostRequest(REST_URL_REPUBLISH_PAYMENT, body, token);
};
