import { reset } from 'redux-form';
import { RXFORM_SESSION_INVITATION } from '../../../constant';
import { addSessionInvitation } from '../../../helper';
import { addingSessionInvitation, clearSessionInvitations, setAlertErrorMessage } from '../simple-action';
import downloadSessionInvitationsAsync from './downloadSessionInvitationsAsync';


export default () => async (dispatch, getState) => {
  try {
    dispatch(addingSessionInvitation(true));

    const { token } = getState().authentication;
    const { sessionId, recipient } = getState().uiSessionInvitation;
    const emails = recipient.reduce((acc, obj) => {
      acc.push(obj.value);
      return acc;
    }, []);

    await addSessionInvitation(sessionId, emails, token);

    dispatch(reset(RXFORM_SESSION_INVITATION));
    dispatch(clearSessionInvitations());
    dispatch(downloadSessionInvitationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingSessionInvitation(false));
  }
};
