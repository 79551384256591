import { getDownloadFileExtension, saveEventOperatorRegistrations, transformSearchText } from '../../../helper';
import '../../../../../component/functional-page/download-file.css';
import { savingEventOperatorRegistrations } from '../simple-action';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { searchBarText, orderBy, filterString } = getState().uiEventOperatorRegistration;
  const { global } = getState().myConfigItems;

  const fileExtension = getDownloadFileExtension(global?.exportFormat || '');

  try {
    dispatch(savingEventOperatorRegistrations(true));

    const response = await saveEventOperatorRegistrations(
      1,
      20,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `event-operator-registration${fileExtension}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  } finally {
    dispatch(savingEventOperatorRegistrations(false));
  }
};
