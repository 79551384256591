import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import FlatList from 'flatlist-react';
import { Body } from './labels';
import LocalizedString from '../localization';
import { COLOR_BODY_TEXT_LIGHT, COLOR_PRIMARY } from '../constant';
import { StyleShape } from '../type';

const styles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    height: '80vh',
  },
  noItemContainer: {
    display: 'flex',
    height: '30px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  refreshingIndicator: {
    width: '28px',
    height: '28px',
    position: 'absolute',
    background: 'white',
    padding: '5px',
    borderRadius: '50px',
    left: '48%',
    right: '50%',
    top: '30px',
    zIndex: '2',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
  },
};

const renderComponentWhenEmpty = (refreshing, data) => {
  if (refreshing && (!data || data.length === 0)) {
    return (
      <div style={styles.container}>
        <CircularProgress sx={{ color: COLOR_PRIMARY }} size={36} />
      </div>
    );
  }

  return (
    <div style={styles.noItemContainer}>
      <Body style={{ color: COLOR_BODY_TEXT_LIGHT, fontSize: 14 }}>
        {LocalizedString.common.errMsgNoResultFound}
      </Body>
    </div>
  );
};

const MyFlatList = ({
  data, refreshing, showFooterActivityIndicator, loadingContainerStyle,
  loadingIndicatorSize, ...props
}) => {
  if (refreshing && (!data || data.length === 0)) {
    return (
      <div style={{ ...styles.container, ...loadingContainerStyle }}>
        <CircularProgress sx={{ color: COLOR_PRIMARY }} size={loadingIndicatorSize} />
      </div>
    );
  }

  return (
    <div style={{ position: 'relative' }}>
      {props.listHeaderComponent || null}
      {
        refreshing && (data || data.length !== 0)
        && (
          <CircularProgress sx={{ color: COLOR_PRIMARY }} style={styles.refreshingIndicator} />
        )
      }
      {props.loadMoreItems
        ? (
          <div className="flatlist-scroll-container" style={{ overflow: 'auto', height: props.scrollHeight, ...props.containerStyle }}>
            <FlatList
              list={data}
              renderWhenEmpty={() => renderComponentWhenEmpty(
                refreshing,
                data,
              )}
              paginationLoadingIndicator={
                <CircularProgress sx={{ color: COLOR_PRIMARY }} size={36} />
              }
              paginationLoadingIndicatorPosition="center"
              {...props}
            />
          </div>
        )
        : (
          <FlatList
            list={data}
            renderWhenEmpty={() => renderComponentWhenEmpty(
              refreshing,
              data,
            )}
            {...props}
          />
        )}
    </div>
  );
};

export default MyFlatList;

MyFlatList.propTypes = {
  listHeaderComponent: PropTypes.node,

  refreshing: PropTypes.bool,
  showFooterActivityIndicator: PropTypes.bool,

  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])).isRequired,

  loadMoreItems: PropTypes.func,

  scrollHeight: PropTypes.string,

  loadingIndicatorSize: PropTypes.number,

  containerStyle: StyleShape,
  loadingContainerStyle: StyleShape,
};

MyFlatList.defaultProps = {
  listHeaderComponent: null,
  refreshing: false,
  showFooterActivityIndicator: false,
  loadMoreItems: null,
  scrollHeight: '80vh',
  containerStyle: '',
  loadingContainerStyle: {},
  loadingIndicatorSize: 36,
};
