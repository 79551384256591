import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { AccentButton, FunctionalPage, SectionTitle } from '../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DROPDOWN, PICKER_MODE_DATE_TIME,
  EVENT_OPERATOR_REGISTRATION_STATUS,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_APPROVED_BY,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_APPROVED_ON,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_CLOCK_IN_DRIFT,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_CLOCK_IN_LATITUDE,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_CLOCK_IN_LOCATION_STATUS,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_CLOCK_IN_LONGITUDE,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_CLOCK_IN_TIME,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_DISCOUNT,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_DISCOUNT_NOTES,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_ID,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_LAST_CHANGED_STATUS,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_CHANNEL,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_CODE,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_DATE,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_STATUS,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_STATUS_NOTES,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_POINT_DISCOUNT,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_POINT_DISCOUNT_ID,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_PRICE,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_SERVICE_FEE,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_TAX,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_TOTAL,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_TRANSACTION_ID,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_REGISTRATION_CODE,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_REGISTRATION_STATUS,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_REGISTRATION_STATUS_NOTES,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_REJECTED_BY,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_REJECTED_ON,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_EVENT_END_TIME,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_EVENT_ID,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_EVENT_NAME,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_EVENT_SHORT_DESCRIPTION,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_EVENT_START_TIME,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_EVENT_TIMEZONE,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_USER_EMAIL,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_USER_FULLNAME,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_USER_ID,
  RXFIELD_EVENT_OPERATOR_REGISTRATION_USER_PHONE,
  RXSTATE_EVENT_OPERATOR_REGISTRATIONS,
  RXSTATE_EVENT_OPERATOR_REGISTRATION_PAGE,
  RXFORM_EVENT_OPERATOR_REGISTRATION,
} from '../../constant';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { toMoment } from '../../helper';
import { renderReduxFormDateTimePickerField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';

const useStyles = makeStyles(() => ({
  fabContainer: {
    marginRight: 10,
  },
  multipleFabContainer: {
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const renderDialogContent = (initialValues) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_REGISTRATION_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderRegistrationCode}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderRegistrationCode}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_REGISTRATION_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage
              .placeholderRegistrationStatus}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderRegistrationStatus}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_REGISTRATION_STATUS_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage
              .placeholderRegistrationStatusNotes}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderRegistrationStatusNotes}
            disabled
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_APPROVED_ON}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderApprovedOn}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderApprovedOn}
            disabled
            hidden={initialValues.registrationStatus !== EVENT_OPERATOR_REGISTRATION_STATUS[0]}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_APPROVED_BY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderApprovedBy}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderApprovedBy}
            disabled
            hidden={initialValues.registrationStatus !== EVENT_OPERATOR_REGISTRATION_STATUS[0]}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_REJECTED_ON}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderRejectedOn}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderRejectedOn}
            disabled
            hidden={initialValues.registrationStatus !== EVENT_OPERATOR_REGISTRATION_STATUS[1]}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_REJECTED_BY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderRejectedBy}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderRejectedBy}
            disabled
            hidden={initialValues.registrationStatus !== EVENT_OPERATOR_REGISTRATION_STATUS[1]}
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_CLOCK_IN_TIME}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderClockInTime}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderClockInTime}
            disabled
            pickerMode={PICKER_MODE_DATE_TIME}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_CLOCK_IN_DRIFT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderClockInDrift}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderClockInDrift}
            disabled
            type="number"
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_CLOCK_IN_LATITUDE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderClockInLatitude}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderClockInLatitude}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_CLOCK_IN_LONGITUDE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderClockInLongitude}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderClockInLongitude}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_CLOCK_IN_LOCATION_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage
              .placeholderClockInLocationStatus}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderClockInLocationStatus}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>

    <SectionTitle title={LocalizedString.eventOperatorRegistrationPage.placeholderUser} />
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_USER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderUserID}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderUserID}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_USER_FULLNAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderUserFullName}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderUserFullName}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_USER_EMAIL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderUserEmail}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderUserEmail}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_USER_PHONE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderUserPhone}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderUserPhone}
            disabled
            type="number"
          />
        </Grid>
      </Grid>
    </Grid>

    <SectionTitle title={LocalizedString.eventOperatorRegistrationPage.placeholderEvent} />
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_EVENT_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderEventID}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderEventID}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_EVENT_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderEventName}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderEventName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_EVENT_SHORT_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage
              .placeholderEventShortDescription}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderEventShortDescription}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_EVENT_START_TIME}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderEventStartTime}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderEventStartTime}
            disabled
            pickerMode={PICKER_MODE_DATE_TIME}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_EVENT_END_TIME}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderEventEndTime}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderEventEndTime}
            disabled
            pickerMode={PICKER_MODE_DATE_TIME}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_EVENT_TIMEZONE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderEventTimezone}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderEventTimezone}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>

    <SectionTitle title={LocalizedString.eventOperatorRegistrationPage.placeholderPayment} />
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderPaymentID}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderPaymentID}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_DATE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderPaymentDate}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderPaymentDate}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_TRANSACTION_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderTransactionID}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderTransactionID}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderPaymentCode}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderPaymentCode}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_CHANNEL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderPaymentChannel}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderPaymentChannel}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderPaymentStatus}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderPaymentStatus}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_STATUS_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage
              .placeholderPaymentStatusNotes}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderPaymentStatusNotes}
            disabled
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_LAST_CHANGED_STATUS}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderLastChangedStatus}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderLastChangedStatus}
            disabled
            pickerMode={PICKER_MODE_DATE_TIME}
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_PRICE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderPrice}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderPrice}
            disabled
            currencyTextFormat={initialValues.price !== LocalizedString
              .eventOperatorRegistrationPage
              .labelFree}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_TAX}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderTax}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderTax}
            disabled
            currencyTextFormat
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_SERVICE_FEE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderServiceFee}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderServiceFee}
            disabled
            currencyTextFormat
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_DISCOUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderDiscount}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderDiscount}
            disabled
            currencyTextFormat
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_DISCOUNT_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderDiscountNotes}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderDiscountNotes}
            disabled
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_POINT_DISCOUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderPointDiscount}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderPointDiscount}
            disabled
            currencyTextFormat
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_POINT_DISCOUNT_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderPointDiscountID}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderPointDiscountID}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_REGISTRATION_PAYMENT_TOTAL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventOperatorRegistrationPage.placeholderTotal}
            label={LocalizedString.eventOperatorRegistrationPage.placeholderTotal}
            disabled
            currencyTextFormat
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const EventOperatorRegistrationPage = ({
  initialValues, profiles, events,
  approving, downloading, downloadingDeleting, downloadingProfiles, downloadingEvents,
  isApprovalAllowed, rejecting,
  onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed, onApprovePressed,
  onChangePage, onChangePageSize, onChangeEventText, onChangeParticipantText,
  onConfirmApproveRejectPressed, onDownloadPressed, onRefresh, onRejectPressed,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
}) => {
  const classes = useStyles();

  const renderCustomFabComponent = () => {
    if (initialValues.registrationStatus === EVENT_OPERATOR_REGISTRATION_STATUS[2]
      && isApprovalAllowed) {
      return (
        <div className={classes.multipleFabContainer}>
          <div className={classes.fabContainer}>
            <AccentButton
              variant="text"
              disabled={downloadingDeleting || approving}
              loading={rejecting}
              disableElevation
              caption={LocalizedString.eventOperatorRegistrationPage.buttonCaptionReject}
              onClick={onRejectPressed}
            />
          </div>
          <AccentButton
            disabled={downloadingDeleting || rejecting}
            loading={approving}
            disableElevation
            caption={LocalizedString.eventOperatorRegistrationPage.buttonCaptionApprove}
            onClick={onApprovePressed}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <FunctionalPage
      data={RXSTATE_EVENT_OPERATOR_REGISTRATIONS}
      uiPage={RXSTATE_EVENT_OPERATOR_REGISTRATION_PAGE}
      tableColumns={[
        {
          title: GlobalLocalizedString.common.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.eventOperatorRegistrationPage.labelParticipant, field: 'user.fullName', sorting: !downloading },
        { title: LocalizedString.eventOperatorRegistrationPage.labelEvent, field: 'event.name', sorting: !downloading },
        { title: LocalizedString.eventOperatorRegistrationPage.labelRegistrationCode, field: 'registrationCode', sorting: !downloading },
        { title: LocalizedString.eventOperatorRegistrationPage.labelRegistrationStatus, field: 'registrationStatus', sorting: !downloading },
        {
          title: LocalizedString.eventOperatorRegistrationPage.labelApprovedOn,
          field: 'approvedRejectedOn',
          sorting: !downloading,
          render: ({ approvedRejectedOn, registrationStatus }) => (
            registrationStatus === 'Approved' && approvedRejectedOn
              ? toMoment(approvedRejectedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        {
          title: LocalizedString.eventOperatorRegistrationPage.labelRejectedOn,
          field: 'approvedRejectedOn',
          sorting: !downloading,
          render: ({ approvedRejectedOn, registrationStatus }) => (
            registrationStatus !== 'Approved' && approvedRejectedOn
              ? toMoment(approvedRejectedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        { title: LocalizedString.eventOperatorRegistrationPage.labelPaymentStatus, field: 'payment.paymentStatus', sorting: !downloading },
        { title: LocalizedString.eventOperatorRegistrationPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
      ]}
      filterColumns={[
        {
          title: LocalizedString.eventOperatorRegistrationPage.labelEvent,
          field: 'event',
          type: FILTER_TYPE_DROPDOWN,
          data: events,
          loading: downloadingEvents,
          onChangeFilterText: onChangeEventText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.eventOperatorRegistrationPage.labelParticipant,
          field: 'participant',
          type: FILTER_TYPE_DROPDOWN,
          data: profiles,
          loading: downloadingProfiles,
          onChangeFilterText: onChangeParticipantText,
          useDropdownValue: true,
        },
      ]}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmApproveRejectPressed}
      onDownloadPressed={onDownloadPressed}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      title={LocalizedString.eventOperatorRegistrationPage.title}
      renderCustomFabComponent={() => renderCustomFabComponent()}
      disableCreate
      disableEdit
      disableDelete
      useFullWidth
      savePermissionName="EVENT_DOWNLOAD_EVENT_REGISTRATION_FOR_OPERATOR"
    >
      {renderDialogContent(initialValues)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_EVENT_OPERATOR_REGISTRATION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(EventOperatorRegistrationPage);

EventOperatorRegistrationPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  profiles: PropTypes.arrayOf(SimpleDataShape).isRequired,
  events: PropTypes.arrayOf(SimpleDataShape).isRequired,
  approving: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  downloadingProfiles: PropTypes.bool.isRequired,
  downloadingEvents: PropTypes.bool.isRequired,
  isApprovalAllowed: PropTypes.bool.isRequired,
  rejecting: PropTypes.bool.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onApprovePressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeEventText: PropTypes.func.isRequired,
  onChangeParticipantText: PropTypes.func.isRequired,
  onConfirmApproveRejectPressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onRejectPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
