import { downloadRegistrationReferrals, transformSearchText } from '../../../helper';
import { downloadingRegistrationReferrals, setRegistrationReferrals } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, registrationReferrals, uiRegistrationReferral,
  } = getState();
  const { token } = authentication;
  const { meta } = registrationReferrals;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiRegistrationReferral;

  try {
    dispatch(downloadingRegistrationReferrals(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadRegistrationReferrals(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setRegistrationReferrals(list));
    }
  } finally {
    dispatch(downloadingRegistrationReferrals(false));
  }
};
