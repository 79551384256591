import {
  buildQueryParams, sendDeleteRequest, sendGetFileRequest, sendGetRequest, sendPostRequest,
  sendPutRequest, transformQueryResult, toUtcMoment, toMoment, removeBase64StringPrefix,
} from '../../helper';
import {
  REST_BASE_URL, REST_URL_IMAGE_STORAGE,
  REST_URL_PACKET_TYPES, REST_URL_ADD_EDIT_PACKET_TYPE, REST_URL_VIEW_DELETE_PACKET_TYPE,
  REST_URL_PICKUP_LOCATIONS, REST_URL_ADD_EDIT_PICKUP_LOCATION,
  REST_URL_VIEW_DELETE_PICKUP_LOCATION,
  REST_URL_PACKETS, REST_URL_ADD_EDIT_PACKET, REST_URL_VIEW_DELETE_PACKET, REST_URL_SAVE_PACKET,
  REST_URL_PICKUP_PACKET,
  REST_URL_SUMMARY_TRENDS, REST_URL_SUMMARY_TOP_RECIPIENTS, REST_URL_SUMMARY_PACKET_TYPES,
  REST_URL_SUMMARY_LOCATIONS,
  PACKET_SUMMARY_GROUPING_BY_DATE, PACKET_SUMMARY_GROUPING_BY_MONTH,
  PACKET_SUMMARY_CURRENT_PERIOD, PACKET_SUMMARY_PREVIOUS_PERIOD, BASE_64_PREFIX,
} from './constant';

export * from '../../helper';

export const downloadPickupLocations = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_PICKUP_LOCATIONS.concat(filterString), pageNumber, pageSize,
      orderBy, searchText)
    : buildQueryParams(REST_URL_PICKUP_LOCATIONS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addPickupLocation = async (name, description, keepPacketForHours, token) => {
  const body = {
    name,
    description,
    keepPacketForHours: keepPacketForHours ? Number(keepPacketForHours) : null,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_PICKUP_LOCATION, body, token);
};

export const editPickupLocation = async (id, name, description, keepPacketForHours, token) => {
  const body = {
    id,
    name,
    description,
    keepPacketForHours: keepPacketForHours ? Number(keepPacketForHours) : null,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_PICKUP_LOCATION, body, token);
};

export const downloadPickupLocation = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_PICKUP_LOCATION.replace(/\{id\}/, id), token);
  return response;
};

export const deletePickupLocation = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_PICKUP_LOCATION.replace(/\{id\}/, id), token);
};

export const downloadPacketTypes = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_PACKET_TYPES.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_PACKET_TYPES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addPacketType = async (name, description, keepPacketForHours, token) => {
  const body = {
    name,
    description,
    keepPacketForHours: keepPacketForHours ? Number(keepPacketForHours) : null,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_PACKET_TYPE, body, token);
};

export const editPacketType = async (id, name, description, keepPacketForHours, token) => {
  const body = {
    id,
    name,
    description,
    keepPacketForHours: keepPacketForHours ? Number(keepPacketForHours) : null,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_PACKET_TYPE, body, token);
};

export const downloadPacketType = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_PACKET_TYPE.replace(/\{id\}/, id), token);
  return response;
};

export const deletePacketType = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_PACKET_TYPE.replace(/\{id\}/, id), token);
};

const transformPacket = (data) => ({
  ...data,
  arrivalImage: data.arrivalImage ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${data.arrivalImage}` : null,
  pickedUpImage: data.pickedUpImage ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${data.pickedUpImage}` : null,
});

export const downloadPackets = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_PACKETS.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_PACKETS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const transformResponse = response.data.map((x) => transformPacket(x));
  return transformQueryResult(response, transformResponse);
};

const getImageValueWithoutBase64Prefix = (image) => {
  if (image) {
    const isImageStartsWithPrefix = image.startsWith(BASE_64_PREFIX);
    const newImage = isImageStartsWithPrefix ? removeBase64StringPrefix(image) : image;
    return newImage;
  }
  return image;
};

export const addPacket = async (sender, recipientProfileId, pickupLocationId, packetTypeId,
  arrivalNotes, ArrivalImage, token) => {
  const newArrivalImage = getImageValueWithoutBase64Prefix(ArrivalImage);

  const body = {
    sender,
    recipientProfileId,
    pickupLocationId,
    packetTypeId,
    arrivalNotes,
    ArrivalImage: newArrivalImage,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_PACKET, body, token);
};

export const editPacket = async (id, sender, recipientProfileId, pickupLocationId, packetTypeId,
  arrivalNotes, ArrivalImage, token) => {
  const newArrivalImage = getImageValueWithoutBase64Prefix(ArrivalImage);

  const body = {
    id,
    sender,
    recipientProfileId,
    pickupLocationId,
    packetTypeId,
    arrivalNotes,
    ArrivalImage: newArrivalImage,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_PACKET, body, token);
};

export const downloadPacket = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_PACKET.replace(/\{id\}/, id), token);
  const transformResponse = transformPacket(response);
  return transformResponse;
};

export const deletePacket = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_PACKET.replace(/\{id\}/, id), token);
};

export const savePackets = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_SAVE_PACKET.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_SAVE_PACKET, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const pickUpPacket = async (id, PickedUpByProfileId, pickedUpByNonUser, PickedUpNotes,
  PickedupImage, token) => {
  const newPickedupImage = getImageValueWithoutBase64Prefix(PickedupImage);

  const body = {
    id,
    PickedUpByProfileId,
    pickedUpByNonUser,
    PickedUpNotes,
    PickedupImage: newPickedupImage,
  };
  await sendPutRequest(REST_URL_PICKUP_PACKET, body, token);
};

const buildSummaryQueryParams = (templateUrl, period, grouping) => {
  let startDate;
  let endDate;
  switch (period) {
    case PACKET_SUMMARY_CURRENT_PERIOD:
      switch (grouping) {
        case PACKET_SUMMARY_GROUPING_BY_DATE:
          startDate = toUtcMoment(toMoment().startOf('month'));
          endDate = toUtcMoment(toMoment().endOf('month'));
          break;
        case PACKET_SUMMARY_GROUPING_BY_MONTH:
          startDate = toUtcMoment(toMoment().startOf('year'));
          endDate = toUtcMoment(toMoment().endOf('year'));
          break;
        default: break;
      }
      break;
    case PACKET_SUMMARY_PREVIOUS_PERIOD:
      switch (grouping) {
        case PACKET_SUMMARY_GROUPING_BY_DATE:
          startDate = toUtcMoment(toMoment().subtract(1, 'months').startOf('month'));
          endDate = toUtcMoment(toMoment().subtract(1, 'months').endOf('month'));
          break;
        case PACKET_SUMMARY_GROUPING_BY_MONTH:
          startDate = toUtcMoment(toMoment().subtract(1, 'years').startOf('year'));
          endDate = toUtcMoment(toMoment().subtract(1, 'years').endOf('year'));
          break;
        default: break;
      }
      break;
    default: break;
  }

  return templateUrl
    .replace(/\{startDate\}/, startDate)
    .replace(/\{endDate\}/, endDate)
    .replace(/\{grouping\}/, grouping);
};

export const downloadSummaryTrends = async (period, grouping, token) => {
  const url = buildSummaryQueryParams(REST_URL_SUMMARY_TRENDS, period, grouping);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadSummaryTopRecipients = async (period, grouping, token) => {
  const url = buildSummaryQueryParams(REST_URL_SUMMARY_TOP_RECIPIENTS, period, grouping);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadSummaryPacketTypes = async (period, grouping, token) => {
  const url = buildSummaryQueryParams(REST_URL_SUMMARY_PACKET_TYPES, period, grouping);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadSummaryLocations = async (period, grouping, token) => {
  const url = buildSummaryQueryParams(REST_URL_SUMMARY_LOCATIONS, period, grouping);
  const response = await sendGetRequest(url, token);
  return response;
};
