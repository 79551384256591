import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  localDateToUtc, transformDropdownData,
  transformInitialValues,
  toMoment,
  getTimeRegion,
  debounceSearch,
  getPermission,
  transformEmailDropdownData,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import EventInvitationPage from './event-invitation.presentation';
import {
  INITIAL_ORDER_BY_EVENT_INVITATIONS, MENUID_EVENT_EVENT_INVITATION,
  PAGE_MODE_TABLE, RXFIELD_EVENT_INVITATION_EVENT,
  RXFIELD_EVENT_INVITATION_RECIPIENTS, RXFORM_EVENT_INVITATION,
} from '../../constant';
import {
  clearEventInvitations, clearEvents, clearProfiles, setActiveSideMenuItem,
  setAlertConfirmationMessage,
  setAlertErrorMessage, setEventAdvancedFilterDialogSelectedFilterString,
  setEventInvitationAdvancedFilterDialogSelectedFilterString,
  setEventInvitationSearchText, setEventInvitationSelectedEventId,
  setEventInvitationSelectedOrderBy,
  setEventInvitationSelectedPageSize, setEventInvitationSelectedRecipient,
  setEventInvitationTappedId, setEventSearchText,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
  addEventInvitationAsync, downloadDeleteEventInvitationAsync,
  downloadEventInvitationsAsync, downloadEventsAsync,
  resendEventInvitationAsync, saveEventInvitationsAsync,
} from '../../redux/action';
import { downloadProfilesAsync } from '../../../../redux/action';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getInitialValues = (state) => {
  const { eventInvitations, uiEventInvitation, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiEventInvitation;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? eventInvitations.data[tappedId] : {};

  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      {
        endTime: found?.event?.endTime ? toMoment(found?.event?.endTime, found.event.location.city.province.timeZone) : '',
        eventId: found?.event?.id || '',
        fullName: found?.user?.fullName || '',
        location: found?.event?.location?.name || '',
        name: found?.event?.name || '',
        phone: found?.user?.phone || '',
        registeredOn: found?.registeredOn ? localDateToUtc(found?.registeredOn, found.event.location.city.province.timeZone) : '',
        shortDescription: found?.event?.shortDescription || '',
        startTime: found?.event?.startTime ? toMoment(found?.event?.startTime, found.event.location.city.province.timeZone) : '',
        userId: found?.user?.id || '',
        timeZone: found?.event?.startTime || found?.event?.endTime ? getTimeRegion(found.event.location.city.province.timeZone) : '',
      })
    : {
      endTime: '',
      email: '',
      event: { label: '', value: '' },
      eventId: '',
      fullname: '',
      invitationStatus: { label: '', value: '' },
      name: '',
      phone: '',
      recipients: [],
      resentCount: 0,
      registeredOn: '',
      shortDescription: '',
      startTime: '',
      userId: '',
      timeZone: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  events: transformDropdownData(state.events.data),
  profiles: transformEmailDropdownData(state.profiles.data),
  eventInvitations: state.eventInvitations.data,
  addingEditing: state.uiEventInvitation.addingEditing,
  downloading: state.uiEventInvitation.downloading,
  downloadingEvents: state.uiEvent.downloading,
  downloadingProfiles: state.uiProfile.downloading,
  resending: state.uiEventInvitation.resending,
  recipientValue: state.uiEventInvitation.recipient,
  initialValues: getInitialValues(state),
  isResendAllowed: getPermission(state, 'EVENT_RESEND_EVENT_INVITATION'),
});

const searchProfileDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchEventDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);


const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setEventInvitationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_EVENT_EVENT_INVITATION));
    dispatch(setEventInvitationSearchText(''));
    dispatch(clearEventInvitations());
    dispatch(setEventInvitationSelectedPageSize(20));
    dispatch(setEventInvitationSelectedOrderBy(INITIAL_ORDER_BY_EVENT_INVITATIONS));
    dispatch(downloadEventInvitationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setEventAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setEventSearchText(''));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/event/, 'event.id');
    dispatch(setEventInvitationAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearEventInvitations());
    dispatch(downloadEventInvitationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_EVENT_INVITATION));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadEventInvitationsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setEventInvitationSelectedPageSize(pageSize));
    dispatch(downloadEventInvitationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeEventText: (text, changeAdvancedFilter) => {
    dispatch(setEventAdvancedFilterDialogSelectedFilterString(
      changeAdvancedFilter ? '' : 'status=enabled|visibilityStatus=visible',
    ));
    dispatch(setEventSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchEventDebounce(dispatch);
    }
  },
  onChangeRecipientText: (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchProfileDebounce(dispatch);
    }
  },
  onConfirmContextMenuPressed: (reason, message) => {
    if (message === GlobalLocalizedString.common.msgDeleteConfirmation) {
      dispatch(downloadDeleteEventInvitationAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else if (message === LocalizedString.eventInvitationPage.msgResendConfirmation) {
      dispatch(resendEventInvitationAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }
  },
  onCreatePressed: () => {
    dispatch(setEventInvitationTappedId(''));

    dispatch(setProfileAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled|visibilityStatus=visible'));
    dispatch(setEventSearchText(''));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onDeletePressed: (id) => {
    dispatch(setEventInvitationTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onDownloadPressed: () => {
    dispatch(saveEventInvitationsAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onEventsTypeSelected: (option) => {
    if (option) {
      dispatch(setEventInvitationSelectedEventId(option.value));
      dispatch(change(RXFORM_EVENT_INVITATION, RXFIELD_EVENT_INVITATION_EVENT, option));
    } else {
      dispatch(setEventInvitationSelectedEventId(''));
      dispatch(change(RXFORM_EVENT_INVITATION, RXFIELD_EVENT_INVITATION_EVENT, ''));
    }
  },
  onRecipientsTypeSelected: (option) => {
    if (option) {
      dispatch(setEventInvitationSelectedRecipient(option));
      dispatch(change(RXFORM_EVENT_INVITATION, RXFIELD_EVENT_INVITATION_RECIPIENTS, option));
    } else {
      dispatch(setEventInvitationSelectedRecipient([]));
      dispatch(change(RXFORM_EVENT_INVITATION, RXFIELD_EVENT_INVITATION_RECIPIENTS, []));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setEventInvitationSelectedPageSize(pageSize));
    dispatch(clearEventInvitations());
    dispatch(downloadEventInvitationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResendPressed: (id) => {
    dispatch(setEventInvitationTappedId(id));
    dispatch(setAlertConfirmationMessage(
      LocalizedString.eventInvitationPage.msgResendConfirmation,
    ));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setEventInvitationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearEventInvitations());
    dispatch(setEventInvitationSelectedOrderBy(INITIAL_ORDER_BY_EVENT_INVITATIONS));
    dispatch(downloadEventInvitationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setEventInvitationSearchText(text));
      dispatch(clearEventInvitations());
      await dispatch(downloadEventInvitationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setEventInvitationSelectedOrderBy(orderBy));
    dispatch(clearEventInvitations());
    dispatch(downloadEventInvitationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ event, recipients }) => {
    await dispatch(addEventInvitationAsync(event, recipients));
  },
  onViewPressed: (id) => {
    dispatch(setEventInvitationTappedId(id));
    dispatch(downloadDeleteEventInvitationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EventInvitationPage);
