import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  clearBranches, clearCompanies, clearDepartments, clearDivisions, clearManagers, clearProfiles,
  setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setBranchAdvancedFilterDialogSelectedFilterString, setBranchSearchText,
  setCompanySearchText, setDepartmentSearchText, setDivisionSearchText,
  setNotificationSelectedBranch, setNotificationSelectedCompany, setNotificationSelectedDepartment,
  setNotificationSelectedDivision, setProfileAdvancedFilterDialogSelectedFilterString,
  setProfileManagerSearchText, setProfileSearchText, setProfileSelectedPageSize,
  setProfileSelectedBranchId, setProfileSelectedCompany, setProfileSelectedDepartment,
  setProfileSelectedDivision, setProfileSelectedManager, setProfileSelectedOrderBy,
  setProfileSelectedTimezone, setProfileTappedId,
  downloadBranchesAsync, downloadCompaniesAsync, downloadDepartmentsAsync, downloadDivisionsAsync,
  downloadManagersAsync, downloadProfileAsync, downloadProfilesAsync, editProfileAsync,
  enableDisableProfileAsync, saveProfilesAsync, sendProfileNotificationAsync,
  setProfileSelectedWorkingShiftId, setWorkingShiftSearchText,
  setWorkingShiftAdvancedFilterDialogSelectedFilterString, clearWorkingShifts,
  downloadWorkingShiftsAsync,
  setProfileSelectedShiftingUser,
} from '../../redux/action';
import {
  DROPDOWN_OPTION_CLEAR, INITIAL_ORDER_BY_PROFILES, MENUID_PERSONALIZATION_PROFILE, PAGE_MODE_TABLE,
  RXFIELD_PROFILE_AUTO_CLOCK_OUT_AFTER_MINUTES, RXFIELD_PROFILE_BRANCH,
  RXFIELD_PROFILE_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES, RXFIELD_PROFILE_END_AFTER_MIN,
  RXFIELD_PROFILE_END_BEFORE_MIN, RXFIELD_PROFILE_END_WORKING_HOUR,
  RXFIELD_PROFILE_SHIFTING_USER,
  RXFIELD_PROFILE_START_AFTER_MIN,
  RXFIELD_PROFILE_START_BEFORE_MIN,
  RXFIELD_PROFILE_START_WORKING_HOUR, RXFIELD_PROFILE_WORKING_HOUR_TIMEZONE,
  RXFIELD_PROFILE_WORKING_SHIFT, RXFORM_ADVANCED_FILTER_DIALOG, RXFORM_PROFILE,
  TIME_MODE_CLOCK_IN, TIME_MODE_CLOCK_OUT, approval,
} from '../../constant';
import {
  convertClockInOutMinutesToHours, convertClockInOutHoursToMinutes, transformDropdownData,
  transformInitialValues, toNumber, transformUserDropdownData, debounceSearch,
} from '../../helper';
import ProfilePage from './profile.presentation';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../module/configuration/redux/action';

const getAddressRegion = (address) => {
  if (!address) return '';
  const { city, district, subDistrict } = address;
  if (city) {
    return `${city.name} - ${city.province.name}`;
  }
  if (district) {
    return `${district.name} - ${district.city.name} - ${district.city.province.name}`;
  }
  if (subDistrict) {
    return `${subDistrict.name} - ${subDistrict.district.name} - ${subDistrict.district.city.name} - ${subDistrict.district.city.province.name}`;
  }
  return '';
};

const getInitialValues = (state) => {
  const { profiles, uiProfile, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiProfile;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? profiles.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      {
        manager: found.manager ? found.manager.fullName : '',
        branch: found.branch ? { label: found.branch.name, value: found.branch.id } : null,
        gender: { label: found.gender, value: found.gender },
        workingHourTimeZone: found.workingHourTimeZone !== null ? found.workingHourTimeZone : '',
        earliestClockInDriftTimeMinutes: convertClockInOutHoursToMinutes(
          found.earliestClockInDriftTimeMinutes, found.startWorkingHour, TIME_MODE_CLOCK_IN,
        ),
        autoClockOutAfterMinutes: convertClockInOutHoursToMinutes(
          found.autoClockOutAfterMinutes, found.endWorkingHour, TIME_MODE_CLOCK_OUT,
        ),
        homeAddressRegion: getAddressRegion(found.homeAddress),
        workAddressRegion: getAddressRegion(found.workAddress),
        workingShift: found.workingShift
          ? { label: found.workingShift.name, value: found.workingShift.id }
          : { label: '', value: '' },
      })
    : {
      gender: { label: '', value: '' },
      branch: { label: '', value: '' },
      workingShift: { label: '', value: '' },
      workingHourTimeZone: '',
      homeAddressRegion: '',
      workAddressRegion: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  branches: transformDropdownData(state.branches.data),
  companies: transformDropdownData(state.companies.data),
  departments: transformDropdownData(state.departments.data),
  divisions: transformDropdownData(state.divisions.data),
  managers: transformUserDropdownData(state.managers.data),
  workingShifts: transformDropdownData(state.workingShifts.data),
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiProfile.addingEditing,
  downloading: state.uiProfile.downloading,
  enablingDisabling: state.uiProfile.enablingDisabling,
  loadingBranch: state.uiBranch.downloading,
  loadingCompanies: state.notification.downloadingCompanies,
  loadingDepartments: state.notification.downloadingDivisions,
  loadingDivisions: state.notification.downloadingDepartments,
  loadingManagers: state.uiProfile.downloadingManagers,
  loadingWorkingShifts: state.uiWorkingShift.downloading,
  sendingNotification: state.uiProfile.sendingNotification,
  initialValues: getInitialValues(state),
  tappedId: state.uiFunctionalPage.tappedId || '',
  currentProfileStatus: state.profiles.data[state.uiFunctionalPage.tappedId]
    ? state.profiles.data[state.uiFunctionalPage.tappedId].status : '',
  selectedBranchId: state.uiProfile.selectedBranchId,
  shiftingUser: state.uiProfile.selectedShiftingUser,
});

const searchWorkingShiftDebounce = debounceSearch(
  (dispatch, selectedBranchId) => {
    dispatch(setWorkingShiftAdvancedFilterDialogSelectedFilterString(
      `status=enabled|branch.id=${selectedBranchId}`,
    ));
    dispatch(clearWorkingShifts());
    dispatch(downloadWorkingShiftsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setBranchAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setBranchSearchText(''));
    dispatch(setProfileSelectedBranchId(''));
    dispatch(setProfileSelectedCompany(''));
    dispatch(setProfileSelectedDepartment(''));
    dispatch(setProfileSelectedDivision(''));
    dispatch(setProfileSelectedManager(''));
    dispatch(setProfileSelectedShiftingUser(null));
    dispatch(clearBranches());
    dispatch(clearCompanies());
    dispatch(clearDepartments());
    dispatch(clearDivisions());
    dispatch(clearManagers());
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDepartmentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDivisionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadManagersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_PERSONALIZATION_PROFILE));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(setProfileSelectedPageSize(20));
    dispatch(setProfileSelectedOrderBy(INITIAL_ORDER_BY_PROFILES));
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString, shiftingUser) => {
    let filterText = filterString;
    if (filterText && filterText.includes(RXFIELD_PROFILE_SHIFTING_USER)) {
      const filterKey = `|${RXFIELD_PROFILE_SHIFTING_USER}=`;
      const filterYesLabel = `${filterKey}${approval[0].label}`;
      const filterYesValue = `${filterKey}${approval[0].value}`;
      const filterNoLabel = `${filterKey}${approval[1].label}`;
      const filterNoValue = `${filterKey}${approval[1].value}`;

      if (filterText.includes(filterYesLabel)) {
        filterText = filterText.replace(filterYesLabel, '');
      }
      if (filterText.includes(filterYesValue)) {
        filterText = filterText.replace(filterYesValue, '');
      }
      if (filterText.includes(filterNoLabel)) {
        filterText = filterText.replace(filterNoLabel, '');
      }
      if (filterText.includes(filterNoValue)) {
        filterText = filterText.replace(filterNoValue, '');
      }
    }
    let text = '';
    text = filterText
      .replace('|', '')
      .replace(/branch/, 'branch.id')
      .replace(/workingShift/, 'workingShift.id')
      .replace(/manager/, 'manager.id');
    if (shiftingUser !== null) {
      if (shiftingUser) {
        text += '|workingShift!=$NULL$';
      } else {
        text = text.replace('|workingShift!=$NULL$', '');
      }
    }
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAutoClockOutChanged: (date) => {
    if (date) {
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_AUTO_CLOCK_OUT_AFTER_MINUTES, date));
    } else {
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_AUTO_CLOCK_OUT_AFTER_MINUTES, ''));
    }
  },
  onBranchOptionSelected: (option, changeForm = true) => {
    if (option) {
      dispatch(setProfileSelectedBranchId(option.value));
      dispatch(setProfileSelectedWorkingShiftId(''));
      if (changeForm) {
        dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_BRANCH, option));
        dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_WORKING_SHIFT, ''));
      } else {
        dispatch(change(RXFORM_ADVANCED_FILTER_DIALOG, RXFIELD_PROFILE_WORKING_SHIFT, ''));
      }

      dispatch(setWorkingShiftAdvancedFilterDialogSelectedFilterString(
        `${changeForm ? 'status=enabled' : ''}|branch.id=${option.value}`,
      ));
      dispatch(setWorkingShiftSearchText(''));
      dispatch(clearWorkingShifts());
      dispatch(downloadWorkingShiftsAsync(1))
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else {
      dispatch(setProfileSelectedBranchId(''));
      dispatch(setProfileSelectedWorkingShiftId(''));
      if (changeForm) {
        dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_BRANCH, ''));
        dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_WORKING_SHIFT, ''));
      } else {
        dispatch(change(RXFORM_ADVANCED_FILTER_DIALOG, RXFIELD_PROFILE_WORKING_SHIFT, ''));
      }
    }
  },
  onShiftingUserOptionSelected: (option) => {
    if (option) {
      dispatch(setProfileSelectedShiftingUser(option.value));
    } else {
      dispatch(setProfileSelectedShiftingUser(null));
    }
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(setProfileSelectedBranchId(''));
    dispatch(setProfileSelectedCompany(''));
    dispatch(setProfileSelectedDepartment(''));
    dispatch(setProfileSelectedDivision(''));
    dispatch(setProfileSelectedManager(''));
    dispatch(setProfileSelectedShiftingUser(null));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_PROFILE));
  },
  onChangeBranchText: async (text) => {
    try {
      dispatch(setBranchAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setBranchSearchText(text));
      dispatch(clearBranches());
      await dispatch(downloadBranchesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeCompanyText: async (text) => {
    try {
      dispatch(setCompanySearchText(text));
      dispatch(clearCompanies());
      await dispatch(downloadCompaniesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeDepartmentText: async (text) => {
    try {
      dispatch(setDepartmentSearchText(text));
      dispatch(clearDepartments());
      await dispatch(downloadDepartmentsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeDivisionText: async (text) => {
    try {
      dispatch(setDivisionSearchText(text));
      dispatch(clearDivisions());
      await dispatch(downloadDivisionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeManagerText: async (text) => {
    try {
      dispatch(setProfileManagerSearchText(text));
      dispatch(clearManagers());
      await dispatch(downloadManagersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadProfilesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setProfileSelectedPageSize(pageSize));
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCompanyOptionSelected: (option) => {
    if (option) {
      dispatch(setProfileSelectedCompany(option.value));
    } else {
      dispatch(setProfileSelectedCompany(''));
    }
  },
  onConfirmEnableDisablePressed: () => {
    dispatch(enableDisableProfileAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDepartmentOptionSelected: (option) => {
    if (option) {
      dispatch(setProfileSelectedDepartment(option.value));
    } else {
      dispatch(setProfileSelectedDepartment(''));
    }
  },
  onDivisionOptionSelected: (option) => {
    if (option) {
      dispatch(setProfileSelectedDivision(option.value));
    } else {
      dispatch(setProfileSelectedDivision(''));
    }
  },
  onDownloadPressed: () => {
    dispatch(saveProfilesAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onEarliestClockInChanged: (date) => {
    if (date) {
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES, date));
    } else {
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES, ''));
    }
  },
  onEditPressed: (id) => {
    dispatch(setProfileTappedId(id));
    dispatch(downloadProfileAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setBranchAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setBranchSearchText(''));
    dispatch(clearBranches());
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onEnableDisablePressed: (id, message) => {
    dispatch(setProfileTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onEndWorkingHourChanged: (date) => {
    if (date) {
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_END_WORKING_HOUR, date));
    } else {
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_END_WORKING_HOUR, ''));
    }
  },
  onManagerOptionSelected: (option) => {
    if (option) {
      dispatch(setProfileSelectedManager(option.value));
    } else {
      dispatch(setProfileSelectedManager(''));
    }
  },
  onOpenSendNotificationDialog: () => {
    dispatch(setBranchAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setBranchSearchText(''));
    dispatch(setCompanySearchText(''));
    dispatch(setDepartmentSearchText(''));
    dispatch(setDivisionSearchText(''));
    dispatch(setNotificationSelectedBranch(''));
    dispatch(setNotificationSelectedCompany(''));
    dispatch(setNotificationSelectedDepartment(''));
    dispatch(setNotificationSelectedDivision(''));
    dispatch(clearBranches());
    dispatch(clearCompanies());
    dispatch(clearDepartments());
    dispatch(clearDivisions());
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDepartmentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDivisionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setProfileSelectedPageSize(pageSize));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSelectedBranchId(''));
    dispatch(setProfileSelectedCompany(''));
    dispatch(setProfileSelectedDepartment(''));
    dispatch(setProfileSelectedDivision(''));
    dispatch(setProfileSelectedManager(''));
    dispatch(setProfileSelectedShiftingUser(null));
    dispatch(clearProfiles());
    dispatch(setProfileSelectedOrderBy(INITIAL_ORDER_BY_PROFILES));
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    startWorkingHour, startWorkingHourMarginBeforeMinutes, startWorkingHourMarginAfterMinutes,
    endWorkingHour, endWorkingHourMarginBeforeMinutes, endWorkingHourMarginAfterMinutes,
    earliestClockInDriftTimeMinutes, autoClockOutAfterMinutes, workingHourTimeZone,
  }) => {
    try {
      await dispatch(editProfileAsync(startWorkingHour,
        toNumber(startWorkingHourMarginBeforeMinutes), toNumber(startWorkingHourMarginAfterMinutes),
        endWorkingHour, toNumber(endWorkingHourMarginBeforeMinutes),
        toNumber(endWorkingHourMarginAfterMinutes),
        convertClockInOutMinutesToHours(earliestClockInDriftTimeMinutes, startWorkingHour,
          endWorkingHour, TIME_MODE_CLOCK_IN),
        convertClockInOutMinutesToHours(autoClockOutAfterMinutes, startWorkingHour,
          endWorkingHour, TIME_MODE_CLOCK_OUT), workingHourTimeZone));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setProfileSearchText(text));
      dispatch(clearProfiles());
      await dispatch(downloadProfilesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSendNotificationPressed: async (values) => {
    await dispatch(sendProfileNotificationAsync(values));
  },
  onSortPressed: (orderBy) => {
    dispatch(setProfileSelectedOrderBy(orderBy));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onStartWorkingHourChanged: (date) => {
    if (date) {
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_START_WORKING_HOUR, date));
    } else {
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_START_WORKING_HOUR, ''));
    }
  },
  onTimeZoneOptionSelected: (option) => {
    if (option !== DROPDOWN_OPTION_CLEAR) {
      dispatch(setProfileSelectedTimezone(option));
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_WORKING_HOUR_TIMEZONE, option));
    } else {
      dispatch(setProfileSelectedTimezone(''));
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_WORKING_HOUR_TIMEZONE, ''));
    }
  },
  onViewPressed: (id) => {
    dispatch(setProfileTappedId(id));
    dispatch(downloadProfileAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCustomWorkingHourResetButtonPressed: () => {
    dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_START_WORKING_HOUR, ''));
    dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_END_WORKING_HOUR, ''));
    dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_WORKING_HOUR_TIMEZONE, ''));
    dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_START_BEFORE_MIN, ''));
    dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_START_AFTER_MIN, ''));
    dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES, ''));
    dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_END_BEFORE_MIN, ''));
    dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_END_AFTER_MIN, ''));
    dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_AUTO_CLOCK_OUT_AFTER_MINUTES, ''));
  },
  onWorkingShiftOptionSelected: (option, changeForm = true) => {
    if (option) {
      dispatch(setProfileSelectedWorkingShiftId(option.value));
      if (changeForm) {
        dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_WORKING_SHIFT, option));
      } else {
        dispatch(change(RXFORM_ADVANCED_FILTER_DIALOG, RXFIELD_PROFILE_WORKING_SHIFT, option));
      }
    } else {
      dispatch(setProfileSelectedWorkingShiftId(''));
      if (changeForm) {
        dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_WORKING_SHIFT, ''));
      } else {
        dispatch(change(RXFORM_ADVANCED_FILTER_DIALOG, RXFIELD_PROFILE_WORKING_SHIFT, ''));
      }
    }
  },
  onChangeWorkingShiftText: async (text, selectedBranchId) => {
    dispatch(setWorkingShiftSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchWorkingShiftDebounce(dispatch, selectedBranchId);
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
