import { connect } from 'react-redux';
import { change } from 'redux-form';
import {
  MENUID_VOUCHER_TOKEN_ASSIGNMENT, PAGE_MODE_TABLE, INITIAL_ORDER_BY_VOUCHER_TOKEN_ASSIGNMENTS,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  RXFORM_VOUCHER_TOKEN_ASSIGNMENT, RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_ASSIGNMENT_MODE,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_BYPASS_PAYMENT,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_BYPASS_MAX_PURCHASE,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_BYPASS_ACHIEVEMENT,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_USERS, ASSIGNMENT_MODE_GENERATE_NEW_CODE,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_VOUCHER,
  ASSIGNMENT_MODE_USE_AVAILABEL_CODE, RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_VALID_FROM,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_VALID_UNTIL,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_METHOD,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_VALID_FROM,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_VALID_UNTIL,
  ASSIGNMENT_METHOD_IMMEDIATELY, STATUS_ENABLED, switches,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_MODE,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VALID_FROM,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VALID_UNTIL,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_BYPASS_PAYMENT,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_BYPASS_MAX_PURCHASE,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_BYPASS_ACHIEVEMENT,
  PAGE_MODE_EDIT, RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CODE,
} from '../../constant';
import {
  debounceSearch, toMoment, transformInitialValues, transformUserDropdownData,
} from '../../helper';
import {
  clearVoucherTokenAssignments, downloadVoucherTokenAssignmentsAsync, setActiveSideMenuItem,
  setAlertErrorMessage, setVoucherTokenAssignmentOrderBy, setVoucherTokenAssignmentPageSize,
  setVoucherTokenAssignmentSearchText, setVoucherTokenAssignmentTappedId,
  downloadVoucherTokenAssignmentAsync, setAdvancedFilterDialogSelectedFilterString,
  setVoucherSearchText, clearVouchers, downloadVouchersAsync,
  setVoucherTokenAssignmentSelectedVoucher, setVoucherTokenAssignmentSelectedAssignmentMode,
  setVoucherTokenAssignmentSelectedBypassPayment,
  setVoucherTokenAssignmentSelectedBypassMaxPurchase,
  setVoucherTokenAssignmentSelectedBypassAchievement, setVoucherTokenAssignmentSelectedUser,
  addEditVoucherTokenAssignmentAsync, setVoucherAdvancedFilterDialogSelectedFilterString,
  clearClaimers, downloadClaimersAsync, setClaimerSearchText,
  setVoucherTokenAssignmentAssignmentMethod,
  setVoucherTokenAssignmentAssignmentValidFrom,
  setVoucherTokenAssignmentAssignmentValidUntil,
  setVoucherTokenAssignmentCopyingRedemptionCode,
  enableDisableVoucherTokenAssignmentAsync,
  setAlertConfirmationMessage,
  setProfileAdvancedFilterDialogSelectedFilterString,
  setProfileSearchText,
  clearProfiles,
  setVoucherTokenClaimerAdvancedFilterDialogSelectedFilterString,
} from '../../redux/action';
import VoucherTokenAssignmentPage from './voucher-token-assignment.presentation';
import GlobalLocalizedString from '../../../../localization';
import { downloadProfilesAsync } from '../../../../redux/action';


const getInitialValues = (state) => {
  const { uiFunctionalPage, uiVoucherTokenAssignment, voucherTokenAssignments } = state;
  const { data } = voucherTokenAssignments;
  const {
    tappedId, downloadingDeleting,
    selectedAssignmentMode, selectedBypassPayment,
    selectedBypassMaxPurchase, selectedBypassAchievement,
  } = uiVoucherTokenAssignment;
  const { pageMode } = uiFunctionalPage;

  const found = data && pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    voucherId: found.voucher?.id || '',
    voucherName: found.voucher?.title || '',
    voucherTokenId: found.voucherToken?.id || '',
    code: found.code || '',
    validFrom: found.validFrom ? toMoment(found.validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    validUntil: found.validUntil ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    bypassPayment: selectedBypassPayment,
    bypassMaxPurchaseValidation: selectedBypassMaxPurchase,
    bypassAchievementValidation: selectedBypassAchievement,
    notes: found.notes || '',
    assignedById: found.assignedBy?.id || '',
    assignedByName: found.assignedBy?.fullName || '',
    assignedToId: found.assignedTo?.id || '',
    assignedToName: found.assignedTo?.fullName || '',
    image: found.voucher.image,
    codeAssignmentMode: selectedAssignmentMode || found.codeAssignmentMode || '',
    notificationMessageTitle: found.notificationMessageTitle || '',
    notificationMessageBody: found.notificationMessageBody || '',
    assignmentMethod: found.assignmentMethod || '',
    assignmentValidFrom: found.assignmentValidFrom ? toMoment(found.assignmentValidFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    assignmentValidUntil: found.assignmentValidUntil ? toMoment(found.assignmentValidUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    redemptionCode: found.id ? `giftvoucher:${found.id}` : '',
  }) : {
    voucherId: '',
    voucherName: '',
    voucherTokenId: '',
    codeAssignmentMode: '',
    validFrom: '',
    validUntil: '',
    bypassPayment: switches[2],
    bypassMaxPurchaseValidation: switches[2],
    bypassAchievementValidation: switches[2],
    code: '',
    notes: '',
    assignedById: '',
    assignedByName: '',
    assignedToId: '',
    assignedToName: '',
    image: '',
    notificationMessageTitle: '',
    notificationMessageBody: '',
    assignmentMethod: '',
    assignmentValidFrom: '',
    assignmentValidUntil: '',
    redemptionCode: '',
  };
  return result;
};

const transformVoucherDropdownData = (data) => Object.values(data).map((item) => ({
  label: item.title,
  value: item.id,
}));


const mapStateToProps = (state) => ({
  addingEditing: state.uiVoucherTokenAssignment.addingEditing,
  downloading: state.uiVoucherTokenAssignment.downloading,
  downloadingDeleting: state.uiVoucherTokenAssignment.downloadingDeleting,
  tappedId: state.uiFunctionalPage.tappedId || '',
  searchBarText: state.uiVoucherTokenAssignment.searchBarText,
  selectedVoucher: state.uiVoucherTokenAssignment.selectedVoucher || '',
  selectedAssignmentMode: state.uiVoucherTokenAssignment.selectedAssignmentMode || '',
  selectedAssignmentMethod: state.uiVoucherTokenAssignment.selectedAssignmentMethod || '',
  selectedBypassPayment: state.uiVoucherTokenAssignment.selectedBypassPayment || '',
  selectedBypassMaxPurchase: state.uiVoucherTokenAssignment.selectedBypassMaxPurchase || '',
  selectedBypassAchievement: state.uiVoucherTokenAssignment.selectedBypassAchievement || '',
  selectedUser: state.uiVoucherTokenAssignment.selectedUser || '',
  vouchers: transformVoucherDropdownData(state.vouchers.data) || [],
  loadingVoucher: state.uiVoucher.downloading,
  users: transformUserDropdownData(state.profiles.data),
  assignedTo: transformUserDropdownData(state.claimers.data),
  loadingUser: state.uiProfile.downloading,
  loadingAssignedTo: state.uiVoucherToken.downloadingClaimers,
  pageMode: state.uiFunctionalPage.pageMode,
  copying: state.uiVoucherTokenAssignment.copying,
  enablingDisabling: state.uiVoucherTokenAssignment.enablingDisabling,
  initialValues: getInitialValues(state),
  currentStatus: state.voucherTokenAssignments.data[state.uiFunctionalPage.tappedId]?.status || '',
  currentAssignmentMethod: state.voucherTokenAssignments.data[state.uiFunctionalPage.tappedId]?.assignmentMethod || '',
});

const searchVouchersDebounce = debounceSearch((dispatch) => {
  dispatch(clearVouchers());
  dispatch(downloadVouchersAsync(1))
    .catch((error) => dispatch(setAlertErrorMessage(error)));
});

const searchUsersDebounce = debounceSearch((dispatch) => {
  dispatch(clearProfiles());
  dispatch(downloadProfilesAsync(1))
    .catch((error) => dispatch(setAlertErrorMessage(error)));
});

const searchAssignedToDebounce = debounceSearch((dispatch) => {
  dispatch(clearClaimers());
  dispatch(downloadClaimersAsync(1))
    .catch((error) => dispatch(setAlertErrorMessage(error)));
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_VOUCHER_TOKEN_ASSIGNMENT));
    dispatch(setVoucherTokenAssignmentSearchText(''));
    dispatch(clearVoucherTokenAssignments());
    dispatch(setVoucherTokenAssignmentOrderBy(INITIAL_ORDER_BY_VOUCHER_TOKEN_ASSIGNMENTS));
    dispatch(downloadVoucherTokenAssignmentsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadVoucherTokenAssignmentsAsync(pageNo + 1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setVoucherTokenAssignmentPageSize(pageSize));
    dispatch(downloadVoucherTokenAssignmentsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onRefresh: (pageSize) => {
    dispatch(setVoucherTokenAssignmentPageSize(pageSize));
    dispatch(clearVoucherTokenAssignments());
    dispatch(downloadVoucherTokenAssignmentsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSearchBarTextChanged: (text) => {
    dispatch(setVoucherTokenAssignmentSearchText(text));
    dispatch(clearVoucherTokenAssignments());
    dispatch(downloadVoucherTokenAssignmentsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSortPressed: (orderBy) => {
    dispatch(setVoucherTokenAssignmentOrderBy(orderBy));
    dispatch(clearVoucherTokenAssignments());
    dispatch(downloadVoucherTokenAssignmentsAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onViewPressed: (id) => {
    dispatch(setVoucherTokenAssignmentTappedId(id));
    dispatch(downloadVoucherTokenAssignmentAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onEditPressed: (id) => {
    dispatch(setVoucherTokenAssignmentTappedId(id));
    dispatch(downloadVoucherTokenAssignmentAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onAdvancedFilterPressed: () => {
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(clearClaimers());
    dispatch(downloadClaimersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearVoucherTokenAssignments());
    dispatch(setVoucherTokenAssignmentOrderBy(INITIAL_ORDER_BY_VOUCHER_TOKEN_ASSIGNMENTS));
    dispatch(downloadVoucherTokenAssignmentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace(/voucherName/, 'voucher.title')
      .replace(/assignedByName/, 'assignedBy.id')
      .replace(/assignedToName/, 'assignedTo.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(downloadVoucherTokenAssignmentsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangeVouchersText: (text, changeAdvanceFilter) => {
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString(
      changeAdvanceFilter ? '' : 'status=enabled',
    ));
    dispatch(setVoucherSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchVouchersDebounce(dispatch);
    }
  },
  onChangeAssignedByText: async (text, changeAdvanceFilter) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(
      changeAdvanceFilter ? '' : 'status=enabled',
    ));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUsersDebounce(dispatch);
    }
  },
  onChangeAssignedToText: async (text, changeAdvanceFilter) => {
    dispatch(setVoucherTokenClaimerAdvancedFilterDialogSelectedFilterString(
      changeAdvanceFilter ? '' : 'status=enabled',
    ));
    dispatch(setClaimerSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchAssignedToDebounce(dispatch);
    }
  },
  onChangeUserText: async (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUsersDebounce(dispatch);
    }
  },
  onCreateNewPressed: () => {
    dispatch(setVoucherTokenAssignmentTappedId(''));
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setProfileAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onVoucherOptionSelected: (option) => {
    if (option) {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_VOUCHER, option));
      dispatch(setVoucherTokenAssignmentSelectedVoucher(option));
    } else {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_VOUCHER, ''));
      dispatch(setVoucherTokenAssignmentSelectedVoucher(''));
    }
  },
  onAssignmentModeOptionSelected: (option, pageMode) => {
    if (option && pageMode === PAGE_MODE_EDIT) {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_MODE, option));
      dispatch(setVoucherTokenAssignmentSelectedAssignmentMode(option));
      if (option === ASSIGNMENT_MODE_USE_AVAILABEL_CODE) {
        dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
          RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VALID_FROM, ''));
        dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
          RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VALID_UNTIL, ''));
        dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
          RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CODE, ''));
      } else if (option === ASSIGNMENT_MODE_GENERATE_NEW_CODE) {
        dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
          RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CODE, ''));
      }
    } else if (option) {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_ASSIGNMENT_MODE, option));
      dispatch(setVoucherTokenAssignmentSelectedAssignmentMode(option));
      if (option === ASSIGNMENT_MODE_USE_AVAILABEL_CODE) {
        dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
          RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_VALID_FROM, ''));
        dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
          RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_VALID_UNTIL, ''));
        dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
          RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE, ''));
      } else if (option === ASSIGNMENT_MODE_GENERATE_NEW_CODE) {
        dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
          RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE, ''));
      }
    } else {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_ASSIGNMENT_MODE, ''));
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_MODE, ''));
      dispatch(setVoucherTokenAssignmentSelectedAssignmentMode(''));
    }
  },
  onBypassPaymentOptionSelected: (option, pageMode) => {
    if (option && pageMode === PAGE_MODE_EDIT) {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_BYPASS_PAYMENT, option));
      dispatch(setVoucherTokenAssignmentSelectedBypassPayment(option));
    } else if (option) {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_BYPASS_PAYMENT, option));
      dispatch(setVoucherTokenAssignmentSelectedBypassPayment(option));
    } else {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_BYPASS_PAYMENT, ''));
      dispatch(setVoucherTokenAssignmentSelectedBypassPayment(''));
    }
  },
  onBypassMaxPurchaseOptionSelected: (option, pageMode) => {
    if (option && pageMode === PAGE_MODE_EDIT) {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_BYPASS_MAX_PURCHASE, option));
      dispatch(setVoucherTokenAssignmentSelectedBypassMaxPurchase(option));
    } else if (option) {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_BYPASS_MAX_PURCHASE, option));
      dispatch(setVoucherTokenAssignmentSelectedBypassMaxPurchase(option));
    } else {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_BYPASS_MAX_PURCHASE, ''));
      dispatch(setVoucherTokenAssignmentSelectedBypassMaxPurchase(''));
    }
  },
  onBypassAchievementOptionSelected: (option, pageMode) => {
    if (option && pageMode === PAGE_MODE_EDIT) {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_BYPASS_ACHIEVEMENT, option));
      dispatch(setVoucherTokenAssignmentSelectedBypassAchievement(option));
    } else if (option) {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_BYPASS_ACHIEVEMENT, option));
      dispatch(setVoucherTokenAssignmentSelectedBypassAchievement(option));
    } else {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_BYPASS_ACHIEVEMENT, ''));
      dispatch(setVoucherTokenAssignmentSelectedBypassAchievement(''));
    }
  },
  onUserOptionSelected: (option) => {
    if (option) {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_USERS, option));
      dispatch(setVoucherTokenAssignmentSelectedUser(option));
    } else {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_USERS, ''));
      dispatch(setVoucherTokenAssignmentSelectedUser(''));
    }
  },
  onAssignmentMethodOptionSelected: (option) => {
    if (option) {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_METHOD, option));
      dispatch(setVoucherTokenAssignmentAssignmentMethod(option));
      if (option === ASSIGNMENT_METHOD_IMMEDIATELY) {
        dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
          RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_VALID_FROM, ''));
        dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
          RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_VALID_UNTIL, ''));
      }
    } else {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_METHOD, ''));
      dispatch(setVoucherTokenAssignmentAssignmentMethod(''));
    }
  },
  onAssignmentValidFromOptionSelected: (option) => {
    if (option) {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_VALID_FROM, option));
      dispatch(setVoucherTokenAssignmentAssignmentValidFrom(option));
    } else {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_VALID_FROM, ''));
      dispatch(setVoucherTokenAssignmentAssignmentValidFrom(''));
    }
  },
  onAssignmentValidUntilOptionSelected: (option) => {
    if (option) {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_VALID_UNTIL, option));
      dispatch(setVoucherTokenAssignmentAssignmentValidUntil(option));
    } else {
      dispatch(change(RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_VALID_UNTIL, ''));
      dispatch(setVoucherTokenAssignmentAssignmentValidUntil(''));
    }
  },
  onCopyPressed: (status) => {
    dispatch(setVoucherTokenAssignmentCopyingRedemptionCode(!status));
  },
  onSubmitPressed: async ({
    createCodeAssignmentMode, createCode, createValidFrom, createValidUntil,
    createNotes, createNotificationMessageTitle, createNotificationMessageBody,
    createAssignmentMethod, createAssignmentValidFrom, createAssignmentValidUntil,
  }) => {
    await dispatch(addEditVoucherTokenAssignmentAsync(createCodeAssignmentMode, createCode,
      createValidFrom, createValidUntil, createNotes,
      createNotificationMessageTitle, createNotificationMessageBody,
      createAssignmentValidFrom, createAssignmentValidUntil, createAssignmentMethod));
  },
  onSavePressed: async ({
    validFrom, validUntil, codeAssignmentMode, code,
    notes, assignmentValidFrom, assignmentValidUntil,
    notificationMessageTitle, notificationMessageBody,
  }) => {
    try {
      await dispatch(addEditVoucherTokenAssignmentAsync(codeAssignmentMode, code,
        validFrom, validUntil, notes,
        notificationMessageTitle, notificationMessageBody,
        assignmentValidFrom, assignmentValidUntil));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onEnabledDisabledPressed: async (id, status) => {
    const message = status === STATUS_ENABLED
      ? GlobalLocalizedString.common.msgEnableConfirmation
      : GlobalLocalizedString.common.msgDisableConfirmation;
    dispatch(setVoucherTokenAssignmentTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onConfirmEnabledDisabledPressed: async () => {
    await dispatch(enableDisableVoucherTokenAssignmentAsync());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VoucherTokenAssignmentPage);
