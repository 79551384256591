import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { AccentButton, FunctionalPage, SectionTitle } from '../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DROPDOWN, PICKER_MODE_DATE_TIME,
  REGISTRATION_STATUS_APPROVED, REGISTRATION_STATUS_REJECTED, REGISTRATION_STATUS_WAITING_APPROVAL,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_APPROVED_BY,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_APPROVED_ON,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_CLOCK_IN_DRIFT,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_CLOCK_IN_LATITUDE,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_CLOCK_IN_LOCATION_STATUS,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_CLOCK_IN_LONGITUDE,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_CLOCK_IN_TIME,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_DISCOUNT,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_DISCOUNT_NOTES,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_ID,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_LAST_CHANGED_STATUS,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_CHANNEL,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_CODE,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_DATE,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_STATUS,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_STATUS_NOTES,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_POINT_DISCOUNT,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_POINT_DISCOUNT_ID,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_PRICE,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_SERVICE_FEE,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_TAX,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_TOTAL,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_TRANSACTION_ID,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_REGISTRATION_CODE,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_REGISTRATION_STATUS,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_REGISTRATION_STATUS_NOTES,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_REJECTED_BY,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_REJECTED_ON,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_SESSION_END_TIME,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_SESSION_ID,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_SESSION_NAME,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_SESSION_SHORT_DESCRIPTION,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_SESSION_START_TIME,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_SESSION_TIMEZONE,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_USER_EMAIL,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_USER_FULLNAME,
  RXFIELD_SESSION_OPERATOR_REGISTRATION_USER_ID, RXFIELD_SESSION_OPERATOR_REGISTRATION_USER_PHONE,
  RXFORM_SESSION_OPERATOR_REGISTRATION, RXSTATE_SESSION_OPERATOR_REGISTRATION_PAGE,
  RXSTATE_SESSION_OPERATOR_REGISTRATIONS,
} from '../../constant';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { toMoment } from '../../helper';
import { renderReduxFormDateTimePickerField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';

const useStyles = makeStyles(() => ({
  fabContainer: {
    marginRight: 10,
  },
  multipleFabContainer: {
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const renderDialogContent = (initialValues) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_REGISTRATION_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderRegistrationCode}
            label={LocalizedString.sessionRegistrationPage.placeholderRegistrationCode}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_REGISTRATION_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderRegistrationStatus}
            label={LocalizedString.sessionRegistrationPage.placeholderRegistrationStatus}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_REGISTRATION_STATUS_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderRegistrationStatusNotes}
            label={LocalizedString.sessionRegistrationPage.placeholderRegistrationStatusNotes}
            disabled
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_APPROVED_ON}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderApprovedOn}
            label={LocalizedString.sessionRegistrationPage.placeholderApprovedOn}
            disabled
            hidden={initialValues.registrationStatus !== REGISTRATION_STATUS_APPROVED}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_APPROVED_BY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderApprovedBy}
            label={LocalizedString.sessionRegistrationPage.placeholderApprovedBy}
            disabled
            hidden={initialValues.registrationStatus !== REGISTRATION_STATUS_APPROVED}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_REJECTED_ON}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderRejectedOn}
            label={LocalizedString.sessionRegistrationPage.placeholderRejectedOn}
            disabled
            hidden={initialValues.registrationStatus !== REGISTRATION_STATUS_REJECTED}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_REJECTED_BY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderRejectedBy}
            label={LocalizedString.sessionRegistrationPage.placeholderRejectedBy}
            disabled
            hidden={initialValues.registrationStatus !== REGISTRATION_STATUS_REJECTED}
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_CLOCK_IN_TIME}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderClockInTime}
            label={LocalizedString.sessionRegistrationPage.placeholderClockInTime}
            disabled
            pickerMode={PICKER_MODE_DATE_TIME}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_CLOCK_IN_DRIFT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderClockInDrift}
            label={LocalizedString.sessionRegistrationPage.placeholderClockInDrift}
            disabled
            type="number"
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_CLOCK_IN_LATITUDE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderClockInLatitude}
            label={LocalizedString.sessionRegistrationPage.placeholderClockInLatitude}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_CLOCK_IN_LONGITUDE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderClockInLongitude}
            label={LocalizedString.sessionRegistrationPage.placeholderClockInLongitude}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_CLOCK_IN_LOCATION_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderClockInLocationStatus}
            label={LocalizedString.sessionRegistrationPage.placeholderClockInLocationStatus}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>

    <SectionTitle title={LocalizedString.sessionRegistrationPage.placeholderUser} />
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_USER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderUserID}
            label={LocalizedString.sessionRegistrationPage.placeholderUserID}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_USER_FULLNAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderUserFullName}
            label={LocalizedString.sessionRegistrationPage.placeholderUserFullName}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_USER_EMAIL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderUserEmail}
            label={LocalizedString.sessionRegistrationPage.placeholderUserEmail}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_USER_PHONE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderUserPhone}
            label={LocalizedString.sessionRegistrationPage.placeholderUserPhone}
            disabled
            type="number"
          />
        </Grid>
      </Grid>
    </Grid>

    <SectionTitle title={LocalizedString.sessionRegistrationPage.placeholderSession} />
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_SESSION_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderSessionID}
            label={LocalizedString.sessionRegistrationPage.placeholderSessionID}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_SESSION_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderSessionName}
            label={LocalizedString.sessionRegistrationPage.placeholderSessionName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_SESSION_SHORT_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderSessionShortDescription}
            label={LocalizedString.sessionRegistrationPage.placeholderSessionShortDescription}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_SESSION_START_TIME}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderSessionStartTime}
            label={LocalizedString.sessionRegistrationPage.placeholderSessionStartTime}
            disabled
            pickerMode={PICKER_MODE_DATE_TIME}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_SESSION_END_TIME}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderSessionEndTime}
            label={LocalizedString.sessionRegistrationPage.placeholderSessionEndTime}
            disabled
            pickerMode={PICKER_MODE_DATE_TIME}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_SESSION_TIMEZONE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderSessionTimezone}
            label={LocalizedString.sessionRegistrationPage.placeholderSessionTimezone}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>

    <SectionTitle title={LocalizedString.sessionRegistrationPage.placeholderPayment} />
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderPaymentID}
            label={LocalizedString.sessionRegistrationPage.placeholderPaymentID}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_DATE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderPaymentDate}
            label={LocalizedString.sessionRegistrationPage.placeholderPaymentDate}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_TRANSACTION_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderTransactionID}
            label={LocalizedString.sessionRegistrationPage.placeholderTransactionID}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderPaymentCode}
            label={LocalizedString.sessionRegistrationPage.placeholderPaymentCode}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_CHANNEL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderPaymentChannel}
            label={LocalizedString.sessionRegistrationPage.placeholderPaymentChannel}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderPaymentStatus}
            label={LocalizedString.sessionRegistrationPage.placeholderPaymentStatus}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_STATUS_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderPaymentStatusNotes}
            label={LocalizedString.sessionRegistrationPage.placeholderPaymentStatusNotes}
            disabled
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_LAST_CHANGED_STATUS}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderLastChangedStatus}
            label={LocalizedString.sessionRegistrationPage.placeholderLastChangedStatus}
            disabled
            pickerMode={PICKER_MODE_DATE_TIME}
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_PRICE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderPrice}
            label={LocalizedString.sessionRegistrationPage.placeholderPrice}
            disabled
            currencyTextFormat={initialValues.price !== LocalizedString.sessionRegistrationPage
              .labelFree}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_TAX}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderTax}
            label={LocalizedString.sessionRegistrationPage.placeholderTax}
            disabled
            currencyTextFormat
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_SERVICE_FEE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderServiceFee}
            label={LocalizedString.sessionRegistrationPage.placeholderServiceFee}
            disabled
            currencyTextFormat
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_DISCOUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderDiscount}
            label={LocalizedString.sessionRegistrationPage.placeholderDiscount}
            disabled
            currencyTextFormat
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_DISCOUNT_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderDiscountNotes}
            label={LocalizedString.sessionRegistrationPage.placeholderDiscountNotes}
            disabled
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_POINT_DISCOUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderPointDiscount}
            label={LocalizedString.sessionRegistrationPage.placeholderPointDiscount}
            disabled
            currencyTextFormat
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_POINT_DISCOUNT_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderPointDiscountID}
            label={LocalizedString.sessionRegistrationPage.placeholderPointDiscountID}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_TOTAL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderTotal}
            label={LocalizedString.sessionRegistrationPage.placeholderTotal}
            disabled
            currencyTextFormat
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const SessionRegistrationPage = ({
  initialValues, profiles, sessions,
  approving, downloading, downloadingDeleting, downloadingProfiles, downloadingSessions,
  isApprovalAllowed, rejecting,
  onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed, onApprovePressed,
  onChangePage, onChangePageSize, onChangeSessionText, onChangeParticipantText,
  onConfirmApproveRejectPressed, onDownloadPressed, onRefresh, onRejectPressed,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
}) => {
  const classes = useStyles();

  const renderCustomFabComponent = () => {
    if (initialValues.registrationStatus === REGISTRATION_STATUS_WAITING_APPROVAL
      && isApprovalAllowed) {
      return (
        <div className={classes.multipleFabContainer}>
          <div className={classes.fabContainer}>
            <AccentButton
              variant="text"
              disabled={downloadingDeleting || approving}
              loading={rejecting}
              disableElevation
              caption={LocalizedString.sessionRegistrationPage.buttonCaptionReject}
              onClick={onRejectPressed}
            />
          </div>
          <AccentButton
            disabled={downloadingDeleting || rejecting}
            loading={approving}
            disableElevation
            caption={LocalizedString.sessionRegistrationPage.buttonCaptionApprove}
            onClick={onApprovePressed}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <FunctionalPage
      data={RXSTATE_SESSION_OPERATOR_REGISTRATIONS}
      uiPage={RXSTATE_SESSION_OPERATOR_REGISTRATION_PAGE}
      tableColumns={[
        {
          title: GlobalLocalizedString.common.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.sessionRegistrationPage.labelParticipant, field: 'user.fullName', sorting: !downloading },
        { title: LocalizedString.sessionRegistrationPage.labelSession, field: 'session.name', sorting: !downloading },
        { title: LocalizedString.sessionRegistrationPage.labelRegistrationCode, field: 'registrationCode', sorting: !downloading },
        { title: LocalizedString.sessionRegistrationPage.labelRegistrationStatus, field: 'registrationStatus', sorting: !downloading },
        {
          title: LocalizedString.sessionRegistrationPage.labelApprovedOn,
          field: 'approvedRejectedOn',
          sorting: !downloading,
          render: ({ approvedRejectedOn, registrationStatus }) => (
            registrationStatus === 'Approved' && approvedRejectedOn
              ? toMoment(approvedRejectedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        {
          title: LocalizedString.sessionRegistrationPage.labelRejectedOn,
          field: 'approvedRejectedOn',
          sorting: !downloading,
          render: ({ approvedRejectedOn, registrationStatus }) => (
            registrationStatus !== 'Approved' && approvedRejectedOn
              ? toMoment(approvedRejectedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        { title: LocalizedString.sessionRegistrationPage.labelPaymentStatus, field: 'payment.paymentStatus', sorting: !downloading },
        { title: LocalizedString.sessionRegistrationPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
      ]}
      filterColumns={[
        {
          title: LocalizedString.sessionRegistrationPage.labelSession,
          field: 'session',
          type: FILTER_TYPE_DROPDOWN,
          data: sessions,
          loading: downloadingSessions,
          onChangeFilterText: onChangeSessionText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sessionRegistrationPage.labelParticipant,
          field: 'participant',
          type: FILTER_TYPE_DROPDOWN,
          data: profiles,
          loading: downloadingProfiles,
          onChangeFilterText: onChangeParticipantText,
          useDropdownValue: true,
        },
      ]}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmApproveRejectPressed}
      onDownloadPressed={onDownloadPressed}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      title={LocalizedString.sessionRegistrationPage.title}
      renderCustomFabComponent={() => renderCustomFabComponent()}
      disableCreate
      disableEdit
      disableDelete
      useFullWidth
      savePermissionName="EVENT_DOWNLOAD_SESSION_REGISTRATION_FOR_OPERATOR"
    >
      {renderDialogContent(initialValues)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_SESSION_OPERATOR_REGISTRATION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SessionRegistrationPage);

SessionRegistrationPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  profiles: PropTypes.arrayOf(SimpleDataShape).isRequired,
  sessions: PropTypes.arrayOf(SimpleDataShape).isRequired,
  approving: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  downloadingProfiles: PropTypes.bool.isRequired,
  downloadingSessions: PropTypes.bool.isRequired,
  isApprovalAllowed: PropTypes.bool.isRequired,
  rejecting: PropTypes.bool.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onApprovePressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeSessionText: PropTypes.func.isRequired,
  onChangeParticipantText: PropTypes.func.isRequired,
  onConfirmApproveRejectPressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onRejectPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
