import {
  DOWNLOADING_NOTIFICATIONS, SET_IS_NOTIFICATION_PROMPT_OPEN, SET_IS_NOTIFICATION_SNACKBAR_OPEN,
  SET_NOTIFICATION_MESSAGE,
} from '../action';

const initialState = {
  message: { title: '', body: '', image: '' },
  isSnackbarOpen: false,
  isNotificationPromptOpen: false,
  downloadingNotifications: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_MESSAGE:
      return { ...state, message: action.text };
    case SET_IS_NOTIFICATION_SNACKBAR_OPEN:
      return { ...state, isSnackbarOpen: action.status };
    case SET_IS_NOTIFICATION_PROMPT_OPEN:
      return { ...state, isNotificationPromptOpen: action.status };
    case DOWNLOADING_NOTIFICATIONS:
      return { ...state, downloadingNotifications: action.status };
    default: return state;
  }
};
