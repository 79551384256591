import {
  buildQueryParams, sendGetRequest, transformQueryResult,
} from '../../helper';
import {
  REST_URL_CAPTCHA_REQUEST, REST_URL_VIEW_CAPTCHA_REQUEST,
} from './constant';

export * from '../../helper';

export const downloadCaptchaRequests = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const filterStringText = '&filterString=status=enabled'.concat(filterString);
  const searchStringText = '&searchString={searchText}';
  const url = searchText !== '%'
    ? buildQueryParams(
      REST_URL_CAPTCHA_REQUEST.concat(searchStringText).concat(filterStringText),
      pageNumber,
      pageSize,
      orderBy,
      searchText,
    )
    : buildQueryParams(
      REST_URL_CAPTCHA_REQUEST.concat(filterStringText),
      pageNumber,
      pageSize,
      orderBy,
      searchText,
    );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadCaptchaRequest = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_CAPTCHA_REQUEST.replace(/\{id\}/, id), token);
  return response;
};
