import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, FormControlLabel, FormGroup, FormHelperText, Typography, makeStyles,
} from '@material-ui/core';
import {
  COLOR_BACKGROUND, COLOR_BODY_TEXT, COLOR_BODY_TEXT_LIGHT, COLOR_DANGEROUS,
} from '../constant';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    margin: '0px 10px 0 10px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  errorTextStyle: {
    fontStyle: 'italic',
    color: COLOR_DANGEROUS,
    marginLeft: 14,
  },
  label: {
    color: COLOR_BODY_TEXT_LIGHT,
    fontSize: '14px',
  },
  labelOption: {
    color: COLOR_BODY_TEXT,
    fontSize: '14px',
  },
}));

const styles = {
  checkbox: {
    marginHorizontal: 0,
    paddingHorizontal: 0,
    alignSelf: 'stretch',
    backgroundColor: COLOR_BACKGROUND,
    borderWidth: 0,
    borderRadius: 0,
  },
};

const CheckboxOption = ({
  data, onValueSelected, disabled, optionValueSeparator,
  error, selectedValue, label, defaultValue, horizontal,
}) => {
  const [selectedValueTemp, setSelectedValueTemp] = useState(selectedValue || defaultValue);

  const classes = useStyles();

  const onCheck = (item, selected) => {
    const optionValueSeparatorTemp = selectedValue ? optionValueSeparator : '';
    const selectedStr = selected
      ? selectedValue.split(optionValueSeparator).filter((x) => x !== item.label).toString()
      : selectedValue + optionValueSeparatorTemp + item.label;
    onValueSelected(selectedStr);
    setSelectedValueTemp(selectedStr);
  };

  const renderCheckboxItem = (item) => {
    const selected = selectedValueTemp.split(optionValueSeparator).includes(item.label);
    return (
      <FormControlLabel
        key={item.label}
        control={(
          <Checkbox
            key={item.label}
            checked={selected}
            style={styles.checkbox}
            onChange={() => onCheck(item, selected)}
            disabled={disabled}
            color="primary"
          />
        )}
        label={(
          <Typography className={classes.labelOption}>{item.label}</Typography>
        )}
      />
    );
  };

  const renderError = () => {
    if (error) {
      return (<FormHelperText className={classes.errorTextStyle}>{error}</FormHelperText>);
    }
    return null;
  };

  return (
    <div className={classes.container}>
      <FormGroup>
        <Typography className={classes.label}>{label}</Typography>
        <div className={horizontal ? classes.row : classes.column}>
          {data.map((item) => renderCheckboxItem(item))}
          {renderError()}
        </div>
      </FormGroup>
    </div>
  );
};

export default CheckboxOption;

const pickerItemShape = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
});

const arrayShape = PropTypes.arrayOf(pickerItemShape);

CheckboxOption.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  data: arrayShape.isRequired,
  onValueSelected: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  horizontal: PropTypes.bool,
  optionValueSeparator: PropTypes.string,
  error: PropTypes.string,
  selectedValue: PropTypes.oneOfType([
    pickerItemShape,
    PropTypes.string,
  ]),
};

CheckboxOption.defaultProps = {
  defaultValue: '',
  label: '',
  disabled: false,
  horizontal: false,
  optionValueSeparator: ',',
  error: '',
  selectedValue: undefined,
};
