import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { DialogContent, makeStyles, Modal } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  ALERT_TYPE_CONFIRMATION, ALERT_TYPE_ERROR, ALERT_TYPE_INFO, ALERT_TYPE_INPUT,
  ALERT_TYPE_SUCCESS, ALERT_TYPE_WARNING, COLOR_DANGEROUS,
  ERR_TOKEN_SESSION_EXPIRED, ROUTE_NAME_LOGIN,
} from '../../constant';
import LocalizedString from '../../localization';
import AccentButton from '../accent-button';
import OutlinedTextField from '../outlined-text-field';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-evenly',
  },
  redButton: {
    color: COLOR_DANGEROUS,
  },
}));

const onCancelPress = (onCancelPressed, onHideAlert, setReason, type) => {
  onCancelPressed();
  onHideAlert(type);
  setReason('');
};

const onConfirmPress = (onConfirmPressed, onHideAlert, setReason, message, reason, type) => {
  onConfirmPressed(message, reason);
  onHideAlert(type);
  setReason('');
};

const AlertBox = ({
  forcePopUp,
  onCancelPressed, onConfirmPressed, onHideAlert,
  message, placeholder, type,
}) => {
  const classes = useStyles();
  const [reason, setReason] = useState('');
  const location = useLocation();

  if ((location && location.pathname !== ROUTE_NAME_LOGIN) || forcePopUp) {
    if (type !== ALERT_TYPE_CONFIRMATION && type !== ALERT_TYPE_INPUT) {
      let defaultTitle = '';
      switch (type) {
        case ALERT_TYPE_INFO: {
          defaultTitle = LocalizedString.common.alertTitleInfo;
          break;
        }
        case ALERT_TYPE_WARNING: {
          defaultTitle = LocalizedString.common.alertTitleWarning;
          break;
        }
        case ALERT_TYPE_ERROR: {
          defaultTitle = LocalizedString.common.alertTitleError;
          break;
        }
        case ALERT_TYPE_SUCCESS: {
          defaultTitle = LocalizedString.common.alertTitleSuccess;
          break;
        }
        default: {
          defaultTitle = '';
          break;
        }
      }

      return (
        <Modal open={!!message} disableBackdropClick disableEscapeKeyDown>
          <DialogContent>
            <SweetAlert
              type={type}
              title={defaultTitle}
              show={!!message}
              onConfirm={() => onHideAlert(type)}
              openAnim={{ name: 'showSweetAlert', duration: 500 }}
              closeOnClickOutside={false}
              customButtons={(
                <AccentButton
                  onClick={() => {
                    if (message.toLowerCase().includes(ERR_TOKEN_SESSION_EXPIRED)) {
                      onConfirmPressed(message);
                    }
                    onHideAlert(type);
                  }}
                  variant="text"
                  className={classes.redButton}
                  caption={LocalizedString.common.buttonCaptionOK}
                />
          )}
            >
              {message}
            </SweetAlert>
          </DialogContent>
        </Modal>
      );
    }

    return (
      <Modal open={!!message} disableBackdropClick disableEscapeKeyDown>
        <DialogContent>
          <SweetAlert
            type="success"
            show={!!message}
            title={LocalizedString.common.alertTitleConfirmation}
            placeholder={placeholder}
            onConfirm={() => onConfirmPress(onConfirmPressed, onHideAlert,
              setReason, message, reason, type)}
            openAnim={{ name: 'showSweetAlert', duration: 500 }}
            closeOnClickOutside={false}
            customButtons={(
              <div className={classes.buttonContainer}>
                <AccentButton
                  onClick={() => onCancelPress(onCancelPressed, onHideAlert, setReason, type)}
                  variant="text"
                  className={classes.redButton}
                  caption={LocalizedString.common.buttonCaptionNo}
                />
                <AccentButton
                  onClick={() => onConfirmPress(onConfirmPressed, onHideAlert,
                    setReason, message, reason, type)}
                  caption={LocalizedString.common.buttonCaptionYes}
                />
              </div>
       )}
          >
            {message}

            {type === ALERT_TYPE_INPUT && (
            <OutlinedTextField
              label={placeholder}
              placeholder={placeholder}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              multiline
            />
            )}
          </SweetAlert>
        </DialogContent>
      </Modal>
    );
  }
  return null;
};

export default AlertBox;

AlertBox.propTypes = {
  forcePopUp: PropTypes.bool.isRequired,
  onCancelPressed: PropTypes.func,
  onConfirmPressed: PropTypes.func,
  onHideAlert: PropTypes.func.isRequired,
  message: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
};

AlertBox.defaultProps = {
  onCancelPressed: () => {},
  onConfirmPressed: () => {},
  message: '',
  placeholder: '',
};
