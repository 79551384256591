import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_SMSES, MENUID_NOTIFICATION_SMS, PAGE_MODE_TABLE, RXFORM_SMS,
} from '../../constant';
import { getPermission, transformInitialValues } from '../../../../helper';
import LocalizedString from '../../localization';
import {
  clearSmses, setActiveSideMenuItem, setAdvancedFilterDialogSelectedFilterString,
  setAlertConfirmationMessage, setAlertErrorMessage, setSmsSearchText,
  setSmsSelectedPageSize, setSmsSelectedOrderBy, setSmsTappedId,
  downloadSmsesAsync, downloadSmsAsync, resendSmsAsync,
} from '../../redux/action';
import SmsPage from './sms.presentation';

const getInitialValues = (state) => {
  const { smses, uiFunctionalPage, uiSms } = state;
  const { data } = smses;
  const { downloadingDeleting, tappedId } = uiSms;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : {
    to: '',
    message: '',
    processingStatus: '',
    notes: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  downloading: state.uiSms.downloading,
  downloadingDeleting: state.uiSms.downloadingDeleting,
  resending: state.uiSms.resending,
  initialValues: getInitialValues(state),
  tappedId: state.uiFunctionalPage.tappedId || '',
  isResendAllowed: getPermission(state, 'MESSAGING_RESEND_SMS'),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_NOTIFICATION_SMS));
    dispatch(setSmsSearchText(''));
    dispatch(clearSmses());
    dispatch(setSmsSelectedPageSize(20));
    dispatch(setSmsSelectedOrderBy(INITIAL_ORDER_BY_SMSES));
    dispatch(downloadSmsesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearSmses());
    dispatch(downloadSmsesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_SMS));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadSmsesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setSmsSelectedPageSize(pageSize));
    dispatch(downloadSmsesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmResendPressed: () => {
    dispatch(resendSmsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setSmsSelectedPageSize(pageSize));
    dispatch(clearSmses());
    dispatch(downloadSmsesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResendPressed: (id) => {
    dispatch(setSmsTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.notificationMenu.msgResendConfirmation));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearSmses());
    dispatch(setSmsSelectedOrderBy(INITIAL_ORDER_BY_SMSES));
    dispatch(downloadSmsesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setSmsSearchText(text));
      dispatch(clearSmses());
      await dispatch(downloadSmsesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setSmsSelectedOrderBy(orderBy));
    dispatch(clearSmses());
    dispatch(downloadSmsesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setSmsTappedId(id));
    dispatch(downloadSmsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SmsPage);
