import { downloadEventOperatorRegistrations, transformSearchText } from '../../../helper';
import { downloadingEventOperatorRegistrations, setEventOperatorRegistrations } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, eventOperatorRegistrations,
    uiEventOperatorRegistration,
  } = getState();
  const { token } = authentication;
  const { meta } = eventOperatorRegistrations;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiEventOperatorRegistration;

  try {
    dispatch(downloadingEventOperatorRegistrations(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadEventOperatorRegistrations(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setEventOperatorRegistrations(list));
    }
  } finally {
    dispatch(downloadingEventOperatorRegistrations(false));
  }
};
