import { reset } from 'redux-form';
import { PAGE_MODE_TABLE, RXFORM_SESSION_OPERATOR_MAPPING } from '../../../constant';
import { addSessionOperatorMapping, editSessionOperatorMapping, localDateToUtc } from '../../../helper';
import {
  addingEditingSessionOperatorMapping, clearSessionOperatorMappings, setAlertErrorMessage,
  setSessionOperatorMappingSelectedSession, setSessionOperatorMappingSelectedUser,
  setFunctionalPageMode,
} from '../simple-action';
import downloadSessionOperatorMappingsAsync from './downloadSessionOperatorMappingsAsync';

export default (validUntil) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingSessionOperatorMapping(true));

    const { token } = getState().authentication;
    const { tappedId, selectedUser, selectedSession } = getState().uiSessionOperatorMapping;
    const { data } = getState().sessionOperatorMappings;

    const found = data[tappedId];
    const user = selectedUser.value || found.user.id;
    const session = selectedSession.value || found.session.id;

    if (tappedId) {
      await editSessionOperatorMapping(tappedId, session, user, localDateToUtc(validUntil), token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addSessionOperatorMapping(session, user, localDateToUtc(validUntil), token);
    }

    dispatch(reset(RXFORM_SESSION_OPERATOR_MAPPING));
    dispatch(clearSessionOperatorMappings());
    dispatch(downloadSessionOperatorMappingsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setSessionOperatorMappingSelectedSession(''));
    dispatch(setSessionOperatorMappingSelectedUser(''));
  } finally {
    dispatch(addingEditingSessionOperatorMapping(false));
  }
};
