import { downloadingNewsCategories, setNewsCategories } from '../simple-action';
import { downloadNewsCategories, transformSearchText } from '../../../helper';

export default (selectedPageNo, isAll) => async (dispatch, getState) => {
  const { authentication, newsCategories, uiNewsCategory } = getState();
  const { token } = authentication;
  const { meta } = newsCategories;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiNewsCategory;

  const searchText = transformSearchText(searchBarText);

  try {
    dispatch(downloadingNewsCategories(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadNewsCategories(
      pageToBeDownloaded,
      isAll ? 50 : selectedPageSize,
      orderBy,
      searchText,
      filterString,
      token,
    );

    if (isAll) {
      const result = { ...list };

      for (let i = list.meta.currentPage; i <= list.meta.totalPages; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        const nextList = await downloadNewsCategories(
          i,
          50,
          orderBy,
          searchText,
          token,
        );

        const finalList = {
          data: { ...result.data, ...nextList.data },
          meta: nextList.meta,
        };

        Object.assign(result, finalList);
        dispatch(setNewsCategories(result));
      }
    } else {
      dispatch(setNewsCategories(list));
    }
  } finally {
    dispatch(downloadingNewsCategories(false));
  }
};
