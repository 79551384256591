import { connect } from 'react-redux';
import { sendGetRequest, downloadProfilePictures } from '../../helper';
import { setAlertErrorMessage } from '../../redux/action';
import Comment from './comment.presentation';

const URL_COMMENTS = '/DiscussionComment/{discusId}?pageNumber={pageNumber}';
const URL_COMMENTS_REPLIES = '/Reply/{commentId}?pageNumber={pageNumber}';
const URL_REACTION = '/reaction/api/v1/Reaction/{reactionId}';

const mapStateToProps = (state) => ({
  token: state.authentication.token,
});

const mapDispatchToProps = (dispatch) => ({
  getCommentsAsync: async (discusId, token, currPage, path) => {
    if (discusId) {
      const url = path.concat(URL_COMMENTS
        .replace(/\{discusId\}/, discusId)
        .replace(/\{pageNumber\}/, currPage + 1));
      const comments = await sendGetRequest(url, token)
        .catch((err) => {
          dispatch(setAlertErrorMessage(err));
        });
      return comments || {};
    } return {};
  },
  getRepliesAsync: async (commentId, token, currPage, path) => {
    const url = path.concat(URL_COMMENTS_REPLIES
      .replace(/\{commentId\}/, commentId)
      .replace(/\{pageNumber\}/, currPage + 1));
    const replies = await sendGetRequest(url, token)
      .catch((err) => {
        dispatch(setAlertErrorMessage(err));
      });
    return replies || {};
  },
  getReactionsAsync: async (reactionId, token) => {
    const url = URL_REACTION.replace(/\{reactionId\}/, reactionId);
    const reactions = await sendGetRequest(url, token)
      .catch((err) => {
        dispatch(setAlertErrorMessage(err));
      });
    return reactions || {};
  },
  getUsersProfilePictureAsync: async (userList, token) => {
    const listId = userList.map((x) => x.user.id);
    const profilePictures = await downloadProfilePictures(listId, token)
      .catch((err) => {
        dispatch(setAlertErrorMessage(err));
      });
    return profilePictures;
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Comment);
