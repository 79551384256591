import {
  DOWNLOADING_DYNAMIC_FORM, SET_DYNAMIC_FORM_BODY, SET_DYNAMIC_FORM_VIEW_IMAGE_MODAL,
  SET_DYNAMIC_FORM_VIEWED_IMAGE,
} from '../action';

const initialValues = {
  downloading: false,
  body: {},
  viewedImage: '',
  viewImageModal: false,
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case DOWNLOADING_DYNAMIC_FORM:
      return { ...state, downloading: action.status };
    case SET_DYNAMIC_FORM_BODY:
      return { ...state, body: action.body };
    case SET_DYNAMIC_FORM_VIEW_IMAGE_MODAL:
      return { ...state, viewImageModal: action.status };
    case SET_DYNAMIC_FORM_VIEWED_IMAGE:
      return { ...state, viewedImage: action.image };
    default: return state;
  }
};
