import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  clearScopes, clearServices, setActiveSideMenuItem, setAlertConfirmationMessage,
  setAlertErrorMessage, setApiKeySelectedOwnerAppName,
  setScopeAdvancedFilterDialogSelectedFilterString, setScopeSearchText, setScopeSelectedPageSize,
  setScopeSelectedServiceId, setScopeSelectedOrderBy, setScopeTappedId, setServiceSearchText,
  addEditScopeAsync, downloadDeleteScopeAsync, downloadScopesAsync, downloadServicesAsync,
  setServiceAdvancedFilterDialogSelectedFilterString,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_SCOPES, PAGE_MODE_TABLE, RXFORM_SCOPE, RXFIELD_SCOPE_SERVICE,
  MENUID_AUTHENTICATION_SCOPE,
  ADVANCED_FILTER_MODE,
} from '../../constant';
import { transformDropdownData, transformInitialValues } from '../../helper';
import LocalizedString from '../../localization';
import ScopePage from './scope.presentation';

const getInitialValues = (state) => {
  const { scopes, uiScope, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiScope;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? scopes.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      { service: { label: found.service.name, value: found.service.id } })
    : {
      name: '',
      description: '',
      service: { label: '', value: '' },
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  services: transformDropdownData(state.services.data),
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiScope.addingEditing,
  downloading: state.uiScope.downloading,
  loadingService: state.uiService.downloading,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setServiceSearchText(''));
    dispatch(setServiceAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearServices());
    dispatch(downloadServicesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setApiKeySelectedOwnerAppName(null));
    dispatch(setScopeAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setActiveSideMenuItem(MENUID_AUTHENTICATION_SCOPE));
    dispatch(setScopeSearchText(''));
    dispatch(clearScopes());
    dispatch(setScopeSelectedPageSize(20));
    dispatch(setScopeSelectedOrderBy(INITIAL_ORDER_BY_SCOPES));
    dispatch(downloadScopesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/service/, 'service.id');
    dispatch(setScopeAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearScopes());
    dispatch(downloadScopesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_SCOPE));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadScopesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setScopeSelectedPageSize(pageSize));
    dispatch(downloadScopesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeServiceText: async (text, mode) => {
    try {
      dispatch(setServiceSearchText(text));
      dispatch(setServiceAdvancedFilterDialogSelectedFilterString(
        mode === ADVANCED_FILTER_MODE ? '' : 'status=enabled',
      ));
      dispatch(clearServices());
      await dispatch(downloadServicesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteScopeAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setScopeTappedId(''));
    dispatch(setServiceSearchText(''));
    dispatch(setServiceAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(clearServices());
    dispatch(downloadServicesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setScopeTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setServiceSearchText(''));
    dispatch(setServiceAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(clearServices());
    dispatch(downloadServicesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setScopeTappedId(id));
  },
  onRefresh: (pageSize) => {
    dispatch(setScopeSelectedPageSize(pageSize));
    dispatch(clearScopes());
    dispatch(downloadScopesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setScopeAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(clearScopes());
    dispatch(setScopeSelectedOrderBy(INITIAL_ORDER_BY_SCOPES));
    dispatch(downloadScopesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ name, description }) => {
    try {
      await dispatch(addEditScopeAsync(name, description));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setScopeSearchText(text));
      dispatch(clearScopes());
      await dispatch(downloadScopesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onServiceOptionSelected: (option) => {
    if (option) {
      dispatch(setScopeSelectedServiceId(option.value));
      dispatch(change(RXFORM_SCOPE, RXFIELD_SCOPE_SERVICE, option));
    } else {
      dispatch(setScopeSelectedServiceId(''));
      dispatch(change(RXFORM_SCOPE, RXFIELD_SCOPE_SERVICE, ''));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setScopeSelectedOrderBy(orderBy));
    dispatch(clearScopes());
    dispatch(downloadScopesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ name, description }) => {
    await dispatch(addEditScopeAsync(name, description));
  },
  onViewPressed: (id) => {
    dispatch(setScopeTappedId(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ScopePage);
