import {
  LAUNCHING_IMAGE_PICKER, SET_IMAGE_PICKER_DIALOG_LAUNCH_MODE,
  SET_IMAGE_PICKER_DIALOG_VISIBILITY,
} from '../action';

const initialState = {
  launchMode: '',
  launching: false,
  visibility: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LAUNCHING_IMAGE_PICKER:
      return { ...state, launching: action.status };
    case SET_IMAGE_PICKER_DIALOG_LAUNCH_MODE:
      return { ...state, launchMode: action.option };
    case SET_IMAGE_PICKER_DIALOG_VISIBILITY:
      return { ...state, visibility: action.status };
    default: return state;
  }
};
