import {
  downloadingNotifications, addNotifications,
  clearNotifications,
} from '../simple-action';

export default (reload = false) => async (dispatch, getState) => {
  const { currentPage, totalPages } = getState().notifications.meta;

  if (!reload && currentPage === totalPages) {
    return;
  }

  try {
    dispatch(downloadingNotifications(true));

    const newNotifications = {};
    if (reload) {
      dispatch(clearNotifications());
    }

    dispatch(addNotifications(newNotifications));
  } finally {
    dispatch(downloadingNotifications(false));
  }
};
