import { PAGE_MODE_TABLE } from '../../../constant';
import { deleteEventOperatorInvitation, downloadEventOperatorInvitation } from '../../../helper';
import {
  addEventOperatorInvitation, clearEventOperatorInvitations,
  downloadingDeletingEventOperatorInvitation, setAlertErrorMessage,
  setEventOperatorInvitationTappedId, setFunctionalPageMode,
} from '../simple-action';
import downloadEventOperatorInvitationsAsync from './downloadEventOperatorInvitationsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingEventOperatorInvitation(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiEventOperatorInvitation;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteEventOperatorInvitation(tappedId, token);
      dispatch(setEventOperatorInvitationTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearEventOperatorInvitations());
      dispatch(downloadEventOperatorInvitationsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const result = await downloadEventOperatorInvitation(tappedId, token);
      dispatch(addEventOperatorInvitation(result));
    }
  } finally {
    dispatch(downloadingDeletingEventOperatorInvitation(false));
  }
};
