import { reset } from 'redux-form';
import {
  COMMENT_ACCESS_EVERYONE,
  PAGE_MODE_TABLE,
  RXFORM_SESSION,
  SESSION_TYPE,
  SESSION_WHO_CAN_REGISTER_DATA,
} from '../../../constant';
import {
  addSession, editSession, localDateToUtc, toNumber, transformAdditionalItemsPayload,
  transformLocalImagePath,
} from '../../../helper';
import {
  addingEditingSession, clearSessions, setAlertErrorMessage, setFunctionalPageMode,
  setSessionBannerImage,
  setSessionSelectedCategoryName, setSessionSelectedEventName, setSessionSelectedRoom,
  setSessionSelectedSpeakers, setSessionSelectedVoucher, setSessionTableDataSpeakers,
} from '../simple-action';
import dowloadSessionsAsync from './downloadSessionsAsync';

const transformMedia = (data) => data.map((x) => {
  if (Object.prototype.hasOwnProperty.call(x, 'type')) {
    return ({
      id: x.id || '',
      order: toNumber(x.order),
      type: x.type,
      url: x.type === 'Youtube' ? x.path : '',
    });
  }
  return ({
    id: x.id || '',
    order: toNumber(x.order),
    type: Object.prototype.hasOwnProperty.call(x, 'content') ? x.content.type : x.link.type,
    url: Object.prototype.hasOwnProperty.call(x, 'link') && x.link.type === 'Youtube' ? x.link.path : '',
    image: Object.prototype.hasOwnProperty.call(x, 'content') && x.content.type === 'Image' ? x.content.path : '',
  });
});

export default (name, shortDescription, description, startTime,
  endTime, earliestClockInBeforeMinutes, maxClockInMinutes,
  quota, featuredOrder, normalPrice, price, registrationRewardVouchers,
  registrationRewardPoint, speakers, media, additionalItems,
  commentAccess, banner) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingSession(true));

    const { token } = getState().authentication;
    const {
      tappedId, selectedEvent, selectedCategory, selectedEnableComment,
      selectedRoom, selectedClockInsideArea,
      selectedVisibilityStatus, selectedWhoCanRegister,
      selectedNeedApproval, selectedFeaturedEvent, selectedCanPayPoint,
      selectedRewardType, selectedType, selectedSurveyForm,
    } = getState().uiSession;
    const { data } = getState().sessions;
    const found = data[tappedId];
    const registrationPointReward = toNumber(registrationRewardPoint) || 0;
    const medias = media?.length > 0 ? transformMedia(media) : [];
    const startTimes = localDateToUtc(startTime);
    const endTimes = localDateToUtc(endTime);
    const speakersName = speakers?.length > 0 ? speakers.map((x, i) => (
      {
        order: i + 1,
        id: x.value,
      }
    )) : found.speakers.map((x) => ({
      order: x.order,
      id: x.speaker.id,
    }));
    const selectedEventId = selectedEvent || found?.event.id;
    const selectedCategoryId = selectedCategory || found?.category.id;
    const selectedRoomId = selectedRoom || found?.room.id;
    const clockInsideArea = selectedClockInsideArea === '' ? found?.clockInMustBeInsideArea : selectedClockInsideArea;
    const clockInsideAreas = selectedType === SESSION_TYPE[1] ? false : clockInsideArea;
    const visibilityStatus = selectedVisibilityStatus || found?.visibilityStatus;
    const whoCanRegister = selectedType === SESSION_TYPE[1]
      ? SESSION_WHO_CAN_REGISTER_DATA[2] : selectedWhoCanRegister || found?.whoCanRegister;
    const needApproval = selectedNeedApproval === '' ? found?.needApproval : selectedNeedApproval;
    const needApprovals = selectedType === SESSION_TYPE[1] ? false : needApproval;
    const isFeatured = selectedFeaturedEvent === '' ? found?.isFeatured : selectedFeaturedEvent;
    const canPayUsingPoint = selectedCanPayPoint === '' ? found?.canPayUsingPoint : selectedCanPayPoint;
    const canPayUsingPoints = selectedType === SESSION_TYPE[1] ? false : canPayUsingPoint;
    const rewardType = selectedRewardType || found?.registrationRewardType;
    const desc = description || '';
    const featured = toNumber(featuredOrder) || 0;
    const type = selectedType || found?.type;
    const convertSurveyForm = typeof selectedSurveyForm === 'object' ? selectedSurveyForm.value : selectedSurveyForm;
    const surveyFormId = selectedType === SESSION_TYPE[1] ? '' : convertSurveyForm || found?.surveyFormId;
    const selectedAdditionalItems = transformAdditionalItemsPayload(additionalItems);
    const registrationRewardVoucherId = registrationRewardVouchers.reduce((acc, obj) => {
      acc.push(obj.value);
      return acc;
    }, []);
    const selectedCommentAccess = selectedEnableComment.value
      ? commentAccess : COMMENT_ACCESS_EVERYONE;

    if (tappedId) {
      await editSession(tappedId, selectedEventId, name, shortDescription, desc,
        startTimes, endTimes, toNumber(earliestClockInBeforeMinutes), toNumber(maxClockInMinutes),
        selectedCategoryId, selectedRoomId, clockInsideAreas, visibilityStatus,
        whoCanRegister, needApprovals, toNumber(quota), type, isFeatured,
        featured, surveyFormId, toNumber(normalPrice), toNumber(price), canPayUsingPoints,
        rewardType, registrationPointReward, registrationRewardVoucherId, speakersName,
        medias, selectedAdditionalItems, selectedEnableComment.value, selectedCommentAccess,
        transformLocalImagePath(banner), token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addSession(selectedEventId, name, shortDescription, desc, startTimes,
        endTimes, toNumber(earliestClockInBeforeMinutes), toNumber(maxClockInMinutes),
        selectedCategoryId, selectedRoomId, clockInsideAreas, visibilityStatus,
        whoCanRegister, needApprovals, toNumber(quota), type, isFeatured,
        featured, surveyFormId, toNumber(normalPrice), toNumber(price), canPayUsingPoints,
        rewardType, registrationPointReward, registrationRewardVoucherId, speakersName,
        medias, selectedAdditionalItems, selectedEnableComment.value, selectedCommentAccess,
        transformLocalImagePath(banner), token);
    }

    dispatch(reset(RXFORM_SESSION));
    dispatch(setSessionSelectedRoom(''));
    dispatch(setSessionSelectedEventName(''));
    dispatch(setSessionSelectedCategoryName(''));
    dispatch(setSessionBannerImage(''));
    dispatch(setSessionSelectedVoucher([]));
    dispatch(setSessionTableDataSpeakers([]));
    dispatch(setSessionSelectedSpeakers(null));
    dispatch(clearSessions());
    dispatch(dowloadSessionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingSession(false));
  }
};
