import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  PAGE_MODE_TABLE,
  MENUID_ACTIVITY_REWARD_REGISTRATION_REFERRAL,
  INITIAL_ORDER_BY_REGISTRATION_REFERRALS,
  RXFORM_REGISTRATION_REFERRAL,
} from '../../constant';
import {
  transformInitialValues, transformUserDropdownData, toCurrency, debounceSearch,
} from '../../helper';
import {
  setActiveSideMenuItem, setAlertErrorMessage,
  setRegistrationReferralSearchText, clearRegistrationReferrals,
  setRegistrationReferralSelectedPageSize,
  setRegistrationReferralSelectedOrderBy,
  setRegistrationReferralAdvancedFilterDialogSelectedFilterString,
  setRegistrationReferralTappedId,
  downloadRegistrationReferralsAsync, downloadRegistrationReferralAsync,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText, clearProfiles,
  saveRegistrationReferralsAsync, downloadProfilesAsync,
} from '../../redux/action';
import RegistrationReferralPage from './registration-referral.presentation';
import {
  clearEmployees, downloadEmployeesAsync, setHistoryEmployeeSearchText,
} from '../../../attendance/redux/action';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getInitialValues = (state) => {
  const {
    registrationReferrals, uiFunctionalPage, uiRegistrationReferral,
  } = state;
  const { data } = registrationReferrals;
  const { downloadingDeleting, tappedId } = uiRegistrationReferral;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};

  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      registeredUserId: found.referee.id ?? '',
      registeredUserName: found.referee.fullName ?? '',
      referrerUserId: found?.referree?.id || '',
      referrerUserName: found?.referree?.fullName || '',
      pointAmount: toCurrency(found.pointAmount),
    }) : {
      registeredUserId: '',
      registeredUserName: '',
      pointAmount: '',
      referrerUserId: '',
      referrerUserName: '',
    };
  return result;
};

const searchProfileDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchReferrerDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearEmployees());
    dispatch(downloadEmployeesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const mapStateToProps = (state) => ({
  downloading: state.uiRegistrationReferral.downloading,
  downloadingDeleting: state.uiRegistrationReferral.downloadingDeleting,
  pageMode: state.uiFunctionalPage.pageMode,
  initialValues: getInitialValues(state),
  profiles: transformUserDropdownData(state.profiles.data),
  referrers: transformUserDropdownData(state.employees.data),
  loadingProfiles: state.uiProfile.downloading,
  loadingReferrers: state.uiHistory.downloadingEmployees,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_ACTIVITY_REWARD_REGISTRATION_REFERRAL));
    dispatch(setRegistrationReferralSearchText(''));
    dispatch(clearRegistrationReferrals());
    dispatch(setRegistrationReferralSelectedPageSize(20));
    dispatch(setRegistrationReferralSelectedOrderBy(
      INITIAL_ORDER_BY_REGISTRATION_REFERRALS,
    ));
    dispatch(downloadRegistrationReferralsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(setHistoryEmployeeSearchText(''));
    dispatch(clearProfiles());
    dispatch(clearEmployees());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(downloadEmployeesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('referrer', 'referrer.id')
      .replace('registeredUser', 'referee.id');

    dispatch(setRegistrationReferralAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearRegistrationReferrals());
    dispatch(downloadRegistrationReferralsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadRegistrationReferralsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setRegistrationReferralSelectedPageSize(pageSize));
    dispatch(downloadRegistrationReferralsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDownloadPressed: () => {
    dispatch(saveRegistrationReferralsAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setRegistrationReferralSelectedPageSize(pageSize));
    dispatch(clearRegistrationReferrals());
    dispatch(downloadRegistrationReferralsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearRegistrationReferrals());
    dispatch(setRegistrationReferralAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setRegistrationReferralSelectedOrderBy(
      INITIAL_ORDER_BY_REGISTRATION_REFERRALS,
    ));
    dispatch(downloadRegistrationReferralsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setRegistrationReferralSearchText(text));
      dispatch(clearRegistrationReferrals());
      await dispatch(downloadRegistrationReferralsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setRegistrationReferralSelectedOrderBy(orderBy));
    dispatch(clearRegistrationReferrals());
    dispatch(downloadRegistrationReferralsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setRegistrationReferralTappedId(id));
    dispatch(downloadRegistrationReferralAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_REGISTRATION_REFERRAL));
  },
  onChangeProfileText: async (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchProfileDebounce(dispatch);
    }
  },
  onChangeReferrerText: async (text) => {
    dispatch(setHistoryEmployeeSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchReferrerDebounce(dispatch);
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationReferralPage);
