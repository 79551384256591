import { downloadSessionRegistration } from '../../../helper';
import {
  addSessionRegistration, downloadingDeletingSessionRegistration,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingSessionRegistration(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSessionRegistration;

    const SessionRegistration = await downloadSessionRegistration(tappedId, token);
    dispatch(addSessionRegistration(SessionRegistration));
  } finally {
    dispatch(downloadingDeletingSessionRegistration(false));
  }
};
