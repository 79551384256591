import {
  downloadingDeletingRegistrationReferral, addRegistrationReferral,
} from '../simple-action';
import { downloadRegistrationReferral } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication, uiRegistrationReferral } = getState();

  const { token } = authentication;
  const { tappedId } = uiRegistrationReferral;

  try {
    dispatch(downloadingDeletingRegistrationReferral(true));

    const registrationReferral = await downloadRegistrationReferral(
      tappedId,
      token,
    );

    dispatch(addRegistrationReferral(registrationReferral));
  } finally {
    dispatch(downloadingDeletingRegistrationReferral(false));
  }
};
