import {
  downloadingVoucherTokenAssignment, addVoucherTokenAssignment,
  setVoucherTokenAssignmentSelectedBypassPayment, setVoucherTokenAssignmentSelectedAssignmentMode,
  setVoucherTokenAssignmentSelectedBypassAchievement,
  setVoucherTokenAssignmentSelectedBypassMaxPurchase,
} from '../simple-action';
import { switches } from '../../../constant';
import { downloadVoucherTokenAssignment } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { tappedId } = getState().uiVoucherTokenAssignment;

  try {
    dispatch(downloadingVoucherTokenAssignment(true));

    const result = await downloadVoucherTokenAssignment(tappedId, token);
    dispatch(addVoucherTokenAssignment(result));

    dispatch(setVoucherTokenAssignmentSelectedAssignmentMode(result.codeAssignmentMode));
    dispatch(setVoucherTokenAssignmentSelectedBypassPayment(result.bypassPayment
      ? switches[0] : switches[1]));
    dispatch(setVoucherTokenAssignmentSelectedBypassAchievement(result.bypassAchievementValidation
      ? switches[0] : switches[1]));
    dispatch(setVoucherTokenAssignmentSelectedBypassMaxPurchase(result.bypassMaxPurchaseValidation
      ? switches[0] : switches[1]));
  } finally {
    dispatch(downloadingVoucherTokenAssignment(false));
  }
};
