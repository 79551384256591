import { connect } from 'react-redux';
import AlertBox from './alert-box.presentation';
import {
  setAlertConfirmationMessage, setAlertErrorMessage, setAlertInfoMessage, setAlertInputMessage,
  setAlertSuccessMessage,
  setAlertWarningMessage,
} from '../../redux/action';
import {
  ALERT_TYPE_CONFIRMATION, ALERT_TYPE_ERROR, ALERT_TYPE_INFO, ALERT_TYPE_INPUT,
  ALERT_TYPE_SUCCESS, ALERT_TYPE_WARNING,
} from '../../constant';

const mapStateToProps = (state) => {
  let message = '';
  switch (state.alert.type) {
    case ALERT_TYPE_INFO: {
      message = state.alert.info; break;
    }
    case ALERT_TYPE_WARNING: {
      message = state.alert.warning; break;
    }
    case ALERT_TYPE_ERROR: {
      message = state.alert.error; break;
    }
    case ALERT_TYPE_CONFIRMATION: {
      message = state.alert.confirmation; break;
    }
    case ALERT_TYPE_SUCCESS: {
      message = state.alert.success; break;
    }
    case ALERT_TYPE_INPUT: {
      message = state.alert.input; break;
    }
    default: {
      message = ''; break;
    }
  }

  return ({
    forcePopUp: state.alert.forcePopUp,
    message,
    placeholder: state.alert.inputPlaceholder,
    type: state.alert.type,
  });
};

const mapDispatchToProps = (dispatch) => ({
  onHideAlert: (type) => {
    switch (type) {
      case ALERT_TYPE_INFO: {
        dispatch(setAlertInfoMessage(''));
        break;
      }
      case ALERT_TYPE_WARNING: {
        dispatch(setAlertWarningMessage(''));
        break;
      }
      case ALERT_TYPE_ERROR: {
        dispatch(setAlertErrorMessage(''));
        break;
      }
      case ALERT_TYPE_SUCCESS: {
        dispatch(setAlertSuccessMessage(''));
        break;
      }
      case ALERT_TYPE_CONFIRMATION: {
        dispatch(setAlertConfirmationMessage(''));
        break;
      }
      case ALERT_TYPE_INPUT: {
        dispatch(setAlertInputMessage('', ''));
        break;
      }
      default: {
        dispatch(setAlertErrorMessage(''));
        dispatch(setAlertInfoMessage(''));
        dispatch(setAlertWarningMessage(''));
        dispatch(setAlertConfirmationMessage(''));
        dispatch(setAlertInputMessage('', ''));
        break;
      }
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertBox);
