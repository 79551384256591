import { reset } from 'redux-form';
import { RXFORM_EVENT_OPERATOR_INVITATION } from '../../../constant';
import { addEventOperatorInvitation } from '../../../helper';
import {
  addingEventOperatorInvitation, clearEventOperatorInvitations, setAlertErrorMessage,
  setEventOperatorInvitationSelectedEventId, setEventOperatorInvitationSelectedRecipient,
} from '../simple-action';
import downloadEventOperatorInvitationsAsync from './downloadEventOperatorInvitationsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(addingEventOperatorInvitation(true));

    const { token } = getState().authentication;
    const { selectedEvent, selectedRecipients } = getState().uiEventOperatorInvitation;
    const emails = selectedRecipients.reduce((acc, obj) => {
      acc.push(obj.value);
      return acc;
    }, []);

    await addEventOperatorInvitation(selectedEvent, emails, token);

    dispatch(reset(RXFORM_EVENT_OPERATOR_INVITATION));
    dispatch(clearEventOperatorInvitations());
    dispatch(downloadEventOperatorInvitationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setEventOperatorInvitationSelectedRecipient([]));
    dispatch(setEventOperatorInvitationSelectedEventId(''));
  } finally {
    dispatch(addingEventOperatorInvitation(false));
  }
};
