import moment from 'moment';
import {
  APPLICABLE_AREA_MODE_SPECIFIC_BRANCHES, VOUCHER_TYPE_PUBLIC, PAGE_MODE_EDIT, PAGE_MODE_TABLE,
  RXFIELD_VOUCHER_CATEGORY_DESCRIPTION, RXFIELD_VOUCHER_CATEGORY_IMAGE,
  RXFIELD_VOUCHER_CATEGORY_NAME,
  RXFIELD_VOUCHER_ACTION_TYPE, RXFIELD_VOUCHER_APPLICABLE_AREA_MODE,
  RXFIELD_VOUCHER_APPLICABLE_BRANCHES, RXFIELD_VOUCHER_CATEGORY, RXFIELD_VOUCHER_CODE,
  RXFIELD_VOUCHER_DESCRIPTION, RXFIELD_VOUCHER_IMAGE,
  RXFIELD_VOUCHER_MAX_ACHIEVEMENT_POIN_TO_PURCHASE, RXFIELD_VOUCHER_MAX_PURCHASE_PER_USER,
  RXFIELD_VOUCHER_MERCHANT, RXFIELD_VOUCHER_MIN_ACHIEVEMENT_POIN_TO_PURCHASE, RXFIELD_VOUCHER_POINT,
  RXFIELD_VOUCHER_STATUS, RXFIELD_VOUCHER_TITLE, RXFIELD_VOUCHER_TYPE, RXFIELD_VOUCHER_VALID_FROM,
  RXFIELD_VOUCHER_VALID_UNTIL, RXFIELD_VOUCHER_VISIBILITY, RXFIELD_TOKEN_REQUEST_VOUCHER,
  RXFIELD_TOKEN_REQUEST_QUANTITY, RXFIELD_TOKEN_REQUEST_VALID_FROM, RXFIELD_VOUCHER_MONETARY_VALUE,
  RXFIELD_TOKEN_REQUEST_VALID_UNTIL, RXFIELD_VOUCHER_IS_PROMOTED,
  RXFIELD_VOUCHER_ORDER, RXFIELD_TOKEN_IMPORT_CODE,
  RXFIELD_TOKEN_IMPORT_VALID_FROM,
  RXFIELD_TOKEN_IMPORT_VALID_UNTIL,
  RXFIELD_TOKEN_IMPORT_VOUCHER,
  RXFIELD_PROMOTED_MERCHANT_IS_PROMOTED,
  RXFIELD_PROMOTED_MERCHANT_ORDER,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_VOUCHER,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_ASSIGNMENT_MODE,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_VALID_FROM,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_VALID_UNTIL,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_NOTES,
  ASSIGNMENT_MODE_CUSTOM_CODE, ASSIGNMENT_MODE_GENERATE_NEW_CODE,
  ASSIGNMENT_METHOD_IMMEDIATELY, ASSIGNMENT_METHOD_URL_ACCESS,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_VALID_FROM,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_VALID_UNTIL,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_MODE,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_NOTES,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VALID_FROM,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VALID_UNTIL,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CODE,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_VALID_FROM,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_VALID_UNTIL,
  RXFIELD_VOUCHER_USAGE_TITLE,
  RXFIELD_VOUCHER_USAGE_MERCHANT_NAME, RXFIELD_VOUCHER_USAGE_TOKEN_COUNT,
  RXFIELD_VOUCHER_USAGE_AVAILABLE_TOKEN_COUNT, RXFIELD_VOUCHER_USAGE_PURCHASED_TOKEN_COUNT,
  RXFIELD_VOUCHER_USAGE_CLAIMED_TOKEN_COUNT, RXFIELD_VOUCHER_USAGE_REVOKED_TOKEN_COUNT,
  RXFIELD_VOUCHER_USAGE_EXPIRED_TOKEN_COUNT,
} from './constant';
import { rxFormValidateRequiredFields } from '../../validation';
import LocalizedString from '../../localization';

export const rxformValidateVoucherCategory = (values) => {
  const requiredFields = [
    RXFIELD_VOUCHER_CATEGORY_NAME,
    RXFIELD_VOUCHER_CATEGORY_DESCRIPTION,
    RXFIELD_VOUCHER_CATEGORY_IMAGE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateVoucher = (values) => {
  let requiredFields = [
    RXFIELD_VOUCHER_TITLE,
    RXFIELD_VOUCHER_TYPE,
    RXFIELD_VOUCHER_POINT,
    RXFIELD_VOUCHER_MAX_PURCHASE_PER_USER,
    RXFIELD_VOUCHER_MIN_ACHIEVEMENT_POIN_TO_PURCHASE,
    RXFIELD_VOUCHER_MAX_ACHIEVEMENT_POIN_TO_PURCHASE,
    RXFIELD_VOUCHER_ACTION_TYPE,
    RXFIELD_VOUCHER_VISIBILITY,
    RXFIELD_VOUCHER_MERCHANT,
    RXFIELD_VOUCHER_APPLICABLE_AREA_MODE,
    RXFIELD_VOUCHER_CATEGORY,
    RXFIELD_VOUCHER_VALID_FROM,
    RXFIELD_VOUCHER_VALID_UNTIL,
    RXFIELD_VOUCHER_IMAGE,
    RXFIELD_VOUCHER_DESCRIPTION,
    RXFIELD_VOUCHER_IS_PROMOTED,
    RXFIELD_VOUCHER_MONETARY_VALUE,
  ];

  const isCodeRequired = values.type === VOUCHER_TYPE_PUBLIC
    ? [...requiredFields, RXFIELD_VOUCHER_CODE] : requiredFields;
  requiredFields = isCodeRequired;
  const isApplicableBranchRequired = values.applicableAreaMode
  === APPLICABLE_AREA_MODE_SPECIFIC_BRANCHES
    ? [...requiredFields, RXFIELD_VOUCHER_APPLICABLE_BRANCHES] : requiredFields;
  requiredFields = isApplicableBranchRequired;
  const isStatusRequired = values.pageMode === PAGE_MODE_TABLE
    ? [...requiredFields, RXFIELD_VOUCHER_STATUS] : requiredFields;
  requiredFields = isStatusRequired;
  const isOrderRequired = values.isPromoted
    ? [...requiredFields, RXFIELD_VOUCHER_ORDER] : requiredFields;
  requiredFields = isOrderRequired;

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!moment(values.validUntil).isAfter(moment(values.validFrom))) {
    errors.validUntil = LocalizedString.common.errMsgStartEndDate;
  }
  return errors;
};

export const rxformValidateTokenRequest = (values) => {
  const requiredFields = [
    RXFIELD_TOKEN_REQUEST_VOUCHER,
    RXFIELD_TOKEN_REQUEST_QUANTITY,
    RXFIELD_TOKEN_REQUEST_VALID_FROM,
    RXFIELD_TOKEN_REQUEST_VALID_UNTIL,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!moment(values.validUntil).isAfter(moment(values.validFrom))) {
    errors.validUntil = LocalizedString.common.errMsgStartEndDate;
  }
  return errors;
};

export const rxformValidateTokenImport = (values, props) => {
  const { pageMode } = props;
  if (pageMode === PAGE_MODE_EDIT) {
    const requiredFields = [
      RXFIELD_TOKEN_IMPORT_CODE,
      RXFIELD_TOKEN_IMPORT_VALID_FROM,
      RXFIELD_TOKEN_IMPORT_VALID_UNTIL,
    ];
    const errors = rxFormValidateRequiredFields(values, requiredFields);

    if (!moment(values.validUntil).isAfter(moment(values.validFrom))) {
      errors.validUntil = LocalizedString.common.errMsgStartEndDate;
    }
    return errors;
  }
  const requiredFields = [
    RXFIELD_TOKEN_IMPORT_VOUCHER,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxFormValidatePromotedMerchant = (values) => {
  const requiredFields = [
    RXFIELD_PROMOTED_MERCHANT_IS_PROMOTED,
    RXFIELD_PROMOTED_MERCHANT_ORDER,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  return errors;
};

export const rxformValidateVoucherTokenAssignment = (values, props) => {
  const { pageMode } = props;
  if (pageMode === PAGE_MODE_EDIT) {
    const requiredFields = [
      RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_MODE,
      RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_NOTES,
      ...(values.codeAssignmentMode === ASSIGNMENT_MODE_CUSTOM_CODE
        ? [RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CODE] : []),
      ...(values.codeAssignmentMode === ASSIGNMENT_MODE_CUSTOM_CODE
        || values.codeAssignmentMode === ASSIGNMENT_MODE_GENERATE_NEW_CODE
        ? [RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VALID_FROM,
          RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VALID_UNTIL] : []),
      ...(values.assignmentMethod === ASSIGNMENT_METHOD_URL_ACCESS
        ? [RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_VALID_FROM,
          RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_VALID_UNTIL] : []),
    ];
    const errors = rxFormValidateRequiredFields(values, requiredFields);

    if (values.codeAssignmentMode === ASSIGNMENT_MODE_CUSTOM_CODE
      || values.codeAssignmentMode === ASSIGNMENT_MODE_GENERATE_NEW_CODE) {
      if (!moment(values.validUntil).isAfter(moment(values.validFrom))) {
        errors.validUntil = LocalizedString.common.errMsgStartEndDate;
      }
    }

    if (values.assignmentMethod === ASSIGNMENT_METHOD_URL_ACCESS) {
      if (!moment(values.assignmentValidUntil)
        .isAfter(moment(values.assignmentValidFrom))) {
        errors.assignmentValidUntil = LocalizedString.common.errMsgStartEndDate;
      }
    }
    if (typeof values.bypassPayment === 'object') {
      if (values.bypassPayment.value !== true && values.bypassPayment.value !== false) {
        errors.bypassPayment = LocalizedString.common.errMsgRequired;
      }
    } else if (values.bypassPayment !== true && values.bypassPayment !== false) {
      errors.bypassPayment = LocalizedString.common.errMsgRequired;
    }

    if (typeof values.bypassMaxPurchaseValidation === 'object') {
      if (values.bypassMaxPurchaseValidation.value !== true
      && values.bypassMaxPurchaseValidation.value !== false) {
        errors.bypassMaxPurchaseValidation = LocalizedString.common.errMsgRequired;
      }
    } else if (values.bypassMaxPurchaseValidation !== true
      && values.bypassMaxPurchaseValidation !== false) {
      errors.bypassMaxPurchaseValidation = LocalizedString.common.errMsgRequired;
    }
    if (typeof values.bypassAchievementValidation === 'object') {
      if (values.bypassAchievementValidation.value !== true
      && values.bypassAchievementValidation.value !== false) {
        errors.bypassAchievementValidation = LocalizedString.common.errMsgRequired;
      }
    } else if (values.bypassAchievementValidation !== true
      && values.bypassAchievementValidation !== false) {
      errors.bypassAchievementValidation = LocalizedString.common.errMsgRequired;
    }

    return errors;
  }
  const requiredFields = [
    RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_VOUCHER,
    RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_ASSIGNMENT_MODE,
    RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_NOTES,
    ...(values.createCodeAssignmentMode === ASSIGNMENT_MODE_CUSTOM_CODE
      ? [RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE] : []),
    ...(values.createCodeAssignmentMode === ASSIGNMENT_MODE_CUSTOM_CODE
      || values.createCodeAssignmentMode === ASSIGNMENT_MODE_GENERATE_NEW_CODE
      ? [RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_VALID_FROM,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_VALID_UNTIL] : []),
    ...(values.createAssignmentMethod === ASSIGNMENT_METHOD_URL_ACCESS
      ? [RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_VALID_FROM,
        RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_VALID_UNTIL] : []),
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (values.createCodeAssignmentMode === ASSIGNMENT_MODE_CUSTOM_CODE
    || values.createCodeAssignmentMode === ASSIGNMENT_MODE_GENERATE_NEW_CODE) {
    if (!moment(values.createValidUntil).isAfter(moment(values.createValidFrom))) {
      errors.createValidUntil = LocalizedString.common.errMsgStartEndDate;
    }
  }

  if (values.createAssignmentMethod === ASSIGNMENT_METHOD_URL_ACCESS) {
    if (!moment(values.createAssignmentValidUntil)
      .isAfter(moment(values.createAssignmentValidFrom))) {
      errors.createAssignmentValidUntil = LocalizedString.common.errMsgStartEndDate;
    }
  }

  if (values.createBypassPayment !== true && values.createBypassPayment !== false) {
    errors.createBypassPayment = LocalizedString.common.errMsgRequired;
  }
  if (values.createBypassMaxPurchase !== true && values.createBypassMaxPurchase !== false) {
    errors.createBypassMaxPurchase = LocalizedString.common.errMsgRequired;
  }
  if (values.createBypassAchievement !== true && values.createBypassAchievement !== false) {
    errors.createBypassAchievement = LocalizedString.common.errMsgRequired;
  }
  if (values.createAssignmnetMethod === ASSIGNMENT_METHOD_IMMEDIATELY) {
    errors.createUsers = LocalizedString.common.errMsgRequired;
  }

  return errors;
};

export const rxFormVoucherUsage = (values) => {
  const requiredFields = [
    RXFIELD_VOUCHER_USAGE_TITLE, RXFIELD_VOUCHER_USAGE_MERCHANT_NAME,
    RXFIELD_VOUCHER_USAGE_TOKEN_COUNT, RXFIELD_VOUCHER_USAGE_AVAILABLE_TOKEN_COUNT,
    RXFIELD_VOUCHER_USAGE_PURCHASED_TOKEN_COUNT, RXFIELD_VOUCHER_USAGE_CLAIMED_TOKEN_COUNT,
    RXFIELD_VOUCHER_USAGE_REVOKED_TOKEN_COUNT, RXFIELD_VOUCHER_USAGE_EXPIRED_TOKEN_COUNT,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  return errors;
};
