import { DOWNLOADING_PREVIEW_SELECTED_FORM, SET_UPLOADING_PREVIEW_FORM_RESPONSE } from '../action/simple-action';

const initialState = {
  downloading: false,
  uploadingResponse: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_PREVIEW_SELECTED_FORM:
      return { ...state, downloading: action.status };
    case SET_UPLOADING_PREVIEW_FORM_RESPONSE:
      return { ...state, uploadingResponse: action.status };
    default:
      return state;
  }
};
