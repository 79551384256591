import {
  addCaptchaRequest, downloadingDeletingCaptchaRequest,
} from '../simple-action';
import { downloadCaptchaRequest } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingCaptchaRequest(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCaptchaRequest;

    const response = await downloadCaptchaRequest(tappedId, token);
    dispatch(addCaptchaRequest(response));
  } finally {
    dispatch(downloadingDeletingCaptchaRequest(false));
  }
};
