import { reset } from 'redux-form';
import {
  addingEditingWorkingShift, clearWorkingShifts, setAlertErrorMessage,
  setWorkingShiftSelectedBranchId,
  setFunctionalPageMode, setWorkingShiftDuplicateMode,
} from '../simple-action';
import { addWorkingShift, editWorkingShift } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_WORKING_SHIFT } from '../../../constant';
import downloadWorkingShiftsAsync from './downloadWorkingShiftsAsync';

export default (name, startWorkingHour, startWorkingHourMarginBeforeMinutes,
  startWorkingHourMarginAfterMinutes, endWorkingHour, endWorkingHourMarginBeforeMinutes,
  endWorkingHourMarginAfterMinutes, earliestClockInDriftTimeMinutes,
  autoClockOutAfterMinutes) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingWorkingShift(true));

    const { token } = getState().authentication;
    const {
      selectedBranchId, tappedId, duplicateMode,
    } = getState().uiWorkingShift;
    const { data } = getState().workingShifts;

    const found = data[tappedId];
    const branchId = selectedBranchId || found.branch.id;

    if (tappedId && !duplicateMode) {
      await editWorkingShift(
        tappedId, name, branchId,
        startWorkingHour, startWorkingHourMarginBeforeMinutes,
        startWorkingHourMarginAfterMinutes,
        endWorkingHour, endWorkingHourMarginBeforeMinutes,
        endWorkingHourMarginAfterMinutes,
        earliestClockInDriftTimeMinutes, autoClockOutAfterMinutes,
        token,
      );
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addWorkingShift(
        name, branchId,
        startWorkingHour, startWorkingHourMarginBeforeMinutes,
        startWorkingHourMarginAfterMinutes,
        endWorkingHour, endWorkingHourMarginBeforeMinutes,
        endWorkingHourMarginAfterMinutes,
        earliestClockInDriftTimeMinutes, autoClockOutAfterMinutes,
        token,
      );
      if (duplicateMode) { dispatch(setWorkingShiftDuplicateMode(false)); }
    }

    dispatch(reset(RXFORM_WORKING_SHIFT));
    dispatch(clearWorkingShifts());
    dispatch(downloadWorkingShiftsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setWorkingShiftSelectedBranchId(''));
  } finally {
    dispatch(addingEditingWorkingShift(false));
  }
};
