import {
  downloadingAttendanceSummaryClockOutArea, setAttendanceSummaryClockOutArea,
} from '../simple-action';
import { downloadAttendanceClockOutArea } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAttendanceSummaryClockOutArea(true));

    const { authentication } = getState();
    const { token } = authentication;

    const data = await downloadAttendanceClockOutArea(token);

    dispatch(setAttendanceSummaryClockOutArea(data));
  } finally {
    dispatch(downloadingAttendanceSummaryClockOutArea(false));
  }
};
