import { connect } from 'react-redux';

import { getToken } from 'firebase/messaging';
import {
  addFcmToken, clearCaptcha, createCaptchaAsync, setAlertErrorMessage,
  setIsNotificationPromptOpen, setIsNotificationSnackbarOpen,
} from '../../redux/action';
import NotificationComponent from './notification.presentation';
import { VAPID_KEY, messaging } from '../../firebase';
import LocalizedString from '../../localization';
import { transformConfigValueBoolean } from '../../helper';
import { downloadMyConfigItemsAsync } from '../../module/configuration/redux/action';

const getFcmToken = async (dispatch, msg) => {
  const fcmToken = await getToken(msg, { vapidKey: VAPID_KEY });
  if (fcmToken) {
    dispatch(addFcmToken(fcmToken));
  } else {
    const permission = await Notification.requestPermission();
    if (permission !== 'granted') {
      dispatch(setAlertErrorMessage(LocalizedString.notification.errorMessagePermissionDenied));
    }
  }
};

const mapStateToProps = (state) => ({
  message: state.uiNotification.message,
  isSnackbarOpen: state.uiNotification.isSnackbarOpen,
  isNotificationPromptOpen: state.uiNotification.isNotificationPromptOpen,
  useCaptcha: transformConfigValueBoolean(state.myConfigItems?.auth?.useCaptchaForLoginApi || ''),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: async () => {
    const isChrome = window.navigator.userAgent.indexOf('Chrome') !== -1;
    const msg = await messaging;
    try {
      if (msg) {
        if (isChrome) {
          await getFcmToken(dispatch);
        } else if (Notification.permission !== 'granted') {
          dispatch(setIsNotificationPromptOpen(true));
        }
      }
    } catch (error) {
      dispatch(setAlertErrorMessage(LocalizedString.notification.errorMessageRetrievingToken));
    }
  },
  onNotificationPromptAllowButtonPressed: async (useCaptcha) => {
    try {
      await getFcmToken(dispatch);
      dispatch(downloadMyConfigItemsAsync(true))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
      if (useCaptcha) {
        dispatch(clearCaptcha());
        dispatch(createCaptchaAsync())
          .catch((err) => dispatch(setAlertErrorMessage(err)));
      }
      dispatch(setIsNotificationPromptOpen(false));
    } catch (error) {
      dispatch(setAlertErrorMessage(LocalizedString.notification.errorMessageRetrievingToken));
    }
  },
  onChangeSnackbarVisibility: (status) => {
    dispatch(setIsNotificationSnackbarOpen(status));
  },
  onChangeNotificationPromptVisibility: (status) => {
    dispatch(setIsNotificationPromptOpen(status));
  },
  onNotificationPressed: (url, history) => {
    if (url && url.includes('qantor://')) {
      const navigationUrl = `/${url.split('//')[1]}`;
      history.push(navigationUrl);
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationComponent);
