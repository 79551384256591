import { reset } from 'redux-form';
import {
  RXFORM_DUPLICATE_FORM_DIALOG,
} from '../../../constant';
import { duplicateForm } from '../../../helper';
import {
  duplicatingForm, setAlertErrorMessage,
  setFormDuplicateDialogVisibility,
} from '../simple-action';
import downloadFormsAsync from './downloadFormsAsync';

export default (values) => async (dispatch, getState) => {
  try {
    dispatch(duplicatingForm(true));

    const { token } = getState().authentication;
    const {
      newName,
    } = values;
    const { tappedId } = getState().uiForm;

    await duplicateForm(tappedId, newName, token);

    dispatch(setFormDuplicateDialogVisibility(false));
    dispatch(reset(RXFORM_DUPLICATE_FORM_DIALOG));
    dispatch(downloadFormsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  } finally {
    dispatch(duplicatingForm(false));
  }
};
