import { change } from 'redux-form';
import {
  PAGE_MODE_TABLE, RXFIELD_EVENT_MEDIA, RXFIELD_EVENT_SPEAKERS, RXFORM_EVENT, approval,
} from '../../../constant';
import { deleteEvent, downloadEvent } from '../../../helper';
import {
  addEvent, clearEvents, downloadingDeletingEvent, setAlertErrorMessage,
  setEventLocationSelected, setEventSelectedEnableComment,
  setEventSelectedSurveyForm, setEventTableDataSpeakers,
  setEventTappedId, setFunctionalPageMode,
} from '../simple-action';
import downloadEventsAsync from './downloadEventsAsync';
import { downloadForm } from '../../../../dform/helper';
import { getDeepLinkScheme } from '../../../../configuration/helper';

export const transformSpeakers = (data) => (data.length > 0 ? data.map((x) => (x.speaker.id
  ? { label: x.speaker.name, value: x.speaker.id } : x.speaker)) : []);

const DEEP_LINK_PATH = 'app/event/detail/';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingEvent(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiEvent;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteEvent(tappedId, token);
      dispatch(setEventTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearEvents());
      dispatch(downloadEventsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const event = await downloadEvent(tappedId, token);
      const result = await getDeepLinkScheme(event, DEEP_LINK_PATH, tappedId, token);

      dispatch(addEvent(result));
      dispatch(setEventSelectedEnableComment(event.enableComment ? approval[0] : approval[1]));
      dispatch(setEventLocationSelected(event?.location?.id));
      dispatch(setEventTableDataSpeakers(transformSpeakers(event.speakers)));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_MEDIA, event.media));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_SPEAKERS, event.speakers));

      if (event.surveyFormId) {
        const form = await downloadForm(event.surveyFormId, token);
        dispatch(setEventSelectedSurveyForm({ label: form.name, value: form.id }));
      }
    }
  } finally {
    dispatch(downloadingDeletingEvent(false));
  }
};
