import { connect } from 'react-redux';
import { reset, change } from 'redux-form';
import {
  clearNewsCategories, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setNewsCategorySearchText, setNewsCategorySelectedPageSize,
  setNewsCategorySelectedOrderBy, setNewsCategoryTappedId,
  addEditNewsCategoryAsync, downloadDeleteNewsCategoryAsync, downloadNewsCategoriesAsync,
  setNewsCategoryAdvancedFilterDialogSelectedFilterString,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_NEWS_CATEGORIES, PAGE_MODE_TABLE, RXFORM_NEWS_CATEGORY,
  MENUID_NEWS_MANAGEMENT_NEWS_CATEGORY, RXFIELD_NEWS_CATEGORY_IMAGE,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE,
} from '../../constant';
import { transformInitialValues } from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import NewsCategoryPage from './news-category.presentation';

const getInitialValues = (state) => {
  const { newsCategories, uiFunctionalPage, uiNewsCategory } = state;
  const { data } = newsCategories;
  const { downloadingDeleting, tappedId } = uiNewsCategory;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    image: found.image ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.image}` : null,
  }) : {
    name: '',
    description: '',
    image: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiNewsCategory.addingEditing,
  downloading: state.uiNewsCategory.downloading,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_NEWS_MANAGEMENT_NEWS_CATEGORY));
    dispatch(setNewsCategoryAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setNewsCategorySearchText(''));
    dispatch(clearNewsCategories());
    dispatch(setNewsCategorySelectedPageSize(20));
    dispatch(setNewsCategorySelectedOrderBy(INITIAL_ORDER_BY_NEWS_CATEGORIES));
    dispatch(downloadNewsCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadNewsCategoriesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setNewsCategorySelectedPageSize(pageSize));
    dispatch(downloadNewsCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_NEWS_CATEGORY));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteNewsCategoryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setNewsCategoryTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setNewsCategoryTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onRefresh: (pageSize) => {
    dispatch(setNewsCategorySelectedPageSize(pageSize));
    dispatch(clearNewsCategories());
    dispatch(downloadNewsCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ name, description, image }) => {
    try {
      await dispatch(addEditNewsCategoryAsync(name, description, image));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setNewsCategoryAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setNewsCategorySearchText(text));
      dispatch(clearNewsCategories());
      await dispatch(downloadNewsCategoriesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setNewsCategorySelectedOrderBy(orderBy));
    dispatch(clearNewsCategories());
    dispatch(downloadNewsCategoriesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ name, description, image }) => {
    await dispatch(addEditNewsCategoryAsync(name, description, image));
  },
  onViewPressed: (id) => {
    dispatch(setNewsCategoryTappedId(id));
  },
  onEditPressed: (id) => {
    dispatch(setNewsCategoryTappedId(id));
  },
  onImageSelected: (image) => {
    dispatch(change(RXFORM_NEWS_CATEGORY, RXFIELD_NEWS_CATEGORY_IMAGE, image));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsCategoryPage);
