import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  clearNotificationTasks, setActiveSideMenuItem, setAdvancedFilterDialogSelectedFilterString,
  setAlertConfirmationMessage, setAlertErrorMessage, setNotificationTaskDuplicateDialogVisibility,
  setNotificationTaskSearchText, setNotificationTaskSelectedPageSize,
  setNotificationTaskSelectedOrderBy, setNotificationTaskTappedId,
  setNotificationTaskTotalPersonCount,
  cancelNotificationTaskAsync, downloadNotificationTasksAsync, downloadNotificationTaskAsync,
  downloadNotificationTaskTotalPersonCountAsync, sendNotificationTaskNotificationAsync,
} from '../../redux/action';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_NOTIFICATION_TASKS,
  MENUID_NOTIFICATION_NOTIFICATION_TASK, PAGE_MODE_TABLE, REST_BASE_URL,
  REST_URL_IMAGE_STORAGE, RXFIELD_NOTIFICATION_TASK_RECIPIENT_TYPE,
  RXFORM_NOTIFICATION_TASK, RXFIELD_NOTIFICATION_TASK_IMAGE,
} from '../../constant';
import { transformInitialValues, toMoment, getPermission } from '../../helper';
import LocalizedString from '../../../../localization';
import NotificationTaskPage from './notification-task.presentation';

const getInitialValues = (state) => {
  const { notificationTasks, uiFunctionalPage, uiNotificationTask } = state;
  const { data } = notificationTasks;
  const {
    tappedId, canceling, downloadingDeleting, duplicateDialogVisibility,
  } = uiNotificationTask;
  const { pageMode } = uiFunctionalPage;

  const found = (pageMode !== PAGE_MODE_TABLE && (!canceling && !downloadingDeleting))
    || duplicateDialogVisibility
    ? data[tappedId] : {};
  const initVal = Object.keys(found).length > 0 && !downloadingDeleting
    ? transformInitialValues(found, {
      startTime: found.startTime ? toMoment(found.startTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE)
        : null,
      endTime: found.endTime ? toMoment(found.endTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
      progress: `${found.progress}%`,
      image: found.image ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.image}` : null,
    }) : {
      image: null,
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  canceling: state.uiNotificationTask.canceling,
  downloading: state.uiNotificationTask.downloading,
  downloadingDeleting: state.uiNotificationTask.downloadingDeleting,
  initialValues: getInitialValues(state),
  tappedId: state.uiFunctionalPage.tappedId || '',
  currentTaskStatus: state.notificationTasks.data[state.uiFunctionalPage.tappedId]
    ? state.notificationTasks.data[state.uiFunctionalPage.tappedId].taskStatus : '',
  downloadingTotalPersonCount: state.uiNotificationTask.downloadingTotalPersonCount,
  duplicateDialogVisibility: state.uiNotificationTask.duplicateDialogVisibility,
  sendingNotification: state.uiNotificationTask.sendingNotification,
  totalPersonCount: state.uiNotificationTask.totalPersonCount,
  isDuplicateAllowed: getPermission(state, 'CORE_SEND_SIMPLE_NOTIFICATION'),
  isCancelTaskAllowed: getPermission(state, 'CORE_CANCEL_SIMPLE_NOTIFICATION'),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_NOTIFICATION_NOTIFICATION_TASK));
    dispatch(setNotificationTaskSearchText(''));
    dispatch(clearNotificationTasks());
    dispatch(setNotificationTaskSelectedPageSize(20));
    dispatch(setNotificationTaskSelectedOrderBy(INITIAL_ORDER_BY_NOTIFICATION_TASKS));
    dispatch(downloadNotificationTasksAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearNotificationTasks());
    dispatch(setNotificationTaskSelectedPageSize(20));
    dispatch(setNotificationTaskSelectedOrderBy(INITIAL_ORDER_BY_NOTIFICATION_TASKS));
    dispatch(downloadNotificationTasksAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_NOTIFICATION_TASK));
    dispatch(setNotificationTaskDuplicateDialogVisibility(false));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadNotificationTasksAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setNotificationTaskSelectedPageSize(pageSize));
    dispatch(downloadNotificationTasksAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmDeletePressed: () => {
    dispatch(cancelNotificationTaskAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCountPressed: ({ filteredRecipients, recipientType }) => {
    dispatch(downloadNotificationTaskTotalPersonCountAsync(filteredRecipients, recipientType))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setNotificationTaskTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgCancelConfirmation));
  },
  onDuplicatePressed: (id) => {
    dispatch(setNotificationTaskTappedId(id));
    dispatch(setNotificationTaskTotalPersonCount(0));
    dispatch(setNotificationTaskDuplicateDialogVisibility(true));
  },
  onRecipientTypeOptionSelected: (option) => {
    if (option) {
      dispatch(change(RXFORM_NOTIFICATION_TASK, RXFIELD_NOTIFICATION_TASK_RECIPIENT_TYPE, option));
    } else {
      dispatch(change(RXFORM_NOTIFICATION_TASK, RXFIELD_NOTIFICATION_TASK_RECIPIENT_TYPE, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setNotificationTaskSelectedPageSize(pageSize));
    dispatch(clearNotificationTasks());
    dispatch(downloadNotificationTasksAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearNotificationTasks());
    dispatch(setNotificationTaskSelectedOrderBy(INITIAL_ORDER_BY_NOTIFICATION_TASKS));
    dispatch(downloadNotificationTasksAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setNotificationTaskSearchText(text));
      dispatch(clearNotificationTasks());
      await dispatch(downloadNotificationTasksAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSendNotificationPressed: async (values) => {
    try {
      await dispatch(sendNotificationTaskNotificationAsync(values));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setNotificationTaskSelectedOrderBy(orderBy));
    dispatch(clearNotificationTasks());
    dispatch(downloadNotificationTasksAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setNotificationTaskTappedId(id));
    dispatch(downloadNotificationTaskAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onImageSelected: (image) => {
    dispatch(change(
      RXFORM_NOTIFICATION_TASK,
      RXFIELD_NOTIFICATION_TASK_IMAGE, image,
    ));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationTaskPage);
