import { ORDER_SORT_ASCENDING, ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_INDUSTRIES = '/activity/api/v1/Industry?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_INDUSTRY = '/activity/api/v1/Industry';
export const REST_URL_VIEW_DELETE_INDUSTRY = '/activity/api/v1/Industry/{id}';
export const REST_URL_CUSTOMERS = '/activity/api/v1/Customer?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_CUSTOMER = '/activity/api/v1/Customer';
export const REST_URL_VIEW_DELETE_CUSTOMER = '/activity/api/v1/Customer/{id}';
export const REST_URL_ACTIVITIES = '/activity/api/v1/Activity?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=profile.fullName|customer.name|activityType.name&filterString=status=enabled';
export const REST_URL_VIEW_DELETE_ACTIVITY = '/activity/api/v1/Activity/{id}';
export const REST_URL_SAVE_ACTIVITY = '/activity/api/v1/Activity/Download?filterString=';
export const REST_URL_ACTIVITY_TYPES = '/activity/api/v1/ActivityType?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_ACTIVITY_TYPE = '/activity/api/v1/ActivityType';
export const REST_URL_VIEW_DELETE_ACTIVITY_TYPE = '/activity/api/v1/ActivityType/{id}';

export const ROUTE_NAME_ACTIVITY_MANAGEMENT = '/activity-management';
export const ROUTE_NAME_INDUSTRY = '/industry';
export const ROUTE_NAME_CUSTOMER = '/customer';
export const ROUTE_NAME_ACTIVITY = '/activity-history';
export const ROUTE_NAME_ACTIVITY_TYPE = '/activity-type';

export const RXFORM_INDUSTRY = 'industryPage';
export const RXFORM_CUSTOMER = 'customerPage';
export const RXFORM_ACTIVITY = 'activityPage';
export const RXFORM_ACTIVITY_TYPE = 'activityTypePage';

export const RXFIELD_INDUSTRY_NAME = 'name';
export const RXFIELD_INDUSTRY_DESCRIPTION = 'description';
export const RXFIELD_CUSTOMER_REF_ID = 'refId';
export const RXFIELD_CUSTOMER_NAME = 'name';
export const RXFIELD_CUSTOMER_DESCRIPTION = 'description';
export const RXFIELD_CUSTOMER_INDUSTRY = 'industry';
export const RXFIELD_ACTIVITY_ACTIVITY_TYPE = 'activityType';
export const RXFIELD_ACTIVITY_ACTIVITY_DESCRIPTION = 'activityDescription';
export const RXFIELD_ACTIVITY_PROFILE = 'profile';
export const RXFIELD_ACTIVITY_START_TIME = 'startTime';
export const RXFIELD_ACTIVITY_START_LATITUDE = 'startLatitude';
export const RXFIELD_ACTIVITY_START_LONGITUDE = 'startLongitude';
export const RXFIELD_ACTIVITY_START_LOCATION_ID = 'startLocationId';
export const RXFIELD_ACTIVITY_START_LOCATION_NAME = 'startLocationName';
export const RXFIELD_ACTIVITY_END_TIME = 'endTime';
export const RXFIELD_ACTIVITY_END_LATITUDE = 'endLatitude';
export const RXFIELD_ACTIVITY_END_LONGITUDE = 'endLongitude';
export const RXFIELD_ACTIVITY_END_LOCATION_ID = 'endLocationId';
export const RXFIELD_ACTIVITY_END_LOCATION_NAME = 'endLocationName';
export const RXFIELD_ACTIVITY_END_LOCATION_NOTES = 'endActivityNotes';
export const RXFIELD_ACTIVITY_CUSTOMER = 'customer';
export const RXFIELD_ACTIVITY_MANAGER = 'manager';
export const RXFIELD_ACTIVITY_ATTENDANCE_DATE = 'attendanceDate';
export const RXFIELD_ACTIVITY_COMPANY = 'company';
export const RXFIELD_ACTIVITY_DEPARTMENT = 'department';
export const RXFIELD_ACTIVITY_DIVISION = 'division';
export const RXFIELD_ACTIVITY_TYPE_NAME = 'name';
export const RXFIELD_ACTIVITY_TYPE_DESCRIPTION = 'description';

export const RXSTATE_INDUSTRIES = 'industries';
export const RXSTATE_INDUSTRY_PAGE = 'uiIndustry';
export const RXSTATE_CUSTOMERS = 'customers';
export const RXSTATE_CUSTOMER_PAGE = 'uiCustomer';
export const RXSTATE_ACTIVITIES = 'activities';
export const RXSTATE_ACTIVITY_PAGE = 'uiActivity';
export const RXSTATE_ACTIVITY_TYPES = 'activityTypes';
export const RXSTATE_ACTIVITY_TYPE_PAGE = 'uiActivityType';

export const INITIAL_ORDER_BY_INDUSTRIES = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_CUSTOMERS = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_ACTIVITIES = `startTime ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_ACTIVITY_TYPES = `name ${ORDER_SORT_ASCENDING}`;

export const MENUID_ACTIVITY_MANAGEMENT = 'MENUID_ACTIVITY_MANAGEMENT';
export const MENUID_ACTIVITY_MANAGEMENT_INDUSTRY = 'MENUID_ACTIVITY_MANAGEMENT_INDUSTRY';
export const MENUID_ACTIVITY_MANAGEMENT_CUSTOMER = 'MENUID_ACTIVITY_MANAGEMENT_CUSTOMER';
export const MENUID_ACTIVITY_MANAGEMENT_ACTIVITY = 'MENUID_ACTIVITY_MANAGEMENT_ACTIVITY';
export const MENUID_ACTIVITY_MANAGEMENT_ACTIVITY_TYPE = 'MENUID_ACTIVITY_MANAGEMENT_ACTIVITY_TYPE';
