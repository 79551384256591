import { change } from 'redux-form';
import {
  PAGE_MODE_TABLE, RXFIELD_SESSION_MEDIA, RXFIELD_SESSION_SPEAKERS, RXFORM_SESSION, approval,
} from '../../../constant';
import { deleteSession, downloadSession } from '../../../helper';
import {
  addSession, clearSessions, downloadingDeletingSession,
  setAlertErrorMessage, setFunctionalPageMode,
  setSessionTappedId,
  setSessionTableDataSpeakers,
  addingSessionSpeakers,
  setSessionSelectedEnableComment,
  setSessionSelectedSurveyForm,
  setSessionSelectedType,
  clearSessionRooms,
} from '../simple-action';
import downloadSessionsAsync from './downloadSessionsAsync';
import { downloadForm } from '../../../../dform/helper';
import { getDeepLinkScheme } from '../../../../configuration/helper';
import downloadSessionRoomsAsync from './downloadSessionRoomsAsync';

const transformSpeakers = (data) => (data.length > 0 ? data.map((x) => (x.speaker.id
  ? { label: x.speaker.name, value: x.speaker.id } : x.speaker)) : []);

const DEEP_LINK_PATH = 'app/session/detail/';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingSession(true));
    dispatch(addingSessionSpeakers(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSession;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteSession(tappedId, token);
      dispatch(setSessionTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearSessions());
      dispatch(downloadSessionsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const session = await downloadSession(tappedId, token);
      const result = await getDeepLinkScheme(session, DEEP_LINK_PATH, tappedId, token);

      dispatch(setSessionSelectedEnableComment(session.enableComment ? approval[0] : approval[1]));
      dispatch(addSession(result));
      dispatch(setSessionTableDataSpeakers(transformSpeakers(session.speakers)));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_MEDIA, session.media));
      dispatch(
        change(RXFORM_SESSION, RXFIELD_SESSION_SPEAKERS, transformSpeakers(session.speakers)),
      );
      dispatch(setSessionSelectedType(session.type));
      if (session.surveyFormId) {
        const form = await downloadForm(session.surveyFormId, token);
        dispatch(setSessionSelectedSurveyForm({ label: form.name, value: form.id }));
      }
      if (session.event?.id) {
        dispatch(clearSessionRooms());
        dispatch(downloadSessionRoomsAsync(session.event?.id))
          .catch((error) => dispatch(setAlertErrorMessage(error)));
      }
    }
  } finally {
    dispatch(downloadingDeletingSession(false));
    dispatch(addingSessionSpeakers(false));
  }
};
