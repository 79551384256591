import { connect } from 'react-redux';
import {
  change, formValueSelector, reset,
} from 'redux-form';
import moment from 'moment';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, EVENT_TYPE_SIMPLE, EVENT_QR_CODE_PATH,
  INITIAL_ORDER_BY_EVENTS, MENUID_EVENT_EVENT, PAGE_MODE_TABLE, REWARDS_TYPE,
  RXFIELD_EVENT_ADDITTIONAL_ITEMS,
  RXFIELD_EVENT_CAN_PAY_USING_POINT,
  RXFIELD_EVENT_CATEGORY, RXFIELD_EVENT_CLOCK_IN_MUST_INSIDE_AREA,
  RXFIELD_EVENT_COMMENT_ACCESS, RXFIELD_EVENT_DESCRIPTION,
  RXFIELD_EVENT_ENABLE_COMMENT,
  RXFIELD_EVENT_EVENT_TYPE, RXFIELD_EVENT_FEATURED, RXFIELD_EVENT_FEATURED_ORDER,
  RXFIELD_EVENT_LOCATION, RXFIELD_EVENT_MEDIA, RXFIELD_EVENT_NEEDS_APPROVAL,
  RXFIELD_EVENT_REGISTRATION_REWARD_POINT,
  RXFIELD_EVENT_REGISTRATION_REWARD_TYPE, RXFIELD_EVENT_REGISTRATION_REWARD_VOUCHER,
  RXFIELD_EVENT_SPEAKERS,
  RXFIELD_EVENT_SURVEY_FORM,
  RXFIELD_EVENT_VIISIBILITY_STATUS,
  RXFIELD_EVENT_WHO_CAN_REGISTER, RXFORM_EVENT, approval, PAGE_MODE_VIEW, EVENT_TYPE_REGULAR,
  RXFIELD_EVENT_BANNER, REST_BASE_URL, REST_URL_IMAGE_STORAGE,
} from '../../constant';
import {
  debounceSearch, getLandingPage, isAdditionalItemYoutubeUrlType, localDateToUtc, toMoment,
  transformDropdownData, transformInitialValues,
} from '../../helper';
import {
  addEditEventAsync, addEvent, addingEventSpeakers, clearEventCategories, clearEvents,
  clearLocations, clearSpeakers, downloadDeleteEventAsync, downloadEventCategoriesAsync,
  downloadEventsAsync, downloadLocationsAsync, downloadSpeakersAsync,
  copyingEventTextField, enableDisableEventAsync, setActiveSideMenuItem,
  setAlertConfirmationMessage, setAlertErrorMessage, setEventAddMediaMenuSelected,
  setEventAdvancedFilterDialogSelectedFilterString, setEventCanPayUsingPointSelected,
  setEventCategoryAdvancedFilterDialogSelectedFilterString, setEventCategorySearchText,
  setEventCategorySelected, setEventClockInAreaSelected, setEventEventTypeSelected,
  setEventFeatureSelected, setEventLocationSelected, setEventNeedApprovalSelected,
  setEventRewardTypeSelected, setEventSearchText, setEventSelectedEnableComment,
  setEventSelectedOrderBy, setEventSelectedPageSize, setEventSelectedSpeakers,
  setEventTableDataSpeakers, setEventTappedId, setEventVisibilityStatusSelected,
  setEventVoucherSelected, setEventWhoCanRegisterSelected,
  setLocationAdvancedFilterDialogSelectedFilterString, setLocationSearchText,
  setSpeakerAdvancedFilterDialogSelectedFilterString, setSpeakerSearchText,
  setEventSelectedSurveyForm, sendEventNotificationAsync, setEventBannerImage,
} from '../../redux/action';
import GlobalLocalizedString from '../../../../localization';
import EventPage from './event.presentation';
import {
  clearVouchers, downloadVouchersAsync, setVoucherAdvancedFilterDialogSelectedFilterString,
  setVoucherSearchText,
} from '../../../voucher/redux/action';
import {
  clearForms, downloadFormsAsync,
  setFormAdvancedFilterDialogSelectedFilterString, setFormSearchText,
} from '../../../dform/redux/action';

const transformSpeakers = (data) => (data.length > 0 ? data.map((x) => (x.id
  ? { label: x.name, value: x.id } : x)) : []);

const getInitialValues = (state) => {
  const {
    events, uiEvent, uiFunctionalPage, forms,
  } = state;
  const {
    downloadingDeleting, tappedId, tableDataSpeakers, selectedForm,
  } = uiEvent;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? events.data[tappedId] : {};

  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      {
        enableComment: found.enableComment ? approval[0] : approval[1],
        canPayUsingPoint: found?.canPayUsingPoint
          ? { label: GlobalLocalizedString.common.labelYes, value: true }
          : { label: GlobalLocalizedString.common.labelNo, value: false },
        category: found?.category ? { label: found.category.name, value: found.category.id } : '',
        clockInMustBeInsideArea: found?.clockInMustBeInsideArea
          ? { label: GlobalLocalizedString.common.labelYes, value: true }
          : { label: GlobalLocalizedString.common.labelNo, value: false },
        endTime: found?.endTime ? toMoment(found?.endTime, found.location.city.province.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
        isFeatured: found?.isFeatured
          ? { label: GlobalLocalizedString.common.labelYes, value: true }
          : { label: GlobalLocalizedString.common.labelNo, value: false },
        location: found?.location ? { label: found.location.name, value: found.location.id } : '',
        needApproval: found?.needApproval
          ? { label: GlobalLocalizedString.common.labelYes, value: true }
          : { label: GlobalLocalizedString.common.labelNo, value: false },
        startTime: found?.startTime ? toMoment(found?.startTime, found.location.city.province.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
        featuredOrder: found?.featuredOrder,
        registrationRewardVoucherIds: Object.values(found?.registrationRewardVouchers)
          .map((item) => ({
            label: item.voucher.voucherName,
            value: item.voucher.id,
          })),
        speakers: tableDataSpeakers?.length > 0 ? transformSpeakers(tableDataSpeakers) : [],
        surveyForm: found.surveyFormId && forms ? selectedForm : {
          label: '',
          value: '',
        },
        additionalItems: found.additionalItems ? found.additionalItems.map((x) => ({
          ...x,
          url: isAdditionalItemYoutubeUrlType(x) ? x.path : undefined,
        })) : [],
        clockInQrCode: found.randomId ? `${EVENT_QR_CODE_PATH}${found.randomId}` : null,
        banner: found.banner ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.banner}` : null,
        landingPage: getLandingPage(found),
      })
    : {
      banner: null,
      enableComment: approval[1],
      canPayUsingPoint: '',
      category: '',
      clockInMustBeInsideArea: '',
      description: '',
      earliestClockInBeforeMinutes: 0,
      endTime: '',
      eventType: '',
      featuredOrder: 0,
      isFeatured: '',
      location: '',
      maxClockInMinutes: 0,
      media: [],
      name: '',
      needApproval: '',
      normalPrice: 0,
      price: 0,
      quota: 0,
      registrationRewardPoint: 0,
      registrationRewardType: '',
      registrationRewardVoucherIds: [],
      shortDescription: '',
      startTime: '',
      visibilityStatus: '',
      whoCanRegister: '',
      speakers: tableDataSpeakers?.length > 0 ? tableDataSpeakers : [],
      surveyForm: {
        label: '',
        value: '',
      },
    };
  return initVal;
};

const getDiscusId = (state) => {
  const { events, uiEvent, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiEvent;
  const { pageMode } = uiFunctionalPage;

  return pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? events.data[tappedId].discussionId : '';
};

const selector = formValueSelector(RXFORM_EVENT);

const mapStateToProps = (state) => ({
  addingEditing: state.uiEvent.addingEditing,
  downloading: state.uiEvent.downloading,
  downloadingDeleting: state.uiEvent.downloadingDeleting,
  events: state.events.data,
  pageMode: state.uiFunctionalPage.pageMode,
  initialValues: getInitialValues(state),
  vouchers: Object.values(state.vouchers.data)
    .map((item) => ({ label: item.title, value: item.id })),
  downloadingVoucher: state.uiVoucher.downloading,
  categories: transformDropdownData(state.eventCategories.data),
  downloadingCategory: state.uiEventCategory.downloading,
  locations: transformDropdownData(state.locations.data),
  downloadingLocation: state.uiLocation.downloading,
  selectedAddMediaMenu: state.uiEvent.selectedAddMediaMenu,
  formSelectedIsFeatured: selector(state, RXFIELD_EVENT_FEATURED) || '',
  formSelectedRegistrationRewardType: selector(state, RXFIELD_EVENT_REGISTRATION_REWARD_TYPE) || '',
  formRegistrationRewardVoucher: selector(state, RXFIELD_EVENT_REGISTRATION_REWARD_VOUCHER) || [],
  selectedEnableComment: state.uiEvent.selectedEnableComment,
  tappedId: state.uiFunctionalPage.tappedId || '',
  currentEventStatus: state.events.data[state.uiFunctionalPage.tappedId]
    ? state.events.data[state.uiFunctionalPage.tappedId].status : '',
  enablingDisabling: state.uiEvent.enablingDisabling,
  speakers: transformDropdownData(state.speakers.data),
  loadingSpeakers: state.uiSpeaker.downloading,
  tableDataSpeakers: state.uiEvent.tableDataSpeakers,
  selectedSpeakers: state.uiEvent.selectedSpeakers,
  addingEventSpeaker: state.uiEvent.addingEventSpeaker,
  tappedEvent: state.events.data[state.uiEvent.tappedId],
  surveyForms: transformDropdownData(state.forms.data),
  downloadingSurveyForms: state.uiForm.downloading,
  copying: state.uiEvent.copying,
  sendingNotification: state.uiEvent.sendingNotification,
  sendingNotifications: state.notification.sendingNotifications,
  typeValues: selector(state, RXFIELD_EVENT_EVENT_TYPE),
  discusId: getDiscusId(state),
});

const validFrom = localDateToUtc(moment().format());
const validUntil = localDateToUtc(moment().format());

const searchCategoryDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearEventCategories());
    dispatch(downloadEventCategoriesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
);

const searchLocationDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearLocations());
    dispatch(downloadLocationsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
);

const searchVoucherDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
);

const searchSpeakerDebouce = debounceSearch(
  (dispatch) => {
    dispatch(clearSpeakers());
    dispatch(downloadSpeakersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
);

const searchSurveyFormDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearForms());
    dispatch(downloadFormsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAppear: async () => {
    dispatch(setEventAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_EVENT_EVENT));
    dispatch(setEventSearchText(''));
    dispatch(clearEvents());
    dispatch(setEventSelectedPageSize(20));
    dispatch(setEventSelectedOrderBy(INITIAL_ORDER_BY_EVENTS));
    dispatch(downloadEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAddAdditionalItemsPressed: (data) => {
    const result = data.map((x) => ({
      ...x,
      content: !isAdditionalItemYoutubeUrlType(x) ? x?.content : undefined,
      url: isAdditionalItemYoutubeUrlType(x) ? x?.url : undefined,
    }));
    dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_ADDITTIONAL_ITEMS, result));
  },
  onAddMediaMenuSelected: (menu) => {
    dispatch(setEventAddMediaMenuSelected(menu));
  },
  onAddMediaPressed: (data) => {
    dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_MEDIA, data));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setEventCategoryAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setEventCategorySearchText(''));
    dispatch(clearEventCategories());
    dispatch(downloadEventCategoriesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setLocationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setLocationSearchText(''));
    dispatch(clearLocations());
    dispatch(downloadLocationsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setFormAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setFormSearchText(''));
    dispatch(clearForms());
    dispatch(downloadFormsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace(/category/, 'category.id')
      .replace(/location/, 'location.id');
    dispatch(setEventAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onBannerImageSelected: (image, fileName, url) => {
    dispatch(setEventBannerImage(url));
    dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_BANNER, image));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_EVENT));
    dispatch(setEventCanPayUsingPointSelected(false));
    dispatch(setEventCategorySelected(''));
    dispatch(setEventClockInAreaSelected(false));
    dispatch(setEventEventTypeSelected(''));
    dispatch(setEventFeatureSelected(false));
    dispatch(setEventLocationSelected(''));
    dispatch(setEventNeedApprovalSelected(false));
    dispatch(setEventRewardTypeSelected(''));
    dispatch(setEventVisibilityStatusSelected(''));
    dispatch(setEventVoucherSelected([]));
    dispatch(setEventWhoCanRegisterSelected(''));
    dispatch(setEventSelectedEnableComment(''));
    dispatch(setEventTableDataSpeakers([]));
    dispatch(setEventSelectedSpeakers(null));
    dispatch(setEventSelectedSurveyForm(null));
    dispatch(setEventBannerImage(''));
  },
  onChangeCategoryText: async (text, changeAdvanceFilter = false) => {
    dispatch(setEventCategoryAdvancedFilterDialogSelectedFilterString(
      changeAdvanceFilter ? '' : 'status=Enabled',
    ));
    dispatch(setEventCategorySearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchCategoryDebounce(dispatch);
    }
  },
  onChangeDescriptionText: (text) => {
    dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_DESCRIPTION, text));
  },
  onChangeLocationText: async (text, changeAdvanceFilter = false) => {
    dispatch(setLocationAdvancedFilterDialogSelectedFilterString(
      changeAdvanceFilter ? '' : 'status=Enabled',
    ));
    dispatch(setLocationSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchLocationDebounce(dispatch);
    }
  },
  onChangeRewardVoucherText: (text) => {
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString(`validFrom<=${validFrom}|validUntil>=${validUntil}|status=Enabled`));
    dispatch(setVoucherSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchVoucherDebounce(dispatch);
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadEventsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setEventSelectedPageSize(pageSize));
    dispatch(downloadEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCommentAccessOptionSelected: (option) => {
    if (option) {
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_COMMENT_ACCESS, option));
    } else {
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_COMMENT_ACCESS, ''));
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setEventTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onCopyPressed: (status) => {
    dispatch(copyingEventTextField(!status));
  },
  onCreatePressed: () => {
    dispatch(setEventTappedId(''));
    dispatch(setEventSelectedEnableComment(approval[1]));
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString(`validFrom<=${validFrom}|validUntil>=${validUntil}|status=Enabled`));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setEventCategoryAdvancedFilterDialogSelectedFilterString('status=Enabled'));
    dispatch(setEventCategorySearchText(''));
    dispatch(clearEventCategories());
    dispatch(downloadEventCategoriesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setLocationAdvancedFilterDialogSelectedFilterString('status=Enabled'));
    dispatch(setLocationSearchText(''));
    dispatch(clearLocations());
    dispatch(downloadLocationsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setEventTableDataSpeakers([]));
    dispatch(setEventSelectedSpeakers(null));
    dispatch(setSpeakerAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setSpeakerSearchText(''));
    dispatch(clearSpeakers());
    dispatch(downloadSpeakersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setEventSelectedSurveyForm(null));
    dispatch(setFormAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setFormSearchText(''));
    dispatch(clearForms());
    dispatch(downloadFormsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onDeleteAdditionalItemsPressed: (data) => {
    dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_ADDITTIONAL_ITEMS, data));
  },
  onDeleteMediaPressed: (data) => {
    dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_MEDIA, data));
  },
  onDeletePressed: (id) => {
    dispatch(setEventTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: async (id, events) => {
    dispatch(setEventTappedId(id));
    dispatch(downloadDeleteEventAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
    dispatch(setFormAdvancedFilterDialogSelectedFilterString(''));

    const selectedEvent = events[id];
    if (selectedEvent.eventType === EVENT_TYPE_SIMPLE) {
      dispatch(setFormAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setFormSearchText(''));
      dispatch(clearForms());
      dispatch(downloadFormsAsync(1))
        .catch((error) => dispatch(setAlertErrorMessage(error)));
    } else {
      dispatch(setEventSelectedSurveyForm(null));
    }

    dispatch(setEventCategoryAdvancedFilterDialogSelectedFilterString('status=Enabled'));
    dispatch(setEventCategorySearchText(''));
    dispatch(clearEventCategories());
    dispatch(downloadEventCategoriesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setLocationAdvancedFilterDialogSelectedFilterString('status=Enabled'));
    dispatch(setLocationSearchText(''));
    dispatch(clearLocations());
    dispatch(downloadLocationsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString(`validFrom<=${validFrom}|validUntil>=${validUntil}|status=Enabled`));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setEventSelectedSpeakers(null));
    dispatch(setSpeakerAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setSpeakerSearchText(''));
    dispatch(clearSpeakers());
    dispatch(downloadSpeakersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onEnableCommentOptionSelected: (option) => {
    if (option) {
      dispatch(setEventSelectedEnableComment(option));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_ENABLE_COMMENT, option));
    } else {
      dispatch(setEventSelectedEnableComment(''));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_ENABLE_COMMENT, ''));
    }
  },
  onCanPayUsingPointOptionSelected: (option) => {
    if (option) {
      dispatch(setEventCanPayUsingPointSelected(option.value));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_CAN_PAY_USING_POINT, option));
    } else {
      dispatch(setEventCanPayUsingPointSelected(false));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_CAN_PAY_USING_POINT, false));
    }
  },
  onCategoryOptionSelected: (option) => {
    if (option) {
      dispatch(setEventCategorySelected(option.value));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_CATEGORY, option));
    } else {
      dispatch(setEventCategorySelected(''));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_CATEGORY, ''));
    }
  },
  onClockInAreaOptionSelected: (option) => {
    if (option) {
      dispatch(setEventClockInAreaSelected(option.value));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_CLOCK_IN_MUST_INSIDE_AREA, option));
    } else {
      dispatch(setEventClockInAreaSelected(false));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_CLOCK_IN_MUST_INSIDE_AREA, false));
    }
  },
  onEventTypeOptionSelected: (option) => {
    if (option) {
      if (option === EVENT_TYPE_SIMPLE) {
        dispatch(setEventTableDataSpeakers([]));
        dispatch(setFormAdvancedFilterDialogSelectedFilterString(''));
        dispatch(setFormSearchText(''));
        dispatch(clearForms());
        dispatch(downloadFormsAsync(1))
          .catch((error) => dispatch(setAlertErrorMessage(error)));
      }
      dispatch(setEventSelectedSurveyForm(null));
      dispatch(setEventSelectedSpeakers(null));

      dispatch(setEventEventTypeSelected(option));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_EVENT_TYPE, option));
    } else {
      dispatch(setEventEventTypeSelected(''));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_EVENT_TYPE, ''));
    }
  },
  onFeaturOptionSelected: (option) => {
    if (option) {
      dispatch(setEventFeatureSelected(option.value));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_FEATURED, option));
      if (option.value === false) {
        dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_FEATURED_ORDER, 0));
      }
      dispatch(change);
    } else {
      dispatch(setEventFeatureSelected(false));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_FEATURED, false));
    }
  },
  onLocationOptionSelected: (option) => {
    if (option) {
      dispatch(setEventLocationSelected(option.value));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_LOCATION, option));
    } else {
      dispatch(setEventLocationSelected(''));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_LOCATION, ''));
    }
  },
  onNeedApprovalOptionSelected: (option) => {
    if (option) {
      dispatch(setEventNeedApprovalSelected(option));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_NEEDS_APPROVAL, option));
    } else {
      dispatch(setEventNeedApprovalSelected(false));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_NEEDS_APPROVAL, false));
    }
  },
  onRewardTypeOptionSelected: (option) => {
    if (option) {
      dispatch(setEventRewardTypeSelected(option));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_REGISTRATION_REWARD_TYPE, option));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_REGISTRATION_REWARD_POINT, 0));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_REGISTRATION_REWARD_VOUCHER, []));
      dispatch(setEventVoucherSelected([]));

      if (option === REWARDS_TYPE[1] || option === REWARDS_TYPE[2]) {
        dispatch(setVoucherAdvancedFilterDialogSelectedFilterString(`validFrom<=${validFrom}|validUntil>=${validUntil}|status=Enabled`));
        dispatch(setVoucherSearchText(''));
        dispatch(clearVouchers());
        dispatch(downloadVouchersAsync(1))
          .catch((error) => dispatch(setAlertErrorMessage(error)));
      }
    } else {
      dispatch(setEventRewardTypeSelected(''));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_REGISTRATION_REWARD_TYPE, ''));
    }
  },
  onVisibilityStatusOptionSelected: (option) => {
    if (option) {
      dispatch(setEventVisibilityStatusSelected(option));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_VIISIBILITY_STATUS, option));
    } else {
      dispatch(setEventVisibilityStatusSelected(''));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_VIISIBILITY_STATUS, ''));
    }
  },
  onVoucherOptionSelected: (option) => {
    if (option) {
      dispatch(setEventVoucherSelected(option));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_REGISTRATION_REWARD_VOUCHER, option));
    } else {
      dispatch(setEventVoucherSelected([]));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_REGISTRATION_REWARD_VOUCHER, []));
    }
  },
  onWhoCanRegisterOptionSelected: (option) => {
    if (option) {
      dispatch(setEventWhoCanRegisterSelected(option));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_WHO_CAN_REGISTER, option));
    } else {
      dispatch(setEventWhoCanRegisterSelected(''));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_WHO_CAN_REGISTER, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setEventSelectedPageSize(pageSize));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setEventAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearEvents());
    dispatch(setEventSelectedOrderBy(INITIAL_ORDER_BY_EVENTS));
    dispatch(downloadEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    name, shortDescription, description, startTime,
    endTime, earliestClockInBeforeMinutes, maxClockInMinutes, category, location,
    clockInMustBeInsideArea, eventType, visibilityStatus, whoCanRegister, needApproval,
    quota, isFeatured, featuredOrder, normalPrice, price, canPayUsingPoint, registrationRewardType,
    registrationRewardPoint, registrationRewardVoucherIds, media, commentAccess, additionalItems,
    speakers, surveyForm, banner,
  }) => {
    try {
      await dispatch(addEditEventAsync(name,
        shortDescription,
        description,
        startTime,
        endTime,
        earliestClockInBeforeMinutes,
        maxClockInMinutes,
        category.value,
        location.value,
        clockInMustBeInsideArea.value,
        eventType,
        visibilityStatus,
        whoCanRegister,
        needApproval.value,
        quota,
        isFeatured.value,
        !isFeatured.value ? 0 : featuredOrder,
        normalPrice,
        price,
        canPayUsingPoint.value,
        registrationRewardType,
        registrationRewardPoint,
        registrationRewardVoucherIds,
        media,
        commentAccess,
        additionalItems,
        speakers,
        surveyForm,
        banner));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setEventSearchText(text));
      dispatch(clearEvents());
      await dispatch(downloadEventsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setEventSelectedOrderBy(orderBy));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    name,
    shortDescription,
    description,
    startTime,
    endTime,
    earliestClockInBeforeMinutes,
    maxClockInMinutes,
    category,
    location,
    clockInMustBeInsideArea,
    eventType,
    visibilityStatus,
    whoCanRegister,
    needApproval,
    quota,
    isFeatured,
    featuredOrder,
    normalPrice,
    price,
    canPayUsingPoint,
    registrationRewardType,
    registrationRewardPoint,
    registrationRewardVoucherIds,
    media,
    commentAccess,
    additionalItems,
    speakers,
    surveyForm,
    banner,
  }) => {
    await dispatch(addEditEventAsync(name,
      shortDescription,
      description,
      startTime,
      endTime,
      earliestClockInBeforeMinutes,
      maxClockInMinutes,
      category.value,
      location.value,
      clockInMustBeInsideArea.value,
      eventType,
      visibilityStatus,
      whoCanRegister,
      needApproval.value,
      quota,
      isFeatured.value,
      !isFeatured.value ? 0 : featuredOrder,
      normalPrice,
      price,
      canPayUsingPoint.value,
      registrationRewardType,
      registrationRewardPoint,
      registrationRewardVoucherIds,
      media,
      commentAccess,
      additionalItems,
      speakers,
      surveyForm,
      banner));
  },
  onViewPressed: (id, events) => {
    dispatch(setEventTappedId(id));
    dispatch(downloadDeleteEventAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
    dispatch(setFormAdvancedFilterDialogSelectedFilterString(''));

    const selectedEvent = events[id];
    if (selectedEvent.eventType === EVENT_TYPE_REGULAR) {
      dispatch(setEventSelectedSurveyForm(null));
    }
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(downloadDeleteEventAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
        break;
      default:
        dispatch(enableDisableEventAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onAddSpeakersPressed: async (defaultValue, newInitialValue, selectedOption, currentData) => {
    if (selectedOption) {
      try {
        dispatch(addingEventSpeakers(true));
        if (Object.prototype.hasOwnProperty.call(currentData, 'id')) {
          const result = {
            ...currentData,
            speakers: defaultValue.length > 0
              ? [...defaultValue, selectedOption] : [selectedOption],
          };
          await dispatch(addEvent(result));
          dispatch(setEventTableDataSpeakers(result.speakers));
          dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_SPEAKERS,
            result.speakers));
        } else {
          const newData = newInitialValue.length > 0
            ? [...newInitialValue.filter((x) => (x.value !== selectedOption.value)), selectedOption]
            : [selectedOption];
          await dispatch(setEventTableDataSpeakers(newData));
          dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_SPEAKERS, newData));
        }
        dispatch(setSpeakerAdvancedFilterDialogSelectedFilterString('status=enabled'));
        dispatch(setSpeakerSearchText(''));
        dispatch(clearSpeakers());
        dispatch(downloadSpeakersAsync(1))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
      } finally {
        dispatch(setEventSelectedSpeakers(null));
        dispatch(addingEventSpeakers(false));
      }
    }
  },
  onChangeSpeakersText: async (text) => {
    dispatch(setSpeakerAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setSpeakerSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchSpeakerDebouce(dispatch);
    }
  },
  onDeleteSpeakersPressed: async (newValue, currentData) => {
    if (Object.prototype.hasOwnProperty.call(currentData, 'id')) {
      const result = { ...currentData, speakers: newValue };
      await dispatch(addEvent(result));
      dispatch(setEventTableDataSpeakers(result.speakers));
    } else {
      dispatch(setEventTableDataSpeakers(newValue));
    }
    dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_SPEAKERS, newValue));
  },
  onSpeakersOptionSelected: (option) => {
    if (option) {
      dispatch(setEventSelectedSpeakers(option));
    } else {
      dispatch(setEventSelectedSpeakers(null));
    }
  },
  onChangeSurveyFormText: (text, pageMode) => {
    if (pageMode === PAGE_MODE_VIEW) return;
    dispatch(setFormAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setFormSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchSurveyFormDebounce(dispatch);
    }
  },
  onSurveyFormOptionSelected: (option) => {
    if (option) {
      dispatch(setEventSelectedSurveyForm(option));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_SURVEY_FORM, option));
    } else {
      dispatch(setEventSelectedSurveyForm(''));
      dispatch(change(RXFORM_EVENT, RXFIELD_EVENT_SURVEY_FORM, ''));
    }
  },
  onSendToMePressed: () => {
    dispatch(sendEventNotificationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EventPage);
