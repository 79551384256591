import {
  TEXT_FORMAT_HTML, REST_URL_VOUCHER_CATEGORIES, REST_URL_ADD_EDIT_VOUCHER_CATEGORY,
  REST_URL_VIEW_DELETE_VOUCHER_CATEGORY,
  REST_URL_VOUCHERS, REST_URL_ADD_EDIT_VOUCHER, REST_URL_ENABLE_DISABLE_VOUCHER,
  REST_URL_VIEW_DELETE_VOUCHER,
  REST_URL_TOKEN_REQUESTS, REST_URL_VIEW_TOKEN_REQUEST, REST_URL_ADD_TOKEN_REQUEST,
  REST_URL_CANCEL_TOKEN_REQUEST,
  REST_URL_TOKEN_IMPORTS, REST_URL_VIEW_DELETE_TOKEN_IMPORT, REST_URL_IMPORT_EDIT_TOKEN_IMPORT,
  REST_URL_RETRY_IMPORT_TOKEN, REST_URL_SAVE_TOKEN_IMPORT,
  REST_URL_VOUCHER_TOKENS, REST_URL_VIEW_VOUCHER_TOKEN,
  REST_URL_VOUCHER_PURCHASE_REQUESTS, REST_URL_VIEW_VOUCHER_PURCHASE_REQUEST,
  REST_URL_PROMOTED_MERCHANTS, REST_URL_VIEW_PROMOTED_MERCHANT,
  REST_URL_EDIT_PROMOTED_MERCHANT, REST_URL_REVOKE_VOUCHER_TOKEN,
  REST_URL_SAVE_FILE,
  REST_URL_ADD_EDIT_VOUCHER_TOKEN_ASSIGNMENT, REST_URL_VOUCHER_TOKEN_ASSIGNMENTS,
  REST_URL_VIEW_VOUCHER_TOKEN_ASSIGNMENT, REST_BASE_URL, REST_URL_IMAGE_STORAGE,
  ASSIGNMENT_METHOD_URL_ACCESS, REST_URL_VOUCHER_TOKEN_ASSIGNMENT_STATUS,
  REST_URL_GIFT_VOUCHER_REDEMPTIONS, REST_URL_VIEW_GIFT_VOUCHER_REDEMPTION,
  REST_URL_VOUCHER_USAGES,
} from './constant';
import {
  buildQueryParams, convertArrToObj, sendDeleteRequest, sendGetRequest, sendPostRequest,
  sendPutRequest, transformQueryResult, localDateToUtc, sendGetFileRequest,
} from '../../helper';

export * from '../../helper';

export const downloadVoucherCategories = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_VOUCHER_CATEGORIES.concat(filterString), pageNumber, pageSize,
      orderBy, searchText)
    : buildQueryParams(REST_URL_VOUCHER_CATEGORIES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addVoucherCategory = async (name, description, image, token) => {
  const body = { name, description, image };
  await sendPostRequest(REST_URL_ADD_EDIT_VOUCHER_CATEGORY, body, token);
};

export const editVoucherCategory = async (id, name, description, image, token) => {
  const body = {
    id, name, description, image,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_VOUCHER_CATEGORY, body, token);
};

export const downloadVoucherCategory = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_VOUCHER_CATEGORY.replace(/\{id\}/, id), token);
  return response;
};

export const deleteVoucherCategory = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_VOUCHER_CATEGORY.replace(/\{id\}/, id), token);
};

export const downloadVoucherUsages = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_VOUCHER_USAGES.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_VOUCHER_USAGES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const saveVoucherUsages = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_VOUCHER_USAGES.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadVouchers = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_VOUCHERS.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_VOUCHERS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addVoucher = async (title, code, type, point, maxPurchasePerUser,
  minAchievementPoinToPurchase, maxAchievementPoinToPurchase, actionType, visibility, categoryId,
  merchantId, applicableAreaMode, applicableBrancheIds, validFrom, validUntil, image, description,
  termsAndConditions, status, isPromoted, order, tags, manualClaimMessage, monetaryValue,
  token) => {
  const body = {
    title,
    code,
    type,
    point,
    maxPurchasePerUser,
    minAchievementPoinToPurchase,
    maxAchievementPoinToPurchase,
    actionType,
    visibility,
    categoryId,
    merchantId,
    applicableAreaMode,
    applicableBrancheIds,
    validFrom,
    validUntil,
    image,
    description,
    descriptionFormat: TEXT_FORMAT_HTML,
    termsAndConditions,
    termsAndConditionsFormat: TEXT_FORMAT_HTML,
    status,
    isPromoted,
    order,
    tags,
    manualClaimMessage,
    monetaryValue,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_VOUCHER, body, token);
};

export const editVoucher = async (id, title, code, type, point, maxPurchasePerUser,
  minAchievementPoinToPurchase, maxAchievementPoinToPurchase, actionType, visibility, categoryId,
  merchantId, applicableAreaMode, applicableBrancheIds, validFrom, validUntil, image, clearImage,
  description, termsAndConditions, status, isPromoted, order, tags, manualClaimMessage,
  monetaryValue, token) => {
  const body = {
    id,
    title,
    code,
    type,
    point,
    maxPurchasePerUser,
    minAchievementPoinToPurchase,
    maxAchievementPoinToPurchase,
    actionType,
    visibility,
    categoryId,
    merchantId,
    applicableAreaMode,
    applicableBrancheIds,
    validFrom,
    validUntil,
    image,
    clearImage,
    description,
    descriptionFormat: TEXT_FORMAT_HTML,
    termsAndConditions,
    termsAndConditionsFormat: TEXT_FORMAT_HTML,
    status,
    isPromoted,
    order,
    tags,
    manualClaimMessage,
    monetaryValue,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_VOUCHER, body, token);
};

export const downloadVoucher = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_VOUCHER.replace(/\{id\}/, id),
    token);
  return response;
};

export const enableDisableVoucher = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_VOUCHER, body, token);
};

export const saveVouchers = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_VOUCHERS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const transformApplicableBranches = (data) => (data.length > 0 ? data.map((x) => (x.id
  ? { label: x.name, value: x.id } : x)) : []);

export const downloadTokenRequests = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_TOKEN_REQUESTS.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_TOKEN_REQUESTS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadTokenRequest = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_TOKEN_REQUEST.replace(/\{id\}/, id),
    token);
  return response;
};

export const addTokenRequest = async (voucherId, quantity, validFrom, validUntil, token) => {
  const body = {
    voucherId,
    quantity,
    validFrom: localDateToUtc(validFrom),
    validUntil: localDateToUtc(validUntil),
  };
  await sendPostRequest(REST_URL_ADD_TOKEN_REQUEST, body, token);
};

export const cancelTokenRequest = async (id, voucherId, quantity, validFrom, validUntil, token) => {
  const body = {
    voucherId,
    quantity,
    validFrom,
    validUntil,
  };
  const response = await sendPutRequest(REST_URL_CANCEL_TOKEN_REQUEST.replace(/\{id\}/, id), body, token);
  return response;
};

export const downloadVoucherTokens = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_VOUCHER_TOKENS.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_VOUCHER_TOKENS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const dataWithHideContextMenuField = response.data.map((item) => ({
    ...item,
    hideContextMenu: item.isRevoked || item.isClaimed,
  }));
  const newResponse = { ...response, data: dataWithHideContextMenuField };
  return transformQueryResult(newResponse);
};

export const downloadVoucherToken = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_VOUCHER_TOKEN.replace(/\{id\}/, id), token);
  return response;
};

export const revokeVoucherToken = async (id, reason, token) => {
  const body = { id, reason };
  await sendPutRequest(REST_URL_REVOKE_VOUCHER_TOKEN, body, token);
};

export const saveVoucherTokens = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_VOUCHER_TOKENS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadTokenImports = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_TOKEN_IMPORTS.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_TOKEN_IMPORTS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const deleteTokenImports = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_TOKEN_IMPORTS.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_TOKEN_IMPORTS, pageNumber, pageSize, orderBy, searchText);
  await sendDeleteRequest(url, token);
};

export const downloadTokenImport = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_TOKEN_IMPORT.replace(/\{id\}/, id),
    token);
  return response;
};

export const deleteTokenImport = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_TOKEN_IMPORT.replace(/\{id\}/, id), token);
};

export const editTokenImport = async (id, code, validFrom, validUntil, token) => {
  const body = {
    id, code, validFrom, validUntil,
  };
  await sendPutRequest(REST_URL_IMPORT_EDIT_TOKEN_IMPORT, body, token);
};

export const importTokenImport = async (voucherId, csvFile, token) => {
  const body = { voucherId, csvFile };
  await sendPostRequest(REST_URL_IMPORT_EDIT_TOKEN_IMPORT, body, token);
};

export const retryTokenImport = async (id, token) => {
  await sendPutRequest(REST_URL_RETRY_IMPORT_TOKEN.replace(/\{id\}/, id), {}, token);
};

export const saveVoucherTokenImport = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_SAVE_TOKEN_IMPORT.concat(filterString), pageNumber, pageSize,
      orderBy, searchText)
    : buildQueryParams(REST_URL_SAVE_TOKEN_IMPORT, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadVoucherPurchaseRequests = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_VOUCHER_PURCHASE_REQUESTS.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_VOUCHER_PURCHASE_REQUESTS, pageNumber, pageSize, orderBy,
      searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadVoucherPurchaseRequest = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_VOUCHER_PURCHASE_REQUEST.replace(/\{id\}/, id),
    token);
  return response;
};

export const downloadPromotedMerchants = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_PROMOTED_MERCHANTS.concat(filterString), pageNumber, pageSize,
      orderBy, searchText)
    : buildQueryParams(REST_URL_PROMOTED_MERCHANTS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadPromotedMerchant = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_PROMOTED_MERCHANT.replace(/\{id\}/, id), token);
  return response;
};

export const editPromotedMerchant = async (id, isPromoted, order, token) => {
  const body = { id, isPromoted, order };
  await sendPutRequest(REST_URL_EDIT_PROMOTED_MERCHANT, body, token);
};

export const downloadVoucherTokenAssignments = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_VOUCHER_TOKEN_ASSIGNMENTS.concat(filterString),
      pageNumber, pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_VOUCHER_TOKEN_ASSIGNMENTS,
      pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const responseDataWithHideContextMenu = response.data.map((item) => ({
    ...item,
    hideContextMenu: item.assignmentMethod !== ASSIGNMENT_METHOD_URL_ACCESS,
  }));
  return transformQueryResult({ ...response, data: responseDataWithHideContextMenu });
};

export const downloadVoucherTokenAssignment = async (id, token) => {
  const url = buildQueryParams(REST_URL_VIEW_VOUCHER_TOKEN_ASSIGNMENT.replace(/{id}/, id));
  const response = await sendGetRequest(url, token);
  const transformResponse = {
    ...response,
    voucher: {
      ...response.voucher,
      image: response.voucher.image ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${response.voucher.image}` : '',
    },
    hideContextMenu: response.assignmentMethod !== ASSIGNMENT_METHOD_URL_ACCESS,
  };
  return transformResponse;
};

export const addVoucherTokenAssignment = async (
  voucherId,
  code,
  validFrom,
  validUntil,
  codeAssignmentMode,
  bypassPayment,
  bypassMaxPurchaseValidation,
  bypassAchievementValidation,
  userId,
  notes,
  notificationMessageTitle,
  notificationMessageBody,
  assignmentMethod,
  assignmentValidFrom,
  assignmentValidUntil,
  token,
) => {
  const body = {
    voucherId,
    code,
    validFrom,
    validUntil,
    codeAssignmentMode,
    bypassPayment,
    bypassMaxPurchaseValidation,
    bypassAchievementValidation,
    userId,
    notes,
    notificationMessageTitle,
    notificationMessageBody,
    assignmentMethod,
    assignmentValidFrom,
    assignmentValidUntil,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_VOUCHER_TOKEN_ASSIGNMENT, body, token);
};

export const editVoucherTokenAssignment = async (
  code,
  validFrom,
  validUntil,
  codeAssignmentMode,
  bypassPayment,
  bypassMaxPurchaseValidation,
  bypassAchievementValidation,
  notes,
  notificationMessageTitle,
  notificationMessageBody,
  assignmentValidFrom,
  assignmentValidUntil,
  id,
  token,
) => {
  const body = {
    id,
    validFrom,
    validUntil,
    codeAssignmentMode,
    code,
    bypassPayment,
    bypassMaxPurchaseValidation,
    bypassAchievementValidation,
    notes,
    notificationMessageTitle,
    notificationMessageBody,
    assignmentValidFrom,
    assignmentValidUntil,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_VOUCHER_TOKEN_ASSIGNMENT, body, token);
};

export const enabledDisabledVoucherTokenAssignment = async (id, status, token) => {
  const body = { id, status };

  await sendPutRequest(REST_URL_VOUCHER_TOKEN_ASSIGNMENT_STATUS, body, token);
};

export const downloadGiftVoucherRedemptions = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_GIFT_VOUCHER_REDEMPTIONS.concat(encodeURIComponent(filterString)),
      pageNumber, pageSize, encodeURIComponent(orderBy), searchText)
    : buildQueryParams(REST_URL_GIFT_VOUCHER_REDEMPTIONS,
      pageNumber, pageSize, encodeURIComponent(orderBy), searchText);
  const response = await sendGetRequest(url, token);
  return {
    data: convertArrToObj(response.data, 'id'),
    meta: {
      pageSize: response.pageSize || 0,
      currentPage: response.currentPage || 0,
      totalCount: response.totalCount || 0,
      totalPages: response.totalPages || 0,
    },
  };
};

export const downloadGiftVoucherRedemption = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_GIFT_VOUCHER_REDEMPTION.replace(/{id}/, id), token);
  return response;
};
