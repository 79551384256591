import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Grid, makeStyles, Paper, Typography,
} from '@material-ui/core';
import uuid from 'uuid';
import {
  BasePage,
  DynamicForm,
} from '../../component';
import {
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  ENUM_FIELD_TEXT,
  RXFIELD_DFORM_PREVIEW_FORM,
  RXFIELD_DFORM_PREVIEW_REFERENCE_ID,
  RXFIELD_DFORM_PREVIEW_REFERENCE_LABEL,
  RXFORM_PREVIEW,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { rxformValidatePreviewForm } from '../../validation';

const useStyles = makeStyles(() => ({
  title: {
    color: COLOR_PRIMARY,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 24px',
    alignItems: 'center',
    margin: '20px 0px',
  },
  boldText: {
    fontWeight: '500',
    fontSize: 18,
  },
  bodyLoadingContainer: {
    padding: '15px 24px',
    overflowY: 'auto',
    height: 'calc(100vh - 400px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fieldsContainer: {
    padding: '18px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  activityIndicator: {
    color: COLOR_SECONDARY,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: '20px',
  },
  circularProgress: {
    height: '28px !important',
    width: '28px !important',
  },
  formNameFiledContainer: {
    marginLeft: '20px',
    width: '600px',
  },
  divider: {
    border: '.5px solid rgba(0, 0, 0, 0.50)',
    margin: '20px 10px',
  },
}));

const renderDynamicForm = (
  downloadingFormDetail, classes, selectedForm, formId,
  uploadingResponse, onSubmitButtonPressed,
) => {
  if (formId) {
    return (
      <DynamicForm
        formId={formId}
        disabled={uploadingResponse || downloadingFormDetail}
        onSubmit={(values) => onSubmitButtonPressed(values, selectedForm)}
        showSubmitButton={false}
        additionalFields={[
          {
            name: RXFIELD_DFORM_PREVIEW_REFERENCE_ID,
            label: LocalizedString.previewPage.labelReferenceId,
            placeHolder: LocalizedString.previewPage.labelReferenceId,
            required: true,
            component: renderReduxFormOutlinedTextField,
            fieldType: ENUM_FIELD_TEXT,
            defaultValue: uuid().substring(0, 8),
          },
          {
            name: RXFIELD_DFORM_PREVIEW_REFERENCE_LABEL,
            label: LocalizedString.previewPage.labelReferenceLabel,
            placeHolder: LocalizedString.previewPage.labelReferenceLabel,
            required: true,
            component: renderReduxFormOutlinedTextField,
            fieldType: ENUM_FIELD_TEXT,
          },
        ]}
      />
    );
  }
  return null;
};

const PreviewPage = ({
  forms, downloadingForms, selectedForm, downloadingFormDetail,
  uploadingResponse, onAppear, onChangeFormText, onFormOptionSelected,
  onSubmitButtonPressed, formId,
}) => {
  const classes = useStyles();
  useEffect(() => onAppear(), [onAppear]);

  return (
    <BasePage>
      <div className={classes.container}>
        <Typography variant="h4" className={classes.title}>
          {LocalizedString.previewPage.title}
        </Typography>

        <Paper className={classes.headerContainer} elevation={3}>
          <Typography className={classes.boldText}>
            {LocalizedString.previewPage.labelForm}
          </Typography>
          <Grid item className={classes.formNameFiledContainer}>
            <Field
              name={RXFIELD_DFORM_PREVIEW_FORM}
              component={renderReduxFormOutlinedDropdownTextField}
              label={LocalizedString.previewPage.labelForm}
              placeholder={LocalizedString.previewPage.labelForm}
              disabled={downloadingForms || uploadingResponse}
              data={forms}
              loading={downloadingForms}
              onChangeText={onChangeFormText}
              onOptionSelected={onFormOptionSelected}
              onBlur={(e) => e.preventDefault()}
              required
            />
          </Grid>
        </Paper>
        {renderDynamicForm(
          downloadingFormDetail, classes, selectedForm, formId,
          uploadingResponse, onSubmitButtonPressed,
        )}
      </div>
    </BasePage>
  );
};

export default reduxForm({
  form: RXFORM_PREVIEW,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  shouldValidate: () => true,
  validate: rxformValidatePreviewForm,
})(PreviewPage);

PreviewPage.propTypes = {
  forms: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedForm: PropTypes.object,
  downloadingForms: PropTypes.bool.isRequired,
  downloadingFormDetail: PropTypes.bool.isRequired,
  uploadingResponse: PropTypes.bool.isRequired,
  formId: PropTypes.string.isRequired,
  onAppear: PropTypes.func.isRequired,
  onChangeFormText: PropTypes.func.isRequired,
  onFormOptionSelected: PropTypes.func.isRequired,
  onSubmitButtonPressed: PropTypes.func.isRequired,
};

PreviewPage.defaultProps = {
  selectedForm: {},
};
