import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_FORMAT, PROCESSING_STATUSES, RXFIELD_WHATSAPP_MESSAGE,
  RXFIELD_WHATSAPP_NOTES, RXFIELD_WHATSAPP_PROCESSING_STATUS, RXFIELD_WHATSAPP_TO,
  RXFORM_WHATSAPP, RXSTATE_WHATSAPPS, RXSTATE_WHATSAPP_PAGE,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';

const WhatsappPage = ({
  downloading, downloadingDeleting, isResendAllowed, resending,
  handleSubmit, onAppear, onApplyAdvancedFilterPressed, onCancelAdvancedFilterPressed,
  onChangePage, onChangePageSize, onConfirmResendPressed, onRefresh, onResendPressed,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
  tappedId,
}) => (
  <FunctionalPage
    data={RXSTATE_WHATSAPPS}
    uiPage={RXSTATE_WHATSAPP_PAGE}
    moreMenus={[
      {
        caption: LocalizedString.notificationMenu.buttonCaptionResend,
        disabled: downloadingDeleting || resending || !isResendAllowed,
        onPress: () => onResendPressed(tappedId),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.whatsappScreen.labelProcessingStatus,
        field: 'processingStatus',
        type: FILTER_TYPE_DROPDOWN,
        data: PROCESSING_STATUSES,
      },
      {
        title: LocalizedString.whatsappScreen.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_FORMAT,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.whatsappScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.whatsappScreen.labelTo, field: 'to', sorting: !downloading },
      { title: LocalizedString.whatsappScreen.labelMessage, field: 'message', sorting: !downloading },
      { title: LocalizedString.whatsappScreen.labelProcessingStatus, field: 'processingStatus', sorting: !downloading },
      {
        title: LocalizedString.whatsappScreen.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmResendPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.whatsappScreen.title}
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
    usefullWidthDialog
  >
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_WHATSAPP_TO}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.smsScreen.placeholderTo}
            label={LocalizedString.smsScreen.placeholderTo}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WHATSAPP_MESSAGE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.smsScreen.placeholderMessage}
            label={LocalizedString.smsScreen.placeholderMessage}
            disabled
            multiline
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_WHATSAPP_PROCESSING_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.smsScreen.placeholderProcessingStatus}
            label={LocalizedString.smsScreen.placeholderProcessingStatus}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WHATSAPP_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.smsScreen.placeholderNotes}
            label={LocalizedString.smsScreen.placeholderNotes}
            disabled
            multiline
          />
        </Grid>
      </Grid>
    </Grid>
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_WHATSAPP,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(WhatsappPage);

WhatsappPage.propTypes = {
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  isResendAllowed: PropTypes.bool.isRequired,
  resending: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmResendPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onResendPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  tappedId: PropTypes.string.isRequired,
};
