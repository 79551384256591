import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_DAILY_CHECKINS = '/activityreward/api/v1/DailyCheckin?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=profile.fullName&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DAILY_CHECKIN = '/activityreward/api/v1/DailyCheckin/{id}';
export const REST_URL_REGISTRATION_REFERRALS = '/activityreward/api/v1/RegistrationReferral?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_REGISTRATION_REFERRAL = '/activityreward/api/v1/RegistrationReferral/{id}';

export const ROUTE_NAME_ACTIVITY_REWARD = '/activity-reward';
export const ROUTE_NAME_DAILY_CHECKIN = '/daily-checkin';
export const ROUTE_NAME_REGISTRATION_REFERRAL = '/registration-referral';

export const RXFORM_DAILY_CHECKIN = 'dailyCheckinPage';
export const RXFORM_REGISTRATION_REFERRAL = 'registrationReferralPage';

export const RXFIELD_DAILY_CHECKIN_USER_ID = 'profile.id';
export const RXFIELD_DAILY_CHECKIN_USER_NAME = 'profile.fullName';
export const RXFIELD_DAILY_CHECKIN_POINT = 'pointAmount';
export const RXFIELD_REGISTRATION_REFERRAL_REGISTERED_USER = 'registeredUser';
export const RXFIELD_REGISTRATION_REFERRAL_REGISTERED_USER_ID = 'registeredUserId';
export const RXFIELD_REGISTRATION_REFERRAL_REGISTERED_USER_NAME = 'registeredUserName';
export const RXFIELD_REGISTRATION_REFERRAL_POINT = 'pointAmount';
export const RXFIELD_REGISTRATION_REFERRAL_REFERRER = 'referrer';
export const RXFIELD_REGISTRATION_REFERRAL_REFERRER_USER_ID = 'referrerUserId';
export const RXFIELD_REGISTRATION_REFERRAL_REFERRER_USER_NAME = 'referrerUserName';

export const RXSTATE_DAILY_CHECKINS = 'dailyCheckins';
export const RXSTATE_DAILY_CHECKIN_PAGE = 'uiDailyCheckin';
export const RXSTATE_REGISTRATION_REFERRALS = 'registrationReferrals';
export const RXSTATE_REGISTRATION_REFERRAL_PAGE = 'uiRegistrationReferral';

export const INITIAL_ORDER_BY_DAILY_CHECKINS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_REGISTRATION_REFERRALS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_ACTIVITY_REWARD = 'MENUID_ACTIVITY_REWARD';
export const MENUID_ACTIVITY_REWARD_DAILY_CHECKIN = 'MENUID_ACTIVITY_REWARD_DAILY_CHECKIN';
export const MENUID_ACTIVITY_REWARD_REGISTRATION_REFERRAL = 'MENUID_ACTIVITY_REWARD_REGISTRATION_REFERRAL';
