import LocalizedString from '../../../../../localization';
import { INITIAL_ORDER_BY_EVENT_CATEGORIES, STATUS_ENABLED } from '../../../constant';
import { enableDisableEventCategory } from '../../../helper';
import {
  enablingDisablingMultipleEventCategory, setAlertErrorMessage,
  setAlertProcessingMessage,
  setEventCategorySelectedOrderBy, setEventCategorySelectedPageSize,
  setEventCategoryTappedId,
  setProcessingMultipleData,
} from '../simple-action';
import downloadEventCategoriesAsync from './downloadEventCategoriesAsync';

export default (selectedStatus) => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingMultipleEventCategory(true));

    const { selectedRow } = getState().uiFunctionalPage;
    const { token } = getState().authentication;
    const promises = [];
    let errorProcess = 0;

    dispatch(setProcessingMultipleData(true));
    for (let i = 0; i < selectedRow.length; i += 1) {
      let errors = 0;
      const message = selectedStatus === STATUS_ENABLED
        ? LocalizedString.common.msgEnablingData.replace(/\{count\}/, `${i + 1}/${selectedRow.length}`)
        : LocalizedString.common.msgDisablingData.replace(/\{count\}/, `${i + 1}/${selectedRow.length}`);
      dispatch(setAlertProcessingMessage(message));
      dispatch(setEventCategoryTappedId(selectedRow[i].id));
      // eslint-disable-next-line no-await-in-loop
      const enablingDisablingEvent = await enableDisableEventCategory(
        selectedRow[i].id, selectedStatus, token,
      ).catch((err) => {
        dispatch(setAlertErrorMessage(err));
        errors += 1;
      });
      errorProcess += errors;
      promises.push(enablingDisablingEvent);
    }
    dispatch(setProcessingMultipleData(false));

    dispatch(setAlertProcessingMessage(LocalizedString.common.msgDownloadingData));
    dispatch(setEventCategorySelectedPageSize(20));
    dispatch(setEventCategorySelectedOrderBy(INITIAL_ORDER_BY_EVENT_CATEGORIES));
    await dispatch(downloadEventCategoriesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    const messageSuccess = selectedStatus === STATUS_ENABLED
      ? LocalizedString.common.msgSuccessfullyEnablingData.replace(/\{count\}/, (selectedRow.length - errorProcess))
      : LocalizedString.common.msgSuccessfullyDisablingData.replace(/\{count\}/, (selectedRow.length - errorProcess));

    const failedMessage = selectedStatus === STATUS_ENABLED
      ? LocalizedString.common.msgFailedEnablingData
      : LocalizedString.common.msgFailedDisablingData;

    dispatch(setAlertProcessingMessage(
      selectedRow.length === errorProcess
        ? failedMessage : messageSuccess,
    ));
  } finally {
    dispatch(enablingDisablingMultipleEventCategory(false));
  }
};
