import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import LocalizedString from '../../localization';
import SectionTitle from '../section-title';
import CommentBox from './comment-box';
import AccentButton from '../accent-button';
import { COLOR_PRIMARY, REST_BASE_URL_DISCUSSION } from '../../constant';

const useStyles = makeStyles(() => ({
  commentsContainer: {
    margin: '0px 0px 20px 10px',
  },
  moreCommentsButton: {
    flex: 1,
    width: '100%',
    color: COLOR_PRIMARY,
  },
}));

const Comment = ({
  token, discusId, reactionMode,
  getCommentsAsync, getRepliesAsync,
  getReactionsAsync, getUsersProfilePictureAsync, path,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [firstAppear, setFirstAppear] = useState(true);
  const [comments, setComments] = useState([]);
  const [commentsDetail, setCommentsDetail] = useState({});
  const [profilePictures, setProfilePictures] = useState({});

  const getComments = async (currPage) => {
    try {
      setLoading(true);
      const response = await getCommentsAsync(discusId, token, currPage, path);
      const { data, ...details } = response;
      setCommentsDetail(details);
      const commentsTemp = currPage > 0 ? [...comments, ...data] : data || [];
      setComments(commentsTemp || []);
      const responseProfilePictures = await getUsersProfilePictureAsync(commentsTemp, token);
      setProfilePictures(responseProfilePictures);
    } finally {
      setLoading(false);
      setFirstAppear(false);
    }
  };

  if (!loading && comments.length < 1 && firstAppear && discusId) { getComments(0); }

  return (
    <div>
      <SectionTitle title={`${comments.length} ${LocalizedString.common.labelComments}`} />

      {loading && !commentsDetail?.currentPage ? (<CircularProgress color="inherit" />) : (
        <div className={classes.commentsContainer}>
          {comments.length > 0 ? (comments.map((item, index) => (
            <CommentBox
              item={item}
              token={token}
              key={item.id}
              avatar={profilePictures[item.user.id]?.profilePicture || ''}
              reactionMode={reactionMode}
              useDivider={index !== comments.length - 1}
              getRepliesAsync={getRepliesAsync}
              getReactionsAsync={getReactionsAsync}
              getUsersProfilePictureAsync={getUsersProfilePictureAsync}
            />
          ))) : (<Typography>{LocalizedString.common.labelNoComments}</Typography>)}
        </div>
      )}

      {commentsDetail?.hasNext && (
      <AccentButton
        caption={LocalizedString.common.labelMoreComments}
        className={classes.moreCommentsButton}
        variant="outlined"
        loading={loading}
        onClick={() => getComments(commentsDetail.currentPage)}
      />
      )}
    </div>
  );
};

export default Comment;

Comment.propTypes = {
  token: PropTypes.string.isRequired,
  discusId: PropTypes.string.isRequired,
  getCommentsAsync: PropTypes.func.isRequired,
  getRepliesAsync: PropTypes.func.isRequired,
  getReactionsAsync: PropTypes.func.isRequired,
  getUsersProfilePictureAsync: PropTypes.func.isRequired,
  reactionMode: PropTypes.string,
  path: PropTypes.string,
};

Comment.defaultProps = {
  reactionMode: 'none',
  path: REST_BASE_URL_DISCUSSION,
};
