import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_GAMEQR_CATEGORIES, MENUID_GAMEQR_CATEGORY, PAGE_MODE_TABLE,
  RXFIELD_GAMEQR_CATEGORY_IMAGE, RXFORM_GAMEQR_CATEGORY,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE,
} from '../../constant';
import { transformInitialValues } from '../../helper';
import {
  clearGameQRCategories, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setGameQRCategoryAdvancedFilterDialogSelectedFilterString, setGameQRCategorySearchText,
  setGameQRCategorySelectedOrderBy, setGameQRCategorySelectedPageSize, setGameQRCategoryTappedId,
  addEditGameQRCategoryAsync, downloadDeleteGameQRCategoryAsync, downloadGameQRCategoriesAsync,
} from '../../redux/action';
import GameQRCategoryPage from './gameqr-category.presentation';
import GlobalLocalizedString from '../../../../localization';

const getInitialValues = (state) => {
  const {
    gameQRCategories, uiFunctionalPage, uiGameQRCategory,
  } = state;
  const { data } = gameQRCategories;
  const { downloadingDeleting, tappedId } = uiGameQRCategory;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};

  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      image: found.image ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.image}` : null,
    }) : {
      name: '',
      description: '',
      image: null,
    };
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiGameQRCategory.downloading,
  addingEditing: state.uiGameQRCategory.addingEditing,
  downloadingDeleting: state.uiGameQRCategory.downloadingDeleting,
  initialValues: getInitialValues(state),
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiGameQRCategory.tappedId,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_GAMEQR_CATEGORY));
    dispatch(setGameQRCategorySearchText(''));
    dispatch(setGameQRCategoryAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearGameQRCategories());
    dispatch(setGameQRCategorySelectedPageSize(20));
    dispatch(setGameQRCategorySelectedOrderBy(INITIAL_ORDER_BY_GAMEQR_CATEGORIES));
    dispatch(downloadGameQRCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString;
    dispatch(setGameQRCategoryAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearGameQRCategories());
    dispatch(downloadGameQRCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_GAMEQR_CATEGORY));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadGameQRCategoriesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setGameQRCategorySelectedPageSize(pageSize));
    dispatch(downloadGameQRCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteGameQRCategoryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setGameQRCategoryTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setGameQRCategoryTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setGameQRCategoryTappedId(id));
    dispatch(downloadDeleteGameQRCategoryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setGameQRCategorySelectedPageSize(pageSize));
    dispatch(clearGameQRCategories());
    dispatch(downloadGameQRCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearGameQRCategories());
    dispatch(setGameQRCategoryAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setGameQRCategorySelectedOrderBy(INITIAL_ORDER_BY_GAMEQR_CATEGORIES));
    dispatch(downloadGameQRCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ name, description, image }) => {
    try {
      await dispatch(addEditGameQRCategoryAsync(name, description, image));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setGameQRCategorySearchText(text));
      dispatch(clearGameQRCategories());
      await dispatch(downloadGameQRCategoriesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setGameQRCategorySelectedOrderBy(orderBy));
    dispatch(clearGameQRCategories());
    dispatch(downloadGameQRCategoriesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ name, description, image }) => {
    await dispatch(addEditGameQRCategoryAsync(name, description, image));
  },
  onViewPressed: (id) => {
    dispatch(setGameQRCategoryTappedId(id));
    dispatch(downloadDeleteGameQRCategoryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onImageSelected: (image) => {
    dispatch(change(RXFORM_GAMEQR_CATEGORY, RXFIELD_GAMEQR_CATEGORY_IMAGE, image));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GameQRCategoryPage);
