import { downloadingActivityTypes, setActivityTypes } from '../simple-action';
import { downloadActivityTypes, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, activityTypes, uiActivityType,
  } = getState();
  const { token } = authentication;
  const { meta, data } = activityTypes;
  const {
    selectedPageSize, searchBarText, filterString, orderBy,
  } = uiActivityType;

  const downloadedItemsCount = Object.keys(data).length;
  const totalItemCount = meta.totalCount;

  if (downloadedItemsCount > 0 && downloadedItemsCount >= totalItemCount) { return; }

  try {
    dispatch(downloadingActivityTypes(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadActivityTypes(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setActivityTypes(list));
    }
  } finally {
    dispatch(downloadingActivityTypes(false));
  }
};
