import { SET_DYNAMIC_FORM, CLEAR_DYNAMIC_FORM } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_DYNAMIC_FORM:
      return action.data;
    case CLEAR_DYNAMIC_FORM:
      return {};
    default:
      return state;
  }
};
