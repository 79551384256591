import {
  enablingDisablingDistrict, setAlertErrorMessage, setDistrictSelectedOrderBy,
  setDistrictSelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_DISTRICTS, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableDistrict } from '../../../helper';
import downloadDistrictsAsync from './downloadDistrictsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingDistrict(true));

    const { token } = getState().authentication;
    const { data } = getState().districts;
    const { tappedId } = getState().uiDistrict;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableDistrict(tappedId, status, token);

    dispatch(setDistrictSelectedPageSize(20));
    dispatch(setDistrictSelectedOrderBy(INITIAL_ORDER_BY_DISTRICTS));
    dispatch(downloadDistrictsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingDistrict(false));
  }
};
