
import { INITIAL_ORDER_BY_SPEAKERS, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableSpeaker } from '../../../helper';
import {
  enablingDisablingSpeaker, setAlertErrorMessage, setSpeakerSelectedOrderBy,
  setSpeakerSelectedPageSize,
} from '../simple-action';
import downloadSpeakersAsync from './downloadSpeakersAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingSpeaker(true));

    const { token } = getState().authentication;
    const { data } = getState().speakers;
    const { tappedId } = getState().uiSpeaker;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableSpeaker(tappedId, status, token);

    dispatch(setSpeakerSelectedPageSize(20));
    dispatch(setSpeakerSelectedOrderBy(INITIAL_ORDER_BY_SPEAKERS));
    dispatch(downloadSpeakersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingSpeaker(false));
  }
};
