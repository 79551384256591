
import { INITIAL_ORDER_BY_VOUCHER_TOKENS } from '../../constant';
import {
  DOWNLOADING_VOUCHER_TOKENS, SET_VOUCHER_TOKEN_SEARCH_TEXT,
  SET_VOUCHER_TOKEN_SELECTED_ORDER_BY, SET_VOUCHER_TOKEN_SELECTED_PAGE_SIZE,
  SET_VOUCHER_TOKEN_TAPPED_ID, DOWNLOADING_VOUCHER_TOKEN,
  SET_PURCHASER_SEARCH_TEXT, SET_REVOKER_SEARCH_TEXT, SET_CLAIMER_SEARCH_TEXT,
  DOWNLOADING_PURCHASERS, DOWNLOADING_REVOKERS, DOWNLOADING_CLAIMERS,
  REVOKING_VOUCHER_TOKEN, SAVING_VOUCHER_TOKENS,
  SET_VOUCHER_TOKEN_CLAIMER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
} from '../action';

const initialState = {
  downloading: false,
  downloadingDeleting: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_VOUCHER_TOKENS,
  claimerSearchBarText: '',
  claimerFilterString: '',
  purchaserSearchBarText: '',
  revokerSearchBarText: '',
  downloadingPurchasers: false,
  downloadingRevokers: false,
  downloadingClaimers: false,
  revoking: false,
  saving: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_VOUCHER_TOKENS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_VOUCHER_TOKEN:
      return { ...state, downloadingDeleting: action.status };
    case SET_VOUCHER_TOKEN_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_VOUCHER_TOKEN_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_VOUCHER_TOKEN_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_VOUCHER_TOKEN_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_CLAIMER_SEARCH_TEXT:
      return { ...state, claimerSearchBarText: action.text };
    case SET_PURCHASER_SEARCH_TEXT:
      return { ...state, purchaserSearchBarText: action.text };
    case SET_REVOKER_SEARCH_TEXT:
      return { ...state, revokerSearchBarText: action.text };
    case DOWNLOADING_PURCHASERS:
      return { ...state, downloadingPurchasers: action.status };
    case DOWNLOADING_REVOKERS:
      return { ...state, downloadingRevokers: action.status };
    case DOWNLOADING_CLAIMERS:
      return { ...state, downloadingClaimers: action.status };
    case REVOKING_VOUCHER_TOKEN:
      return { ...state, revoking: action.status };
    case SAVING_VOUCHER_TOKENS:
      return { ...state, saving: action.status };
    case SET_VOUCHER_TOKEN_CLAIMER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, claimerFilterString: action.text };
    default: return state;
  }
};
