import { downloadLocations, transformSearchText } from '../../../helper';
import { downloadingLocations, setLocations } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, locations, uiLocation } = getState();
  const { token } = authentication;
  const { meta } = locations;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiLocation;

  try {
    dispatch(downloadingLocations(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadLocations(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setLocations(list));
    }
  } finally {
    dispatch(downloadingLocations(false));
  }
};
