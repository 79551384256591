import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, Card, CardContent, CardHeader, CircularProgress, Divider, FormControl, FormHelperText,
  Grid, List, ListItem, ListItemText, ListItemIcon, makeStyles, TextField, Typography,
} from '@material-ui/core';
import {
  COLOR_PRIMARY, COLOR_BODY_TEXT, COLOR_DANGEROUS,
} from '../constant';
import { sortAsc } from '../helper';
import LocalizedString from '../localization';
import { SimpleDataShape } from '../type';
import AccentButton from './accent-button';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  form: {
    margin: theme.spacing(1),
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_PRIMARY,
      },
    },
    width: '100%',
    minWidth: 250,
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  disabledlist: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  list: {
    width: '100%',
    height: 300,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    width: 100,
    color: COLOR_PRIMARY,
    borderWidth: 1,
    borderRadius: 50,
    borderColor: COLOR_PRIMARY,
    fontSize: '12px',
    marginBottom: '8px',
  },
  helperText: {
    fontWeight: 'bold',
    color: COLOR_DANGEROUS,
  },
  textField: {
    display: 'flex',
    alignItems: 'stretch',
    fontSize: '12px',
  },
  checkboxText: {
    fontSize: '12px',
  },
  checkbox: {
    padding: 0,
  },
  cardHeaderTitle: {
    fontSize: '14px',
  },
  loading: {
    width: '100%',
    display: 'flex',
    height: 50,
    justifyContent: 'center',
  },
}));

const currentId = (arr, currValue) => (typeof currValue === 'object'
  ? arr.findIndex((x) => x.value === currValue.value)
  : arr.indexOf(currValue));

const sortData = (arr) => arr.sort((a, b) => sortAsc(a.label ? a.label : a, b.label ? b.label : b));

const not = (a, b) => a.filter((value) => currentId(b, value) === -1);
const intersection = (a, b) => a.filter((value) => currentId(b, value) !== -1);
const union = (a, b) => [...a, ...not(b, a)];

const transformSearchText = (text, searchTextValue) => text.toLowerCase()
  .includes(searchTextValue.toLowerCase());

const TransferListField = ({
  data, selectedData,
  disabled, error, hidden, loading,
  onOptionSelected,
  label, helperText,
  ...props
}) => {
  const classes = useStyles();

  const filteredData = selectedData.length > 0
    ? sortData(data.filter((item) => (typeof item === 'object'
      ? !selectedData.find((x) => x.value === item.value) : !selectedData.includes(item))))
    : sortData(data);
  const [checked, setChecked] = useState([]);
  const [adding, setAdding] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [left, setLeft] = useState(filteredData);
  const [right, setRight] = useState(selectedData);
  const [leftSearchText, setLeftSearchText] = useState('');
  const [rightSearchText, setRightSearchText] = useState('');

  useEffect(() => {
    if (!loading && !removing && !adding && data.length > 0) {
      setLeft(filteredData);
    }
    if (right.length > 0) {
      setChecked(right);
      onOptionSelected(sortData(right));
    }
    // eslint-disable-next-line
  }, [loading, data.length, right, right.length, onOptionSelected]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const onToggle = (value) => () => {
    const currentIndex = currentId(checked, value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const onToggleAll = (filteredItems, allItems) => () => {
    if (numberOfChecked(filteredItems) === filteredItems.length) {
      setChecked(not(checked, allItems));
    } else {
      setChecked(union(checked, filteredItems));
    }
  };

  const onAddPress = () => {
    setAdding(true);
    setRight(sortData(right.concat(leftChecked)));
    setLeft(sortData(not(left, leftChecked)));
    onOptionSelected(sortData(right.concat(leftChecked)));
    setChecked(right.concat(leftChecked));
  };

  const onRemovePress = () => {
    setRemoving(true);
    setLeft(sortData(left.concat(rightChecked)));
    setRight(sortData(not(right, rightChecked)));
    onOptionSelected(sortData(not(checked, rightChecked)));
    setChecked(not(checked, rightChecked));
  };

  const onSearchTextChange = (filteredItems, allItems) => {
    const filtered = not(allItems, filteredItems);
    setChecked(not(checked, filtered));
  };

  const renderList = (title, items, searchTextValue, onChangeSearchText) => {
    const filteredItems = searchTextValue ? items.filter((x) => (typeof x === 'object' ? transformSearchText(x.value, searchTextValue) : transformSearchText(x, searchTextValue))) : items;

    const renderEmptyListComponent = () => {
      if (searchTextValue) {
        return (
          <CardContent>
            <Typography>
              {LocalizedString.common.errMsgNoDataFound}
            </Typography>
          </CardContent>
        );
      }
      return null;
    };

    return (
      <Card>
        {!disabled && (
          <CardContent>
            <TextField
              label={LocalizedString.common.labelSearch}
              disabled={loading}
              placeholder={LocalizedString.common.labelSearch}
              variant="outlined"
              value={searchTextValue}
              onChange={(e) => {
                onChangeSearchText(e.target.value);
                onSearchTextChange(filteredItems, items);
              }}
              fullWidth
              className={classes.textField}
              size="small"
            />
          </CardContent>
        )}

        <CardHeader
          className={classes.cardHeader}
          avatar={!disabled ? (
            <Checkbox
              onClick={onToggleAll(filteredItems, items)}
              checked={numberOfChecked(filteredItems) === filteredItems.length
                && filteredItems.length !== 0}
              indeterminate={numberOfChecked(filteredItems) !== filteredItems.length
                && numberOfChecked(filteredItems) !== 0}
              disabled={items.length === 0 || disabled}
              inputProps={{ 'aria-label': 'all items selected' }}
            />
          ) : undefined}
          title={title}
          subheader={disabled ? `${items.length} ${LocalizedString.common.labelSelected}` : `${numberOfChecked(filteredItems)}/${items.length} ${LocalizedString.common.labelSelected}`}
          classes={{
            title: classes.cardHeaderTitle,
            subheader: classes.cardHeaderTitle,
          }}
        />

        <Divider />

        {loading ? (
          <div className={classes.loading}>
            <CircularProgress color="inherit" size={20} style={{ margin: 10 }} />
          </div>
        ) : (
          <List className={disabled ? classes.disabledList : classes.list} dense component="div" role="list" disabled={disabled}>
            {filteredItems.length > 0 ? filteredItems.map((value) => {
              const currValue = typeof value === 'object' ? value.value : value;
              return (
                <ListItem key={currValue} role="listitem" button onClick={onToggle(value)} disabled={disabled}>
                  {!disabled ? (
                    <ListItemIcon>
                      <Checkbox
                        checked={currentId(checked, value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': currValue }}
                        disabled={disabled}
                        className={classes.checkbox}
                      />
                    </ListItemIcon>
                  ) : null}
                  <ListItemText
                    id={currValue}
                    primary={typeof value === 'object' ? value.label : value}
                    classes={{ primary: classes.checkboxText }}
                  />
                </ListItem>
              );
            }) : renderEmptyListComponent()}
            <ListItem />
          </List>
        )}
      </Card>
    );
  };

  const renderGrid = (isRightEmpty) => {
    if (disabled) {
      return (
        <Grid container spacing={1} justify="center" alignItems="center">
          <Grid item sm md>{renderList(LocalizedString.common.labelChosen, right)}</Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={1} justify="center" alignItems="center" alignContent="stretch">
        <Grid item xs sm md>
          {renderList(LocalizedString.common.labelChoices, left, leftSearchText,
            setLeftSearchText)}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <AccentButton
              variant="outlined"
              caption={LocalizedString.common.buttonCaptionAdd}
              onClick={onAddPress}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
              className={classes.button}
              size="small"
            />
            <AccentButton
              variant="outlined"
              caption={LocalizedString.common.buttonCaptionRemove}
              onClick={onRemovePress}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
              className={classes.button}
              size="small"
            />
          </Grid>
        </Grid>
        <Grid item xs sm md>
          {renderList(LocalizedString.common.labelChosen, right, rightSearchText,
            setRightSearchText)}
          {error || isRightEmpty
            ? <FormHelperText className={classes.helperText}>{helperText}</FormHelperText> : null}
        </Grid>
      </Grid>
    );
  };

  if (!hidden) {
    const isRightEmpty = right.length === 0;

    return (
      <div className={classes.container}>
        <FormControl className={classes.form} variant="outlined" error={error || isRightEmpty} {...props}>
          <Typography variant="subtitle1">{label}</Typography>
          {renderGrid(isRightEmpty)}
        </FormControl>
      </div>
    );
  }
  return null;
};

export default TransferListField;

TransferListField.propTypes = {
  data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(SimpleDataShape)]).isRequired,
  selectedData: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(SimpleDataShape),
    PropTypes.bool]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hidden: PropTypes.bool,
  loading: PropTypes.bool,
  onOptionSelected: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
};

TransferListField.defaultProps = {
  selectedData: [],
  disabled: false,
  error: false,
  hidden: false,
  loading: false,
  helperText: null,
};
