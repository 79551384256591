import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  changePasswordAsync, setAlertErrorMessage, setMyProfileActiveTabId,
  setSettingsDialogVisibility,
} from '../../redux/action';
import { RXFORM_SETTINGS_DIALOG } from '../../constant';
import { } from '../../helper';
import SettingsDialog from './settings-dialog.presentation';


const mapStateToProps = (state) => ({
  visible: state.uiBasePage.settingsDialogVisibility,
  changingPassword: state.uiBasePage.changingPassword,
  activeTabId: state.uiBasePage.myProfileActiveTabId,
  downloadingMyProfile: state.myProfile.downloading,
  myProfile: state.myProfile.data,
});

const mapDispatchToProps = (dispatch) => ({
  onSavePressed: (values, logoutDevices) => {
    dispatch(changePasswordAsync({ ...values, logoutDevices }))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onTabPressed: (tabId) => {
    dispatch(setMyProfileActiveTabId(tabId));
  },
  onCancelPressed: () => {
    dispatch(setSettingsDialogVisibility(false));
    dispatch(reset(RXFORM_SETTINGS_DIALOG));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsDialog);
