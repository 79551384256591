import {
  addEventCategory, clearEventCategories, downloadingDeletingEventCategory,
  setEventCategoryTappedId, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { deleteEventCategory, downloadEventCategory } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadEventCategoriesAsync from './downloadEventCategoriesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingEventCategory(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiEventCategory;
    const { confirmation } = getState().alert;
    const { processingMultipleData } = getState().uiBasePage;

    if (confirmation || processingMultipleData) {
      await deleteEventCategory(tappedId, token);
      dispatch(setEventCategoryTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearEventCategories());
      dispatch(downloadEventCategoriesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const eventCategory = await downloadEventCategory(tappedId, token);
      dispatch(addEventCategory(eventCategory));
    }
  } finally {
    dispatch(downloadingDeletingEventCategory(false));
  }
};
