import {
  REST_URL_ADD_EDIT_EVENT_OPERATOR_MAPPING, REST_URL_ADD_EVENT_OPERATOR_INVITATION,
  REST_URL_ENABLE_DISABLE_EVENT_OPERATOR_MAPPING, REST_URL_EVENT_OPERATOR_INVITATIONS,
  REST_URL_EVENT_OPERATOR_MAPPINGS, REST_URL_RESEND_EVENT_OPERATOR_INVITATION, REST_URL_SAVE_FILE,
  REST_URL_VIEW_DELETE_EVENT_OPERATOR_INVITATION, REST_URL_VIEW_DELETE_EVENT_OPERATOR_MAPPING,
  REST_URL_EVENT_OPERATOR_REGISTRATIONS, REST_URL_EVENT_OPERATOR_REGISTRATION,
  REST_URL_APPROVE_REJECT_EVENT_OPERATOR_REGISTRATION,
  REST_URL_VIEW_EVENT_OPERATOR_EVENT, REST_URL_EVENT_OPERATOR_EVENTS,
} from './constant';
import {
  buildQueryParams, sendDeleteRequest, sendGetFileRequest, sendGetRequest, sendPostRequest,
  sendPutRequest, transformCompleteUrl, transformEventSessionData, transformQueryResult,
} from '../../helper';

export * from '../../helper';

export const downloadEventOperatorMappings = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(transformCompleteUrl(REST_URL_EVENT_OPERATOR_MAPPINGS, searchText,
    filterString), pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addEventOperatorMapping = async (eventId, userId, validUntil, token) => {
  const body = { eventId, userId, validUntil };
  await sendPostRequest(REST_URL_ADD_EDIT_EVENT_OPERATOR_MAPPING, body, token);
};

export const editEventOperatorMapping = async (id, eventId, userId, validUntil, token) => {
  const body = {
    id, eventId, userId, validUntil,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_EVENT_OPERATOR_MAPPING, body, token);
};

export const downloadEventOperatorMapping = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_EVENT_OPERATOR_MAPPING.replace(/\{id\}/, id), token);
  return response;
};

export const deleteEventOperatorMapping = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_EVENT_OPERATOR_MAPPING.replace(/\{id\}/, id), token);
};

export const enableDisableEventOperatorMapping = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_EVENT_OPERATOR_MAPPING, body, token);
};

export const downloadEventOperatorInvitations = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_EVENT_OPERATOR_INVITATIONS, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadEventOperatorInvitation = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_EVENT_OPERATOR_INVITATION.replace(/\{id\}/, id), token);
  return response;
};

export const deleteEventOperatorInvitation = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_EVENT_OPERATOR_INVITATION.replace(/\{id\}/, id), token);
};

export const addEventOperatorInvitation = async (eventId, emails, token) => {
  const body = { eventId, emails };
  await sendPostRequest(REST_URL_ADD_EVENT_OPERATOR_INVITATION, body, token);
};

export const resendEventOperatorInvitation = async (invitationId, token) => {
  const body = { invitationId };
  await sendPostRequest(REST_URL_RESEND_EVENT_OPERATOR_INVITATION, body, token);
};

export const saveEventOperatorInvitations = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const downloadUrl = REST_URL_EVENT_OPERATOR_INVITATIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = buildQueryParams(
    transformCompleteUrl(downloadUrl, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadEventOperatorEvents = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(transformCompleteUrl(REST_URL_EVENT_OPERATOR_EVENTS, searchText,
    filterString), pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadEventOperatorEvent = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_EVENT_OPERATOR_EVENT.replace(/\{id\}/, id), token);
  const transformData = transformEventSessionData(response);
  return transformData;
};

export const downloadEventOperatorRegistrations = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_EVENT_OPERATOR_REGISTRATIONS, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadEventOperatorRegistration = async (id, token) => {
  const response = await sendGetRequest(REST_URL_EVENT_OPERATOR_REGISTRATION.replace(/\{id\}/, id), token);
  return response;
};

export const approveRejectEventOperatorRegistration = async (id, status, notes, token) => {
  const body = { id, status, notes };
  await sendPutRequest(REST_URL_APPROVE_REJECT_EVENT_OPERATOR_REGISTRATION, body, token);
};

export const saveEventOperatorRegistrations = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const downloadUrl = REST_URL_EVENT_OPERATOR_REGISTRATIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = buildQueryParams(
    transformCompleteUrl(downloadUrl, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetFileRequest(url, token);
  return response;
};
