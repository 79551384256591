import {
  addLocation,
  clearLocations,
  downloadingDeletingLocation,
  setAlertErrorMessage, setFunctionalPageMode, setLocationTappedId,
} from '../simple-action';
import { PAGE_MODE_TABLE } from '../../../constant';
import { deleteLocation, downloadLocation } from '../../../helper';
import downloadLocationsAsync from './downloadLocationsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingLocation(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiLocation;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteLocation(tappedId, token);
      dispatch(setLocationTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearLocations());
      dispatch(downloadLocationsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const location = await downloadLocation(tappedId, token);
      dispatch(addLocation(location));
    }
  } finally {
    dispatch(downloadingDeletingLocation(false));
  }
};
