import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW,
  RXFIELD_SPONSOR_EVENT_ID, RXFIELD_SPONSOR_COMPANY_ID, RXFIELD_SPONSOR_SPONSOR_CATEGORY_ID,
  RXFIELD_SPONSOR_ORDER, RXFORM_SPONSOR,
  RXSTATE_SPONSORS, RXSTATE_SPONSOR_PAGE, status, FILTER_TYPE_DATE_RANGE,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, ADVANCED_FILTER_MODE,
} from '../../constant';
import { renderReduxFormOutlinedTextField, renderReduxFormOutlinedDropdownTextField } from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { rxformValidateSponsor } from '../../validation';
import { FunctionalPage } from '../../../configuration/component';
import { toMoment } from '../../helper';

const useStyles = makeStyles(() => ({
  dialogHeader: {
    marginBottom: 20,
  },
}));


const renderDialogContent = (
  addingEditing, pageMode, events, downloadingEvents, onChangeEventText, onEventsTypeSelected,
  companiesMdata, downloadingCompanies, onChangeCompanyText, onCompaniesTypeSelected,
  sponsorCategories, downloadingSponsorCategories, onChangeSponsorCategoryText,
  onSponsorCategoriesTypeSelected,
) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_SPONSOR_EVENT_ID}
          component={renderReduxFormOutlinedDropdownTextField}
          label={LocalizedString.sponsorPage.labelEvent}
          placeholder={LocalizedString.sponsorPage.placeholderEvent}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          data={events}
          loading={downloadingEvents}
          onChangeText={onChangeEventText}
          onOptionSelected={onEventsTypeSelected}
          onBlur={(e) => e.preventDefault()}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_SPONSOR_COMPANY_ID}
          component={renderReduxFormOutlinedDropdownTextField}
          label={LocalizedString.sponsorPage.labelCompany}
          placeholder={LocalizedString.sponsorPage.placeholderCompany}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          data={companiesMdata}
          loading={downloadingCompanies}
          onChangeText={onChangeCompanyText}
          onOptionSelected={onCompaniesTypeSelected}
          onBlur={(e) => e.preventDefault()}
          required
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_SPONSOR_SPONSOR_CATEGORY_ID}
          component={renderReduxFormOutlinedDropdownTextField}
          label={LocalizedString.sponsorPage.labelSponsorCategory}
          placeholder={LocalizedString.sponsorPage.placeholderSponsorCategory}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          data={sponsorCategories}
          loading={downloadingSponsorCategories}
          onChangeText={onChangeSponsorCategoryText}
          onOptionSelected={onSponsorCategoriesTypeSelected}
          onBlur={(e) => e.preventDefault()}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_SPONSOR_ORDER}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.sponsorPage.placeholderOrder}
          label={LocalizedString.sponsorPage.labelOrder}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
          type="number"
        />
      </Grid>
    </Grid>
  </Grid>
);

const EventCategoryPage = ({
  addingEditing, downloading,
  handleSubmit, onAppear, onApplyAdvancedFilterPressed,
  onCancelPressed, onChangePage, onChangePageSize,
  onConfirmContextMenuPressed, onCreatePressed,
  onEditPressed, onRefresh, onResetAdvancedFilterPressed,
  onSearchBarTextChanged, onSortPressed,
  onSubmitPressed, onViewPressed,
  pageMode, sponsors,
  onDeletePressed, onSavePressed, onAdvancedFilterPressed,
  events, downloadingEvents, onChangeEventText, onEventsTypeSelected,
  companiesMdata, downloadingCompanies, onChangeCompanyText, onCompaniesTypeSelected,
  sponsorCategories, downloadingSponsorCategories, onChangeSponsorCategoryText,
  onSponsorCategoriesTypeSelected,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_SPONSORS}
      uiPage={RXSTATE_SPONSOR_PAGE}
      tableColumns={[
        {
          title: GlobalLocalizedString.common.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.sponsorPage.labelCompanyName, field: 'company.name', sorting: !downloading },
        { title: LocalizedString.sponsorPage.labelEventName, field: 'event.name', sorting: !downloading },
        { title: LocalizedString.sponsorPage.labelCategory, field: 'category.name', sorting: !downloading },
        { title: LocalizedString.sponsorPage.labelOrder, field: 'order', sorting: !downloading },
        {
          title: GlobalLocalizedString.common.placeholderCreatedDate,
          field: 'createdDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
      ]}
      filterColumns={[
        {
          title: LocalizedString.sponsorPage.labelCreatedDate,
          field: 'createdDate',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.sponsorPage.labelEvent,
          field: 'event',
          type: FILTER_TYPE_DROPDOWN,
          data: events,
          loading: downloadingEvents,
          onChangeFilterText: (text) => onChangeEventText(text, ADVANCED_FILTER_MODE),
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sponsorPage.labelCompany,
          field: 'company',
          type: FILTER_TYPE_DROPDOWN,
          data: companiesMdata,
          loading: downloadingCompanies,
          onChangeFilterText: (text) => onChangeCompanyText(text, ADVANCED_FILTER_MODE),
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sponsorPage.labelSponsorCategory,
          field: 'sponsorCategory',
          type: FILTER_TYPE_DROPDOWN,
          data: sponsorCategories,
          loading: downloadingSponsorCategories,
          onChangeFilterText: (text) => onChangeSponsorCategoryText(text, ADVANCED_FILTER_MODE),
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sponsorPage.labelStatus,
          field: 'status',
          type: FILTER_TYPE_DROPDOWN,
          data: status,
          useDropdownValue: true,
        },
      ]}
      handleSubmit={handleSubmit}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmContextMenuPressed}
      onCreatePressed={onCreatePressed}
      onEditPressed={(id) => onEditPressed(id, sponsors)}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSavePressed={onSavePressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onSubmitPressed={onSubmitPressed}
      onViewPressed={(id) => onViewPressed(id, sponsors)}
      createNewButtonCaption={LocalizedString.sponsorPage.buttonCaptionCreateNewSponsor}
      deleteButtonCaption={LocalizedString.sponsorPage.buttonCaptionDeleteSponsor}
      editButtonCaption={LocalizedString.sponsorPage.buttonCaptionEditSponsor}
      title={LocalizedString.sponsorPage.title}
      addDialogHeaderContainerStyle={classes.dialogHeader}
      onDeletePressed={onDeletePressed}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      useFullWidth
      createPermissionName="EVENT_CREATE_SPONSOR"
      deletePermissionName="EVENT_DELETE_SPONSOR"
      editPermissionName="EVENT_EDIT_SPONSOR"

    >
      {renderDialogContent(addingEditing, pageMode, events, downloadingEvents,
        onChangeEventText, onEventsTypeSelected,
        companiesMdata, downloadingCompanies, onChangeCompanyText, onCompaniesTypeSelected,
        sponsorCategories, downloadingSponsorCategories, onChangeSponsorCategoryText,
        onSponsorCategoriesTypeSelected)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_SPONSOR,
  validate: rxformValidateSponsor,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(EventCategoryPage);

EventCategoryPage.propTypes = {
  addingEditing: PropTypes.bool.isRequired,
  companiesMdata: PropTypes.arrayOf(PropTypes.object).isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingCompanies: PropTypes.bool.isRequired,
  downloadingEvents: PropTypes.bool.isRequired,
  downloadingSponsorCategories: PropTypes.bool.isRequired,
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  sponsorCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  sponsors: PropTypes.objectOf(PropTypes.object).isRequired,
  pageMode: PropTypes.string.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeCompanyText: PropTypes.func.isRequired,
  onChangeEventText: PropTypes.func.isRequired,
  onChangeSponsorCategoryText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onCompaniesTypeSelected: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onEventsTypeSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSponsorCategoriesTypeSelected: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
