import {
  enablingDisablingProfile, setAlertErrorMessage, setProfileSelectedOrderBy,
  setProfileSelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_PROFILES, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableProfile } from '../../../helper';
import downloadProfilesAsync from './downloadProfilesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingProfile(true));

    const { token } = getState().authentication;
    const { data } = getState().profiles;
    const { tappedId } = getState().uiProfile;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableProfile(tappedId, status, token);

    dispatch(setProfileSelectedPageSize(20));
    dispatch(setProfileSelectedOrderBy(INITIAL_ORDER_BY_PROFILES));
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingProfile(false));
  }
};
