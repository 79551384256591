import {
  INITIAL_ORDER_BY_EVENT_CATEGORIES, STATUS_DISABLED, STATUS_ENABLED,
} from '../../../constant';
import { enableDisableEventCategory } from '../../../helper';
import {
  enablingDisablingEventCategory, setAlertErrorMessage, setEventCategorySelectedOrderBy,
  setEventCategorySelectedPageSize,
} from '../simple-action';
import downloadEventCategoriesAsync from './downloadEventCategoriesAsync';

export default (selectedStatus = '') => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingEventCategory(true));

    const { token } = getState().authentication;
    const { data } = getState().eventCategories;
    const { tappedId } = getState().uiEventCategory;

    const checkStatus = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;
    const status = selectedStatus !== '' ? selectedStatus : checkStatus;

    await enableDisableEventCategory(tappedId, status, token);

    dispatch(setEventCategorySelectedPageSize(20));
    dispatch(setEventCategorySelectedOrderBy(INITIAL_ORDER_BY_EVENT_CATEGORIES));
    dispatch(downloadEventCategoriesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingEventCategory(false));
  }
};
