import {
  resyncingBranch, setAlertErrorMessage, setBranchSelectedOrderBy, setBranchSelectedPageSize,
} from '../simple-action';
import { resyncMasterDataIndividually } from '../../../helper';
import { INITIAL_ORDER_BY_BRANCHES, MASTER_DATA_ENTITY_NAME_BRANCH } from '../../../constant';
import downloadBranchesAsync from './downloadBranchesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resyncingBranch(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiBranch;

    await resyncMasterDataIndividually(MASTER_DATA_ENTITY_NAME_BRANCH, tappedId, token);

    dispatch(setBranchSelectedPageSize(20));
    dispatch(setBranchSelectedOrderBy(INITIAL_ORDER_BY_BRANCHES));
    dispatch(downloadBranchesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(resyncingBranch(false));
  }
};
