import { PAGE_MODE_TABLE } from '../../../constant';
import { deleteSessionInvitation, downloadSessionInvitation } from '../../../helper';
import {
  addSessionInvitation, clearSessionInvitations, downloadingDeletingSessionInvitation,
  setAlertErrorMessage, setSessionInvitationTappedId, setFunctionalPageMode,
} from '../simple-action';
import downloadSessionInvitationsAsync from './downloadSessionInvitationsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingSessionInvitation(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSessionInvitation;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteSessionInvitation(tappedId, token);
      dispatch(setSessionInvitationTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearSessionInvitations());
      dispatch(downloadSessionInvitationsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const sessionInvitation = await downloadSessionInvitation(tappedId, token);
      dispatch(addSessionInvitation(sessionInvitation));
    }
  } finally {
    dispatch(downloadingDeletingSessionInvitation(false));
  }
};
