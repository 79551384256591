import { PAGE_MODE_TABLE } from '../../../constant';
import { deleteSessionOperatorInvitation, downloadSessionOperatorInvitation } from '../../../helper';
import {
  addSessionOperatorInvitation, clearSessionOperatorInvitations,
  downloadingDeletingSessionOperatorInvitation, setAlertErrorMessage,
  setSessionOperatorInvitationTappedId, setFunctionalPageMode,
} from '../simple-action';
import downloadSessionOperatorInvitationsAsync from './downloadSessionOperatorInvitationsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingSessionOperatorInvitation(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSessionOperatorInvitation;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteSessionOperatorInvitation(tappedId, token);
      dispatch(setSessionOperatorInvitationTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearSessionOperatorInvitations());
      dispatch(downloadSessionOperatorInvitationsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const result = await downloadSessionOperatorInvitation(tappedId, token);
      dispatch(addSessionOperatorInvitation(result));
    }
  } finally {
    dispatch(downloadingDeletingSessionOperatorInvitation(false));
  }
};
