import { downloadEventOperatorMappings, transformSearchText } from '../../../helper';
import { downloadingEventOperatorMappings, setEventOperatorMappings } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, eventOperatorMappings, uiEventOperatorMapping } = getState();
  const { token } = authentication;
  const { meta } = eventOperatorMappings;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiEventOperatorMapping;

  try {
    dispatch(downloadingEventOperatorMappings(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadEventOperatorMappings(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setEventOperatorMappings(list));
    }
  } finally {
    dispatch(downloadingEventOperatorMappings(false));
  }
};
