import {
  SET_IS_SELFIE_MODAL_OPEN,
} from '../action';

const initialState = {
  isSelfieModalOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_SELFIE_MODAL_OPEN:
      return { ...state, isSelfieModalOpen: action.status };
    default: return state;
  }
};
