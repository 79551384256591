import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import { toNumber } from 'lodash';
import {
  clearPopUpBanners, setActiveSideMenuItem, setAlertConfirmationMessage,
  setAlertErrorMessage, setPopUpBannerSearchText, setPopUpBannerSelectedOrderBy,
  setPopUpBannerTappedId, addEditPopUpBannerAsync, downloadDeletePopUpBannerAsync,
  downloadPopUpBannersAsync, setPopUpBannerSelectedPageSize,
  setPopUpBannerAdvancedFilterDialogSelectedFilterString,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_POPUP_BANNERS, PAGE_MODE_TABLE, RXFORM_POPUP_BANNER,
  MENUID_PERSONALIZATION_POPUP_BANNER, REST_BASE_URL, REST_URL_IMAGE_STORAGE,
  RXFIELD_POPUP_BANNER_IMAGE,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
} from '../../constant';
import { transformInitialValues, localDateToUtc, toMoment } from '../../helper';
import LocalizedString from '../../localization';
import PopupBannerPage from './popup-banner.presentation';

const getInitialValues = (state) => {
  const {
    popUpBanners, uiPopUpBanner, uiFunctionalPage,
  } = state;
  const { downloadingDeleting, tappedId } = uiPopUpBanner;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? popUpBanners.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      order: found.order || 0,
      image: found.image ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.image}` : null,
      description: found?.description || '',
      validFrom: found.validFrom
        ? toMoment(found.validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
      validUntil: found.validUntil
        ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
    }) : {
      order: 0,
      image: '',
      description: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiPopUpBanner.addingEditing,
  downloadingDeleting: state.uiPopUpBanner.downloadingDeleting,
  downloading: state.uiPopUpBanner.downloading,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_PERSONALIZATION_POPUP_BANNER));
    dispatch(setPopUpBannerSearchText(''));
    dispatch(clearPopUpBanners());
    dispatch(setPopUpBannerSelectedPageSize(20));
    dispatch(setPopUpBannerSelectedOrderBy(INITIAL_ORDER_BY_POPUP_BANNERS));
    dispatch(downloadPopUpBannersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString;
    dispatch(setPopUpBannerAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearPopUpBanners());
    dispatch(downloadPopUpBannersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadPopUpBannersAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setPopUpBannerSelectedPageSize(pageSize));
    dispatch(downloadPopUpBannersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_POPUP_BANNER));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeletePopUpBannerAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setPopUpBannerTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setPopUpBannerTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setPopUpBannerTappedId(id));
    dispatch(downloadDeletePopUpBannerAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onImageSelected: (image) => {
    dispatch(change(RXFORM_POPUP_BANNER, RXFIELD_POPUP_BANNER_IMAGE, image));
  },
  onRefresh: (pageSize) => {
    dispatch(setPopUpBannerSelectedPageSize(pageSize));
    dispatch(clearPopUpBanners());
    dispatch(downloadPopUpBannersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setPopUpBannerAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearPopUpBanners());
    dispatch(setPopUpBannerSelectedOrderBy(INITIAL_ORDER_BY_POPUP_BANNERS));
    dispatch(downloadPopUpBannersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    title, description, url, order, validFrom, validUntil, image,
  }) => {
    try {
      await dispatch(addEditPopUpBannerAsync(
        title, description, url, toNumber(order),
        localDateToUtc(validFrom), localDateToUtc(validUntil),
        image,
      ));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setPopUpBannerSearchText(text));
      dispatch(clearPopUpBanners());
      await dispatch(downloadPopUpBannersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setPopUpBannerSelectedOrderBy(orderBy));
    dispatch(clearPopUpBanners());
    dispatch(downloadPopUpBannersAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    title, description, url, order, validFrom, validUntil, image,
  }) => {
    await dispatch(addEditPopUpBannerAsync(
      title, description, url, toNumber(order),
      localDateToUtc(validFrom), localDateToUtc(validUntil),
      image,
    ));
  },
  onViewPressed: (id) => {
    dispatch(setPopUpBannerTappedId(id));
    dispatch(downloadDeletePopUpBannerAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupBannerPage);
