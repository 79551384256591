import {
  INITIAL_ORDER_BY_EVENTS,
  STATUS_DISABLED, STATUS_ENABLED,
} from '../../../constant';
import { enableDisableEvent } from '../../../helper';
import {
  enablingDisablingEvent,
  setAlertErrorMessage,
  setEventSelectedOrderBy,
  setEventSelectedPageSize,
} from '../simple-action';
import downloadEventsAsync from './downloadEventsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingEvent(true));

    const { token } = getState().authentication;
    const { data } = getState().events;
    const { tappedId } = getState().uiEvent;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableEvent(tappedId, status, token);

    dispatch(setEventSelectedPageSize(20));
    dispatch(setEventSelectedOrderBy(INITIAL_ORDER_BY_EVENTS));
    dispatch(downloadEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingEvent(false));
  }
};
