import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../../../component';
import {
  FILTER_TYPE_DROPDOWN, MAPPING_OPERATORS, MAPPING_TYPES, PAGE_MODE_VIEW,
  RXFIELD_INFO_MAPPING_MAPPING_OPERATOR, RXFIELD_INFO_MAPPING_NEW_NAME, RXFIELD_INFO_MAPPING_ORDER,
  RXFIELD_INFO_MAPPING_PHRASE, RXFIELD_INFO_MAPPING_TYPE, RXFORM_INFO_MAPPING,
  RXSTATE_INFO_MAPPINGS, RXSTATE_INFO_MAPPING_PAGE,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField, renderReduxFormSimpleDropdownField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape } from '../../type';
import { rxformValidateInfoMapping } from '../../validation';

const renderDialogContent = (initialValues, addingEditing, downloadingDeleting,
  onMappingOperatorOptionSelected, onTypeOptionSelected, pageMode) => (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid>
          <Field
            name={RXFIELD_INFO_MAPPING_MAPPING_OPERATOR}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.infoMappingScreen.placeholderMappingOperator}
            label={LocalizedString.infoMappingScreen.placeholderMappingOperator}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={MAPPING_OPERATORS}
            value={initialValues.mappingOperator}
            onOptionSelected={onMappingOperatorOptionSelected}
            required
          />
        </Grid>
        <Grid>
          <Field
            name={RXFIELD_INFO_MAPPING_PHRASE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.infoMappingScreen.placeholderPhrase}
            label={LocalizedString.infoMappingScreen.placeholderPhrase}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            multiline
            required
          />
        </Grid>
        <Grid>
          <Field
            name={RXFIELD_INFO_MAPPING_NEW_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.infoMappingScreen.placeholderNewName}
            label={LocalizedString.infoMappingScreen.placeholderNewName}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            multiline
            required
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid>
          <Field
            name={RXFIELD_INFO_MAPPING_TYPE}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.infoMappingScreen.placeholderType}
            label={LocalizedString.infoMappingScreen.placeholderType}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={MAPPING_TYPES}
            value={initialValues.type}
            onOptionSelected={onTypeOptionSelected}
            required
          />
        </Grid>
        <Grid>
          <Field
            name={RXFIELD_INFO_MAPPING_ORDER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.infoMappingScreen.placeholderOrder}
            label={LocalizedString.infoMappingScreen.placeholderOrder}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
            type="number"
          />
        </Grid>
      </Grid>
    </Grid>
);

const InfoMappingPage = ({
  initialValues, addingEditing, downloading, downloadingDeleting,
  handleSubmit, onAppear, onApplyAdvancedFilterPressed, onCancelAdvancedFilterPressed,
  onCancelPressed, onChangePage, onChangePageSize, onConfirmDeletePressed, onCreatePressed,
  onDeletePressed, onEditPressed, onMappingOperatorOptionSelected, onRefresh,
  onResetAdvancedFilterPressed, onSavePressed, onSearchBarTextChanged, onSortPressed,
  onSubmitPressed, onTypeOptionSelected, onViewPressed,
  pageMode,
}) => (
  <FunctionalPage
    data={RXSTATE_INFO_MAPPINGS}
    uiPage={RXSTATE_INFO_MAPPING_PAGE}
    filterColumns={[
      {
        title: LocalizedString.infoMappingScreen.labelOperator,
        field: RXFIELD_INFO_MAPPING_MAPPING_OPERATOR,
        type: FILTER_TYPE_DROPDOWN,
        data: MAPPING_OPERATORS,
      },
      {
        title: LocalizedString.infoMappingScreen.labelType,
        field: RXFIELD_INFO_MAPPING_TYPE,
        type: FILTER_TYPE_DROPDOWN,
        data: MAPPING_TYPES,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.infoMappingScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      {
        title: LocalizedString.infoMappingScreen.labelOrder,
        field: RXFIELD_INFO_MAPPING_ORDER,
        sorting: !downloading,
      },
      {
        title: LocalizedString.infoMappingScreen.labelType,
        field: RXFIELD_INFO_MAPPING_TYPE,
        sorting: !downloading,
      },
      {
        title: LocalizedString.infoMappingScreen.labelOperator,
        field: RXFIELD_INFO_MAPPING_MAPPING_OPERATOR,
        sorting: !downloading,
      },
      {
        title: LocalizedString.infoMappingScreen.labelPhrase,
        field: RXFIELD_INFO_MAPPING_PHRASE,
        sorting: !downloading,
      },
      {
        title: LocalizedString.infoMappingScreen.labelNewName,
        field: RXFIELD_INFO_MAPPING_NEW_NAME,
        sorting: !downloading,
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.infoMappingScreen.buttonCaptionCreateNewInfoMapping}
    deleteButtonCaption={LocalizedString.infoMappingScreen.buttonCaptionDeleteInfoMapping}
    title={LocalizedString.infoMappingScreen.title}
    editButtonCaption={LocalizedString.infoMappingScreen.buttonCaptionEditInfoMapping}
    useFullWidth
    createPermissionName="BCARD_CREATE_INFO_MAPPING"
    deletePermissionName="BCARD_DELETE_INFO_MAPPING"
    editPermissionName="BCARD_EDIT_INFO_MAPPING"
  >
    {renderDialogContent(initialValues, addingEditing, downloadingDeleting,
      onMappingOperatorOptionSelected, onTypeOptionSelected, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_INFO_MAPPING,
  validate: rxformValidateInfoMapping,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(InfoMappingPage);

InfoMappingPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onMappingOperatorOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onTypeOptionSelected: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
