import { isEmpty } from 'lodash';
import {
  RXFIELD_GAMEQR_CATEGORY_NAME, RXFIELD_GAMEQR_CATEGORY_DESCRIPTION,
  RXFIELD_CHALLENGE_TITLE, RXFIELD_CHALLENGE_CONTENT, RXFIELD_CHALLENGE_VALID_FROM,
  RXFIELD_CHALLENGE_VALID_UNTIL, RXFIELD_CHALLENGE_CATEGORY, RXFIELD_CHALLENGE_VISIBILITY_STATUS,
  RXFIELD_CHALLENGE_SCAN_POLICY, RXFIELD_CHALLENGE_MEDIA, RXFIELD_CHALLENGE_CODES,
  RXFIELD_CHALLENGE_IS_PROMOTED, RXFIELD_CHALLENGE_ORDER, RXFIELD_CHALLENGE_SCAN_LIMIT_HOUR,
  RXFIELD_CHALLENGE_SCAN_LIMIT_VALUE, RXFIELD_CHALLENGE_SCAN_LIMIT_DAY,
  RXFIELD_CHALLENGE_SCAN_LIMIT_WEEK, RXFIELD_CHALLENGE_SCAN_LIMIT_MONTH,
  RXFIELD_CHALLENGE_SCAN_LIMIT_MODE, DROPDOWN_MENU_ABSOLUTE,
  RXFIELD_CHALLENGE_VOUCHER,
  RXFIELD_CHALLENGE_BYPASS_MAX_PURCHASE,
  RXFIELD_CHALLENGE_BYPASS_ACHIEVEMENT_VALIDATION,
  RXFIELD_CHALLENGE_COMPLETE_REWARD_TYPE,
  RXFIELD_CHALLENGE_ENABLE_BUDGET,
  RXFIELD_CHALLENGE_BUDGET,
  RXFIELD_CHALLENGE_COINS_AMOUNT,
  COMPLETED_REWARD_TYPE_VOUCHER,
  COMPLETED_REWARD_TYPE_POINT,
} from './constant';
import { rxFormValidateRequiredFields } from '../../validation';
import LocalizedString from './localization';

export const rxformValidateFormGameQRCategory = (values) => {
  const requiredFields = [
    RXFIELD_GAMEQR_CATEGORY_NAME,
    RXFIELD_GAMEQR_CATEGORY_DESCRIPTION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateChallenge = (values) => {
  let requiredFields = [
    RXFIELD_CHALLENGE_TITLE,
    RXFIELD_CHALLENGE_CONTENT,
    RXFIELD_CHALLENGE_VALID_FROM,
    RXFIELD_CHALLENGE_VALID_UNTIL,
    RXFIELD_CHALLENGE_CATEGORY,
    RXFIELD_CHALLENGE_VISIBILITY_STATUS,
    RXFIELD_CHALLENGE_SCAN_POLICY,
    RXFIELD_CHALLENGE_MEDIA,
    RXFIELD_CHALLENGE_CODES,
    RXFIELD_CHALLENGE_IS_PROMOTED,
    RXFIELD_CHALLENGE_SCAN_LIMIT_MODE,
    RXFIELD_CHALLENGE_COMPLETE_REWARD_TYPE,
    RXFIELD_CHALLENGE_ENABLE_BUDGET,
    RXFIELD_CHALLENGE_BUDGET,
  ];
  const isOrderRequired = values.isPromoted
    ? [...requiredFields, RXFIELD_CHALLENGE_ORDER] : requiredFields;
  requiredFields = isOrderRequired;

  let errors = rxFormValidateRequiredFields(values, requiredFields);
  if (values.scanLimitMode === DROPDOWN_MENU_ABSOLUTE) {
    if (values.scanLimitAbsoluteValue <= 0) {
      errors[RXFIELD_CHALLENGE_SCAN_LIMIT_VALUE] = LocalizedString.challengePage
        .errMsgValueMustBeGreater;
    }
  }
  if (values.scanLimitPerHour < 0) {
    errors[RXFIELD_CHALLENGE_SCAN_LIMIT_HOUR] = LocalizedString.challengePage
      .errMsgValueMustBeGreaterOrEqual;
  }
  if (values.scanLimitPerDay < 0) {
    errors[RXFIELD_CHALLENGE_SCAN_LIMIT_DAY] = LocalizedString.challengePage
      .errMsgValueMustBeGreaterOrEqual;
  }
  if (values.scanLimitPerWeek < 0) {
    errors[RXFIELD_CHALLENGE_SCAN_LIMIT_WEEK] = LocalizedString.challengePage
      .errMsgValueMustBeGreaterOrEqual;
  }
  if (values.scanLimitPerMonth < 0) {
    errors[RXFIELD_CHALLENGE_SCAN_LIMIT_MONTH] = LocalizedString.challengePage
      .errMsgValueMustBeGreaterOrEqual;
  }

  if (!isEmpty(values)) {
    const found = Object.entries(values)
      .find((x) => x[0] === RXFIELD_CHALLENGE_COMPLETE_REWARD_TYPE);
    if (found) {
      const rewardType = found[1];
      const rewardTypeVoucher = rewardType?.value === COMPLETED_REWARD_TYPE_VOUCHER || false;
      const rewardTypeCoin = rewardType?.value === COMPLETED_REWARD_TYPE_POINT || false;

      if (rewardTypeVoucher) {
        const additionalRequiredField = [
          RXFIELD_CHALLENGE_VOUCHER,
          RXFIELD_CHALLENGE_BYPASS_MAX_PURCHASE,
          RXFIELD_CHALLENGE_BYPASS_ACHIEVEMENT_VALIDATION,
        ];
        requiredFields.push(...additionalRequiredField);
      }
      if (rewardTypeCoin) {
        requiredFields.push(RXFIELD_CHALLENGE_COINS_AMOUNT);
      }
    }
  }
  errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
