import LocalizedString from '../../../../../localization';
import {
  setAlertErrorMessage,
  downloadingDeletingMultipleEventCategory,
  setProcessingMultipleData,
  setAlertProcessingMessage,
  clearEventCategories,
} from '../simple-action';
import downloadEventCategoriesAsync from './downloadEventCategoriesAsync';
import { deleteEventCategory } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { selectedRow } = getState().uiFunctionalPage;
  const { token } = getState().authentication;
  const { confirmation } = getState().alert;
  const { processingMultipleData } = getState().uiBasePage;
  const promises = [];
  let errorProcess = 0;

  try {
    dispatch(downloadingDeletingMultipleEventCategory(true));

    dispatch(setProcessingMultipleData(true));

    if (confirmation || processingMultipleData) {
      for (let i = 0; i < selectedRow.length; i += 1) {
        let errors = 0;
        dispatch(setAlertProcessingMessage(LocalizedString.common.msgDeletingData.replace(/\{count\}/, `${i + 1}/${selectedRow.length}`)));
        // eslint-disable-next-line no-await-in-loop
        const deletingEvent = await deleteEventCategory(selectedRow[i].id, token).catch((err) => {
          dispatch(setAlertErrorMessage(err));
          errors += 1;
        });
        errorProcess += errors;
        promises.push(deletingEvent);
      }
    }

    await Promise.all(promises).then(() => {
      dispatch(setProcessingMultipleData(false));
    }).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });

    dispatch(clearEventCategories());
    dispatch(setAlertProcessingMessage(LocalizedString.common.msgDownloadingData));
    await dispatch(downloadEventCategoriesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    const message = LocalizedString.common.msgSuccessfullyDeletingData.replace(/\{count\}/, (selectedRow.length - errorProcess));
    const errorMessage = LocalizedString.common.msgFailedDeletingData;
    dispatch(downloadingDeletingMultipleEventCategory(false));
    dispatch(setProcessingMultipleData(false));
    dispatch(setAlertProcessingMessage(
      selectedRow.length === errorProcess ? errorMessage : message,
    ));
  }
};
