import { ORDER_SORT_ASCENDING } from '../../constant';
import GlobalLocalizedString from '../../localization';

export * from '../../constant';

export const REST_URL_FORMS = '/dform/api/v1/Form?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|name|title|tags|description';
export const REST_URL_VIEW_DELETE_FORM = '/dform/api/v1/Form/{id}';
export const REST_URL_ADD_EDIT_FORM = '/dform/api/v1/Form';
export const REST_URL_ENABLE_DISABLE_FORM = '/dform/api/v1/Form/Status';
export const REST_URL_FIELDS = '/dform/api/v1/Field?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|name|label|placeholder|sectionId|sectionLabel';
export const REST_URL_VIEW_DELETE_FIELD = '/dform/api/v1/Field/{id}';
export const REST_URL_ADD_EDIT_FIELD = '/dform/api/v1/Field';
export const REST_URL_ENABLE_DISABLE_FIELD = '/dform/api/v1/Field/Status';
export const REST_URL_RESPONSES = '/dform/api/v1/FormResponse?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|form.name';
export const REST_URL_VIEW_RESPONSE = '/dform/api/v1/FormResponse/{id}';
export const REST_URL_UPLOAD_PREVIEW_FORM_RESPONSE = '/dform/api/v1/FormResponse';
export const REST_URL_DUPLICATE_FORM = '/dform/api/v1/Form/Duplicate';

export const ROUTE_NAME_DFORM = '/dform';
export const ROUTE_NAME_DFORM_FORM = '/form';
export const ROUTE_NAME_DFORM_FIELD = '/field';
export const ROUTE_NAME_DFORM_PREVIEW = '/preview';
export const ROUTE_NAME_DFORM_RESPONSE = '/response';

export const RXFORM_FORM = 'formPage';
export const RXFORM_DUPLICATE_FORM_DIALOG = 'duplicateFormDialog';
export const RXFORM_FIELD = 'fieldPage';
export const RXFORM_PREVIEW = 'previewPage';
export const RXFORM_RESPONSE = 'responsePage';

export const RXFIELD_DFORM_FORM_NAME = 'name';
export const RXFIELD_DFORM_FORM_DESCRIPTION = 'description';
export const RXFIELD_DFORM_FORM_CREATED_DATE = 'createdDate';
export const RXFIELD_DFORM_FORM_VALID_FROM = 'validFrom';
export const RXFIELD_DFORM_FORM_VALID_UNTIL = 'validUntil';
export const RXFIELD_DFORM_FORM_VISIBILITY_STATUS = 'visibilityStatus';
export const RXFIELD_DFORM_FORM_CLOSE_RESPONSE = 'closeFormResponse';
export const RXFIELD_DFORM_FORM_ALLOW_DIRECT_RESPONSE = 'allowDirectUserResponse';
export const RXFIELD_DFORM_FORM_STATUS = 'status';
export const RXFIELD_DFORM_FORM_TITLE = 'title';
export const RXFIELD_DFORM_FORM_TAGS = 'tag';
export const RXFIELD_DFORM_FORM_DETECT_DUPLICATED_RESPONSE = 'detectDuplicatedResponseBasedOnReferenceId';
export const RXFIELD_DFORM_FORM_NAME_DIALOG = 'newName';
export const RXFIELD_DFORM_FIELD_NAME = 'name';
export const RXFIELD_DFORM_FIELD_LABEL = 'label';
export const RXFIELD_DFORM_FIELD_PLACEHOLDER = 'placeHolder';
export const RXFIELD_DFORM_FIELD_VALIDATION_MESSAGE = 'validationMessage';
export const RXFIELD_DFORM_FIELD_DEFAULT_VALUE = 'defaultValue';
export const RXFIELD_DFORM_FIELD_ORDER = 'order';
export const RXFIELD_DFORM_FIELD_REQUIRED = 'required';
export const RXFIELD_DFORM_FIELD_VISIBLE = 'visible';
export const RXFIELD_DFORM_FIELD_READ_ONLY = 'readOnly';
export const RXFIELD_DFORM_FIELD_TEXT_MULTILINE = 'textMultiline';
export const RXFIELD_DFORM_FIELD_TEXT_KEYBOARD_TYPE = 'textKeyboardType';
export const RXFIELD_DFORM_FIELD_FIELD_TYPE = 'fieldType';
export const RXFIELD_DFORM_FIELD_PICKER_MODE = 'pickerMode';
export const RXFIELD_DFORM_FIELD_OPTION_MODE = 'optionMode';
export const RXFIELD_DFORM_FIELD_OPTION_VALUES = 'optionValues';
export const RXFIELD_DFORM_FIELD_OPTION_VALUE_SEPARATOR = 'optionValueSeparator';
export const RXFIELD_DFORM_FIELD_SECTION_ID = 'sectionId';
export const RXFIELD_DFORM_FIELD_SECTION_LABEL = 'sectionLabel';
export const RXFIELD_DFORM_FIELD_TEXT_MIN_LENGTH = 'textMinLength';
export const RXFIELD_DFORM_FIELD_TEXT_MAX_LENGTH = 'textMaxLength';
export const RXFIELD_DFORM_FIELD_TEXT_REGEX_VALIDATION = 'textRegexValidation';
export const RXFIELD_DFORM_FIELD_INT_MIN_VALUE = 'intMinValue';
export const RXFIELD_DFORM_FIELD_INT_MAX_VALUE = 'intMaxValue';
export const RXFIELD_DFORM_FIELD_FLOAT_MIN_VALUE = 'floatMinValue';
export const RXFIELD_DFORM_FIELD_FLOAT_MAX_VALUE = 'floatMaxValue';
export const RXFIELD_DFORM_FIELD_DATE_TIME_MIN_VALUE = 'dateTimeMinValue';
export const RXFIELD_DFORM_FIELD_DATE_TIME_MAX_VALUE = 'dateTimeMaxValue';
export const RXFIELD_DFORM_FIELD_PARENT = 'parent';
export const RXFIELD_DFORM_FIELD_SHOW_IF_PARENT_VALUE = 'showIfParentValue';
export const RXFIELD_DFORM_FIELD_SHOW_IF_PARENT_VALUE_LOGICAL_OPERATOR = 'showIfParentValueLogicalOperator';
export const RXFIELD_DFORM_FIELD_MUST_USE_CAMERA = 'mustUseCamera';
export const RXFIELD_DFORM_FIELD_CREATED_DATE = 'createdDate';
export const RXFIELD_DFORM_FIELD_FORM = 'form';
export const RXFIELD_DFORM_PREVIEW_FORM = 'form';
export const RXFIELD_DFORM_RESPONSE_FORM_ID = 'form.id';
export const RXFIELD_DFORM_RESPONSE_FORM_NAME = 'form.name';
export const RXFIELD_DFORM_RESPONSE_USER_ID = 'user.id';
export const RXFIELD_DFORM_RESPONSE_USER_NAME = 'user.fullName';
export const RXFIELD_DFORM_RESPONSE_REFERENCE_ID = 'referenceId';
export const RXFIELD_DFORM_RESPONSE_REFERENCE_LABEL = 'referenceLabel';
export const RXFIELD_DFORM_RESPONSE_RESPONSE = 'responses';
export const RXFIELD_DFORM_RESPONSE_CREATED_DATE = 'createdDate';

export const RXFIELD_DFORM_PREVIEW_REFERENCE_ID = 'referenceId';
export const RXFIELD_DFORM_PREVIEW_REFERENCE_LABEL = 'referenceLabel';

export const RXSTATE_DFORM_FORM = 'forms';
export const RXSTATE_DFORM_FORM_PAGE = 'uiForm';
export const RXSTATE_DFORM_FIELDS = 'fields';
export const RXSTATE_DFORM_FIELD_PAGE = 'uiField';
export const RXSTATE_DFORM_RESPONSE = 'responses';
export const RXSTATE_DFORM_RESPONSE_PAGE = 'uiResponse';

export const INITIAL_ORDER_BY_FORMS = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_FIELDS = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_RESPONSES = `createdDate ${ORDER_SORT_ASCENDING}`;

export const MENUID_DFORM = 'MENUID_DFORM';
export const MENUID_DFORM_FORM = 'MENUID_DFORM_FORM';
export const MENUID_DFORM_FIELD = 'MENUID_DFORM_FIELD';
export const MENUID_DFORM_PREVIEW = 'MENUID_DFORM_PREVIEW';
export const MENUID_DFORM_RESPONSE = 'MENUID_DFORM_RESPONSE';

export const DFORM_FIELD_PARENT_DEFAULT_FILTER_STRING = 'status=enabled';

export const BOOLEAN_OPTION = [
  { label: GlobalLocalizedString.common.labelYes, value: true },
  { label: GlobalLocalizedString.common.labelNo, value: false },
];
