import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    dFormMenu: {
      title: 'DForm',
    },
    formPage: {
      title: 'Form',

      labelName: 'Name',
      labelValidFrom: 'Valid From',
      labelValidUntil: 'Valid Until',
      labelVisibility: 'Visibility',
      labelVisibilityStatus: 'Visibility Status',
      labelAllowDirectResponse: 'Allow Direct Response',
      labelCloseResponse: 'Close Response',
      labelCreatedDate: 'Created Date',
      labelCloseResponseQuestion: 'Close Response?',
      labelAllowDirectResponseQuestion: 'Allow Direct Response?',
      labelStatus: 'Status',
      labelTitle: 'Title',
      labelTags: 'Tags',
      labelDescription: 'Description',
      labelDetectDuplicatedResponse: 'Detect Duplicated Response Based on Reference ID',
      labelDuplicateTask: 'Duplicate Task',
      labelNewName: 'New Name',

      buttonCaptionCreateNewForm: 'Create New Form',
      buttonCaptionDeleteForm: 'Delete Form',
      buttonCaptionDuplicate: 'Duplicate',
      buttonCaptionEditForm: 'Edit Form',
    },
    fieldPage: {
      title: 'Field',

      labelNo: 'No',
      labelName: 'Name',
      labelLabel: 'Label',
      labelAppliedOn: 'Applied On',
      labelFieldType: 'Field Type',
      labelOrder: 'Order',
      labelVisible: 'Visible',
      labelReadOnly: 'Read Only',
      labelRequired: 'Required',
      labelOptionValues: 'Option Values',
      labelOptionValuesHelper: 'Write each value separated by |',
      labelLogicalOperatorHelper: 'You can specify multiple values separated by |',
      labelStatus: 'Status',
      labelForm: 'Form',
      labelSectionLabel: 'Section Label',
      labelCreatedDate: 'Created Date',
      labelParent: 'Parent',
      labelPickerMode: 'Picker Mode',
      labelOptionMode: 'Option Mode',
      labelKeyboardType: 'Keyboard Type',
      labelMustUseCamera: 'Must Use Camera',

      buttonCaptionCreateNewField: 'Create New Field',
      buttonCaptionEditField: 'Edit Field',
      buttonCaptionDeleteField: 'Delete Field',
      buttonCaptionEnable: 'Enable',
      buttonCaptionDisable: 'Disable',

      placeholderName: 'Name',
      placeholderLabel: 'Label',
      placeholderPlaceholder: 'Placeholder',
      placeholderValidationMessage: 'Validation Message',
      placeholderDefaultValue: 'Default Value',
      placeholderOrder: 'Order',
      placeholderRequired: 'Required',
      placeholderVisible: 'Visible',
      placeholderReadOnly: 'Read Only',
      placeholderTextMultiline: 'Text Multiline',
      placeholderTextKeyboardType: 'Text Keyboard Type',
      placeholderFieldType: 'Field Type',
      placeholderPickerMode: 'Picker Mode',
      placeholderOptionMode: 'Option Mode',
      placeholderOptionValues: 'Option A|Option B|Option C',
      placeholderOptionValueSeparator: 'Option Value Separator',
      placeholderSectionId: 'Section ID',
      placeholderSectionLabel: 'Section Label',
      placeholderAppliedOn: 'Applied On',
      placeholderTextMinLength: 'Text Min Length',
      placeholderTextMaxLength: 'Text Max Length',
      placeholderTextRegexValidation: 'Text Regex Validation',
      placeholderIntMinValue: 'Int Min Value',
      placeholderIntMaxValue: 'Int Max Value',
      placeholderFloatMinValue: 'Float Min Value',
      placeholderFloatMaxValue: 'Float Max Value',
      placeholderDateTimeMinValue: 'Date/Time Min Value',
      placeholderDateTimeMaxValue: 'Date/Time Max Value',
      placeholderParent: 'Parent',
      placeholderShowIfParentValue: 'Show If Parent Value',
      placeholderLogicalOperator: 'Logical Operator',
      placeholderMustUseCamera: 'Must Use Camera',
    },
    previewPage: {
      title: 'Preview',

      labelForm: 'Form Name',
      labelReferenceId: 'Reference ID',
      labelReferenceLabel: 'Reference Label',
      labelResponseSubmitted: 'Response Submitted',
    },
    responsePage: {
      title: 'Response',

      labelForm: 'Form',
      labelUser: 'User',
      labelReferenceLabel: 'Reference Label',
      labelCreatedDate: 'Created Date',
      labelResponse: 'Response',

      placeholderFormID: 'Form ID',
      placeholderFormName: 'Form Name',
      placeholderUserID: 'User ID',
      placeholderUserName: 'User Name',
      placeholderReferenceID: 'Reference ID',
      placeholderReferenceLabel: 'Reference Label',
    },
  },
  id: {
    dFormMenu: {
      title: 'DForm',
    },
    formPage: {
      title: 'Formulir',

      labelName: 'Name',
      labelValidFrom: 'Berlaku Dari',
      labelValidUntil: 'Berlaku Sampai',
      labelVisibility: 'Terlihat',
      labelVisibilityStatus: 'Status Terlihat',
      labelAllowDirectResponse: 'Izinkan Tanggapan Langsung',
      labelCloseResponse: 'Respon Tertutup',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelCloseResponseQuestion: 'Respon Tertutup?',
      labelAllowDirectResponseQuestion: 'Izinkan Tanggapan Langsung?',
      labelStatus: 'Status',
      labelTitle: 'Judul',
      labelTags: 'Penanda',
      labelDescription: 'Deskripsi',
      labelDetectDuplicatedResponse: 'Temukan Tanggapan Ganda Berdasarkan ID Referensi',
      labelDuplicateTask: 'Duplikasi Tugas',
      labelNewName: 'Nama Baru',

      buttonCaptionCreateNewForm: 'Buat Formulir Baru',
      buttonCaptionDeleteForm: 'Hapus Formulir',
      buttonCaptionDuplicate: 'Duplikasi',
      buttonCaptionEditForm: 'Ubah Formulir',
    },
    fieldPage: {
      title: 'Field',

      labelNo: 'No',
      labelName: 'Nama',
      labelLabel: 'Label',
      labelAppliedOn: 'Diterapkan Pada',
      labelFieldType: 'Tipe Field',
      labelOrder: 'Urutan',
      labelVisible: 'Terlihat',
      labelReadOnly: 'Hanya Dibaca',
      labelRequired: 'Harus Diisi',
      labelOptionValues: 'Nilai-nilai Opsi',
      labelOptionValuesHelper: 'Tulis setiap nilai dipisahkan dengan |',
      labelLogicalOperatorHelper: 'Anda bisa menulis lebih dari satu pilihan yang dipisahkan dengan |',
      labelStatus: 'Status',
      labelCreatedDate: 'Tanggal Dibuat',
      labelParent: 'Parent',
      labelPickerMode: 'Mode Picker',
      labelOptionMode: 'Mode Opsi',
      labelKeyboardType: 'Tipe Keyboard',
      labelMustUseCamera: 'Harus Gunakan Kamera',
      labelForm: 'Formulir',
      labelSectionLabel: 'Label Bagian',

      buttonCaptionCreateNewField: 'Buat Field Baru',
      buttonCaptionEditField: 'Ubah Field',
      buttonCaptionDeleteField: 'Hapus Field',
      buttonCaptionEnable: 'Aktifkan',
      buttonCaptionDisable: 'Nonaktifkan',

      placeholderName: 'Nama',
      placeholderLabel: 'Label',
      placeholderPlaceholder: 'Placeholder',
      placeholderValidationMessage: 'Pesan Validasi',
      placeholderDefaultValue: 'Nilai Default',
      placeholderOrder: 'Urutan',
      placeholderRequired: 'Harus Diisi',
      placeholderVisible: 'Terlihat',
      placeholderReadOnly: 'Hanya Dibaca',
      placeholderTextMultiline: 'Teks Multi baris',
      placeholderTextKeyboardType: 'Tipe Keyboard Teks',
      placeholderFieldType: 'Tipe Field',
      placeholderPickerMode: 'Mode Pemilihan',
      placeholderOptionMode: 'Mode Opsi',
      placeholderOptionValues: 'Opsi A|Opsi B|Opsi C',
      placeholderOptionValueSeparator: 'Pemisah Nilai Opsi',
      placeholderSectionId: 'ID Bagian',
      placeholderSectionLabel: 'Label Bagian',
      placeholderAppliedOn: 'Diterapkan Pada',
      placeholderTextMinLength: 'Panjang Teks Min.',
      placeholderTextMaxLength: 'Panjang Teks Maks.',
      placeholderTextRegexValidation: 'Validasi Regex Teks',
      placeholderIntMinValue: 'Nilai Min. Int',
      placeholderIntMaxValue: 'Nilai Maks. Int',
      placeholderFloatMinValue: 'Nilai Min Float',
      placeholderFloatMaxValue: 'Nilai Maks.Float',
      placeholderDateTimeMinValue: 'Nilai Min. Tanggal/Waktu',
      placeholderDateTimeMaxValue: 'Nilai Maks. Tanggal/Waktu',
      placeholderParent: 'Induk',
      placeholderShowIfParentValue: 'Tunjukkan Jika Nilai Induk',
      placeholderLogicalOperator: 'Operator Logika',
      placeholderMustUseCamera: 'Harus Menggunakan Kamera',
    },
    previewPage: {
      title: 'Pratinjau',

      labelForm: 'Nama Formulir',
      labelReferenceId: 'ID Referensi',
      labelReferenceLabel: 'Label Referensi',
      labelResponseSubmitted: 'Respons Dikirim',
    },
    responsePage: {
      title: 'Tanggapan',

      labelForm: 'Form',
      labelUser: 'Pengguna',
      labelReferenceLabel: 'Label Referensi',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelResponse: 'Tanggapan',

      placeholderFormID: 'ID Form',
      placeholderFormName: 'Nama Form',
      placeholderUserID: 'ID Pengguna',
      placeholderUserName: 'Nama Pengguna',
      placeholderReferenceID: 'ID Referensi',
      placeholderReferenceLabel: 'Label Referensi',
    },
  },
});

export default LocalizedString;
