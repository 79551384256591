import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    activityRewardMenu: {
      title: 'Activity Reward',
    },
    dailyCheckinPage: {
      title: 'Daily Check-in',

      labelUserName: 'User Name',
      labelPoint: 'Point',
      labelCreatedDate: 'Created Date',

      placeholderUserID: 'User ID',
      placeholderUserName: 'User Name',
      placeholderPoint: 'Point',
      placeholderCreatedDate: 'Created Date',
    },
    registrationReferralPage: {
      title: 'Registration Referral',

      labelNo: 'No',
      labelRegisteredUser: 'Registered User',
      labelReferrer: 'Referrer',
      labelPoint: 'Point',
      labelCreatedDate: 'Created Date',
      labelRegisteredUserID: 'Registered User ID',
      labelRegisteredUserName: 'Registered User Name',
      labelReferrerUserID: 'Referrer User ID',
      labelReferrerUserName: 'Referrer User Name',

      placeholderRegisteredUserID: 'Registered User ID',
      placeholderRegisteredUserName: 'Registered User Name',
      placeholderPoint: 'Point',
      placeholderReferrerUserID: 'Referrer User ID',
      placeholderReferrerUserName: 'Referrer User Name',
    },
  },
  id: {
    activityRewardMenu: {
      title: 'Hadiah Aktivitas',
    },
    dailyCheckinPage: {
      title: 'Check-in Harian',

      labelUserName: 'Nama Pengguna',
      labelPoint: 'Poin',
      labelCreatedDate: 'Tanggal Pembuatan',

      placeholderUserID: 'ID Pengguna',
      placeholderUserName: 'Nama Pengguna',
      placeholderPoint: 'Poin',
      placeholderCreatedDate: 'Tanggal Pembuatan',
    },
    registrationReferralPage: {
      title: 'Referensi Pendaftaran',

      labelNo: 'No',
      labelRegisteredUser: 'Pengguna Terdaftar',
      labelReferrer: 'Perujuk',
      labelPoint: 'Poin',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelRegisteredUserID: 'ID Pengguna',
      labelRegisteredUserName: 'Nama Pengguna',
      labelReferrerUserID: 'ID Perujuk',
      labelReferrerUserName: 'Nama Perujuk',

      placeholderRegisteredUserID: 'ID Pengguna Terdaftar',
      placeholderRegisteredUserName: 'Nama Pengguna Terdaftar',
      placeholderPoint: 'Poin',
      placeholderReferrerUserID: 'ID Perujuk',
    },
  },
});

export default LocalizedString;
