import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';


export const DOWNLOADING_LOCATIONS = 'DOWNLOADING_LOCATIONS';
export const downloadingLocations = makeActionCreator(DOWNLOADING_LOCATIONS, 'status');

export const SET_LOCATIONS = 'SET_LOCATIONS';
export const setLocations = makeActionCreator(SET_LOCATIONS, 'data');

export const ADD_LOCATION = 'ADD_LOCATION';
export const addLocation = makeActionCreator(ADD_LOCATION, 'data');

export const CLEAR_LOCATIONS = 'CLEAR_LOCATIONS';
export const clearLocations = makeActionCreator(CLEAR_LOCATIONS);

export const ADDING_EDITING_LOCATION = 'ADDING_EDITING_LOCATION';
export const addingEditingLocation = makeActionCreator(ADDING_EDITING_LOCATION, 'status');

export const DOWNLOADING_DELETING_LOCATION = 'DOWNLOADING_DELETING_LOCATION';
export const downloadingDeletingLocation = makeActionCreator(DOWNLOADING_DELETING_LOCATION, 'status');

export const ENABLING_DISABLING_LOCATION = 'ENABLING_DISABLING_LOCATION';
export const enablingDisablingLocation = makeActionCreator(ENABLING_DISABLING_LOCATION, 'status');

export const SAVING_LOCATIONS = 'SAVING_LOCATIONS';
export const savingLocations = makeActionCreator(SAVING_LOCATIONS, 'status');

export const SET_LOCATION_SELECTED_PAGE_SIZE = 'SET_LOCATION_SELECTED_PAGE_SIZE';
export const setLocationSelectedPageSize = makeActionCreator(SET_LOCATION_SELECTED_PAGE_SIZE, 'size');

export const SET_LOCATION_SELECTED_ORDER_BY = 'SET_LOCATION_SELECTED_ORDER_BY';
export const setLocationSelectedOrderBy = makeActionCreator(SET_LOCATION_SELECTED_ORDER_BY, 'order');

export const SET_LOCATION_TAPPED_ID = 'SET_LOCATION_TAPPED_ID';
export const setLocationTappedId = makeActionCreator(SET_LOCATION_TAPPED_ID, 'id');

export const SET_LOCATION_SEARCH_TEXT = 'SET_LOCATION_SEARCH_TEXT';
export const setLocationSearchText = makeActionCreator(SET_LOCATION_SEARCH_TEXT, 'text');

export const SET_LOCATION_SELECTED_CITY_ID = 'SET_LOCATION_SELECTED_CITY_ID';
export const setLocationSelectedCityId = makeActionCreator(SET_LOCATION_SELECTED_CITY_ID, 'id');

export const SET_LOCATION_MAP_CENTER = 'SET_LOCATION_MAP_CENTER';
export const setLocationMapCenter = makeActionCreator(SET_LOCATION_MAP_CENTER, 'data');

export const SET_LOCATION_MAP_MARKER = 'SET_LOCATION_MAP_MARKER';
export const setLocationMapMarker = makeActionCreator(SET_LOCATION_MAP_MARKER, 'data');

export const SET_LOCATION_MAP_POLYGON = 'SET_LOCATION_MAP_POLYGON';
export const setLocationMapPolygon = makeActionCreator(SET_LOCATION_MAP_POLYGON, 'data');

export const SET_LOCATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_LOCATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setLocationAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_LOCATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_EVENT_CATEGORIES = 'DOWNLOADING_EVENT_CATEGORIES';
export const downloadingEventCategories = makeActionCreator(DOWNLOADING_EVENT_CATEGORIES, 'status');

export const SET_EVENT_CATEGORIES = 'SET_EVENT_CATEGORIES';
export const setEventCategories = makeActionCreator(SET_EVENT_CATEGORIES, 'data');

export const ADD_EVENT_CATEGORY = 'ADD_EVENT_CATEGORY';
export const addEventCategory = makeActionCreator(ADD_EVENT_CATEGORY, 'data');

export const CLEAR_EVENT_CATEGORIES = 'CLEAR_EVENT_CATEGORIES';
export const clearEventCategories = makeActionCreator(CLEAR_EVENT_CATEGORIES);

export const ADDING_EDITING_EVENT_CATEGORY = 'ADDING_EDITING_EVENT_CATEGORY';
export const addingEditingEventCategory = makeActionCreator(ADDING_EDITING_EVENT_CATEGORY, 'status');

export const DOWNLOADING_DELETING_EVENT_CATEGORY = 'DOWNLOADING_DELETING_EVENT_CATEGORY';
export const downloadingDeletingEventCategory = makeActionCreator(DOWNLOADING_DELETING_EVENT_CATEGORY, 'status');

export const DOWNLOADING_DELETING_MULTIPLE_EVENT_CATEGORY = 'DOWNLOADING_DELETING_MULTIPLE_EVENT_CATEGORY';
export const downloadingDeletingMultipleEventCategory = makeActionCreator(DOWNLOADING_DELETING_MULTIPLE_EVENT_CATEGORY, 'status');

export const ENABLING_DISABLING_EVENT_CATEGORY = 'ENABLING_DISABLING_EVENT_CATEGORY';
export const enablingDisablingEventCategory = makeActionCreator(ENABLING_DISABLING_EVENT_CATEGORY, 'status');

export const ENABLING_DISABLING_MULTIPLE_EVENT_CATEGORY = 'ENABLING_DISABLING_MULTIPLE_EVENT_CATEGORY';
export const enablingDisablingMultipleEventCategory = makeActionCreator(ENABLING_DISABLING_MULTIPLE_EVENT_CATEGORY, 'status');

export const SET_EVENT_CATEGORY_SELECTED_PAGE_SIZE = 'SET_EVENT_CATEGORY_SELECTED_PAGE_SIZE';
export const setEventCategorySelectedPageSize = makeActionCreator(SET_EVENT_CATEGORY_SELECTED_PAGE_SIZE, 'size');

export const SET_EVENT_CATEGORY_SELECTED_ORDER_BY = 'SET_EVENT_CATEGORY_SELECTED_ORDER_BY';
export const setEventCategorySelectedOrderBy = makeActionCreator(SET_EVENT_CATEGORY_SELECTED_ORDER_BY, 'order');

export const SET_EVENT_CATEGORY_TAPPED_ID = 'SET_EVENT_CATEGORY_TAPPED_ID';
export const setEventCategoryTappedId = makeActionCreator(SET_EVENT_CATEGORY_TAPPED_ID, 'id');

export const SET_EVENT_CATEGORY_SEARCH_TEXT = 'SET_EVENT_CATEGORY_SEARCH_TEXT';
export const setEventCategorySearchText = makeActionCreator(SET_EVENT_CATEGORY_SEARCH_TEXT, 'text');

export const SET_EVENT_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_EVENT_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setEventCategoryAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_EVENT_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_EVENT_CATEGORY_SELECTED_CLEAR_IMAGE = 'SET_EVENT_CATEGORY_SELECTED_CLEAR_IMAGE';
export const setEventCategorySelectedClearImage = makeActionCreator(
  SET_EVENT_CATEGORY_SELECTED_CLEAR_IMAGE, 'text',
);

export const SET_EVENT_BANNER_IMAGE = 'SET_EVENT_BANNER_IMAGE';
export const setEventBannerImage = makeActionCreator(SET_EVENT_BANNER_IMAGE, 'image');


export const DOWNLOADING_SESSION_CATEGORIES = 'DOWNLOADING_SESSION_CATEGORIES';
export const downloadingSessionCategories = makeActionCreator(DOWNLOADING_SESSION_CATEGORIES, 'status');

export const SET_SESSION_CATEGORIES = 'SET_SESSION_CATEGORIES';
export const setSessionCategories = makeActionCreator(SET_SESSION_CATEGORIES, 'data');

export const ADD_SESSION_CATEGORY = 'ADD_SESSION_CATEGORY';
export const addSessionCategory = makeActionCreator(ADD_SESSION_CATEGORY, 'data');

export const CLEAR_SESSION_CATEGORIES = 'CLEAR_SESSION_CATEGORIES';
export const clearSessionCategories = makeActionCreator(CLEAR_SESSION_CATEGORIES);

export const ADDING_EDITING_SESSION_CATEGORY = 'ADDING_EDITING_SESSION_CATEGORY';
export const addingEditingSessionCategory = makeActionCreator(ADDING_EDITING_SESSION_CATEGORY, 'status');

export const DOWNLOADING_DELETING_SESSION_CATEGORY = 'DOWNLOADING_DELETING_SESSION_CATEGORY';
export const downloadingDeletingSessionCategory = makeActionCreator(DOWNLOADING_DELETING_SESSION_CATEGORY, 'status');

export const ENABLING_DISABLING_SESSION_CATEGORY = 'ENABLING_DISABLING_SESSION_CATEGORY';
export const enablingDisablingSessionCategory = makeActionCreator(ENABLING_DISABLING_SESSION_CATEGORY, 'status');

export const SET_SESSION_CATEGORY_SELECTED_PAGE_SIZE = 'SET_SESSION_CATEGORY_SELECTED_PAGE_SIZE';
export const setSessionCategorySelectedPageSize = makeActionCreator(SET_SESSION_CATEGORY_SELECTED_PAGE_SIZE, 'size');

export const SET_SESSION_CATEGORY_SELECTED_ORDER_BY = 'SET_SESSION_CATEGORY_SELECTED_ORDER_BY';
export const setSessionCategorySelectedOrderBy = makeActionCreator(SET_SESSION_CATEGORY_SELECTED_ORDER_BY, 'order');

export const SET_SESSION_CATEGORY_TAPPED_ID = 'SET_SESSION_CATEGORY_TAPPED_ID';
export const setSessionCategoryTappedId = makeActionCreator(SET_SESSION_CATEGORY_TAPPED_ID, 'id');

export const SET_SESSION_CATEGORY_SEARCH_TEXT = 'SET_SESSION_CATEGORY_SEARCH_TEXT';
export const setSessionCategorySearchText = makeActionCreator(SET_SESSION_CATEGORY_SEARCH_TEXT, 'text');

export const SET_SESSION_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_SESSION_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setSessionCategoryAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_SESSION_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_SPONSOR_CATEGORIES = 'DOWNLOADING_SPONSOR_CATEGORIES';
export const downloadingSponsorCategories = makeActionCreator(DOWNLOADING_SPONSOR_CATEGORIES, 'status');

export const SET_SPONSOR_CATEGORIES = 'SET_SPONSOR_CATEGORIES';
export const setSponsorCategories = makeActionCreator(SET_SPONSOR_CATEGORIES, 'data');

export const ADD_SPONSOR_CATEGORY = 'ADD_SPONSOR_CATEGORY';
export const addSponsorCategory = makeActionCreator(ADD_SPONSOR_CATEGORY, 'data');

export const CLEAR_SPONSOR_CATEGORIES = 'CLEAR_SPONSOR_CATEGORIES';
export const clearSponsorCategories = makeActionCreator(CLEAR_SPONSOR_CATEGORIES);

export const ADDING_EDITING_SPONSOR_CATEGORY = 'ADDING_EDITING_SPONSOR_CATEGORY';
export const addingEditingSponsorCategory = makeActionCreator(ADDING_EDITING_SPONSOR_CATEGORY, 'status');

export const DOWNLOADING_DELETING_SPONSOR_CATEGORY = 'DOWNLOADING_DELETING_SPONSOR_CATEGORY';
export const downloadingDeletingSponsorCategory = makeActionCreator(DOWNLOADING_DELETING_SPONSOR_CATEGORY, 'status');

export const ENABLING_DISABLING_SPONSOR_CATEGORY = 'ENABLING_DISABLING_SPONSOR_CATEGORY';
export const enablingDisablingSponsorCategory = makeActionCreator(ENABLING_DISABLING_SPONSOR_CATEGORY, 'status');

export const SET_SPONSOR_CATEGORY_SELECTED_PAGE_SIZE = 'SET_SPONSOR_CATEGORY_SELECTED_PAGE_SIZE';
export const setSponsorCategorySelectedPageSize = makeActionCreator(SET_SPONSOR_CATEGORY_SELECTED_PAGE_SIZE, 'size');

export const SET_SPONSOR_CATEGORY_SELECTED_ORDER_BY = 'SET_SPONSOR_CATEGORY_SELECTED_ORDER_BY';
export const setSponsorCategorySelectedOrderBy = makeActionCreator(SET_SPONSOR_CATEGORY_SELECTED_ORDER_BY, 'order');

export const SET_SPONSOR_CATEGORY_TAPPED_ID = 'SET_SPONSOR_CATEGORY_TAPPED_ID';
export const setSponsorCategoryTappedId = makeActionCreator(SET_SPONSOR_CATEGORY_TAPPED_ID, 'id');

export const SET_SPONSOR_CATEGORY_SEARCH_TEXT = 'SET_SPONSOR_CATEGORY_SEARCH_TEXT';
export const setSponsorCategorySearchText = makeActionCreator(SET_SPONSOR_CATEGORY_SEARCH_TEXT, 'text');

export const SET_SPONSOR_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_SPONSOR_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setSponsorCategoryAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_SPONSOR_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_SPEAKERS = 'DOWNLOADING_SPEAKERS';
export const downloadingSpeakers = makeActionCreator(DOWNLOADING_SPEAKERS, 'status');

export const SET_SPEAKERS = 'SET_SPEAKERS';
export const setSpeakers = makeActionCreator(SET_SPEAKERS, 'data');

export const ADD_SPEAKER = 'ADD_SPEAKER';
export const addSpeaker = makeActionCreator(ADD_SPEAKER, 'data');

export const CLEAR_SPEAKERS = 'CLEAR_SPEAKERS';
export const clearSpeakers = makeActionCreator(CLEAR_SPEAKERS);

export const ADDING_EDITING_SPEAKER = 'ADDING_EDITING_SPEAKER';
export const addingEditingSpeaker = makeActionCreator(ADDING_EDITING_SPEAKER, 'status');

export const DOWNLOADING_DELETING_SPEAKER = 'DOWNLOADING_DELETING_SPEAKER';
export const downloadingDeletingSpeaker = makeActionCreator(DOWNLOADING_DELETING_SPEAKER, 'status');

export const ENABLING_DISABLING_SPEAKER = 'ENABLING_DISABLING_SPEAKER';
export const enablingDisablingSpeaker = makeActionCreator(ENABLING_DISABLING_SPEAKER, 'status');

export const SET_SPEAKER_SELECTED_PAGE_SIZE = 'SET_SPEAKER_SELECTED_PAGE_SIZE';
export const setSpeakerSelectedPageSize = makeActionCreator(SET_SPEAKER_SELECTED_PAGE_SIZE, 'size');

export const SET_SPEAKER_SELECTED_ORDER_BY = 'SET_SPEAKER_SELECTED_ORDER_BY';
export const setSpeakerSelectedOrderBy = makeActionCreator(SET_SPEAKER_SELECTED_ORDER_BY, 'order');

export const SET_SPEAKER_TAPPED_ID = 'SET_SPEAKER_TAPPED_ID';
export const setSpeakerTappedId = makeActionCreator(SET_SPEAKER_TAPPED_ID, 'id');

export const SET_SPEAKER_SEARCH_TEXT = 'SET_SPEAKER_SEARCH_TEXT';
export const setSpeakerSearchText = makeActionCreator(SET_SPEAKER_SEARCH_TEXT, 'text');

export const SET_SPEAKER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_SPEAKER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setSpeakerAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_SPEAKER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_ROOMS = 'DOWNLOADING_ROOMS';
export const downloadingRooms = makeActionCreator(DOWNLOADING_ROOMS, 'status');

export const SET_ROOMS = 'SET_ROOMS';
export const setRooms = makeActionCreator(SET_ROOMS, 'data');

export const ADD_ROOM = 'ADD_ROOM';
export const addRoom = makeActionCreator(ADD_ROOM, 'data');

export const CLEAR_ROOMS = 'CLEAR_ROOMS';
export const clearRooms = makeActionCreator(CLEAR_ROOMS);

export const ADDING_EDITING_ROOM = 'ADDING_EDITING_ROOM';
export const addingEditingRoom = makeActionCreator(ADDING_EDITING_ROOM, 'status');

export const DOWNLOADING_DELETING_ROOM = 'DOWNLOADING_DELETING_ROOM';
export const downloadingDeletingRoom = makeActionCreator(DOWNLOADING_DELETING_ROOM, 'status');

export const ENABLING_DISABLING_ROOM = 'ENABLING_DISABLING_ROOM';
export const enablingDisablingRoom = makeActionCreator(ENABLING_DISABLING_ROOM, 'status');

export const SET_ROOM_SELECTED_PAGE_SIZE = 'SET_ROOM_SELECTED_PAGE_SIZE';
export const setRoomSelectedPageSize = makeActionCreator(SET_ROOM_SELECTED_PAGE_SIZE, 'size');

export const SET_ROOM_SELECTED_ORDER_BY = 'SET_ROOM_SELECTED_ORDER_BY';
export const setRoomSelectedOrderBy = makeActionCreator(SET_ROOM_SELECTED_ORDER_BY, 'order');

export const SET_ROOM_TAPPED_ID = 'SET_ROOM_TAPPED_ID';
export const setRoomTappedId = makeActionCreator(SET_ROOM_TAPPED_ID, 'id');

export const SET_ROOM_SEARCH_TEXT = 'SET_ROOM_SEARCH_TEXT';
export const setRoomSearchText = makeActionCreator(SET_ROOM_SEARCH_TEXT, 'text');

export const SET_ROOM_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_ROOM_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setRoomAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_ROOM_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SAVING_ROOMS = 'SAVING_ROOMS';
export const savingRooms = makeActionCreator(SAVING_ROOMS, 'status');

export const SET_ROOM_SELECTED_LOCATION_ID = 'SET_ROOM_SELECTED_LOCATION_ID';
export const setRoomSelectedLocationId = makeActionCreator(SET_ROOM_SELECTED_LOCATION_ID, 'id');


export const DOWNLOADING_SESSIONS = 'DOWNLOADING_SESSIONS';
export const downloadingSessions = makeActionCreator(DOWNLOADING_SESSIONS, 'status');

export const SET_SESSIONS = 'SET_SESSIONS';
export const setSessions = makeActionCreator(SET_SESSIONS, 'data');

export const ADD_SESSION = 'ADD_SESSION';
export const addSession = makeActionCreator(ADD_SESSION, 'data');

export const CLEAR_SESSIONS = 'CLEAR_SESSIONS';
export const clearSessions = makeActionCreator(CLEAR_SESSIONS);

export const ADDING_EDITING_SESSION = 'ADDING_EDITING_SESSION';
export const addingEditingSession = makeActionCreator(ADDING_EDITING_SESSION, 'status');

export const DOWNLOADING_DELETING_SESSION = 'DOWNLOADING_DELETING_SESSION';
export const downloadingDeletingSession = makeActionCreator(DOWNLOADING_DELETING_SESSION, 'status');

export const ENABLING_DISABLING_SESSION = 'ENABLING_DISABLING_SESSION';
export const enablingDisablingSession = makeActionCreator(ENABLING_DISABLING_SESSION, 'status');

export const SET_SESSION_SELECTED_PAGE_SIZE = 'SET_SESSION_SELECTED_PAGE_SIZE';
export const setSessionSelectedPageSize = makeActionCreator(SET_SESSION_SELECTED_PAGE_SIZE, 'size');

export const SET_SESSION_SELECTED_ORDER_BY = 'SET_SESSION_SELECTED_ORDER_BY';
export const setSessionSelectedOrderBy = makeActionCreator(SET_SESSION_SELECTED_ORDER_BY, 'order');

export const SET_SESSION_TAPPED_ID = 'SET_SESSION_TAPPED_ID';
export const setSessionTappedId = makeActionCreator(SET_SESSION_TAPPED_ID, 'id');

export const SET_SESSION_SEARCH_TEXT = 'SET_SESSION_SEARCH_TEXT';
export const setSessionSearchText = makeActionCreator(SET_SESSION_SEARCH_TEXT, 'text');

export const SET_SESSION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_SESSION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setSessionAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_SESSION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_SESSIONS_SELECTED_ADD_MEDIA_MENU = 'SET_SESSIONS_SELECTED_ADD_MEDIA_MENU';
export const setSessionsSelectedAddMediaMenu = makeActionCreator(SET_SESSIONS_SELECTED_ADD_MEDIA_MENU, 'text');

export const SET_SESSION_SELECTED_VOUCHER = 'SET_SESSION_SELECTED_VOUCHER';
export const setSessionSelectedVoucher = makeActionCreator(SET_SESSION_SELECTED_VOUCHER, 'option');

export const SET_SESSION_SELECTED_CAN_PAY_POINT = 'SET_SESSION_SELECTED_CAN_PAY_POINT';
export const setSessionSelectedCanPayUsingPoint = makeActionCreator(SET_SESSION_SELECTED_CAN_PAY_POINT, 'option');

export const SET_SESSION_SELECTED_CATEGORY_NAME = 'SET_SESSION_SELECTED_CATEGORY_NAME';
export const setSessionSelectedCategoryName = makeActionCreator(SET_SESSION_SELECTED_CATEGORY_NAME, 'option');

export const SET_SESSION_SELECTED_ROOM = 'SET_SESSION_SELECTED_ROOM';
export const setSessionSelectedRoom = makeActionCreator(SET_SESSION_SELECTED_ROOM, 'option');

export const SET_SESSION_SELECTED_EVENT_NAME = 'SET_SESSION_SELECTED_EVENT_NAME';
export const setSessionSelectedEventName = makeActionCreator(SET_SESSION_SELECTED_EVENT_NAME, 'option');

export const SET_SESSION_SELECTED_FEATURED_OPTION = 'SET_SESSION_SELECTED_FEATURED_OPTION';
export const setSessionSelectedFeaturedEvent = makeActionCreator(SET_SESSION_SELECTED_FEATURED_OPTION, 'option');

export const SET_SESSION_SELECTED_NEED_APPROVAL = 'SET_SESSION_SELECTED_NEED_APPROVAL';
export const setSessionSelectedNeedApproval = makeActionCreator(SET_SESSION_SELECTED_NEED_APPROVAL, 'option');

export const SET_SESSION_SELECTED_REWARD_TYPE = 'SET_SESSION_SELECTED_REWARD_TYPE';
export const setSessionSelectedRewardType = makeActionCreator(SET_SESSION_SELECTED_REWARD_TYPE, 'option');

export const SET_SESSION_SELECTED_VISIBILITY_STATUS = 'SET_SESSION_SELECTED_VISIBILITY_STATUS';
export const setSessionSelectedVisibilityStatus = makeActionCreator(SET_SESSION_SELECTED_VISIBILITY_STATUS, 'option');

export const SET_SESSION_SELECTED_WHO_CAN_REGISTER = 'SET_SESSION_SELECTED_WHO_CAN_REGISTER';
export const setSessionSelectedWhoCanRegister = makeActionCreator(SET_SESSION_SELECTED_WHO_CAN_REGISTER, 'option');

export const SET_SESSION_SELECTED_CLOCK_INSIDE_AREA = 'SET_SESSION_SELECTED_CLOCK_INSIDE_AREA';
export const setSessionSelectedClockInsideArea = makeActionCreator(SET_SESSION_SELECTED_CLOCK_INSIDE_AREA, 'option');

export const SET_SESSION_TABLE_DATA_SPEAKERS = 'SET_SESSION_TABLE_DATA_SPEAKERS';
export const setSessionTableDataSpeakers = makeActionCreator(SET_SESSION_TABLE_DATA_SPEAKERS, 'option');

export const ADDING_SESSION_SPEAKERS = 'ADDING_SESSION_SPEAKERS';
export const addingSessionSpeakers = makeActionCreator(ADDING_SESSION_SPEAKERS, 'status');

export const SET_SESSION_SELECTED_SPEAKERS = 'SET_SESSION_SELECTED_SPEAKERS';
export const setSessionSelectedSpeakers = makeActionCreator(SET_SESSION_SELECTED_SPEAKERS, 'option');

export const SET_SESSION_SELECTED_TYPE = 'SET_SESSION_SELECTED_TYPE';
export const setSessionSelectedType = makeActionCreator(SET_SESSION_SELECTED_TYPE, 'option');

export const SET_SESSION_SELECTED_SURVEY_FORM = 'SET_SESSION_SELECTED_SURVEY_FORM';
export const setSessionSelectedSurveyForm = makeActionCreator(SET_SESSION_SELECTED_SURVEY_FORM, 'option');

export const SET_SESSION_SELECTED_ENABLE_COMMENT = 'SET_SESSION_SELECTED_ENABLE_COMMENT';
export const setSessionSelectedEnableComment = makeActionCreator(SET_SESSION_SELECTED_ENABLE_COMMENT, 'option');

export const SET_SESSION_BANNER_IMAGE = 'SET_SESSION_BANNER_IMAGE';
export const setSessionBannerImage = makeActionCreator(SET_SESSION_BANNER_IMAGE, 'image');

export const COPYING_SESSION_QR_CODE = 'COPYING_SESSION_QR_CODE';
export const copyingSessionQrCode = makeActionCreator(COPYING_SESSION_QR_CODE, 'status');

export const COPYING_SESSION_DEEP_LINK = 'COPYING_SESSION_DEEP_LINK';
export const copyingSessionDeepLink = makeActionCreator(COPYING_SESSION_DEEP_LINK, 'status');

export const SENDING_SESSION_NOTIFICATION = 'SENDING_SESSION_NOTIFICATION';
export const sendingSessionNotification = makeActionCreator(SENDING_SESSION_NOTIFICATION, 'status');


export const DOWNLOADING_EVENTS = 'DOWNLOADING_EVENTS';
export const downloadingEvents = makeActionCreator(DOWNLOADING_EVENTS, 'status');

export const SET_EVENTS = 'SET_EVENTS';
export const setEvents = makeActionCreator(SET_EVENTS, 'data');

export const ADD_EVENT = 'ADD_EVENT';
export const addEvent = makeActionCreator(ADD_EVENT, 'data');

export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const clearEvents = makeActionCreator(CLEAR_EVENTS);

export const ADDING_EDITING_EVENT = 'ADDING_EDITING_EVENT';
export const addingEditingEvent = makeActionCreator(ADDING_EDITING_EVENT, 'status');

export const DOWNLOADING_DELETING_EVENT = 'DOWNLOADING_DELETING_EVENT';
export const downloadingDeletingEvent = makeActionCreator(DOWNLOADING_DELETING_EVENT, 'status');

export const SET_EVENT_SELECTED_PAGE_SIZE = 'SET_EVENT_SELECTED_PAGE_SIZE';
export const setEventSelectedPageSize = makeActionCreator(SET_EVENT_SELECTED_PAGE_SIZE, 'size');

export const SET_EVENT_SELECTED_ORDER_BY = 'SET_EVENT_SELECTED_ORDER_BY';
export const setEventSelectedOrderBy = makeActionCreator(SET_EVENT_SELECTED_ORDER_BY, 'order');

export const SET_EVENT_TAPPED_ID = 'SET_EVENT_TAPPED_ID';
export const setEventTappedId = makeActionCreator(SET_EVENT_TAPPED_ID, 'id');

export const SET_EVENT_SEARCH_TEXT = 'SET_EVENT_SEARCH_TEXT';
export const setEventSearchText = makeActionCreator(SET_EVENT_SEARCH_TEXT, 'text');

export const SET_EVENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_EVENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setEventAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_EVENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_EVENT_CLOCK_IN_AREA_SELECTED = 'SET_EVENT_CLOCK_IN_AREA_SELECTED';
export const setEventClockInAreaSelected = makeActionCreator(SET_EVENT_CLOCK_IN_AREA_SELECTED, 'status');

export const SET_EVENT_REWARD_TYPE_SELECTED = 'SET_EVENT_REWARD_TYPE_SELECTED';
export const setEventRewardTypeSelected = makeActionCreator(SET_EVENT_REWARD_TYPE_SELECTED, 'text');

export const SET_EVENT_VOUCHER_SELECTED = 'SET_EVENT_VOUCHER_SELECTED';
export const setEventVoucherSelected = makeActionCreator(SET_EVENT_VOUCHER_SELECTED, 'data');

export const SET_EVENT_EVENT_TYPE_SELECTED = 'SET_EVENT_EVENT_TYPE_SELECTED';
export const setEventEventTypeSelected = makeActionCreator(SET_EVENT_EVENT_TYPE_SELECTED, 'text');

export const SET_EVENT_CATEGORY_SELECTED = 'SET_EVENT_CATEGORY_SELECTED';
export const setEventCategorySelected = makeActionCreator(SET_EVENT_CATEGORY_SELECTED, 'text');

export const SET_EVENT_LOCATION_SELECTED = 'SET_EVENT_LOCATION_SELECTED';
export const setEventLocationSelected = makeActionCreator(SET_EVENT_LOCATION_SELECTED, 'text');

export const SET_EVENT_FEATURE_SELECTED = 'SET_EVENT_FEATURE_SELECTED';
export const setEventFeatureSelected = makeActionCreator(SET_EVENT_FEATURE_SELECTED, 'status');

export const SET_EVENT_WHO_CAN_REGISTER_SELECTED = 'SET_EVENT_WHO_CAN_REGISTER_SELECTED';
export const setEventWhoCanRegisterSelected = makeActionCreator(SET_EVENT_WHO_CAN_REGISTER_SELECTED, 'text');

export const SET_EVENT_NEED_APPROVAL_SELECTED = 'SET_EVENT_NEED_APPROVAL_SELECTED';
export const setEventNeedApprovalSelected = makeActionCreator(SET_EVENT_NEED_APPROVAL_SELECTED, 'status');

export const SET_EVENT_CAN_PAY_USING_POINT_SELECTED = 'SET_EVENT_CAN_PAY_USING_POINT_SELECTED';
export const setEventCanPayUsingPointSelected = makeActionCreator(SET_EVENT_CAN_PAY_USING_POINT_SELECTED, 'status');

export const SET_EVENT_VISIBILITY_STATUS_SELECTED = 'SET_EVENT_VISIBILITY_STATUS_SELECTED';
export const setEventVisibilityStatusSelected = makeActionCreator(SET_EVENT_VISIBILITY_STATUS_SELECTED, 'text');

export const SET_EVENT_ADD_MEDIA_MENU_SELECTED = 'SET_EVENT_ADD_MEDIA_MENU_SELECTED';
export const setEventAddMediaMenuSelected = makeActionCreator(SET_EVENT_ADD_MEDIA_MENU_SELECTED, 'text');

export const SET_EVENT_SELECTED_ENABLE_COMMENT = 'SET_EVENT_SELECTED_ENABLE_COMMENT';
export const setEventSelectedEnableComment = makeActionCreator(SET_EVENT_SELECTED_ENABLE_COMMENT, 'option');

export const SET_EVENT_VOUCHER_SEARCH_TEXT = 'SET_EVENT_VOUCHER_SEARCH_TEXT';
export const setEventVoucherSearchText = makeActionCreator(SET_EVENT_VOUCHER_SEARCH_TEXT, 'text');

export const COPYING_EVENT_TEXT_FIELD = 'COPYING_EVENT_TEXT_FIELD';
export const copyingEventTextField = makeActionCreator(COPYING_EVENT_TEXT_FIELD, 'status');

export const ENABLING_DISABLING_EVENT = 'ENABLING_DISABLING_EVENT';
export const enablingDisablingEvent = makeActionCreator(ENABLING_DISABLING_EVENT, 'status');

export const SENDING_EVENT_NOTIFICATION = 'SENDING_EVENT_NOTIFICATION';
export const sendingEventNotification = makeActionCreator(SENDING_EVENT_NOTIFICATION, 'status');


export const DOWNLOADING_EVENT_INVITATIONS = 'DOWNLOADING_EVENT_INVITATIONS';
export const downloadingEventInvitations = makeActionCreator(DOWNLOADING_EVENT_INVITATIONS, 'status');

export const SET_EVENT_INVITATIONS = 'SET_EVENT_INVITATIONS';
export const setEventInvitations = makeActionCreator(SET_EVENT_INVITATIONS, 'data');

export const ADD_EVENT_INVITATION = 'ADD_EVENT_INVITATION';
export const addEventInvitation = makeActionCreator(ADD_EVENT_INVITATION, 'data');

export const CLEAR_EVENT_INVITATIONS = 'CLEAR_EVENT_INVITATIONS';
export const clearEventInvitations = makeActionCreator(CLEAR_EVENT_INVITATIONS);

export const ADDING_EVENT_INVITATION = 'ADDING_EVENT_INVITATION';
export const addingEventInvitation = makeActionCreator(ADDING_EVENT_INVITATION, 'status');

export const DOWNLOADING_DELETING_EVENT_INVITATION = 'DOWNLOADING_DELETING_EVENT_INVITATION';
export const downloadingDeletingEventInvitation = makeActionCreator(DOWNLOADING_DELETING_EVENT_INVITATION, 'status');

export const SAVING_EVENT_INVITATIONS = 'SAVING_EVENT_INVITATIONS';
export const savingEventInvitations = makeActionCreator(SAVING_EVENT_INVITATIONS, 'status');

export const SET_EVENT_INVITATION_SELECTED_PAGE_SIZE = 'SET_EVENT_INVITATION_SELECTED_PAGE_SIZE';
export const setEventInvitationSelectedPageSize = makeActionCreator(SET_EVENT_INVITATION_SELECTED_PAGE_SIZE, 'size');

export const SET_EVENT_INVITATION_SELECTED_ORDER_BY = 'SET_EVENT_INVITATION_SELECTED_ORDER_BY';
export const setEventInvitationSelectedOrderBy = makeActionCreator(SET_EVENT_INVITATION_SELECTED_ORDER_BY, 'order');

export const SET_EVENT_INVITATION_TAPPED_ID = 'SET_EVENT_INVITATION_TAPPED_ID';
export const setEventInvitationTappedId = makeActionCreator(SET_EVENT_INVITATION_TAPPED_ID, 'id');

export const SET_EVENT_INVITATION_SEARCH_TEXT = 'SET_EVENT_INVITATION_SEARCH_TEXT';
export const setEventInvitationSearchText = makeActionCreator(SET_EVENT_INVITATION_SEARCH_TEXT, 'text');

export const SET_EVENT_INVITATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_EVENT_INVITATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setEventInvitationAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_EVENT_INVITATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_EVENT_INVITATION_SELECTED_EVENT_ID = 'SET_EVENT_INVITATION_SELECTED_EVENT_ID';
export const setEventInvitationSelectedEventId = makeActionCreator(SET_EVENT_INVITATION_SELECTED_EVENT_ID, 'id');

export const SET_EVENT_INVITATION_SELECTED_RECIPIENT = 'SET_EVENT_INVITATION_SELECTED_RECIPIENT';
export const setEventInvitationSelectedRecipient = makeActionCreator(SET_EVENT_INVITATION_SELECTED_RECIPIENT, 'data');

export const RESENDING_EVENT_INVITATION = 'RESENDING_EVENT_INVITATION';
export const resendingEventInvitation = makeActionCreator(RESENDING_EVENT_INVITATION, 'status');


export const DOWNLOADING_SPONSORS = 'DOWNLOADING_SPONSORS';
export const downloadingSponsors = makeActionCreator(DOWNLOADING_SPONSORS, 'status');

export const SET_SPONSORS = 'SET_SPONSOR';
export const setSponsors = makeActionCreator(SET_SPONSORS, 'data');

export const ADD_SPONSOR = 'ADD_SPONSOR';
export const addSponsor = makeActionCreator(ADD_SPONSOR, 'data');

export const CLEAR_SPONSORS = 'CLEAR_SPONSORS';
export const clearSponsors = makeActionCreator(CLEAR_SPONSORS);

export const ADDING_EDITING_SPONSOR = 'ADDING_EDITING_SPONSOR';
export const addingEditingSponsor = makeActionCreator(ADDING_EDITING_SPONSOR, 'status');

export const DOWNLOADING_DELETING_SPONSOR = 'DOWNLOADING_DELETING_SPONSOR';
export const downloadingDeletingSponsor = makeActionCreator(DOWNLOADING_DELETING_SPONSOR, 'status');

export const SET_SPONSOR_SELECTED_PAGE_SIZE = 'SET_SPONSOR_SELECTED_PAGE_SIZE';
export const setSponsorSelectedPageSize = makeActionCreator(SET_SPONSOR_SELECTED_PAGE_SIZE, 'size');

export const SET_SPONSOR_SELECTED_ORDER_BY = 'SET_SPONSOR_SELECTED_ORDER_BY';
export const setSponsorSelectedOrderBy = makeActionCreator(SET_SPONSOR_SELECTED_ORDER_BY, 'order');

export const SET_SPONSOR_TAPPED_ID = 'SET_SPONSOR_TAPPED_ID';
export const setSponsorTappedId = makeActionCreator(SET_SPONSOR_TAPPED_ID, 'id');

export const SET_SPONSOR_SEARCH_TEXT = 'SET_SPONSOR_SEARCH_TEXT';
export const setSponsorSearchText = makeActionCreator(SET_SPONSOR_SEARCH_TEXT, 'text');

export const SET_SPONSOR_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_SPONSOR_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setSponsorAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_SPONSOR_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_SPONSOR_SELECTED_EVENT_ID = 'SET_SPONSOR_SELECTED_EVENT_ID';
export const setSponsorSelectedEventId = makeActionCreator(SET_SPONSOR_SELECTED_EVENT_ID, 'id');

export const SET_SPONSOR_SELECTED_COMPANY = 'SET_SPONSOR_SELECTED_COMPANY';
export const setSponsorSelectedCompany = makeActionCreator(SET_SPONSOR_SELECTED_COMPANY, 'data');

export const SET_SPONSOR_SELECTED_SPONSOR_CATEGORY = 'SET_SPONSOR_SELECTED_SPONSOR_CATEGORY';
export const setSponsorSelectedSponsorCategory = makeActionCreator(SET_SPONSOR_SELECTED_SPONSOR_CATEGORY, 'data');


export const DOWNLOADING_SESSION_INVITATIONS = 'DOWNLOADING_SESSION_INVITATIONS';
export const downloadingSessionInvitations = makeActionCreator(DOWNLOADING_SESSION_INVITATIONS, 'status');

export const SET_SESSION_INVITATIONS = 'SET_SESSION_INVITATIONS';
export const setSessionInvitations = makeActionCreator(SET_SESSION_INVITATIONS, 'data');

export const ADD_SESSION_INVITATION = 'ADD_SESSION_INVITATION';
export const addSessionInvitation = makeActionCreator(ADD_SESSION_INVITATION, 'data');

export const CLEAR_SESSION_INVITATIONS = 'CLEAR_SESSION_INVITATIONS';
export const clearSessionInvitations = makeActionCreator(CLEAR_SESSION_INVITATIONS);

export const ADDING_SESSION_INVITATION = 'ADDING_SESSION_INVITATION';
export const addingSessionInvitation = makeActionCreator(ADDING_SESSION_INVITATION, 'status');

export const DOWNLOADING_DELETING_SESSION_INVITATION = 'DOWNLOADING_DELETING_SESSION_INVITATION';
export const downloadingDeletingSessionInvitation = makeActionCreator(DOWNLOADING_DELETING_SESSION_INVITATION, 'status');

export const SET_SESSION_INVITATION_SELECTED_PAGE_SIZE = 'SET_SESSION_INVITATION_SELECTED_PAGE_SIZE';
export const setSessionInvitationSelectedPageSize = makeActionCreator(SET_SESSION_INVITATION_SELECTED_PAGE_SIZE, 'size');

export const SET_SESSION_INVITATION_SELECTED_ORDER_BY = 'SET_SESSION_INVITATION_SELECTED_ORDER_BY';
export const setSessionInvitationSelectedOrderBy = makeActionCreator(SET_SESSION_INVITATION_SELECTED_ORDER_BY, 'order');

export const SET_SESSION_INVITATION_TAPPED_ID = 'SET_SESSION_INVITATION_TAPPED_ID';
export const setSessionInvitationTappedId = makeActionCreator(SET_SESSION_INVITATION_TAPPED_ID, 'id');

export const SET_SESSION_INVITATION_SEARCH_TEXT = 'SET_SESSION_INVITATION_SEARCH_TEXT';
export const setSessionInvitationSearchText = makeActionCreator(SET_SESSION_INVITATION_SEARCH_TEXT, 'text');

export const SET_SESSION_INVITATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_SESSION_INVITATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setSessionInvitationAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_SESSION_INVITATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_SESSION_INVITATION_SELECTED_SESSION_ID = 'SET_SESSION_INVITATION_SELECTED_SESSION_ID';
export const setSessionInvitationSelectedSessionId = makeActionCreator(SET_SESSION_INVITATION_SELECTED_SESSION_ID, 'id');

export const SET_SESSION_INVITATION_SELECTED_RECIPIENT = 'SET_SESSION_INVITATION_SELECTED_RECIPIENT';
export const setSessionInvitationSelectedRecipient = makeActionCreator(SET_SESSION_INVITATION_SELECTED_RECIPIENT, 'data');

export const RESENDING_SESSION_INVITATION = 'RESENDING_SESSION_INVITATION';
export const resendingSessionInvitation = makeActionCreator(RESENDING_SESSION_INVITATION, 'status');

export const SAVING_SESSION_INVITATIONS = 'SAVING_SESSION_INVITATIONS';
export const savingSessionInvitations = makeActionCreator(SAVING_SESSION_INVITATIONS, 'status');


export const DOWNLOADING_EVENT_REGISTRATIONS = 'DOWNLOADING_EVENT_REGISTRATIONS';
export const downloadingEventRegistrations = makeActionCreator(DOWNLOADING_EVENT_REGISTRATIONS, 'status');

export const SET_EVENT_REGISTRATIONS = 'SET_EVENT_REGISTRATIONS';
export const setEventRegistrations = makeActionCreator(SET_EVENT_REGISTRATIONS, 'data');

export const ADD_EVENT_REGISTRATION = 'ADD_EVENT_REGISTRATION';
export const addEventRegistration = makeActionCreator(ADD_EVENT_REGISTRATION, 'data');

export const CLEAR_EVENT_REGISTRATIONS = 'CLEAR_EVENT_REGISTRATIONS';
export const clearEventRegistrations = makeActionCreator(CLEAR_EVENT_REGISTRATIONS);

export const ADDING_EVENT_REGISTRATION = 'ADDING_EVENT_REGISTRATION';
export const addingEventRegistration = makeActionCreator(ADDING_EVENT_REGISTRATION, 'status');

export const DOWNLOADING_DELETING_EVENT_REGISTRATION = 'DOWNLOADING_DELETING_EVENT_REGISTRATION';
export const downloadingDeletingEventRegistration = makeActionCreator(DOWNLOADING_DELETING_EVENT_REGISTRATION, 'status');

export const APPROVING_EVENT_REGISTRATION = 'APPROVING_EVENT_REGISTRATION';
export const approvingEventRegistration = makeActionCreator(APPROVING_EVENT_REGISTRATION, 'status');

export const REJECTING_EVENT_REGISTRATION = 'REJECTING_EVENT_REGISTRATION';
export const rejectingEventRegistration = makeActionCreator(REJECTING_EVENT_REGISTRATION, 'status');

export const SET_EVENT_REGISTRATION_SELECTED_PAGE_SIZE = 'SET_EVENT_REGISTRATION_SELECTED_PAGE_SIZE';
export const setEventRegistrationSelectedPageSize = makeActionCreator(SET_EVENT_REGISTRATION_SELECTED_PAGE_SIZE, 'size');

export const SET_EVENT_REGISTRATION_SELECTED_ORDER_BY = 'SET_EVENT_REGISTRATION_SELECTED_ORDER_BY';
export const setEventRegistrationSelectedOrderBy = makeActionCreator(SET_EVENT_REGISTRATION_SELECTED_ORDER_BY, 'order');

export const SET_EVENT_REGISTRATION_TAPPED_ID = 'SET_EVENT_REGISTRATION_TAPPED_ID';
export const setEventRegistrationTappedId = makeActionCreator(SET_EVENT_REGISTRATION_TAPPED_ID, 'id');

export const SET_EVENT_REGISTRATION_SEARCH_TEXT = 'SET_EVENT_REGISTRATION_SEARCH_TEXT';
export const setEventRegistrationSearchText = makeActionCreator(SET_EVENT_REGISTRATION_SEARCH_TEXT, 'text');

export const SET_EVENT_REGISTRATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_EVENT_REGISTRATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setEventRegistrationAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_EVENT_REGISTRATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SAVING_EVENT_REGISTRATIONS = 'SAVING_EVENT_REGISTRATIONS';
export const savingEventRegistrations = makeActionCreator(SAVING_EVENT_REGISTRATIONS, 'status');


export const DOWNLOADING_SESSION_REGISTRATIONS = 'DOWNLOADING_SESSION_REGISTRATIONS';
export const downloadingSessionRegistrations = makeActionCreator(DOWNLOADING_SESSION_REGISTRATIONS, 'status');

export const SET_SESSION_REGISTRATIONS = 'SET_SESSION_REGISTRATIONS';
export const setSessionRegistrations = makeActionCreator(SET_SESSION_REGISTRATIONS, 'data');

export const ADD_SESSION_REGISTRATION = 'ADD_SESSION_REGISTRATION';
export const addSessionRegistration = makeActionCreator(ADD_SESSION_REGISTRATION, 'data');

export const CLEAR_SESSION_REGISTRATIONS = 'CLEAR_SESSION_REGISTRATIONS';
export const clearSessionRegistrations = makeActionCreator(CLEAR_SESSION_REGISTRATIONS);

export const ADDING_SESSION_REGISTRATION = 'ADDING_SESSION_REGISTRATION';
export const addingSessionRegistration = makeActionCreator(ADDING_SESSION_REGISTRATION, 'status');

export const DOWNLOADING_DELETING_SESSION_REGISTRATION = 'DOWNLOADING_DELETING_SESSION_REGISTRATION';
export const downloadingDeletingSessionRegistration = makeActionCreator(DOWNLOADING_DELETING_SESSION_REGISTRATION, 'status');

export const APPROVING_SESSION_REGISTRATION = 'APPROVING_SESSION_REGISTRATION';
export const approvingSessionRegistration = makeActionCreator(APPROVING_SESSION_REGISTRATION, 'status');

export const REJECTING_SESSION_REGISTRATION = 'REJECTING_SESSION_REGISTRATION';
export const rejectingSessionRegistration = makeActionCreator(REJECTING_SESSION_REGISTRATION, 'status');

export const SET_SESSION_REGISTRATION_SELECTED_PAGE_SIZE = 'SET_SESSION_REGISTRATION_SELECTED_PAGE_SIZE';
export const setSessionRegistrationSelectedPageSize = makeActionCreator(SET_SESSION_REGISTRATION_SELECTED_PAGE_SIZE, 'size');

export const SET_SESSION_REGISTRATION_SELECTED_ORDER_BY = 'SET_SESSION_REGISTRATION_SELECTED_ORDER_BY';
export const setSessionRegistrationSelectedOrderBy = makeActionCreator(SET_SESSION_REGISTRATION_SELECTED_ORDER_BY, 'order');

export const SET_SESSION_REGISTRATION_TAPPED_ID = 'SET_SESSION_REGISTRATION_TAPPED_ID';
export const setSessionRegistrationTappedId = makeActionCreator(SET_SESSION_REGISTRATION_TAPPED_ID, 'id');

export const SET_SESSION_REGISTRATION_SEARCH_TEXT = 'SET_SESSION_REGISTRATION_SEARCH_TEXT';
export const setSessionRegistrationSearchText = makeActionCreator(SET_SESSION_REGISTRATION_SEARCH_TEXT, 'text');

export const SET_SESSION_REGISTRATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_SESSION_REGISTRATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setSessionRegistrationAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_SESSION_REGISTRATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SAVING_SESSION_REGISTRATIONS = 'SAVING_SESSION_REGISTRATIONS';
export const savingSessionRegistrations = makeActionCreator(SAVING_SESSION_REGISTRATIONS, 'status');

export const SET_EVENT_TABLE_DATA_SPEAKERS = 'SET_EVENT_TABLE_DATA_SPEAKERS';
export const setEventTableDataSpeakers = makeActionCreator(SET_EVENT_TABLE_DATA_SPEAKERS, 'option');

export const ADDING_EVENT_SPEAKERS = 'ADDING_EVENT_SPEAKERS';
export const addingEventSpeakers = makeActionCreator(ADDING_EVENT_SPEAKERS, 'status');

export const SET_EVENT_SELECTED_SPEAKERS = 'SET_EVENT_SELECTED_SPEAKERS';
export const setEventSelectedSpeakers = makeActionCreator(SET_EVENT_SELECTED_SPEAKERS, 'option');

export const SET_EVENT_SELECTED_SURVEY_FORM = 'SET_EVENT_SELECTED_SURVEY_FORM';
export const setEventSelectedSurveyForm = makeActionCreator(SET_EVENT_SELECTED_SURVEY_FORM, 'option');


export const DOWNLOADING_SESSION_ROOMS = 'DOWNLOADING_SESSION_ROOMS';
export const downloadingSessionRooms = makeActionCreator(DOWNLOADING_SESSION_ROOMS, 'status');

export const SET_SESSION_ROOMS = 'SET_SESSION_ROOMS';
export const setSessionRooms = makeActionCreator(SET_SESSION_ROOMS, 'data');

export const CLEAR_SESSION_ROOMS = 'CLEAR_SESSION_ROOMS';
export const clearSessionRooms = makeActionCreator(CLEAR_SESSION_ROOMS);
