import { downloadSessionInvitations, transformSearchText } from '../../../helper';
import { downloadingSessionInvitations, setSessionInvitations } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, sessionInvitations, uiSessionInvitation } = getState();
  const { token } = authentication;
  const { meta } = sessionInvitations;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiSessionInvitation;

  try {
    dispatch(downloadingSessionInvitations(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadSessionInvitations(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      searchBarText ? transformSearchText(searchBarText) : '',
      filterString,
      token,
    );

    if (list) {
      dispatch(setSessionInvitations(list));
    }
  } finally {
    dispatch(downloadingSessionInvitations(false));
  }
};
