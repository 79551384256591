import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_POINTS = 'DOWNLOADING_POINTS';
export const downloadingPoints = makeActionCreator(DOWNLOADING_POINTS, 'status');

export const SET_POINTS = 'SET_POINTS';
export const setPoints = makeActionCreator(SET_POINTS, 'data');

export const ADD_POINT = 'ADD_POINT';
export const addPoint = makeActionCreator(ADD_POINT, 'data');

export const CLEAR_POINTS = 'CLEAR_POINTS';
export const clearPoints = makeActionCreator(CLEAR_POINTS);

export const DOWNLOADING_DELETING_POINT = 'DOWNLOADING_DELETING_POINT';
export const downloadingDeletingPoint = makeActionCreator(DOWNLOADING_DELETING_POINT, 'status');

export const SET_POINT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_POINT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setPointAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_POINT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const SET_POINT_SELECTED_PAGE_SIZE = 'SET_POINT_SELECTED_PAGE_SIZE';
export const setPointSelectedPageSize = makeActionCreator(SET_POINT_SELECTED_PAGE_SIZE, 'size');

export const SET_POINT_SELECTED_ORDER_BY = 'SET_POINT_SELECTED_ORDER_BY';
export const setPointSelectedOrderBy = makeActionCreator(SET_POINT_SELECTED_ORDER_BY, 'order');

export const SET_POINT_TAPPED_ID = 'SET_POINT_TAPPED_ID';
export const setPointTappedId = makeActionCreator(SET_POINT_TAPPED_ID, 'id');

export const SET_POINT_SEARCH_TEXT = 'SET_POINT_SEARCH_TEXT';
export const setPointSearchText = makeActionCreator(SET_POINT_SEARCH_TEXT, 'text');


export const DOWNLOADING_POINT_SUBMISSIONS = 'DOWNLOADING_POINT_SUBMISSIONS';
export const downloadingPointSubmissions = makeActionCreator(DOWNLOADING_POINT_SUBMISSIONS, 'status');

export const SET_POINT_SUBMISSIONS = 'SET_POINT_SUBMISSIONS';
export const setPointSubmissions = makeActionCreator(SET_POINT_SUBMISSIONS, 'data');

export const ADD_POINT_SUBMISSION = 'ADD_POINT_SUBMISSION';
export const addPointSubmission = makeActionCreator(ADD_POINT_SUBMISSION, 'data');

export const CLEAR_POINT_SUBMISSIONS = 'CLEAR_POINT_SUBMISSIONS';
export const clearPointSubmissions = makeActionCreator(CLEAR_POINT_SUBMISSIONS);

export const DOWNLOADING_DELETING_POINT_SUBMISSION = 'DOWNLOADING_DELETING_POINT_SUBMISSION';
export const downloadingDeletingPointSubmission = makeActionCreator(DOWNLOADING_DELETING_POINT_SUBMISSION, 'status');

export const REPROCESSING_POINT_SUBMISSION = 'REPROCESSING_POINT_SUBMISSION';
export const reprocessingPointSubmission = makeActionCreator(REPROCESSING_POINT_SUBMISSION, 'status');

export const ADDING_EDITING_POINT_SUBMISSIONS = 'ADDING_EDITING_POINT_SUBMISSIONS';
export const addingEditingPointSubmissions = makeActionCreator(ADDING_EDITING_POINT_SUBMISSIONS, 'status');

export const SET_POINT_SUBMISSION_SELECTED_PAGE_SIZE = 'SET_POINT_SUBMISSION_SELECTED_PAGE_SIZE';
export const setPointSubmissionSelectedPageSize = makeActionCreator(SET_POINT_SUBMISSION_SELECTED_PAGE_SIZE, 'size');

export const SET_POINT_SUBMISSION_SELECTED_ORDER_BY = 'SET_POINT_SUBMISSION_SELECTED_ORDER_BY';
export const setPointSubmissionSelectedOrderBy = makeActionCreator(SET_POINT_SUBMISSION_SELECTED_ORDER_BY, 'order');

export const SET_POINT_SUBMISSION_SELECTED_USER = 'SET_POINT_SUBMISSION_SELECTED_USER';
export const setPointSubmissionSelectedUser = makeActionCreator(SET_POINT_SUBMISSION_SELECTED_USER, 'user');

export const SET_POINT_SUBMISSION_TAPPED_ID = 'SET_POINT_SUBMISSION_TAPPED_ID';
export const setPointSubmissionTappedId = makeActionCreator(SET_POINT_SUBMISSION_TAPPED_ID, 'id');

export const SET_POINT_SUBMISSION_SEARCH_TEXT = 'SET_POINT_SUBMISSION_SEARCH_TEXT';
export const setPointSubmissionSearchText = makeActionCreator(SET_POINT_SUBMISSION_SEARCH_TEXT, 'text');

export const SET_POINT_SUBMISSION_SEND_NOTIFICATION_OPTION = 'SET_POINT_SUBMISSION_SEND_NOTIFICATION_OPTION';
export const setPointSubmissionSendNotificationOption = makeActionCreator(SET_POINT_SUBMISSION_SEND_NOTIFICATION_OPTION, 'option');


export const DOWNLOADING_POINT_TRANSACTIONS = 'DOWNLOADING_POINT_TRANSACTIONS';
export const downloadingPointTransactions = makeActionCreator(DOWNLOADING_POINT_TRANSACTIONS, 'status');

export const SET_POINT_TRANSACTIONS = 'SET_POINT_TRANSACTIONS';
export const setPointTransactions = makeActionCreator(SET_POINT_TRANSACTIONS, 'data');

export const ADD_POINT_TRANSACTION = 'ADD_POINT_TRANSACTION';
export const addPointTransaction = makeActionCreator(ADD_POINT_TRANSACTION, 'data');

export const CLEAR_POINT_TRANSACTIONS = 'CLEAR_POINT_TRANSACTIONS';
export const clearPointTransactions = makeActionCreator(CLEAR_POINT_TRANSACTIONS);

export const DOWNLOADING_DELETING_POINT_TRANSACTION = 'DOWNLOADING_DELETING_POINT_TRANSACTION';
export const downloadingDeletingPointTransaction = makeActionCreator(DOWNLOADING_DELETING_POINT_TRANSACTION, 'status');


export const SET_POINT_TRANSACTION_SELECTED_PAGE_SIZE = 'SET_POINT_TRANSACTION_SELECTED_PAGE_SIZE';
export const setPointTransactionSelectedPageSize = makeActionCreator(SET_POINT_TRANSACTION_SELECTED_PAGE_SIZE, 'size');

export const SET_POINT_TRANSACTION_SELECTED_ORDER_BY = 'SET_POINT_TRANSACTION_SELECTED_ORDER_BY';
export const setPointTransactionSelectedOrderBy = makeActionCreator(SET_POINT_TRANSACTION_SELECTED_ORDER_BY, 'order');

export const SET_POINT_TRANSACTION_TAPPED_ID = 'SET_POINT_TRANSACTION_TAPPED_ID';
export const setPointTransactionTappedId = makeActionCreator(SET_POINT_TRANSACTION_TAPPED_ID, 'id');

export const SET_POINT_TRANSACTION_SEARCH_TEXT = 'SET_POINT_TRANSACTION_SEARCH_TEXT';
export const setPointTransactionSearchText = makeActionCreator(SET_POINT_TRANSACTION_SEARCH_TEXT, 'text');

export const SET_POINT_TRANSACTION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_POINT_TRANSACTION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setPointTransactionAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_POINT_TRANSACTION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_ACHIEVEMENT = 'DOWNLOADING_ACHIEVEMENT';
export const downloadingAchievement = makeActionCreator(DOWNLOADING_ACHIEVEMENT, 'status');

export const SET_ACHIEVEMENT = 'SET_ACHIEVEMENT';
export const setAchievement = makeActionCreator(SET_ACHIEVEMENT, 'data');

export const CLEAR_ACHIEVEMENT = 'CLEAR_ACHIEVEMENT';
export const clearAchievement = makeActionCreator(CLEAR_ACHIEVEMENT);


export const DOWNLOADING_BALANCE = 'DOWNLOADING_BALANCE';
export const downloadingBalance = makeActionCreator(DOWNLOADING_BALANCE, 'status');

export const SET_BALANCE = 'SET_BALANCE';
export const setBalance = makeActionCreator(SET_BALANCE, 'data');

export const CLEAR_BALANCE = 'CLEAR_BALANCE';
export const clearBalance = makeActionCreator(CLEAR_BALANCE);


export const SET_ACHIEVEMENT_TAPPED_ID = 'SET_ACHIEVEMENT_TAPPED_ID';
export const setAchievementTappedId = makeActionCreator(SET_ACHIEVEMENT_TAPPED_ID, 'id');


export const DOWNLOADING_LEADER_BOARD = 'DOWNLOADING_LEADER_BOARD';
export const downloadingLeaderBoard = makeActionCreator(DOWNLOADING_LEADER_BOARD, 'status');

export const SET_LEADER_BOARD = 'SET_LEADER_BOARD';
export const setLeaderBoard = makeActionCreator(SET_LEADER_BOARD, 'data');

export const CLEAR_LEADER_BOARD = 'CLEAR_LEADER_BOARD';
export const clearLeaderBoard = makeActionCreator(CLEAR_LEADER_BOARD);


export const DOWNLOADING_PURCHASE_REQUESTS = 'DOWNLOADING_PURCHASE_REQUESTS';
export const downloadingPurchaseRequests = makeActionCreator(DOWNLOADING_PURCHASE_REQUESTS, 'status');

export const SET_PURCHASE_REQUESTS = 'SET_PURCHASE_REQUESTS';
export const setPurchaseRequests = makeActionCreator(SET_PURCHASE_REQUESTS, 'data');

export const ADD_PURCHASE_REQUEST = 'ADD_PURCHASE_REQUEST';
export const addPurchaseRequest = makeActionCreator(ADD_PURCHASE_REQUEST, 'data');

export const CLEAR_PURCHASE_REQUESTS = 'CLEAR_PURCHASE_REQUESTS';
export const clearPurchaseRequests = makeActionCreator(CLEAR_PURCHASE_REQUESTS);

export const DOWNLOADING_PURCHASE_REQUEST = 'DOWNLOADING_PURCHASE_REQUEST';
export const downloadingPurchaseRequest = makeActionCreator(DOWNLOADING_PURCHASE_REQUEST, 'status');


export const SET_PURCHASE_REQUEST_SELECTED_PAGE_SIZE = 'SET_PURCHASE_REQUEST_SELECTED_PAGE_SIZE';
export const setPurchaseRequestSelectedPageSize = makeActionCreator(SET_PURCHASE_REQUEST_SELECTED_PAGE_SIZE, 'size');

export const SET_PURCHASE_REQUEST_SELECTED_ORDER_BY = 'SET_PURCHASE_REQUEST_SELECTED_ORDER_BY';
export const setPurchaseRequestSelectedOrderBy = makeActionCreator(SET_PURCHASE_REQUEST_SELECTED_ORDER_BY, 'order');

export const SET_PURCHASE_REQUEST_TAPPED_ID = 'SET_PURCHASE_REQUEST_TAPPED_ID';
export const setPurchaseRequestTappedId = makeActionCreator(SET_PURCHASE_REQUEST_TAPPED_ID, 'id');

export const SET_PURCHASE_REQUEST_SEARCH_TEXT = 'SET_PURCHASE_REQUEST_SEARCH_TEXT';
export const setPurchaseRequestSearchText = makeActionCreator(SET_PURCHASE_REQUEST_SEARCH_TEXT, 'text');


export const DOWNLOADING_VOID_POINT_REQUESTS = 'DOWNLOADING_VOID_POINT_REQUESTS';
export const downloadingVoidPointRequests = makeActionCreator(DOWNLOADING_VOID_POINT_REQUESTS, 'status');

export const SET_VOID_POINT_REQUESTS = 'SET_VOID_POINT_REQUESTS';
export const setVoidPointRequests = makeActionCreator(SET_VOID_POINT_REQUESTS, 'data');

export const ADD_VOID_POINT_REQUEST = 'ADD_VOID_POINT_REQUEST';
export const addVoidPointRequest = makeActionCreator(ADD_VOID_POINT_REQUEST, 'data');

export const CLEAR_VOID_POINT_REQUESTS = 'CLEAR_VOID_POINT_REQUESTS';
export const clearVoidPointRequests = makeActionCreator(CLEAR_VOID_POINT_REQUESTS);

export const DOWNLOADING_VOID_POINT_REQUEST = 'DOWNLOADING_VOID_POINT_REQUEST';
export const downloadingVoidPointRequest = makeActionCreator(DOWNLOADING_VOID_POINT_REQUEST, 'status');

export const ADDING_VOID_POINT_REQUEST = 'ADDING_VOID_POINT_REQUEST';
export const addingVoidPointRequest = makeActionCreator(ADDING_VOID_POINT_REQUEST, 'status');


export const SET_VOID_POINT_REQUEST_SELECTED_PAGE_SIZE = 'SET_VOID_POINT_REQUEST_SELECTED_PAGE_SIZE';
export const setVoidPointRequestSelectedPageSize = makeActionCreator(SET_VOID_POINT_REQUEST_SELECTED_PAGE_SIZE, 'size');

export const SET_VOID_POINT_REQUEST_SELECTED_ORDER_BY = 'SET_VOID_POINT_REQUEST_SELECTED_ORDER_BY';
export const setVoidPointRequestSelectedOrderBy = makeActionCreator(SET_VOID_POINT_REQUEST_SELECTED_ORDER_BY, 'order');

export const SET_VOID_POINT_REQUEST_TAPPED_ID = 'SET_VOID_POINT_REQUEST_TAPPED_ID';
export const setVoidPointRequestTappedId = makeActionCreator(SET_VOID_POINT_REQUEST_TAPPED_ID, 'id');

export const SET_VOID_POINT_REQUEST_SEARCH_TEXT = 'SET_VOID_POINT_REQUEST_SEARCH_TEXT';
export const setVoidPointRequestSearchText = makeActionCreator(SET_VOID_POINT_REQUEST_SEARCH_TEXT, 'text');

export const SET_VOID_POINT_REQUEST_SELECTED_POINT = 'SET_VOID_POINT_REQUEST_SELECTED_POINT';
export const setVoidPointRequestSelectedPoint = makeActionCreator(SET_VOID_POINT_REQUEST_SELECTED_POINT, 'option');

export const SET_VOID_POINT_DIALOG = 'SET_VOID_POINT_DIALOG';
export const showVoidPointDialog = makeActionCreator(SET_VOID_POINT_DIALOG, 'value');

export const SET_VOIDING_POINT = 'SET_VOIDING_POINT';
export const setVoidingPoint = makeActionCreator(SET_VOIDING_POINT, 'status');

export const SET_VOID_POINT_DIALOG_SELECTED_SEND_NOTIFICATION = 'SET_VOID_POINT_DIALOG_SELECTED_SEND_NOTIFICATION';
export const setVoidPointDialogSelectedSendNotification = makeActionCreator(SET_VOID_POINT_DIALOG_SELECTED_SEND_NOTIFICATION, 'value');
