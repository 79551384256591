import React, {
  useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import {
  GoogleMap, LoadScript, Marker, MarkerClusterer, InfoWindow,
} from '@react-google-maps/api';
import {
  COLOR_BACKGROUND, ENUM_FIELD_PHOTO, ENUM_FIELD_TEXT, GOOGLE_MAP_API_KEY, GOOGLE_MAP_URL_PREFIX,
  MAP_ZOOM_LEVEL_IN_KM,
} from '../constant';
import { sortIntAsc, sortIntDesc } from '../helper/helper';
import LocalizedString from '../localization';
import { ClockInOutAreaShape, PointShape } from '../type';

const LIBRARIES = ['geometry'];
const MAX_CLUSTER_ZOOM = 19;

const useStyles = makeStyles(() => ({
  title: {
    backgroundColor: COLOR_BACKGROUND,
    maxWidth: '200px',
    whiteSpace: 'pre-line',
    marginTop: '-30px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
  },
  infoWindowContainer: {
    maxWidth: '240px',
    maxHeight: '167px',
  },
  infoWindowDataContainer: {
    marginBottom: 8,
  },
  boldText: {
    fontWeight: 'bold',
  },
  photoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  photo: {
    width: '100%',
    height: '50%',
    aspectRatio: 1 / 1,
    resizeMode: 'contain',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  rightText: {
    marginLeft: '8px',
    textAlign: 'right',
  },
}));

const mapContainerStyle = {
  width: '100%',
  height: '650px',
};

const googleMapButtonStyle = {
  textDecoration: 'none',
  textDecorationColor: 'none',
  color: '#1A73E8',
  fontSize: '13px',
  fontFamily: 'Roboto,Arial',
  marginTop: '2px',
};

const renderActivityIndicator = () => (<CircularProgress color="inherit" />);

const renderInfoWindowBody = (body, classes) => body
  .map((item) => (item.type === ENUM_FIELD_PHOTO ? (
    <div className={classes.photoContainer}>
      <Typography variant="caption" className={classes.boldText}>
        {item.label}
      </Typography>
      <img alt="additional-field" src={item.value} className={classes.photo} />
    </div>
  ) : (
    <div className={classes.rowContainer}>
      <Typography variant="caption" className={classes.boldText}>
        {item.label}
      </Typography>
      <Typography variant="caption" className={classes.rightText}>
        {item.value || '-'}
      </Typography>
    </div>
  )));

const renderInfoWindowData = (infoWindowData, classes) => infoWindowData.map((item, index) => (
  <div className={classes.infoWindowDataContainer}>
    <Typography variant={index === 0 ? 'body2' : 'caption'} className={classes.boldText}>
      {item.title}
    </Typography>
    {renderInfoWindowBody(item.body, classes)}
  </div>
));

const renderInfoWindowButton = (item) => (
  <a target="_blank" rel="noopener noreferrer" jstcache="6" href={GOOGLE_MAP_URL_PREFIX.replace(/\{latitude\}/, item.position.lat).replace(/\{longitude\}/, item.position.lng)} style={googleMapButtonStyle}>
    <span>{LocalizedString.common.buttonCaptionViewOnGoogleMaps}</span>
  </a>
);

const renderInfoWindowContent = (infoWindowData, infoWindowChildren, item, classes) => {
  if (infoWindowData.length && infoWindowChildren) {
    return (
      <div>
        {renderInfoWindowData(infoWindowData, classes)}
        {infoWindowChildren}
        {renderInfoWindowButton(item)}
      </div>
    );
  }
  if (infoWindowData.length && !infoWindowChildren) {
    return (
      <div>
        {renderInfoWindowData(infoWindowData, classes)}
        {renderInfoWindowButton(item)}
      </div>
    );
  }
  if (!infoWindowData.length && infoWindowChildren) {
    return (
      <div>
        {infoWindowChildren}
        {renderInfoWindowButton(item)}
      </div>
    );
  }
  return null;
};

const getZoomLevel = (lat, lng) => {
  const distanceInKm = lat > lng ? lat : lng;
  const roundedDistance = Math.round(distanceInKm);
  let result;
  for (let i = 0; i < MAP_ZOOM_LEVEL_IN_KM.length; i += 1) {
    if (roundedDistance <= MAP_ZOOM_LEVEL_IN_KM[i].distance) {
      result = MAP_ZOOM_LEVEL_IN_KM[i].level + 1;
    }
  }
  const lastLevel = MAP_ZOOM_LEVEL_IN_KM[MAP_ZOOM_LEVEL_IN_KM.length - 1].level;
  return result === lastLevel + 1 ? lastLevel : result;
};

const MapMarkerClusterer = ({
  center, infoWindowData, markers,
  disabled, hidden, loading, infoWindowLoading,
  onMarkerPressed,
  infoWindowChildren,
  zoom,
  containerStyle,
}) => {
  const classes = useStyles();
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [data, setData] = useState({ center, markers, zoom });
  const [dialogVisibility, setDialogVisibility] = useState({ status: false, id: null });
  const [map, setMap] = useState();
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    const loadMap = () => {
      if (!loading && center && markers.length && window.google) {
        const coordinates = {
          lat: [],
          lng: [],
        };

        markers.forEach((item) => {
          coordinates.lat.push(Number(item.position.lat));
          coordinates.lng.push(Number(item.position.lng));
        });

        const sortLat = markers.sort((a, b) => sortIntDesc(a.position.lat, b.position.lat))
          .map((x) => x.position);
        const sortLng = markers.sort((a, b) => sortIntAsc(a.position.lng, b.position.lng))
          .map((x) => x.position);

        // latitude horizontal
        const west = sortLat[0]; // min
        const east = sortLat[markers.length - 1]; // max

        // longitude vertical
        const north = sortLng[0]; // min
        const south = sortLng[markers.length - 1]; // max

        const averageLat = (west.lat + east.lat) / 2;
        const averageLng = (north.lng + south.lng) / 2;

        const { geometry: { spherical: { computeDistanceBetween } }, LatLng } = window.google.maps;

        const minLat = new LatLng(west.lat, west.lng);
        const maxLat = new LatLng(east.lat, east.lng);
        const minLng = new LatLng(north.lat, north.lng);
        const maxLng = new LatLng(south.lat, south.lng);

        const distanceLatInMeters = computeDistanceBetween(minLat, maxLat);
        const distanceLngInMeters = computeDistanceBetween(minLng, maxLng);

        const distanceLatInKm = distanceLatInMeters / 1000;
        const distanceLngInKm = distanceLngInMeters / 1000;

        const mapCenter = map?.getCenter();
        const isPositionChanged = mapCenter?.lat() && mapCenter?.lng();

        setData({
          center: isPositionChanged
            ? { lat: mapCenter?.lat() || 0, lng: mapCenter?.lng() || 0 }
            : { lat: averageLat, lng: averageLng } || center,
          markers,
          zoom: isPositionChanged
            ? map?.getZoom()
            : getZoomLevel(distanceLatInKm, distanceLngInKm) || zoom,
        });
        setMapLoaded(true);
      } else {
        const mapCenter = map?.getCenter();
        const isPositionChanged = mapCenter?.lat() && mapCenter?.lng();

        setData({
          center: isPositionChanged
            ? { lat: mapCenter?.lat() || 0, lng: mapCenter?.lng() || 0 } : center,
          markers,
          zoom: isPositionChanged ? map?.getZoom() : zoom,
        });
        setMapLoaded(true);
      }
    };

    const interval = setInterval(() => {
      loadMap();
      clearInterval(interval);
    }, 1000);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [loading, center, markers, zoom, map]);

  if (loading) {
    return renderActivityIndicator();
  }
  if (!hidden && mapLoaded) {
    return (
      <LoadScript libraries={LIBRARIES} googleMapsApiKey={GOOGLE_MAP_API_KEY}>
        {data.markers.length ? (
          <GoogleMap
            mapContainerStyle={{ ...mapContainerStyle, ...containerStyle }}
            center={data.center}
            zoom={data.zoom}
            onLoad={(currMap) => { setMap(currMap); mapRef.current = currMap; }}
            ref={mapRef}
          >
            <MarkerClusterer
              zoomOnClick={false}
              onClick={async (cluster) => {
                const clusterCenter = await cluster?.getCenter();
                setData({
                  ...data,
                  center: { lat: clusterCenter.lat(), lng: clusterCenter.lng() },
                  zoom: map?.getZoom() + 2,
                });
              }}
              maxZoom={map?.getZoom() >= MAX_CLUSTER_ZOOM ? MAX_CLUSTER_ZOOM : null}
            >
              {(clusterer) => (
                <div>
                  {data.markers.length && data.markers.map((item) => (
                    <Marker
                      onClick={() => {
                        setDialogVisibility({ status: true, id: item.id });
                        map.setCenter(item.position);
                        onMarkerPressed(item);
                      }}
                      ref={markerRef}
                      disabled={disabled}
                      key={item.id}
                      position={item.position}
                      label={{
                        text: item?.title,
                        className: classes.title,
                      }}
                      clusterer={clusterer}
                    >
                      {dialogVisibility.status && dialogVisibility.id === item.id && (
                        <InfoWindow
                          onCloseClick={() => setDialogVisibility({ status: false, id: item.id })}
                          position={item.position}
                          options={{ pixelOffset: new window.google.maps.Size(0, -20) }}
                        >
                          <div className={classes.infoWindowContainer}>
                            {infoWindowLoading
                              ? (renderActivityIndicator())
                              : (renderInfoWindowContent(infoWindowData, infoWindowChildren,
                                item, classes))}
                          </div>
                        </InfoWindow>
                      )}
                    </Marker>
                  ))}
                </div>
              )}
            </MarkerClusterer>
          </GoogleMap>
        ) : (
          <GoogleMap
            mapContainerStyle={{ ...mapContainerStyle, ...containerStyle }}
            center={data.center}
            zoom={data.zoom}
            onLoad={(currMap) => { setMap(currMap); mapRef.current = currMap; }}
            ref={mapRef}
          />
        )}
      </LoadScript>
    );
  }
  return null;
};

const SimpleDataShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.oneOf([ENUM_FIELD_PHOTO, ENUM_FIELD_TEXT]),
});

const InfoWindowDataShape = PropTypes.shape({
  title: PropTypes.string,
  body: PropTypes.arrayOf(SimpleDataShape),
});

MapMarkerClusterer.propTypes = {
  center: PointShape,
  markers: PropTypes.arrayOf(ClockInOutAreaShape),
  infoWindowData: PropTypes.arrayOf(InfoWindowDataShape),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  infoWindowLoading: PropTypes.bool,
  hidden: PropTypes.bool,
  onMarkerPressed: PropTypes.func,
  infoWindowChildren: PropTypes.node,
  zoom: PropTypes.number,
  containerStyle: PropTypes.objectOf(PropTypes.string),
};

MapMarkerClusterer.defaultProps = {
  center: { lat: 0, lng: 0 },
  markers: [],
  infoWindowData: [],
  disabled: false,
  hidden: false,
  loading: false,
  infoWindowLoading: false,
  onMarkerPressed: () => {},
  infoWindowChildren: <div />,
  zoom: 2,
  containerStyle: {},
};

export default MapMarkerClusterer;
