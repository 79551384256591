import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_TOKEN_IMPORTS, MENUID_VOUCHER_TOKEN_IMPORT,
  PAGE_MODE_TABLE, RXFIELD_TOKEN_IMPORT_VOUCHER, RXFORM_TOKEN_IMPORT,
} from '../../constant';
import {
  getPermission,
  transformDropdownData, transformInitialValues,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import {
  clearCompaniesMData, clearTokenImports, clearVouchers, setActiveSideMenuItem,
  setAdvancedFilterDialogSelectedFilterString, setAlertConfirmationMessage, setAlertErrorMessage,
  setCompanyAdvancedFilterDialogSelectedFilterStringMData, setCompanySearchTextMData,
  setTokenImportSearchText, setTokenImportSelectedCsv, setTokenImportSelectedOrderBy,
  setTokenImportSelectedPageSize, setTokenImportTappedId, setTokenImportSelectedVoucher,
  setVoucherAdvancedFilterDialogSelectedFilterString, setVoucherSearchText,
  downloadDeleteTokenImportAsync, downloadDeleteTokenImportsAsync, downloadVouchersAsync,
  importEditTokenImportAsync, retryTokenImportAsync,
  saveVoucherTokenImportsAsync,
} from '../../redux/action';
import { downloadMDataCompaniesAsync } from '../../../../redux/action';
import TokenImportPage from './token-import.presentation';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getInitialValues = (state) => {
  const { uiFunctionalPage, uiTokenImport, tokenImports } = state;
  const { data } = tokenImports;
  const { downloadingDeleting, canceling, tappedId } = uiTokenImport;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting && !canceling
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    voucherId: found.voucher?.id || '',
    voucherName: found.voucher?.title || '',
    merchantName: found.voucher?.merchant?.name || '',
  }) : {
    voucherId: '',
    voucherName: '',
    validFrom: '',
    validUntil: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  merchants: transformDropdownData(state.companiesMdata.data),
  vouchers: Object.values(state.vouchers.data)
    .map((item) => ({ label: item.title, value: item.id })),
  addingEditing: state.uiTokenImport.addingEditing,
  downloading: state.uiTokenImport.downloading,
  downloadingDeleting: state.uiTokenImport.downloadingDeleting,
  isRetryAllowed: getPermission(state, 'VOUCHER_RETRY_VOUCHER_IMPORT'),
  loadingMerchant: state.uiCompany.downloading,
  loadingVoucher: state.uiVoucher.downloading,
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData(''));
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setCompanySearchTextMData(''));
    dispatch(setVoucherSearchText(''));
    dispatch(clearCompaniesMData());
    dispatch(clearVouchers());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadVouchersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_VOUCHER_TOKEN_IMPORT));
    dispatch(setTokenImportSearchText(''));
    dispatch(clearTokenImports());
    dispatch(setTokenImportSelectedPageSize(20));
    dispatch(setTokenImportSelectedOrderBy(INITIAL_ORDER_BY_TOKEN_IMPORTS));
    dispatch(downloadDeleteTokenImportsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/voucher/, 'voucher.id').replace(/merchant/, 'voucher.merchant.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearTokenImports());
    dispatch(downloadDeleteTokenImportsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(setTokenImportSelectedCsv(''));
    dispatch(reset(RXFORM_TOKEN_IMPORT));
  },
  onChangeMerchantText: async (text) => {
    try {
      dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData(''));
      dispatch(setCompanySearchTextMData(text));
      dispatch(clearCompaniesMData());
      await dispatch(downloadMDataCompaniesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadDeleteTokenImportsAsync(pageNo + 1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setTokenImportSelectedPageSize(pageSize));
    dispatch(downloadDeleteTokenImportsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangeVoucherText: async (text, changeAdvanceFilter) => {
    try {
      dispatch(setVoucherAdvancedFilterDialogSelectedFilterString(
        changeAdvanceFilter ? '' : 'status=enabled',
      ));
      dispatch(setVoucherSearchText(text));
      dispatch(clearVouchers());
      await dispatch(downloadVouchersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case LocalizedString.tokenImportPage.msgRetryImportConfirmation:
        dispatch(retryTokenImportAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      case LocalizedString.tokenImportPage.msgDeleteFilteredDataConfirmation:
        dispatch(clearTokenImports());
        dispatch(downloadDeleteTokenImportsAsync(1, true))
          .catch((error) => dispatch(setAlertErrorMessage(error)));
        break;
      default:
        dispatch(downloadDeleteTokenImportAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onCreatePressed: () => {
    dispatch(setTokenImportTappedId(''));
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCsvFileSelected: (base64) => {
    dispatch(setTokenImportSelectedCsv(base64));
  },
  onDeleteFilteredData: () => {
    dispatch(setAlertConfirmationMessage(LocalizedString.tokenImportPage
      .msgDeleteFilteredDataConfirmation));
  },
  onDeletePressed: (id) => {
    dispatch(setTokenImportTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onDownloadPressed: () => {
    dispatch(saveVoucherTokenImportsAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onEditPressed: (id) => {
    dispatch(setTokenImportTappedId(id));
    dispatch(downloadDeleteTokenImportAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setTokenImportSelectedPageSize(pageSize));
    dispatch(clearTokenImports());
    dispatch(downloadDeleteTokenImportsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearTokenImports());
    dispatch(setTokenImportSelectedOrderBy(INITIAL_ORDER_BY_TOKEN_IMPORTS));
    dispatch(downloadDeleteTokenImportsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRetryPressed: (id) => {
    dispatch(setTokenImportTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.tokenImportPage
      .msgRetryImportConfirmation));
  },
  onSavePressed: async ({ code, validFrom, validUntil }) => {
    try {
      await dispatch(importEditTokenImportAsync(code, validFrom, validUntil));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: (text) => {
    dispatch(setTokenImportSearchText(text));
    dispatch(clearTokenImports());
    dispatch(downloadDeleteTokenImportsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSortPressed: (orderBy) => {
    dispatch(setTokenImportSelectedOrderBy(orderBy));
    dispatch(clearTokenImports());
    dispatch(downloadDeleteTokenImportsAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSubmitPressed: async () => {
    await dispatch(importEditTokenImportAsync());
  },
  onViewPressed: (id) => {
    dispatch(setTokenImportTappedId(id));
    dispatch(downloadDeleteTokenImportAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onVoucherOptionSelected: (option) => {
    if (option) {
      dispatch(change(RXFORM_TOKEN_IMPORT, RXFIELD_TOKEN_IMPORT_VOUCHER, option));
      dispatch(setTokenImportSelectedVoucher(option.value));
    } else {
      dispatch(change(RXFORM_TOKEN_IMPORT, RXFIELD_TOKEN_IMPORT_VOUCHER, ''));
      dispatch(setTokenImportSelectedVoucher(''));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TokenImportPage);
