import React from 'react';
import {
  Avatar, CircularProgress, Grid, TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  FunctionalPage, DropzonePicker, NumberTextFieldWithoutArrowDial, SectionTitle,
} from '../../component';
import {
  CONTENT_FORMAT_HTML, CONTENTS_FORMATS, FILTER_TYPE_DROPDOWN, FILTER_TYPE_SWITCH,
  IMAGE_SOURCE_URI_PREFIX, PAGE_MODE_VIEW, REST_BASE_URL, REST_URL_IMAGE_STORAGE,
  RXFIELD_FORUM_POST_AUTHOR, RXFIELD_FORUM_POST_CATEGORY, RXFIELD_FORUM_POST_CONTENT,
  RXFIELD_FORUM_POST_CONTENT_FORMAT, RXFIELD_FORUM_POST_LOCKED, RXFIELD_FORUM_POST_MEDIA,
  RXFIELD_FORUM_POST_PINNED, RXFIELD_FORUM_POST_SLUG, RXFIELD_FORUM_POST_STATUS,
  RXFIELD_FORUM_POST_TITLE, RXFORM_FORUM_POST, RXSTATE_FORUM_POSTS, RXSTATE_FORUM_POST_PAGE,
  status, switches, ADVANCED_FILTER_MODE,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormEditableTableField, renderReduxFormOutlinedDropdownTextField,
  renderReduxFormOutlinedTextField, renderReduxFormRichTextEditorField,
  renderReduxFormSimpleDropdownField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxformValidateFormPost } from '../../validation';

const renderDialogContent = (initialValues, forumCategories, addingEditing, downloadingDeleting,
  loadingCategory, onAddMediaPressed, onAddMediaMenuSelected, onCategoryOptionSelected,
  onChangeCategoryText, onDeleteMediaPressed, onLockedOptionSelected, onPinnedOptionSelected,
  pageMode, selectedAddMediaMenu, selectedContentFormat, onContentFormatSelected,
  onChangeContentText) => (
    <Grid>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_FORUM_POST_TITLE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.forumPostPage.placeholderTitle}
              label={LocalizedString.forumPostPage.placeholderTitle}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              multiline
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_FORUM_POST_SLUG}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.forumPostPage.placeholderSlug}
              label={LocalizedString.forumPostPage.placeholderSlug}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              multiline
              hidden={pageMode !== PAGE_MODE_VIEW}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_FORUM_POST_CONTENT_FORMAT}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.forumPostPage.placeholderContentFormat}
              label={LocalizedString.forumPostPage.placeholderContentFormat}
              data={CONTENTS_FORMATS}
              onOptionSelected={onContentFormatSelected}
              value={initialValues.contentFormat}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            />
          </Grid>
          <Grid item>
            {selectedContentFormat === CONTENT_FORMAT_HTML ? (
              <Field
                name={RXFIELD_FORUM_POST_CONTENT}
                component={renderReduxFormRichTextEditorField}
                placeholder={LocalizedString.forumPostPage.placeholderContent}
                label={LocalizedString.forumPostPage.placeholderContent}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                loading={downloadingDeleting}
                onChangeText={onChangeContentText}
                required
              />
            ) : (
              <Field
                name={RXFIELD_FORUM_POST_CONTENT}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.forumPostPage.placeholderContent}
                label={LocalizedString.forumPostPage.placeholderContent}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                multiline
                required
              />
            )}
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_FORUM_POST_CATEGORY}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.forumPostPage.placeholderCategory}
              label={LocalizedString.forumPostPage.placeholderCategory}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={forumCategories}
              value={initialValues.category}
              loading={loadingCategory}
              onChangeText={onChangeCategoryText}
              onOptionSelected={onCategoryOptionSelected}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_FORUM_POST_AUTHOR}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.forumPostPage.placeholderAuthor}
              label={LocalizedString.forumPostPage.placeholderAuthor}
              disabled
              hidden={pageMode !== PAGE_MODE_VIEW}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_FORUM_POST_LOCKED}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.forumPostPage.placeholderLocked}
              label={LocalizedString.forumPostPage.placeholderLocked}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={switches}
              value={initialValues.locked}
              onOptionSelected={onLockedOptionSelected}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_FORUM_POST_PINNED}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.forumPostPage.placeholderPinned}
              label={LocalizedString.forumPostPage.placeholderPinned}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={switches}
              value={initialValues.pinned}
              onOptionSelected={onPinnedOptionSelected}
            />
          </Grid>
        </Grid>
      </Grid>

      <SectionTitle title={LocalizedString.forumPostPage.labelMedia} />

      {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_FORUM_POST_MEDIA}
            component={renderReduxFormEditableTableField}
            label={LocalizedString.forumPostPage.labelMedia}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            onAddPressed={onAddMediaPressed}
            onDeletePressed={onDeleteMediaPressed}
            defaultValue={initialValues.media}
            addMenuList={[
              {
                caption: LocalizedString.forumPostPage.buttonCaptionYoutube,
                onPress: () => onAddMediaMenuSelected('Youtube'),
              },
              {
                caption: LocalizedString.forumPostPage.buttonCaptionImage,
                onPress: () => onAddMediaMenuSelected('Image'),
              },
            ]}
            requiredTableColumnField={['order']}
            optionalTableColumnField={['content', 'link']}
            disableEdit
            disableToolbar={pageMode === PAGE_MODE_VIEW}
            tableColumns={[
              {
                title: LocalizedString.forumPostPage.labelOrder,
                field: 'order',
                sorting: false,
                editable: 'onAdd',
                // eslint-disable-next-line react/prop-types
                editComponent: ({ value, onChange }) => (
                  <NumberTextFieldWithoutArrowDial
                    placeholder={LocalizedString.forumPostPage.labelOrder}
                    label={LocalizedString.forumPostPage.labelOrder}
                    variant="outlined"
                    type="number"
                    defaultValue={value}
                    onChange={(e) => onChange(e.target.value)}
                    fullWidth
                  />
                ),
              },
              {
                title: LocalizedString.forumPostPage.labelYoutube,
                field: 'link',
                sorting: false,
                editable: selectedAddMediaMenu === 'Youtube' ? 'onAdd' : 'never',
                render: (prop) => {
                  if (prop) {
                    if (prop.path) {
                      if (prop.type === 'Youtube' && !prop.tableData.editing) {
                        const path = prop.path.includes(`${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}`) ? prop.path.slice(68) : prop.path;
                        return path;
                      }
                      if ((prop.type === 'Youtube' || Object.prototype.hasOwnProperty.call(prop, 'path')) && prop.tableData.editing) {
                        onAddMediaMenuSelected('Youtube');
                      }
                    }
                    if (prop.link) {
                      return prop.link.path;
                    }
                    return null;
                  }
                  return null;
                },
                // eslint-disable-next-line react/prop-types
                editComponent: ({ value, onChange }) => (
                  <TextField
                    placeholder={LocalizedString.forumPostPage.placeholderYoutube}
                    label={LocalizedString.forumPostPage.labelYoutube}
                    variant="outlined"
                    defaultValue={value}
                    onChange={(e) => onChange({ path: e.target.value, type: 'Youtube' })}
                    fullWidth
                  />
                ),
              },
              {
                title: LocalizedString.forumPostPage.labelImage,
                field: 'content',
                sorting: false,
                editable: selectedAddMediaMenu === 'Image' ? 'onAdd' : 'never',
                render: (prop) => {
                  if (prop) {
                    if (prop.path) {
                      if (prop.type === 'Image' && !prop.tableData.editing) {
                        return (<Avatar variant="square" src={prop.path} />);
                      }
                      if ((prop.type === 'Image' || Object.prototype.hasOwnProperty.call(prop, 'path')) && prop.tableData.editing) {
                        onAddMediaMenuSelected('Image');
                      }
                    }
                    if (prop.content) {
                      return (
                        <Avatar
                          variant="square"
                          src={IMAGE_SOURCE_URI_PREFIX.concat(prop.content.path)}
                        />
                      );
                    }
                    return null;
                  }
                  return null;
                },
                // eslint-disable-next-line react/prop-types
                editComponent: ({ onChange }) => (
                  <DropzonePicker
                    onImageSelected={(path) => onChange({ path, type: 'Image' })}
                  />
                ),
              },
            ]}
          />
        </Grid>
      )}
    </Grid>
);

const ForumPostPage = ({
  initialValues, forumCategories, profiles,
  addingEditing, downloading, downloadingDeleting, loadingCategory, loadingProfile,
  handleSubmit, onAddMediaPressed, onAddMediaMenuSelected, onAdvancedFilterPressed, onAppear,
  onApplyAdvancedFilterPressed, onCancelAdvancedFilterPressed, onCancelPressed,
  onCategoryOptionSelected, onChangeCategoryText, onChangeContentText, onChangePage,
  onChangePageSize, onChangeProfileText, onConfirmDeletePressed, onContentFormatSelected,
  onCreatePressed, onDeleteMediaPressed, onDeletePressed, onEditPressed, onLockedOptionSelected,
  onPinnedOptionSelected, onRefresh, onResetAdvancedFilterPressed, onSavePressed,
  onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  pageMode, selectedAddMediaMenu, selectedContentFormat,
}) => (
  <FunctionalPage
    data={RXSTATE_FORUM_POSTS}
    uiPage={RXSTATE_FORUM_POST_PAGE}
    filterColumns={[
      {
        title: LocalizedString.forumPostPage.labelCategory,
        field: RXFIELD_FORUM_POST_CATEGORY,
        type: FILTER_TYPE_DROPDOWN,
        data: forumCategories,
        loading: loadingCategory,
        onChangeFilterText: (text) => onChangeCategoryText(text, ADVANCED_FILTER_MODE),
        useDropdownValue: true,
      },
      {
        title: LocalizedString.forumPostPage.labelAuthor,
        field: RXFIELD_FORUM_POST_AUTHOR,
        type: FILTER_TYPE_DROPDOWN,
        data: profiles,
        loading: loadingProfile,
        onChangeFilterText: onChangeProfileText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.forumPostPage.labelStatus,
        field: RXFIELD_FORUM_POST_STATUS,
        type: FILTER_TYPE_DROPDOWN,
        data: status,
      },
      {
        title: LocalizedString.forumPostPage.labelLocked,
        field: RXFIELD_FORUM_POST_LOCKED,
        type: FILTER_TYPE_SWITCH,
      },
      {
        title: LocalizedString.forumPostPage.labelPinned,
        field: RXFIELD_FORUM_POST_PINNED,
        type: FILTER_TYPE_SWITCH,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.forumPostPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.forumPostPage.labelTitle, field: 'title', sorting: !downloading },
      { title: LocalizedString.forumPostPage.labelCategory, field: 'category.name', sorting: !downloading },
      { title: LocalizedString.forumPostPage.labelAuthor, field: 'author.fullName', sorting: !downloading },
      { title: LocalizedString.forumPostPage.labelLocked, field: 'locked', sorting: !downloading },
      { title: LocalizedString.forumPostPage.labelPinned, field: 'pinned', sorting: !downloading },
      { title: LocalizedString.forumPostPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.forumPostPage.buttonCaptionCreateNewPost}
    deleteButtonCaption={LocalizedString.forumPostPage.buttonCaptionDeletePost}
    editButtonCaption={LocalizedString.forumPostPage.buttonCaptionEditPost}
    title={LocalizedString.forumPostPage.title}
    useFullWidth
    createPermissionName="FORUM_CREATE_POST"
    deletePermissionName="FORUM_DELETE_POST"
    editPermissionName="FORUM_EDIT_POST"
  >
    {renderDialogContent(initialValues, forumCategories, addingEditing, downloadingDeleting,
      loadingCategory, onAddMediaPressed, onAddMediaMenuSelected, onCategoryOptionSelected,
      onChangeCategoryText, onDeleteMediaPressed, onLockedOptionSelected, onPinnedOptionSelected,
      pageMode, selectedAddMediaMenu, selectedContentFormat, onContentFormatSelected,
      onChangeContentText)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_FORUM_POST,
  validate: rxformValidateFormPost,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ForumPostPage);

ForumPostPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  forumCategories: PropTypes.arrayOf(SimpleDataShape).isRequired,
  profiles: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingCategory: PropTypes.bool.isRequired,
  loadingProfile: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onAddMediaPressed: PropTypes.func.isRequired,
  onAddMediaMenuSelected: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onCategoryOptionSelected: PropTypes.func.isRequired,
  onChangeCategoryText: PropTypes.func.isRequired,
  onChangeContentText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeProfileText: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onContentFormatSelected: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeleteMediaPressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onLockedOptionSelected: PropTypes.func.isRequired,
  onPinnedOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  selectedAddMediaMenu: PropTypes.string,
  selectedContentFormat: PropTypes.string.isRequired,
};

ForumPostPage.defaultProps = {
  selectedAddMediaMenu: null,
};
