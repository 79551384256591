import { downloadEventCategories, transformSearchText } from '../../../helper';
import { downloadingEventCategories, setEventCategories } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, eventCategories, uiEventCategory, uiBasePage,
  } = getState();
  const { token } = authentication;
  const { meta } = eventCategories;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiEventCategory;
  const { processingMultipleData } = uiBasePage;

  if (processingMultipleData) { return; }

  try {
    dispatch(downloadingEventCategories(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadEventCategories(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setEventCategories(list));
    }
  } finally {
    dispatch(downloadingEventCategories(false));
  }
};
