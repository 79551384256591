import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage } from '../../component';
import {
  PAGE_MODE_VIEW, RXFIELD_TOKEN_REQUEST_VOUCHER, RXFIELD_TOKEN_REQUEST_QUANTITY,
  RXFORM_TOKEN_REQUEST, RXSTATE_TOKEN_REQUESTS, RXSTATE_TOKEN_REQUEST_PAGE,
  RXFIELD_TOKEN_REQUEST_VALID_FROM, RXFIELD_TOKEN_REQUEST_VALID_UNTIL,
  DATE_TIME_FORMAT_WITHOUT_PIPE, PICKER_MODE_DATE_TIME, RXFIELD_TOKEN_REQUEST_PROGRESS,
  RXFIELD_TOKEN_REQUEST_PROGRESS_NOTES, RXFIELD_TOKEN_REQUEST_START_TIME,
  RXFIELD_TOKEN_REQUEST_END_TIME, RXFIELD_TOKEN_REQUEST_TASK_STATUS,
  FILTER_TYPE_DROPDOWN, FILTER_TYPE_DATE_RANGE, RXFIELD_CREATED_DATE,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, TOKEN_REQUEST_TASK_STATUS,
  TASK_STATUS_PROCESSING, TASK_STATUS_QUEUING, RXFIELD_TOKEN_REQUEST_MERCHANT,
  RXFIELD_TOKEN_REQUEST_VOUCHER_ID, RXFIELD_TOKEN_REQUEST_VOUCHER_NAME, ADVANCED_FILTER_MODE,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField, renderReduxFormDateTimePickerField,
  renderReduxFormOutlinedDropdownTextField,
} from '../../../../redux-form-rendererer';
import { rxformValidateTokenRequest } from '../../validation';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { toMoment } from '../../helper';

const renderDialogContent = (
  addingEditing, downloadingDeleting, pageMode,
  vouchers, loadingVoucher, initialValues,
  onChangeVoucherText, onVoucherOptionSelected,
  merchants, loadingMerchant, onChangeMerchantText,
) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_REQUEST_VOUCHER}
          component={renderReduxFormOutlinedDropdownTextField}
          placeholder={LocalizedString.tokenRequestPage.placeholderVoucher}
          label={LocalizedString.tokenRequestPage.placeholderVoucher}
          data={vouchers}
          loading={loadingVoucher}
          value={initialValues.voucher}
          onChangeText={onChangeVoucherText}
          onOptionSelected={onVoucherOptionSelected}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
          hidden={pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_REQUEST_VOUCHER_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.tokenRequestPage.placeholderVoucherId}
          label={LocalizedString.tokenRequestPage.placeholderVoucherId}
          disabled
          hidden={pageMode !== PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_REQUEST_VOUCHER_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.tokenRequestPage.placeholderVoucherName}
          label={LocalizedString.tokenRequestPage.placeholderVoucherName}
          disabled
          hidden={pageMode !== PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_REQUEST_MERCHANT}
          component={renderReduxFormOutlinedDropdownTextField}
          placeholder={LocalizedString.voucherPage.placeholderMerchant}
          label={LocalizedString.voucherPage.placeholderMerchant}
          data={merchants}
          loading={loadingMerchant}
          value={initialValues.merchant}
          onChangeText={onChangeMerchantText}
          hidden={pageMode !== PAGE_MODE_VIEW}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_REQUEST_PROGRESS}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.tokenRequestPage.placeholderProgress}
          label={LocalizedString.tokenRequestPage.placeholderProgress}
          disabled
          hidden={pageMode !== PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_REQUEST_PROGRESS_NOTES}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.tokenRequestPage.placeholderProgressNotes}
          label={LocalizedString.tokenRequestPage.placeholderProgressNotes}
          disabled
          hidden={pageMode !== PAGE_MODE_VIEW}
          multiline
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_REQUEST_TASK_STATUS}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.tokenRequestPage.placeholderTaskStatus}
          label={LocalizedString.tokenRequestPage.placeholderTaskStatus}
          disabled
          hidden={pageMode !== PAGE_MODE_VIEW}
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_REQUEST_QUANTITY}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.tokenRequestPage.placeholderQuantity}
          label={LocalizedString.tokenRequestPage.placeholderQuantity}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW || downloadingDeleting}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_REQUEST_VALID_FROM}
          component={renderReduxFormDateTimePickerField}
          label={LocalizedString.tokenRequestPage.placeholderValidFrom}
          pickerMode={PICKER_MODE_DATE_TIME}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW || downloadingDeleting}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_REQUEST_VALID_UNTIL}
          component={renderReduxFormDateTimePickerField}
          label={LocalizedString.tokenRequestPage.placeholderValidUntil}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW || downloadingDeleting}
          pickerMode={PICKER_MODE_DATE_TIME}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_REQUEST_START_TIME}
          component={renderReduxFormDateTimePickerField}
          label={LocalizedString.tokenRequestPage.placeholderStartTime}
          pickerMode={PICKER_MODE_DATE_TIME}
          disabled
          hidden={pageMode !== PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_REQUEST_END_TIME}
          component={renderReduxFormDateTimePickerField}
          label={LocalizedString.tokenRequestPage.placeholderEndTime}
          pickerMode={PICKER_MODE_DATE_TIME}
          disabled
          hidden={pageMode !== PAGE_MODE_VIEW}
        />
      </Grid>
    </Grid>
  </Grid>
);

const TokenRequestPage = ({
  addingEditing, downloading, downloadingDeleting, isCancelAllowed,
  handleSubmit, onAppear, onCancelPressed, onChangePage,
  onChangePageSize, onCreatePressed, onRefresh,
  onSubmitPressed, onSearchBarTextChanged, onSortPressed,
  onViewPressed, pageMode, initialValues,
  vouchers, loadingVoucher, onChangeVoucherText, onVoucherOptionSelected,
  onAdvancedFilterPressed, onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  tappedId, onCancelTokenRequestPressed, taskStatus, onConfirmCancelTokenRequestPressed,
  canceling, merchants, loadingMerchant, onChangeMerchantText,
}) => (
  <FunctionalPage
    data={RXSTATE_TOKEN_REQUESTS}
    uiPage={RXSTATE_TOKEN_REQUEST_PAGE}
    filterColumns={[
      {
        title: LocalizedString.tokenRequestPage.placeholderCreatedDate,
        field: RXFIELD_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.tokenRequestPage.placeholderVoucher,
        field: RXFIELD_TOKEN_REQUEST_VOUCHER,
        type: FILTER_TYPE_DROPDOWN,
        data: vouchers,
        loading: loadingVoucher,
        onChangeFilterText: (text) => onChangeVoucherText(text, ADVANCED_FILTER_MODE),
        useDropdownValue: true,
      },
      {
        title: LocalizedString.tokenRequestPage.placeholderTaskStatus,
        field: RXFIELD_TOKEN_REQUEST_TASK_STATUS,
        type: FILTER_TYPE_DROPDOWN,
        data: TOKEN_REQUEST_TASK_STATUS,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.tokenRequestPage.placeholderMerchant,
        field: RXFIELD_TOKEN_REQUEST_MERCHANT,
        type: FILTER_TYPE_DROPDOWN,
        data: merchants,
        loading: loadingMerchant,
        onChangeFilterText: onChangeMerchantText,
        useDropdownValue: true,
      },
    ]}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    tableColumns={[
      {
        title: LocalizedString.tokenRequestPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.tokenRequestPage.labelTitle, field: 'voucher.title', sorting: !downloading },
      {
        title: LocalizedString.tokenRequestPage.labelStartTime,
        field: 'startTime',
        sorting: !downloading,
        render: ({ startTime }) => (startTime
          ? toMoment(startTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      {
        title: LocalizedString.tokenRequestPage.labelProgress,
        field: 'progress',
        sorting: !downloading,
        render: ({ progress }) => `${progress}%`,
      },
      { title: LocalizedString.tokenRequestPage.labelNotes, field: 'progressNotes', sorting: !downloading },
      { title: LocalizedString.tokenRequestPage.labelTaskStatus, field: 'taskStatus', sorting: !downloading },
      {
        title: LocalizedString.tokenRequestPage.labelEndTime,
        field: 'endTime',
        sorting: !downloading,
        render: ({ endTime }) => (endTime
          ? toMoment(endTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onCreatePressed={onCreatePressed}
    onRefresh={onRefresh}
    onSubmitPressed={onSubmitPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.tokenRequestPage.buttonCaptionCreate}
    title={LocalizedString.tokenRequestPage.title}
    onConfirmDeletePressed={onConfirmCancelTokenRequestPressed}
    moreMenus={[
      {
        caption: taskStatus === TASK_STATUS_PROCESSING || taskStatus === TASK_STATUS_QUEUING
          ? LocalizedString.tokenRequestPage.buttonCaptionCancel : '',
        disabled: canceling || !isCancelAllowed,
        onPress: taskStatus === TASK_STATUS_PROCESSING || taskStatus === TASK_STATUS_QUEUING
          ? () => onCancelTokenRequestPressed(tappedId) : () => {},
      },
    ]}
    useFullWidth
    disableEdit
    disableDelete
    createPermissionName="VOUCHER_CREATE_TOKEN_REQUEST"
  >
    {renderDialogContent(
      addingEditing, downloadingDeleting, pageMode,
      vouchers, loadingVoucher, initialValues,
      onChangeVoucherText, onVoucherOptionSelected,
      merchants, loadingMerchant, onChangeMerchantText,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_TOKEN_REQUEST,
  validate: rxformValidateTokenRequest,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(TokenRequestPage);

TokenRequestPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  vouchers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  merchants: PropTypes.arrayOf(SimpleDataShape).isRequired,
  onAppear: PropTypes.func.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  canceling: PropTypes.bool.isRequired,
  isCancelAllowed: PropTypes.bool.isRequired,
  loadingMerchant: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  loadingVoucher: PropTypes.bool.isRequired,
  onChangeVoucherText: PropTypes.func.isRequired,
  onVoucherOptionSelected: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelTokenRequestPressed: PropTypes.func.isRequired,
  onConfirmCancelTokenRequestPressed: PropTypes.func.isRequired,
  onChangeMerchantText: PropTypes.func.isRequired,
  tappedId: PropTypes.string.isRequired,
  taskStatus: PropTypes.string,
};

TokenRequestPage.defaultProps = {
  taskStatus: undefined,
};
