import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, CircularProgress, Grid, IconButton, InputAdornment, Snackbar,
} from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FileCopyOutlined } from '@material-ui/icons';
import {
  Comment, FunctionalPage, SectionTitle,
} from '../../component';
import {
  ADDITIONAL_ITEM_TYPE_IMAGE, COLOR_PRIMARY, DATE_TIME_FORMAT_WITHOUT_PIPE,
  IMAGE_SOURCE_URI_PREFIX, REST_BASE_URL, REST_URL_IMAGE_STORAGE,
  RXFIELD_EVENT_OPERATOR_EVENT_ADDITTIONAL_ITEMS,
  RXFIELD_EVENT_OPERATOR_EVENT_CAN_PAY_USING_POINT, RXFIELD_EVENT_OPERATOR_EVENT_CATEGORY,
  RXFIELD_EVENT_OPERATOR_EVENT_CLOCK_IN_QR_CODE,
  RXFIELD_EVENT_OPERATOR_EVENT_CLOCK_INSIDE_AREA,
  RXFIELD_EVENT_OPERATOR_EVENT_COMMENT_ACCESS, RXFIELD_EVENT_OPERATOR_EVENT_DESCRIPTION,
  RXFIELD_EVENT_OPERATOR_EVENT_DISCUSSION_ID,
  RXFIELD_EVENT_OPERATOR_EVENT_ENABLE_COMMENT, RXFIELD_EVENT_OPERATOR_EVENT_END_TIME,
  RXFIELD_EVENT_OPERATOR_EVENT_FEATURED_ORDER,
  RXFIELD_EVENT_OPERATOR_EVENT_MEDIA, RXFIELD_EVENT_OPERATOR_EVENT_NAME,
  RXFIELD_EVENT_OPERATOR_EVENT_NEEDS_APPROVAL, RXFIELD_EVENT_OPERATOR_EVENT_NORMAL_PRICE,
  RXFIELD_EVENT_OPERATOR_EVENT_PRICE, RXFIELD_EVENT_OPERATOR_EVENT_QUOTA,
  RXFIELD_EVENT_OPERATOR_EVENT_REGISTRATION_REWARD_POINT,
  RXFIELD_EVENT_OPERATOR_EVENT_REGISTRATION_REWARD_TYPE,
  RXFIELD_EVENT_OPERATOR_EVENT_REGISTRATION_REWARD_VOUCHERS,
  RXFIELD_EVENT_OPERATOR_EVENT_SHORT_DESCRIPTION,
  RXFIELD_EVENT_OPERATOR_EVENT_SPEAKERS, RXFIELD_EVENT_OPERATOR_EVENT_START_TIME,
  RXFIELD_EVENT_OPERATOR_EVENT_SURVEY_FORM_ID, RXFIELD_EVENT_OPERATOR_EVENT_EVENT_TYPE,
  RXFIELD_EVENT_OPERATOR_EVENT_WHO_CAN_REGISTER, RXFORM_EVENT_OPERATOR_EVENT,
  RXSTATE_EVENT_OPERATOR_EVENT_PAGE, RXSTATE_EVENT_OPERATOR_EVENTS,
  RXFIELD_EVENT_OPERATOR_EVENT_FEATURED,
  PAGE_MODE_VIEW, PAGE_MODE_TABLE, snackbarDuration,
  RXFIELD_EVENT_OPERATOR_EVENT_EARLIEST_CLOCK, RXFIELD_EVENT_OPERATOR_EVENT_MAX_CLOCK,
  EVENT_OPERATOR_EVENT_REWARD_TYPE_POINT, EVENT_OPERATOR_EVENT_REWARD_TYPE_VOUCHER,
  EVENT_TYPE_REGULAR, REST_BASE_URL_EVENT,
  EVENT_OPERATOR_EVENT_REWARD_TYPE_NONE,
  RXFIELD_EVENT_OPERATOR_EVENT_LOCATION,
  RXFIELD_EVENT_OPERATOR_EVENT_VISIBILITY_STATUS,
  RXFIELD_EVENT_OPERATOR_EVENT_BANNER,
} from '../../constant';
import {
  getAdditionalItemIcon, isAdditionalItemYoutubeUrlType, toMoment,
} from '../../helper';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import {
  renderReduxFormAutocompleteCheckboxField, renderReduxFormAutocompleteTableField,
  renderReduxFormDateTimePickerField, renderReduxFormOutlinedDropdownTextField,
  renderReduxFormEditableTableField, renderReduxFormOutlinedTextField,
  renderReduxFormRichTextEditorField,
  renderReduxFormImageInputField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape } from '../../type';

const renderDialogContent = (
  pageMode, initialValues, copying, downloadingDeleting, onCopyPressed,
) => {
  const hiddenFieldRewardPoint = initialValues.registrationRewardType
  === EVENT_OPERATOR_EVENT_REWARD_TYPE_VOUCHER
  || initialValues.registrationRewardType === EVENT_OPERATOR_EVENT_REWARD_TYPE_NONE
  || initialValues.registrationRewardType === '';
  const hiddenFieldRewardVoucher = initialValues.registrationRewardType
   === EVENT_OPERATOR_EVENT_REWARD_TYPE_POINT
  || initialValues.registrationRewardType === EVENT_OPERATOR_EVENT_REWARD_TYPE_NONE
  || initialValues.registrationRewardType === '';
  const isEventTypeRegular = initialValues.eventType === EVENT_TYPE_REGULAR;

  return (
    <Grid>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_NAME}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventOperatorEventPage.labelName}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderName}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_SHORT_DESCRIPTION}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventOperatorEventPage.labelShortDescription}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderShortDescription}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_START_TIME}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.eventOperatorEventPage.labelStartTime}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderStartTime}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_END_TIME}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.eventOperatorEventPage.labelEndTime}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderEndTime}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_EARLIEST_CLOCK}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventOperatorEventPage.labelEarliestClock}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderEarliestClock}
              disabled
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_MAX_CLOCK}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventOperatorEventPage.labelMaxClock}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderMaxClock}
              disabled
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_CLOCK_INSIDE_AREA}
              component={renderReduxFormOutlinedDropdownTextField}
              label={LocalizedString.eventOperatorEventPage.labelClockInMustBeInsideArea}
              placeholder={
                LocalizedString.eventOperatorEventPage.placeholderClockInMustBeInsideArea
              }
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_REGISTRATION_REWARD_TYPE}
              component={renderReduxFormOutlinedDropdownTextField}
              label={LocalizedString.eventOperatorEventPage.labelRegistrationRewardType}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderRegistrationRewardType}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_REGISTRATION_REWARD_POINT}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventOperatorEventPage.labelRegistrationRewardPoint}
              placeholder={
                LocalizedString.eventOperatorEventPage.placeholderRegistrationRewardPoint
              }
              disabled
              hidden={hiddenFieldRewardPoint}
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_REGISTRATION_REWARD_VOUCHERS}
              component={renderReduxFormAutocompleteCheckboxField}
              label={LocalizedString.eventOperatorEventPage.labelRegistrationRewardVoucher}
              placeholder={
                LocalizedString.eventOperatorEventPage.placeholderRegistrationRewardVoucher
              }
              disabled
              value={initialValues.registrationRewardVoucherIds}
              hidden={hiddenFieldRewardVoucher}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_SURVEY_FORM_ID}
              component={renderReduxFormOutlinedDropdownTextField}
              label={LocalizedString.eventOperatorEventPage.labelSurveyForm}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderSurveyForm}
              disabled
              hidden={isEventTypeRegular}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_ENABLE_COMMENT}
              component={renderReduxFormOutlinedDropdownTextField}
              label={LocalizedString.eventOperatorEventPage.placeholderEnableComment}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderEnableComment}
              disabled
              value={initialValues.enableComment}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_COMMENT_ACCESS}
              component={renderReduxFormOutlinedDropdownTextField}
              label={LocalizedString.eventOperatorEventPage.placeholderCommentAccess}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderCommentAccess}
              disabled
              hidden={initialValues.enableComment?.value === false}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_DISCUSSION_ID}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventOperatorEventPage.placeholderDiscussionId}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderDiscussionId}
              disabled
              hidden={pageMode !== PAGE_MODE_VIEW}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_CLOCK_IN_QR_CODE}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventOperatorEventPage.placeholderClockInQrCode}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderClockInQrCode}
              disabled
              hidden={pageMode === PAGE_MODE_TABLE}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => onCopyPressed(copying)} edge="end">
                      <CopyToClipboard text={initialValues.clockInQrCode}>
                        <FileCopyOutlined style={{ fill: COLOR_PRIMARY }} />
                      </CopyToClipboard>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_EVENT_TYPE}
              component={renderReduxFormOutlinedDropdownTextField}
              label={LocalizedString.eventOperatorEventPage.labelEventType}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderEventType}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_CATEGORY}
              component={renderReduxFormOutlinedDropdownTextField}
              label={LocalizedString.eventOperatorEventPage.labelCategory}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderCategory}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_LOCATION}
              component={renderReduxFormOutlinedDropdownTextField}
              label={LocalizedString.eventOperatorEventPage.labelLocation}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderLocation}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_FEATURED}
              component={renderReduxFormOutlinedDropdownTextField}
              label={LocalizedString.eventOperatorEventPage.labelFeatured}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderFeatured}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_FEATURED_ORDER}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventOperatorEventPage.labelFeaturedOrder}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderFeaturedOrder}
              disabled
              type="number"
              hidden={initialValues.isFeatured?.value === false}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_WHO_CAN_REGISTER}
              component={renderReduxFormOutlinedDropdownTextField}
              label={LocalizedString.eventOperatorEventPage.labelWhoCanRegister}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderWhoCanRegister}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_NEEDS_APPROVAL}
              component={renderReduxFormOutlinedDropdownTextField}
              label={LocalizedString.eventOperatorEventPage.labelNeedApproval}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderNeedApproval}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_QUOTA}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventOperatorEventPage.labelQuota}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderQuota}
              disabled
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_NORMAL_PRICE}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventOperatorEventPage.labelNormalPrice}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderNormalPrice}
              disabled
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_PRICE}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventOperatorEventPage.labelPrice}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderPrice}
              disabled
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_CAN_PAY_USING_POINT}
              component={renderReduxFormOutlinedDropdownTextField}
              label={LocalizedString.eventOperatorEventPage.labelCanPayUsingPoint}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderCanPayUsingPoint}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_VISIBILITY_STATUS}
              component={renderReduxFormOutlinedDropdownTextField}
              label={LocalizedString.eventOperatorEventPage.labelVisibilityStatus}
              placeholder={LocalizedString.eventOperatorEventPage.placeholderVisibilityStatus}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_EVENT_BANNER}
              component={renderReduxFormImageInputField}
              label={LocalizedString.eventOperatorEventPage.placeholderBanner}
              disabled
              loading={downloadingDeleting}
              defaultValue={initialValues.banner}
              useFullWidthImage
              fullImageWidth="100%"
            />
          </Grid>
        </Grid>
      </Grid>

      <SectionTitle title={LocalizedString.eventOperatorEventPage.labelMedia} />
      {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_EVENT_OPERATOR_EVENT_MEDIA}
            component={renderReduxFormEditableTableField}
            label={LocalizedString.eventOperatorEventPage.labelMedia}
            disabled
            defaultValue={initialValues.media}
            disableEdit
            disableToolbar
            tableColumns={[
              {
                title: LocalizedString.eventOperatorEventPage.labelOrder,
                field: 'order',
                sorting: false,
              },
              {
                title: LocalizedString.eventOperatorEventPage.labelYoutube,
                field: 'link',
                sorting: false,
                render: (prop) => {
                  if (prop) {
                    if (prop.path) {
                      if (prop.type === 'Youtube' && !prop.tableData.editing) {
                        const path = prop.path.includes(`${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}`) ? prop.path.slice(68) : prop.path;
                        return path;
                      }
                    }
                    if (prop.link) {
                      return prop.link.path;
                    }
                    return null;
                  }
                  return null;
                },
              },
              {
                title: LocalizedString.eventOperatorEventPage.labelImage,
                field: 'content',
                sorting: false,
                render: (prop) => {
                  if (prop) {
                    if (prop.path) {
                      if (prop.type === 'Image' && !prop.tableData.editing) {
                        return (<Avatar variant="square" src={prop.path} />);
                      }
                    }
                    if (prop.content) {
                      return (
                        <Avatar
                          variant="square"
                          src={IMAGE_SOURCE_URI_PREFIX.concat(prop.content.path)}
                        />
                      );
                    }
                    return null;
                  }
                  return null;
                },
              },
            ]}

          />
        </Grid>
      )}

      <SectionTitle title={LocalizedString.eventOperatorEventPage.labelDescription} />
      <Grid container>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_EVENT_DESCRIPTION}
            component={renderReduxFormRichTextEditorField}
            label={LocalizedString.eventOperatorEventPage.labelDescription}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
      </Grid>

      {isEventTypeRegular
        ? null
        : (<SectionTitle title={LocalizedString.eventOperatorEventPage.labelSpeakers} />)}
      {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_EVENT_OPERATOR_EVENT_SPEAKERS}
            component={renderReduxFormAutocompleteTableField}
            label={LocalizedString.eventOperatorEventPage.placeholderSpeakers}
            defaultValue={initialValues.speakers}
            disableAdd
            disabled
            optionPlaceholder={LocalizedString.eventOperatorEventPage.placeholderSpeakers}
            hidden={isEventTypeRegular}
          />
        </Grid>
      )}

      <SectionTitle title={LocalizedString.eventOperatorEventPage.labelAdditionalItems} />
      {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_EVENT_OPERATOR_EVENT_ADDITTIONAL_ITEMS}
            component={renderReduxFormEditableTableField}
            label={LocalizedString.eventOperatorEventPage.labelAdditionalItems}
            disabled
            defaultValue={initialValues.additionalItems}
            disableToolbar
            tableColumns={[
              {
                title: LocalizedString.eventOperatorEventPage.labelOrder, field: 'order', sorting: false, type: 'numeric',
              },
              { title: LocalizedString.eventOperatorEventPage.labelLabel, field: 'label', sorting: false },
              {
                title: LocalizedString.eventOperatorEventPage.labelType, field: 'type', sorting: false,
              },
              {
                title: LocalizedString.eventOperatorEventPage.labelViewAccess, field: 'viewAccess', sorting: false,
              },
              {
                title: LocalizedString.eventOperatorEventPage.labelVisibilityTime, field: 'visibilityTime', sorting: false,
              },
              {
                title: LocalizedString.eventOperatorEventPage.labelContent,
                field: 'content',
                sorting: false,
                render: (prop) => {
                  if (prop) {
                    if (!isAdditionalItemYoutubeUrlType(prop)) {
                      if (prop.type === ADDITIONAL_ITEM_TYPE_IMAGE) {
                        if (prop.path) {
                          return (<Avatar variant="square" src={prop.path} />);
                        }
                        if (prop.content) {
                          return (
                            <Avatar
                              variant="square"
                              src={IMAGE_SOURCE_URI_PREFIX.concat(prop.content.content)}
                            />
                          );
                        }
                      } else {
                        const { icon: Icon, color } = getAdditionalItemIcon(prop);
                        return (<Icon style={{ color, width: 40, height: 40 }} />);
                      }
                    }
                    return null;
                  }
                  return null;
                },
              },
              {
                title: LocalizedString.eventOperatorEventPage.labelUrl,
                field: 'url',
                sorting: false,
                render: (prop) => {
                  if (prop) {
                    if (isAdditionalItemYoutubeUrlType(prop)) {
                      return prop?.url || prop?.path || null;
                    }
                    return null;
                  }
                  return null;
                },
              },
            ]}
          />
        </Grid>
      )}

      <Snackbar
        open={copying}
        autoHideDuration={snackbarDuration}
        message={LocalizedString.eventOperatorEventPage.labelCopied}
        onClose={() => onCopyPressed(copying)}
      />
    </Grid>
  );
};

const EventOperatorEventPage = ({
  initialValues, copying, downloading, downloadingDeleting,
  onAppear, onChangePage, onChangePageSize, onCopyPressed, onRefresh, onSearchBarTextChanged,
  onSortPressed, onViewPressed, discusId, pageMode,
}) => (
  <FunctionalPage
    data={RXSTATE_EVENT_OPERATOR_EVENTS}
    uiPage={RXSTATE_EVENT_OPERATOR_EVENT_PAGE}
    tableColumns={[
      {
        title: GlobalLocalizedString.common.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.eventOperatorEventPage.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.eventOperatorEventPage.labelCategory, field: 'category.name', sorting: !downloading },
      { title: LocalizedString.eventOperatorEventPage.labelLocation, field: 'location.name', sorting: !downloading },
      { title: LocalizedString.eventOperatorEventPage.labelEventType, field: 'eventType', sorting: !downloading },
      {
        title: LocalizedString.eventOperatorEventPage.labelStartTime,
        field: 'startTime',
        sorting: !downloading,
        render: ({ startTime }) => (startTime
          ? toMoment(startTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      {
        title: LocalizedString.eventOperatorEventPage.labelEndTime,
        field: 'endTime',
        sorting: !downloading,
        render: ({ endTime }) => (endTime
          ? toMoment(endTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      {
        title: LocalizedString.eventOperatorEventPage.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.eventOperatorEventPage.title}
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
  >
    {renderDialogContent(
      pageMode, initialValues, copying, downloadingDeleting, onCopyPressed,
    )}
    {pageMode !== PAGE_MODE_TABLE && (<Comment discusId={discusId} path={REST_BASE_URL_EVENT} />)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_EVENT_OPERATOR_EVENT,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(EventOperatorEventPage);

EventOperatorEventPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  copying: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  discusId: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,

  onAppear: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onCopyPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
