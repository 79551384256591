import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_EMAILS, MENUID_NOTIFICATION_EMAIL, PAGE_MODE_TABLE, RXFORM_EMAIL,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE,
} from '../../constant';
import { getPermission, transformInitialValues } from '../../../../helper';
import LocalizedString from '../../localization';
import {
  clearEmails, setActiveSideMenuItem, setAdvancedFilterDialogSelectedFilterString,
  setAlertConfirmationMessage, setAlertErrorMessage, setEmailSearchText,
  setEmailSelectedPageSize, setEmailSelectedOrderBy, setEmailTappedId,
  downloadEmailsAsync, downloadEmailAsync, resendEmailAsync,
} from '../../redux/action';
import EmailPage from './email.presentation';

const transformArrToString = (data) => (data.length > 0 ? data.join('\n') : null);

const getInitialValues = (state) => {
  const { emails, uiFunctionalPage, uiEmail } = state;
  const { data } = emails;
  const { downloadingDeleting, tappedId } = uiEmail;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    to: transformArrToString(found.to),
    cc: transformArrToString(found.cc),
    bcc: transformArrToString(found.bcc),
    attachments: found.attachments.map((item, i) => ({
      ...item,
      no: i + 1,
    })),
  }) : {
    to: '',
    cc: '',
    bcc: '',
    subject: '',
    body: '',
    format: '',
    processingStatus: '',
    notes: '',
    attachments: [],
  };
  return result;
};

const onClickFileDownloader = async (filePath) => {
  const getFileType = filePath.split('.').pop();
  await fetch(`${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${filePath}`)
    .then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = `attachment.${getFileType}`;
        alink.click();
      });
    });
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  downloading: state.uiEmail.downloading,
  downloadingDeleting: state.uiEmail.downloadingDeleting,
  resending: state.uiEmail.resending,
  initialValues: getInitialValues(state),
  tappedId: state.uiFunctionalPage.tappedId || '',
  isResendAllowed: getPermission(state, 'MESSAGING_RESEND_EMAIL'),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_NOTIFICATION_EMAIL));
    dispatch(setEmailSearchText(''));
    dispatch(clearEmails());
    dispatch(setEmailSelectedPageSize(20));
    dispatch(setEmailSelectedOrderBy(INITIAL_ORDER_BY_EMAILS));
    dispatch(downloadEmailsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearEmails());
    dispatch(downloadEmailsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_EMAIL));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadEmailsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setEmailSelectedPageSize(pageSize));
    dispatch(downloadEmailsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmResendPressed: () => {
    dispatch(resendEmailAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setEmailSelectedPageSize(pageSize));
    dispatch(clearEmails());
    dispatch(downloadEmailsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResendPressed: (id) => {
    dispatch(setEmailTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.notificationMenu.msgResendConfirmation));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearEmails());
    dispatch(setEmailSelectedOrderBy(INITIAL_ORDER_BY_EMAILS));
    dispatch(downloadEmailsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setEmailSearchText(text));
      dispatch(clearEmails());
      await dispatch(downloadEmailsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setEmailSelectedOrderBy(orderBy));
    dispatch(clearEmails());
    dispatch(downloadEmailsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setEmailTappedId(id));
    dispatch(downloadEmailAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDownloadAttachmentPressed: (filePath) => {
    onClickFileDownloader(filePath);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailPage);
