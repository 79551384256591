import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_EVENT_OPERATOR_MAPPINGS = 'DOWNLOADING_EVENT_OPERATOR_MAPPINGS';
export const downloadingEventOperatorMappings = makeActionCreator(DOWNLOADING_EVENT_OPERATOR_MAPPINGS, 'status');

export const SET_EVENT_OPERATOR_MAPPINGS = 'SET_EVENT_OPERATOR_MAPPINGS';
export const setEventOperatorMappings = makeActionCreator(SET_EVENT_OPERATOR_MAPPINGS, 'data');

export const ADD_EVENT_OPERATOR_MAPPING = 'ADD_EVENT_OPERATOR_MAPPING';
export const addEventOperatorMapping = makeActionCreator(ADD_EVENT_OPERATOR_MAPPING, 'data');

export const CLEAR_EVENT_OPERATOR_MAPPINGS = 'CLEAR_EVENT_OPERATOR_MAPPINGS';
export const clearEventOperatorMappings = makeActionCreator(CLEAR_EVENT_OPERATOR_MAPPINGS);

export const ADDING_EDITING_EVENT_OPERATOR_MAPPING = 'ADDING_EDITING_EVENT_OPERATOR_MAPPING';
export const addingEditingEventOperatorMapping = makeActionCreator(ADDING_EDITING_EVENT_OPERATOR_MAPPING, 'status');

export const DOWNLOADING_DELETING_EVENT_OPERATOR_MAPPING = 'DOWNLOADING_DELETING_EVENT_OPERATOR_MAPPING';
export const downloadingDeletingEventOperatorMapping = makeActionCreator(DOWNLOADING_DELETING_EVENT_OPERATOR_MAPPING, 'status');

export const ENABLING_DISABLING_EVENT_OPERATOR_MAPPING = 'ENABLING_DISABLING_EVENT_OPERATOR_MAPPING';
export const enablingDisablingEventOperatorMapping = makeActionCreator(ENABLING_DISABLING_EVENT_OPERATOR_MAPPING, 'status');


export const SET_EVENT_OPERATOR_MAPPING_SELECTED_PAGE_SIZE = 'SET_EVENT_OPERATOR_MAPPING_SELECTED_PAGE_SIZE';
export const setEventOperatorMappingSelectedPageSize = makeActionCreator(SET_EVENT_OPERATOR_MAPPING_SELECTED_PAGE_SIZE, 'size');

export const SET_EVENT_OPERATOR_MAPPING_SELECTED_ORDER_BY = 'SET_EVENT_OPERATOR_MAPPING_SELECTED_ORDER_BY';
export const setEventOperatorMappingSelectedOrderBy = makeActionCreator(SET_EVENT_OPERATOR_MAPPING_SELECTED_ORDER_BY, 'order');

export const SET_EVENT_OPERATOR_MAPPING_TAPPED_ID = 'SET_EVENT_OPERATOR_MAPPING_TAPPED_ID';
export const setEventOperatorMappingTappedId = makeActionCreator(SET_EVENT_OPERATOR_MAPPING_TAPPED_ID, 'id');

export const SET_EVENT_OPERATOR_MAPPING_SEARCH_TEXT = 'SET_EVENT_OPERATOR_MAPPING_SEARCH_TEXT';
export const setEventOperatorMappingSearchText = makeActionCreator(SET_EVENT_OPERATOR_MAPPING_SEARCH_TEXT, 'text');

export const SET_EVENT_OPERATOR_MAPPING_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_EVENT_OPERATOR_MAPPING_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setEventOperatorMappingAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_EVENT_OPERATOR_MAPPING_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_EVENT_OPERATOR_MAPPING_SELECTED_EVENT = 'SET_EVENT_OPERATOR_MAPPING_SELECTED_EVENT';
export const setEventOperatorMappingSelectedEvent = makeActionCreator(SET_EVENT_OPERATOR_MAPPING_SELECTED_EVENT, 'option');

export const SET_EVENT_OPERATOR_MAPPING_SELECTED_USER = 'SET_EVENT_OPERATOR_MAPPING_SELECTED_USER';
export const setEventOperatorMappingSelectedUser = makeActionCreator(SET_EVENT_OPERATOR_MAPPING_SELECTED_USER, 'option');


export const DOWNLOADING_EVENT_OPERATOR_INVITATIONS = 'DOWNLOADING_EVENT_OPERATOR_INVITATIONS';
export const downloadingEventOperatorInvitations = makeActionCreator(DOWNLOADING_EVENT_OPERATOR_INVITATIONS, 'status');

export const SET_EVENT_OPERATOR_INVITATIONS = 'SET_EVENT_OPERATOR_INVITATIONS';
export const setEventOperatorInvitations = makeActionCreator(SET_EVENT_OPERATOR_INVITATIONS, 'data');

export const ADD_EVENT_OPERATOR_INVITATION = 'ADD_EVENT_OPERATOR_INVITATION';
export const addEventOperatorInvitation = makeActionCreator(ADD_EVENT_OPERATOR_INVITATION, 'data');

export const CLEAR_EVENT_OPERATOR_INVITATIONS = 'CLEAR_EVENT_OPERATOR_INVITATIONS';
export const clearEventOperatorInvitations = makeActionCreator(CLEAR_EVENT_OPERATOR_INVITATIONS);

export const ADDING_EVENT_OPERATOR_INVITATION = 'ADDING_EVENT_OPERATOR_INVITATION';
export const addingEventOperatorInvitation = makeActionCreator(ADDING_EVENT_OPERATOR_INVITATION, 'status');

export const DOWNLOADING_DELETING_EVENT_OPERATOR_INVITATION = 'DOWNLOADING_DELETING_EVENT_OPERATOR_INVITATION';
export const downloadingDeletingEventOperatorInvitation = makeActionCreator(DOWNLOADING_DELETING_EVENT_OPERATOR_INVITATION, 'status');

export const SAVING_EVENT_OPERATOR_INVITATIONS = 'SAVING_EVENT_OPERATOR_INVITATIONS';
export const savingEventOperatorInvitations = makeActionCreator(SAVING_EVENT_OPERATOR_INVITATIONS, 'status');

export const RESENDING_EVENT_OPERATOR_INVITATION = 'RESENDING_EVENT_OPERATOR_INVITATION';
export const resendingEventOperatorInvitation = makeActionCreator(RESENDING_EVENT_OPERATOR_INVITATION, 'status');


export const SET_EVENT_OPERATOR_INVITATION_SELECTED_PAGE_SIZE = 'SET_EVENT_OPERATOR_INVITATION_SELECTED_PAGE_SIZE';
export const setEventOperatorInvitationSelectedPageSize = makeActionCreator(SET_EVENT_OPERATOR_INVITATION_SELECTED_PAGE_SIZE, 'size');

export const SET_EVENT_OPERATOR_INVITATION_SELECTED_ORDER_BY = 'SET_EVENT_OPERATOR_INVITATION_SELECTED_ORDER_BY';
export const setEventOperatorInvitationSelectedOrderBy = makeActionCreator(SET_EVENT_OPERATOR_INVITATION_SELECTED_ORDER_BY, 'order');

export const SET_EVENT_OPERATOR_INVITATION_TAPPED_ID = 'SET_EVENT_OPERATOR_INVITATION_TAPPED_ID';
export const setEventOperatorInvitationTappedId = makeActionCreator(SET_EVENT_OPERATOR_INVITATION_TAPPED_ID, 'id');

export const SET_EVENT_OPERATOR_INVITATION_SEARCH_TEXT = 'SET_EVENT_OPERATOR_INVITATION_SEARCH_TEXT';
export const setEventOperatorInvitationSearchText = makeActionCreator(SET_EVENT_OPERATOR_INVITATION_SEARCH_TEXT, 'text');

export const SET_EVENT_OPERATOR_INVITATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_EVENT_OPERATOR_INVITATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setEventOperatorInvitationAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_EVENT_OPERATOR_INVITATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_EVENT_OPERATOR_INVITATION_SELECTED_EVENT_ID = 'SET_EVENT_OPERATOR_INVITATION_SELECTED_EVENT_ID';
export const setEventOperatorInvitationSelectedEventId = makeActionCreator(SET_EVENT_OPERATOR_INVITATION_SELECTED_EVENT_ID, 'id');

export const SET_EVENT_OPERATOR_INVITATION_SELECTED_RECIPIENT = 'SET_EVENT_OPERATOR_INVITATION_SELECTED_RECIPIENT';
export const setEventOperatorInvitationSelectedRecipient = makeActionCreator(SET_EVENT_OPERATOR_INVITATION_SELECTED_RECIPIENT, 'data');


export const DOWNLOADING_EVENT_OPERATOR_REGISTRATIONS = 'DOWNLOADING_EVENT_OPERATOR_REGISTRATIONS';
export const downloadingEventOperatorRegistrations = makeActionCreator(DOWNLOADING_EVENT_OPERATOR_REGISTRATIONS, 'status');

export const SET_EVENT_OPERATOR_REGISTRATIONS = 'SET_EVENT_OPERATOR_REGISTRATIONS';
export const setEventOperatorRegistrations = makeActionCreator(SET_EVENT_OPERATOR_REGISTRATIONS, 'data');

export const ADD_EVENT_OPERATOR_REGISTRATION = 'ADD_EVENT_OPERATOR_REGISTRATION';
export const addEventOperatorRegistration = makeActionCreator(ADD_EVENT_OPERATOR_REGISTRATION, 'data');

export const CLEAR_EVENT_OPERATOR_REGISTRATIONS = 'CLEAR_EVENT_OPERATOR_REGISTRATIONS';
export const clearEventOperatorRegistrations = makeActionCreator(
  CLEAR_EVENT_OPERATOR_REGISTRATIONS,
);

export const DOWNLOADING_DELETING_EVENT_OPERATOR_REGISTRATION = 'DOWNLOADING_DELETING_EVENT_OPERATOR_REGISTRATION';
export const downloadingDeletingEventOperatorRegistration = makeActionCreator(DOWNLOADING_DELETING_EVENT_OPERATOR_REGISTRATION, 'status');

export const APPROVING_EVENT_OPERATOR_REGISTRATION = 'APPROVING_EVENT_OPERATOR_REGISTRATION';
export const approvingEventOperatorRegistration = makeActionCreator(APPROVING_EVENT_OPERATOR_REGISTRATION, 'status');

export const REJECTING_EVENT_OPERATOR_REGISTRATION = 'REJECTING_EVENT_OPERATOR_REGISTRATION';
export const rejectingEventOperatorRegistration = makeActionCreator(REJECTING_EVENT_OPERATOR_REGISTRATION, 'status');

export const SAVING_EVENT_OPERATOR_REGISTRATIONS = 'SAVING_EVENT_OPERATOR_REGISTRATIONS';
export const savingEventOperatorRegistrations = makeActionCreator(SAVING_EVENT_OPERATOR_REGISTRATIONS, 'status');


export const SET_EVENT_OPERATOR_REGISTRATION_SELECTED_PAGE_SIZE = 'SET_EVENT_OPERATOR_REGISTRATION_SELECTED_PAGE_SIZE';
export const setEventOperatorRegistrationSelectedPageSize = makeActionCreator(SET_EVENT_OPERATOR_REGISTRATION_SELECTED_PAGE_SIZE, 'size');

export const SET_EVENT_OPERATOR_REGISTRATION_SELECTED_ORDER_BY = 'SET_EVENT_OPERATOR_REGISTRATION_SELECTED_ORDER_BY';
export const setEventOperatorRegistrationSelectedOrderBy = makeActionCreator(SET_EVENT_OPERATOR_REGISTRATION_SELECTED_ORDER_BY, 'order');

export const SET_EVENT_OPERATOR_REGISTRATION_TAPPED_ID = 'SET_EVENT_OPERATOR_REGISTRATION_TAPPED_ID';
export const setEventOperatorRegistrationTappedId = makeActionCreator(SET_EVENT_OPERATOR_REGISTRATION_TAPPED_ID, 'id');

export const SET_EVENT_OPERATOR_REGISTRATION_SEARCH_TEXT = 'SET_EVENT_OPERATOR_REGISTRATION_SEARCH_TEXT';
export const setEventOperatorRegistrationSearchText = makeActionCreator(SET_EVENT_OPERATOR_REGISTRATION_SEARCH_TEXT, 'text');

export const SET_EVENT_OPERATOR_REGISTRATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_EVENT_OPERATOR_REGISTRATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setEventOperatorRegistrationAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_EVENT_OPERATOR_REGISTRATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');
export const DOWNLOADING_EVENT_OPERATOR_EVENTS = 'DOWNLOADING_EVENT_OPERATOR_EVENTS';
export const downloadingEventOperatorEvents = makeActionCreator(DOWNLOADING_EVENT_OPERATOR_EVENTS, 'status');

export const SET_EVENT_OPERATOR_EVENTS = 'SET_EVENT_OPERATOR_EVENTS';
export const setEventOperatorEvents = makeActionCreator(SET_EVENT_OPERATOR_EVENTS, 'data');

export const ADD_EVENT_OPERATOR_EVENT = 'ADD_EVENT_OPERATOR_EVENT';
export const addEventOperatorEvent = makeActionCreator(ADD_EVENT_OPERATOR_EVENT, 'data');

export const CLEAR_EVENT_OPERATOR_EVENTS = 'CLEAR_EVENT_OPERATOR_EVENTS';
export const clearEventOperatorEvents = makeActionCreator(CLEAR_EVENT_OPERATOR_EVENTS);

export const DOWNLOADING_DELETING_EVENT_OPERATOR_EVENT = 'DOWNLOADING_DELETING_EVENT_OPERATOR_EVENT';
export const downloadingDeletingEventOperatorEvent = makeActionCreator(DOWNLOADING_DELETING_EVENT_OPERATOR_EVENT, 'status');

export const SET_EVENT_OPERATOR_EVENT_SELECTED_PAGE_SIZE = 'SET_EVENT_OPERATOR_EVENT_SELECTED_PAGE_SIZE';
export const setEventOperatorEventSelectedPageSize = makeActionCreator(SET_EVENT_OPERATOR_EVENT_SELECTED_PAGE_SIZE, 'size');

export const SET_EVENT_OPERATOR_EVENT_SELECTED_ORDER_BY = 'SET_EVENT_OPERATOR_EVENT_SELECTED_ORDER_BY';
export const setEventOperatorEventSelectedOrderBy = makeActionCreator(SET_EVENT_OPERATOR_EVENT_SELECTED_ORDER_BY, 'order');

export const SET_EVENT_OPERATOR_EVENT_TAPPED_ID = 'SET_EVENT_OPERATOR_EVENT_TAPPED_ID';
export const setEventOperatorEventTappedId = makeActionCreator(SET_EVENT_OPERATOR_EVENT_TAPPED_ID, 'id');

export const SET_EVENT_OPERATOR_EVENT_SEARCH_TEXT = 'SET_EVENT_OPERATOR_EVENT_SEARCH_TEXT';
export const setEventOperatorEventSearchText = makeActionCreator(SET_EVENT_OPERATOR_EVENT_SEARCH_TEXT, 'text');

export const COPYING_EVENT_OPERATOR_EVENT_QR_CODE = 'COPYING_EVENT_OPERATOR_EVENT_QR_CODE';
export const copyingEventOperatorEventQrCode = makeActionCreator(COPYING_EVENT_OPERATOR_EVENT_QR_CODE, 'status');
