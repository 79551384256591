import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    sessionOperatorMenu: {
      title: 'Session Operator',
    },
    mappingPage: {
      title: 'Session Operator Mapping',

      labelNo: 'No',
      labelUserName: 'User Name',
      labelEmail: 'Email',
      labelSessionName: 'Session Name',
      labelValidUntil: 'Valid Until',
      labelCreatedDate: 'Created Date',

      placeholderUser: 'User',
      placeholderUserId: 'User ID',
      placeholderFullName: 'Full Name',
      placeholderEmail: 'Email',
      placeholderSessionId: 'Session ID',
      placeholderSessionName: 'Session Name',
      placeholderValidUntil: 'Valid Until',

      buttonCaptionCreateNewMapping: 'Create New Mapping',
      buttonCaptionDeleteMapping: 'Delete Mapping',
      buttonCaptionEditMapping: 'Edit Mapping',
    },
    sessionInvitationPage: {
      title: 'Session Invitation',

      labelCreatedDate: 'Created Date',
      labelEmail: 'Email',
      labelEndTime: 'End Time',
      labelSession: 'Session',
      labelSessionName: 'Session Name',
      labelFullName: 'Full Name',
      labelId: 'ID',
      labelInvitationStatus: 'Invitation Status',
      labelLocation: 'Location',
      labelName: 'Name',
      labelPhone: 'Phone',
      labelRecipient: 'Recipient',
      labelRecipients: 'Recipients',
      labelRegisteredOn: 'Registered On',
      labelResentCount: 'Resent Count',
      labelShortDescription: 'Short Description',
      labelStartTime: 'Start Time',
      labelStatus: 'Status',
      labelUser: 'User',
      labelTimeZone: 'Time Zone',

      buttonCaptionCreateNewSessionInvitation: 'Create New Session Invitation',
      buttonCaptionDeleteSessionInvitation: 'Delete Session Invitation',
      buttonCaptionResend: 'Resend',

      placeholderEmail: 'Email',
      placeholderEndTime: 'End Time',
      placeholderSession: 'Session',
      placeholderFullName: 'Full Name',
      placeholderId: 'ID',
      placeholderInvitationStatus: 'Invitation Status',
      placeholderLocation: 'Location',
      placeholderName: 'Name',
      placeholderPhone: 'Phone',
      placeholderRegisteredOn: 'Registered On',
      placeholderResentCount: 'Resent Count',
      placeholderShortDescription: 'Short Description',
      placeholderStartTime: 'Start Time',
      placeholderTimeZone: 'Time Zone',

      msgResendConfirmation: 'Are you sure want to resend this invitation?',
    },
    sessionPage: {
      title: 'Session',

      labelName: 'Name',
      labelEventName: 'Event Name',
      labelStartTime: 'Start Time',
      labelEndTime: 'End Time',
      labelRoom: 'Room',
      labelQuota: 'Quota',
      labelCreatedDate: 'Created Date',
      labelEvent: 'Event',
      labelWhoCanRegister: 'Who Can Register',
      labelClockInsideArea: 'Clock In Must Be Inside Area',
      labelNeedsApproval: 'Needs Approval',
      labelFeatured: 'Featured',
      labelCanPayUsingPoint: 'Can Pay Using Point',
      labelVisibilityStatus: 'Visibility Status',
      labelStatus: 'Status',
      labelMedia: 'Media',
      labelOrder: 'Order',
      labelYoutube: 'Youtube',
      labelImage: 'Image',
      labelSpeakers: 'Speakers',
      labelAdditionalItems: 'Additional Items',
      labelLabel: 'Label',
      labelType: 'Type',
      labelViewAccess: 'View Access',
      labelVisibilityTime: 'Visibility Time',
      labelContent: 'Content',
      labelUrl: 'URL',
      labelCopied: 'Copied!',

      placeholderBanner: 'Banner',
      placeholderName: 'Name',
      placeholderStartTime: 'Start Time',
      placeholderEndTime: 'End Time',
      placeholderEarliestClockIn: 'Earliest Clock In (minutes)',
      placeholderMaxClockIn: 'Max Clock In (minutes)',
      placeholderFeatured: 'Featured',
      placeholderFeaturedOrder: 'Featured Order',
      placeholderVisibilityStatus: 'Visibility Status',
      placeholderRegistrationRewardType: 'Registration Reward Type',
      placeholderRegistrationRewardPoint: 'Registration Reward Point',
      placeholderRegistrationRewardVouchers: 'Registration Reward Vouchers',
      placeholderEvent: 'Event',
      placeholderRoom: 'Room',
      placeholderCategory: 'Category',
      placeholderDescription: 'Description',
      placeholderShortDescription: 'Short Description',
      placeholderClockInsideArea: 'Clock In Must Be Inside Area',
      placeholderWhoCanRegister: 'Who Can Register',
      placeholderNeedsApproval: 'Needs Approval',
      placeholderQuota: 'Quota',
      placeholderNormalPrice: 'Normal Price',
      placeholderPrice: 'Price',
      placeholderCanPayUsingPoint: 'Can Pay Using Point',
      placeholderSpeaker: 'Speaker',
      placeholderType: 'Type',
      placeholderSurveyFormId: 'Survey Form ID',
      placeholderEnableComment: 'Enable Comment',
      placeholderCommentAccess: 'Comment Access',
      placeholderDiscussionId: 'Discussion ID',
      placeholderClockInQrCode: 'Clock In QR Code',
    },
    sessionRegistrationPage: {
      title: 'Session Registration',

      labelParticipant: 'Participant',
      labelSession: 'Session',
      labelRegistrationCode: 'Registration Code',
      labelRegistrationStatus: 'Registration Status',
      labelApprovedOn: 'Approved On',
      labelRejectedOn: 'Rejected On',
      labelRegisteredOn: 'Registered On',
      labelPaymentStatus: 'Payment Status',
      labelCreatedDate: 'Created Date',
      labelStatus: 'Status',
      labelFree: 'Free',

      placeholderRegistrationCode: 'Registration Code',
      placeholderRegistrationStatus: 'Registration Status',
      placeholderRegistrationStatusNotes: 'Registration Status Notes',
      placeholderApprovedOn: 'Approved On',
      placeholderRejectedOn: 'Rejected On',
      placeholderApprovedBy: 'Approved By',
      placeholderRejectedBy: 'Rejected By',
      placeholderNotes: 'Notes',
      placeholderClockInTime: 'Clock In Time',
      placeholderClockInDrift: 'Clock In Drift (Minutes)',
      placeholderClockInLatitude: 'Clock In Latitude',
      placeholderClockInLongitude: 'Clock In Longitude',
      placeholderClockInLocationStatus: 'Clock In Location Status',
      placeholderUser: 'User',
      placeholderUserID: 'User ID',
      placeholderUserFullName: 'User Full Name',
      placeholderUserEmail: 'User Email',
      placeholderUserPhone: 'User Phone',
      placeholderSession: 'Session',
      placeholderSessionID: 'Session ID',
      placeholderSessionName: 'Session Name',
      placeholderSessionShortDescription: 'Session Short Description',
      placeholderSessionStartTime: 'Session Start Time',
      placeholderSessionEndTime: 'Session End Time',
      placeholderSessionTimezone: 'Session Time Zone',
      placeholderPayment: 'Payment',
      placeholderPaymentID: 'Payment ID',
      placeholderPrice: 'Price',
      placeholderPaymentDate: 'Payment Date',
      placeholderTax: 'Tax',
      placeholderTransactionID: 'Transaction ID',
      placeholderServiceFee: 'Service Fee',
      placeholderPaymentCode: 'Payment Code',
      placeholderDiscount: 'Discount',
      placeholderPaymentChannel: 'Payment Channel',
      placeholderDiscountNotes: 'Discount Notes',
      placeholderPaymentStatus: 'Payment Status',
      placeholderPointDiscount: 'Point Discount',
      placeholderPaymentStatusNotes: 'Payment Status Notes',
      placeholderPointDiscountID: 'Point Discount ID',
      placeholderLastChangedStatus: 'Last Changed Status',
      placeholderTotal: 'Total',

      buttonCaptionApprove: 'Approve',
      buttonCaptionReject: 'Reject',

      msgRejectSessionRegistration: 'Are you sure want to reject this registration?',
      msgApproveSessionRegistration: 'Are you sure want to approve this registration?',
    },
  },
  id: {
    sessionOperatorMenu: {
      title: 'Operator Sesi',
    },
    mappingPage: {
      title: 'Pemetaan Operator Sesi',

      labelNo: 'No',
      labelUserName: 'Nama Pengguna',
      labelEmail: 'Email',
      labelSessionName: 'Nama Sesi',
      labelValidUntil: 'Berlaku Sampai',
      labelCreatedDate: 'Tanggal Pembuatan',

      placeholderUser: 'Pengguna',
      placeholderUserId: 'ID Pengguna',
      placeholderFullName: 'Nama Lengkap',
      placeholderEmail: 'Email',
      placeholderSessionId: 'ID Sesi',
      placeholderSessionName: 'Nama Sesi',
      placeholderValidUntil: 'Berlaku Sampai',

      buttonCaptionCreateNewMapping: 'Buat Pemetaan Baru',
      buttonCaptionDeleteMapping: 'Hapus Pemetaan',
      buttonCaptionEditMapping: 'Ubah Pemetaan',
    },
    sessionInvitationPage: {
      title: 'Undangan Sesi',

      labelCreatedDate: 'Tanggal Pembuatan',
      labelEmail: 'Email',
      labelEndTime: 'Waktu Selesai',
      labelSession: 'Sesi',
      labelSessionName: 'Nama Sesi',
      labelFullName: 'Nama Lengkap',
      labelId: 'ID',
      labelInvitationStatus: 'Status Undangan',
      labelLocation: 'Lokasi',
      labelName: 'Nama',
      labelPhone: 'Telepon',
      labelRecipient: 'Penerima',
      labelRecipients: 'Penerima',
      labelRegisteredOn: 'Terdaftar Di',
      labelResentCount: 'Jumlah Terkirim',
      labelShortDescription: 'Deskripsi Singkat',
      labelStartTime: 'Waktu Mulai',
      labelStatus: 'Status',
      labelUser: 'Pengguna',
      labelTimeZone: 'Zona Waktu',

      buttonCaptionCreateNewSessionInvitation: 'Buat Undangan Sesi Baru',
      buttonCaptionDeleteSessionInvitation: 'Hapus Undangan Sesi',
      buttonCaptionResend: 'Kirim Ulang',

      placeholderEmail: 'Email',
      placeholderEndTime: 'Waktu Selesai',
      placeholderSession: 'Sesi',
      placeholderFullName: 'Nama Lengkap',
      placeholderId: 'ID',
      placeholderInvitationStatus: 'Status Undangan',
      placeholderLocation: 'Lokasi',
      placeholderName: 'Nama',
      placeholderPhone: 'Telepon',
      placeholderRegisteredOn: 'Terdaftar Di',
      placeholderResentCount: 'Jumlah Terkirim',
      placeholderShortDescription: 'Deskripsi Singkat',
      placeholderStartTime: 'Waktu Mulai',
      placeholderTimeZone: 'Zona Waktu',

      msgResendConfirmation: 'Apa Anda yakin ingin mengirim ulang undangan ini?',
    },
    sessionPage: {
      title: 'Sesi',

      labelName: 'Nama',
      labelEventName: 'Nama Acara',
      labelStartTime: 'Waktu Mulai',
      labelEndTime: 'Waktu Selesai',
      labelRoom: 'Ruangan',
      labelQuota: 'Kuota',
      labelCreatedDate: 'Tanggal Dibuat',
      labelEvent: 'Acara',
      labelWhoCanRegister: 'Yang Dapat Mendaftar',
      labelClockInsideArea: 'Jam Masuk Harus Di Dalam Area',
      labelNeedsApproval: 'Butuh Persetujuan',
      labelFeatured: 'Unggulan',
      labelCanPayUsingPoint: 'Dapat Membayar Menggunakan Poin',
      labelVisibilityStatus: 'Status Visibilitas',
      labelStatus: 'Status',
      labelMedia: 'Media',
      labelOrder: 'Urutan',
      labelYoutube: 'Youtube',
      labelImage: 'Gambar',
      labelSpeakers: 'Pembicara',
      labelAdditionalItems: 'Materi Tambahan',
      labelLabel: 'Label',
      labelType: 'Tipe',
      labelViewAccess: 'Akses Pratinjau',
      labelVisibilityTime: 'Waktu Visibilitas',
      labelContent: 'Isi',
      labelUrl: 'URL',
      labelCopied: 'Disalin!',

      placeholderBanner: 'Spanduk',
      placeholderName: 'Nama',
      placeholderStartTime: 'Waktu Mulai',
      placeholderEndTime: 'Waktu Selesai',
      placeholderEarliestClockIn: 'Jam Masuk Awal (menit)',
      placeholderMaxClockIn: 'Maks Jam Masuk (menit)',
      placeholderFeatured: 'Unggulan',
      placeholderFeaturedOrder: 'Urutan Unggulan',
      placeholderVisibilityStatus: 'Status Visibilitas',
      placeholderRegistrationRewardType: 'Jenis Hadiah Pendaftaran',
      placeholderRegistrationRewardPoint: 'Poin Hadiah Pendaftaran',
      placeholderRegistrationRewardVouchers: 'Voucher Hadiah Pendaftaran',
      placeholderEvent: 'Acara',
      placeholderRoom: 'Ruangan',
      placeholderCategory: 'Kategori',
      placeholderDescription: 'Deskripsi',
      placeholderShortDescription: 'Deskripsi Singkat',
      placeholderClockInsideArea: 'Jam Masuk Harus Di Dalam Area',
      placeholderWhoCanRegister: 'Yang Dapat Mendaftar',
      placeholderNeedsApproval: 'Butuh Persetujuan',
      placeholderQuota: 'Kuota',
      placeholderNormalPrice: 'Harga Normal',
      placeholderPrice: 'Harga',
      placeholderCanPayUsingPoint: 'Dapat Membayar Menggunakan Poin',
      placeholderSpeaker: 'Pembicara',
      placeholderType: 'Tipe',
      placeholderSurveyFormId: 'ID Formulir Survei',
      placeholderEnableComment: 'Aktifkan Komentar',
      placeholderCommentAccess: 'Akses Komentar',
      placeholderDiscussionId: 'ID Diskusi',
      placeholderClockInQrCode: 'Kode QR Absen Masuk',
    },
    sessionRegistrationPage: {
      title: 'Registrasi Sesi',

      labelParticipant: 'Peserta',
      labelSession: 'Sesi',
      labelRegistrationCode: 'Kode Registrasi',
      labelRegistrationStatus: 'Status Registrasi',
      labelApprovedOn: 'Disetujui Pada',
      labelRejectedOn: 'Ditolak Pada',
      labelRegisteredOn: 'Terdaftar Pada',
      labelPaymentStatus: 'Status Pembayaran',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelStatus: 'Status',
      labelFree: 'Gratis',

      placeholderRegistrationCode: 'Kode Registrasi',
      placeholderRegistrationStatus: 'Status Registrasi',
      placeholderRegistrationStatusNotes: 'Catatan Status Registrasi',
      placeholderApprovedOn: 'Disetujui Pada',
      placeholderRejectedOn: 'Ditolak Pada',
      placeholderApprovedBy: 'Disetujui Oleh',
      placeholderRejectedBy: 'Ditolak Oleh',
      placeholderNotes: 'Catatan',
      placeholderClockInTime: 'Waktu Jam Masuk',
      placeholderClockInDrift: 'Perbedaan Jam Masuk (Menit)',
      placeholderClockInLatitude: 'Posisi Garis Lintang Jam Masuk',
      placeholderClockInLongitude: 'Posisi Garis Bujur Jam Masuk',
      placeholderClockInLocationStatus: 'Status lokasi Jam Masuk',
      placeholderUser: 'Pengguna',
      placeholderUserID: 'ID Pengguna',
      placeholderUserFullName: 'Nama Lengkap Pengguna',
      placeholderUserEmail: 'Email Pengguna',
      placeholderUserPhone: 'Telepon Pengguna',
      placeholderSession: 'Sesi',
      placeholderSessionID: 'ID Sesi',
      placeholderSessionName: 'Nama Sesi',
      placeholderSessionShortDescription: 'Deskripsi Singkat Sesi',
      placeholderSessionStartTime: 'Waktu Mulai Sesi',
      placeholderSessionEndTime: 'Waktu Berakhir Sesi',
      placeholderSessionTimezone: 'Zona Waktu Sesi',
      placeholderPayment: 'Pembayaran',
      placeholderPaymentID: 'ID Pembayaran',
      placeholderPrice: 'Harga',
      placeholderPaymentDate: 'Tanggal Pembayaran',
      placeholderTax: 'Pajak',
      placeholderTransactionID: 'ID Transaksi',
      placeholderServiceFee: 'Biaya Pelayanan',
      placeholderPaymentCode: 'Kode Pembayaran',
      placeholderDiscount: 'Diskon',
      placeholderPaymentChannel: 'Saluran Pembayaran',
      placeholderDiscountNotes: 'Catatan Diskon',
      placeholderPaymentStatus: 'Status Pembayaran',
      placeholderPointDiscount: 'Poin Diskon',
      placeholderPaymentStatusNotes: 'Catatan Status Pembayaran',
      placeholderPointDiscountID: 'ID Poin Diskon',
      placeholderLastChangedStatus: 'Perubahan Status Terakhir',
      placeholderTotal: 'Total',

      buttonCaptionApprove: 'Setuju',
      buttonCaptionReject: 'Tolak',

      msgRejectSessionRegistration: 'Apa Anda yakin ingin menolak registrasi ini?',
      msgApproveSessionRegistration: 'Apa anda yakin ingin menyetujui registrasi ini?',
    },
  },
});

export default LocalizedString;
