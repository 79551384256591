import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardActionArea, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  COLOR_BACKGROUND, COLOR_BLACK_HOVER_OVERLAY, COLOR_BLACK_MEDIUM_EMPHASIS, COLOR_DISABLED_CONTROL,
  COLOR_ERROR, COLOR_PRIMARY, BUTTON_TYPE_OUTLINED,
} from '../../constant';
import LocalizedString from '../../localization';
import { StyleShape } from '../../type';
import ImagePickerDialog from '../image-picker-dialog';
import { BodySmall, Body } from '../labels';
import VerticalSpacer from '../vertical-spacer';
import AccentButton from '../accent-button';

const styles = {
  labelText: {
    color: COLOR_BLACK_MEDIUM_EMPHASIS,
  },
  pictureContainer: {
    border: `1px dashed ${COLOR_BLACK_HOVER_OVERLAY}`,
    padding: '16px 0',
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  uploadButtonText: {
    color: COLOR_PRIMARY,
    fontWeight: '500',
  },
  uploadButtonContainer: {
    border: `1px solid ${COLOR_BLACK_HOVER_OVERLAY}`,
    borderRadius: '4px',
    width: '100%',
  },
  errorTextStyle: {
    color: COLOR_ERROR,
  },
  deleteIconContainer: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    width: '28px',
    height: '28px',
    borderRadius: '14px',
    backgroundColor: COLOR_DISABLED_CONTROL,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imgStyle: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
};

const onPictureTaken = (onButtonPress, image, closeSelfieModal, closeImagePickerDialog) => {
  onButtonPress(image, 'selfie.jpg');
  closeSelfieModal();
  closeImagePickerDialog();
};

const onPictureSelected = (
  onButtonPress,
  selectedPicture,
  closeImagePickerDialog,
  displayAlertErrorDialog,
) => {
  const { files } = selectedPicture;
  if (files && files[0]) {
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;
      image.name = files[0]?.name || files[0].lastModified;
      image.onload = async () => {
        try {
          onButtonPress(image.src, image.name);
          closeImagePickerDialog();
        } catch (error) {
          displayAlertErrorDialog(error);
          closeImagePickerDialog();
        }
      };
    };
  }
};

const UploadPictureField = ({
  disabled, image, buttonCaption, launchingImagePicker,
  onAppear, onButtonPress, onImagePickerPressed,
  imageStyle, error, mustUseCamera, onView, openSelfieModal,
  closeSelfieModal, closeImagePickerDialog, displayAlertErrorDialog,
  onDeleteButtonPressed,
  ...props
}) => {
  useEffect(onAppear, []);

  const defaultPicOptions = { maxWidth: 720 };
  // eslint-disable-next-line no-unused-vars
  const propsOptions = Object.fromEntries(Object.entries(props).filter(([key, value]) => typeof value !== 'function'));
  const options = Object.assign(defaultPicOptions, propsOptions);

  const onUploadPressed = () => {
    if (mustUseCamera) {
      openSelfieModal();
    } else {
      onImagePickerPressed();
    }
  };

  const onDeletePressed = () => {
    onButtonPress('', '');
    onDeleteButtonPressed();
  };

  const renderPict = () => {
    if (image) {
      return (
        <div style={styles.imageGrid}>
          <CardActionArea
            onClick={() => onView(image)}
            disabled={disabled}
            style={imageStyle}
          >
            <img
              alt="uploaded-pic"
              src={image}
              style={styles.imgStyle}
            />
          </CardActionArea>

          <div style={styles.deleteIconContainer}>
            <IconButton
              disabled={disabled}
              aria-label="close"
              onClick={onDeletePressed}
            >
              <Close sx={{ fontSize: 25, color: COLOR_BACKGROUND }} />
            </IconButton>
          </div>
        </div>
      );
    }
    return (
      <BodySmall style={{ color: COLOR_DISABLED_CONTROL }}>
        {LocalizedString.common.labelNoPhoto}
      </BodySmall>
    );
  };

  const renderError = () => {
    if (error) {
      return (<Body style={styles.errorTextStyle}>{error}</Body>);
    }
    return null;
  };

  return (
    <div>
      <div style={styles.pictureContainer}>
        {renderPict()}
      </div>

      <VerticalSpacer height={8} />

      <AccentButton
        variant={BUTTON_TYPE_OUTLINED}
        caption={`${LocalizedString.common.labelUpload} ${buttonCaption}`}
        onClick={onUploadPressed}
        disabled={disabled || launchingImagePicker}
        containerStyle={{ ...styles.uploadButtonContainer, ...styles.uploadButtonText }}
      />

      <VerticalSpacer height={5} />

      {renderError()}

      <ImagePickerDialog
        onPictureSelected={(selectedImage) => onPictureSelected(
          onButtonPress,
          selectedImage,
          closeImagePickerDialog,
          displayAlertErrorDialog,
        )}
        pictureOptions={options}
        onPhotoTaken={(takenImage) => onPictureTaken(
          onButtonPress,
          takenImage,
          closeSelfieModal,
          closeImagePickerDialog,
        )}
      />
    </div>
  );
};

export default UploadPictureField;

UploadPictureField.propTypes = {
  imageStyle: StyleShape,
  disabled: PropTypes.bool,
  launchingImagePicker: PropTypes.bool.isRequired,
  mustUseCamera: PropTypes.bool,
  image: PropTypes.string,
  error: PropTypes.string,
  buttonCaption: PropTypes.string.isRequired,
  onAppear: PropTypes.func.isRequired,
  onButtonPress: PropTypes.func.isRequired,
  onImagePickerPressed: PropTypes.func.isRequired,
  openSelfieModal: PropTypes.func.isRequired,
  closeSelfieModal: PropTypes.func.isRequired,
  closeImagePickerDialog: PropTypes.func.isRequired,
  displayAlertErrorDialog: PropTypes.func.isRequired,
  onView: PropTypes.func,
  onDeleteButtonPressed: PropTypes.func,
};

UploadPictureField.defaultProps = {
  imageStyle: { width: '90%', height: 200 },
  disabled: false,
  mustUseCamera: false,
  image: '',
  error: '',
  onView: () => {},
  onDeleteButtonPressed: () => {},
};
