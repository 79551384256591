import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import {
  approval,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW,
  status, STATUS_DISABLED, STATUS_ENABLED,
  RXFORM_FORM,
  RXSTATE_DFORM_FORM, RXSTATE_DFORM_FORM_PAGE,
  RXFIELD_DFORM_FORM_DESCRIPTION,
  RXFIELD_DFORM_FORM_NAME,
  RXFIELD_DFORM_FORM_CREATED_DATE,
  RXFIELD_DFORM_FORM_VALID_FROM,
  RXFIELD_DFORM_FORM_VALID_UNTIL,
  RXFIELD_DFORM_FORM_VISIBILITY_STATUS,
  RXFIELD_DFORM_FORM_CLOSE_RESPONSE,
  RXFIELD_DFORM_FORM_ALLOW_DIRECT_RESPONSE,
  RXFIELD_DFORM_FORM_STATUS,
  RXFIELD_DFORM_FORM_TITLE,
  RXFIELD_DFORM_FORM_TAGS,
  RXFIELD_DFORM_FORM_DETECT_DUPLICATED_RESPONSE,
  visibilityStatuses,
  FILTER_TYPE_SWITCH,
} from '../../constant';
import { FormInitialValueShape } from '../../type';
import {
  renderReduxFormDateTimePickerField, renderReduxFormOutlinedDropdownTextField,
  renderReduxFormOutlinedTextField, renderReduxFormSimpleDropdownField,
} from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { rxformValidateForm } from '../../validation';
import { FunctionalPage } from '../../component';
import { toMoment } from '../../helper';
import DuplicateDialog from './duplicate-dialog';

const useStyles = makeStyles(() => ({
  dialogHeader: {
    marginBottom: 20,
  },
}));

const renderDialogContent = (
  addingEditing, pageMode, defaultVisibilityStatusValue, onVisibilityStatusOptionSelected,
  initialValues, onAllowDirectResponseOptionSelected, onCloseResponseOptionSelected,
  onDetectDuplicatedResponseBasedOnReferenceIdOptionSelected,
) => (
  <div>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_DFORM_FORM_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.formPage.labelName}
            label={LocalizedString.formPage.labelName}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DFORM_FORM_TITLE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.formPage.labelTitle}
            label={LocalizedString.formPage.labelTitle}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DFORM_FORM_TAGS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.formPage.labelTags}
            label={LocalizedString.formPage.labelTags}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DFORM_FORM_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.formPage.labelDescription}
            label={LocalizedString.formPage.labelDescription}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            multiline
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DFORM_FORM_DETECT_DUPLICATED_RESPONSE}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.formPage.labelDetectDuplicatedResponse}
            label={LocalizedString.formPage.labelDetectDuplicatedResponse}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={approval}
            value={initialValues.detectDuplicatedResponseBasedOnReferenceId}
            onOptionSelected={onDetectDuplicatedResponseBasedOnReferenceIdOptionSelected}
            onBlur={(e) => e.preventDefault()}
            required
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_DFORM_FORM_VALID_FROM}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.formPage.labelValidFrom}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DFORM_FORM_VALID_UNTIL}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.formPage.labelValidUntil}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DFORM_FORM_VISIBILITY_STATUS}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.formPage.labelVisibilityStatus}
            label={LocalizedString.formPage.labelVisibilityStatus}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={visibilityStatuses}
            value={defaultVisibilityStatusValue}
            onOptionSelected={onVisibilityStatusOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DFORM_FORM_ALLOW_DIRECT_RESPONSE}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.formPage.labelAllowDirectResponse}
            label={LocalizedString.formPage.labelAllowDirectResponse}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={approval}
            value={initialValues.allowDirectUserResponse}
            onOptionSelected={onAllowDirectResponseOptionSelected}
            onBlur={(e) => e.preventDefault()}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DFORM_FORM_CLOSE_RESPONSE}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.formPage.labelCloseResponse}
            label={LocalizedString.formPage.labelCloseResponse}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={approval}
            value={initialValues.closeFormResponse}
            onOptionSelected={onCloseResponseOptionSelected}
            onBlur={(e) => e.preventDefault()}
            required
          />
        </Grid>
      </Grid>
    </Grid>
  </div>
);

const FormPage = ({
  initialValues,
  addingEditing, downloading, duplicateDialogVisibility, enablingDisabling,
  handleSubmit, onAppear, onApplyAdvancedFilterPressed,
  onCancelPressed,
  onChangePage, onChangePageSize,
  onConfirmContextMenuPressed, onCreatePressed, onCancelDialogPressed,
  onEditPressed, onContextMenuPressed, onDuplicatePressed,
  onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed,
  onSubmitPressed, onViewPressed,
  currentFormStatus, pageMode, tappedId,
  onDeletePressed, onSavePressed,
  onVisibilityStatusOptionSelected,
  onAllowDirectResponseOptionSelected,
  onCloseResponseOptionSelected, onDetectDuplicatedResponseBasedOnReferenceIdOptionSelected,
  onSendFormPressed, duplicatingForm, isDuplicateAllowed, createPermissionName,
}) => {
  const classes = useStyles();

  return (
    <div>
      <DuplicateDialog
        submitting={duplicatingForm}
        visibility={duplicateDialogVisibility}
        onCancelPressed={onCancelDialogPressed}
        onSendPressed={onSendFormPressed}
      />
      <FunctionalPage
        data={RXSTATE_DFORM_FORM}
        uiPage={RXSTATE_DFORM_FORM_PAGE}
        moreMenus={[
          {
            caption: GlobalLocalizedString.common.buttonCaptionEnable,
            disabled: currentFormStatus === STATUS_ENABLED || enablingDisabling,
            onPress: () => onContextMenuPressed(tappedId,
              GlobalLocalizedString.common.msgEnableConfirmation),
          },
          {
            caption: GlobalLocalizedString.common.buttonCaptionDisable,
            disabled: currentFormStatus === STATUS_DISABLED || enablingDisabling,
            onPress: () => onContextMenuPressed(tappedId,
              GlobalLocalizedString.common.msgDisableConfirmation),
          },
          {
            caption: LocalizedString.formPage.buttonCaptionDuplicate,
            disabled: !isDuplicateAllowed,
            onPress: () => onDuplicatePressed(tappedId),
          },
        ]}
        tableColumns={[
          {
            title: GlobalLocalizedString.common.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
          },
          { title: LocalizedString.formPage.labelName, field: 'name', sorting: !downloading },
          {
            title: LocalizedString.formPage.labelValidFrom,
            field: 'validFrom',
            sorting: !downloading,
            render: ({ validFrom }) => (validFrom
              ? toMoment(validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
          },
          {
            title: LocalizedString.formPage.labelValidUntil,
            field: 'validUntil',
            sorting: !downloading,
            render: ({ validUntil }) => (validUntil
              ? toMoment(validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
          },
          { title: LocalizedString.formPage.labelVisibility, field: 'visibilityStatus', sorting: !downloading },
          {
            title: LocalizedString.formPage.labelAllowDirectResponse,
            field: 'allowDirectUserResponse',
            sorting: !downloading,
            render: ({ allowDirectUserResponse }) => {
              if (allowDirectUserResponse === true) return approval[0].label;
              if (allowDirectUserResponse === false) return approval[1].label;
              return '';
            },
          },
          {
            title: LocalizedString.formPage.labelCloseResponse,
            field: 'closeFormResponse',
            sorting: !downloading,
            render: ({ closeFormResponse }) => {
              if (closeFormResponse === true) return approval[0].label;
              if (closeFormResponse === false) return approval[1].label;
              return '';
            },
          },
          {
            title: GlobalLocalizedString.common.placeholderCreatedDate,
            field: 'createdDate',
            sorting: !downloading,
            render: ({ createdDate }) => (createdDate
              ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
          },
        ]}
        filterColumns={[
          {
            title: LocalizedString.formPage.labelCreatedDate,
            field: RXFIELD_DFORM_FORM_CREATED_DATE,
            type: FILTER_TYPE_DATE_RANGE,
          },
          {
            title: LocalizedString.formPage.labelValidFrom,
            field: RXFIELD_DFORM_FORM_VALID_FROM,
            type: FILTER_TYPE_DATE_RANGE,
          },
          {
            title: LocalizedString.formPage.labelValidUntil,
            field: RXFIELD_DFORM_FORM_VALID_UNTIL,
            type: FILTER_TYPE_DATE_RANGE,
          },
          {
            title: LocalizedString.formPage.labelVisibilityStatus,
            field: RXFIELD_DFORM_FORM_VISIBILITY_STATUS,
            type: FILTER_TYPE_DROPDOWN,
            data: visibilityStatuses,
          },
          {
            title: LocalizedString.formPage.labelCloseResponseQuestion,
            field: RXFIELD_DFORM_FORM_CLOSE_RESPONSE,
            type: FILTER_TYPE_SWITCH,
            data: approval,
          },
          {
            title: LocalizedString.formPage.labelAllowDirectResponseQuestion,
            field: RXFIELD_DFORM_FORM_ALLOW_DIRECT_RESPONSE,
            type: FILTER_TYPE_SWITCH,
            data: approval,
          },
          {
            title: LocalizedString.formPage.labelStatus,
            field: RXFIELD_DFORM_FORM_STATUS,
            type: FILTER_TYPE_SWITCH,
            data: status,
          },
        ]}
        handleSubmit={handleSubmit}
        onAppear={onAppear}
        onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
        onCancelPressed={onCancelPressed}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onConfirmDeletePressed={onConfirmContextMenuPressed}
        onCreatePressed={onCreatePressed}
        onEditPressed={(id) => onEditPressed(id)}
        onRefresh={onRefresh}
        onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
        onSavePressed={onSavePressed}
        onSearchBarTextChanged={onSearchBarTextChanged}
        onSortPressed={onSortPressed}
        onSubmitPressed={onSubmitPressed}
        onViewPressed={(id) => onViewPressed(id)}
        createNewButtonCaption={LocalizedString.formPage.buttonCaptionCreateNewForm}
        deleteButtonCaption={LocalizedString.formPage.buttonCaptionDeleteForm}
        editButtonCaption={LocalizedString.formPage.buttonCaptionEditForm}
        title={LocalizedString.formPage.title}
        addDialogHeaderContainerStyle={classes.dialogHeader}
        onDeletePressed={onDeletePressed}
        useFullWidth
        useFullWidthDialog
        createPermissionName={createPermissionName}
        deletePermissionName="DFORM_DELETE_FORM_RESPONSE"
        editPermissionName="DFORM_EDIT_FORM_RESPONSE"
      >
        {renderDialogContent(
          addingEditing, pageMode, initialValues.visibilityStatus, onVisibilityStatusOptionSelected,
          initialValues, onAllowDirectResponseOptionSelected, onCloseResponseOptionSelected,
          onDetectDuplicatedResponseBasedOnReferenceIdOptionSelected,
        )}
      </FunctionalPage>
    </div>
  );
};

export default reduxForm({
  form: RXFORM_FORM,
  validate: rxformValidateForm,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(FormPage);

FormPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  duplicateDialogVisibility: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  duplicatingForm: PropTypes.bool.isRequired,
  isDuplicateAllowed: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onCancelDialogPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  onDuplicatePressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSendFormPressed: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onVisibilityStatusOptionSelected: PropTypes.func.isRequired,
  onAllowDirectResponseOptionSelected: PropTypes.func.isRequired,
  onCloseResponseOptionSelected: PropTypes.func.isRequired,
  onDetectDuplicatedResponseBasedOnReferenceIdOptionSelected: PropTypes.func.isRequired,
  createPermissionName: PropTypes.string.isRequired,
  currentFormStatus: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
};
