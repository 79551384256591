import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress, Paper, Typography, makeStyles, withStyles,
} from '@material-ui/core';
import {
  Chart, Legend, PieSeries, Title, Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation, EventTracker, HoverState } from '@devexpress/dx-react-chart';
import classNames from 'clsx';
import LocalizedString from '../localization';

const useStyles = makeStyles(() => ({
  loading: {
    display: 'flex',
    height: 550,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const styles = (theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    marginBottom: 0,
    marginRight: theme.spacing(2),
  },
});

const setStyle = (style) => {
  const wrap = withStyles({ root: style });
  return (Target) => wrap(({ classes, className, ...restProps }) => (
    <Target className={classNames(classes.root, className)} {...restProps} />
  ));
};

const TooltipTitle = setStyle({
  fontWeight: 'bold',
  paddingBottom: 0,
})(Tooltip.Content);

const TooltipBody = setStyle({
  paddingTop: 0,
})(Tooltip.Content);

const legendTitle = withStyles(styles)(({ classes, ...restProps }) => (
  <div>
    <Typography className={classes.title}>{LocalizedString.common.labelLegend}</Typography>
    <Legend.Root {...restProps} />
  </div>
));

const PieChart = ({
  data, loading, argumentField, title, valueField,
}) => {
  const [targetItem, setTargetItem] = useState(null);

  const classes = useStyles();

  const TooltipContent = () => (
    <div>
      <div>
        <TooltipTitle
          text={data[targetItem.point][argumentField]}
        />
      </div>
      <div>
        <TooltipBody
          text={data[targetItem.point][valueField]}
        />
      </div>
    </div>
  );
  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress color="inherit" size={20} style={{ margin: 10 }} />
      </div>
    );
  }
  return (
    <Paper>
      <Chart data={data} height={550}>
        <PieSeries valueField={valueField} argumentField={argumentField} />
        <Legend rootComponent={legendTitle} position="left" />
        <Title text={title} />
        <EventTracker />
        <HoverState />
        <Tooltip
          targetItem={targetItem}
          onTargetItemChange={(target) => setTargetItem(target)}
          contentComponent={TooltipContent}
        />
        <Animation />
      </Chart>
    </Paper>
  );
};

export default PieChart;

PieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  argumentField: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
};

PieChart.defaultProps = {
  loading: false,
};
