import moment from 'moment';
import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_DFORM_FORM_NAME,
  RXFIELD_DFORM_FORM_CREATED_DATE,
  RXFIELD_DFORM_FORM_VALID_FROM,
  RXFIELD_DFORM_FORM_VALID_UNTIL,
  RXFIELD_DFORM_FORM_VISIBILITY_STATUS,
  RXFIELD_DFORM_FORM_CLOSE_RESPONSE,
  RXFIELD_DFORM_FORM_ALLOW_DIRECT_RESPONSE,
  RXFIELD_DFORM_FORM_STATUS,
  RXFIELD_DFORM_FORM_TITLE,
  RXFIELD_DFORM_FORM_DETECT_DUPLICATED_RESPONSE,
  RXFIELD_DFORM_FORM_DESCRIPTION,
  RXFIELD_DFORM_FIELD_FIELD_TYPE,
  RXFIELD_DFORM_FIELD_LABEL,
  RXFIELD_DFORM_FIELD_NAME,
  RXFIELD_DFORM_FIELD_ORDER,
  ENUM_FIELD_DATE,
  ENUM_FIELD_DATE_TIME,
  ENUM_FIELD_TIME,
  ENUM_FIELD_TEXT,
  ENUM_FIELD_INTEGER,
  ENUM_FIELD_FLOAT,
  ENUM_FIELD_PICKER,
  ENUM_FIELD_OPTIONS,
  TIME_FORMAT,
  RXFIELD_DFORM_FIELD_FORM,
  RXFIELD_DFORM_FORM_NAME_DIALOG,
  RXFIELD_DFORM_PREVIEW_REFERENCE_ID,
  RXFIELD_DFORM_PREVIEW_REFERENCE_LABEL,
} from './constant';
import GlobalLocalizedString from '../../localization';
import { toNumber } from './helper';

export const rxformValidateForm = (values) => {
  const requiredFields = [
    RXFIELD_DFORM_FORM_NAME,
    RXFIELD_DFORM_FORM_CREATED_DATE,
    RXFIELD_DFORM_FORM_VALID_FROM,
    RXFIELD_DFORM_FORM_VALID_UNTIL,
    RXFIELD_DFORM_FORM_VISIBILITY_STATUS,
    RXFIELD_DFORM_FORM_CLOSE_RESPONSE,
    RXFIELD_DFORM_FORM_ALLOW_DIRECT_RESPONSE,
    RXFIELD_DFORM_FORM_STATUS,
    RXFIELD_DFORM_FORM_TITLE,
    RXFIELD_DFORM_FORM_DETECT_DUPLICATED_RESPONSE,
    RXFIELD_DFORM_FORM_DESCRIPTION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateField = (values) => {
  const requiredFields = [
    RXFIELD_DFORM_FIELD_FIELD_TYPE,
    RXFIELD_DFORM_FIELD_LABEL,
    RXFIELD_DFORM_FIELD_NAME,
    RXFIELD_DFORM_FIELD_ORDER,
    RXFIELD_DFORM_FIELD_FORM,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  switch (values.fieldType) {
    case ENUM_FIELD_DATE:
      if (values.dateTimeMaxValue && values.dateTimeMinValue
        && !moment(values.dateTimeMaxValue).isAfter(moment(values.dateTimeMinValue))) {
        errors.dateTimeMaxValue = GlobalLocalizedString.common.errMsgStartEndDate;
      } break;
    case ENUM_FIELD_DATE_TIME:
      if (values.dateTimeMaxValue && values.dateTimeMinValue
        && !moment(values.dateTimeMaxValue).isAfter(moment(values.dateTimeMinValue))) {
        errors.dateTimeMaxValue = GlobalLocalizedString.common.errMsgStartEndDate;
      } break;
    case ENUM_FIELD_TIME:
      if (values.dateTimeMaxValue && values.dateTimeMinValue
        && !moment(values.dateTimeMaxValue, TIME_FORMAT)
          .isAfter(moment(values.dateTimeMinValue, TIME_FORMAT))) {
        errors.dateTimeMaxValue = GlobalLocalizedString.common.errMsgStartEndTime;
      } break;
    case ENUM_FIELD_TEXT:
      if (!values.textKeyboardType) {
        errors.textKeyboardType = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      }
      if (values.textMinLength && values.textMaxLength
        && (toNumber(values.textMinLength) > toNumber(values.textMaxLength)
          || toNumber(values.textMaxLength) < toNumber(values.textMinLength))) {
        errors.textMaxLength = GlobalLocalizedString.common.errMsgMinMaxValue;
      } break;
    case ENUM_FIELD_INTEGER:
      if (values.intMinValue && values.intMaxValue
        && (toNumber(values.intMinValue) > toNumber(values.intMaxValue)
          || toNumber(values.intMaxValue) < toNumber(values.intMinValue))) {
        errors.intMaxValue = GlobalLocalizedString.common.errMsgMinMaxValue;
      } break;
    case ENUM_FIELD_FLOAT:
      if (values.floatMinValue && values.floatMaxValue
        && (parseFloat(values.floatMinValue) > parseFloat(values.floatMaxValue)
          || parseFloat(values.floatMaxValue) < parseFloat(values.floatMinValue))) {
        errors.floatMaxValue = GlobalLocalizedString.common.errMsgMinMaxValue;
      } break;
    case ENUM_FIELD_PICKER:
      if (!values.pickerMode) {
        errors.pickerMode = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      }
      if (!values.optionValues) {
        errors.optionValues = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      } break;
    case ENUM_FIELD_OPTIONS:
      if (!values.optionMode) {
        errors.optionMode = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      }
      if (!values.optionValues) {
        errors.optionValues = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      }
      if (!values.optionValueSeparator) {
        errors.optionValueSeparator = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      }
      break;
    default: break;
  }

  return errors;
};

export const rxformValidateFormDialog = (values) => {
  const requiredFields = [
    RXFIELD_DFORM_FORM_NAME_DIALOG,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidatePreviewForm = (values, props) => {
  let requiredFields = [];
  if (props?.selectedForm && props?.selectedForm?.fields) {
    requiredFields = props.selectedForm.fields.reduce((newArr, field) => {
      if (field.required === true) {
        newArr.push(field.name);
      }
      return newArr;
    }, []);
  }
  const errors = rxFormValidateRequiredFields(
    values,
    [...requiredFields, RXFIELD_DFORM_PREVIEW_REFERENCE_ID, RXFIELD_DFORM_PREVIEW_REFERENCE_LABEL],
  );
  return errors;
};
