import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    eventMenu: {
      title: 'Event',
    },
    categoryPage: {
      title: 'Event Category',

      labelClearImage: 'Clear Image',
      labelDescription: 'Description',
      labelImage: 'Image',
      labelName: 'Name',
      labelStatus: 'Status',

      buttonCaptionCreateNewCategory: 'Create New Event Category',
      buttonCaptionDeleteCategory: 'Delete Event Category',
      buttonCaptionEditCategory: 'Edit Event Category',

      placeholderClearImage: 'Clear Image',
      placeholderDescription: 'Description',
      placeholderName: 'Name',
    },
    sessionCategoryPage: {
      title: 'Session Category',

      labelDescription: 'Description',
      labelName: 'Name',
      labelStatus: 'Status',

      buttonCaptionCreateNewSessionCategory: 'Create New Session Category',
      buttonCaptionDeleteSessionCategory: 'Delete Session Category',
      buttonCaptionEditSessinCategory: 'Edit Session Category',

      placeholderDescription: 'Description',
      placeholderName: 'Name',
    },
    sponsorCategoryPage: {
      title: 'Sponsor Category',

      labelDescription: 'Description',
      labelName: 'Name',
      labelStatus: 'Status',
      labelOrder: 'Order',

      buttonCaptionCreateNewSponsorCategory: 'Create New Sponsor Category',
      buttonCaptionDeleteSponsorCategory: 'Delete Sponsor Category',
      buttonCaptionEditSessinCategory: 'Edit Sponsor Category',

      placeholderDescription: 'Description',
      placeholderName: 'Name',
      placeholderOrder: 'Order',
    },
    speakerPage: {
      title: 'Speaker',

      labelCompany: 'Company',
      labelDescription: 'Description',
      labelImage: 'Image',
      labelJobTitle: 'Job Title',
      labelName: 'Name',
      labelStatus: 'Status',

      buttonCaptionCreateNewSpeaker: 'Create New Speaker',
      buttonCaptionDeleteSpeaker: 'Delete Speaker',
      buttonCaptionEditSpeaker: 'Edit Speaker',

      placeholderCompany: 'Company',
      placeholderDescription: 'Description',
      placeholderJobTitle: 'Job Title',
      placeholderName: 'Name',
    },
    locationPage: {
      title: 'Location',

      labelNo: 'No',
      labelName: 'Name',
      labelCity: 'City',
      labelAddress: 'Address',
      labelGoogleMap: 'Google Map',
      labelLongitude: 'Longitude',
      labelLatitude: 'Latitude',
      labelTimeZone: 'Time Zone',
      labelProvince: 'Province',
      labelStatus: 'Status',
      labelBorderArea: 'Border Area',

      buttonCaptionCreateNewLocation: 'Create New Location',
      buttonCaptionEditLocation: 'Edit Location',
      buttonCaptionDeleteLocation: 'Delete Location',

      placeholderLocationName: 'Location Name',
      placeholderCityName: 'City',
      placeholderAddress: 'Address',
      placeholderDescription: 'Description',
      placeholderPhone: 'Phone',
      placeholderEmail: 'Email',
      placeholderPostalCode: 'Postal Code',
      placeholderGoogleMapUrl: 'Google Map URL',
      placeholderLatitude: 'Latitude',
      placeholderLongitude: 'Longitude',
    },
    roomPage: {
      title: 'Room',

      labelDescription: 'Description',
      labelName: 'Name',
      labelStatus: 'Status',
      labelLocation: 'Location',
      labelLocationName: 'Location Name',
      labelLocationId: 'Location ID',

      buttonCaptionCreateNewRoom: 'Create New Room',
      buttonCaptionDeleteRoom: 'Delete Room',
      buttonCaptionEditRoom: 'Edit Room',

      placeholderDescription: 'Description',
      placeholderName: 'Name',
      placeholderLocation: 'Location',
      placeholderLocationName: 'Location Name',
      placeholderLocationId: 'Location ID',
    },
    sessionPage: {
      title: 'Session',

      labelName: 'Name',
      labelEventName: 'Event Name',
      labelStartTime: 'Start Time',
      labelEndTime: 'End Time',
      labelRoom: 'Room',
      labelQuota: 'Quota',
      labelCreatedDate: 'Created Date',
      labelEvent: 'Event',
      labelWhoCanRegister: 'Who Can Register',
      labelClockInsideArea: 'Clock In Must Be Inside Area',
      labelNeedsApproval: 'Needs Approval',
      labelFeatured: 'Featured',
      labelCanPayUsingPoint: 'Can Pay Using Point',
      labelVisibilityStatus: 'Visibility Status',
      labelStatus: 'Status',
      labelMedia: 'Media',
      labelOrder: 'Order',
      labelYoutube: 'Youtube',
      labelImage: 'Image',
      labelSpeakers: 'Speakers',
      labelAdditionalItems: 'Additional Items',
      labelLabel: 'Label',
      labelType: 'Type',
      labelViewAccess: 'View Access',
      labelVisibilityTime: 'Visibility Time',
      labelContent: 'Content',
      labelUrl: 'URL',
      labelCopied: 'Copied!',

      placeholderName: 'Name',
      placeholderStartTime: 'Start Time',
      placeholderEndTime: 'End Time',
      placeholderEarliestClockIn: 'Earliest Clock In (minutes)',
      placeholderMaxClockIn: 'Max Clock In (minutes)',
      placeholderFeatured: 'Featured',
      placeholderFeaturedOrder: 'Featured Order',
      placeholderVisibilityStatus: 'Visibility Status',
      placeholderRegistrationRewardType: 'Registration Reward Type',
      placeholderRegistrationRewardPoint: 'Registration Reward Point',
      placeholderRegistrationRewardVouchers: 'Registration Reward Vouchers',
      placeholderEvent: 'Event',
      placeholderRoom: 'Room',
      placeholderCategory: 'Category',
      placeholderDescription: 'Description',
      placeholderShortDescription: 'Short Description',
      placeholderClockInsideArea: 'Clock In Must Be Inside Area',
      placeholderWhoCanRegister: 'Who Can Register',
      placeholderNeedsApproval: 'Needs Approval',
      placeholderQuota: 'Quota',
      placeholderNormalPrice: 'Normal Price',
      placeholderPrice: 'Price',
      placeholderCanPayUsingPoint: 'Can Pay Using Point',
      placeholderSpeaker: 'Speaker',
      placeholderType: 'Type',
      placeholderSurveyForm: 'Survey Form',
      placeholderEnableComment: 'Enable Comment',
      placeholderCommentAccess: 'Comment Access',
      placeholderDiscussionId: 'Discussion ID',
      placeholderClockInQrCode: 'Clock In QR Code',
      placeholderDeepLink: 'Deep Link',
      placeholderBanner: 'Banner',

      buttonCaptionCreateNewSession: 'Create New Session',
      buttonCaptionEditSession: 'Edit Session',
      buttonCaptionDeleteSession: 'Delete Session',
      buttonCaptionYoutube: 'Youtube',
      buttonCaptionImage: 'Image',
    },
    eventPage: {
      title: 'Event',

      labelCanPayUsingPoint: 'Can Pay Using Point',
      labelCategory: 'Category',
      labelClockInMustBeInsideArea: 'Clock In Must Be Inside Area',
      labelCreatedDate: 'Created Date',
      labelDescription: 'Description',
      labelEarliestClock: 'Earliest Clock In (minutes)',
      labelEndTime: 'End Time',
      labelEventType: 'Event Type',
      labelFeatured: ' Featured',
      labelFeaturedOrder: 'Featured Order',
      labelImage: 'Image',
      labelLocation: 'Location',
      labelMaxClock: 'Max Clock In (minutes)',
      labelMedia: 'Media',
      labelName: 'Name',
      labelNeedApproval: 'Need Approval',
      labelNormalPrice: 'Normal Price',
      labelOrder: 'Order',
      labelPrice: 'Price',
      labelQuota: 'Quota',
      labelRegistrationRewardPoint: 'Registration Reward Point',
      labelRegistrationRewardType: 'Registration Reward Type',
      labelRegistrationRewardVoucher: 'Registration Reward Voucher',
      labelShortDescription: 'Short Description',
      labelStartTime: 'Start Time',
      labelStatus: 'Status',
      labelVisibilityStatus: 'Visibility Status',
      labelWhoCanRegister: 'Who Can Register',
      labelYoutube: 'Youtube',
      labelAdditionalItems: 'Additional Items',
      labelLabel: 'Label',
      labelType: 'Type',
      labelViewAccess: 'View Access',
      labelVisibilityTime: 'Visibility Time',
      labelContent: 'Content',
      labelUrl: 'URL',
      labelSpeakers: 'Speakers',
      labelSurveyForm: 'Survey Form',
      labelCopied: 'Copied!',

      buttonCaptionCreateNewEvent: 'Create New Event',
      buttonCaptionDeleteEvent: 'Delete Event',
      buttonCaptionEditEvent: 'Edit Event',
      buttonCaptionImage: 'Image',
      buttonCaptionYoutube: 'Youtube',
      buttonCaptionSendToMe: 'Send To Me',

      placeholderBanner: 'Banner',
      placeholderCanPayUsingPoint: 'Can Pay Using Point',
      placeholderCategory: 'Category',
      placeholderClockInMustBeInsideArea: 'Clock In Must Be Inside Area',
      placeholderDeeplink: 'Deep Link',
      placeholderEarliestClock: 'Earliest Clock In (minutes)',
      placeholderEndTime: 'End Time',
      placeholderEventType: 'Event Type',
      placeholderFeatured: ' Featured',
      placeholderFeaturedOrder: 'Featured Order',
      placeholderLocation: 'Location',
      placeholderMaxClock: 'Max Clock In (minutes)',
      placeholderName: 'Name',
      placeholderNeedApproval: 'Need Approval',
      placeholderNormalPrice: 'Normal Price',
      placeholderPrice: 'Price',
      placeholderQuota: 'Quota',
      placeholderRegistrationRewardPoint: 'Registration Reward Point',
      placeholderRegistrationRewardType: 'Registration Reward Type',
      placeholderRegistrationRewardVoucher: 'Registration Reward Voucher',
      placeholderShortDescription: 'Short Description',
      placeholderStartTime: 'Start Time',
      placeholderVisibilityStatus: 'Visibility Status',
      placeholderWhoCanRegister: 'Who Can Register',
      placeholderEnableComment: 'Enable Comment',
      placeholderCommentAccess: 'Comment Access',
      placeholderDiscussionId: 'Discussion ID',
      placeholderSpeakers: 'Speakers',
      placeholderSurveyForm: 'Survey Form',
      placeholderClockInQrCode: 'Clock In QR Code',
      placeholderLandingPage: 'Landing Page',
    },
    eventInvitationPage: {
      title: 'Event Invitation',

      labelCreatedDate: 'Created Date',
      labelEmail: 'Email',
      labelEndTime: 'End Time',
      labelEvent: 'Event',
      labelEventName: 'Event Name',
      labelFullName: 'Full Name',
      labelId: 'ID',
      labelInvitationStatus: 'Invitation Status',
      labelLocation: 'Location',
      labelName: 'Name',
      labelPhone: 'Phone',
      labelRecipient: 'Recipient',
      labelRecipients: 'Recipients',
      labelRegisteredOn: 'Registered On',
      labelResentCount: 'Resent Count',
      labelShortDescription: 'Short Description',
      labelStartTime: 'Start Time',
      labelStatus: 'Status',
      labelUser: 'User',
      labelTimeZone: 'Time Zone',

      buttonCaptionCreateNewEventInvitation: 'Create New Event Invitation',
      buttonCaptionDeleteEventInvitation: 'Delete Event Invitation',
      buttonCaptionResend: 'Resend',

      placeholderEmail: 'Email',
      placeholderEndTime: 'End Time',
      placeholderEvent: 'Event',
      placeholderFullName: 'Full Name',
      placeholderId: 'ID',
      placeholderInvitationStatus: 'Invitation Status',
      placeholderLocation: 'Location',
      placeholderName: 'Name',
      placeholderPhone: 'Phone',
      placeholderRegisteredOn: 'Registered On',
      placeholderResentCount: 'Resent Count',
      placeholderShortDescription: 'Short Description',
      placeholderStartTime: 'Start Time',
      placeholderTimeZone: 'Time Zone',

      msgResendConfirmation: 'Are you sure want to resend this invitation?',
    },
    sponsorPage: {
      title: 'Sponsor',

      labelEvent: 'Event',
      labelCompany: 'Company',
      labelSponsorCategory: 'Sponsor Category',
      labelOrder: 'Order',
      labelCompanyName: 'Company Name',
      labelEventName: 'Event Name',
      labelCategory: 'Category',
      labelCreatedDate: 'Created Date',
      labelStatus: 'Status',

      buttonCaptionCreateNewSponsor: 'Create New Sponsor',
      buttonCaptionDeleteSponsor: 'Delete Sponsor',
      buttonCaptionEditSponsor: 'Edit Sponsor',

      placeholderEvent: 'Event',
      placeholderCompany: 'Company',
      placeholderSponsorCategory: 'Sponsor Category',
      placeholderOrder: 'Order',
    },
    sessionInvitationPage: {
      title: 'Session Invitation',

      labelCreatedDate: 'Created Date',
      labelEmail: 'Email',
      labelEndTime: 'End Time',
      labelSession: 'Session',
      labelSessionName: 'Session Name',
      labelFullName: 'Full Name',
      labelId: 'ID',
      labelInvitationStatus: 'Invitation Status',
      labelLocation: 'Location',
      labelName: 'Name',
      labelPhone: 'Phone',
      labelRecipient: 'Recipient',
      labelRecipients: 'Recipients',
      labelRegisteredOn: 'Registered On',
      labelResentCount: 'Resent Count',
      labelShortDescription: 'Short Description',
      labelStartTime: 'Start Time',
      labelStatus: 'Status',
      labelUser: 'User',
      labelTimeZone: 'Time Zone',

      buttonCaptionCreateNewSessionInvitation: 'Create New Session Invitation',
      buttonCaptionDeleteSessionInvitation: 'Delete Session Invitation',
      buttonCaptionResend: 'Resend',

      placeholderEmail: 'Email',
      placeholderEndTime: 'End Time',
      placeholderSession: 'Session',
      placeholderFullName: 'Full Name',
      placeholderId: 'ID',
      placeholderInvitationStatus: 'Invitation Status',
      placeholderLocation: 'Location',
      placeholderName: 'Name',
      placeholderPhone: 'Phone',
      placeholderRegisteredOn: 'Registered On',
      placeholderResentCount: 'Resent Count',
      placeholderShortDescription: 'Short Description',
      placeholderStartTime: 'Start Time',
      placeholderTimeZone: 'Time Zone',

      msgResendConfirmation: 'Are you sure want to resend this invitation?',
    },
    eventRegistrationPage: {
      title: 'Event Registration',

      labelParticipant: 'Participant',
      labelEvent: 'Event',
      labelRegistrationCode: 'Registration Code',
      labelRegistrationStatus: 'Registration Status',
      labelApprovedOn: 'Approved On',
      labelRejectedOn: 'Rejected On',
      labelRegisteredOn: 'Registered On',
      labelPaymentStatus: 'Payment Status',
      labelCreatedDate: 'Created Date',
      labelStatus: 'Status',
      labelFree: 'Free',

      placeholderRegistrationCode: 'Registration Code',
      placeholderRegistrationStatus: 'Registration Status',
      placeholderRegistrationStatusNotes: 'Registration Status Notes',
      placeholderApprovedOn: 'Approved On',
      placeholderRejectedOn: 'Rejected On',
      placeholderApprovedBy: 'Approved By',
      placeholderRejectedBy: 'Rejected By',
      placeholderNotes: 'Notes',
      placeholderClockInTime: 'Clock In Time',
      placeholderClockInDrift: 'Clock In Drift (Minutes)',
      placeholderClockInLatitude: 'Clock In Latitude',
      placeholderClockInLongtitude: 'Clock In Longtitude',
      placeholderClockInLocationStatus: 'Clock In Location Status',
      placeholderUser: 'User',
      placeholderUserID: 'User ID',
      placeholderUserFullName: 'User Full Name',
      placeholderUserEmail: 'User Email',
      placeholderUserPhone: 'User Phone',
      placeholderEvent: 'Event',
      placeholderEventID: 'Event ID',
      placeholderEventName: 'Event Name',
      placeholderEventShortDescription: 'Event Short Description',
      placeholderEventStartTime: 'Event Start Time',
      placeholderEventEndTime: 'Event End Time',
      placeholderEventTimezone: 'Event Time Zone',
      placeholderPayment: 'Payment',
      placeholderPaymentID: 'Payment ID',
      placeholderPrice: 'Price',
      placeholderPaymentDate: 'Payment Date',
      placeholderTax: 'Tax',
      placeholderTransactionID: 'Transaction ID',
      placeholderServiceFee: 'Service Fee',
      placeholderPaymentCode: 'Payment Code',
      placeholderDiscount: 'Discount',
      placeholderPaymentChannel: 'Payment Channel',
      placeholderDiscountNotes: 'Discount Notes',
      placeholderPaymentStatus: 'Payment Status',
      placeholderPointDiscount: 'Point Discount',
      placeholderPaymentStatusNotes: 'Payment Status Notes',
      placeholderPointDiscountID: 'Point Discount ID',
      placeholderLastChangedStatus: 'Last Changed Status',
      placeholderTotal: 'Total',

      buttonCaptionApprove: 'Approve',
      buttonCaptionReject: 'Reject',

      msgRejectEventRegistration: 'Are you sure want to reject this registration?',
      msgApproveEventRegistration: 'Are you sure want to approve this registration?',
    },
    sessionRegistrationPage: {
      title: 'Session Registration',

      labelParticipant: 'Participant',
      labelSession: 'Session',
      labelRegistrationCode: 'Registration Code',
      labelRegistrationStatus: 'Registration Status',
      labelApprovedOn: 'Approved On',
      labelRejectedOn: 'Rejected On',
      labelRegisteredOn: 'Registered On',
      labelPaymentStatus: 'Payment Status',
      labelCreatedDate: 'Created Date',
      labelStatus: 'Status',
      labelFree: 'Free',

      placeholderRegistrationCode: 'Registration Code',
      placeholderRegistrationStatus: 'Registration Status',
      placeholderRegistrationStatusNotes: 'Registration Status Notes',
      placeholderApprovedOn: 'Approved On',
      placeholderRejectedOn: 'Rejected On',
      placeholderApprovedBy: 'Approved By',
      placeholderRejectedBy: 'Rejected By',
      placeholderNotes: 'Notes',
      placeholderClockInTime: 'Clock In Time',
      placeholderClockInDrift: 'Clock In Drift (Minutes)',
      placeholderClockInLatitude: 'Clock In Latitude',
      placeholderClockInLongtitude: 'Clock In Longtitude',
      placeholderClockInLocationStatus: 'Clock In Location Status',
      placeholderUser: 'User',
      placeholderUserID: 'User ID',
      placeholderUserFullName: 'User Full Name',
      placeholderUserEmail: 'User Email',
      placeholderUserPhone: 'User Phone',
      placeholderSession: 'Session',
      placeholderSessionID: 'Session ID',
      placeholderSessionName: 'Session Name',
      placeholderSessionShortDescription: 'Session Short Description',
      placeholderSessionStartTime: 'Session Start Time',
      placeholderSessionEndTime: 'Session End Time',
      placeholderSessionTimezone: 'Session Time Zone',
      placeholderPayment: 'Payment',
      placeholderPaymentID: 'Payment ID',
      placeholderPrice: 'Price',
      placeholderPaymentDate: 'Payment Date',
      placeholderTax: 'Tax',
      placeholderTransactionID: 'Transaction ID',
      placeholderServiceFee: 'Service Fee',
      placeholderPaymentCode: 'Payment Code',
      placeholderDiscount: 'Discount',
      placeholderPaymentChannel: 'Payment Channel',
      placeholderDiscountNotes: 'Discount Notes',
      placeholderPaymentStatus: 'Payment Status',
      placeholderPointDiscount: 'Point Discount',
      placeholderPaymentStatusNotes: 'Payment Status Notes',
      placeholderPointDiscountID: 'Point Discount ID',
      placeholderLastChangedStatus: 'Last Changed Status',
      placeholderTotal: 'Total',

      buttonCaptionApprove: 'Approve',
      buttonCaptionReject: 'Reject',

      msgRejectSessionRegistration: 'Are you sure want to reject this registration?',
      msgApproveSessionRegistration: 'Are you sure want to approve this registration?',
    },
  },
  id: {
    eventMenu: {
      title: 'Acara',
    },
    categoryPage: {
      title: 'Kategori Acara',

      labelClearImage: 'Hapus Gambar',
      labelDescription: 'Deskripsi',
      labelImage: 'Gambar',
      labelName: 'Nama',
      labelStatus: 'Status',

      buttonCaptionCreateNewCategory: 'Buat Kategori Acara Baru',
      buttonCaptionDeleteCategory: 'Hapus Kategori Acara',
      buttonCaptionEditCategory: 'Ubah Kategori Acara',

      placeholderClearImage: 'Hapus Gambar',
      placeholderDescription: 'Deskripsi',
      placeholderName: 'Nama',
    },
    sessionCategoryPage: {
      title: 'Kategori Sesi',

      labelDescription: 'Deskripsi',
      labelName: 'Nama',
      labelStatus: 'Status',

      buttonCaptionCreateNewSessionCategory: 'Buat Kategori Sesi Baru',
      buttonCaptionDeleteSessionCategory: 'Hapus Kategori Sesi',
      buttonCaptionEditSessinCategory: 'Ubah Kategori Sesi',

      placeholderDescription: 'Deskripsi',
      placeholderName: 'Nama',
    },
    sponsorCategoryPage: {
      title: 'Kategori Sponsor',

      labelDescription: 'Deskripsi',
      labelName: 'Nama',
      labelStatus: 'Status',
      labelOrder: 'Urutan',

      buttonCaptionCreateNewSponsorCategory: 'Buat Kategori Sponsor Baru',
      buttonCaptionDeleteSponsorCategory: 'Hapus Kategori Sponsor',
      buttonCaptionEditSessinCategory: 'Ubah Kategori Sponsor',

      placeholderDescription: 'Deskripsi',
      placeholderName: 'Nama',
      placeholderOrder: 'Urutan',
    },
    speakerPage: {
      title: 'Pembicara',

      labelCompany: 'Perusahaan',
      labelDescription: 'Deskripsi',
      labelImage: 'Gambar',
      labelJobTitle: 'Jabatan',
      labelName: 'Nama',
      labelStatus: 'Status',

      buttonCaptionCreateNewSpeaker: 'Buat Pembicara Baru',
      buttonCaptionDeleteSpeaker: 'Hapus Pembicara',
      buttonCaptionEditSpeaker: 'Ubah Pembicara',

      placeholderCompany: 'Perusahaan',
      placeholderDescription: 'Deskripsi',
      placeholderJobTitle: 'Jabatan',
      placeholderName: 'Nama',
    },
    locationPage: {
      title: 'Lokasi',

      labelNo: 'No',
      labelName: 'Nama',
      labelCity: 'Kota',
      labelAddress: 'Alamat',
      labelGoogleMap: 'Peta Google',
      labelLongitude: 'Garis Bujur',
      labelLatitude: 'Garis Lintang',
      labelTimeZone: 'Zona Waktu',
      labelProvince: 'Provinsi',
      labelStatus: 'Status',
      labelBorderArea: 'Batas Wilayah',

      buttonCaptionCreateNewLocation: 'Buat Lokasi Baru',
      buttonCaptionEditLocation: 'Ubah Lokasi',
      buttonCaptionDeleteLocation: 'Hapus Lokasi',

      placeholderLocationName: 'Nama Lokasi',
      placeholderCityName: 'Kota',
      placeholderAddress: 'Alamat',
      placeholderDescription: 'Deskripsi',
      placeholderPhone: 'Telepon',
      placeholderEmail: 'Email',
      placeholderPostalCode: 'Kode Pos',
      placeholderGoogleMapUrl: 'URL Peta Google',
      placeholderLatitude: 'Garis Lintang',
      placeholderLongitude: 'Garis Bujur',
    },
    roomPage: {
      title: 'Ruangan',

      labelDescription: 'Deskripsi',
      labelName: 'Nama',
      labelStatus: 'Status',
      labelLocation: 'Lokasi',
      labelLocationName: 'Nama Lokasi',
      labelLocationId: 'ID Lokasi',

      buttonCaptionCreateNewRoom: 'Buat Ruangan Baru',
      buttonCaptionDeleteRoom: 'Hapus Ruangan',
      buttonCaptionEditRoom: 'Ubah Ruangan',

      placeholderDescription: 'Deskripsi',
      placeholderName: 'Nama',
      placeholderLocation: 'Lokasi',
      placeholderLocationName: 'Nama Lokasi',
      placeholderLocationId: 'ID Lokasi',
    },
    sessionPage: {
      title: 'Sesi',

      labelName: 'Nama',
      labelEventName: 'Nama Acara',
      labelStartTime: 'Waktu Mulai',
      labelEndTime: 'Waktu Selesai',
      labelRoom: 'Ruangan',
      labelQuota: 'Kuota',
      labelCreatedDate: 'Tanggal Dibuat',
      labelEvent: 'Acara',
      labelWhoCanRegister: 'Yang Dapat Mendaftar',
      labelClockInsideArea: 'Jam Masuk Harus Di Dalam Area',
      labelNeedsApproval: 'Butuh Persetujuan',
      labelFeatured: 'Unggulan',
      labelCanPayUsingPoint: 'Dapat Membayar Menggunakan Poin',
      labelVisibilityStatus: 'Status Visibilitas',
      labelStatus: 'Status',
      labelMedia: 'Media',
      labelOrder: 'Urutan',
      labelYoutube: 'Youtube',
      labelImage: 'Gambar',
      labelSpeakers: 'Pembicara',
      labelAdditionalItems: 'Materi Tambahan',
      labelLabel: 'Label',
      labelType: 'Tipe',
      labelViewAccess: 'Akses Pratinjau',
      labelVisibilityTime: 'Waktu Visibilitas',
      labelContent: 'Isi',
      labelUrl: 'URL',
      labelCopied: 'Disalin!',

      placeholderName: 'Nama',
      placeholderStartTime: 'Waktu Mulai',
      placeholderEndTime: 'Waktu Selesai',
      placeholderEarliestClockIn: 'Jam Masuk Awal (menit)',
      placeholderMaxClockIn: 'Maks Jam Masuk (menit)',
      placeholderFeatured: 'Unggulan',
      placeholderFeaturedOrder: 'Urutan Unggulan',
      placeholderVisibilityStatus: 'Status Visibilitas',
      placeholderRegistrationRewardType: 'Jenis Hadiah Pendaftaran',
      placeholderRegistrationRewardPoint: 'Poin Hadiah Pendaftaran',
      placeholderRegistrationRewardVouchers: 'Voucher Hadiah Pendaftaran',
      placeholderEvent: 'Acara',
      placeholderRoom: 'Ruangan',
      placeholderCategory: 'Kategori',
      placeholderDescription: 'Deskripsi',
      placeholderShortDescription: 'Deskripsi Singkat',
      placeholderClockInsideArea: 'Jam Masuk Harus Di Dalam Area',
      placeholderWhoCanRegister: 'Yang Dapat Mendaftar',
      placeholderNeedsApproval: 'Butuh Persetujuan',
      placeholderQuota: 'Kuota',
      placeholderNormalPrice: 'Harga Normal',
      placeholderPrice: 'Harga',
      placeholderCanPayUsingPoint: 'Dapat Membayar Menggunakan Poin',
      placeholderSpeaker: 'Pembicara',
      placeholderType: 'Tipe',
      placeholderSurveyForm: 'Formulir Survei',
      placeholderEnableComment: 'Aktifkan Komentar',
      placeholderCommentAccess: 'Akses Komentar',
      placeholderDiscussionId: 'ID Diskusi',
      placeholderClockInQrCode: 'Kode QR Absen Masuk',
      placeholderDeepLink: 'Tautan Dalam',
      placeholderBanner: 'Spanduk',

      buttonCaptionCreateNewSession: 'Buat Sesi Baru',
      buttonCaptionEditSession: 'Ubah Sesi',
      buttonCaptionDeleteSession: 'Hapus Sesi',
      buttonCaptionYoutube: 'Youtube',
      buttonCaptionImage: 'Gambar',
    },
    eventPage: {
      title: 'Acara',

      labelCanPayUsingPoint: 'Bisa Membayar Menggunakan Poin',
      labelCategory: 'Kategori',
      labelClockInMustBeInsideArea: 'Jam Masuk Harus Dalam Area',
      labelCreatedDate: 'Tanggal Dibuat',
      labelDescription: 'Deskripsi',
      labelEarliestClock: 'Masuk Paling Awal (menit)',
      labelEndTime: 'Waktu Selesai',
      labelEventType: 'Jenis Acara',
      labelFeatured: ' Unggulan',
      labelFeaturedOrder: 'Urutan Unggulan',
      labelImage: 'Gambar',
      labelLocation: 'Lokasi',
      labelMaxClock: 'Maksimal Jam Masuk (menit)',
      labelMedia: 'Media',
      labelName: 'Nama',
      labelNeedApproval: 'Butuh Persetujuan',
      labelNormalPrice: 'Harga Normal',
      labelOrder: 'Urutan',
      labelPrice: 'Harga',
      labelQuota: 'Kuota',
      labelRegistrationRewardPoint: 'Poin Hadiah Pendaftaran',
      labelRegistrationRewardType: 'Jenis Hadiah Pendaftaran',
      labelRegistrationRewardVoucher: 'Voucher Hadiah Pendaftaran ',
      labelShortDescription: 'Deskripsi Singkat',
      labelStartTime: 'Waktu Mulai',
      labelStatus: 'Status',
      labelVisibilityStatus: 'Status Visibilitas',
      labelWhoCanRegister: 'Yang Dapat Mendaftar',
      labelYoutube: 'Youtube',
      labelAdditionalItems: 'Materi Tambahan',
      labelLabel: 'Label',
      labelType: 'Tipe',
      labelViewAccess: 'Akses Pratinjau',
      labelVisibilityTime: 'Waktu Visibilitas',
      labelContent: 'Isi',
      labelUrl: 'URL',
      labelSpeakers: 'Pembicara',
      labelSurveyForm: 'Formulir Survei',
      labelCopied: 'Disalin!',

      buttonCaptionCreateNewEvent: 'Buat Acara Baru',
      buttonCaptionDeleteEvent: 'Hapus Acara',
      buttonCaptionEditEvent: 'Ubah Acara',
      buttonCaptionImage: 'Gambar',
      buttonCaptionYoutube: 'Youtube',
      buttonCaptionSendToMe: 'Kirim Kepada Saya',

      placeholderBanner: 'Spanduk',
      placeholderCanPayUsingPoint: 'Bisa Membayar Menggunakan Poin',
      placeholderCategory: 'Kategori',
      placeholderClockInMustBeInsideArea: 'Jam Masuk Harus Dalam Area',
      placeholderDeeplink: 'Tautan Dalam',
      placeholderEarliestClock: 'Masuk Paling Awal (menit)',
      placeholderEndTime: 'Waktu Selesai',
      placeholderEventType: 'Jenis Acara',
      placeholderFeatured: ' Unggulan',
      placeholderFeaturedOrder: 'Urutan Unggulan',
      placeholderLocation: 'Lokasi',
      placeholderMaxClock: 'Maksimal Jam Masuk (menit)',
      placeholderName: 'Nama',
      placeholderNeedApproval: 'Butuh Persetujuan',
      placeholderNormalPrice: 'Harga Normal',
      placeholderPrice: 'Harga',
      placeholderQuota: 'Kuota',
      placeholderRegistrationRewardPoint: 'Poin Hadiah Pendaftaran',
      placeholderRegistrationRewardType: 'Jenis Hadiah Pendaftaran',
      placeholderRegistrationRewardVoucher: 'Voucher Hadiah Pendaftaran ',
      placeholderShortDescription: 'Deskripsi Singkat',
      placeholderStartTime: 'Waktu Mulai',
      placeholderVisibilityStatus: 'Status Visibilitas',
      placeholderWhoCanRegister: 'Yang Dapat Mendaftar',
      placeholderEnableComment: 'Aktifkan Komentar',
      placeholderCommentAccess: 'Akses Komentar',
      placeholderDiscussionId: 'ID Diskusi',
      placeholderSpeakers: 'Pembicara',
      placeholderSurveyForm: 'Formulir Survei',
      placeholderClockInQrCode: 'Kode QR Absen Masuk',
      placeholderLandingPage: 'Halaman Landing',
    },
    eventInvitationPage: {
      title: 'Undangan Acara',

      labelCreatedDate: 'Tanggal Pembuatan',
      labelEmail: 'Email',
      labelEndTime: 'Waktu Selesai',
      labelEvent: 'Acara',
      labelEventName: 'Nama Acara',
      labelFullName: 'Nama Lengkap',
      labelId: 'ID',
      labelInvitationStatus: 'Status Undangan',
      labelLocation: 'Lokasi',
      labelName: 'Nama',
      labelPhone: 'Telepon',
      labelRecipient: 'Penerima',
      labelRecipients: 'Penerima',
      labelRegisteredOn: 'Terdaftar Di',
      labelResentCount: 'Jumlah Terkirim',
      labelShortDescription: 'Deskripsi Singkat',
      labelStartTime: 'Waktu Mulai',
      labelStatus: 'Status',
      labelUser: 'Pengguna',
      labelTimeZone: 'Zona Waktu',

      buttonCaptionCreateNewEventInvitation: 'Buat Undangan Acara Baru',
      buttonCaptionDeleteEventInvitation: 'Hapus Undangan Acara',
      buttonCaptionResend: 'Kirim Ulang',

      placeholderEmail: 'Email',
      placeholderEndTime: 'Waktu Selesai',
      placeholderEvent: 'Acara',
      placeholderFullName: 'Nama Lengkap',
      placeholderId: 'ID',
      placeholderInvitationStatus: 'Status Undangan',
      placeholderLocation: 'Lokasi',
      placeholderName: 'Nama',
      placeholderPhone: 'Telepon',
      placeholderRegisteredOn: 'Terdaftar Di',
      placeholderResentCount: 'Jumlah Terkirim',
      placeholderShortDescription: 'Deskripsi Singkat',
      placeholderStartTime: 'Waktu Mulai',
      placeholderTimeZone: 'Zona Waktu',

      msgResendConfirmation: 'Apa Anda yakin ingin mengirim ulang undangan ini?',
    },
    sponsorPage: {
      title: 'Sponsor',

      labelEvent: 'Acara',
      labelCompany: 'Perusahaan',
      labelOrder: 'Urutan',
      labelSponsorCategory: 'Kategori Sponsor',
      labelCompanyName: 'Nama Perusahaan',
      labelEventName: 'Nama Acara',
      labelCategory: 'Kategori',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelStatus: 'Status',

      buttonCaptionCreateNewSponsor: 'Buat Sponsor Baru',
      buttonCaptionDeleteSponsor: 'Hapus Sponsor',
      buttonCaptionEditSponsor: 'Ubah Sponsor',

      placeholderEvent: 'Acara',
      placeholderCompany: 'Perusahaan',
      placeholderSponsorCategory: 'Kategori Sponsor',
      placeholderOrder: 'Urutan',
    },
    sessionInvitationPage: {
      title: 'Undangan Sesi',

      labelCreatedDate: 'Tanggal Pembuatan',
      labelEmail: 'Email',
      labelEndTime: 'Waktu Selesai',
      labelSession: 'Sesi',
      labelSessionName: 'Nama Sesi',
      labelFullName: 'Nama Lengkap',
      labelId: 'ID',
      labelInvitationStatus: 'Status Undangan',
      labelLocation: 'Lokasi',
      labelName: 'Nama',
      labelPhone: 'Telepon',
      labelRecipient: 'Penerima',
      labelRecipients: 'Penerima',
      labelRegisteredOn: 'Terdaftar Di',
      labelResentCount: 'Jumlah Terkirim',
      labelShortDescription: 'Deskripsi Singkat',
      labelStartTime: 'Waktu Mulai',
      labelStatus: 'Status',
      labelUser: 'Pengguna',
      labelTimeZone: 'Zona Waktu',

      buttonCaptionCreateNewSessionInvitation: 'Buat Undangan Sesi Baru',
      buttonCaptionDeleteSessionInvitation: 'Hapus Undangan Sesi',
      buttonCaptionResend: 'Kirim Ulang',

      placeholderEmail: 'Email',
      placeholderEndTime: 'Waktu Selesai',
      placeholderSession: 'Sesi',
      placeholderFullName: 'Nama Lengkap',
      placeholderId: 'ID',
      placeholderInvitationStatus: 'Status Undangan',
      placeholderLocation: 'Lokasi',
      placeholderName: 'Nama',
      placeholderPhone: 'Telepon',
      placeholderRegisteredOn: 'Terdaftar Di',
      placeholderResentCount: 'Jumlah Terkirim',
      placeholderShortDescription: 'Deskripsi Singkat',
      placeholderStartTime: 'Waktu Mulai',
      placeholderTimeZone: 'Zona Waktu',

      msgResendConfirmation: 'Apa Anda yakin ingin mengirim ulang undangan ini?',
    },
    eventRegistrationPage: {
      title: 'Registrasi Acara',

      labelParticipant: 'Peserta',
      labelEvent: 'Acara',
      labelRegistrationCode: 'Kode Registrasi',
      labelRegistrationStatus: 'Status Registrasi',
      labelApprovedOn: 'Disetujui Pada',
      labelRejectedOn: 'Ditolak Pada',
      labelRegisteredOn: 'Terdaftar Pada',
      labelPaymentStatus: 'Status Pembayaran',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelStatus: 'Status',
      labelFree: 'Gratis',

      placeholderRegistrationCode: 'Kode Registrasi',
      placeholderRegistrationStatus: 'Status Registrasi',
      placeholderRegistrationStatusNotes: 'Catatan Status Registrasi',
      placeholderApprovedOn: 'Disetujui Pada',
      placeholderRejectedOn: 'Ditolak Pada',
      placeholderApprovedBy: 'Disetujui Oleh',
      placeholderRejectedBy: 'Ditolak Oleh',
      placeholderNotes: 'Catatan',
      placeholderClockInTime: 'Waktu Jam Masuk',
      placeholderClockInDrift: 'Perbedaan Jam Masuk (Menit)',
      placeholderClockInLatitude: 'Lintang Jam Masuk',
      placeholderClockInLongtitude: 'Bujur Jam Masuk',
      placeholderClockInLocationStatus: 'Status lokasi Jam Masuk',
      placeholderUser: 'Pengguna',
      placeholderUserID: 'ID Pengguna',
      placeholderUserFullName: 'Nama Lengkap Pengguna',
      placeholderUserEmail: 'Email Pengguna',
      placeholderUserPhone: 'Telepon Pengguna',
      placeholderEvent: 'Acara',
      placeholderEventID: 'ID Acara',
      placeholderEventName: 'Nama Acara',
      placeholderEventShortDescription: 'Deskripsi Singkat Acara',
      placeholderEventStartTime: 'Waktu Mulai Acara',
      placeholderEventEndTime: 'Waktu Berakhir Acara',
      placeholderEventTimezone: 'Zona Waktu Acara',
      placeholderPayment: 'Pembayaran',
      placeholderPaymentID: 'ID Pembayaran',
      placeholderPrice: 'Harga',
      placeholderPaymentDate: 'Tanggal Pembayaran',
      placeholderTax: 'Pajak',
      placeholderTransactionID: 'ID Transaksi',
      placeholderServiceFee: 'Biaya Pelayanan',
      placeholderPaymentCode: 'Kode Pembayaran',
      placeholderDiscount: 'Diskon',
      placeholderPaymentChannel: 'Saluran Pembayaran',
      placeholderDiscountNotes: 'Catatan Diskon',
      placeholderPaymentStatus: 'Status Pembayaran',
      placeholderPointDiscount: 'Poin Diskon',
      placeholderPaymentStatusNotes: 'Catatan Status Pembayaran',
      placeholderPointDiscountID: 'ID Poin Diskon',
      placeholderLastChangedStatus: 'Perubahan Status Terakhir',
      placeholderTotal: 'Total',

      buttonCaptionApprove: 'Setuju',
      buttonCaptionReject: 'Tolak',

      msgRejectEventRegistration: 'Apa Anda yakin ingin menolak registrasi ini?',
      msgApproveEventRegistration: 'Apa Anda yakin ingin menyetujui registrasi ini?',
    },
    sessionRegistrationPage: {
      title: 'Registrasi Sesi',

      labelParticipant: 'Peserta',
      labelSession: 'Sesi',
      labelRegistrationCode: 'Kode Registrasi',
      labelRegistrationStatus: 'Status Registrasi',
      labelApprovedOn: 'Disetujui Pada',
      labelRejectedOn: 'Ditolak Pada',
      labelRegisteredOn: 'Terdaftar Pada',
      labelPaymentStatus: 'Status Pembayaran',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelStatus: 'Status',
      labelFree: 'Gratis',

      placeholderRegistrationCode: 'Kode Registrasi',
      placeholderRegistrationStatus: 'Status Registrasi',
      placeholderRegistrationStatusNotes: 'Catatan Status Registrasi',
      placeholderApprovedOn: 'Disetujui Pada',
      placeholderRejectedOn: 'Ditolak Pada',
      placeholderApprovedBy: 'Disetujui Oleh',
      placeholderRejectedBy: 'Ditolak Oleh',
      placeholderNotes: 'Catatan',
      placeholderClockInTime: 'Waktu Jam Masuk',
      placeholderClockInDrift: 'Perbedaan Jam Masuk (Menit)',
      placeholderClockInLatitude: 'Lintang Jam Masuk',
      placeholderClockInLongtitude: 'Bujur Jam Masuk',
      placeholderClockInLocationStatus: 'Status lokasi Jam Masuk',
      placeholderUser: 'Pengguna',
      placeholderUserID: 'ID Pengguna',
      placeholderUserFullName: 'Nama Lengkap Pengguna',
      placeholderUserEmail: 'Email Pengguna',
      placeholderUserPhone: 'Telepon Pengguna',
      placeholderSession: 'Sesi',
      placeholderSessionID: 'ID Sesi',
      placeholderSessionName: 'Nama Sesi',
      placeholderSessionShortDescription: 'Deskripsi Singkat Sesi',
      placeholderSessionStartTime: 'Waktu Mulai Sesi',
      placeholderSessionEndTime: 'Waktu Berakhir Sesi',
      placeholderSessionTimezone: 'Zona Waktu Sesi',
      placeholderPayment: 'Pembayaran',
      placeholderPaymentID: 'ID Pembayaran',
      placeholderPrice: 'Harga',
      placeholderPaymentDate: 'Tanggal Pembayaran',
      placeholderTax: 'Pajak',
      placeholderTransactionID: 'ID Transaksi',
      placeholderServiceFee: 'Biaya Pelayanan',
      placeholderPaymentCode: 'Kode Pembayaran',
      placeholderDiscount: 'Diskon',
      placeholderPaymentChannel: 'Saluran Pembayaran',
      placeholderDiscountNotes: 'Catatan Diskon',
      placeholderPaymentStatus: 'Status Pembayaran',
      placeholderPointDiscount: 'Poin Diskon',
      placeholderPaymentStatusNotes: 'Catatan Status Pembayaran',
      placeholderPointDiscountID: 'ID Poin Diskon',
      placeholderLastChangedStatus: 'Perubahan Status Terakhir',
      placeholderTotal: 'Total',

      buttonCaptionApprove: 'Setuju',
      buttonCaptionReject: 'Tolak',

      msgRejectSessionRegistration: 'Apa Anda yakin ingin menolak registrasi ini?',
      msgApproveSessionRegistration: 'Apa anda yakin ingin menyetujui registrasi ini?',
    },
  },
});

export default LocalizedString;
