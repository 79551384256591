import { downloadingFields, setFields } from '../simple-action';
import { downloadFields, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, fields, uiField } = getState();
  const { token } = authentication;
  const { meta } = fields;
  const { selectedPageSize, searchBarText, orderBy } = uiField;
  const { filterString } = getState().uiFunctionalPage;

  try {
    dispatch(downloadingFields(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadFields(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      searchBarText ? transformSearchText(searchBarText) : '',
      filterString,
      token,
    );

    dispatch(setFields(list));
  } finally {
    dispatch(downloadingFields(false));
  }
};
