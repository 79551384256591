import {
  disablingToken, setAlertErrorMessage, setFunctionalPageMode, setTokenSelectedOrderBy,
  setTokenSelectedPageSize, setTokenTappedId,
} from '../simple-action';
import { PAGE_MODE_TABLE } from '../../../constant';
import { disableToken } from '../../../helper';
import downloadTokensAsync from './downloadTokensAsync';

export default (tappedId, route) => async (dispatch, getState) => {
  try {
    dispatch(disablingToken(true));

    const { tokens } = getState();
    const { token } = getState().authentication;

    const found = Object.values(tokens.data).find((x) => x.id === tappedId);

    if (found) {
      await disableToken(found.id, token);
      if (route === 'token') {
        dispatch(setTokenTappedId(''));
        dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      }
    }

    dispatch(setTokenSelectedPageSize(20));
    dispatch(setTokenSelectedOrderBy('lastActivity desc'));
    dispatch(downloadTokensAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(disablingToken(false));
  }
};
