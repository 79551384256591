import {
  downloadingDeletingProfile,
  addProfile,
  setProfileSelectedWorkingShiftId,
  setProfileSelectedBranchId,
  setWorkingShiftAdvancedFilterDialogSelectedFilterString,
  setWorkingShiftSearchText,
  clearWorkingShifts,
} from '../simple-action';
import { downloadProfile } from '../../../helper';
import downloadWorkingShiftsAsync from './downloadWorkingShiftsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingProfile(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiProfile;
    const { tappedId: setAchievementTappedId } = getState().uiAchievement;

    const result = await downloadProfile(setAchievementTappedId || tappedId, token);
    const branchId = result?.branch?.id || '';
    dispatch(addProfile(result));
    dispatch(setProfileSelectedBranchId(branchId));

    dispatch(setProfileSelectedWorkingShiftId(result?.workingShift?.id || ''));
    if (branchId) {
      dispatch(setWorkingShiftAdvancedFilterDialogSelectedFilterString(`status=enabled|branch.id=${branchId}`));
      dispatch(setWorkingShiftSearchText(''));
      dispatch(clearWorkingShifts());
      dispatch(downloadWorkingShiftsAsync(1));
    }
  } finally {
    dispatch(downloadingDeletingProfile(false));
  }
};
