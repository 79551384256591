import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  approval, INITIAL_ORDER_BY_DISCUSSIONS, MENUID_DISCUSSION_DISCUSSION, PAGE_MODE_TABLE,
  RXFIELD_DISCUSSION_ALLOW_DIRECT_USER_COMMENT, RXFIELD_DISCUSSION_ALLOW_PUBLIC_READ,
  RXFIELD_DISCUSSION_DISABLE_COMMENT, RXFORM_DISCUSSION,
} from '../../constant';
import { transformInitialValues } from '../../helper';
import {
  clearDiscussions, setActiveSideMenuItem, setAlertErrorMessage,
  setDiscussionAdvancedFilterDialogSelectedFilterString, setDiscussionSearchText,
  setDiscussionSelectedAllowDirectComment, setDiscussionSelectedAllowPublicRead,
  setDiscussionSelectedDisableComment, setDiscussionSelectedOrderBy, setDiscussionSelectedPageSize,
  setDiscussionTappedId,
  editDiscussionAsync, downloadDiscussionAsync, downloadDiscussionsAsync,
} from '../../redux/action';
import DiscussionPage from './discussion.presentation';

const getInitialValues = (state) => {
  const { discussions, uiDiscussion, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiDiscussion;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? discussions.data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    allowDirectUserComment: found.allowDirectUserComment ? approval[0] : approval[1],
    allowPublicRead: found.allowPublicRead ? approval[0] : approval[1],
    disableComment: found.disableComment ? approval[0] : approval[1],
  }) : {
    allowDirectUserComment: { label: '', value: '' },
    allowPublicRead: { label: '', value: '' },
    disableComment: { label: '', value: '' },
  };
  return result;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  addingEditing: state.uiDiscussion.addingEditing,
  downloading: state.uiDiscussion.downloading,
  downloadingDeleting: state.uiDiscussion.downloadingDeleting,
  pageMode: state.uiFunctionalPage.pageMode,
});

const mapDispatchToProps = (dispatch) => ({
  onAllowDirectUserCommentOptionSelected: (option) => {
    if (option) {
      dispatch(setDiscussionSelectedAllowDirectComment(option));
      dispatch(change(RXFORM_DISCUSSION, RXFIELD_DISCUSSION_ALLOW_DIRECT_USER_COMMENT, option));
    } else {
      dispatch(setDiscussionSelectedAllowDirectComment(''));
      dispatch(change(RXFORM_DISCUSSION, RXFIELD_DISCUSSION_ALLOW_DIRECT_USER_COMMENT, ''));
    }
  },
  onAllowPublicReadOptionSelected: (option) => {
    if (option) {
      dispatch(setDiscussionSelectedAllowPublicRead(option));
      dispatch(change(RXFORM_DISCUSSION, RXFIELD_DISCUSSION_ALLOW_PUBLIC_READ, option));
    } else {
      dispatch(setDiscussionSelectedAllowPublicRead(''));
      dispatch(change(RXFORM_DISCUSSION, RXFIELD_DISCUSSION_ALLOW_PUBLIC_READ, ''));
    }
  },
  onAppear: () => {
    dispatch(setDiscussionAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_DISCUSSION_DISCUSSION));
    dispatch(setDiscussionSearchText(''));
    dispatch(clearDiscussions());
    dispatch(setDiscussionSelectedPageSize(20));
    dispatch(setDiscussionSelectedOrderBy(INITIAL_ORDER_BY_DISCUSSIONS));
    dispatch(downloadDiscussionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/user/, 'user.id').replace(/event/, 'event.id');
    dispatch(setDiscussionAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearDiscussions());
    dispatch(downloadDiscussionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_DISCUSSION));
    dispatch(setDiscussionSelectedAllowDirectComment(''));
    dispatch(setDiscussionSelectedAllowPublicRead(''));
    dispatch(setDiscussionSelectedDisableComment(''));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadDiscussionsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setDiscussionSelectedPageSize(pageSize));
    dispatch(downloadDiscussionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDisableCommentOptionSelected: (option) => {
    if (option) {
      dispatch(setDiscussionSelectedDisableComment(option));
      dispatch(change(RXFORM_DISCUSSION, RXFIELD_DISCUSSION_DISABLE_COMMENT, option));
    } else {
      dispatch(setDiscussionSelectedDisableComment(''));
      dispatch(change(RXFORM_DISCUSSION, RXFIELD_DISCUSSION_DISABLE_COMMENT, ''));
    }
  },
  onEditPressed: (id) => {
    dispatch(setDiscussionTappedId(id));
    dispatch(downloadDiscussionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setDiscussionSelectedPageSize(pageSize));
    dispatch(clearDiscussions());
    dispatch(downloadDiscussionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setDiscussionAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearDiscussions());
    dispatch(setDiscussionSelectedOrderBy(INITIAL_ORDER_BY_DISCUSSIONS));
    dispatch(downloadDiscussionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async () => {
    try {
      await dispatch(editDiscussionAsync());
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setDiscussionSearchText(text));
      dispatch(clearDiscussions());
      await dispatch(downloadDiscussionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setDiscussionSelectedOrderBy(orderBy));
    dispatch(clearDiscussions());
    dispatch(downloadDiscussionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setDiscussionTappedId(id));
    dispatch(downloadDiscussionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DiscussionPage);
