import {
  ADD_MY_PROFILE, DOWNLOADING_MY_PROFILE, CLEAR_MY_PROFILE,
} from '../action';

const initialState = {
  downloading: false,
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_MY_PROFILE:
      return { ...state, data: action.data };
    case DOWNLOADING_MY_PROFILE:
      return { ...state, downloading: action.status };
    case CLEAR_MY_PROFILE:
      return initialState;
    default: return state;
  }
};
