import {
  resyncingWorkingShift, setAlertErrorMessage, setWorkingShiftSelectedOrderBy,
  setWorkingShiftSelectedPageSize,
} from '../simple-action';
import { resyncMasterDataIndividually } from '../../../helper';
import { INITIAL_ORDER_BY_WORKING_SHIFTS, MASTER_DATA_ENTITY_NAME_WORKING_SHIFT } from '../../../constant';
import downloadWorkingShiftsAsync from './downloadWorkingShiftsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resyncingWorkingShift(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiWorkingShift;

    await resyncMasterDataIndividually(MASTER_DATA_ENTITY_NAME_WORKING_SHIFT, tappedId, token);

    dispatch(setWorkingShiftSelectedPageSize(20));
    dispatch(setWorkingShiftSelectedOrderBy(INITIAL_ORDER_BY_WORKING_SHIFTS));
    dispatch(downloadWorkingShiftsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  } finally {
    dispatch(resyncingWorkingShift(false));
  }
};
