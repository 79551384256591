import { downloadSessionOperatorInvitation, resendSessionOperatorInvitation } from '../../../helper';
import { addSessionOperatorInvitation, resendingSessionOperatorInvitation } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resendingSessionOperatorInvitation(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSessionOperatorInvitation;

    await resendSessionOperatorInvitation(tappedId, token);
    const result = await downloadSessionOperatorInvitation(tappedId, token);

    dispatch(addSessionOperatorInvitation(result));
  } finally {
    dispatch(resendingSessionOperatorInvitation(false));
  }
};
