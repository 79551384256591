import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { change, formValueSelector, reset } from 'redux-form';
import {
  addNotificationDialogFilterForm, clearNotificationDialogFilterForm, clearBranches, clearCompanies,
  clearDepartments, clearDivisions, setAlertErrorMessage,
  setBranchAdvancedFilterDialogSelectedFilterString, setBranchSearchText, setCompanySearchText,
  setDepartmentSearchText, setDivisionSearchText, setNotificationDialogVisibility,
  setNotificationSelectedBranch, setNotificationSelectedCompany, setNotificationSelectedDepartment,
  setNotificationSelectedDivision, setTotalPersonCount,
  downloadBranchesAsync, downloadCompaniesAsync, downloadDepartmentsAsync, downloadDivisionsAsync,
  downloadTotalPersonCountAsync, sendNotificationsAsync,
} from '../../redux/action';
import {
  RXFIELD_SEND_PERSONAL_NOTIFICATION_BODY, RXFIELD_SEND_PERSONAL_NOTIFICATION_IMAGE,
  RXFIELD_SEND_PERSONAL_NOTIFICATION_TITLE,
  RXFORM_SEND_PERSONAL_NOTIFICATION_MODAL,
} from '../../constant';
import { transformDropdownData, debounceSearch } from '../../helper';
import SendNotificationDialog from './send-notification-dialog.presentation';

const selector = formValueSelector(RXFORM_SEND_PERSONAL_NOTIFICATION_MODAL);

const mapStateToProps = (state, ownProps) => ({
  branches: transformDropdownData(state.branches.data),
  companies: transformDropdownData(state.companies.data),
  divisions: transformDropdownData(state.divisions.data),
  departments: transformDropdownData(state.departments.data),
  downloadingBranches: state.uiBranch.downloading,
  downloadingCompanies: state.notification.downloadingCompanies,
  downloadingDivisions: state.notification.downloadingDivisions,
  downloadingDepartments: state.notification.downloadingDepartments,
  downloadingTotalPersonCount: state.notification.downloadingTotalPersonCount,
  sendingNotifications: state.notification.sendingNotifications,
  visible: state.notification.visibility,
  totalPersonCount: state.notification.totalPersonCount,
  filterForm: state.notification.filterForm,
  initialValues: state.form.sendPersonalNotificationModal
    && !isEmpty(state.notification.filterForm)
    ? { ...state.form.sendPersonalNotificationModal.values, ...state.notification.filterForm }
    : state.notification.filterForm,
  isEmptyRequiredFields: !!(!selector(state, RXFIELD_SEND_PERSONAL_NOTIFICATION_TITLE)
    || !selector(state, RXFIELD_SEND_PERSONAL_NOTIFICATION_BODY)
    || (ownProps.sendToFieldName && !selector(state, ownProps.sendToFieldName))),
});

const transformSelectedValue = (value) => value.reduce((prev, curr) => (
  prev ? `${prev};${curr.label || curr}` : curr.label || curr), '');

const searchBranchDebounce = debounceSearch(
  (dispatch) => {
    dispatch(setBranchAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(clearBranches());
    dispatch(downloadBranchesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchCompanyDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearCompanies());
    dispatch(downloadCompaniesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchDivisionDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearDivisions());
    dispatch(downloadDivisionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchDepartmentDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearDepartments());
    dispatch(downloadDepartmentsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onBranchOptionSelected: (value) => {
    dispatch(setNotificationSelectedBranch(transformSelectedValue(value)));
    dispatch(setTotalPersonCount(0));
  },
  onCancelPressed: (useDefaultFilterSection) => {
    dispatch(setTotalPersonCount(0));
    dispatch(clearNotificationDialogFilterForm());
    dispatch(setNotificationDialogVisibility(false));
    dispatch(reset(RXFORM_SEND_PERSONAL_NOTIFICATION_MODAL));

    if (useDefaultFilterSection) {
      dispatch(setBranchSearchText(''));
      dispatch(setCompanySearchText(''));
      dispatch(setDepartmentSearchText(''));
      dispatch(setDivisionSearchText(''));
      dispatch(setNotificationSelectedBranch(''));
      dispatch(setNotificationSelectedCompany(''));
      dispatch(setNotificationSelectedDepartment(''));
      dispatch(setNotificationSelectedDivision(''));
    }
  },
  onChangeBranchText: (text) => {
    dispatch(setBranchSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchBranchDebounce(dispatch, true);
    }
  },
  onChangeCompanyText: (text) => {
    dispatch(setCompanySearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchCompanyDebounce(dispatch, true);
    }
  },
  onChangeDate: (e, field) => {
    dispatch(addNotificationDialogFilterForm(field, e));
  },
  onChangeDepartmentText: (text) => {
    dispatch(setDepartmentSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchDepartmentDebounce(dispatch, true);
    }
  },
  onChangeDivisionText: (text) => {
    dispatch(setDivisionSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchDivisionDebounce(dispatch, true);
    }
  },
  onChangeDropdown: (e, field) => {
    if (e) {
      dispatch(addNotificationDialogFilterForm(field, e));
    } else {
      dispatch(addNotificationDialogFilterForm(field, ''));
    }
  },
  onChangeNumber: (e, field) => {
    dispatch(addNotificationDialogFilterForm(field, e.target.value));
  },
  onChangeSwitch: (e, field) => {
    dispatch(addNotificationDialogFilterForm(field, e.value));
  },
  onCompanyOptionSelected: (value) => {
    dispatch(setNotificationSelectedCompany(transformSelectedValue(value)));
    dispatch(setTotalPersonCount(0));
  },
  onCountPressed: () => {
    dispatch(downloadTotalPersonCountAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDepartmentOptionSelected: (value) => {
    dispatch(setNotificationSelectedDepartment(transformSelectedValue(value)));
    dispatch(setTotalPersonCount(0));
  },
  onDivisionOptionSelected: (value) => {
    dispatch(setNotificationSelectedDivision(transformSelectedValue(value)));
    dispatch(setTotalPersonCount(0));
  },
  onError: (error) => {
    dispatch(setAlertErrorMessage(error));
  },
  onSendNotificationPressed: async (values, onSendPressed, useDefaultFilterSection,
    filterString) => {
    try {
      if (useDefaultFilterSection) {
        await dispatch(sendNotificationsAsync(values));
      } else {
        onSendPressed(values, filterString);
      }
      dispatch(setNotificationDialogVisibility(false));
      dispatch(reset(RXFORM_SEND_PERSONAL_NOTIFICATION_MODAL));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onImageSelected: (image) => {
    dispatch(change(
      RXFORM_SEND_PERSONAL_NOTIFICATION_MODAL,
      RXFIELD_SEND_PERSONAL_NOTIFICATION_IMAGE, image,
    ));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SendNotificationDialog);
