import {
  DOWNLOADING_POPUP_BANNERS, ADDING_EDITING_POPUP_BANNER, DOWNLOADING_DELETING_POPUP_BANNER,
  SET_POPUP_BANNER_SELECTED_PAGE_SIZE, SET_POPUP_BANNER_TAPPED_ID,
  SET_POPUP_BANNER_SEARCH_TEXT, SET_POPUP_BANNER_SELECTED_ORDER_BY,
  SET_POPUP_BANNER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
} from '../action';
import { INITIAL_ORDER_BY_POPUP_BANNERS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  orderBy: INITIAL_ORDER_BY_POPUP_BANNERS,
  searchBarText: '',
  selectedPageSize: 20,
  tappedId: '',
  filterString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_POPUP_BANNERS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_POPUP_BANNER:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_POPUP_BANNER:
      return { ...state, downloadingDeleting: action.status };
    case SET_POPUP_BANNER_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_POPUP_BANNER_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_POPUP_BANNER_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_POPUP_BANNER_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_POPUP_BANNER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    default: return state;
  }
};
