import { change } from 'redux-form';
import {
  RXFIELD_EVENT_OPERATOR_EVENT_MEDIA,
  RXFIELD_EVENT_OPERATOR_EVENT_SPEAKERS, RXFORM_EVENT_OPERATOR_EVENT,
} from '../../../constant';
import { downloadEventOperatorEvent } from '../../../helper';
import {
  addEventOperatorEvent, downloadingDeletingEventOperatorEvent,
} from '../simple-action';
import { downloadForm } from '../../../../dform/helper';

export const transformSpeakers = (data) => (data.length > 0 ? data.map((x) => (x.speaker.id
  ? { label: x.speaker.name, value: x.speaker.id } : x.speaker)) : []);

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingEventOperatorEvent(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiEventOperatorEvent;

    const event = await downloadEventOperatorEvent(tappedId, token);
    dispatch(addEventOperatorEvent(event));
    dispatch(change(RXFORM_EVENT_OPERATOR_EVENT, RXFIELD_EVENT_OPERATOR_EVENT_MEDIA, event.media));
    dispatch(change(RXFORM_EVENT_OPERATOR_EVENT, RXFIELD_EVENT_OPERATOR_EVENT_SPEAKERS,
      event.speakers));

    if (event.surveyFormId) {
      const form = await downloadForm(event.surveyFormId, token);
      dispatch(addEventOperatorEvent({
        ...event,
        surveyFormId: { label: form.name, value: form.id },
      }));
    }
  } finally {
    dispatch(downloadingDeletingEventOperatorEvent(false));
  }
};
