import { connect } from 'react-redux';
import { INITIAL_ORDER_BY_REPORTED_COMMENT, MENUID_DISCUSSION_REPORTED_COMMENT, PAGE_MODE_TABLE } from '../../constant';
import {
  transformDropdownData, transformUserDropdownData, transformInitialValues, debounceSearch,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  clearReportedComments, clearDiscussions, clearProfiles, setActiveSideMenuItem,
  setAdvancedFilterDialogSelectedFilterString, setAlertErrorMessage,
  setDiscussionAdvancedFilterDialogSelectedFilterString, setDiscussionSearchText,
  setReportedCommentSearchText, setReportedCommentSelectedOrderBy,
  setReportedCommentSelectedPageSize, setReportedCommentTappedId,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
  downloadReportedCommentAsync, downloadReportedCommentsAsync, downloadDiscussionsAsync,
} from '../../redux/action';
import { downloadProfilesAsync } from '../../../../redux/action';
import ReportedCommentPage from './reported-comment.presentation';

const getInitialValues = (state) => {
  const {
    reportedComments, uiFunctionalPage, uiReportedComment,
  } = state;
  const { data } = reportedComments;
  const { downloadingDetail, tappedId } = uiReportedComment;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDetail
    ? data[tappedId] : {};

  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      userId: found.user?.id || '',
      fullName: found.user?.fullName || '',
      commentId: found.comment?.id || '',
      comment: found.comment?.comment || '',
      discussionId: found.comment?.discussion?.id || '',
      discussionName: found.comment?.discussion?.name || '',
      handled: found.handled ? GlobalLocalizedString.common.labelYes
        : GlobalLocalizedString.common.labelNo,
    }) : {
      userId: '',
      fullName: '',
      commentId: '',
      comment: '',
      discussionId: '',
      discussionName: '',
      handled: '',
      reason: '',
    };
  return result;
};

const mapStateToProps = (state) => ({
  users: transformUserDropdownData(state.profiles.data),
  discussions: transformDropdownData(state.discussions.data),
  downloading: state.uiDiscussionComment.downloading,
  loadingUser: state.uiProfile.downloading,
  dialogVisibility: state.uiFunctionalPage.visibility,
  loadingDiscussion: state.uiDiscussion.downloading,
  initialValues: getInitialValues(state),
});

const searchUserDebounce = debounceSearch((dispatch) => {
  dispatch(clearProfiles());
  dispatch(downloadProfilesAsync(1))
    .catch((err) => dispatch(setAlertErrorMessage(err)));
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setDiscussionAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(setDiscussionSearchText(''));
    dispatch(clearDiscussions());
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDiscussionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_DISCUSSION_REPORTED_COMMENT));
    dispatch(setReportedCommentSearchText(''));
    dispatch(clearReportedComments());
    dispatch(setReportedCommentSelectedPageSize(20));
    dispatch(setReportedCommentSelectedOrderBy(INITIAL_ORDER_BY_REPORTED_COMMENT));
    dispatch(downloadReportedCommentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/fullName/, 'user.id')
      .replace(/discussion/, 'comment.discussion.id').replace(/commentId/, 'comment.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearReportedComments());
    dispatch(downloadReportedCommentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeDiscussionText: async (text) => {
    try {
      dispatch(setDiscussionSearchText(text));
      dispatch(clearDiscussions());
      await dispatch(downloadDiscussionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadReportedCommentsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setReportedCommentSelectedPageSize(pageSize));
    dispatch(downloadReportedCommentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setReportedCommentSelectedPageSize(pageSize));
    dispatch(clearReportedComments());
    dispatch(downloadReportedCommentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearReportedComments());
    dispatch(setReportedCommentSelectedOrderBy(INITIAL_ORDER_BY_REPORTED_COMMENT));
    dispatch(downloadReportedCommentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setReportedCommentSearchText(text));
      dispatch(clearReportedComments());
      await dispatch(downloadReportedCommentsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setReportedCommentSelectedOrderBy(orderBy));
    dispatch(clearReportedComments());
    dispatch(downloadReportedCommentsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setReportedCommentTappedId(id));
    dispatch(downloadReportedCommentAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportedCommentPage);
