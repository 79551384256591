import { downloadEventOperatorInvitations, transformSearchText } from '../../../helper';
import { downloadingEventOperatorInvitations, setEventOperatorInvitations } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, eventOperatorInvitations, uiEventOperatorInvitation } = getState();
  const { token } = authentication;
  const { meta } = eventOperatorInvitations;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiEventOperatorInvitation;

  try {
    dispatch(downloadingEventOperatorInvitations(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadEventOperatorInvitations(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setEventOperatorInvitations(list));
    }
  } finally {
    dispatch(downloadingEventOperatorInvitations(false));
  }
};
