import { PAGE_MODE_TABLE } from '../../../constant';
import { deleteEventOperatorMapping, downloadEventOperatorMapping } from '../../../helper';
import {
  addEventOperatorMapping, clearEventOperatorMappings, downloadingDeletingEventOperatorMapping,
  setAlertErrorMessage, setEventOperatorMappingSelectedEvent, setEventOperatorMappingSelectedUser,
  setEventOperatorMappingTappedId, setFunctionalPageMode,
} from '../simple-action';
import downloadEventOperatorMappingsAsync from './downloadEventOperatorMappingsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingEventOperatorMapping(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiEventOperatorMapping;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteEventOperatorMapping(tappedId, token);
      dispatch(setEventOperatorMappingTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearEventOperatorMappings());
      dispatch(downloadEventOperatorMappingsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const result = await downloadEventOperatorMapping(tappedId, token);
      dispatch(addEventOperatorMapping(result));

      const { event, user } = result;
      dispatch(setEventOperatorMappingSelectedEvent({ label: event.name, value: event.id }));
      dispatch(setEventOperatorMappingSelectedUser({ label: user.fullName, value: user.id }));
    }
  } finally {
    dispatch(downloadingDeletingEventOperatorMapping(false));
  }
};
