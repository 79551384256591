import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW, RXFIELD_CITY_NAME,
  RXFIELD_CITY_PROVINCE, RXFORM_CITY, RXSTATE_CITIES, RXSTATE_CITY_PAGE, status, STATUS_DISABLED,
  STATUS_ENABLED, timezoneOffset, RXFIELD_CITY_CODE, FILTER_TYPE_DATE_RANGE,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_FORMAT, ADVANCED_FILTER_MODE,
} from '../../constant';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { renderReduxFormOutlinedTextField, renderReduxFormOutlinedDropdownTextField } from '../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateCity } from '../../validation';

const renderDialogContent = (defaultValue, provinces, addingEditing, loadingProvince,
  onChangeProvinceText, onProvinceOptionSelected, pageMode) => (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CITY_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.cityScreen.placeholderCityName}
            label={LocalizedString.cityScreen.placeholderCityName}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CITY_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.cityScreen.placeholderCityCode}
            label={LocalizedString.cityScreen.placeholderCityCode}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            type="number"
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CITY_PROVINCE}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.cityScreen.placeholderProvinceName}
            label={LocalizedString.cityScreen.placeholderProvinceName}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={provinces}
            value={defaultValue}
            loading={loadingProvince}
            onChangeText={onChangeProvinceText}
            onOptionSelected={onProvinceOptionSelected}
            required
          />
        </Grid>
      </Grid>
    </Grid>
);

const CityPage = ({
  initialValues, provinces,
  addingEditing, downloading, enablingDisabling, loadingProvince, isResyncAllowed, resyncing,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed, onCancelPressed,
  onChangePage, onChangePageSize, onChangeProvinceText, onConfirmContextMenuPressed,
  onContextMenuPressed, onCreatePressed, onDownloadPressed, onEditPressed,
  onProvinceOptionSelected, onRefresh, onResetAdvancedFilterPressed, onSavePressed,
  onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  currentCityStatus, pageMode, tappedId,
}) => (
  <FunctionalPage
    data={RXSTATE_CITIES}
    uiPage={RXSTATE_CITY_PAGE}
    moreMenus={[
      {
        caption: LocalizedString.common.buttonCaptionEnable,
        disabled: currentCityStatus === STATUS_ENABLED || enablingDisabling,
        onPress: () => onContextMenuPressed(tappedId,
          LocalizedString.common.msgEnableConfirmation),
      },
      {
        caption: LocalizedString.common.buttonCaptionDisable,
        disabled: currentCityStatus === STATUS_DISABLED || enablingDisabling,
        onPress: () => onContextMenuPressed(tappedId,
          LocalizedString.common.msgDisableConfirmation),
      },
      {
        caption: LocalizedString.common.buttonCaptionResync,
        disabled: resyncing || !isResyncAllowed,
        onPress: () => onContextMenuPressed(tappedId,
          LocalizedString.common.msgResyncConfirmation),
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.cityScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.cityScreen.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.cityScreen.labelProvince, field: 'province.name', sorting: !downloading },
      { title: LocalizedString.cityScreen.labelCreatedDate, field: 'createdDate', sorting: !downloading },
      { title: LocalizedString.cityScreen.labelLastModifiedDate, field: 'lastModifiedDate', sorting: !downloading },
      { title: LocalizedString.cityScreen.labelStatus, field: 'status', sorting: !downloading },
    ]}
    filterColumns={[
      {
        title: LocalizedString.cityScreen.labelTimeZone, field: 'timeZone', type: FILTER_TYPE_DROPDOWN, data: timezoneOffset,
      },
      {
        title: LocalizedString.cityScreen.labelProvince,
        field: 'province',
        type: FILTER_TYPE_DROPDOWN,
        data: provinces,
        loading: loadingProvince,
        onChangeFilterText: (text) => onChangeProvinceText(text, ADVANCED_FILTER_MODE),
        useDropdownValue: true,
      },
      {
        title: LocalizedString.cityScreen.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_FORMAT,
      },
      {
        title: LocalizedString.cityScreen.labelStatus, field: 'status', type: FILTER_TYPE_DROPDOWN, data: status, useDropdownValue: true,
      },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmContextMenuPressed}
    onCreatePressed={onCreatePressed}
    onDownloadPressed={onDownloadPressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.cityScreen.buttonCaptionCreateNewCity}
    deleteButtonCaption={LocalizedString.cityScreen.buttonCaptionDeleteCity}
    editButtonCaption={LocalizedString.cityScreen.buttonCaptionEditCity}
    title={LocalizedString.cityScreen.title}
    disableDelete
    createPermissionName="MDATA_CREATE_CITY"
    editPermissionName="MDATA_EDIT_CITY"
    savePermissionName="MDATA_GET_CITY"
    useFullWidth
  >
    {renderDialogContent(initialValues.province.label, provinces, addingEditing, loadingProvince,
      onChangeProvinceText, onProvinceOptionSelected, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_CITY,
  validate: rxformValidateCity,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CityPage);

CityPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  provinces: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  isResyncAllowed: PropTypes.bool.isRequired,
  resyncing: PropTypes.bool.isRequired,
  loadingProvince: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeProvinceText: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onProvinceOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  currentCityStatus: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
};
