import {
  addWorkingShift, downloadingDeletingWorkingShift,
} from '../simple-action';
import { downloadWorkingShift } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingWorkingShift(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiWorkingShift;

    const branch = await downloadWorkingShift(tappedId, token);
    dispatch(addWorkingShift(branch));
  } finally {
    dispatch(downloadingDeletingWorkingShift(false));
  }
};
