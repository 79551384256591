import { downloadDiscussions, transformSearchText } from '../../../helper';
import { downloadingDiscussions, setDiscussions } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, discussions, uiDiscussion } = getState();
  const { token } = authentication;
  const { meta } = discussions;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiDiscussion;

  try {
    dispatch(downloadingDiscussions(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadDiscussions(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setDiscussions(list));
    }
  } finally {
    dispatch(downloadingDiscussions(false));
  }
};
