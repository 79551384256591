import { downloadingManagers, setManagers } from '../simple-action';
import { downloadProfiles, transformSearchText } from '../../../helper';
import { INITIAL_ORDER_BY_PROFILES } from '../../../constant';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, managers, uiProfile } = getState();
  const { token } = authentication;
  const { meta } = managers;
  const { managerSearchText, filterString } = uiProfile;

  const searchText = transformSearchText(managerSearchText);

  try {
    dispatch(downloadingManagers(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadProfiles(
      pageToBeDownloaded,
      20,
      INITIAL_ORDER_BY_PROFILES,
      searchText,
      filterString,
      token,
    );

    dispatch(setManagers(list));
  } finally {
    dispatch(downloadingManagers(false));
  }
};
