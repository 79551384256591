import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_GAMEQR_CATEGORIES = 'DOWNLOADING_GAMEQR_CATEGORIES';
export const downloadingGameQRCategories = makeActionCreator(DOWNLOADING_GAMEQR_CATEGORIES, 'status');

export const SET_GAMEQR_CATEGORIES = 'SET_GAMEQR_CATEGORIES';
export const setGameQRCategories = makeActionCreator(SET_GAMEQR_CATEGORIES, 'data');

export const ADD_GAMEQR_CATEGORY = 'ADD_GAMEQR_CATEGORY';
export const addGameQRCategory = makeActionCreator(ADD_GAMEQR_CATEGORY, 'data');

export const CLEAR_GAMEQR_CATEGORIES = 'CLEAR_GAMEQR_CATEGORIES';
export const clearGameQRCategories = makeActionCreator(CLEAR_GAMEQR_CATEGORIES);

export const ADDING_EDITING_GAMEQR_CATEGORY = 'ADDING_EDITING_GAMEQR_CATEGORY';
export const addingEditingGameQRCategory = makeActionCreator(ADDING_EDITING_GAMEQR_CATEGORY, 'status');

export const DOWNLOADING_DELETING_GAMEQR_CATEGORY = 'DOWNLOADING_DELETING_GAMEQR_CATEGORY';
export const downloadingDeletingGameQRCategory = makeActionCreator(DOWNLOADING_DELETING_GAMEQR_CATEGORY, 'status');


export const SET_GAMEQR_CATEGORY_SELECTED_PAGE_SIZE = 'SET_GAMEQR_CATEGORY_SELECTED_PAGE_SIZE';
export const setGameQRCategorySelectedPageSize = makeActionCreator(SET_GAMEQR_CATEGORY_SELECTED_PAGE_SIZE, 'size');

export const SET_GAMEQR_CATEGORY_SELECTED_ORDER_BY = 'SET_GAMEQR_CATEGORY_SELECTED_ORDER_BY';
export const setGameQRCategorySelectedOrderBy = makeActionCreator(SET_GAMEQR_CATEGORY_SELECTED_ORDER_BY, 'order');

export const SET_GAMEQR_CATEGORY_TAPPED_ID = 'SET_GAMEQR_CATEGORY_TAPPED_ID';
export const setGameQRCategoryTappedId = makeActionCreator(SET_GAMEQR_CATEGORY_TAPPED_ID, 'id');

export const SET_GAMEQR_CATEGORY_SEARCH_TEXT = 'SET_GAMEQR_CATEGORY_SEARCH_TEXT';
export const setGameQRCategorySearchText = makeActionCreator(SET_GAMEQR_CATEGORY_SEARCH_TEXT, 'text');

export const SET_GAMEQR_CATEGORY_SELECTED_PARENT_CATEGORY = 'SET_GAMEQR_CATEGORY_SELECTED_PARENT_CATEGORY';
export const setGameQRCategorySelectedParentCategory = makeActionCreator(SET_GAMEQR_CATEGORY_SELECTED_PARENT_CATEGORY, 'option');

export const SET_GAMEQR_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_GAMEQR_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setGameQRCategoryAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_GAMEQR_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_CHALLENGES = 'DOWNLOADING_CHALLENGES';
export const downloadingChallenges = makeActionCreator(DOWNLOADING_CHALLENGES, 'status');

export const SET_CHALLENGES = 'SET_CHALLENGES';
export const setChallenge = makeActionCreator(SET_CHALLENGES, 'data');

export const ADD_CHALLENGE = 'ADD_CHALLENGE';
export const addChallenge = makeActionCreator(ADD_CHALLENGE, 'data');

export const CLEAR_CHALLENGES = 'CLEAR_CHALLENGES';
export const clearChallenges = makeActionCreator(CLEAR_CHALLENGES);

export const ADDING_EDITING_CHALLENGE = 'ADDING_EDITING_CHALLENGE';
export const addingEditingChallenge = makeActionCreator(ADDING_EDITING_CHALLENGE, 'status');

export const DOWNLOADING_DELETING_CHALLENGE = 'DOWNLOADING_DELETING_CHALLENGE';
export const downloadingDeletingChallenge = makeActionCreator(DOWNLOADING_DELETING_CHALLENGE, 'status');

export const COPYING_CHALLENGE_DEEP_LINK = 'COPYING_CHALLENGE_DEEP_LINK';
export const copyingChallengeDeepLink = makeActionCreator(COPYING_CHALLENGE_DEEP_LINK, 'status');


export const SET_CHALLENGE_SELECTED_PAGE_SIZE = 'SET_CHALLENGE_SELECTED_PAGE_SIZE';
export const setChallengeSelectedPageSize = makeActionCreator(SET_CHALLENGE_SELECTED_PAGE_SIZE, 'size');

export const SET_CHALLENGE_SELECTED_ORDER_BY = 'SET_CHALLENGE_SELECTED_ORDER_BY';
export const setChallengeSelectedOrderBy = makeActionCreator(SET_CHALLENGE_SELECTED_ORDER_BY, 'order');

export const SET_CHALLENGE_TAPPED_ID = 'SET_CHALLENGE_TAPPED_ID';
export const setChallengeTappedId = makeActionCreator(SET_CHALLENGE_TAPPED_ID, 'id');

export const SET_CHALLENGE_SEARCH_TEXT = 'SET_CHALLENGE_SEARCH_TEXT';
export const setChallengeSearchText = makeActionCreator(SET_CHALLENGE_SEARCH_TEXT, 'text');

export const SET_CHALLENGE_SELECTED_CATEGORY = 'SET_CHALLENGE_SELECTED_CATEGORY';
export const setChallengeSelectedCategory = makeActionCreator(SET_CHALLENGE_SELECTED_CATEGORY, 'option');

export const SET_CHALLENGE_SELECTED_VISIBILITY_STATUS = 'SET_CHALLENGE_SELECTED_VISIBILITY_STATUS';
export const setChallengeSelectedVisibilityStatus = makeActionCreator(SET_CHALLENGE_SELECTED_VISIBILITY_STATUS, 'option');

export const SET_CHALLENGE_SELECTED_ADD_MEDIA_MENU = 'SET_CHALLENGE_SELECTED_ADD_MEDIA_MENU';
export const setChallengeSelectedAddMediaMenu = makeActionCreator(SET_CHALLENGE_SELECTED_ADD_MEDIA_MENU, 'text');

export const SET_CHALLENGE_SELECTED_SCAN_POLICY = 'SET_CHALLENGE_SELECTED_SCAN_POLICY';
export const setChallengeSelectedScanPolicy = makeActionCreator(SET_CHALLENGE_SELECTED_SCAN_POLICY, 'option');

export const SET_CHALLENGE_SELECTED_IS_PROMOTED = 'SET_CHALLENGE_SELECTED_IS_PROMOTED';
export const setChallengeSelectedIsPromoted = makeActionCreator(SET_CHALLENGE_SELECTED_IS_PROMOTED, 'option');

export const SET_CHALLENGE_SCAN_LIMIT_MODE = 'SET_CHALLENGE_SCAN_LIMIT_MODE';
export const setChallengeScanLimitMode = makeActionCreator(SET_CHALLENGE_SCAN_LIMIT_MODE, 'option');

export const SET_CHALLENGE_SCAN_LIMIT_TIMEZONE = 'SET_CHALLENGE_SCAN_LIMIT_TIMEZONE';
export const setChallengeScanLimitTimezone = makeActionCreator(SET_CHALLENGE_SCAN_LIMIT_TIMEZONE, 'option');

export const SET_CHALLENGE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_CHALLENGE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setChallengeAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_CHALLENGE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_CHALLENGE_SELECTED_VOUCHER = 'SET_CHALLENGE_SELECTED_VOUCHER';
export const setChallengeSelectedVoucher = makeActionCreator(SET_CHALLENGE_SELECTED_VOUCHER, 'value');

export const SET_CHALLENGE_SELECTED_COMPLETE_REWARD = 'SET_CHALLENGE_SELECTED_COMPLETE_REWARD';
export const setChallengeSelectedCompleteReward = makeActionCreator(SET_CHALLENGE_SELECTED_COMPLETE_REWARD, 'value');

export const SET_CHALLENGE_SELECTED_BYPASS_MAX_PURCHASE = 'SET_CHALLENGE_SELECTED_BYPASS_MAX_PURCHASE';
export const setChallengeSelectedBypassMaxPurchase = makeActionCreator(SET_CHALLENGE_SELECTED_BYPASS_MAX_PURCHASE, 'value');

export const SET_CHALLENGE_SELECTED_BYPASS_ACHIEVEMENT = 'SET_CHALLENGE_SELECTED_BYPASS_ACHIEVEMENT';
export const setChallengeSelectedBypassAchievement = makeActionCreator(SET_CHALLENGE_SELECTED_BYPASS_ACHIEVEMENT, 'value');

export const SET_CHALLENGE_SELECTED_ENABLE_BUDGET = 'SET_CHALLENGE_SELECTED_ENABLE_BUDGET';
export const setChallengeSelectedEnableBudget = makeActionCreator(SET_CHALLENGE_SELECTED_ENABLE_BUDGET, 'option');


export const DOWNLOADING_SCANNED_CODES = 'DOWNLOADING_SCANNED_CODES';
export const downloadingScannedCodes = makeActionCreator(DOWNLOADING_SCANNED_CODES, 'status');

export const DOWNLOADING_DELETING_SCANNED_CODE = 'DOWNLOADING_DELETING_SCANNED_CODE';
export const downloadingDeletingScannedCode = makeActionCreator(DOWNLOADING_DELETING_SCANNED_CODE, 'status');

export const SET_SCANNED_CODES = 'SET_SCANNED_CODES';
export const setScannedCode = makeActionCreator(SET_SCANNED_CODES, 'data');

export const ADD_SCANNED_CODE = 'ADD_SCANNED_CODE';
export const addScannedCode = makeActionCreator(ADD_SCANNED_CODE, 'data');

export const CLEAR_SCANNED_CODES = 'CLEAR_SCANNED_CODES';
export const clearScannedCodes = makeActionCreator(CLEAR_SCANNED_CODES);


export const SET_SCANNED_CODE_SELECTED_PAGE_SIZE = 'SET_SCANNED_CODE_SELECTED_PAGE_SIZE';
export const setScannedCodeSelectedPageSize = makeActionCreator(SET_SCANNED_CODE_SELECTED_PAGE_SIZE, 'size');

export const SET_SCANNED_CODE_SELECTED_ORDER_BY = 'SET_SCANNED_CODE_SELECTED_ORDER_BY';
export const setScannedCodeSelectedOrderBy = makeActionCreator(SET_SCANNED_CODE_SELECTED_ORDER_BY, 'order');

export const SET_SCANNED_CODE_TAPPED_ID = 'SET_SCANNED_CODE_TAPPED_ID';
export const setScannedCodeTappedId = makeActionCreator(SET_SCANNED_CODE_TAPPED_ID, 'id');

export const SET_SCANNED_CODE_SEARCH_TEXT = 'SET_SCANNED_CODE_SEARCH_TEXT';
export const setScannedCodeSearchText = makeActionCreator(SET_SCANNED_CODE_SEARCH_TEXT, 'text');

export const SAVING_SCANNED_CODES = 'SAVING_SCANNED_CODES';
export const savingScannedCodes = makeActionCreator(SAVING_SCANNED_CODES, 'status');


export const DOWNLOADING_COMPLETED_CHALLENGES = 'DOWNLOADING_COMPLETED_CHALLENGES';
export const downloadingCompletedChallenges = makeActionCreator(DOWNLOADING_COMPLETED_CHALLENGES, 'status');

export const ADDING_EDITING_COMPLETED_CHALLENGE = 'ADDING_EDITING_COMPLETED_CHALLENGE';
export const addingEditingCompletedChallenge = makeActionCreator(ADDING_EDITING_COMPLETED_CHALLENGE, 'status');

export const DOWNLOADING_DELETING_COMPLETED_CHALLENGE = 'DOWNLOADING_DELETING_COMPLETED_CHALLENGE';
export const downloadingDeletingCompletedChallenge = makeActionCreator(DOWNLOADING_DELETING_COMPLETED_CHALLENGE, 'status');

export const SET_COMPLETED_CHALLENGES = 'SET_COMPLETED_CHALLENGES';
export const setCompletedChallenge = makeActionCreator(SET_COMPLETED_CHALLENGES, 'data');

export const ADD_COMPLETED_CHALLENGE = 'ADD_COMPLETED_CHALLENGE';
export const addCompletedChallenge = makeActionCreator(ADD_COMPLETED_CHALLENGE, 'data');

export const CLEAR_COMPLETED_CHALLENGES = 'CLEAR_COMPLETED_CHALLENGES';
export const clearCompletedChallenges = makeActionCreator(CLEAR_COMPLETED_CHALLENGES);


export const SET_COMPLETED_CHALLENGE_SELECTED_PAGE_SIZE = 'SET_COMPLETED_CHALLENGE_SELECTED_PAGE_SIZE';
export const setCompletedChallengeSelectedPageSize = makeActionCreator(SET_COMPLETED_CHALLENGE_SELECTED_PAGE_SIZE, 'size');

export const SET_COMPLETED_CHALLENGE_TAPPED_ID = 'SET_COMPLETED_CHALLENGE_TAPPED_ID';
export const setCompletedChallengeTappedId = makeActionCreator(SET_COMPLETED_CHALLENGE_TAPPED_ID, 'id');

export const SET_COMPLETED_CHALLENGE_SEARCH_TEXT = 'SET_COMPLETED_CHALLENGE_SEARCH_TEXT';
export const setCompletedChallengeSearchText = makeActionCreator(SET_COMPLETED_CHALLENGE_SEARCH_TEXT, 'text');

export const SET_COMPLETED_CHALLENGE_SELECTED_ORDER_BY = 'SET_COMPLETED_CHALLENGE_SELECTED_ORDER_BY';
export const setCompletedChallengeSelectedOrderBy = makeActionCreator(SET_COMPLETED_CHALLENGE_SELECTED_ORDER_BY, 'order');

export const SET_COMPLETED_CHALLENGE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_COMPLETED_CHALLENGE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setCompletedChallengeAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_COMPLETED_CHALLENGE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');
