import { INITIAL_ORDER_BY_FORMS } from '../../constant';
import {
  ADDING_EDITING_FORM, DOWNLOADING_DELETING_FORM,
  DOWNLOADING_FORMS, ENABLING_DISABLING_FORM,
  SET_FORM_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_FORM_SEARCH_TEXT,
  SET_FORM_SELECTED_ORDER_BY,
  SET_FORM_SELECTED_PAGE_SIZE, SET_FORM_TAPPED_ID,
  SET_FORM_SELECTED_VISIBILITY_STATUS,
  SET_FORM_SELECTED_ALLOW_DIRECT_RESPONSE,
  SET_FORM_SELECTED_CLOSE_RESPONSE,
  SET_FORM_SELECTED_DETECT_DUPLICATED_RESPONSE,
  DUPLICATING_FORM,
  SET_FORM_DUPLICATE_DIALOG_VISIBILITY,
} from '../action/simple-action';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  enablingDisabling: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_FORMS,
  filterString: '',
  selectedVisibilityStatus: null,
  selectedAllowDirectResponse: null,
  selectedCloseResponse: null,
  selectedDetectDuplicatedResponse: null,
  duplicatingForm: false,
  duplicateDialogVisibility: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_FORMS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_FORM:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_FORM:
      return { ...state, downloadingDeleting: action.status };
    case SET_FORM_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_FORM_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_FORM_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_FORM_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_FORM_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case ENABLING_DISABLING_FORM:
      return { ...state, enablingDisabling: action.status };
    case SET_FORM_SELECTED_VISIBILITY_STATUS:
      return { ...state, selectedVisibilityStatus: action.value };
    case SET_FORM_SELECTED_ALLOW_DIRECT_RESPONSE:
      return { ...state, selectedAllowDirectResponse: action.value };
    case SET_FORM_SELECTED_CLOSE_RESPONSE:
      return { ...state, selectedCloseResponse: action.value };
    case SET_FORM_SELECTED_DETECT_DUPLICATED_RESPONSE:
      return { ...state, selectedDetectDuplicatedResponse: action.value };
    case DUPLICATING_FORM:
      return { ...state, duplicatingForm: action.status };
    case SET_FORM_DUPLICATE_DIALOG_VISIBILITY:
      return { ...state, duplicateDialogVisibility: action.status };
    default: return state;
  }
};
