import {
  buildQueryParams, sendDeleteRequest, sendGetFileRequest, sendGetRequest, sendPostRequest,
  sendPutRequest, transformQueryResult,
} from '../../helper';
import {
  REST_URL_INDUSTRIES, REST_URL_ADD_EDIT_INDUSTRY, REST_URL_VIEW_DELETE_INDUSTRY,
  REST_URL_CUSTOMERS, REST_URL_ADD_EDIT_CUSTOMER, REST_URL_VIEW_DELETE_CUSTOMER,
  REST_URL_ACTIVITY_TYPES, REST_URL_ADD_EDIT_ACTIVITY_TYPE, REST_URL_VIEW_DELETE_ACTIVITY_TYPE,
  REST_URL_ACTIVITIES, REST_URL_VIEW_DELETE_ACTIVITY, REST_URL_SAVE_ACTIVITY,
} from './constant';

export * from '../../helper';

export const downloadIndustries = async (pageNumber, pageSize, orderBy, searchText, token) => {
  const url = buildQueryParams(REST_URL_INDUSTRIES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addIndustry = async (name, description, token) => {
  const body = { name, description };
  await sendPostRequest(REST_URL_ADD_EDIT_INDUSTRY, body, token);
};

export const editIndustry = async (id, name, description, token) => {
  const body = { id, name, description };
  await sendPutRequest(REST_URL_ADD_EDIT_INDUSTRY, body, token);
};

export const downloadIndustry = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_INDUSTRY.replace(/\{id\}/, id), token);
  return response;
};

export const deleteIndustry = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_INDUSTRY.replace(/\{id\}/, id), token);
};

export const downloadCustomers = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(REST_URL_CUSTOMERS.concat(filterString), pageNumber, pageSize,
    orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addCustomer = async (refId, name, description, industryId, token) => {
  const body = {
    refId,
    name,
    description,
    industryId,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_CUSTOMER, body, token);
};

export const editCustomer = async (id, refId, name, description, industryId, token) => {
  const body = {
    id,
    refId,
    name,
    description,
    industryId,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_CUSTOMER, body, token);
};

export const downloadCustomer = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_CUSTOMER.replace(/\{id\}/, id), token);
  return response;
};

export const deleteCustomer = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_CUSTOMER.replace(/\{id\}/, id), token);
};

export const downloadActivities = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_ACTIVITIES.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_ACTIVITIES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadActivity = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_ACTIVITY.replace(/\{id\}/, id), token);
  return response;
};

export const downloadActivityTypes = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(REST_URL_ACTIVITY_TYPES.concat(filterString), pageNumber, pageSize,
    orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const saveActivities = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_SAVE_ACTIVITY.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_SAVE_ACTIVITY, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const addActivityType = async (name, description, token) => {
  const body = {
    name,
    description,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_ACTIVITY_TYPE, body, token);
};

export const downloadActivityType = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_ACTIVITY_TYPE.replace(/\{id\}/, id), token);
  return response;
};

export const deleteActivityType = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_ACTIVITY_TYPE.replace(/\{id\}/, id), token);
};

export const editActivityType = async (id, name, description, token) => {
  const body = {
    id,
    name,
    description,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_ACTIVITY_TYPE, body, token);
};
