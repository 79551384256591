import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    eventOperatorMenu: {
      title: 'Event Operator',
    },
    mappingPage: {
      title: 'Event Operator Mapping',

      labelNo: 'No',
      labelUserName: 'User Name',
      labelEmail: 'Email',
      labelEventName: 'Event Name',
      labelValidUntil: 'Valid Until',
      labelCreatedDate: 'Created Date',

      placeholderUser: 'User',
      placeholderUserId: 'User ID',
      placeholderFullName: 'Full Name',
      placeholderEmail: 'Email',
      placeholderEventId: 'Event ID',
      placeholderEventName: 'Event Name',
      placeholderValidUntil: 'Valid Until',

      buttonCaptionCreateNewMapping: 'Create New Mapping',
      buttonCaptionDeleteMapping: 'Delete Mapping',
      buttonCaptionEditMapping: 'Edit Mapping',
    },
    eventOperatorEventPage: {
      title: 'Event',

      labelCanPayUsingPoint: 'Can Pay Using Point',
      labelCategory: 'Category',
      labelClockInMustBeInsideArea: 'Clock In Must Be Inside Area',
      labelCreatedDate: 'Created Date',
      labelDescription: 'Description',
      labelEarliestClock: 'Earliest Clock In (minutes)',
      labelEndTime: 'End Time',
      labelEventType: 'Event Type',
      labelFeatured: ' Featured',
      labelFeaturedOrder: 'Featured Order',
      labelImage: 'Image',
      labelLocation: 'Location',
      labelMaxClock: 'Max Clock In (minutes)',
      labelMedia: 'Media',
      labelName: 'Name',
      labelNeedApproval: 'Need Approval',
      labelNormalPrice: 'Normal Price',
      labelOrder: 'Order',
      labelPrice: 'Price',
      labelQuota: 'Quota',
      labelRegistrationRewardPoint: 'Registration Reward Point',
      labelRegistrationRewardType: 'Registration Reward Type',
      labelRegistrationRewardVoucher: 'Registration Reward Voucher',
      labelShortDescription: 'Short Description',
      labelStartTime: 'Start Time',
      labelStatus: 'Status',
      labelVisibilityStatus: 'Visibility Status',
      labelWhoCanRegister: 'Who Can Register',
      labelYoutube: 'Youtube',
      labelAdditionalItems: 'Additional Items',
      labelLabel: 'Label',
      labelType: 'Type',
      labelViewAccess: 'View Access',
      labelVisibilityTime: 'Visibility Time',
      labelContent: 'Content',
      labelUrl: 'URL',
      labelSpeakers: 'Speakers',
      labelSurveyForm: 'Survey Form',
      labelCopied: 'Copied!',

      placeholderCanPayUsingPoint: 'Can Pay Using Point',
      placeholderCategory: 'Category',
      placeholderClockInMustBeInsideArea: 'Clock In Must Be Inside Area',
      placeholderEarliestClock: 'Earliest Clock In (minutes)',
      placeholderEndTime: 'End Time',
      placeholderEventType: 'Event Type',
      placeholderFeatured: ' Featured',
      placeholderFeaturedOrder: 'Featured Order',
      placeholderLocation: 'Location',
      placeholderMaxClock: 'Max Clock In (minutes)',
      placeholderName: 'Name',
      placeholderNeedApproval: 'Need Approval',
      placeholderNormalPrice: 'Normal Price',
      placeholderPrice: 'Price',
      placeholderQuota: 'Quota',
      placeholderRegistrationRewardPoint: 'Registration Reward Point',
      placeholderRegistrationRewardType: 'Registration Reward Type',
      placeholderRegistrationRewardVoucher: 'Registration Reward Voucher',
      placeholderShortDescription: 'Short Description',
      placeholderStartTime: 'Start Time',
      placeholderVisibilityStatus: 'Visibility Status',
      placeholderWhoCanRegister: 'Who Can Register',
      placeholderEnableComment: 'Enable Comment',
      placeholderCommentAccess: 'Comment Access',
      placeholderDiscussionId: 'Discussion ID',
      placeholderSpeakers: 'Speakers',
      placeholderSurveyForm: 'Survey Form',
      placeholderClockInQrCode: 'Clock In QR Code',
      placeholderBanner: 'Banner',
    },
    eventInvitationPage: {
      title: 'Event Invitation',

      labelCreatedDate: 'Created Date',
      labelEmail: 'Email',
      labelEndTime: 'End Time',
      labelEvent: 'Event',
      labelEventName: 'Event Name',
      labelFullName: 'Full Name',
      labelId: 'ID',
      labelInvitationStatus: 'Invitation Status',
      labelLocation: 'Location',
      labelName: 'Name',
      labelPhone: 'Phone',
      labelRecipient: 'Recipient',
      labelRecipients: 'Recipients',
      labelRegisteredOn: 'Registered On',
      labelResentCount: 'Resent Count',
      labelShortDescription: 'Short Description',
      labelStartTime: 'Start Time',
      labelStatus: 'Status',
      labelUser: 'User',
      labelTimeZone: 'Time Zone',

      buttonCaptionCreateNewEventInvitation: 'Create New Event Invitation',
      buttonCaptionDeleteEventInvitation: 'Delete Event Invitation',
      buttonCaptionResend: 'Resend',

      placeholderEmail: 'Email',
      placeholderEndTime: 'End Time',
      placeholderEvent: 'Event',
      placeholderFullName: 'Full Name',
      placeholderId: 'ID',
      placeholderInvitationStatus: 'Invitation Status',
      placeholderLocation: 'Location',
      placeholderName: 'Name',
      placeholderPhone: 'Phone',
      placeholderRegisteredOn: 'Registered On',
      placeholderResentCount: 'Resent Count',
      placeholderShortDescription: 'Short Description',
      placeholderStartTime: 'Start Time',
      placeholderTimeZone: 'Time Zone',

      msgResendConfirmation: 'Are you sure want to resend this invitation?',
    },
    eventOperatorRegistrationPage: {
      title: 'Event Registration',

      labelParticipant: 'Participant',
      labelEvent: 'Event',
      labelRegistrationCode: 'Registration Code',
      labelRegistrationStatus: 'Registration Status',
      labelApprovedOn: 'Approved On',
      labelRejectedOn: 'Rejected On',
      labelRegisteredOn: 'Registered On',
      labelPaymentStatus: 'Payment Status',
      labelCreatedDate: 'Created Date',
      labelStatus: 'Status',
      labelFree: 'Free',

      placeholderRegistrationCode: 'Registration Code',
      placeholderRegistrationStatus: 'Registration Status',
      placeholderRegistrationStatusNotes: 'Registration Status Notes',
      placeholderApprovedOn: 'Approved On',
      placeholderRejectedOn: 'Rejected On',
      placeholderApprovedBy: 'Approved By',
      placeholderRejectedBy: 'Rejected By',
      placeholderNotes: 'Notes',
      placeholderClockInTime: 'Clock In Time',
      placeholderClockInDrift: 'Clock In Drift (Minutes)',
      placeholderClockInLatitude: 'Clock In Latitude',
      placeholderClockInLongitude: 'Clock In Longitude',
      placeholderClockInLocationStatus: 'Clock In Location Status',
      placeholderUser: 'User',
      placeholderUserID: 'User ID',
      placeholderUserFullName: 'User Full Name',
      placeholderUserEmail: 'User Email',
      placeholderUserPhone: 'User Phone',
      placeholderEvent: 'Event',
      placeholderEventID: 'Event ID',
      placeholderEventName: 'Event Name',
      placeholderEventShortDescription: 'Event Short Description',
      placeholderEventStartTime: 'Event Start Time',
      placeholderEventEndTime: 'Event End Time',
      placeholderEventTimezone: 'Event Time Zone',
      placeholderPayment: 'Payment',
      placeholderPaymentID: 'Payment ID',
      placeholderPrice: 'Price',
      placeholderPaymentDate: 'Payment Date',
      placeholderTax: 'Tax',
      placeholderTransactionID: 'Transaction ID',
      placeholderServiceFee: 'Service Fee',
      placeholderPaymentCode: 'Payment Code',
      placeholderDiscount: 'Discount',
      placeholderPaymentChannel: 'Payment Channel',
      placeholderDiscountNotes: 'Discount Notes',
      placeholderPaymentStatus: 'Payment Status',
      placeholderPointDiscount: 'Point Discount',
      placeholderPaymentStatusNotes: 'Payment Status Notes',
      placeholderPointDiscountID: 'Point Discount ID',
      placeholderLastChangedStatus: 'Last Changed Status',
      placeholderTotal: 'Total',

      buttonCaptionApprove: 'Approve',
      buttonCaptionReject: 'Reject',

      msgRejectEventOperatorRegistration: 'Are you sure want to reject this registration?',
      msgApproveEventOperatorRegistration: 'Are you sure want to approve this registration?',
    },
  },
  id: {
    eventOperatorMenu: {
      title: 'Operator Acara',
    },
    mappingPage: {
      title: 'Pemetaan Operator Acara',

      labelNo: 'No',
      labelUserName: 'Nama Pengguna',
      labelEmail: 'Email',
      labelEventName: 'Nama Acara',
      labelValidUntil: 'Berlaku Sampai',
      labelCreatedDate: 'Tanggal Pembuatan',

      placeholderUser: 'Pengguna',
      placeholderUserId: 'ID Pengguna',
      placeholderFullName: 'Nama Lengkap',
      placeholderEmail: 'Email',
      placeholderEventId: 'ID Acara',
      placeholderEventName: 'Nama Acara',
      placeholderValidUntil: 'Berlaku Sampai',

      buttonCaptionCreateNewMapping: 'Buat Pemetaan Baru',
      buttonCaptionDeleteMapping: 'Hapus Pemetaan',
      buttonCaptionEditMapping: 'Ubah Pemetaan',
    },
    eventOperatorEventPage: {
      title: 'Acara',

      labelCanPayUsingPoint: 'Bisa Membayar Menggunakan Poin',
      labelCategory: 'Kategori',
      labelClockInMustBeInsideArea: 'Jam Masuk Harus Dalam Area',
      labelCreatedDate: 'Tanggal Dibuat',
      labelDescription: 'Deskripsi',
      labelEarliestClock: 'Masuk Paling Awal (menit)',
      labelEndTime: 'Waktu Selesai',
      labelEventType: 'Jenis Acara',
      labelFeatured: ' Unggulan',
      labelFeaturedOrder: 'Urutan Unggulan',
      labelImage: 'Gambar',
      labelLocation: 'Lokasi',
      labelMaxClock: 'Maksimal Jam Masuk (menit)',
      labelMedia: 'Media',
      labelName: 'Nama',
      labelNeedApproval: 'Butuh Persetujuan',
      labelNormalPrice: 'Harga Normal',
      labelOrder: 'Urutan',
      labelPrice: 'Harga',
      labelQuota: 'Kuota',
      labelRegistrationRewardPoint: 'Poin Hadiah Pendaftaran',
      labelRegistrationRewardType: 'Jenis Hadiah Pendaftaran',
      labelRegistrationRewardVoucher: 'Voucher Hadiah Pendaftaran ',
      labelShortDescription: 'Deskripsi Singkat',
      labelStartTime: 'Waktu Mulai',
      labelStatus: 'Status',
      labelVisibilityStatus: 'Status Visibilitas',
      labelWhoCanRegister: 'Yang Dapat Mendaftar',
      labelYoutube: 'Youtube',
      labelAdditionalItems: 'Materi Tambahan',
      labelLabel: 'Label',
      labelType: 'Tipe',
      labelViewAccess: 'Akses Pratinjau',
      labelVisibilityTime: 'Waktu Visibilitas',
      labelContent: 'Isi',
      labelUrl: 'URL',
      labelSpeakers: 'Pembicara',
      labelSurveyForm: 'Formulir Survei',
      labelCopied: 'Disalin!',

      placeholderCanPayUsingPoint: 'Bisa Membayar Menggunakan Poin',
      placeholderCategory: 'Kategori',
      placeholderClockInMustBeInsideArea: 'Jam Masuk Harus Dalam Area',
      placeholderEarliestClock: 'Masuk Paling Awal (menit)',
      placeholderEndTime: 'Waktu Selesai',
      placeholderEventType: 'Jenis Acara',
      placeholderFeatured: ' Unggulan',
      placeholderFeaturedOrder: 'Urutan Unggulan',
      placeholderLocation: 'Lokasi',
      placeholderMaxClock: 'Maksimal Jam Masuk (menit)',
      placeholderName: 'Nama',
      placeholderNeedApproval: 'Butuh Persetujuan',
      placeholderNormalPrice: 'Harga Normal',
      placeholderPrice: 'Harga',
      placeholderQuota: 'Kuota',
      placeholderRegistrationRewardPoint: 'Poin Hadiah Pendaftaran',
      placeholderRegistrationRewardType: 'Jenis Hadiah Pendaftaran',
      placeholderRegistrationRewardVoucher: 'Voucher Hadiah Pendaftaran ',
      placeholderShortDescription: 'Deskripsi Singkat',
      placeholderStartTime: 'Waktu Mulai',
      placeholderVisibilityStatus: 'Status Visibilitas',
      placeholderWhoCanRegister: 'Yang Dapat Mendaftar',
      placeholderEnableComment: 'Aktifkan Komentar',
      placeholderCommentAccess: 'Akses Komentar',
      placeholderDiscussionId: 'ID Diskusi',
      placeholderSpeakers: 'Pembicara',
      placeholderSurveyForm: 'Formulir Survei',
      placeholderClockInQrCode: 'Kode QR Absen Masuk',
      placeholderBanner: 'Spanduk',
    },
    eventInvitationPage: {
      title: 'Undangan Acara',

      labelCreatedDate: 'Tanggal Pembuatan',
      labelEmail: 'Email',
      labelEndTime: 'Waktu Selesai',
      labelEvent: 'Acara',
      labelEventName: 'Nama Acara',
      labelFullName: 'Nama Lengkap',
      labelId: 'ID',
      labelInvitationStatus: 'Status Undangan',
      labelLocation: 'Lokasi',
      labelName: 'Nama',
      labelPhone: 'Telepon',
      labelRecipient: 'Penerima',
      labelRecipients: 'Penerima',
      labelRegisteredOn: 'Terdaftar Di',
      labelResentCount: 'Jumlah Terkirim',
      labelShortDescription: 'Deskripsi Singkat',
      labelStartTime: 'Waktu Mulai',
      labelStatus: 'Status',
      labelUser: 'Pengguna',
      labelTimeZone: 'Zona Waktu',

      buttonCaptionCreateNewEventInvitation: 'Buat Undangan Acara Baru',
      buttonCaptionDeleteEventInvitation: 'Hapus Undangan Acara',
      buttonCaptionResend: 'Kirim Ulang',

      placeholderEmail: 'Email',
      placeholderEndTime: 'Waktu Selesai',
      placeholderEvent: 'Acara',
      placeholderFullName: 'Nama Lengkap',
      placeholderId: 'ID',
      placeholderInvitationStatus: 'Status Undangan',
      placeholderLocation: 'Lokasi',
      placeholderName: 'Nama',
      placeholderPhone: 'Telepon',
      placeholderRegisteredOn: 'Terdaftar Di',
      placeholderResentCount: 'Jumlah Terkirim',
      placeholderShortDescription: 'Deskripsi Singkat',
      placeholderStartTime: 'Waktu Mulai',
      placeholderTimeZone: 'Zona Waktu',

      msgResendConfirmation: 'Apa Anda yakin ingin mengirim ulang undangan ini?',
    },
    eventOperatorRegistrationPage: {
      title: 'Undangan Acara',

      labelParticipant: 'Peserta',
      labelEvent: 'Acara',
      labelRegistrationCode: 'Kode Registrasi',
      labelRegistrationStatus: 'Status Registrasi',
      labelApprovedOn: 'Disetujui Pada',
      labelRejectedOn: 'Ditolak Pada',
      labelRegisteredOn: 'Terdaftar Pada',
      labelPaymentStatus: 'Status Pembayaran',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelStatus: 'Status',
      labelFree: 'Gratis',

      placeholderRegistrationCode: 'Kode Registrasi',
      placeholderRegistrationStatus: 'Status Registrasi',
      placeholderRegistrationStatusNotes: 'Catatan Status Registrasi',
      placeholderApprovedOn: 'Disetujui Pada',
      placeholderRejectedOn: 'Ditolak Pada',
      placeholderApprovedBy: 'Disetujui Oleh',
      placeholderRejectedBy: 'Ditolak Oleh',
      placeholderNotes: 'Catatan',
      placeholderClockInTime: 'Waktu Jam Masuk',
      placeholderClockInDrift: 'Perbedaan Jam Masuk (Menit)',
      placeholderClockInLatitude: 'Posisi Garis Lintang Jam Masuk',
      placeholderClockInLongitude: 'Posisi Garis Bujur Jam Masuk',
      placeholderClockInLocationStatus: 'Status lokasi Jam Masuk',
      placeholderUser: 'Pengguna',
      placeholderUserID: 'ID Pengguna',
      placeholderUserFullName: 'Nama Lengkap Pengguna',
      placeholderUserEmail: 'Email Pengguna',
      placeholderUserPhone: 'Telepon Pengguna',
      placeholderEvent: 'Acara',
      placeholderEventID: 'ID Acara',
      placeholderEventName: 'Nama Acara',
      placeholderEventShortDescription: 'Deskripsi Singkat Acara',
      placeholderEventStartTime: 'Waktu Mulai Acara',
      placeholderEventEndTime: 'Waktu Berakhir Acara',
      placeholderEventTimezone: 'Zona Waktu Acara',
      placeholderPayment: 'Pembayaran',
      placeholderPaymentID: 'ID Pembayaran',
      placeholderPrice: 'Harga',
      placeholderPaymentDate: 'Tanggal Pembayaran',
      placeholderTax: 'Pajak',
      placeholderTransactionID: 'ID Transaksi',
      placeholderServiceFee: 'Biaya Pelayanan',
      placeholderPaymentCode: 'Kode Pembayaran',
      placeholderDiscount: 'Diskon',
      placeholderPaymentChannel: 'Saluran Pembayaran',
      placeholderDiscountNotes: 'Catatan Diskon',
      placeholderPaymentStatus: 'Status Pembayaran',
      placeholderPointDiscount: 'Poin Diskon',
      placeholderPaymentStatusNotes: 'Catatan Status Pembayaran',
      placeholderPointDiscountID: 'ID Poin Diskon',
      placeholderLastChangedStatus: 'Perubahan Status Terakhir',
      placeholderTotal: 'Total',

      buttonCaptionApprove: 'Setuju',
      buttonCaptionReject: 'Tolak',

      msgRejectEventOperatorRegistration: 'Apa Anda yakin ingin menolak registrasi ini?',
      msgApproveEventOperatorRegistration: 'Apa anda yakin ingin menyetujui registrasi ini?',
    },
  },
});

export default LocalizedString;
