import { reset } from 'redux-form';
import { PAGE_MODE_TABLE, RXFORM_EVENT_OPERATOR_MAPPING } from '../../../constant';
import { addEventOperatorMapping, editEventOperatorMapping, localDateToUtc } from '../../../helper';
import {
  addingEditingEventOperatorMapping, clearEventOperatorMappings, setAlertErrorMessage,
  setEventOperatorMappingSelectedEvent, setEventOperatorMappingSelectedUser, setFunctionalPageMode,
} from '../simple-action';
import downloadEventOperatorMappingsAsync from './downloadEventOperatorMappingsAsync';

export default (validUntil) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingEventOperatorMapping(true));

    const { token } = getState().authentication;
    const { tappedId, selectedUser, selectedEvent } = getState().uiEventOperatorMapping;
    const { data } = getState().eventOperatorMappings;

    const found = data[tappedId];
    const user = selectedUser.value || found.user.id;
    const event = selectedEvent.value || found.event.id;

    if (tappedId) {
      await editEventOperatorMapping(tappedId, event, user, localDateToUtc(validUntil), token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addEventOperatorMapping(event, user, localDateToUtc(validUntil), token);
    }

    dispatch(reset(RXFORM_EVENT_OPERATOR_MAPPING));
    dispatch(clearEventOperatorMappings());
    dispatch(downloadEventOperatorMappingsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setEventOperatorMappingSelectedEvent(''));
    dispatch(setEventOperatorMappingSelectedUser(''));
  } finally {
    dispatch(addingEditingEventOperatorMapping(false));
  }
};
