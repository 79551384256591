import {
  downloadPayments,
  transformSearchText,
} from '../../../helper';
import {
  downloadingPayments,
  setPayments,
} from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication,
    payments,
    uiPayment,
  } = getState();
  const { token } = authentication;
  const { meta } = payments;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiPayment;

  try {
    dispatch(downloadingPayments(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadPayments(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      searchBarText ? transformSearchText(searchBarText) : '',
      filterString,
      token,
    );

    if (list) {
      dispatch(setPayments(list));
    }
  } finally {
    dispatch(downloadingPayments(false));
  }
};
