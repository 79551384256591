import { approval } from '../../../constant';
import { downloadDiscussion } from '../../../helper';
import {
  addDiscussion, downloadingDeletingDiscussion, setDiscussionSelectedAllowDirectComment,
  setDiscussionSelectedAllowPublicRead, setDiscussionSelectedDisableComment,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingDiscussion(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiDiscussion;

    const result = await downloadDiscussion(tappedId, token);
    dispatch(addDiscussion(result));
    dispatch(setDiscussionSelectedAllowDirectComment(result.allowDirectUserComment
      ? approval[0] : approval[1]));
    dispatch(setDiscussionSelectedAllowPublicRead(result.allowPublicComment
      ? approval[0] : approval[1]));
    dispatch(setDiscussionSelectedDisableComment(result.disableComment
      ? approval[0] : approval[1]));
  } finally {
    dispatch(downloadingDeletingDiscussion(false));
  }
};
