import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage } from '../../component';
import {
  RXFORM_PROMOTED_MERCHANT, RXSTATE_PROMOTED_MERCHANTS, approval,
  FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW,
  RXSTATE_PROMOTED_MERCHANT_PAGE,
  RXFIELD_PROMOTED_MERCHANT_IS_PROMOTED, FILTER_TYPE_SWITCH,
  RXFIELD_PROMOTED_MERCHANT_CITY, RXFIELD_PROMOTED_MERCHANT_NAME,
  RXFIELD_PROMOTED_MERCHANT_ADDRESS, RXFIELD_PROMOTED_MERCHANT_WEBSITE,
  RXFIELD_PROMOTED_MERCHANT_PLAY_STORE_URL, RXFIELD_PROMOTED_MERCHANT_APP_STORE_URL,
  RXFIELD_PROMOTED_MERCHANT_ORDER, RXFIELD_PROMOTED_MERCHANT_COMPANY_LOGO,
  RXFIELD_PROMOTED_MERCHANT_COMPANY_BANNER,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField, renderReduxFormImageInputField,
  renderReduxFormSimpleDropdownField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxFormValidatePromotedMerchant } from '../../validation';

const renderDialogContent = (
  initialValues, addingEditing, downloadingDeleting, pageMode,
  onPromotedOptionSelected,
) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_PROMOTED_MERCHANT_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.promotedMerchantPage.placeholderName}
          label={LocalizedString.promotedMerchantPage.placeholderName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_PROMOTED_MERCHANT_ADDRESS}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.promotedMerchantPage.placeholderAddress}
          label={LocalizedString.promotedMerchantPage.placeholderAddress}
          multiline
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_PROMOTED_MERCHANT_CITY}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.promotedMerchantPage.placeholderCity}
          label={LocalizedString.promotedMerchantPage.placeholderCity}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_PROMOTED_MERCHANT_WEBSITE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.promotedMerchantPage.placeholderWebsite}
          label={LocalizedString.promotedMerchantPage.placeholderWebsite}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_PROMOTED_MERCHANT_PLAY_STORE_URL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.promotedMerchantPage.placeholderPlayStoreUrl}
          label={LocalizedString.promotedMerchantPage.placeholderPlaystoreUrl}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_PROMOTED_MERCHANT_APP_STORE_URL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.promotedMerchantPage.placeholderAppStoreUrl}
          label={LocalizedString.promotedMerchantPage.placeholderAppstoreUrl}
          disabled
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_PROMOTED_MERCHANT_IS_PROMOTED}
          component={renderReduxFormSimpleDropdownField}
          placeholder={LocalizedString.promotedMerchantPage.placeholderPromoted}
          label={LocalizedString.promotedMerchantPage.placeholderPromoted}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          data={approval}
          value={initialValues.isPromoted}
          onOptionSelected={onPromotedOptionSelected}
          onBlur={(e) => e.preventDefault()}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_PROMOTED_MERCHANT_ORDER}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.promotedMerchantPage.placeholderOrder}
          label={LocalizedString.promotedMerchantPage.placeholderOrder}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          type="number"
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_PROMOTED_MERCHANT_COMPANY_LOGO}
          component={renderReduxFormImageInputField}
          label={LocalizedString.promotedMerchantPage.placeholderCompanyLogo}
          disabled
          defaultValue={initialValues.logo}
          useFullWidthImage
          fullImageWidth="100%"
          loading={downloadingDeleting}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_PROMOTED_MERCHANT_COMPANY_BANNER}
          component={renderReduxFormImageInputField}
          label={LocalizedString.promotedMerchantPage.placeholderCompanyBanner}
          disabled
          defaultValue={initialValues.banner}
          useFullWidthImage
          fullImageWidth="100%"
          loading={downloadingDeleting}
        />
      </Grid>
    </Grid>
  </Grid>
);

const PromotedMerchantPage = ({
  downloading,
  handleSubmit, onAppear, onChangePage,
  onChangePageSize, onRefresh,
  onSearchBarTextChanged, onSortPressed,
  onViewPressed, initialValues,
  onAdvancedFilterPressed, onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  cities, loadingCity, onChangeCityText, addingEditing, downloadingDeleting, pageMode,
  onPromotedOptionSelected, onSavePressed, onEditPressed, onCancelPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_PROMOTED_MERCHANTS}
    uiPage={RXSTATE_PROMOTED_MERCHANT_PAGE}
    filterColumns={[
      {
        title: LocalizedString.promotedMerchantPage.labelCity,
        field: RXFIELD_PROMOTED_MERCHANT_CITY,
        type: FILTER_TYPE_DROPDOWN,
        data: cities,
        loading: loadingCity,
        onChangeFilterText: onChangeCityText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.promotedMerchantPage.placeholderPromoted,
        field: RXFIELD_PROMOTED_MERCHANT_IS_PROMOTED,
        type: FILTER_TYPE_SWITCH,
      },
    ]}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    tableColumns={[
      {
        title: LocalizedString.promotedMerchantPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.promotedMerchantPage.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.promotedMerchantPage.labelCity, field: 'city.name', sorting: !downloading },
      { title: LocalizedString.promotedMerchantPage.labelPromoted, field: 'isPromoted', sorting: !downloading },
      { title: LocalizedString.promotedMerchantPage.labelOrder, field: 'order', sorting: !downloading },
      { title: LocalizedString.promotedMerchantPage.labelStatus, field: 'status', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.promotedMerchantPage.title}
    editButtonCaption={LocalizedString.promotedMerchantPage.buttonCaptionEditPromotedMerchant}
    onEditPressed={onEditPressed}
    onSavePressed={onSavePressed}
    useFullWidth
    disableCreate
    disableDelete
    onCancelPressed={onCancelPressed}
    editPermissionName="MERCHANT_EDIT_MERCHANT"
  >
    {renderDialogContent(
      initialValues, addingEditing, downloadingDeleting, pageMode,
      onPromotedOptionSelected,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_PROMOTED_MERCHANT,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: rxFormValidatePromotedMerchant,
})(PromotedMerchantPage);

PromotedMerchantPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  cities: PropTypes.arrayOf(SimpleDataShape).isRequired,

  pageMode: PropTypes.string.isRequired,

  loadingCity: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,

  onAppear: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeCityText: PropTypes.func.isRequired,
  onPromotedOptionSelected: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
};
