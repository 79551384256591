import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Grid, withStyles,
} from '@material-ui/core';
import { AccentButton } from '../../component';
import {
  COLOR_BACKGROUND, COLOR_BACKGROUND_SECONDARY, COLOR_DANGEROUS, COLOR_PRIMARY,
  COLOR_TEXT_ON_PRIMARY, RXFIELD_DFORM_FORM_NAME_DIALOG, RXFORM_DUPLICATE_FORM_DIALOG,
} from '../../constant';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { rxformValidateFormDialog } from '../../validation';

const styles = (() => ({
  paper: {
    transform: 'translateZ(0px)',
  },
  headerContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  form: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    justifyContent: 'center',
  },
  button: {
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
    margin: '15px 0px 10px 0px',
    borderRadius: 50,
    '&:hover': {
      backgroundColor: COLOR_PRIMARY,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: COLOR_PRIMARY,
    },
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${COLOR_PRIMARY}`,
    },
  },
  buttonText: {
    color: COLOR_DANGEROUS,
  },
  totalPersonCountContainer: {
    padding: '10px 20px 10px 20px',
    background: COLOR_BACKGROUND_SECONDARY,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '20px 0px 20px 0px',
  },
  totalPersonCountWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  captionText: {
    marginLeft: 10,
  },
  boldText: {
    fontWeight: 'bold',
  },
  outlinedButton: {
    color: COLOR_PRIMARY,
    borderWidth: 1,
    borderRadius: 50,
    borderColor: COLOR_PRIMARY,
    background: COLOR_BACKGROUND,
  },
}));

const DuplicateDialog = ({
  submitting, visibility,
  handleSubmit, onCancelPressed, onSendPressed,
  classes,
}) => (
  <Dialog
    open={visibility}
    aria-labelledby="form-dialog-title"
    maxWidth="sm"
    fullWidth
    classes={{ paper: classes.paper }}
  >
    <div className={classes.headerContainer}>
      <DialogTitle>{LocalizedString.formPage.labelDuplicateTask}</DialogTitle>
    </div>

    <DialogContent>
      <form
        onSubmit={handleSubmit(onSendPressed)}
        className={classes.form}
      >
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_DFORM_FORM_NAME_DIALOG}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.formPage.labelNewName}
                label={LocalizedString.formPage.labelNewName}
                disabled={submitting}
                required
              />
            </Grid>
          </Grid>
        </Grid>

        <DialogActions className={classes.buttonContainer}>
          <AccentButton
            onClick={onCancelPressed}
            variant="text"
            caption={GlobalLocalizedString.common.buttonCaptionCancel}
            className={classes.buttonText}
            disabled={submitting}
          />

          <AccentButton
            variant="contained"
            type="submit"
            className={classes.button}
            loading={submitting}
            disableElevation
            caption={GlobalLocalizedString.common.buttonCaptionSend}
            disabled={submitting}
          />
        </DialogActions>
      </form>
    </DialogContent>
  </Dialog>
);

export default reduxForm({
  form: RXFORM_DUPLICATE_FORM_DIALOG,
  validate: rxformValidateFormDialog,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(withStyles(styles)(DuplicateDialog));

DuplicateDialog.propTypes = {
  submitting: PropTypes.bool.isRequired,
  visibility: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onSendPressed: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};
