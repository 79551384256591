import { ORDER_SORT_ASCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_FAQ_CATEGORIES = '/news/api/v1/FaqCategory?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_FAQ_CATEGORY = '/news/api/v1/FaqCategory';
export const REST_URL_VIEW_DELETE_FAQ_CATEGORY = '/news/api/v1/FaqCategory/{id}';
export const REST_URL_FAQS = '/news/api/v1/Faq?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_FAQ = '/news/api/v1/Faq';
export const REST_URL_VIEW_DELETE_FAQ = '/news/api/v1/Faq/{id}';

export const ROUTE_NAME_FAQ_MANAGEMENT = '/faq-management';
export const ROUTE_NAME_FAQ = '/faq';
export const ROUTE_NAME_FAQ_CATEGORY = '/faq-category';

export const RXFORM_FAQ = 'faqPage';
export const RXFORM_FAQ_CATEGORY = 'faqCategoryPage';

export const RXFIELD_FAQ_CATEGORY_NAME = 'name';
export const RXFIELD_FAQ_CATEGORY_DESCRIPTION = 'description';
export const RXFIELD_FAQ_QUESTION = 'question';
export const RXFIELD_FAQ_SHORT_ANSWER = 'shortAnswer';
export const RXFIELD_FAQ_DETAILED_ANSWER = 'detailedAnswer';
export const RXFIELD_FAQ_ORDER = 'order';
export const RXFIELD_FAQ_VISIBILITY_STATUS = 'visibilityStatus';
export const RXFIELD_FAQ_CATEGORY = 'category';

export const RXSTATE_FAQ = 'faqs';
export const RXSTATE_FAQ_PAGE = 'uiFAQ';
export const RXSTATE_FAQ_CATEGORY = 'faqCategories';
export const RXSTATE_FAQ_CATEGORY_PAGE = 'uiFAQCategory';

export const INITIAL_ORDER_BY_FAQS = `question ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_FAQ_CATEGORIES = `name ${ORDER_SORT_ASCENDING}`;

export const MENUID_FAQ_MANAGEMENT = 'MENUID_FAQ_MANAGEMENT';
export const MENUID_FAQ_MANAGEMENT_FAQ = 'MENUID_FAQ_MANAGEMENT_FAQ';
export const MENUID_FAQ_MANAGEMENT_FAQ_CATEGORY = 'MENUID_FAQ_MANAGEMENT_CATEGORY';
