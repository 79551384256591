import { downloadEventInvitation, resendEventInvitation } from '../../../helper';
import { addEventInvitation, resendingEventInvitation } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resendingEventInvitation(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiEventInvitation;

    await resendEventInvitation(tappedId, token);
    const result = await downloadEventInvitation(tappedId, token);

    dispatch(addEventInvitation(result));
  } finally {
    dispatch(resendingEventInvitation(false));
  }
};
