import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  CircularProgress, Grid, makeStyles, Snackbar, Typography,
} from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FunctionalPage } from '../../component';
import {
  COLOR_BACKGROUND, COLOR_MODIFIED_ROW, COLOR_TABLE_ICON,
  DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE, EVENT_TYPE_OPERATION, EVENT_TYPE_OPERATION_UPDATE,
  FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_TEXT, PICKER_MODE_DATE_TIME,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, RXFIELD_AUDIT_COLUMNS, RXFIELD_AUDIT_ENTITY_ID,
  RXFIELD_AUDIT_ENTITY_NAME, RXFIELD_AUDIT_EVENT_TIME, RXFIELD_AUDIT_EVENT_TYPE,
  RXFIELD_AUDIT_FILTER_USER_NAME, RXFIELD_AUDIT_GROUP_ID, RXFIELD_AUDIT_IS_MODIFIED,
  RXFIELD_AUDIT_SERVICE_NAME, RXFIELD_AUDIT_USER_ID, RXFIELD_AUDIT_USER_NAME, RXFORM_AUDIT_TRAIL,
  RXSTATE_AUDIT_TRAILS, RXSTATE_AUDIT_TRAILS_PAGE, snackbarDuration,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import {
  renderReduxFormEditableTableField, renderReduxFormOutlinedTextField, renderReduxFormSwitchField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';

const useStyles = makeStyles(() => ({
  tableRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cellText: {
    wordBreak: 'break-all',
  },
  copyIconContainer: {
    alignItems: 'flex-end',
    marginLeft: 10,
    fontSize: 18,
  },
  tableContainer: {
    position: 'relative',
    paddingTop: 10,
  },
  tableHelperText: {
    fontStyle: 'italic',
    position: 'absolute',
    top: 0,
    left: 16,
  },
}));

const renderValueRow = (value, copying, onCopyPressed, classes) => value && (
  <div className={classes.tableRowContainer}>
    <Typography className={classes.cellText}>
      {value.length > 200 ? `${value.substr(0, 200)}...` : value}
    </Typography>

    <CopyToClipboard
      text={value}
      onCopy={() => onCopyPressed(copying)}
      className={classes.copyIconContainer}
    >
      <FileCopyOutlined style={{ fill: COLOR_TABLE_ICON }} />
    </CopyToClipboard>

    <Snackbar
      open={copying}
      autoHideDuration={snackbarDuration}
      message={LocalizedString.auditTrailPage.labelCopied}
      onClose={() => onCopyPressed(copying)}
    />
  </div>
);

const renderDialogContent = (initialValues, copying, downloading, downloadingDeleting, filtering,
  isModified, onCopyPressed, onIsModifiedOptionSelected, classes) => (
    <Grid>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_AUDIT_EVENT_TYPE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.auditTrailPage.placeholderOperation}
              label={LocalizedString.auditTrailPage.placeholderOperation}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_AUDIT_SERVICE_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.auditTrailPage.placeholderServiceName}
              label={LocalizedString.auditTrailPage.placeholderServiceName}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_AUDIT_ENTITY_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.auditTrailPage.placeholderEntityName}
              label={LocalizedString.auditTrailPage.placeholderEntityName}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_AUDIT_ENTITY_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.auditTrailPage.placeholderEntityId}
              label={LocalizedString.auditTrailPage.placeholderEntityId}
              disabled
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_AUDIT_GROUP_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.auditTrailPage.placeholderGroupID}
              label={LocalizedString.auditTrailPage.placeholderGroupID}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_AUDIT_USER_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.auditTrailPage.placeholderUserID}
              label={LocalizedString.auditTrailPage.placeholderUserID}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_AUDIT_USER_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.auditTrailPage.placeholderUserName}
              label={LocalizedString.auditTrailPage.placeholderUserName}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_AUDIT_EVENT_TIME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.auditTrailPage.placeholderEventTime}
              label={LocalizedString.auditTrailPage.placeholderEventTime}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_AUDIT_IS_MODIFIED}
              component={renderReduxFormSwitchField}
              label={LocalizedString.auditTrailPage.placeholderModified}
              hidden={initialValues.eventType !== EVENT_TYPE_OPERATION_UPDATE}
              onOptionSelected={onIsModifiedOptionSelected}
            />
          </Grid>

          {downloadingDeleting || filtering ? (<CircularProgress color="inherit" />) : (
            <Grid item sm={12} className={classes.tableContainer}>
              <Field
                name={RXFIELD_AUDIT_COLUMNS}
                component={renderReduxFormEditableTableField}
                disabled
                disableToolbar
                currentPage={1}
                totalCount={initialValues.columns.length}
                defaultValue={initialValues.columns}
                tableColumns={[
                  {
                    title: LocalizedString.auditTrailPage.labelNo, field: 'no', sorting: false, width: 40,
                  },
                  {
                    title: LocalizedString.auditTrailPage.labelColumnName, field: 'columnName', sorting: false, width: '15%',
                  },
                  {
                    title: LocalizedString.auditTrailPage.labelOldValue,
                    field: 'oldValue',
                    sorting: false,
                    width: '30%',
                    render: ({ oldValue }) => renderValueRow(oldValue, copying, onCopyPressed,
                      classes),
                  },
                  {
                    title: LocalizedString.auditTrailPage.labelNewValue,
                    field: 'newValue',
                    sorting: false,
                    width: '30%',
                    render: ({ newValue }) => renderValueRow(newValue, copying, onCopyPressed,
                      classes),
                  },
                ]}
                rowStyle={(rowData) => ({
                  backgroundColor: rowData.isModified ? COLOR_MODIFIED_ROW : COLOR_BACKGROUND,
                })}
                tableLayout="fixed"
              />

              <Typography variant="body2" className={classes.tableHelperText}>
                {LocalizedString.auditTrailPage.labelDateTimeTableValues}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
);

const AuditTrailPage = ({
  initialValues, serviceNames, profiles,
  copying, downloading, downloadingDeleting, filtering, isModified, loadingServiceName,
  loadingProfile,
  handleSubmit, onAppear, onAdvancedFilterPressed, onApplyAdvancedFilterPressed, onCancelPressed,
  onChangePage, onChangePageSize, onChangeServiceNameText, onChangeProfileText, onCopyPressed,
  onFinishModifiying, onIsModifiedOptionSelected, onRefresh, onResetAdvancedFilterPressed,
  onSearchBarTextChanged, onSortPressed, onViewPressed,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (filtering) {
      onFinishModifiying();
    }
  });

  return (
    <FunctionalPage
      data={RXSTATE_AUDIT_TRAILS}
      uiPage={RXSTATE_AUDIT_TRAILS_PAGE}
      filterColumns={[
        {
          title: LocalizedString.auditTrailPage.placeholderEventTime,
          field: RXFIELD_AUDIT_EVENT_TIME,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.auditTrailPage.placeholderOperation,
          field: RXFIELD_AUDIT_EVENT_TYPE,
          type: FILTER_TYPE_DROPDOWN,
          data: EVENT_TYPE_OPERATION,
          useDropdownValue: true,
          mediumWidth: 4,
        },
        {
          title: LocalizedString.auditTrailPage.placeholderServiceName,
          field: RXFIELD_AUDIT_SERVICE_NAME,
          type: FILTER_TYPE_DROPDOWN,
          data: serviceNames,
          loading: loadingServiceName,
          onChangeFilterText: onChangeServiceNameText,
          mediumWidth: 4,
        },
        {
          title: LocalizedString.auditTrailPage.placeholderEntityName,
          field: RXFIELD_AUDIT_ENTITY_NAME,
          type: FILTER_TYPE_TEXT,
          mediumWidth: 4,
        },
        {
          title: LocalizedString.auditTrailPage.placeholderEntityId,
          field: RXFIELD_AUDIT_ENTITY_ID,
          type: FILTER_TYPE_TEXT,
          mediumWidth: 4,
        },
        {
          title: LocalizedString.auditTrailPage.placeholderGroupID,
          field: RXFIELD_AUDIT_GROUP_ID,
          type: FILTER_TYPE_TEXT,
          mediumWidth: 4,
        },
        {
          title: LocalizedString.auditTrailPage.placeholderUserName,
          field: RXFIELD_AUDIT_FILTER_USER_NAME,
          type: FILTER_TYPE_DROPDOWN,
          data: profiles,
          loading: loadingProfile,
          onChangeFilterText: onChangeProfileText,
          useDropdownValue: true,
          mediumWidth: 4,
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.auditTrailPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.auditTrailPage.labelOperation, field: 'eventType', sorting: !downloading },
        { title: LocalizedString.auditTrailPage.labelServiceName, field: 'serviceName', sorting: !downloading },
        { title: LocalizedString.auditTrailPage.labelEntityName, field: 'entityName', sorting: !downloading },
        { title: LocalizedString.auditTrailPage.labelEntityId, field: 'entityId', sorting: !downloading },
        { title: LocalizedString.auditTrailPage.labelUser, field: 'user.fullName', sorting: !downloading },
        {
          title: LocalizedString.auditTrailPage.labelEventTime,
          field: 'eventTime',
          sorting: !downloading,
          render: ({ eventTime }) => (eventTime
            ? toMoment(eventTime).format(DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE) : null),
        },
      ]}
      handleSubmit={handleSubmit}
      onAppear={onAppear}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      title={LocalizedString.auditTrailPage.title}
      useFullWidth
      disableCreate
      disableDelete
      disableEdit
    >
      {renderDialogContent(initialValues, copying, downloading, downloadingDeleting, filtering,
        isModified, onCopyPressed, onIsModifiedOptionSelected, classes)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_AUDIT_TRAIL,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AuditTrailPage);

AuditTrailPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  serviceNames: PropTypes.arrayOf(SimpleDataShape).isRequired,
  profiles: PropTypes.arrayOf(SimpleDataShape).isRequired,
  copying: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  filtering: PropTypes.bool.isRequired,
  isModified: PropTypes.bool.isRequired,
  loadingServiceName: PropTypes.bool.isRequired,
  loadingProfile: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeServiceNameText: PropTypes.func.isRequired,
  onChangeProfileText: PropTypes.func.isRequired,
  onCopyPressed: PropTypes.func.isRequired,
  onFinishModifiying: PropTypes.func.isRequired,
  onIsModifiedOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
