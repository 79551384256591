import { PAGE_MODE_TABLE } from '../../../constant';
import { deleteSponsorCategory, downloadSponsorCategory } from '../../../helper';
import {
  addSponsorCategory, clearSponsorCategories, downloadingDeletingSponsorCategory,
  setAlertErrorMessage, setFunctionalPageMode, setSponsorCategoryTappedId,
} from '../simple-action';
import downloadSponsorCategoriesAsync from './downloadSponsorCategoriesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingSponsorCategory(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSponsorCategory;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteSponsorCategory(tappedId, token);
      dispatch(setSponsorCategoryTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearSponsorCategories());
      dispatch(downloadSponsorCategoriesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const sponsorCategory = await downloadSponsorCategory(tappedId, token);
      dispatch(addSponsorCategory(sponsorCategory));
    }
  } finally {
    dispatch(downloadingDeletingSponsorCategory(false));
  }
};
