import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { transformInitialValues } from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  INITIAL_ORDER_BY_SPONSOR_CATEGORIES, MENUID_EVENT_SPONSOR_CATEGORY,
  PAGE_MODE_TABLE, RXFORM_SPONSOR_CATEGORY,
} from '../../constant';
import {
  addEditSponsorCategoryAsync, clearSponsorCategories, downloadDeleteSponsorCategoryAsync,
  downloadSponsorCategoriesAsync, enableDisableSponsorCategoryAsync, setActiveSideMenuItem,
  setAlertConfirmationMessage, setAlertErrorMessage,
  setSponsorCategoryAdvancedFilterDialogSelectedFilterString, setSponsorCategorySearchText,
  setSponsorCategorySelectedOrderBy, setSponsorCategorySelectedPageSize, setSponsorCategoryTappedId,
} from '../../redux/action';
import SponsorCategoryPage from './sponsor-category.presentation';

const getInitialValues = (state) => {
  const { sponsorCategories, uiSponsorCategory, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiSponsorCategory;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? sponsorCategories.data[tappedId] : {};

  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      {
        name: found.name,
        description: found.description,
        order: found.order,
      })
    : {
      name: '',
      description: '',
      order: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  addingEditing: state.uiSponsorCategory.addingEditing,
  currentCategoryStatus: state.sponsorCategories.data[state.uiFunctionalPage.tappedId]
    ? state.sponsorCategories.data[state.uiFunctionalPage.tappedId].status : '',
  downloading: state.uiSponsorCategory.downloading,
  enablingDisabling: state.uiSponsorCategory.enablingDisabling,
  sponsorCategories: state.sponsorCategories.data,
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setSponsorCategoryAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_EVENT_SPONSOR_CATEGORY));
    dispatch(setSponsorCategorySearchText(''));
    dispatch(clearSponsorCategories());
    dispatch(setSponsorCategorySelectedPageSize(20));
    dispatch(setSponsorCategorySelectedOrderBy(INITIAL_ORDER_BY_SPONSOR_CATEGORIES));
    dispatch(downloadSponsorCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setSponsorCategoryAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearSponsorCategories());
    dispatch(downloadSponsorCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_SPONSOR_CATEGORY));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadSponsorCategoriesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setSponsorCategorySelectedPageSize(pageSize));
    dispatch(downloadSponsorCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(downloadDeleteSponsorCategoryAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableSponsorCategoryAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setSponsorCategoryTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onCreatePressed: () => {
    dispatch(setSponsorCategoryTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setSponsorCategoryTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setSponsorCategoryTappedId(id));
    dispatch(downloadDeleteSponsorCategoryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setSponsorCategorySelectedPageSize(pageSize));
    dispatch(clearSponsorCategories());
    dispatch(downloadSponsorCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setSponsorCategoryAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearSponsorCategories());
    dispatch(setSponsorCategorySelectedOrderBy(INITIAL_ORDER_BY_SPONSOR_CATEGORIES));
    dispatch(downloadSponsorCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ name, description, order }) => {
    try {
      await dispatch(addEditSponsorCategoryAsync(name, description, order));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setSponsorCategorySearchText(text));
      dispatch(clearSponsorCategories());
      await dispatch(downloadSponsorCategoriesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setSponsorCategorySelectedOrderBy(orderBy));
    dispatch(clearSponsorCategories());
    dispatch(downloadSponsorCategoriesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ name, description, order }) => {
    await dispatch(addEditSponsorCategoryAsync(name, description, order));
  },
  onViewPressed: (id) => {
    dispatch(setSponsorCategoryTappedId(id));
    dispatch(downloadDeleteSponsorCategoryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SponsorCategoryPage);
