import { INITIAL_ORDER_BY_EVENT_OPERATOR_EVENTS } from '../../constant';
import {
  DOWNLOADING_DELETING_EVENT_OPERATOR_EVENT,
  DOWNLOADING_EVENT_OPERATOR_EVENTS, COPYING_EVENT_OPERATOR_EVENT_QR_CODE,
  SET_EVENT_OPERATOR_EVENT_SELECTED_ORDER_BY, SET_EVENT_OPERATOR_EVENT_SEARCH_TEXT,
  SET_EVENT_OPERATOR_EVENT_SELECTED_PAGE_SIZE, SET_EVENT_OPERATOR_EVENT_TAPPED_ID,
} from '../action';

const initialState = {
  copying: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_EVENT_OPERATOR_EVENTS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_EVENT_OPERATOR_EVENTS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_EVENT_OPERATOR_EVENT:
      return { ...state, downloadingDeleting: action.status };
    case SET_EVENT_OPERATOR_EVENT_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_EVENT_OPERATOR_EVENT_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_EVENT_OPERATOR_EVENT_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_EVENT_OPERATOR_EVENT_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case COPYING_EVENT_OPERATOR_EVENT_QR_CODE:
      return { ...state, copying: action.status };
    default: return state;
  }
};
