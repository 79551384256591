import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  approval, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_SWITCH, FILTER_TYPE_TEXT,
  PAGE_MODE_VIEW, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, PICKER_MODE_DATE_TIME,
  RXFIELD_DISCUSSION_ALLOW_DIRECT_USER_COMMENT, RXFIELD_DISCUSSION_ALLOW_PUBLIC_READ,
  RXFIELD_DISCUSSION_COMMENT_COUNT, RXFIELD_DISCUSSION_DESCRIPTION,
  RXFIELD_DISCUSSION_DISABLE_COMMENT, RXFIELD_DISCUSSION_NAME, RXFIELD_DISCUSSION_REFERENCE_ID,
  RXFIELD_DISCUSSION_TAGS, RXFORM_DISCUSSION, RXSTATE_DISCUSSION_PAGE, RXSTATE_DISCUSSIONS, status,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape } from '../../type';
import { rxformValidateDiscussion } from '../../validation';

const renderDialogContent = (initialValues, addingEditing, downloadingDeleting,
  onAllowDirectUserCommentOptionSelected, onAllowPublicReadOptionSelected,
  onDisableCommentOptionSelected, pageMode) => (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_DISCUSSION_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.discussionPage.placeholderName}
            label={LocalizedString.discussionPage.placeholderName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DISCUSSION_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.discussionPage.placeholderDescription}
            label={LocalizedString.discussionPage.placeholderDescription}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DISCUSSION_ALLOW_DIRECT_USER_COMMENT}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.discussionPage.placeholderAllowDirectComment}
            label={LocalizedString.discussionPage.placeholderAllowDirectComment}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={approval}
            value={initialValues.allowDirectUserComment}
            onOptionSelected={onAllowDirectUserCommentOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DISCUSSION_ALLOW_PUBLIC_READ}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.discussionPage.placeholderAllowPublicRead}
            label={LocalizedString.discussionPage.placeholderAllowPublicRead}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={approval}
            value={initialValues.allowPublicRead}
            onOptionSelected={onAllowPublicReadOptionSelected}
            required
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_DISCUSSION_REFERENCE_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.discussionPage.placeholderReferenceId}
            label={LocalizedString.discussionPage.placeholderReferenceId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DISCUSSION_TAGS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.discussionPage.placeholderTags}
            label={LocalizedString.discussionPage.placeholderTags}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DISCUSSION_DISABLE_COMMENT}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.discussionPage.placeholderDisableComment}
            label={LocalizedString.discussionPage.placeholderDisableComment}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={approval}
            value={initialValues.disableComment}
            onOptionSelected={onDisableCommentOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DISCUSSION_COMMENT_COUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.discussionPage.placeholderCommentCount}
            label={LocalizedString.discussionPage.placeholderCommentCount}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
);

const DiscussionPage = ({
  initialValues,
  addingEditing, downloading, downloadingDeleting,
  handleSubmit, onAllowDirectUserCommentOptionSelected, onAllowPublicReadOptionSelected, onAppear,
  onApplyAdvancedFilterPressed, onCancelPressed, onChangePage, onChangePageSize,
  onDisableCommentOptionSelected, onEditPressed, onRefresh, onResetAdvancedFilterPressed,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
  pageMode,
}) => (
  <FunctionalPage
    data={RXSTATE_DISCUSSIONS}
    uiPage={RXSTATE_DISCUSSION_PAGE}
    tableColumns={[
      {
        title: LocalizedString.discussionPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.discussionPage.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.discussionPage.labelAllowDirectComment, field: 'allowDirectUserComment', sorting: !downloading },
      { title: LocalizedString.discussionPage.labelAllowPublicRead, field: 'allowPublicRead', sorting: !downloading },
      { title: LocalizedString.discussionPage.labelDisableComment, field: 'disableComment', sorting: !downloading },
      { title: LocalizedString.discussionPage.labelCommentCount, field: 'commentCount', sorting: !downloading },
      { title: LocalizedString.discussionPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    filterColumns={[
      {
        title: LocalizedString.discussionPage.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.discussionPage.labelReferenceId,
        field: RXFIELD_DISCUSSION_REFERENCE_ID,
        type: FILTER_TYPE_TEXT,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.discussionPage.labelAllowDirectComment,
        field: RXFIELD_DISCUSSION_ALLOW_DIRECT_USER_COMMENT,
        type: FILTER_TYPE_SWITCH,
      },
      {
        title: LocalizedString.discussionPage.labelAllowPublicRead,
        field: RXFIELD_DISCUSSION_ALLOW_PUBLIC_READ,
        type: FILTER_TYPE_SWITCH,
      },
      {
        title: LocalizedString.discussionPage.labelDisableComment,
        field: RXFIELD_DISCUSSION_DISABLE_COMMENT,
        type: FILTER_TYPE_SWITCH,
      },
      {
        title: LocalizedString.discussionPage.labelStatus,
        field: 'status',
        type: FILTER_TYPE_DROPDOWN,
        data: status,
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    editButtonCaption={LocalizedString.discussionPage.buttonCaptionEditDiscussion}
    title={LocalizedString.discussionPage.title}
    disableCreate
    disableDelete
    editPermissionName="DISCUS_EDIT_DISCUS"
    useFullWidth
  >
    {renderDialogContent(initialValues, addingEditing, downloadingDeleting,
      onAllowDirectUserCommentOptionSelected, onAllowPublicReadOptionSelected,
      onDisableCommentOptionSelected, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_DISCUSSION,
  validate: rxformValidateDiscussion,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(DiscussionPage);

DiscussionPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAllowDirectUserCommentOptionSelected: PropTypes.func.isRequired,
  onAllowPublicReadOptionSelected: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onDisableCommentOptionSelected: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
