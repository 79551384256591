import {
  REST_URL_DISCUSSION_COMMENTS, REST_URL_DISCUSSIONS, REST_URL_EDIT_DISCUSSION,
  REST_URL_REPORTED_COMMENTS, REST_URL_VIEW_DISCUSSION, REST_URL_VIEW_DISCUSSION_COMMENT,
  REST_URL_VIEW_REPORTED_COMMENT,
} from './constant';
import {
  buildQueryParams, sendGetRequest, sendPutRequest, transformCompleteUrl, transformQueryResult,
} from '../../helper';

export * from '../../helper';

export const downloadDiscussions = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = buildQueryParams(transformCompleteUrl(REST_URL_DISCUSSIONS, searchText,
    filterString), pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const editDiscussion = async (id, allowDirectUserComment, allowPublicRead, disableComment,
  referenceId, name, description, tags, token) => {
  const body = {
    id,
    allowDirectUserComment,
    allowPublicRead,
    disableComment,
    referenceId,
    name,
    description,
    tags,
  };
  await sendPutRequest(REST_URL_EDIT_DISCUSSION, body, token);
};

export const downloadDiscussion = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DISCUSSION.replace(/\{id\}/, id), token);
  return response;
};

export const downloadDiscussionComments = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_DISCUSSION_COMMENTS.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_DISCUSSION_COMMENTS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadDiscussionComment = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DISCUSSION_COMMENT.replace(/\{id\}/, id), token);
  return response;
};

export const downloadReportedComments = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_REPORTED_COMMENTS.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_REPORTED_COMMENTS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadReportedComment = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_REPORTED_COMMENT.replace(/\{id\}/, id), token);
  return response;
};
