import { reset } from 'redux-form';
import { PAGE_MODE_TABLE, RXFORM_EVENT_CATEGORY } from '../../../constant';
import { addEventCategory, editEventCategory, transformLocalImagePath } from '../../../helper';
import {
  addingEditingEventCategory, clearEventCategories,
  setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import downloadEventCategoriesAsync from './downloadEventCategoriesAsync';


export default (name, description, image) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingEventCategory(true));

    const { token } = getState().authentication;
    const { clearImage, tappedId } = getState().uiEventCategory;

    const isClearImage = image ? clearImage.value : true;

    if (tappedId) {
      await editEventCategory(tappedId, name, description,
        transformLocalImagePath(image), isClearImage, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addEventCategory(name, description, transformLocalImagePath(image), token);
    }

    dispatch(reset(RXFORM_EVENT_CATEGORY));
    dispatch(clearEventCategories());
    dispatch(downloadEventCategoriesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingEventCategory(false));
  }
};
