import { downloadSessionOperatorSessions, transformSearchText } from '../../../helper';
import { downloadingSessionOperatorSessions, setSessionOperatorSessions } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, sessions, uiSessionOperatorSession } = getState();
  const { token } = authentication;
  const { meta } = sessions;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiSessionOperatorSession;

  try {
    dispatch(downloadingSessionOperatorSessions(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadSessionOperatorSessions(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setSessionOperatorSessions(list));
    }
  } finally {
    dispatch(downloadingSessionOperatorSessions(false));
  }
};
