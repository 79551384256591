import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, makeStyles, Grid, Paper,
} from '@material-ui/core';
import {
  AccountMultiple, ClockIn, ClockOut,
} from 'mdi-material-ui';
import {
  BasePage, TotalCountSection, PieChart, AttendanceTrendChart, MapMarkerClusterer,
} from '../../component';
import LocalizedString from '../../localization';
import {
  CLOCK_IN_KEY, CLOCK_IN_OUT_LOCATION_TREND_BARS, CLOCK_IN_OUT_STATUS_TREND_BARS,
  CLOCK_OUT_KEY, COLOR_PRIMARY,
} from '../../constant';
import {
  SummaryCountShape, ClockInOutStatusTrendShape, ClockInOutLocationTrendShape,
  ClockInOutAreaShape,
  ProfileShape,
} from '../../type';

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 23,
  },
  title: {
    color: COLOR_PRIMARY,
  },
  chartContainer: {
    padding: 24,
    margin: '30px 0px 30px 0px',
    justifyContent: 'space-between',
  },
}));

const renderMapMarkerClustererField = (data, onClockInOutAreaPressed) => data.map((item) => (
  <Grid container alignItems="stretch" key={item.id}>
    {item.title ? (
      <Grid item xs={12} md key={item.id}>
        <Typography variant="h6" style={{ alignSelf: 'center' }}>
          {item.title}
        </Typography>
        <MapMarkerClusterer
          key={item.title}
          markers={item.data}
          loading={item.downloading}
          onMarkerPressed={(marker) => onClockInOutAreaPressed(marker, item.id)}
          infoWindowLoading={item.loadingInfoWindow}
          infoWindowData={item.infoWindowData}
        />
      </Grid>
    ) : (
      <Grid item xs={12} md key={item.id} />
    )}
  </Grid>
));

const renderPieChartField = (data) => data.map((row) => (
  <Grid container alignItems="stretch" key={row.id}>
    {row.children.map((item) => (
      item.title ? (
        <Grid item xs={12} md key={item.id}>
          <PieChart
            data={item.data}
            loading={item.downloading}
            argumentField="name"
            title={item.title}
            valueField="count"
          />
        </Grid>
      ) : (
        <Grid item xs={12} md key={item.id} />
      )
    ))}
  </Grid>
));

const renderTrendChart = (
  data, downloading, onTooltipTargetItemChange, targetItem, title,
  chartBars,
) => (
  <AttendanceTrendChart
    key={title}
    data={data}
    downloading={downloading}
    title={title}
    targetItem={targetItem}
    onTooltipTargetItemChange={onTooltipTargetItemChange}
    chartBars={chartBars}
  />
);

const AttendanceSummaryPage = ({
  downloadingTotalUsers, totalUsers, onAppear,
  downloadingTotalClockInToday, totalClockInToday,
  downloadingTotalClockOutToday, totalClockOutToday,
  downloadingTodayClockInStatus, todayClockInStatus,
  downloadingTodayClockOutStatus, todayClockOutStatus,
  downloadingTodayClockInLocationStatus, todayClockInLocationStatus,
  downloadingTodayClockOutLocationStatus, todayClockOutLocationStatus,
  downloadingClockInStatusTrend, clockInStatusTrend,
  downloadingClockOutStatusTrend, clockOutStatusTrend,
  downloadingClockInLocationTrend, clockInLocationTrend,
  downloadingClockOutLocationTrend, clockOutLocationTrend,
  onClockInStatusTrendTargetItemChange, clockInStatusTrendTargetItem,
  onClockOutStatusTrendTargetItemChange, clockOutStatusTrendTargetItem,
  onClockInLocationTrendTargetItemChange, clockInLocationTrendTargetItem,
  onClockOutLocationTrendTargetItemChange, clockOutLocationTrendTargetItem,
  downloadingClockInArea, downloadingClockOutArea, clockInArea, clockOutArea,
  onClockInOutAreaPressed, downloadingClockInAreaProfile, selectedClockInAreaProfile,
  selectedClockOutAreaProfile, downloadingClockOutAreaProfile,
}) => {
  const classes = useStyles();
  useEffect(onAppear, []);

  const dataChart = [
    {
      id: '1a',
      children: [
        {
          id: '1aa',
          downloading: downloadingTodayClockInStatus,
          data: todayClockInStatus,
          title: LocalizedString.attendanceSummaryScreen.labelTodayClockInStatus,
        },
        {
          id: '1ab',
          downloading: downloadingTodayClockInLocationStatus,
          data: todayClockInLocationStatus,
          title: LocalizedString.attendanceSummaryScreen.labelTodayClockInLocationStatus,
        },
      ],
    },
    {
      id: '2a',
      children: [
        {
          id: '2aa',
          downloading: downloadingTodayClockOutStatus,
          data: todayClockOutStatus,
          title: LocalizedString.attendanceSummaryScreen.labelTodayClockOutStatus,
        },
        {
          id: '2ab',
          downloading: downloadingTodayClockOutLocationStatus,
          data: todayClockOutLocationStatus,
          title: LocalizedString.attendanceSummaryScreen.labelTodayClockOutLocationStatus,
        },
      ],
    },
  ];

  const dataMap = [
    {
      id: CLOCK_IN_KEY,
      downloading: downloadingClockInArea,
      loadingInfoWindow: downloadingClockInAreaProfile,
      data: clockInArea,
      title: LocalizedString.attendanceSummaryScreen.labelClockInArea,
      infoWindowData: [
        {
          title: selectedClockInAreaProfile?.profile?.fullName,
          body: [
            {
              label: LocalizedString.historyScreen.placeholderAttendanceDate,
              value: selectedClockInAreaProfile?.attendanceDate,
            },
            {
              label: LocalizedString.historyScreen.placeholderClockInTime,
              value: selectedClockInAreaProfile?.clockInTime,
            },
            {
              label: LocalizedString.historyScreen.placeholderClockInStatus,
              value: selectedClockInAreaProfile?.clockInStatus,
            },
            {
              label: LocalizedString.historyScreen.placeholderLocation,
              value: selectedClockInAreaProfile?.clockInLocationName,
            },
            {
              label: LocalizedString.historyScreen.placeholderBranch,
              value: selectedClockInAreaProfile?.branch?.name,
            },
            {
              label: LocalizedString.historyScreen.placeholderClockInLocationStatus,
              value: selectedClockInAreaProfile?.clockInLocationStatus,
            },
            {
              label: LocalizedString.historyScreen.placeholderWorkingHour,
              value: selectedClockInAreaProfile?.workingHour,
            },
            {
              label: LocalizedString.historyScreen.placeholderNotes,
              value: selectedClockInAreaProfile?.clockInNotes,
            },
            {
              label: LocalizedString.historyScreen.placeholderLatitutde,
              value: selectedClockInAreaProfile?.clockInLatitude,
            },
            {
              label: LocalizedString.historyScreen.placeholderLongitude,
              value: selectedClockInAreaProfile?.clockInLongitude,
            },
          ],
        },
        {
          title: LocalizedString.historyScreen.labelAdditionalFields,
          body: selectedClockInAreaProfile?.additionalFields,
        },
      ],
    },
    {
      id: CLOCK_OUT_KEY,
      downloading: downloadingClockOutArea,
      loadingInfoWindow: downloadingClockOutAreaProfile,
      data: clockOutArea,
      title: LocalizedString.attendanceSummaryScreen.labelClockOutArea,
      infoWindowData: [
        {
          title: selectedClockOutAreaProfile?.profile?.fullName,
          body: [
            {
              label: LocalizedString.historyScreen.placeholderClockOutTime,
              value: selectedClockOutAreaProfile?.clockOutTime,
            },
            {
              label: LocalizedString.historyScreen.placeholderClockOutStatus,
              value: selectedClockOutAreaProfile?.clockOutStatus,
            },
            {
              label: LocalizedString.historyScreen.placeholderLocation,
              value: selectedClockOutAreaProfile?.clockOutLocationName,
            },
            {
              label: LocalizedString.historyScreen.placeholderBranch,
              value: selectedClockOutAreaProfile?.branch?.name,
            },
            {
              label: LocalizedString.historyScreen.placeholderClockOutLocationStatus,
              value: selectedClockOutAreaProfile?.clockOutLocationStatus,
            },
            {
              label: LocalizedString.historyScreen.placeholderNotes,
              value: selectedClockOutAreaProfile?.clockOutNotes,
            },
            {
              label: LocalizedString.historyScreen.placeholderLatitutde,
              value: selectedClockOutAreaProfile?.clockOutLatitude,
            },
            {
              label: LocalizedString.historyScreen.placeholderLongitude,
              value: selectedClockOutAreaProfile?.clockOutLongitude,
            },
          ],
        },
        {
          title: LocalizedString.attendanceSummaryScreen.labelClockInInformation,
          body: [
            {
              label: LocalizedString.historyScreen.placeholderAttendanceDate,
              value: selectedClockOutAreaProfile?.attendanceDate,
            },
            {
              label: LocalizedString.historyScreen.placeholderClockInTime,
              value: selectedClockOutAreaProfile?.clockInTime,
            },
            {
              label: LocalizedString.historyScreen.placeholderClockInStatus,
              value: selectedClockOutAreaProfile?.clockInStatus,
            },
            {
              label: LocalizedString.historyScreen.placeholderLocation,
              value: selectedClockOutAreaProfile?.clockInLocationName,
            },
            {
              label: LocalizedString.historyScreen.placeholderClockInLocationStatus,
              value: selectedClockOutAreaProfile?.clockInLocationStatus,
            },
            {
              label: LocalizedString.historyScreen.placeholderWorkingHour,
              value: selectedClockOutAreaProfile?.workingHour,
            },
          ],
        },
        {
          title: LocalizedString.historyScreen.labelAdditionalFields,
          body: selectedClockOutAreaProfile?.additionalFields,
        },
      ],
      infoWindowValue: selectedClockOutAreaProfile,
    },
  ];

  return (
    <BasePage>
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.title}>
          {LocalizedString.attendanceSummaryScreen.title}
        </Typography>
      </div>

      <div className={classes.bodyContainer}>
        <TotalCountSection
          data={[
            {
              downloading: downloadingTotalUsers,
              count: totalUsers,
              icon: <AccountMultiple />,
              label: LocalizedString.attendanceSummaryScreen.labelTotalUser,
            },
            {
              downloading: downloadingTotalClockInToday,
              count: totalClockInToday,
              icon: <ClockIn />,
              label: LocalizedString.attendanceSummaryScreen.labelTotalClockInToday,
            },
            {
              downloading: downloadingTotalClockOutToday,
              count: totalClockOutToday,
              icon: <ClockOut />,
              label: LocalizedString.attendanceSummaryScreen.labelTotalClockOutToday,
            },
          ]}
        />

        <Paper elevation={3} className={classes.chartContainer}>
          {renderMapMarkerClustererField(dataMap, onClockInOutAreaPressed)}
          {renderPieChartField(dataChart)}
          {renderTrendChart(
            clockInStatusTrend, downloadingClockInStatusTrend,
            onClockInStatusTrendTargetItemChange, clockInStatusTrendTargetItem,
            LocalizedString.attendanceSummaryScreen.labelClockInStatusTrend,
            CLOCK_IN_OUT_STATUS_TREND_BARS,
          )}
          {renderTrendChart(
            clockOutStatusTrend, downloadingClockOutStatusTrend,
            onClockOutStatusTrendTargetItemChange, clockOutStatusTrendTargetItem,
            LocalizedString.attendanceSummaryScreen.labelClockOutStatusTrend,
            CLOCK_IN_OUT_STATUS_TREND_BARS,
          )}
          {renderTrendChart(
            clockInLocationTrend, downloadingClockInLocationTrend,
            onClockInLocationTrendTargetItemChange, clockInLocationTrendTargetItem,
            LocalizedString.attendanceSummaryScreen.labelClockInLocationTrend,
            CLOCK_IN_OUT_LOCATION_TREND_BARS,
          )}
          {renderTrendChart(
            clockOutLocationTrend, downloadingClockOutLocationTrend,
            onClockOutLocationTrendTargetItemChange, clockOutLocationTrendTargetItem,
            LocalizedString.attendanceSummaryScreen.labelClockOutLocationTrend,
            CLOCK_IN_OUT_LOCATION_TREND_BARS,
          )}
        </Paper>
      </div>
    </BasePage>
  );
};

export default AttendanceSummaryPage;

AttendanceSummaryPage.propTypes = {
  selectedClockInAreaProfile: ProfileShape,
  selectedClockOutAreaProfile: ProfileShape,
  downloadingTotalUsers: PropTypes.bool,
  downloadingTotalClockInToday: PropTypes.bool,
  downloadingTotalClockOutToday: PropTypes.bool,
  downloadingTodayClockInStatus: PropTypes.bool,
  downloadingTodayClockOutStatus: PropTypes.bool,
  downloadingTodayClockInLocationStatus: PropTypes.bool,
  downloadingTodayClockOutLocationStatus: PropTypes.bool,
  downloadingClockInStatusTrend: PropTypes.bool,
  downloadingClockOutStatusTrend: PropTypes.bool,
  downloadingClockInLocationTrend: PropTypes.bool,
  downloadingClockOutLocationTrend: PropTypes.bool,
  downloadingClockInArea: PropTypes.bool.isRequired,
  downloadingClockOutArea: PropTypes.bool.isRequired,
  downloadingClockInAreaProfile: PropTypes.bool.isRequired,
  downloadingClockOutAreaProfile: PropTypes.bool.isRequired,

  clockInStatusTrendTargetItem: PropTypes.object,
  clockOutStatusTrendTargetItem: PropTypes.object,
  clockInLocationTrendTargetItem: PropTypes.object,
  clockOutLocationTrendTargetItem: PropTypes.object,

  todayClockInLocationStatus: PropTypes.arrayOf(SummaryCountShape).isRequired,
  todayClockOutLocationStatus: PropTypes.arrayOf(SummaryCountShape).isRequired,
  todayClockOutStatus: PropTypes.arrayOf(SummaryCountShape).isRequired,
  todayClockInStatus: PropTypes.arrayOf(SummaryCountShape).isRequired,
  totalClockOutToday: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  totalClockInToday: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  totalUsers: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  clockInStatusTrend: PropTypes.arrayOf(ClockInOutStatusTrendShape).isRequired,
  clockOutStatusTrend: PropTypes.arrayOf(ClockInOutStatusTrendShape).isRequired,
  clockInLocationTrend: PropTypes.arrayOf(ClockInOutLocationTrendShape).isRequired,
  clockOutLocationTrend: PropTypes.arrayOf(ClockInOutLocationTrendShape).isRequired,
  clockInArea: PropTypes.arrayOf(ClockInOutAreaShape).isRequired,
  clockOutArea: PropTypes.arrayOf(ClockInOutAreaShape).isRequired,

  onAppear: PropTypes.func.isRequired,
  onClockInStatusTrendTargetItemChange: PropTypes.func.isRequired,
  onClockOutStatusTrendTargetItemChange: PropTypes.func.isRequired,
  onClockInLocationTrendTargetItemChange: PropTypes.func.isRequired,
  onClockOutLocationTrendTargetItemChange: PropTypes.func.isRequired,
  onClockInOutAreaPressed: PropTypes.func.isRequired,
};

AttendanceSummaryPage.defaultProps = {
  selectedClockInAreaProfile: null,
  selectedClockOutAreaProfile: null,
  downloadingTotalUsers: false,
  downloadingTotalClockInToday: false,
  downloadingTotalClockOutToday: false,
  downloadingTodayClockInStatus: false,
  downloadingTodayClockOutStatus: false,
  downloadingTodayClockInLocationStatus: false,
  downloadingTodayClockOutLocationStatus: false,
  downloadingClockInStatusTrend: false,
  downloadingClockOutStatusTrend: false,
  downloadingClockInLocationTrend: false,
  downloadingClockOutLocationTrend: false,
  clockInStatusTrendTargetItem: null,
  clockOutStatusTrendTargetItem: null,
  clockInLocationTrendTargetItem: null,
  clockOutLocationTrendTargetItem: null,
};
