import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  clearCaptchaRequests, setActiveSideMenuItem, setAlertErrorMessage,
  setCaptchaRequestSearchText, setCaptchaRequestSelectedPageSize, setCaptchaRequestSelectedOrderBy,
  setCaptchaRequestTappedId,
  downloadCaptchaRequestAsync, downloadCaptchaRequestsAsync,
  setCaptchaRequestAdvancedFilterDialogSelectedFilterString,
  clearProfiles,
  setProfileAdvancedFilterDialogSelectedFilterString,
  setProfileSearchText,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_CAPTCHA_REQUESTS, MENUID_CAPTCHA_REQUEST, PAGE_MODE_TABLE,
  RXFORM_CAPTCHA_REQUEST, REST_BASE_URL, REST_URL_IMAGE_STORAGE,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
} from '../../constant';
import {
  transformInitialValues, toMoment, transformUserDropdownData,
  debounceSearch,
} from '../../helper';
import CaptchaRequestPage from './captcha-request.presentation';
import { downloadProfilesAsync } from '../../../../redux/action';

const getInitialValues = (state) => {
  const { captchaRequests, uiFunctionalPage, uiCaptchaRequest } = state;
  const { data } = captchaRequests;
  const { downloadingDeleting, tappedId } = uiCaptchaRequest;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      verifiedOn: found.verifiedOn ? toMoment(found.verifiedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
      validUntil: found.validUntil ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
      captchaImage: found.captchaImage ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.captchaImage}` : '',
    })
    : { validUntil: '', captchaImage: '' };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  downloading: state.uiCaptchaRequest.downloading,
  downloadingDeleting: state.uiCaptchaRequest.downloadingDeleting,
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.profiles.data),
  loadingUser: state.uiProfile.downloading,
});

const searchUserDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CAPTCHA_REQUEST));
    dispatch(setCaptchaRequestSearchText(''));
    dispatch(clearCaptchaRequests());
    dispatch(setCaptchaRequestSelectedPageSize(20));
    dispatch(setCaptchaRequestSelectedOrderBy(INITIAL_ORDER_BY_CAPTCHA_REQUESTS));
    dispatch(downloadCaptchaRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/fullName/, 'verifiedFor.id');
    dispatch(setCaptchaRequestAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearCaptchaRequests());
    dispatch(downloadCaptchaRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCaptchaRequestsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCaptchaRequestSelectedPageSize(pageSize));
    dispatch(downloadCaptchaRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_CAPTCHA_REQUEST));
  },
  onRefresh: (pageSize) => {
    dispatch(setCaptchaRequestSelectedPageSize(pageSize));
    dispatch(clearCaptchaRequests());
    dispatch(downloadCaptchaRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setCaptchaRequestAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearCaptchaRequests());
    dispatch(setCaptchaRequestSelectedOrderBy(INITIAL_ORDER_BY_CAPTCHA_REQUESTS));
    dispatch(downloadCaptchaRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCaptchaRequestSearchText(text));
      dispatch(clearCaptchaRequests());
      await dispatch(downloadCaptchaRequestsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setCaptchaRequestSelectedOrderBy(orderBy));
    dispatch(clearCaptchaRequests());
    dispatch(downloadCaptchaRequestsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setCaptchaRequestTappedId(id));
    dispatch(downloadCaptchaRequestAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CaptchaRequestPage);
