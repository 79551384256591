import { reset } from 'redux-form';
import { PAGE_MODE_TABLE, RXFORM_ROOM } from '../../../constant';
import { addRoom, editRoom } from '../../../helper';
import {
  addingEditingRoom, clearRooms, setAlertErrorMessage, setFunctionalPageMode,
  setRoomSelectedLocationId,
} from '../simple-action';
import downloadRoomsAsync from './downloadRoomsAsync';

export default (name, description) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingRoom(true));

    const { token } = getState().authentication;
    const { tappedId, selectedLocationId } = getState().uiRoom;

    if (tappedId) {
      await editRoom(tappedId, selectedLocationId, name, description, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addRoom(selectedLocationId, name, description, token);
    }

    dispatch(reset(RXFORM_ROOM));
    dispatch(setRoomSelectedLocationId(''));
    dispatch(clearRooms());
    dispatch(downloadRoomsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingRoom(false));
  }
};
