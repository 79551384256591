import { downloadingFieldParents, setFieldParents } from '../simple-action';
import { downloadFields, transformSearchText } from '../../../helper';
import { INITIAL_ORDER_BY_FIELDS } from '../../../constant';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, fieldParents, uiField } = getState();
  const { token } = authentication;
  const { meta } = fieldParents;
  const { parentSearchText, parentFilterString } = uiField;

  try {
    dispatch(downloadingFieldParents(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadFields(
      pageToBeDownloaded,
      20,
      INITIAL_ORDER_BY_FIELDS,
      parentSearchText ? transformSearchText(parentSearchText) : '',
      parentFilterString,
      token,
    );
    dispatch(setFieldParents(list));
  } finally {
    dispatch(downloadingFieldParents(false));
  }
};
