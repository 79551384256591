import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';


export const DOWNLOADING_PAYMENTS = 'DOWNLOADING_PAYMENTS';
export const downloadingPayments = makeActionCreator(DOWNLOADING_PAYMENTS, 'status');

export const SET_PAYMENTS = 'SET_PAYMENTS';
export const setPayments = makeActionCreator(SET_PAYMENTS, 'data');

export const ADD_PAYMENT = 'ADD_PAYMENT';
export const addPayment = makeActionCreator(ADD_PAYMENT, 'data');

export const CLEAR_PAYMENTS = 'CLEAR_PAYMENTS';
export const clearPayments = makeActionCreator(
  CLEAR_PAYMENTS,
);

export const DOWNLOADING_DELETING_PAYMENT = 'DOWNLOADING_DELETING_PAYMENT';
export const downloadingDeletingPayment = makeActionCreator(DOWNLOADING_DELETING_PAYMENT, 'status');

export const SET_PAYMENT_SELECTED_PAGE_SIZE = 'SET_PAYMENT_SELECTED_PAGE_SIZE';
export const setPaymentSelectedPageSize = makeActionCreator(SET_PAYMENT_SELECTED_PAGE_SIZE, 'size');

export const SET_PAYMENT_SELECTED_ORDER_BY = 'SET_PAYMENT_SELECTED_ORDER_BY';
export const setPaymentSelectedOrderBy = makeActionCreator(SET_PAYMENT_SELECTED_ORDER_BY, 'order');

export const SET_PAYMENT_TAPPED_ID = 'SET_PAYMENT_TAPPED_ID';
export const setPaymentTappedId = makeActionCreator(SET_PAYMENT_TAPPED_ID, 'id');

export const SET_PAYMENT_SEARCH_TEXT = 'SET_PAYMENT_SEARCH_TEXT';
export const setPaymentSearchText = makeActionCreator(SET_PAYMENT_SEARCH_TEXT, 'text');

export const SET_PAYMENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_PAYMENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setPaymentAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_PAYMENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const REPUBLISHING_PAYMENT = 'REPUBLISHING_PAYMENT';
export const republishingPayment = makeActionCreator(REPUBLISHING_PAYMENT, 'status');
