import { reset } from 'redux-form';
import { PAGE_MODE_TABLE, RXFORM_SPONSOR } from '../../../constant';
import { addSponsor, editSponsor, toNumber } from '../../../helper';
import {
  addingEditingSponsor, clearSponsors,
  setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import downloadSponsorsAsync from './downloadSponsorsAsync';


export default (eventId, sponsorCategoryId, companyId, order) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingSponsor(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSponsor;


    if (tappedId) {
      await editSponsor(tappedId, eventId.value,
        sponsorCategoryId.value, companyId.value, toNumber(order), token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addSponsor(eventId.value, sponsorCategoryId.value, companyId.value,
        toNumber(order), token);
    }

    dispatch(reset(RXFORM_SPONSOR));
    dispatch(clearSponsors());
    dispatch(downloadSponsorsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingSponsor(false));
  }
};
