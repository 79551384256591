import {
  buildQueryParams, convertArrToObj, sendDeleteRequest, sendGetFileRequest, sendGetRequest,
  sendPostRequest, sendPutRequest, transformQueryResult,
} from '../../helper';
import {
  ENUM_FIELD_PHOTO,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE, REST_URL_SAVE_FILE,
  REST_URL_DOWNLOAD_TOTAL_CLOCK_IN_TODAY, REST_URL_DOWNLOAD_TOTAL_CLOCK_OUT_TODAY,
  REST_URL_DOWNLOAD_TODAY_CLOCK_IN_STATUS, REST_URL_DOWNLOAD_TODAY_CLOCK_OUT_STATUS,
  REST_URL_DOWNLOAD_TODAY_CLOCK_IN_LOCATION_STATUS,
  REST_URL_DOWNLOAD_TODAY_CLOCK_OUT_LOCATION_STATUS,
  REST_URL_DOWNLOAD_CLOCK_IN_STATUS_TREND, REST_URL_DOWNLOAD_CLOCK_OUT_STATUS_TREND,
  REST_URL_DOWNLOAD_CLOCK_IN_LOCATION_TREND, REST_URL_DOWNLOAD_CLOCK_OUT_LOCATION_TREND,
  REST_URL_HISTORIES,
  REST_URL_ADDITIONAL_FIELDS, REST_URL_ADD_EDIT_ADDITIONAL_FIELD,
  REST_URL_VIEW_DELETE_ADDITIONAL_FIELD, REST_URL_ENABLE_DISABLE_ADDITIONAL_FIELD,
  REST_URL_DOWNLOAD_ATTENDANCE_SUMMARY_TOTAL_USERS,
  REST_URL_DOWNLOAD_CLOCK_IN_AREA,
  REST_URL_DOWNLOAD_DELETE_HISTORY,
  REST_URL_DOWNLOAD_CLOCK_OUT_AREA,
} from './constant';

export * from '../../helper';

export const downloadAttendanceSummaryTotalClockInToday = async (token) => {
  const url = REST_URL_DOWNLOAD_TOTAL_CLOCK_IN_TODAY.replace(/\{timeZone\}/, encodeURIComponent('+0700'));
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadAttendanceSummaryTotalClockOutToday = async (token) => {
  const url = REST_URL_DOWNLOAD_TOTAL_CLOCK_OUT_TODAY.replace(/\{timeZone\}/, encodeURIComponent('+0700'));
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadAttendanceSummaryTodayClockInStatus = async (token) => {
  const url = REST_URL_DOWNLOAD_TODAY_CLOCK_IN_STATUS.replace(/\{timeZone\}/, encodeURIComponent('+0700'));
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadAttendanceSummaryTodayClockOutStatus = async (token) => {
  const url = REST_URL_DOWNLOAD_TODAY_CLOCK_OUT_STATUS.replace(/\{timeZone\}/, encodeURIComponent('+0700'));
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadAttendanceSummaryTodayClockInLocationStatus = async (token) => {
  const url = REST_URL_DOWNLOAD_TODAY_CLOCK_IN_LOCATION_STATUS.replace(/\{timeZone\}/, encodeURIComponent('+0700'));
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadAttendanceSummaryTodayClockOutLocationStatus = async (token) => {
  const url = REST_URL_DOWNLOAD_TODAY_CLOCK_OUT_LOCATION_STATUS.replace(/\{timeZone\}/, encodeURIComponent('+0700'));
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadAttendanceSummaryClockInStatusTrend = async (token) => {
  const response = await sendGetRequest(REST_URL_DOWNLOAD_CLOCK_IN_STATUS_TREND, token);
  return response;
};

export const downloadAttendanceSummaryClockOutStatusTrend = async (token) => {
  const response = await sendGetRequest(REST_URL_DOWNLOAD_CLOCK_OUT_STATUS_TREND, token);
  return response;
};

export const downloadAttendanceSummaryClockInLocationTrend = async (token) => {
  const response = await sendGetRequest(REST_URL_DOWNLOAD_CLOCK_IN_LOCATION_TREND, token);
  return response;
};

export const downloadAttendanceSummaryClockOutLocationTrend = async (token) => {
  const response = await sendGetRequest(REST_URL_DOWNLOAD_CLOCK_OUT_LOCATION_TREND, token);
  return response;
};

export const downloadHistories = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_HISTORIES.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_HISTORIES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const transformResponse = response.data.map((x) => ({
    ...x,
    additionalFields: x.additionalFields && x.additionalFields.length > 0
      ? [
        ...x.additionalFields.filter((y) => y.field.fieldType !== ENUM_FIELD_PHOTO),
        ...x.additionalFields.filter((y) => y.field.fieldType === ENUM_FIELD_PHOTO)
          .map((z) => ({ ...z, value: z.value ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${z.value}` : '' }))]
      : [],
  }));
  return transformQueryResult(response, transformResponse);
};

export const downloadHistory = async (id, token) => {
  const response = await sendGetRequest(REST_URL_DOWNLOAD_DELETE_HISTORY.replace(/\{id\}/, id), token);
  const transformResponse = {
    ...response,
    additionalFields: response.additionalFields && response.additionalFields.length > 0
      ? [
        ...response.additionalFields.filter((y) => y.field.fieldType !== ENUM_FIELD_PHOTO),
        ...response.additionalFields.filter((y) => y.field.fieldType === ENUM_FIELD_PHOTO)
          .map((z) => ({ ...z, value: z.value ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${z.value}` : '' }))]
      : [],
  };
  return transformResponse;
};

export const saveHistories = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_HISTORIES.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token, true);
  return response;
};

export const downloadAdditionalFields = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_ADDITIONAL_FIELDS.concat(filterString), pageNumber, pageSize,
      orderBy, searchText)
    : buildQueryParams(REST_URL_ADDITIONAL_FIELDS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addAdditionalField = async (body, token) => {
  await sendPostRequest(REST_URL_ADD_EDIT_ADDITIONAL_FIELD, body, token);
};

export const editAdditionalField = async (body, token) => {
  await sendPutRequest(REST_URL_ADD_EDIT_ADDITIONAL_FIELD, body, token);
};

export const downloadAdditionalField = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_ADDITIONAL_FIELD.replace(/\{id\}/, id), token);
  return response;
};

export const deleteAdditionalField = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_ADDITIONAL_FIELD.replace(/\{id\}/, id), token);
};

export const enableDisableAdditionalField = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_ADDITIONAL_FIELD, body, token);
};

export const downloadAttendanceSummaryTotalUsers = async (token) => {
  const response = await sendGetRequest(REST_URL_DOWNLOAD_ATTENDANCE_SUMMARY_TOTAL_USERS, token);
  return response;
};

export const downloadAttendanceClockInArea = async (token) => {
  const url = REST_URL_DOWNLOAD_CLOCK_IN_AREA.replace(/\{timeZone\}/, encodeURIComponent('+0700'));
  const response = await sendGetRequest(url, token);
  return convertArrToObj(response, 'id');
};

export const downloadAttendanceClockOutArea = async (token) => {
  const url = REST_URL_DOWNLOAD_CLOCK_OUT_AREA.replace(/\{timeZone\}/, encodeURIComponent('+0700'));
  const response = await sendGetRequest(url, token);
  return convertArrToObj(response, 'id');
};
