
import { INITIAL_ORDER_BY_SESSION_CATEGORIES, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableSessionCategory } from '../../../helper';
import {
  enablingDisablingSessionCategory, setAlertErrorMessage,
  setSessionCategorySelectedOrderBy, setSessionCategorySelectedPageSize,
} from '../simple-action';
import downloadSessionCategoriesAsync from './downloadSessionCategoriesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingSessionCategory(true));

    const { token } = getState().authentication;
    const { data } = getState().sessionCategories;
    const { tappedId } = getState().uiSessionCategory;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableSessionCategory(tappedId, status, token);

    dispatch(setSessionCategorySelectedPageSize(20));
    dispatch(setSessionCategorySelectedOrderBy(INITIAL_ORDER_BY_SESSION_CATEGORIES));
    dispatch(downloadSessionCategoriesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingSessionCategory(false));
  }
};
