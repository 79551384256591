import {
  enablingDisablingCompanyMData, setAlertErrorMessage, setCompanySelectedOrderByMData,
  setCompanySelectedPageSizeMData,
} from '../simple-action';
import { INITIAL_ORDER_BY_BRANCHES, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableCompanyMData } from '../../../helper';
import downloadMDataCompaniesAsync from './downloadMDataCompaniesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingCompanyMData(true));

    const { token } = getState().authentication;
    const { data } = getState().companiesMdata;
    const { tappedId } = getState().uiCompany;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableCompanyMData(tappedId, status, token);

    dispatch(setCompanySelectedPageSizeMData(20));
    dispatch(setCompanySelectedOrderByMData(INITIAL_ORDER_BY_BRANCHES));
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingCompanyMData(false));
  }
};
