import { downloadingPurchasers, setPurchasers } from '../simple-action';
import { downloadProfiles, transformSearchText } from '../../../helper';
import { INITIAL_ORDER_BY_USERS } from '../../../constant';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, purchasers, uiVoucherToken } = getState();
  const { token } = authentication;
  const { meta } = purchasers;
  const { purchaserSearchBarText } = uiVoucherToken;

  try {
    dispatch(downloadingPurchasers(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadProfiles(
      pageToBeDownloaded,
      20,
      INITIAL_ORDER_BY_USERS,
      transformSearchText(purchaserSearchBarText),
      '',
      token,
    );

    if (list) {
      dispatch(setPurchasers(list));
    }
  } finally {
    dispatch(downloadingPurchasers(false));
  }
};
