import {
  addPopUpBanner, clearPopUpBanners, downloadingDeletingPopUpBanner, setAlertErrorMessage,
  setPopUpBannerTappedId, setFunctionalPageMode,
} from '../simple-action';
import { downloadPopUpBanner, deletePopUpBanner } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadPopUpBannersAsync from './downloadPopUpBannersAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingPopUpBanner(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPopUpBanner;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deletePopUpBanner(tappedId, token);
      dispatch(setPopUpBannerTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearPopUpBanners());
      dispatch(downloadPopUpBannersAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const banner = await downloadPopUpBanner(tappedId, token);
      dispatch(addPopUpBanner(banner));
    }
  } finally {
    dispatch(downloadingDeletingPopUpBanner(false));
  }
};
