import { reset } from 'redux-form';
import { RXFORM_SESSION_OPERATOR_INVITATION } from '../../../constant';
import { addSessionOperatorInvitation } from '../../../helper';
import {
  addingSessionOperatorInvitation, clearSessionOperatorInvitations, setAlertErrorMessage,
  setSessionOperatorInvitationSelectedSessionId, setSessionOperatorInvitationSelectedRecipient,
} from '../simple-action';
import downloadSessionOperatorInvitationsAsync from './downloadSessionOperatorInvitationsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(addingSessionOperatorInvitation(true));

    const { token } = getState().authentication;
    const { selectedSession, selectedRecipients } = getState().uiSessionOperatorInvitation;
    const emails = selectedRecipients.reduce((acc, obj) => {
      acc.push(obj.value);
      return acc;
    }, []);

    await addSessionOperatorInvitation(selectedSession, emails, token);

    dispatch(reset(RXFORM_SESSION_OPERATOR_INVITATION));
    dispatch(clearSessionOperatorInvitations());
    dispatch(downloadSessionOperatorInvitationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setSessionOperatorInvitationSelectedRecipient([]));
    dispatch(setSessionOperatorInvitationSelectedSessionId(''));
  } finally {
    dispatch(addingSessionOperatorInvitation(false));
  }
};
