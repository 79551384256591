import {
  DOWNLOADING_WORKING_SHIFTS, ADDING_EDITING_WORKING_SHIFT, DOWNLOADING_DELETING_WORKING_SHIFT,
  SET_WORKING_SHIFT_SELECTED_PAGE_SIZE, SET_WORKING_SHIFT_TAPPED_ID, SET_WORKING_SHIFT_SEARCH_TEXT,
  SET_WORKING_SHIFT_SELECTED_ORDER_BY, SET_WORKING_SHIFT_SELECTED_BRANCH_ID, SAVING_WORKING_SHIFTS,
  SET_WORKING_SHIFT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  ENABLING_DISABLING_WORKING_SHIFT, RESYNCING_WORKING_SHIFT, SET_WORKING_SHIFT_DUPLICATE_MODE,
} from '../action';
import { INITIAL_ORDER_BY_WORKING_SHIFTS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  saving: false,
  enablingDisabling: false,
  selectedPageSize: 20,
  tappedId: '',
  selectedBranchId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_WORKING_SHIFTS,
  filterString: '',
  resyncing: false,
  duplicateMode: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_WORKING_SHIFTS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_WORKING_SHIFT:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_WORKING_SHIFT:
      return { ...state, downloadingDeleting: action.status };
    case SET_WORKING_SHIFT_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_WORKING_SHIFT_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_WORKING_SHIFT_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_WORKING_SHIFT_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_WORKING_SHIFT_SELECTED_BRANCH_ID:
      return { ...state, selectedBranchId: action.id };
    case SAVING_WORKING_SHIFTS:
      return { ...state, saving: action.status };
    case SET_WORKING_SHIFT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case ENABLING_DISABLING_WORKING_SHIFT:
      return { ...state, enablingDisabling: action.status };
    case RESYNCING_WORKING_SHIFT:
      return { ...state, resyncing: action.status };
    case SET_WORKING_SHIFT_DUPLICATE_MODE:
      return { ...state, duplicateMode: action.status };
    default: return state;
  }
};
