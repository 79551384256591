import { toNumber } from 'lodash';
import {
  REST_URL_LOCATIONS,
  REST_URL_VIEW_DELETE_LOCATION,
  REST_URL_ADD_EDIT_LOCATION,
  REST_URL_ENABLE_DISABLE_LOCATION,
  REST_URL_SAVE_FILE,
  REST_URL_EVENT_CATEGORIES,
  REST_URL_VIEW_DELETE_EVENT_CATEGORY,
  REST_URL_ADD_EDIT_EVENT_CATEGORY,
  REST_URL_ENABLE_DISABLE_EVENT_CATEGORY,
  REST_URL_SESSION_CATEGORIES,
  REST_URL_VIEW_DELETE_SESSION_CATEGORY,
  REST_URL_ADD_EDIT_SESSION_CATEGORY,
  REST_URL_ENABLE_DISABLE_SESSION_CATEGORY,
  REST_URL_SPEAKERS,
  REST_URL_VIEW_DELETE_SPEAKER,
  REST_URL_ADD_EDIT_SPEAKER,
  REST_URL_ENABLE_DISABLE_SPEAKER,
  REST_URL_SPONSOR_CATEGORIES,
  REST_URL_VIEW_DELETE_SPONSOR_CATEGORY,
  REST_URL_ADD_EDIT_SPONSOR_CATEGORY,
  REST_URL_ENABLE_DISABLE_SPONSOR_CATEGORY,
  REST_URL_ROOMS,
  REST_URL_VIEW_DELETE_ROOM,
  REST_URL_ADD_EDIT_ROOM,
  REST_URL_ENABLE_DISABLE_ROOM,
  REST_URL_SESSIONS, REST_URL_VIEW_DELETE_SESSION, REST_URL_ADD_EDIT_SESSION,
  REST_URL_EVENTS,
  REST_URL_VIEW_DELETE_EVENT,
  REST_URL_ADD_EDIT_EVENT,
  REST_URL_EVENT_INVITATIONS,
  REST_URL_VIEW_DELETE_EVENT_INVITATION,
  REST_URL_ADD_EDIT_EVENT_INVITATION,
  REST_URL_RESEND_EVENT_INVITATION,
  REST_URL_SPONSORS,
  REST_URL_VIEW_DELETE_SPONSOR,
  REST_URL_ADD_EDIT_SPONSOR,
  REST_URL_SESSION_INVITATIONS,
  REST_URL_VIEW_DELETE_SESSION_INVITATION,
  REST_URL_ADD_EDIT_SESSION_INVITATION,
  REST_URL_RESEND_SESSION_INVITATION,
  REST_URL_EVENT_REGISTRATIONS,
  REST_URL_VIEW_EVENT_REGISTRATION,
  REST_URL_APPROVE_REJECT_EVENT_REGISTRATION,
  REST_URL_SESSION_REGISTRATIONS,
  REST_URL_VIEW_SESSION_REGISTRATION,
  REST_URL_APPROVE_REJECT_SESSION_REGISTRATION,
  REST_URL_SEND_EVENT_TO_ME,
  REST_URL_SEND_SESSION_TO_ME,
  FILE_EXTENSION_PDF,
  ADDITIONAL_ITEM_TYPE_PDF,
  ADDITIONAL_ITEM_TYPE_SPREADSHEET,
  ADDITIONAL_ITEM_TYPE_SLIDESHOW,
  ADDITIONAL_ITEM_TYPE_DOCUMENT,
  ADDITIONAL_ITEM_TYPE_OTHER,
  FILE_EXTENSION_ALL,
  FILE_EXTENSION_DOCUMENT,
  FILE_EXTENSION_SLIDESHOW,
  FILE_EXTENSION_SPREADSHEET,
  ADDITIONAL_ITEM_TYPE_IMAGE,
  FILE_EXTENSION_IMAGE,
  FILE_EXTENSION_ZIP,
  ADDITIONAL_ITEM_TYPE_ZIP,
  REST_URL_ENABLE_DISABLE_EVENT,
  REST_URL_ENABLE_DISABLE_SESSION,
  REST_URL_SESSION_ROOMS,
  REST_BASE_WEBAPP_LANDING,
} from './constant';
import {
  buildQueryParams, sendDeleteRequest, sendGetFileRequest, sendGetRequest,
  sendPostRequest, sendPutRequest, sortAsc, transformCompleteUrl, transformQueryResult,
  isAdditionalItemYoutubeUrlType, transformEventSessionData,
} from '../../helper';

export * from '../../helper';

export const downloadLocations = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_LOCATIONS, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);
  const sortedArea = {
    ...response,
    data: response.data.map((location) => ({
      ...location,
      area: location.area.sort((a, b) => sortAsc(a.order, b.order)),
    })),
  };
  return transformQueryResult(sortedArea);
};

export const downloadLocation = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_LOCATION.replace(/\{id\}/, id), token);
  return {
    ...response,
    area: response.area.sort((a, b) => sortAsc(a.order, b.order)),
  };
};

export const deleteLocation = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_LOCATION.replace(/\{id\}/, id), token);
};

export const addLocation = async (name, description, latitude, longitude, googleMapUrl, address,
  postalCode, phone, email, cityId, area, token) => {
  const body = {
    name,
    description,
    latitude,
    longitude,
    googleMapUrl,
    address,
    postalCode,
    phone,
    email,
    cityId,
    area,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_LOCATION, body, token);
};

export const editLocation = async (id, name, description, latitude, longitude, googleMapUrl,
  address, postalCode, phone, email, cityId, area, token) => {
  const body = {
    id,
    name,
    description,
    latitude,
    longitude,
    googleMapUrl,
    address,
    postalCode,
    phone,
    email,
    cityId,
    area,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_LOCATION, body, token);
};

export const enableDisableLocation = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_LOCATION, body, token);
};

export const saveLocations = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_LOCATIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadEventCategories = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_EVENT_CATEGORIES, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadEventCategory = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_EVENT_CATEGORY.replace(/\{id\}/, id), token);
  return response;
};

export const deleteEventCategory = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_EVENT_CATEGORY.replace(/\{id\}/, id), token);
};

export const addEventCategory = async (name, description, image, token) => {
  const body = {
    name,
    description,
    image,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_EVENT_CATEGORY, body, token);
};

export const editEventCategory = async (id, name, description, image, clearImage, token) => {
  const body = {
    id,
    name,
    description,
    image,
    clearImage,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_EVENT_CATEGORY, body, token);
};

export const enableDisableEventCategory = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_EVENT_CATEGORY, body, token);
};

export const downloadSessionCategories = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_SESSION_CATEGORIES, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadSessionCategory = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_SESSION_CATEGORY.replace(/\{id\}/, id), token);
  return response;
};

export const deleteSessionCategory = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_SESSION_CATEGORY.replace(/\{id\}/, id), token);
};

export const addSessionCategory = async (name, description, token) => {
  const body = {
    name,
    description,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_SESSION_CATEGORY, body, token);
};

export const editSessionCategory = async (id, name, description, token) => {
  const body = {
    id,
    name,
    description,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_SESSION_CATEGORY, body, token);
};

export const enableDisableSessionCategory = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_SESSION_CATEGORY, body, token);
};

export const downloadSponsorCategories = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_SPONSOR_CATEGORIES, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadSponsorCategory = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_SPONSOR_CATEGORY.replace(/\{id\}/, id), token);
  return response;
};

export const deleteSponsorCategory = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_SPONSOR_CATEGORY.replace(/\{id\}/, id), token);
};

export const addSponsorCategory = async (name, description, order, token) => {
  const body = {
    name,
    description,
    order,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_SPONSOR_CATEGORY, body, token);
};

export const editSponsorCategory = async (id, name, description, order, token) => {
  const body = {
    id,
    name,
    description,
    order,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_SPONSOR_CATEGORY, body, token);
};

export const enableDisableSponsorCategory = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_SPONSOR_CATEGORY, body, token);
};

export const downloadSpeakers = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_SPEAKERS, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadSpeaker = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_SPEAKER.replace(/\{id\}/, id), token);
  return response;
};

export const deleteSpeaker = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_SPEAKER.replace(/\{id\}/, id), token);
};

export const addSpeaker = async (name, description, jobTitle, companyName, image, token) => {
  const body = {
    name,
    description,
    jobTitle,
    companyName,
    image,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_SPEAKER, body, token);
};

export const editSpeaker = async (id, name, description, jobTitle, companyName, image, token) => {
  const body = {
    id,
    name,
    description,
    jobTitle,
    companyName,
    image,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_SPEAKER, body, token);
};

export const enableDisableSpeaker = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_SPEAKER, body, token);
};

export const downloadRooms = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_ROOMS, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadRoom = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_ROOM.replace(/\{id\}/, id), token);
  return response;
};

export const deleteRoom = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_ROOM.replace(/\{id\}/, id), token);
};

export const addRoom = async (locationId, name, description, token) => {
  const body = {
    locationId,
    name,
    description,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_ROOM, body, token);
};

export const editRoom = async (id, locationId, name, description, token) => {
  const body = {
    id,
    locationId,
    name,
    description,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_ROOM, body, token);
};

export const enableDisableRoom = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_ROOM, body, token);
};

export const saveRooms = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_ROOMS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadEvents = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_EVENTS, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadEvent = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_EVENT.replace(/\{id\}/, id), token);
  const transformData = transformEventSessionData(response);
  return transformData;
};

export const deleteEvent = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_EVENT.replace(/\{id\}/, id), token);
};

export const addEvent = async (name,
  shortDescription,
  description,
  startTime,
  endTime,
  earliestClockInBeforeMinutes,
  maxClockInMinutes,
  categoryId,
  locationId,
  clockInMustBeInsideArea,
  eventType,
  visibilityStatus,
  whoCanRegister,
  needApproval,
  quota,
  isFeatured,
  featuredOrder,
  normalPrice,
  price,
  canPayUsingPoint,
  registrationRewardType,
  registrationRewardPoint,
  registrationRewardVoucherIds,
  media,
  enableComment,
  commentAccess,
  additionalItems,
  speakers,
  surveyFormId,
  banner,
  token) => {
  const body = {
    name,
    shortDescription,
    description,
    startTime,
    endTime,
    earliestClockInBeforeMinutes,
    maxClockInMinutes,
    categoryId,
    locationId,
    clockInMustBeInsideArea,
    eventType,
    visibilityStatus,
    whoCanRegister,
    needApproval,
    quota,
    isFeatured,
    featuredOrder,
    normalPrice,
    price,
    canPayUsingPoint,
    registrationRewardType,
    registrationRewardPoint,
    registrationRewardVoucherIds,
    media,
    enableComment,
    commentAccess,
    additionalItems,
    speakers,
    surveyFormId,
    banner,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_EVENT, body, token);
};

export const editEvent = async (id, name,
  shortDescription,
  description,
  startTime,
  endTime,
  earliestClockInBeforeMinutes,
  maxClockInMinutes,
  categoryId,
  locationId,
  clockInMustBeInsideArea,
  eventType,
  visibilityStatus,
  whoCanRegister,
  needApproval,
  quota,
  isFeatured,
  featuredOrder,
  normalPrice,
  price,
  canPayUsingPoint,
  registrationRewardType,
  registrationRewardPoint,
  registrationRewardVoucherIds,
  media,
  enableComment,
  commentAccess,
  additionalItems,
  speakers,
  surveyFormId,
  banner,
  token) => {
  const body = {
    id,
    name,
    shortDescription,
    description,
    startTime,
    endTime,
    earliestClockInBeforeMinutes,
    maxClockInMinutes,
    categoryId,
    locationId,
    clockInMustBeInsideArea,
    eventType,
    visibilityStatus,
    whoCanRegister,
    needApproval,
    quota,
    isFeatured,
    featuredOrder,
    normalPrice,
    price,
    canPayUsingPoint,
    registrationRewardType,
    registrationRewardPoint,
    registrationRewardVoucherIds,
    media,
    enableComment,
    commentAccess,
    additionalItems,
    speakers,
    surveyFormId,
    banner,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_EVENT, body, token);
};

export const enableDisableEvent = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_EVENT, body, token);
};

export const sendEventNotification = async (id, token) => {
  await sendPostRequest(REST_URL_SEND_EVENT_TO_ME.replace(/\{id\}/, id), null, token);
};

export const downloadSessions = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_SESSIONS, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );

  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const deleteSession = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_SESSION.replace(/\{id\}/, id), token);
};

export const downloadSession = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_SESSION.replace(/\{id\}/, id), token);
  const transformData = transformEventSessionData(response);
  return transformData;
};

export const addSession = async (eventId, name, shortDescription, description,
  startTime, endTime, earliestClockInBeforeMinutes, maxClockInMinutes,
  categoryId, roomId, clockInMustBeInsideArea, visibilityStatus,
  whoCanRegister, needApproval, quota, type, isFeatured, featuredOrder, surveyFormId, normalPrice,
  price, canPayUsingPoint, registrationRewardType, registrationRewardPoint,
  registrationRewardVoucherIds, speakers, media, additionalItems, enableComment, commentAccess,
  banner, token) => {
  const body = {
    eventId,
    name,
    shortDescription,
    description,
    startTime,
    endTime,
    earliestClockInBeforeMinutes,
    maxClockInMinutes,
    categoryId,
    roomId,
    clockInMustBeInsideArea,
    visibilityStatus,
    whoCanRegister,
    needApproval,
    quota,
    type,
    isFeatured,
    featuredOrder,
    surveyFormId,
    normalPrice,
    price,
    canPayUsingPoint,
    registrationRewardType,
    registrationRewardPoint,
    registrationRewardVoucherIds,
    speakers,
    media,
    additionalItems,
    enableComment,
    commentAccess,
    banner,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_SESSION, body, token);
};

export const editSession = async (id, eventId, name, shortDescription, description,
  startTime, endTime, earliestClockInBeforeMinutes, maxClockInMinutes,
  categoryId, roomId, clockInMustBeInsideArea, visibilityStatus,
  whoCanRegister, needApproval, quota, type, isFeatured, featuredOrder, surveyFormId, normalPrice,
  price, canPayUsingPoint, registrationRewardType, registrationRewardPoint,
  registrationRewardVoucherIds, speakers, media, additionalItems, enableComment, commentAccess,
  banner, token) => {
  const body = {
    id,
    eventId,
    name,
    shortDescription,
    description,
    startTime,
    endTime,
    earliestClockInBeforeMinutes,
    maxClockInMinutes,
    categoryId,
    roomId,
    clockInMustBeInsideArea,
    visibilityStatus,
    whoCanRegister,
    needApproval,
    quota,
    type,
    isFeatured,
    featuredOrder,
    surveyFormId,
    normalPrice,
    price,
    canPayUsingPoint,
    registrationRewardType,
    registrationRewardPoint,
    registrationRewardVoucherIds,
    speakers,
    media,
    additionalItems,
    enableComment,
    commentAccess,
    banner,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_SESSION, body, token);
};

export const enableDisableSession = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_SESSION, body, token);
};

export const sendSessionNotification = async (id, token) => {
  await sendPostRequest(REST_URL_SEND_SESSION_TO_ME.replace(/\{id\}/, id), null, token);
};

export const downloadEventInvitations = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_EVENT_INVITATIONS, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadEventInvitation = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_EVENT_INVITATION.replace(/\{id\}/, id), token);
  return response;
};

export const deleteEventInvitation = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_EVENT_INVITATION.replace(/\{id\}/, id), token);
};

export const addEventInvitation = async (eventId, emails, token) => {
  const body = {
    eventId,
    emails,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_EVENT_INVITATION, body, token);
};

export const resendEventInvitation = async (invitationId, token) => {
  const body = { invitationId };
  await sendPostRequest(REST_URL_RESEND_EVENT_INVITATION, body, token);
};

export const saveEventInvitations = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const downloadUrl = REST_URL_EVENT_INVITATIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = buildQueryParams(
    transformCompleteUrl(downloadUrl, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadSponsors = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_SPONSORS, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadSponsor = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_SPONSOR.replace(/\{id\}/, id), token);
  return response;
};

export const deleteSponsor = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_SPONSOR.replace(/\{id\}/, id), token);
};

export const addSponsor = async (eventId, sponsorCategoryId, companyId, order, token) => {
  const body = {
    eventId, sponsorCategoryId, companyId, order,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_SPONSOR, body, token);
};

export const editSponsor = async (id, eventId, sponsorCategoryId, companyId, order, token) => {
  const body = {
    id,
    eventId,
    sponsorCategoryId,
    companyId,
    order,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_SPONSOR, body, token);
};

export const downloadEventRegistrations = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_EVENT_REGISTRATIONS, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadEventRegistration = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_EVENT_REGISTRATION.replace(/\{id\}/, id), token);
  return response;
};

export const approveRejectEventRegistration = async (id, status, notes, token) => {
  const body = { id, status, notes };
  await sendPutRequest(REST_URL_APPROVE_REJECT_EVENT_REGISTRATION, body, token);
};

export const saveEventRegistrations = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const downloadUrl = REST_URL_EVENT_REGISTRATIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = buildQueryParams(
    transformCompleteUrl(downloadUrl, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadSessionRegistrations = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_SESSION_REGISTRATIONS, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadSessionRegistration = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_SESSION_REGISTRATION.replace(/\{id\}/, id), token);
  return response;
};

export const approveRejectSessionRegistration = async (id, status, notes, token) => {
  const body = { id, status, notes };
  await sendPutRequest(REST_URL_APPROVE_REJECT_SESSION_REGISTRATION, body, token);
};

export const saveSessionRegistrations = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const downloadUrl = REST_URL_SESSION_REGISTRATIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = buildQueryParams(
    transformCompleteUrl(downloadUrl, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadSessionInvitation = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_SESSION_INVITATION.replace(/\{id\}/, id), token);
  return response;
};

export const deleteSessionInvitation = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_SESSION_INVITATION.replace(/\{id\}/, id), token);
};

export const addSessionInvitation = async (sessionId, emails, token) => {
  const body = {
    sessionId,
    emails,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_SESSION_INVITATION, body, token);
};

export const resendSessionInvitation = async (invitationId, token) => {
  const body = { invitationId };
  await sendPostRequest(REST_URL_RESEND_SESSION_INVITATION, body, token);
};

export const downloadSessionInvitations = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_SESSION_INVITATIONS, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const saveSessionInvitations = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const downloadUrl = REST_URL_SESSION_INVITATIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = buildQueryParams(
    transformCompleteUrl(downloadUrl, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const getAdditionalItemFileExtension = (data) => {
  switch (data.type) {
    case ADDITIONAL_ITEM_TYPE_IMAGE: return FILE_EXTENSION_IMAGE;
    case ADDITIONAL_ITEM_TYPE_PDF: return FILE_EXTENSION_PDF;
    case ADDITIONAL_ITEM_TYPE_SPREADSHEET: return FILE_EXTENSION_SPREADSHEET;
    case ADDITIONAL_ITEM_TYPE_SLIDESHOW: return FILE_EXTENSION_SLIDESHOW;
    case ADDITIONAL_ITEM_TYPE_DOCUMENT: return FILE_EXTENSION_DOCUMENT;
    case ADDITIONAL_ITEM_TYPE_ZIP: return FILE_EXTENSION_ZIP;
    case ADDITIONAL_ITEM_TYPE_OTHER:
    default: return FILE_EXTENSION_ALL;
  }
};

export const transformAdditionalItemsPayload = (data) => (data?.length ? data.map((x) => ({
  id: x.id || '',
  order: toNumber(x.order),
  label: x.label,
  type: x.type,
  viewAccess: x.viewAccess,
  visibilityTime: x.visibilityTime,
  content: !isAdditionalItemYoutubeUrlType(x) ? x?.content?.content || null : '',
  url: isAdditionalItemYoutubeUrlType(x) ? x?.url || x.path : '',
})) : []);

export const downloadSessionRooms = async (eventId, token) => {
  const url = REST_URL_SESSION_ROOMS.replace(/\{eventId\}/, eventId);
  const response = await sendGetRequest(url, token);
  return response;
};

export const getLandingPage = (data) => (REST_BASE_WEBAPP_LANDING.replace(/\{id\}/, data.id));
