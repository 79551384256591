import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogActions, makeStyles, Typography, Checkbox,
  Tabs, Tab, CircularProgress, Avatar,
} from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank, LockOutlined } from '@material-ui/icons/';
import { reduxForm, Field } from 'redux-form';
import {
  COLOR_BACKGROUND, COLOR_DANGEROUS, COLOR_PRIMARY,
  COLOR_ACTIVE_TAB_BACKGROUND,
  RXFIELD_SETTINGS_NEW_PASSWORD,
  RXFIELD_SETTINGS_NEW_PASSWORD_RETYPE,
  RXFIELD_SETTINGS_OLD_PASSWORD,
  RXFORM_SETTINGS_DIALOG,
  COLOR_TABS_BORDER,
  COLOR_ICON,
  SIMPLE_DATE_FORMAT,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../redux-form-rendererer';
import { rxformValidateSettings } from '../../validation';
import AccentButton from '../accent-button';
import TabPanel from '../tab-panel';

const useStyles = makeStyles(() => ({
  dialog: {
    padding: '24px !important',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    background: COLOR_PRIMARY,
    height: 40,
    width: 40,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 12,
    overflow: 'hidden',
  },
  icon: {
    fontSize: 20,
    color: COLOR_BACKGROUND,
  },
  boldText: {
    fontWeight: 'bold',
  },
  mainFieldsContainer: {
    padding: '24px',
    border: `1px solid ${COLOR_PRIMARY}`,
    borderRadius: 10,
    marginBottom: 15,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionButton: {
    flex: 1,
    paddingTop: '12px',
    paddingBottom: '12px',
    borderRadius: '100px',
    fontWeight: 700,
  },
  cancelButton: {
    color: COLOR_DANGEROUS,
  },
  saveButton: {
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_BACKGROUND,

    '&:hover': {
      backgroundColor: COLOR_PRIMARY,
    },
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  myProfileSectionContainer: {
    marginLeft: 5,
  },
  settingsSectionContainer: {
    marginLeft: 10,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: COLOR_PRIMARY,
      height: 2,
    },

    '& .MuiTab-root.Mui-selected': {
      color: COLOR_PRIMARY,
      backgroundColor: COLOR_ACTIVE_TAB_BACKGROUND,
      borderWidth: 0,
      fontWeight: 600,
    },

    '& .MuiTab-root': {
      border: `1px solid ${COLOR_TABS_BORDER}`,
    },

    '& .MuiTab-root.Mui-selected#simple-tab-1': {
      borderRightWidth: `1px solid ${COLOR_TABS_BORDER}`,
    },

    '& .MuiTab-root.Mui-selected#simple-tab-2': {
      borderLeftWidth: `1px solid ${COLOR_TABS_BORDER}`,
    },
  },
  tabItemTitle: {
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 400,
    color: COLOR_ICON,
  },
  primaryBlueText: {
    color: COLOR_PRIMARY,
  },
  myProfileField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    border: `1px solid ${COLOR_TABS_BORDER}`,
    borderRadius: 4,
    padding: '8px 16px',
    marginTop: 8,
    flexWrap: 'wrap',
  },
  myProfileFieldName: {
    flex: 1,
  },
  myProfileFieldValue: {
    flex: 2,
    fontWeight: 600,
  },
}));

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const MyProfileItem = ({ classes, fieldName, fieldValue }) => (
  <div className={classes.myProfileField}>
    <Typography className={classes.myProfileFieldName}>
      {fieldName}
    </Typography>
    <Typography className={`${classes.primaryBlueText} ${classes.myProfileFieldValue}`}>
      :
      {' '}
      {fieldValue || '-'}
    </Typography>
  </div>
);

MyProfileItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.any.isRequired,
  fieldName: PropTypes.string,
  fieldValue: PropTypes.string,
};

MyProfileItem.defaultProps = {
  fieldName: '',
  fieldValue: '',
};

const SettingsDialog = ({
  visible, changingPassword, activeTabId, myProfile,
  downloadingMyProfile, onSavePressed, onCancelPressed, handleSubmit,
  onTabPressed,
}) => {
  const classes = useStyles();
  const [logoutDevices, setLogoutDevices] = useState(false);

  return (
    <Dialog
      open={visible}
      onClose={onCancelPressed}
      fullWidth
    >
      <DialogContent className={classes.dialog}>
        <Tabs
          value={activeTabId}
          onChange={(event, newValue) => onTabPressed(newValue)}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          className={classes.tabs}
        >
          <Tab
            label={LocalizedString.common.labelMyProfile}
            disabled={downloadingMyProfile}
            className={classes.tabItemTitle}
            {...tabProps(1)}
          />
          <Tab
            label={LocalizedString.common.labelSettings}
            disabled={downloadingMyProfile}
            className={classes.tabItemTitle}
            {...tabProps(2)}
          />
        </Tabs>

        <TabPanel value={activeTabId} index={0}>
          {downloadingMyProfile ? (
            <div className={classes.headerContainer}>
              <CircularProgress className={classes.primaryBlueText} size={32} />
            </div>
          ) : (
            <div className={classes.mainFieldsContainer}>
              <div className={`${classes.sectionContainer} ${classes.myProfileSectionContainer}`}>
                <div className={classes.iconContainer}>
                  <Avatar className={classes.icon} src={myProfile?.profilePicture} />
                </div>

                <Typography variant="h6" className={classes.primaryBlueText}>
                  {LocalizedString.common.labelMyProfile}
                </Typography>
              </div>
              <div>
                <MyProfileItem
                  classes={classes}
                  fieldName={LocalizedString.profileScreen.labelEmployeeID}
                  fieldValue={`${myProfile?.employeeId}`}
                />
                <MyProfileItem
                  classes={classes}
                  fieldName={LocalizedString.common.labelUserName}
                  fieldValue={myProfile?.username}
                />
                <MyProfileItem
                  classes={classes}
                  fieldName={LocalizedString.profileScreen.labelFullName}
                  fieldValue={myProfile?.fullName}
                />
                <MyProfileItem
                  classes={classes}
                  fieldName={LocalizedString.profileScreen.labelEmail}
                  fieldValue={myProfile?.email}
                />
                <MyProfileItem
                  classes={classes}
                  fieldName={LocalizedString.common.labelUserCode}
                  fieldValue={myProfile?.userCode}
                />
                <MyProfileItem
                  classes={classes}
                  fieldName={LocalizedString.profileScreen.placeholderDateOfBirth}
                  fieldValue={myProfile?.dateOfBirth
                    ? toMoment(myProfile?.dateOfBirth).format(SIMPLE_DATE_FORMAT) : null}
                />
                <MyProfileItem
                  classes={classes}
                  fieldName={LocalizedString.common.labelGender}
                  fieldValue={myProfile?.gender}
                />
                <MyProfileItem
                  classes={classes}
                  fieldName={LocalizedString.common.labelPhone}
                  fieldValue={myProfile?.phone}
                />
                <MyProfileItem
                  classes={classes}
                  fieldName={LocalizedString.common.labelRoles}
                  fieldValue={myProfile?.roles}
                />
                <MyProfileItem
                  classes={classes}
                  fieldName={LocalizedString.common.labelTags}
                  fieldValue={myProfile?.tags}
                />
              </div>
            </div>
          )}
        </TabPanel>

        <TabPanel value={activeTabId} index={1}>
          <div className={classes.mainFieldsContainer}>
            <div className={`${classes.sectionContainer} ${classes.settingsSectionContainer}`}>
              <div className={classes.iconContainer}>
                <LockOutlined className={classes.icon} />
              </div>

              <Typography variant="h6" className={classes.primaryBlueText}>
                {LocalizedString.common.labelChangePassword}
              </Typography>
            </div>
            <Field
              name={RXFIELD_SETTINGS_OLD_PASSWORD}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.common.placeholderOldPassword}
              label={LocalizedString.common.placeholderOldPassword}
              editable={changingPassword}
              secureTextEntry
              required
            />
            <Field
              name={RXFIELD_SETTINGS_NEW_PASSWORD}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.common.placeholderNewPassword}
              label={LocalizedString.common.placeholderNewPassword}
              editable={changingPassword}
              secureTextEntry
              required
            />
            <Field
              name={RXFIELD_SETTINGS_NEW_PASSWORD_RETYPE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.common.placeholderRetypeNewPassword}
              label={LocalizedString.common.placeholderRetypeNewPassword}
              editable={changingPassword}
              secureTextEntry
              required
            />

            <div className={classes.rowContainer}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                style={{ marginRight: 8, color: '#2B2B2B80' }}
                checked={logoutDevices}
                onChange={() => setLogoutDevices(!logoutDevices)}
              />
              <Typography style={{ color: '#2B2B2B80' }}>
                {LocalizedString.common.labelLogoutDevices}
              </Typography>
            </div>
          </div>
          <DialogActions className={classes.buttonContainer}>
            <AccentButton
              variant="text"
              caption={LocalizedString.common.buttonCaptionCancel}
              onClick={onCancelPressed}
              className={`${classes.cancelButton} ${classes.actionButton}`}
              disabled={changingPassword}
            />

            <AccentButton
              caption={LocalizedString.common.buttonCaptionSave}
              onClick={handleSubmit((values) => onSavePressed(values, logoutDevices))}
              loading={changingPassword}
              className={`${classes.saveButton} ${classes.actionButton}`}
            />
          </DialogActions>
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

export default reduxForm({
  form: RXFORM_SETTINGS_DIALOG,
  validate: rxformValidateSettings,
})(SettingsDialog);

SettingsDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  changingPassword: PropTypes.bool.isRequired,
  activeTabId: PropTypes.number.isRequired,
  myProfile: PropTypes.object.isRequired,
  downloadingMyProfile: PropTypes.bool.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onTabPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
};

SettingsDialog.defaultProps = {
};
