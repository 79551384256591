import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_CAPTCHA_REQUEST = '/captcha/api/v1/CaptchaRequest?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|requestTag';
export const REST_URL_VIEW_CAPTCHA_REQUEST = '/captcha/api/v1/CaptchaRequest/{id}';

export const ROUTE_NAME_CAPTCHA_MENU = '/captcha';
export const ROUTE_NAME_CAPTCHA_REQUEST = '/captcha-request';

export const RXFORM_CAPTCHA_REQUEST = 'captchaRequestPage';

export const RXFIELD_CAPTCHA_REQUEST_REQUEST_TAG = 'requestTag';
export const RXFIELD_CAPTCHA_REQUEST_CAPTCHA_TEXT = 'captchaText';
export const RXFIELD_CAPTCHA_REQUEST_IMAGE = 'captchaImage';
export const RXFIELD_CAPTCHA_REQUEST_REFERENCE_ID = 'referenceId';
export const RXFIELD_CAPTCHA_REQUEST_TAG = 'tag';
export const RXFIELD_CAPTCHA_REQUEST_VALID_UNTIL = 'validUntil';
export const RXFIELD_CAPTCHA_REQUEST_VERIFIED = 'verified';
export const RXFIELD_CAPTCHA_REQUEST_VERIFIED_ON = 'verifiedOn';
export const RXFIELD_CAPTCHA_REQUEST_VERIFIED_FOR_USER_ID = 'verifiedFor.userId';
export const RXFIELD_CAPTCHA_REQUEST_VERIFIED_FOR_FULL_NAME = 'verifiedFor.fullName';
export const RXFIELD_CAPTCHA_REQUEST_DEVICE_ID = 'deviceId';
export const RXFIELD_CAPTCHA_REQUEST_FCM_TOKEN = 'fcmToken';
export const RXFIELD_CAPTCHA_REQUEST_LANGUAGE = 'acceptLanguage';
export const RXFIELD_CAPTCHA_REQUEST_USER_AGENT = 'userAgent';
export const RXFIELD_CAPTCHA_REQUEST_MANUFACTURER = 'manufacturer';
export const RXFIELD_CAPTCHA_REQUEST_MODEL = 'model';
export const RXFIELD_CAPTCHA_REQUEST_OS_NAME = 'osName';
export const RXFIELD_CAPTCHA_REQUEST_OS_VERSION = 'osVersion';
export const RXFIELD_CAPTCHA_REQUEST_APP_VERSION = 'appVersion';

export const RXSTATE_CAPTCHA_REQUESTS = 'captchaRequests';
export const RXSTATE_CAPTCHA_REQUEST_PAGE = 'uiCaptchaRequest';

export const INITIAL_ORDER_BY_CAPTCHA_REQUESTS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_CAPTCHA = 'MENUID_CAPTCHA';
export const MENUID_CAPTCHA_REQUEST = 'MENUID_CAPTCHA_REQUEST';
