import {
  ADD_EVENT_OPERATOR_EVENT, CLEAR_EVENT_OPERATOR_EVENTS,
  SET_EVENT_OPERATOR_EVENTS,
} from '../action';

const initialState = {
  data: {},
  meta: {
    pageSize: 0,
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_EVENT_OPERATOR_EVENTS: {
      return {
        data: { ...action.data.data },
        meta: action.data.meta,
      };
    }
    case ADD_EVENT_OPERATOR_EVENT: {
      return {
        ...state,
        data: { ...state.data, [action.data.id]: action.data },
      };
    }
    case CLEAR_EVENT_OPERATOR_EVENTS:
      return initialState;
    default:
      return state;
  }
};
