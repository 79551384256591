import { INITIAL_ORDER_BY_EVENTS } from '../../constant';
import {
  ADDING_EDITING_EVENT, DOWNLOADING_DELETING_EVENT, DOWNLOADING_EVENTS, COPYING_EVENT_TEXT_FIELD,
  SET_EVENT_ADD_MEDIA_MENU_SELECTED, SENDING_EVENT_NOTIFICATION,
  SET_EVENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_EVENT_CAN_PAY_USING_POINT_SELECTED, SET_EVENT_CATEGORY_SELECTED,
  SET_EVENT_CLOCK_IN_AREA_SELECTED, SET_EVENT_EVENT_TYPE_SELECTED,
  SET_EVENT_FEATURE_SELECTED, SET_EVENT_LOCATION_SELECTED, SET_EVENT_NEED_APPROVAL_SELECTED,
  SET_EVENT_REWARD_TYPE_SELECTED, SET_EVENT_SEARCH_TEXT, SET_EVENT_SELECTED_ORDER_BY,
  SET_EVENT_SELECTED_PAGE_SIZE, SET_EVENT_TAPPED_ID, SET_EVENT_VISIBILITY_STATUS_SELECTED,
  SET_EVENT_VOUCHER_SEARCH_TEXT, SET_EVENT_SELECTED_ENABLE_COMMENT,
  SET_EVENT_VOUCHER_SELECTED, SET_EVENT_WHO_CAN_REGISTER_SELECTED, ENABLING_DISABLING_EVENT,
  SET_EVENT_TABLE_DATA_SPEAKERS, ADDING_EVENT_SPEAKERS, SET_EVENT_SELECTED_SPEAKERS,
  SET_EVENT_SELECTED_SURVEY_FORM,
  SET_EVENT_BANNER_IMAGE,
} from '../action';

const initialState = {
  addingEditing: false,
  copying: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_EVENTS,
  filterString: '',
  clockInMustBeInsideArea: false,
  registrationRewardType: '',
  registrationRewardVoucher: [],
  eventType: '',
  categoryId: '',
  locationIds: '',
  isFeatured: false,
  whoCanRegister: '',
  needApproval: false,
  canPayUsingPoint: false,
  visibilityStatus: '',
  selectedAddMediaMenu: '',
  voucherSearchText: '',
  selectedEnableComment: '',
  enablingDisabling: false,
  addingEventSpeaker: false,
  tableDataSpeakers: [],
  selectedSpeakers: null,
  selectedForm: null,
  sendingNotification: false,
  selectedBannerImage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_EVENTS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_EVENT:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_EVENT:
      return { ...state, downloadingDeleting: action.status };
    case SET_EVENT_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_EVENT_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_EVENT_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_EVENT_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_EVENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_EVENT_CLOCK_IN_AREA_SELECTED:
      return { ...state, clockInMustBeInsideArea: action.status };
    case SET_EVENT_REWARD_TYPE_SELECTED:
      return { ...state, registrationRewardType: action.text };
    case SET_EVENT_VOUCHER_SELECTED:
      return { ...state, registrationRewardVoucher: action.data };
    case SET_EVENT_EVENT_TYPE_SELECTED:
      return { ...state, eventType: action.text };
    case SET_EVENT_CATEGORY_SELECTED:
      return { ...state, categoryId: action.text };
    case SET_EVENT_LOCATION_SELECTED:
      return { ...state, locationIds: action.text };
    case SET_EVENT_FEATURE_SELECTED:
      return { ...state, isFeatured: action.status };
    case SET_EVENT_WHO_CAN_REGISTER_SELECTED:
      return { ...state, whoCanRegister: action.text };
    case SET_EVENT_NEED_APPROVAL_SELECTED:
      return { ...state, needApproval: action.status };
    case SET_EVENT_CAN_PAY_USING_POINT_SELECTED:
      return { ...state, canPayUsingPoint: action.status };
    case SET_EVENT_VISIBILITY_STATUS_SELECTED:
      return { ...state, visibilityStatus: action.text };
    case SET_EVENT_ADD_MEDIA_MENU_SELECTED:
      return { ...state, selectedAddMediaMenu: action.text };
    case SET_EVENT_VOUCHER_SEARCH_TEXT:
      return { ...state, voucherSearchText: action.text };
    case SET_EVENT_SELECTED_ENABLE_COMMENT:
      return { ...state, selectedEnableComment: action.option };
    case ENABLING_DISABLING_EVENT:
      return { ...state, enablingDisabling: action.status };
    case SET_EVENT_TABLE_DATA_SPEAKERS:
      return { ...state, tableDataSpeakers: action.option };
    case ADDING_EVENT_SPEAKERS:
      return { ...state, addingEventSpeaker: action.status };
    case SET_EVENT_SELECTED_SPEAKERS:
      return { ...state, selectedSpeakers: action.option };
    case SET_EVENT_SELECTED_SURVEY_FORM:
      return { ...state, selectedForm: action.option };
    case COPYING_EVENT_TEXT_FIELD:
      return { ...state, copying: action.status };
    case SENDING_EVENT_NOTIFICATION:
      return { ...state, sendingNotification: action.status };
    case SET_EVENT_BANNER_IMAGE:
      return { ...state, selectedBannerImage: action.image };
    default: return state;
  }
};
