import { INITIAL_ORDER_BY_SESSION_OPERATOR_MAPPINGS } from '../../constant';
import {
  DOWNLOADING_SESSION_OPERATOR_MAPPINGS, ADDING_EDITING_SESSION_OPERATOR_MAPPING,
  DOWNLOADING_DELETING_SESSION_OPERATOR_MAPPING, SET_SESSION_OPERATOR_MAPPING_SELECTED_PAGE_SIZE,
  SET_SESSION_OPERATOR_MAPPING_TAPPED_ID, SET_SESSION_OPERATOR_MAPPING_SEARCH_TEXT,
  SET_SESSION_OPERATOR_MAPPING_SELECTED_ORDER_BY, ENABLING_DISABLING_SESSION_OPERATOR_MAPPING,
  SET_SESSION_OPERATOR_MAPPING_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_SESSION_OPERATOR_MAPPING_SELECTED_SESSION, SET_SESSION_OPERATOR_MAPPING_SELECTED_USER,
} from '../action';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  enablingDisabling: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_SESSION_OPERATOR_MAPPINGS,
  filterString: '',
  selectedSession: '',
  selectedUser: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_SESSION_OPERATOR_MAPPINGS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_SESSION_OPERATOR_MAPPING:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_SESSION_OPERATOR_MAPPING:
      return { ...state, downloadingDeleting: action.status };
    case ENABLING_DISABLING_SESSION_OPERATOR_MAPPING:
      return { ...state, enablingDisabling: action.status };
    case SET_SESSION_OPERATOR_MAPPING_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_SESSION_OPERATOR_MAPPING_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_SESSION_OPERATOR_MAPPING_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_SESSION_OPERATOR_MAPPING_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_SESSION_OPERATOR_MAPPING_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_SESSION_OPERATOR_MAPPING_SELECTED_SESSION:
      return { ...state, selectedSession: action.option };
    case SET_SESSION_OPERATOR_MAPPING_SELECTED_USER:
      return { ...state, selectedUser: action.option };
    default: return state;
  }
};
