import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_SCANNED_CODES, MENUID_GAMEQR_SCANNED_CODE, PAGE_MODE_TABLE, RXFORM_SCANNED_CODE,
} from '../../constant';
import {
  debounceSearch,
  transformInitialValues, transformUserDropdownData,
} from '../../helper';
import {
  clearScannedCodes, clearChallenges,
  setActiveSideMenuItem, setAdvancedFilterDialogSelectedFilterString,
  setAlertErrorMessage, setScannedCodeSearchText, setScannedCodeSelectedPageSize,
  setScannedCodeSelectedOrderBy, setScannedCodeTappedId,
  downloadScannedCodeAsync, downloadScannedCodesAsync,
  downloadChallengesAsync, setChallengeSearchText,
  setChallengeAdvancedFilterDialogSelectedFilterString, saveScannedCodesAsync,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText, clearProfiles,
} from '../../redux/action';
import ScannedCodePage from './scanned-code.presentation';
import { downloadProfilesAsync } from '../../../forum/redux/action';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getInitialValues = (state) => {
  const { scannedCodes, uiScannedCode, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiScannedCode;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? scannedCodes.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      point: found.points ? found.points : '0',
      challengeName: found.challengeCode ? found.challengeCode?.challenge?.title : '',
      challengeCode: found.challengeCode ? found.challengeCode?.code : '',
      challengeId: found.challengeCode ? found.challengeCode?.challenge?.id : '',
      challengeLabel: found.challengeCode ? found.challengeCode?.label : '',
      fullName: found.user ? found.user?.fullName : '',
      userId: found.user ? found.user?.id : '',
    }) : {
      challengeCode: '',
      challengeId: '',
      challengeName: '',
      challengeLabel: '',
      userId: '',
      fullName: '',
      point: '',
    };
  return initVal;
};

const searchUserDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
);

const mapStateToProps = (state) => ({
  challenges: Object.values(state.challenges.data).map((item) => ({
    label: item.title,
    value: item.id,
  })),
  users: transformUserDropdownData(state.profiles.data),
  downloading: state.uiScannedCode.downloading,
  downloadingDeleting: state.uiScannedCode.downloadingDeleting,
  loadingChallenge: state.uiChallenge.downloading,
  loadingUsers: state.uiProfile.downloading,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setChallengeAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setChallengeSearchText(''));
    dispatch(clearChallenges());
    dispatch(downloadChallengesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_GAMEQR_SCANNED_CODE));
    dispatch(setScannedCodeSearchText(''));
    dispatch(clearScannedCodes());
    dispatch(setScannedCodeSelectedPageSize(20));
    dispatch(setScannedCodeSelectedOrderBy(INITIAL_ORDER_BY_SCANNED_CODES));
    dispatch(downloadScannedCodesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace(/user/, 'user.id')
      .replace(/challenge=/, 'challengeCode.challenge.id=')
      .replace(/challengeCode=/, 'challengeCode.code=');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearScannedCodes());
    dispatch(downloadScannedCodesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_SCANNED_CODE));
  },
  onChangeChallengeText: async (text) => {
    try {
      dispatch(setChallengeSearchText(text));
      dispatch(clearChallenges());
      await dispatch(downloadChallengesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadScannedCodesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setScannedCodeSelectedPageSize(pageSize));
    dispatch(downloadScannedCodesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDownloadPressed: () => {
    dispatch(saveScannedCodesAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setScannedCodeSelectedPageSize(pageSize));
    dispatch(clearScannedCodes());
    dispatch(downloadScannedCodesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearScannedCodes());
    dispatch(setScannedCodeSelectedOrderBy(INITIAL_ORDER_BY_SCANNED_CODES));
    dispatch(downloadScannedCodesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setScannedCodeSearchText(text));
      dispatch(clearScannedCodes());
      await dispatch(downloadScannedCodesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setScannedCodeSelectedOrderBy(orderBy));
    dispatch(clearScannedCodes());
    dispatch(downloadScannedCodesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setScannedCodeTappedId(id));
    dispatch(downloadScannedCodeAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ScannedCodePage);
