/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Grid, makeStyles, Typography,
} from '@material-ui/core';
import { Notifications } from '@material-ui/icons/';
import { reduxForm, Field } from 'redux-form';
import {
  COLOR_BACKGROUND, COLOR_BACKGROUND_SECONDARY, COLOR_BODY_TEXT, COLOR_DANGEROUS, COLOR_PRIMARY,
  eventNotificationRecipients, FILTER_TYPE_DATE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  FILTER_TYPE_MULTIPLE_VALUES, FILTER_TYPE_NUMBER, FILTER_TYPE_NUMBER_RANGE,
  FILTER_TYPE_RADIO_BUTTON, FILTER_TYPE_SWITCH, FILTER_TYPE_TEXT,
  IMAGE_FILE_EXTENSIONS, REGEX_DATE_TIME_FORMAT,
  RXFIELD_SEND_PERSONAL_NOTIFICATION_BODY, RXFIELD_SEND_PERSONAL_NOTIFICATION_BRANCH,
  RXFIELD_SEND_PERSONAL_NOTIFICATION_COMPANY_NAME, RXFIELD_SEND_PERSONAL_NOTIFICATION_DEPARTMENT,
  RXFIELD_SEND_PERSONAL_NOTIFICATION_DIVISION, RXFIELD_SEND_PERSONAL_NOTIFICATION_IMAGE,
  RXFIELD_SEND_PERSONAL_NOTIFICATION_TITLE,
  RXFIELD_SEND_PERSONAL_NOTIFICATION_URL, RXFORM_SEND_PERSONAL_NOTIFICATION_MODAL,
  switches,
} from '../../constant';
import {
  localDateToUtc, toMoment, toNumber, toUtcMoment,
} from '../../helper';
import LocalizedString from '../../localization';
import {
  renderReduxFormAutocompleteCheckboxField, renderReduxFormDateTimePickerField,
  renderReduxFormDropzonePicker,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField,
  renderReduxFormRadioButtonField, renderReduxFormSimpleDropdownField,
} from '../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape, TableColumnShape } from '../../type';
import { rxformValidateSendPersonalNotification } from '../../validation';
import AccentButton from '../accent-button';
import SectionTitle from '../section-title';

const REGEX_MATCH_STRING_INSIDE_CURLY_BRACES = '{(.*?)}';

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    background: COLOR_PRIMARY,
    height: 100,
    width: 100,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 15,
  },
  icon: {
    fontSize: 50,
    color: COLOR_BACKGROUND,
  },
  boldText: {
    fontWeight: 'bold',
  },
  italicText: {
    fontStyle: 'italic',
  },
  subtitle: {
    marginTop: 15,
  },
  darkGreenText: {
    color: COLOR_PRIMARY,
  },
  mainFieldsContainer: {
    padding: '17px 15px 24px 15px',
    border: `1px solid ${COLOR_PRIMARY}`,
    borderRadius: 10,
    marginBottom: 15,
  },
  dropdownContainer: {
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_PRIMARY,
      },
    },
    width: '100%',
  },
  gridContainer: {
    marginTop: 20,
  },
  totalPersonCountContainer: {
    padding: '10px 20px 10px 20px',
    background: COLOR_BACKGROUND_SECONDARY,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '20px 0px 20px 0px',
  },
  totalPersonCountWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  captionText: {
    marginLeft: 10,
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  outlinedButton: {
    color: COLOR_PRIMARY,
    borderWidth: 1,
    borderRadius: 50,
    borderColor: COLOR_PRIMARY,
    background: COLOR_BACKGROUND,
  },
  redButton: {
    color: COLOR_DANGEROUS,
  },
}));

const onCancelPress = (onCancelPressed, onCancelSendNotificationDialogPressed, setErrorDateRange,
  setErrorNumRange, useDefaultFilterSection) => {
  setErrorDateRange(null);
  setErrorNumRange(null);
  onCancelPressed(useDefaultFilterSection);
  onCancelSendNotificationDialogPressed();
};

const getCustomFilterString = (e, setErrorDateRange, setErrorNumRange, sendToFieldName) => {
  setErrorDateRange(null);
  setErrorNumRange(null);

  let result = '';
  let minNum = null;
  let maxNum = null;
  let minDate = '';
  let maxDate = '';

  const exceptionFields = {
    [RXFIELD_SEND_PERSONAL_NOTIFICATION_TITLE]: e[RXFIELD_SEND_PERSONAL_NOTIFICATION_TITLE],
    [RXFIELD_SEND_PERSONAL_NOTIFICATION_BODY]: e[RXFIELD_SEND_PERSONAL_NOTIFICATION_BODY],
    [RXFIELD_SEND_PERSONAL_NOTIFICATION_URL]: e[RXFIELD_SEND_PERSONAL_NOTIFICATION_URL],
    [RXFIELD_SEND_PERSONAL_NOTIFICATION_IMAGE]: e[RXFIELD_SEND_PERSONAL_NOTIFICATION_IMAGE],
    [sendToFieldName]: e[sendToFieldName],
  };

  Object.keys(e).forEach(() => Object.keys(exceptionFields).forEach((x) => delete e[x]));

  Object.keys(e).forEach((key) => {
    if (typeof e[key] === 'string' && e[key].match(/^[0-9]*$/)) {
      if (key.includes('>')) {
        minNum += toNumber(e[key]);
      }
      if (key.includes('<')) {
        maxNum += toNumber(e[key]);
      }
    }
    if (typeof e[key] === 'string' && e[key].match(REGEX_DATE_TIME_FORMAT)) {
      if (key.includes('>')) {
        minDate += toUtcMoment(e[key]);
      }
      if (key.includes('<')) {
        maxDate += toUtcMoment(e[key]);
      }
    }
  });

  const dateRangeCheck = minDate && maxDate && !toMoment(maxDate).isSameOrAfter(toMoment(minDate));
  const numberRangeCheck = (minNum && maxNum && minNum > maxNum)
  || (minNum > maxNum && maxNum === 0);
  if (dateRangeCheck) {
    setErrorDateRange(LocalizedString.common.errMsgStartEndDate);
  }
  if (numberRangeCheck) {
    setErrorNumRange(LocalizedString.common.errMsgMinMaxNum);
  }
  if (!dateRangeCheck && !numberRangeCheck) {
    Object.keys(e).forEach((key) => {
      if ((!e[key] && typeof e[key] !== 'boolean' && typeof e[key] !== 'object')) {
        return delete e[key];
      }
      if (typeof e[key] === 'string' && e[key].match(REGEX_DATE_TIME_FORMAT)) {
        result += `|${key}=${localDateToUtc(e[key])}`;
      }
      if ((typeof e[key] === 'object' && e[key].value)
        || (typeof e[key] === 'string' && !e[key].match(REGEX_DATE_TIME_FORMAT))
        || (typeof e[key] === 'boolean')) {
        result += `|${key}=${e[key] && typeof e[key] === 'object' && e[key].value ? encodeURIComponent(e[key].value) : encodeURIComponent(e[key])}`;
      }
      if (e[key] instanceof Array) {
        if (e[key].some((item) => typeof item === 'string')) {
          const transformArr = e[key].map((x) => encodeURIComponent(x));
          result += `|${key}><${transformArr.join(';')}`;
        }
        if (e[key].some((item) => typeof item === 'object')) {
          const transformArr = e[key].map((x) => encodeURIComponent(x.value));
          result += `|${key}><${transformArr.join(';')}`;
        }
      }
      return result;
    });
    return result;
  }
  return null;
};

const onHandleSubmitCustomFilterForm = async (e, onCancelPressed,
  onCancelSendNotificationDialogPressed, onError, onSendNotificationPressed, onSendPressed,
  setErrorDateRange, setErrorNumRange, sendToFieldName) => {
  const exceptionFields = {
    [RXFIELD_SEND_PERSONAL_NOTIFICATION_TITLE]: e[RXFIELD_SEND_PERSONAL_NOTIFICATION_TITLE],
    [RXFIELD_SEND_PERSONAL_NOTIFICATION_BODY]: e[RXFIELD_SEND_PERSONAL_NOTIFICATION_BODY],
    [RXFIELD_SEND_PERSONAL_NOTIFICATION_URL]: e[RXFIELD_SEND_PERSONAL_NOTIFICATION_URL],
    [RXFIELD_SEND_PERSONAL_NOTIFICATION_IMAGE]: e[RXFIELD_SEND_PERSONAL_NOTIFICATION_IMAGE],
    [sendToFieldName]: e[sendToFieldName],
  };

  const result = getCustomFilterString(e, setErrorDateRange, setErrorNumRange, sendToFieldName);

  if (result) {
    try {
      await onSendNotificationPressed(exceptionFields, onSendPressed, null, result);
      onCancelPress(onCancelPressed, onCancelSendNotificationDialogPressed, setErrorDateRange,
        setErrorNumRange);
    } catch (error) {
      onError(error);
    }
  } else {
    try {
      await onSendNotificationPressed(exceptionFields, onSendPressed, null);
      onCancelPress(onCancelPressed, onCancelSendNotificationDialogPressed, setErrorDateRange,
        setErrorNumRange);
    } catch (error) {
      onError(error);
    }
  }
};

const onHandleSubmitDefaultForm = async (values, useFilterSection, onCancelPressed,
  onCancelSendNotificationDialogPressed, onError, onSendNotificationPressed, onSendPressed,
  setErrorDateRange, setErrorNumRange) => {
  try {
    await onSendNotificationPressed(values, onSendPressed, useFilterSection);
    onCancelPress(onCancelPressed, onCancelSendNotificationDialogPressed,
      setErrorDateRange, setErrorNumRange, useFilterSection);
  } catch (error) {
    onError(error);
  }
};

const renderCheckboxAutoComplete = (options, loading, onChangeDropdown, onChangeText,
  onOptionSelected, fieldName, label, placeholder, classes) => (
    <div className={classes.dropdownContainer} key={fieldName}>
      <Field
        name={fieldName}
        component={renderReduxFormAutocompleteCheckboxField}
        placeholder={placeholder}
        label={label}
        data={options}
        loading={loading}
        onChangeText={onChangeText}
        onOptionSelected={(e) => {
          onChangeDropdown(e, fieldName);
          onOptionSelected(e);
        }}
        onBlur={(e) => e.preventDefault()}
      />
    </div>
);

const renderCustomFilterItem = (item, loading, errorDateRange, errorNumRange, onChangeDate,
  onChangeDropdown, onChangeNumber, onChangeSwitch, setErrorDateRange, setErrorNumRange) => {
  switch (item.type) {
    case FILTER_TYPE_DATE: {
      return (
        <Grid item sm md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormDateTimePickerField}
            label={item.title}
            disabled={loading}
            pickerMode={item.pickerMode}
            onChangeDate={(e) => onChangeDate(e, item.field)}
            dateFormat={item.format}
          />
        </Grid>
      );
    }
    case FILTER_TYPE_DATE_RANGE: {
      return (
        <Grid item container key={item.field}>
          <Grid item sm md>
            <Field
              name={`${item.field}>`}
              component={renderReduxFormDateTimePickerField}
              label={`${LocalizedString.common.labelMin} ${item.title}`}
              disabled={loading}
              pickerMode={item.pickerMode}
              onChangeDate={(e) => {
                setErrorDateRange(null);
                onChangeDate(e, `${item.field}>`);
              }}
              helperText={errorDateRange}
              dateFormat={item.format}
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={`${item.field}<`}
              component={renderReduxFormDateTimePickerField}
              label={`${LocalizedString.common.labelMax} ${item.title}`}
              disabled={loading}
              pickerMode={item.pickerMode}
              onChangeDate={(e) => {
                setErrorDateRange(null);
                onChangeDate(e, `${item.field}<`);
              }}
              helperText={errorDateRange}
            />
          </Grid>
        </Grid>
      );
    }
    case FILTER_TYPE_DROPDOWN: {
      return (
        <Grid item sm md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={item.title}
            label={item.title}
            disabled={loading}
            data={item.data}
            loading={item.loading}
            onChangeText={item.onChangeFilterText}
            onOptionSelected={item.onFilterOptionSelected ? (e) => {
              onChangeDropdown(e, item.field);
              item.onFilterOptionSelected();
            } : (e) => onChangeDropdown(e, item.field)}
            onBlur={item.useDropdownValue ? (e) => e.preventDefault() : undefined}
          />
        </Grid>
      );
    }
    case FILTER_TYPE_MULTIPLE_VALUES: {
      return (
        <Grid item sm md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormAutocompleteCheckboxField}
            placeholder={item.title}
            label={item.title}
            disabled={loading}
            data={item.data}
            loading={item.loading}
            onChangeText={item.onChangeFilterText}
            onOptionSelected={item.onFilterOptionSelected ? (e) => {
              onChangeDropdown(e, item.field);
              item.onFilterOptionSelected();
            } : (e) => onChangeDropdown(e, item.field)}
            onBlur={(e) => e.preventDefault()}
          />
        </Grid>
      );
    }
    case FILTER_TYPE_NUMBER: {
      return (
        <Grid item xs md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormOutlinedTextField}
            placeholder={item.title}
            label={item.title}
            disabled={loading}
            type="number"
          />
        </Grid>
      );
    }
    case FILTER_TYPE_NUMBER_RANGE: {
      return (
        <Grid item container key={item.field}>
          <Grid item sm md>
            <Field
              name={`${item.field}>`}
              component={renderReduxFormOutlinedTextField}
              placeholder={`${LocalizedString.common.labelMin} ${item.title}`}
              label={`${LocalizedString.common.labelMin} ${item.title}`}
              disabled={loading}
              onChangeText={(e) => {
                setErrorNumRange(null);
                onChangeNumber(e, `${item.field}>`);
              }}
              type="number"
              helperText={errorNumRange}
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={`${item.field}<`}
              component={renderReduxFormOutlinedTextField}
              placeholder={`${LocalizedString.common.labelMax} ${item.title}`}
              label={`${LocalizedString.common.labelMax} ${item.title}`}
              disabled={loading}
              onChangeText={(e) => {
                setErrorNumRange(null);
                onChangeNumber(e, `${item.field}<`);
              }}
              type="number"
              helperText={errorNumRange}
            />
          </Grid>
        </Grid>
      );
    }
    case FILTER_TYPE_RADIO_BUTTON: {
      return (
        <Grid item sm md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormRadioButtonField}
            label={item.title}
            data={item.data}
            onOptionSelected={item.onFilterOptionSelected}
            disabled={loading}
          />
        </Grid>
      );
    }
    case FILTER_TYPE_SWITCH: {
      return (
        <Grid item sm md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormSimpleDropdownField}
            placeholder={item.title}
            label={item.title}
            disabled={loading}
            data={switches}
            onOptionSelected={(e) => onChangeSwitch(e, item.field)}
          />
        </Grid>
      );
    }
    case FILTER_TYPE_TEXT: {
      return (
        <Grid item xs md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormOutlinedTextField}
            placeholder={item.title}
            label={item.title}
            disabled={loading}
          />
        </Grid>
      );
    }

    default: {
      return (
        <Grid item xs md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormOutlinedTextField}
            placeholder={item.title}
            label={item.title}
            disabled={loading}
          />
        </Grid>
      );
    }
  }
};

const SendNotificationDialog = ({
  initialValues, filterForm, branches, companies, divisions, departments, filterColumns,
  disabledUrlField, downloadingBranches, downloadingCompanies, downloadingDepartments,
  downloadingDivisions, downloadingTotalPersonCount, isEmptyRequiredFields, loading,
  sendingNotifications, useFilterSection, useSendToFilter, visible,
  handleSubmit, onBranchOptionSelected, onCancelPressed, onCancelSendNotificationDialogPressed,
  onChangeBranchText, onChangeCompanyText, onChangeDate, onChangeDepartmentText,
  onChangeDivisionText, onChangeDropdown, onChangeNumber, onChangeSwitch, onCompanyOptionSelected,
  onCountPressed, onCountTotalPersonPressed, onDepartmentOptionSelected, onDivisionOptionSelected,
  onError, onSendNotificationPressed, onSendPressed, onSendToOptionSelected,
  totalPersonCount,
  additionalUrlPlaceholder, recipient, sendToFieldDefaultValue, sendToFieldName, sendingToLabel,
  onImageSelected,
}) => {
  const classes = useStyles();

  const [errorDateRange, setErrorDateRange] = useState(null);
  const [errorNumRange, setErrorNumRange] = useState(null);
  const useDefaultFilterSection = filterColumns.length === 0;

  const additionalPlaceholderHtmlComponent = `<p><i>${additionalUrlPlaceholder.match(REGEX_MATCH_STRING_INSIDE_CURLY_BRACES) ? additionalUrlPlaceholder.replace(/{(.*?)}/g, '<b>{$1}</b>') : additionalUrlPlaceholder}</i></p>`;

  return (
    <Dialog
      open={visible}
      onClose={() => onCancelPress(onCancelPressed, onCancelSendNotificationDialogPressed,
        setErrorDateRange, setErrorNumRange, useDefaultFilterSection)}
      fullWidth
      maxWidth={useFilterSection ? 'md' : 'sm'}
    >
      <DialogTitle>
        <div className={classes.headerContainer}>
          <div className={classes.iconContainer}>
            <Notifications className={classes.icon} />
          </div>

          <Typography variant="h6" className={classes.boldText}>
            {LocalizedString.common.labelSendNotification}
          </Typography>

          <Typography variant="body2" className={classes.subtitle}>{sendingToLabel}</Typography>

          <Typography variant="body2" className={classes.darkGreenText}>
            {`${recipient || LocalizedString.common.labelSelectedOrAllUser}`}
          </Typography>
        </div>
      </DialogTitle>

      <DialogContent>
        <div className={classes.mainFieldsContainer}>
          <Field
            name={RXFIELD_SEND_PERSONAL_NOTIFICATION_TITLE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.common.placeholderTitle}
            label={LocalizedString.common.placeholderTitle}
            value={initialValues ? initialValues.title : ''}
            required
          />
          <Field
            name={RXFIELD_SEND_PERSONAL_NOTIFICATION_BODY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.common.placeholderBody}
            label={LocalizedString.common.placeholderBody}
            value={initialValues ? initialValues.body : ''}
            multiline
            required
          />
          <Field
            name={RXFIELD_SEND_PERSONAL_NOTIFICATION_IMAGE}
            component={renderReduxFormDropzonePicker}
            label={LocalizedString.common.placeholderImage}
            disabled={loading}
            onImageSelected={onImageSelected}
            onBlur={(e) => e.preventDefault()}
            defaultValue={initialValues ? initialValues.image : ''}
            imageFileExtension={IMAGE_FILE_EXTENSIONS}
          />
          <div className={classes.captionText}>
            <Typography variant="body2" className={classes.italicText}>
              {LocalizedString.common.labelImageRatio1To1}
            </Typography>
          </div>
        </div>

        {useSendToFilter ? (
          <Field
            name={sendToFieldName}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.common.placeholderSendTo}
            label={LocalizedString.common.placeholderSendTo}
            disabled={loading}
            data={eventNotificationRecipients}
            value={sendToFieldDefaultValue}
            onOptionSelected={onSendToOptionSelected}
            required
          />
        ) : (
          <div>
            <Field
              name={RXFIELD_SEND_PERSONAL_NOTIFICATION_URL}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.common.placeholderUrl}
              label={LocalizedString.common.placeholderUrl}
              value={initialValues ? initialValues.url : ''}
              disabled={disabledUrlField}
            />

            {additionalUrlPlaceholder && (
            <div className={classes.captionText}>
              <Typography variant="body2" className={classes.italicText}>
                {LocalizedString.common.labelYouCanUsePlaceholdersFieldsAbove}
              </Typography>
              <div dangerouslySetInnerHTML={{ __html: additionalPlaceholderHtmlComponent }} />
            </div>
            )}
          </div>
        )}

        {useFilterSection && (
          <div>
            <SectionTitle title={LocalizedString.common.labelFilter} />

            {!useDefaultFilterSection ? (
              <Grid container>
                {filterColumns.map((x) => renderCustomFilterItem(x, loading, errorDateRange,
                  errorNumRange, onChangeDate, onChangeDropdown, onChangeNumber, onChangeSwitch,
                  setErrorDateRange, setErrorNumRange))}
              </Grid>
            ) : (
              <Grid container>
                {renderCheckboxAutoComplete(branches, downloadingBranches,
                  onChangeDropdown, onChangeBranchText, onBranchOptionSelected,
                  RXFIELD_SEND_PERSONAL_NOTIFICATION_BRANCH, LocalizedString.common.labelBranch,
                  LocalizedString.common.placeholderSearchBranch, classes)}

                <Grid container className={classes.gridContainer}>
                  <Grid item xs sm md>
                    {renderCheckboxAutoComplete(companies, downloadingCompanies,
                      onChangeDropdown, onChangeCompanyText, onCompanyOptionSelected,
                      RXFIELD_SEND_PERSONAL_NOTIFICATION_COMPANY_NAME,
                      LocalizedString.common.labelCompanyName,
                      LocalizedString.common.placeholderSearchCompany, classes)}
                  </Grid>
                  <Grid item xs sm md>
                    {renderCheckboxAutoComplete(divisions, downloadingDivisions,
                      onChangeDropdown, onChangeDivisionText, onDivisionOptionSelected,
                      RXFIELD_SEND_PERSONAL_NOTIFICATION_DIVISION,
                      LocalizedString.common.labelDivision,
                      LocalizedString.common.placeholderSearchDivision, classes)}
                  </Grid>
                  <Grid item xs sm md>
                    {renderCheckboxAutoComplete(departments, downloadingDepartments,
                      onChangeDropdown, onChangeDepartmentText, onDepartmentOptionSelected,
                      RXFIELD_SEND_PERSONAL_NOTIFICATION_DEPARTMENT,
                      LocalizedString.common.labelDepartment,
                      LocalizedString.common.placeholderSearchDepartment, classes)}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </div>
        )}

        {useFilterSection && (
        <Grid item className={classes.totalPersonCountContainer}>
          <div className={classes.totalPersonCountWrapper}>
            <Typography variant="h6" className={classes.boldText}>{totalPersonCount}</Typography>
            <Typography variant="subtitle2" className={classes.captionText}>{LocalizedString.common.labelPerson}</Typography>
          </div>

          <AccentButton
            variant="outlined"
            caption={LocalizedString.common.buttonCaptionCount}
            onClick={() => (!useDefaultFilterSection
              ? onCountTotalPersonPressed(getCustomFilterString(filterForm, setErrorDateRange,
                setErrorNumRange, sendToFieldName))
              : onCountPressed())}
            loading={downloadingTotalPersonCount}
            className={classes.outlinedButton}
          />
        </Grid>
        )}
      </DialogContent>

      <DialogActions className={classes.buttonContainer}>
        <AccentButton
          variant="text"
          caption={LocalizedString.common.buttonCaptionCancel}
          onClick={() => onCancelPress(onCancelPressed, onCancelSendNotificationDialogPressed,
            setErrorDateRange, setErrorNumRange, useDefaultFilterSection)}
          className={classes.redButton}
          disabled={sendingNotifications || loading}
        />

        <AccentButton
          caption={LocalizedString.common.buttonCaptionSend}
          onClick={handleSubmit((values) => (!useDefaultFilterSection
            ? onHandleSubmitCustomFilterForm(values, onCancelPressed,
              onCancelSendNotificationDialogPressed, onError, onSendNotificationPressed,
              onSendPressed, setErrorDateRange, setErrorNumRange, sendToFieldName)
            : onHandleSubmitDefaultForm(values, useFilterSection, onCancelPressed,
              onCancelSendNotificationDialogPressed, onError, onSendNotificationPressed,
              onSendPressed, setErrorDateRange, setErrorNumRange)))}
          loading={sendingNotifications || loading}
          disabled={isEmptyRequiredFields}
        />
      </DialogActions>
    </Dialog>
  );
};

export default reduxForm({
  form: RXFORM_SEND_PERSONAL_NOTIFICATION_MODAL,
  enableReinitialize: true,
  validate: rxformValidateSendPersonalNotification,
})(SendNotificationDialog);

SendNotificationDialog.propTypes = {
  initialValues: FormInitialValueShape,
  filterForm: FormInitialValueShape.isRequired,
  branches: PropTypes.arrayOf(SimpleDataShape).isRequired,
  companies: PropTypes.arrayOf(SimpleDataShape).isRequired,
  divisions: PropTypes.arrayOf(SimpleDataShape).isRequired,
  departments: PropTypes.arrayOf(SimpleDataShape).isRequired,
  filterColumns: PropTypes.arrayOf(TableColumnShape),
  disabledUrlField: PropTypes.bool,
  downloadingBranches: PropTypes.bool.isRequired,
  downloadingCompanies: PropTypes.bool.isRequired,
  downloadingDivisions: PropTypes.bool.isRequired,
  downloadingDepartments: PropTypes.bool.isRequired,
  downloadingTotalPersonCount: PropTypes.bool.isRequired,
  isEmptyRequiredFields: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  sendingNotifications: PropTypes.bool.isRequired,
  useFilterSection: PropTypes.bool,
  useSendToFilter: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onBranchOptionSelected: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onCancelSendNotificationDialogPressed: PropTypes.func,
  onChangeBranchText: PropTypes.func.isRequired,
  onChangeCompanyText: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onChangeDepartmentText: PropTypes.func.isRequired,
  onChangeDivisionText: PropTypes.func.isRequired,
  onChangeDropdown: PropTypes.func.isRequired,
  onChangeNumber: PropTypes.func.isRequired,
  onChangeSwitch: PropTypes.func.isRequired,
  onCompanyOptionSelected: PropTypes.func.isRequired,
  onCountPressed: PropTypes.func.isRequired,
  onCountTotalPersonPressed: PropTypes.func,
  onDepartmentOptionSelected: PropTypes.func.isRequired,
  onDivisionOptionSelected: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onSendNotificationPressed: PropTypes.func.isRequired,
  onSendPressed: PropTypes.func,
  onSendToOptionSelected: PropTypes.func,
  onImageSelected: PropTypes.func.isRequired,
  totalPersonCount: PropTypes.number.isRequired,
  additionalUrlPlaceholder: PropTypes.string,
  recipient: PropTypes.string,
  sendToFieldDefaultValue: PropTypes.string,
  sendToFieldName: PropTypes.string,
  sendingToLabel: PropTypes.string.isRequired,
};

SendNotificationDialog.defaultProps = {
  initialValues: {},
  disabledUrlField: false,
  useFilterSection: false,
  useSendToFilter: false,
  filterColumns: [],
  onCancelSendNotificationDialogPressed: () => {},
  onCountTotalPersonPressed: () => {},
  onSendPressed: () => {},
  onSendToOptionSelected: () => {},
  additionalUrlPlaceholder: null,
  recipient: null,
  sendToFieldDefaultValue: '',
  sendToFieldName: '',
};
