import { INITIAL_ORDER_BY_VOUCHER_TOKEN_ASSIGNMENTS } from '../../constant';
import {
  ADDING_EDITING_VOUCHER_TOKEN_ASSIGNMENT, DOWNLOADING_VOUCHER_TOKEN_ASSIGNMENTS,
  DOWNLOADING_VOUCHER_TOKEN_ASSIGNMENT, SET_VOUCHER_TOKEN_ASSIGNMENT_TAPPED_ID,
  SET_VOUCHER_TOKEN_ASSIGNMENT_SEARCH_TEXT, SET_VOUCHER_TOKEN_ASSIGNMENT_ORDER_BY,
  SET_VOUCHER_TOKEN_ASSIGNMENT_PAGE_SIZE, SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_VOUCHER,
  SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_ASSIGNMENT_MODE,
  SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_BYPASS_PAYMENT,
  SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_BYPASS_MAX_PURCHASE,
  SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_BYPASS_ACHIEVEMENT,
  SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_USER,
  SET_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_METHOD,
  SET_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_VALID_FROM,
  SET_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_VALID_UNTIL,
  SET_VOUCHER_TOKEN_ASSIGNMENT_COPYING_REDEMPTION_CODE,
  ENABLING_DISABLING_VOUCHER_TOKEN_ASSIGNMENT,
} from '../action';

const initialState = {
  addingEditing: false,
  downloading: false,
  downloadingDeleting: false,
  selectedPageSize: 20,
  copying: false,
  enablingDisabling: false,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_VOUCHER_TOKEN_ASSIGNMENTS,
  selectedVoucher: {},
  selectedAssignmentMode: '',
  selectedBypassPayment: {},
  selectedBypassMaxPurchase: {},
  selectedBypassAchievement: {},
  selectedAssignmentMethod: '',
  selectedAssignmentValidFrom: '',
  selectedAssignmentValidUntil: '',
  selectedUser: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADDING_EDITING_VOUCHER_TOKEN_ASSIGNMENT:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_VOUCHER_TOKEN_ASSIGNMENTS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_VOUCHER_TOKEN_ASSIGNMENT:
      return { ...state, downloadingDeleting: action.status };
    case SET_VOUCHER_TOKEN_ASSIGNMENT_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_VOUCHER_TOKEN_ASSIGNMENT_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_VOUCHER_TOKEN_ASSIGNMENT_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_VOUCHER_TOKEN_ASSIGNMENT_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_VOUCHER:
      return { ...state, selectedVoucher: action.option };
    case SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_ASSIGNMENT_MODE:
      return { ...state, selectedAssignmentMode: action.option };
    case SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_BYPASS_PAYMENT:
      return { ...state, selectedBypassPayment: action.option };
    case SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_BYPASS_MAX_PURCHASE:
      return { ...state, selectedBypassMaxPurchase: action.option };
    case SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_BYPASS_ACHIEVEMENT:
      return { ...state, selectedBypassAchievement: action.option };
    case SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_USER:
      return { ...state, selectedUser: action.option };
    case SET_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_METHOD:
      return { ...state, selectedAssignmentMethod: action.option };
    case SET_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_VALID_FROM:
      return { ...state, selectedAssignmentValidFrom: action.option };
    case SET_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_VALID_UNTIL:
      return { ...state, selectedAssignmentValidUntil: action.option };
    case SET_VOUCHER_TOKEN_ASSIGNMENT_COPYING_REDEMPTION_CODE:
      return { ...state, copying: action.status };
    case ENABLING_DISABLING_VOUCHER_TOKEN_ASSIGNMENT:
      return { ...state, enablingDisabling: action.status };
    default: return state;
  }
};
