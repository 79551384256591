import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress, Dialog, DialogContent, makeStyles,
} from '@material-ui/core';
import { COLOR_PRIMARY } from '../constant';

const useStyles = makeStyles(() => ({
  paper: {
    width: '300px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const LoadingDialog = ({ visible }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={visible}
      aria-labelledby="responsive-dialog-title"
      classes={{ paper: classes.paper }}
    >
      <DialogContent sx={{ alignSelf: 'center' }}>
        <CircularProgress sx={{ color: COLOR_PRIMARY }} size={36} />
      </DialogContent>
    </Dialog>
  );
};

LoadingDialog.propTypes = {
  visible: PropTypes.bool,
};

LoadingDialog.defaultProps = {
  visible: false,
};

export default LoadingDialog;
