import { ORDER_SORT_ASCENDING, ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_DISCUSSIONS = '/discus/api/v1/Discussion?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|name';
export const REST_URL_VIEW_DISCUSSION = '/discus/api/v1/Discussion/{id}';
export const REST_URL_EDIT_DISCUSSION = '/discus/api/v1/Discussion';
export const REST_URL_DISCUSSION_COMMENTS = '/discus/api/v1/Comment?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_REPORTED_COMMENTS = '/discus/api/v1/ReportedComment?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DISCUSSION_COMMENT = '/discus/api/v1/Comment/{id}';
export const REST_URL_VIEW_REPORTED_COMMENT = '/discus/api/v1/ReportedComment/{id}';

export const ROUTE_NAME_DISCUSSION_MENU = '/discussion-menu';
export const ROUTE_NAME_DISCUSSION = '/discussion';
export const ROUTE_NAME_DISCUSSION_DISCUSSION_COMMENT = '/discussion-comment';
export const ROUTE_NAME_DISCUSSION_REPORTED_COMMENT = '/reported-comment';

export const RXFORM_DISCUSSION = 'discussionPage';
export const RXFORM_DISCUSION_COMMENT = 'discussionCommentPage';
export const RXFORM_REPORTED_COMMENT = 'reportedCommentPage';

export const RXFIELD_DISCUSSION_NAME = 'name';
export const RXFIELD_DISCUSSION_DESCRIPTION = 'description';
export const RXFIELD_DISCUSSION_ALLOW_DIRECT_USER_COMMENT = 'allowDirectUserComment';
export const RXFIELD_DISCUSSION_ALLOW_PUBLIC_READ = 'allowPublicRead';
export const RXFIELD_DISCUSSION_REFERENCE_ID = 'referenceId';
export const RXFIELD_DISCUSSION_TAGS = 'tags';
export const RXFIELD_DISCUSSION_DISABLE_COMMENT = 'disableComment';
export const RXFIELD_DISCUSSION_COMMENT_COUNT = 'commentCount';
export const RXFIELD_DISCUSSION_COMMENT_REPLY_TO_ID = 'replyToId';
export const RXFIELD_DISCUSSION_COMMENT_REPLY_TO_COMMENT = 'replyToComment';
export const RXFIELD_DISCUSSION_COMMENT_DISCUSSION_ID = 'discussionId';
export const RXFIELD_DISCUSSION_COMMENT_DISCUSSION_NAME = 'discussionName';
export const RXFIELD_DISCUSSION_COMMENT_COMMENT = 'comment';
export const RXFIELD_DISCUSSION_COMMENT_REACTION_ID = 'reactionId';
export const RXFIELD_DISCUSSION_COMMENT_REPLY_COUNT = 'replyCount';
export const RXFIELD_DISCUSSION_COMMENT_USER_ID = 'userId';
export const RXFIELD_DISCUSSION_COMMENT_USERNAME = 'fullName';
export const RXFIELD_DISCUSSION_COMMENT_DISCUSSION = 'discussion';
export const RXFIELD_REPORTED_COMMENT_USER_ID = 'userId';
export const RXFIELD_REPORTED_COMMENT_USERNAME = 'fullName';
export const RXFIELD_REPORTED_COMMENT_REASON = 'reason';
export const RXFIELD_REPORTED_COMMENT_HANDLED = 'handled';
export const RXFIELD_REPORTED_COMMENT_DISCUSSION = 'discussion';
export const RXFIELD_REPORTED_COMMENT_DISCUSSION_ID = 'discussionId';
export const RXFIELD_REPORTED_COMMENT_DISCUSSION_TITLE = 'discussionName';
export const RXFIELD_REPORTED_COMMENT_COMMENT_ID = 'commentId';
export const RXFIELD_REPORTED_COMMENT_COMMENT = 'comment';

export const RXSTATE_DISCUSSIONS = 'discussions';
export const RXSTATE_DISCUSSION_PAGE = 'uiDiscussion';
export const RXSTATE_DISCUSSION_COMMENTS = 'discussionComments';
export const RXSTATE_DISCUSSION_COMMENT_PAGE = 'uiDiscussionComment';
export const RXSTATE_REPORTED_COMMENTS = 'reportedComments';
export const RXSTATE_REPORTED_COMMENT_PAGE = 'uiReportedComment';

export const INITIAL_ORDER_BY_DISCUSSIONS = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_DISCUSSION_COMMENT = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_REPORTED_COMMENT = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_DISCUSSION = 'MENUID_DISCUSSION';
export const MENUID_DISCUSSION_DISCUSSION = 'MENUID_DISCUSSION_DISCUSSION';
export const MENUID_DISCUSSION_DISCUSSION_COMMENT = 'MENUID_DISCUSSION_DISCUSSION_COMMENT';
export const MENUID_DISCUSSION_REPORTED_COMMENT = 'MENUID_DISCUSSION_REPORTED_COMMENT';
