import * as Action from '../action';

const initialAuthentication = {
  token: '',
  fcmToken: '',
};

export default (state = initialAuthentication, action) => {
  switch (action.type) {
    case Action.ADD_TOKEN:
      return { ...state, token: action.token };
    case Action.REMOVE_TOKEN:
      return initialAuthentication;
    case Action.ADD_FCM_TOKEN:
      return { ...state, fcmToken: action.token };
    default: return state;
  }
};
