import { reset } from 'redux-form';
import { RXFORM_EVENT_INVITATION } from '../../../constant';
import { addEventInvitation } from '../../../helper';
import { addingEventInvitation, clearEventInvitations, setAlertErrorMessage } from '../simple-action';
import downloadEventInvitationsAsync from './downloadEventInvitationsAsync';


export default () => async (dispatch, getState) => {
  try {
    dispatch(addingEventInvitation(true));

    const { token } = getState().authentication;
    const { eventId, recipient } = getState().uiEventInvitation;
    const emails = recipient.reduce((acc, obj) => {
      acc.push(obj.value);
      return acc;
    }, []);

    await addEventInvitation(eventId, emails, token);

    dispatch(reset(RXFORM_EVENT_INVITATION));
    dispatch(clearEventInvitations());
    dispatch(downloadEventInvitationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEventInvitation(false));
  }
};
