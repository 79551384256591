import { INITIAL_ORDER_BY_SESSIONS } from '../../constant';
import {
  ADDING_EDITING_SESSION, DOWNLOADING_DELETING_SESSION, DOWNLOADING_SESSIONS,
  COPYING_SESSION_QR_CODE, COPYING_SESSION_DEEP_LINK, DOWNLOADING_SESSION_ROOMS,
  ENABLING_DISABLING_SESSION, SET_SESSION_SELECTED_ENABLE_COMMENT,
  SET_SESSION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_SESSION_SEARCH_TEXT, SET_SESSION_SELECTED_ORDER_BY,
  SET_SESSION_SELECTED_PAGE_SIZE, SET_SESSION_TAPPED_ID,
  SET_SESSIONS_SELECTED_ADD_MEDIA_MENU, SET_SESSION_SELECTED_VOUCHER,
  SET_SESSION_SELECTED_CAN_PAY_POINT, SET_SESSION_SELECTED_CATEGORY_NAME,
  SET_SESSION_SELECTED_ROOM, SET_SESSION_SELECTED_EVENT_NAME,
  SET_SESSION_SELECTED_FEATURED_OPTION, SET_SESSION_SELECTED_NEED_APPROVAL,
  SET_SESSION_SELECTED_REWARD_TYPE, SET_SESSION_SELECTED_VISIBILITY_STATUS,
  SET_SESSION_SELECTED_WHO_CAN_REGISTER, SET_SESSION_SELECTED_CLOCK_INSIDE_AREA,
  SET_SESSION_TABLE_DATA_SPEAKERS, ADDING_SESSION_SPEAKERS,
  SET_SESSION_SELECTED_SPEAKERS, SET_SESSION_SELECTED_TYPE,
  SET_SESSION_SELECTED_SURVEY_FORM, SENDING_SESSION_NOTIFICATION,
  SET_SESSION_BANNER_IMAGE,
} from '../action/simple-action';

const initialState = {
  addingSessionSpeaker: false,
  addingEditing: false,
  copying: false,
  copyingDeepLink: false,
  downloadingDeleting: false,
  downloading: false,
  downloadingSessionRooms: false,
  enablingDisabling: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_SESSIONS,
  filterString: '',
  selectedAddMediaMenu: '',
  selectedCategory: '',
  selectedCanPayPoint: '',
  selectedVoucher: [],
  selectedRoom: '',
  selectedEvent: '',
  selectedFeaturedEvent: '',
  selectedNeedApproval: '',
  selectedRewardType: '',
  selectedSpeakers: null,
  selectedType: '',
  selectedVisibilityStatus: '',
  selectedWhoCanRegister: '',
  selectedClockInsideArea: '',
  selectedSurveyForm: '',
  clearImage: '',
  tableDataSpeakers: [],
  selectedEnableComment: '',
  sendingNotification: false,
  selectedBannerImage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_SESSIONS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_SESSION:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_SESSION:
      return { ...state, downloadingDeleting: action.status };
    case SET_SESSION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_SESSION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_SESSION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_SESSION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_SESSION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case ENABLING_DISABLING_SESSION:
      return { ...state, enablingDisabling: action.status };
    case SET_SESSIONS_SELECTED_ADD_MEDIA_MENU:
      return { ...state, selectedAddMediaMenu: action.text };
    case SET_SESSION_SELECTED_VOUCHER:
      return { ...state, selectedVoucher: action.option };
    case SET_SESSION_SELECTED_CAN_PAY_POINT:
      return { ...state, selectedCanPayPoint: action.option };
    case SET_SESSION_SELECTED_CATEGORY_NAME:
      return { ...state, selectedCategory: action.option };
    case SET_SESSION_SELECTED_ROOM:
      return { ...state, selectedRoom: action.option };
    case SET_SESSION_SELECTED_EVENT_NAME:
      return { ...state, selectedEvent: action.option };
    case SET_SESSION_SELECTED_FEATURED_OPTION:
      return { ...state, selectedFeaturedEvent: action.option };
    case SET_SESSION_SELECTED_NEED_APPROVAL:
      return { ...state, selectedNeedApproval: action.option };
    case SET_SESSION_SELECTED_REWARD_TYPE:
      return { ...state, selectedRewardType: action.option };
    case SET_SESSION_SELECTED_VISIBILITY_STATUS:
      return { ...state, selectedVisibilityStatus: action.option };
    case SET_SESSION_SELECTED_WHO_CAN_REGISTER:
      return { ...state, selectedWhoCanRegister: action.option };
    case SET_SESSION_SELECTED_CLOCK_INSIDE_AREA:
      return { ...state, selectedClockInsideArea: action.option };
    case SET_SESSION_TABLE_DATA_SPEAKERS:
      return { ...state, tableDataSpeakers: action.option };
    case ADDING_SESSION_SPEAKERS:
      return { ...state, addingSessionSpeaker: action.status };
    case SET_SESSION_SELECTED_SPEAKERS:
      return { ...state, selectedSpeakers: action.option };
    case SET_SESSION_SELECTED_TYPE:
      return { ...state, selectedType: action.option };
    case SET_SESSION_SELECTED_SURVEY_FORM:
      return { ...state, selectedSurveyForm: action.option };
    case SET_SESSION_SELECTED_ENABLE_COMMENT:
      return { ...state, selectedEnableComment: action.option };
    case COPYING_SESSION_QR_CODE:
      return { ...state, copying: action.status };
    case COPYING_SESSION_DEEP_LINK:
      return { ...state, copyingDeepLink: action.status };
    case SENDING_SESSION_NOTIFICATION:
      return { ...state, sendingNotification: action.status };
    case SET_SESSION_BANNER_IMAGE:
      return { ...state, selectedBannerImage: action.image };
    case DOWNLOADING_SESSION_ROOMS:
      return { ...state, downloadingSessionRooms: action.status };
    default: return state;
  }
};
