import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  clearRoles, clearTokens, clearUsers, setActiveSideMenuItem, setAlertConfirmationMessage,
  setAlertErrorMessage, setRoleSearchText, setTokenSearchText, setTokenSelectedOrderBy,
  setTokenSelectedPageSize, setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText,
  setUserSelectedPageSize, setUserSelectedRole, setUserSelectedOrderBy, setUserTappedId,
  disablingTokenAsync, downloadRolesAsync, downloadTokensAsync, downloadUsersAsync,
  downloadUserAsync, editUserAsync, resyncUserAsync, downloadReferrersAsync, clearReferrers,
  setReferrerSearchText, enableDisableUserAsync,
} from '../../redux/action';
import {
  transformInitialValues, toMoment, transformUserDropdownData, getPermission,
} from '../../helper';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_USERS, MENUID_AUTHENTICATION_USER,
  PAGE_MODE_TABLE, RXFIELD_USER_ACTIVE_SESSION, RXFIELD_USER_ROLES, RXFORM_USER,
} from '../../constant';
import UserPage from './user.presentation';
import LocalizedString from '../../localization';

const getInitialValues = (state) => {
  const {
    tokens, users, uiFunctionalPage, uiToken, uiUser,
  } = state;
  const { data } = users;
  const { meta } = tokens;
  const { downloading, selectedPageSize } = uiToken;
  const { tappedId, downloadingDeleting } = uiUser;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE
    ? data[tappedId] : {};
  const initVal = Object.keys(found).length > 0 && !downloadingDeleting
    ? transformInitialValues(found, {
      activeSessions: Object.values(tokens.data).length > 0 && !downloading
        ? Object.values(tokens.data).map((x, i) => ({
          ...x,
          no: i + 1 + ((meta.currentPage - 1) * selectedPageSize),
          validUntil: toMoment(x.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
          lastActivity: toMoment(x.lastActivity).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
          device: `${x.deviceManufacturer} ${x.deviceModel}`,
          os: `${x.osName} ${x.osVersion}`,
          appVersion: x.appVersion,
        })) : [],
    }) : { roles: [], activeSessions: [] };
  return initVal;
};

const mapStateToProps = (state) => ({
  roles: Object.values(state.roles.data).map((x) => x.name),
  referrers: transformUserDropdownData(state.referrers.data),
  activeSessionPageSize: state.tokens.meta.pageSize,
  totalCurrentPage: state.tokens.meta.currentPage,
  tokenTotalCount: state.tokens.meta.totalCount,
  pageMode: state.uiFunctionalPage.pageMode,
  loadingRole: state.uiRole.downloading,
  loadingToken: state.uiToken.downloading,
  addingEditing: state.uiUser.addingEditing,
  downloading: state.uiUser.downloading,
  downloadingReferrer: state.uiUser.downloadingReferrers,
  resyncing: state.uiUser.resyncing,
  tappedId: state.uiFunctionalPage.tappedId || '',
  initialValues: getInitialValues(state),
  isResyncAllowed: getPermission(state, 'AUTHEXT_USER_SYNC'),
  enablingDisabling: state.uiUser.enablingDisabling,
  currentUserStatus: state.users.data[state.uiFunctionalPage.tappedId]
    ? state.users.data[state.uiFunctionalPage.tappedId].status : '',
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(clearReferrers());
    dispatch(downloadReferrersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_AUTHENTICATION_USER));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(setUserSelectedPageSize(20));
    dispatch(setUserSelectedOrderBy(INITIAL_ORDER_BY_USERS));
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/referrer/, 'referrer.id');
    dispatch(setUserAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_USER));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadUsersAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setUserSelectedPageSize(pageSize));
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeReffererText: async (text) => {
    try {
      dispatch(setReferrerSearchText(text));
      dispatch(clearReferrers());
      await dispatch(downloadReferrersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case LocalizedString.common.msgResyncConfirmation:
        dispatch(resyncUserAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableUserAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setUserTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onDeleteActiveSessionPressed: (data, selectedData) => {
    dispatch(disablingTokenAsync(selectedData.id, 'user'))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(change(RXFORM_USER, RXFIELD_USER_ACTIVE_SESSION, data));
  },
  onEditPressed: (id) => {
    dispatch(setUserTappedId(id));
    dispatch(setRoleSearchText(''));
    dispatch(setTokenSearchText(''));
    dispatch(clearTokens());
    dispatch(clearRoles());
    dispatch(setTokenSelectedPageSize(20));
    dispatch(setTokenSelectedOrderBy('lastActivity desc'));
    dispatch(downloadTokensAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadRolesAsync(1, true))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
    dispatch(downloadUserAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setUserSelectedPageSize(pageSize));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearUsers());
    dispatch(setUserSelectedOrderBy(INITIAL_ORDER_BY_USERS));
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRoleSelected: (option) => {
    if (option.length > 0) {
      dispatch(setUserSelectedRole(option));
      dispatch(change(RXFORM_USER, RXFIELD_USER_ROLES, option));
    } else {
      dispatch(setUserSelectedRole([]));
      dispatch(change(RXFORM_USER, RXFIELD_USER_ROLES, []));
    }
  },
  onSavePressed: async () => {
    try {
      await dispatch(editUserAsync());
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setUserSelectedOrderBy(orderBy));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setUserTappedId(id));
    dispatch(setTokenSearchText(''));
    dispatch(setTokenSearchText(''));
    dispatch(clearTokens());
    dispatch(setTokenSelectedPageSize(20));
    dispatch(setTokenSelectedOrderBy('lastActivity desc'));
    dispatch(downloadTokensAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadUserAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);
