import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  RXFORM_POPUP_BANNER, RXSTATE_POPUP_BANNERS, RXSTATE_POPUP_BANNER_PAGE,
  PAGE_MODE_VIEW, RXFIELD_POPUP_BANNER_TITLE, RXFIELD_POPUP_BANNER_DESCRIPTION,
  RXFIELD_POPUP_BANNER_URL, RXFIELD_POPUP_BANNER_ORDER, RXFIELD_POPUP_BANNER_VALID_UNTIL,
  RXFIELD_POPUP_BANNER_VALID_FROM, RXFIELD_POPUP_BANNER_IMAGE, FILTER_TYPE_DATE_RANGE,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, DATE_TIME_FORMAT_WITHOUT_PIPE,
  IMAGE_FILE_EXTENSIONS,
} from '../../constant';
import {
  renderReduxFormOutlinedTextField,
  renderReduxFormDropzonePicker,
  renderReduxFormDateTimePickerField,
} from '../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidatePopUpBanner } from '../../validation';
import { FormInitialValueShape } from '../../type';
import { toMoment } from '../../helper';

const renderDialogContent = (
  initialValues, addingEditing, pageMode, downloadingDeleting,
  onImageSelected,
) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_POPUP_BANNER_TITLE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.popUpBannerScreen.placeholderTitle}
            label={LocalizedString.popUpBannerScreen.placeholderTitle}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POPUP_BANNER_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.popUpBannerScreen.placeholderDescription}
            label={LocalizedString.popUpBannerScreen.placeholderDescription}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POPUP_BANNER_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.popUpBannerScreen.placeholderUrl}
            label={LocalizedString.popUpBannerScreen.placeholderUrl}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POPUP_BANNER_ORDER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.popUpBannerScreen.placeholderOrder}
            label={LocalizedString.popUpBannerScreen.placeholderOrder}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            type="number"
            required
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_POPUP_BANNER_VALID_FROM}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.popUpBannerScreen.placeholderValidFrom}
            pickerMode={PICKER_MODE_DATE_TIME}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POPUP_BANNER_VALID_UNTIL}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.popUpBannerScreen.placeholderValidUntil}
            pickerMode={PICKER_MODE_DATE_TIME}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POPUP_BANNER_IMAGE}
            component={renderReduxFormDropzonePicker}
            label={LocalizedString.popUpBannerScreen.placeholderImage}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            onImageSelected={onImageSelected}
            onBlur={(e) => e.preventDefault()}
            defaultValue={initialValues.image}
            imageFileExtension={IMAGE_FILE_EXTENSIONS}
            loading={addingEditing || downloadingDeleting}
            required
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const PopupBannerPage = ({
  addingEditing, downloading, handleSubmit, onAppear, onCancelPressed,
  onChangePage, onChangePageSize, onConfirmDeletePressed, onCreatePressed,
  onDeletePressed, onRefresh, onSavePressed, onSearchBarTextChanged,
  onSortPressed, onSubmitPressed, onViewPressed, pageMode, onEditPressed,
  downloadingDeleting, initialValues, onImageSelected,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_POPUP_BANNERS}
    uiPage={RXSTATE_POPUP_BANNER_PAGE}
    tableColumns={[
      {
        title: LocalizedString.popUpBannerScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.popUpBannerScreen.labelTitle, field: 'title', sorting: !downloading },
      { title: LocalizedString.popUpBannerScreen.labelOrder, field: 'order', sorting: !downloading },
      {
        title: LocalizedString.popUpBannerScreen.labelValidFrom,
        field: 'validFrom',
        render: ({ validFrom }) => (validFrom
          ? toMoment(validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        sorting: !downloading,
      },
      {
        title: LocalizedString.popUpBannerScreen.labelValidUntil,
        field: 'validUntil',
        render: ({ validUntil }) => (validUntil
          ? toMoment(validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        sorting: !downloading,
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.popUpBannerScreen.placeholderValidFrom,
        field: RXFIELD_POPUP_BANNER_VALID_FROM,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.popUpBannerScreen.placeholderValidUntil,
        field: RXFIELD_POPUP_BANNER_VALID_UNTIL,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.popUpBannerScreen.buttonCaptionCreateNewPopUpBanner}
    deleteButtonCaption={LocalizedString.popUpBannerScreen.buttonCaptionDeletePopUpBanner}
    title={LocalizedString.popUpBannerScreen.title}
    editButtonCaption={LocalizedString.popUpBannerScreen.buttonCaptionEditPopUpBanner}
    useFullWidth
    createPermissionName="CORE_CREATE_POPUP_BANNER"
    deletePermissionName="CORE_DELETE_POPUP_BANNER"
    editPermissionName="CORE_EDIT_POPUP_BANNER"
  >
    {renderDialogContent(
      initialValues, addingEditing, pageMode, downloadingDeleting,
      onImageSelected,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_POPUP_BANNER,
  validate: rxformValidatePopUpBanner,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(PopupBannerPage);

PopupBannerPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,

  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onImageSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,

  pageMode: PropTypes.string.isRequired,
};
