import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_FORMS = 'DOWNLOADING_FORMS';
export const downloadingForms = makeActionCreator(DOWNLOADING_FORMS, 'status');

export const SET_FORMS = 'SET_FORMS';
export const setForms = makeActionCreator(SET_FORMS, 'data');

export const ADD_FORM = 'ADD_FORM';
export const addForm = makeActionCreator(ADD_FORM, 'data');

export const CLEAR_FORMS = 'CLEAR_FORMS';
export const clearForms = makeActionCreator(CLEAR_FORMS);

export const ADDING_EDITING_FORM = 'ADDING_EDITING_FORM';
export const addingEditingForm = makeActionCreator(ADDING_EDITING_FORM, 'status');

export const DOWNLOADING_DELETING_FORM = 'DOWNLOADING_DELETING_FORM';
export const downloadingDeletingForm = makeActionCreator(DOWNLOADING_DELETING_FORM, 'status');

export const ENABLING_DISABLING_FORM = 'ENABLING_DISABLING_FORM';
export const enablingDisablingForm = makeActionCreator(ENABLING_DISABLING_FORM, 'status');

export const SET_FORM_SELECTED_PAGE_SIZE = 'SET_FORM_SELECTED_PAGE_SIZE';
export const setFormSelectedPageSize = makeActionCreator(SET_FORM_SELECTED_PAGE_SIZE, 'size');

export const SET_FORM_SELECTED_ORDER_BY = 'SET_FORM_SELECTED_ORDER_BY';
export const setFormSelectedOrderBy = makeActionCreator(SET_FORM_SELECTED_ORDER_BY, 'order');

export const SET_FORM_TAPPED_ID = 'SET_FORM_TAPPED_ID';
export const setFormTappedId = makeActionCreator(SET_FORM_TAPPED_ID, 'id');

export const SET_FORM_SEARCH_TEXT = 'SET_FORM_SEARCH_TEXT';
export const setFormSearchText = makeActionCreator(SET_FORM_SEARCH_TEXT, 'text');

export const SET_FORM_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_FORM_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setFormAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_FORM_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_FORM_SELECTED_VISIBILITY_STATUS = 'SET_FORM_SELECTED_VISIBILITY_STATUS';
export const setFormSelectedVisibilityStatus = makeActionCreator(SET_FORM_SELECTED_VISIBILITY_STATUS, 'value');

export const SET_FORM_SELECTED_ALLOW_DIRECT_RESPONSE = 'SET_FORM_SELECTED_ALLOW_DIRECT_RESPONSE';
export const setFormSelectedAllowDirectResponse = makeActionCreator(SET_FORM_SELECTED_ALLOW_DIRECT_RESPONSE, 'value');

export const SET_FORM_SELECTED_CLOSE_RESPONSE = 'SET_FORM_SELECTED_CLOSE_RESPONSE';
export const setFormSelectedCloseResponse = makeActionCreator(SET_FORM_SELECTED_CLOSE_RESPONSE, 'value');

export const SET_FORM_SELECTED_DETECT_DUPLICATED_RESPONSE = 'SET_FORM_SELECTED_DETECT_DUPLICATED_RESPONSE';
export const setFormSelectedDetectDuplicatedResponse = makeActionCreator(SET_FORM_SELECTED_DETECT_DUPLICATED_RESPONSE, 'value');

export const DUPLICATING_FORM = 'DUPLICATING_FORM';
export const duplicatingForm = makeActionCreator(DUPLICATING_FORM, 'status');

export const SET_FORM_DUPLICATE_DIALOG_VISIBILITY = 'SET_FORM_DUPLICATE_DIALOG_VISIBILITY';
export const setFormDuplicateDialogVisibility = makeActionCreator(SET_FORM_DUPLICATE_DIALOG_VISIBILITY, 'status');


export const DOWNLOADING_FIELDS = 'DOWNLOADING_FIELDS';
export const downloadingFields = makeActionCreator(DOWNLOADING_FIELDS, 'status');

export const SET_FIELDS = 'SET_FIELDS';
export const setFields = makeActionCreator(SET_FIELDS, 'data');

export const ADD_FIELD = 'ADD_FIELD';
export const addField = makeActionCreator(ADD_FIELD, 'data');

export const CLEAR_FIELDS = 'CLEAR_FIELDS';
export const clearFields = makeActionCreator(CLEAR_FIELDS);

export const ADDING_EDITING_FIELD = 'ADDING_EDITING_FIELD';
export const addingEditingField = makeActionCreator(ADDING_EDITING_FIELD, 'status');

export const DOWNLOADING_DELETING_FIELD = 'DOWNLOADING_DELETING_FIELD';
export const downloadingDeletingField = makeActionCreator(DOWNLOADING_DELETING_FIELD, 'status');

export const ENABLING_DISABLING_FIELD = 'ENABLING_DISABLING_FIELD';
export const enablingDisablingField = makeActionCreator(ENABLING_DISABLING_FIELD, 'status');

export const DOWNLOADING_FIELD_PARENTS = 'DOWNLOADING_FIELD_PARENTS';
export const downloadingFieldParents = makeActionCreator(DOWNLOADING_FIELD_PARENTS, 'status');

export const SET_FIELD_PARENTS = 'SET_FIELD_PARENTS';
export const setFieldParents = makeActionCreator(SET_FIELD_PARENTS, 'data');

export const CLEAR_FIELD_PARENTS = 'CLEAR_FIELD_PARENTS';
export const clearFieldParents = makeActionCreator(CLEAR_FIELD_PARENTS);


export const SET_FIELD_SELECTED_PAGE_SIZE = 'SET_FIELD_SELECTED_PAGE_SIZE';
export const setFieldSelectedPageSize = makeActionCreator(SET_FIELD_SELECTED_PAGE_SIZE, 'size');

export const SET_FIELD_SELECTED_ORDER_BY = 'SET_FIELD_SELECTED_ORDER_BY';
export const setFieldSelectedOrderBy = makeActionCreator(SET_FIELD_SELECTED_ORDER_BY, 'order');

export const SET_FIELD_TAPPED_ID = 'SET_FIELD_TAPPED_ID';
export const setFieldTappedId = makeActionCreator(SET_FIELD_TAPPED_ID, 'id');

export const SET_FIELD_SEARCH_TEXT = 'SET_FIELD_SEARCH_TEXT';
export const setFieldSearchText = makeActionCreator(SET_FIELD_SEARCH_TEXT, 'text');

export const SET_FIELD_PARENT_SEARCH_TEXT = 'SET_FIELD_PARENT_SEARCH_TEXT';
export const setFieldParentSearchText = makeActionCreator(SET_FIELD_PARENT_SEARCH_TEXT, 'text');

export const SET_FIELD_PARENT_FILTER_STRING = 'SET_FIELD_PARENT_FILTER_STRING';
export const setFieldParentFilterString = makeActionCreator(SET_FIELD_PARENT_FILTER_STRING, 'text');

export const SET_FIELD_SELECTED_FIELD_TYPE = 'SET_FIELD_SELECTED_FIELD_TYPE';
export const setFieldSelectedFieldType = makeActionCreator(SET_FIELD_SELECTED_FIELD_TYPE, 'option');

export const SET_FIELD_SELECTED_OPTION_MODE = 'SET_FIELD_SELECTED_OPTION_MODE';
export const setFieldSelectedOptionMode = makeActionCreator(SET_FIELD_SELECTED_OPTION_MODE, 'option');

export const SET_FIELD_SELECTED_OPTION_VALUE_SEPARATOR = 'SET_FIELD_SELECTED_OPTION_VALUE_SEPARATOR';
export const setFieldSelectedOptionValueSeparator = makeActionCreator(SET_FIELD_SELECTED_OPTION_VALUE_SEPARATOR, 'option');

export const SET_FIELD_SELECTED_PARENT = 'SET_FIELD_SELECTED_PARENT';
export const setFieldSelectedParent = makeActionCreator(SET_FIELD_SELECTED_PARENT, 'option');

export const SET_FIELD_SELECTED_PICKER_MODE = 'SET_FIELD_SELECTED_PICKER_MODE';
export const setFieldSelectedPickerMode = makeActionCreator(SET_FIELD_SELECTED_PICKER_MODE, 'option');

export const SET_FIELD_SELECTED_TEXT_MULTILINE = 'SET_FIELD_SELECTED_TEXT_MULTILINE';
export const setFieldSelectedTextMultiline = makeActionCreator(SET_FIELD_SELECTED_TEXT_MULTILINE, 'option');

export const SET_FIELD_SELECTED_TEXT_KEYBOARD_TYPE = 'SET_FIELD_SELECTED_TEXT_KEYBOARD_TYPE';
export const setFieldSelectedTextKeyboardType = makeActionCreator(SET_FIELD_SELECTED_TEXT_KEYBOARD_TYPE, 'option');

export const SET_FIELD_SELECTED_LOGICAL_OPERATOR = 'SET_FIELD_SELECTED_LOGICAL_OPERATOR';
export const setFieldSelectedLogicalOperator = makeActionCreator(SET_FIELD_SELECTED_LOGICAL_OPERATOR, 'option');

export const SET_FIELD_SELECTED_MUST_USE_CAMERA = 'SET_FIELD_SELECTED_MUST_USE_CAMERA';
export const setFieldSelectedMustUseCamera = makeActionCreator(SET_FIELD_SELECTED_MUST_USE_CAMERA, 'option');

export const SET_FIELD_SELECTED_FORM = 'SET_FIELD_SELECTED_FORM';
export const setFieldSelectedForm = makeActionCreator(SET_FIELD_SELECTED_FORM, 'option');


export const SET_PREVIEW_SELECTED_FORM = 'SET_PREVIEW_SELECTED_FORM';
export const setPreviewSelectedForm = makeActionCreator(SET_PREVIEW_SELECTED_FORM, 'form');

export const DOWNLOADING_PREVIEW_SELECTED_FORM = 'DOWNLOADING_PREVIEW_SELECTED_FORM';
export const downloadingPreviewSelectedForm = makeActionCreator(DOWNLOADING_PREVIEW_SELECTED_FORM, 'status');

export const SET_PREVIEW_FORM_FIELD_VALUE = 'SET_PREVIEW_FORM_FIELD_VALUE';
export const setPreviewFormFieldValue = makeActionCreator(SET_PREVIEW_FORM_FIELD_VALUE, 'value');

export const SET_UPLOADING_PREVIEW_FORM_RESPONSE = 'SET_UPLOADING_PREVIEW_FORM_RESPONSE';
export const setUploadingPreviewFormResponse = makeActionCreator(SET_UPLOADING_PREVIEW_FORM_RESPONSE, 'status');


export const DOWNLOADING_RESPONSES = 'DOWNLOADING_RESPONSES';
export const downloadingResponses = makeActionCreator(DOWNLOADING_RESPONSES, 'status');

export const SET_RESPONSES = 'SET_RESPONSES';
export const setResponses = makeActionCreator(SET_RESPONSES, 'data');

export const ADD_RESPONSE = 'ADD_RESPONSE';
export const addResponse = makeActionCreator(ADD_RESPONSE, 'data');

export const CLEAR_RESPONSES = 'CLEAR_RESPONSES';
export const clearResponses = makeActionCreator(CLEAR_RESPONSES);

export const DOWNLOADING_DELETING_RESPONSE = 'DOWNLOADING_DELETING_RESPONSE';
export const downloadingDeletingResponse = makeActionCreator(DOWNLOADING_DELETING_RESPONSE, 'status');

export const SET_RESPONSE_SELECTED_PAGE_SIZE = 'SET_RESPONSE_SELECTED_PAGE_SIZE';
export const setResponseSelectedPageSize = makeActionCreator(SET_RESPONSE_SELECTED_PAGE_SIZE, 'size');

export const SET_RESPONSE_SELECTED_ORDER_BY = 'SET_RESPONSE_SELECTED_ORDER_BY';
export const setResponseSelectedOrderBy = makeActionCreator(SET_RESPONSE_SELECTED_ORDER_BY, 'order');

export const SET_RESPONSE_TAPPED_ID = 'SET_RESPONSE_TAPPED_ID';
export const setResponseTappedId = makeActionCreator(SET_RESPONSE_TAPPED_ID, 'id');

export const SET_RESPONSE_SEARCH_TEXT = 'SET_RESPONSE_SEARCH_TEXT';
export const setResponseSearchText = makeActionCreator(SET_RESPONSE_SEARCH_TEXT, 'text');

export const SET_RESPONSE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_RESPONSE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setResponseAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_RESPONSE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');
