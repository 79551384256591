import { connect } from 'react-redux';
import {
  clearEmployees, setHistoryEmployeeSearchText, setHistorySelectedCompany,
  setHistorySelectedDepartment, setHistorySelectedDivision,
  downloadEmployeesAsync,
} from '../../../attendance/redux/action';
import {
  INITIAL_ORDER_BY_ACTIVITIES, MENUID_ACTIVITY_MANAGEMENT_ACTIVITY, PAGE_MODE_TABLE,
  SIMPLE_DATE_FORMAT, TIME_FORMAT,
} from '../../constant';
import {
  isDecimal, transformDropdownData, transformInitialValues, toMoment, transformUserDropdownData,
} from '../../helper';
import {
  clearActivities, clearActivityTypes, clearCustomers, clearCompanies, clearDepartments,
  clearDivisions, setActiveSideMenuItem, setAdvancedFilterDialogSelectedFilterString,
  setAlertErrorMessage, setActivitySearchText, setActivitySelectedPageSize,
  setActivitySelectedActivityType, setActivitySelectedCustomer, setActivitySelectedEmployee,
  setActivitySelectedOrderBy, setActivityTappedId, setActivityTypeSearchText, setCompanySearchText,
  setCustomerSearchText, setDepartmentSearchText, setDivisionSearchText,
  downloadActivityAsync, downloadActivityTypesAsync, downloadActivitiesAsync,
  downloadCustomersAsync, saveActivitiesAsync, setCustomerAdvancedFilterDialogSelectedFilterString,
  setActivityTypeAdvancedFilterDialogSelectedFilterString,
} from '../../redux/action';
import { downloadCompaniesAsync, downloadDepartmentsAsync, downloadDivisionsAsync } from '../../../../redux/action';
import ActivityPage from './history.presentation';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getInitialValues = (state) => {
  const { activities, uiActivity, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiActivity;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? activities.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      profile: found.profile ? found.profile.fullName : '',
      manager: found.profile.manager ? found.profile.manager.fullName : '',
      customer: found.customer ? found.customer.name : '',
      activityType: found.activityType ? found.activityType.name : '',
      attendanceDate: found.startTime ? toMoment(found.startTime).format(SIMPLE_DATE_FORMAT) : '',
      startTime: found.startTime ? toMoment(found.startTime).format(TIME_FORMAT) : '',
      endTime: found.endTime ? toMoment(found.endTime).format(TIME_FORMAT) : '',
      clockInCenter: found.startLatitude && found.startLongitude
        && isDecimal(found.startLatitude)
        && isDecimal(found.startLongitude) ? {
          lat: found.startLatitude,
          lng: found.startLongitude,
        } : null,
      clockInMarker: found.startLatitude && found.startLongitude
        && isDecimal(found.startLatitude)
        && isDecimal(found.startLongitude) ? {
          lat: found.startLatitude,
          lng: found.startLongitude,
        } : null,
      clockOutCenter: found.endLatitude && found.endLongitude
        && isDecimal(found.endLatitude)
        && isDecimal(found.endLongitude) ? {
          lat: found.endLatitude,
          lng: found.endLongitude,
        } : null,
      clockOutMarker: found.endLatitude && found.endLongitude
        && isDecimal(found.endLatitude)
        && isDecimal(found.endLongitude) ? {
          lat: found.endLatitude,
          lng: found.endLongitude,
        } : null,
    }) : {
      clockInCenter: null,
      clockInMarker: null,
      clockOutCenter: null,
      clockOutMarker: null,
    };
  return initVal;
};

const transformMultipleDropdownValue = (data) => Object
  .values(data).map((item) => item.fullName || item.name);

const mapStateToProps = (state) => ({
  activityTypes: transformDropdownData(state.activityTypes.data),
  companies: transformMultipleDropdownValue(state.companies.data),
  customers: transformDropdownData(state.customers.data),
  departments: transformMultipleDropdownValue(state.departments.data),
  divisions: transformMultipleDropdownValue(state.divisions.data),
  employees: transformUserDropdownData(state.employees.data),
  downloading: state.uiActivity.downloading,
  downloadingDeleting: state.uiActivity.downloadingDeleting,
  loadingActivityType: state.uiActivityType.downloading,
  loadingCompanies: state.notification.downloadingCompanies,
  loadingCustomer: state.uiCustomer.downloading,
  loadingDepartments: state.notification.downloadingDivisions,
  loadingDivisions: state.notification.downloadingDepartments,
  loadingEmployee: state.uiHistory.downloading,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onActivityTypeOptionSelected: (option) => {
    if (option) {
      dispatch(setActivitySelectedActivityType(option.value));
    } else {
      dispatch(setActivitySelectedActivityType(''));
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setCustomerAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActivityTypeAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActivitySelectedEmployee(''));
    dispatch(setActivitySelectedActivityType(''));
    dispatch(setHistorySelectedCompany(''));
    dispatch(setActivitySelectedCustomer(''));
    dispatch(setHistorySelectedDepartment(''));
    dispatch(setHistorySelectedDivision(''));
    dispatch(clearEmployees());
    dispatch(clearActivityTypes());
    dispatch(clearCompanies());
    dispatch(clearCustomers());
    dispatch(clearDepartments());
    dispatch(clearDivisions());
    dispatch(downloadEmployeesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadActivityTypesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadCustomersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDepartmentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDivisionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_ACTIVITY_MANAGEMENT_ACTIVITY));
    dispatch(setActivitySearchText(''));
    dispatch(clearActivities());
    dispatch(setActivitySelectedPageSize(20));
    dispatch(setActivitySelectedOrderBy(INITIAL_ORDER_BY_ACTIVITIES));
    dispatch(downloadActivitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/profile/, 'profile.id').replace(/customer/, 'customer.id')
      .replace(/activityType/, 'activityType.id').replace(/department/, 'profile.department')
      .replace(/division/, 'profile.division')
      .replace(/company/, 'profile.company');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearActivities());
    dispatch(downloadActivitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(setActivitySelectedEmployee(''));
    dispatch(setActivitySelectedActivityType(''));
    dispatch(setActivitySelectedCustomer(''));
  },
  onChangeActivityTypeText: async (text) => {
    try {
      dispatch(setActivityTypeSearchText(text));
      dispatch(clearActivityTypes());
      await dispatch(downloadActivityTypesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeCompanyText: async (text) => {
    try {
      dispatch(setCompanySearchText(text));
      dispatch(clearCompanies());
      await dispatch(downloadCompaniesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeCustomerText: async (text) => {
    try {
      dispatch(setCustomerSearchText(text));
      dispatch(clearCustomers());
      await dispatch(downloadCustomersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeDepartmentText: async (text) => {
    try {
      dispatch(setDepartmentSearchText(text));
      dispatch(clearDepartments());
      await dispatch(downloadDepartmentsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeDivisionText: async (text) => {
    try {
      dispatch(setDivisionSearchText(text));
      dispatch(clearDivisions());
      await dispatch(downloadDivisionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeEmployeeText: async (text) => {
    try {
      dispatch(setHistoryEmployeeSearchText(text));
      dispatch(clearEmployees());
      await dispatch(downloadEmployeesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadActivitiesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setActivitySelectedPageSize(pageSize));
    dispatch(downloadActivitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDownloadPressed: () => {
    dispatch(saveActivitiesAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setActivitySelectedPageSize(pageSize));
    dispatch(clearActivities());
    dispatch(downloadActivitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setActivitySelectedEmployee(''));
    dispatch(setActivitySelectedActivityType(''));
    dispatch(setHistorySelectedCompany(''));
    dispatch(setActivitySelectedCustomer(''));
    dispatch(setHistorySelectedDepartment(''));
    dispatch(setHistorySelectedDivision(''));
    dispatch(clearActivities());
    dispatch(setActivitySelectedOrderBy(INITIAL_ORDER_BY_ACTIVITIES));
    dispatch(downloadActivitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setActivitySearchText(text));
      dispatch(clearActivities());
      await dispatch(downloadActivitiesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setActivitySelectedOrderBy(orderBy));
    dispatch(clearActivities());
    dispatch(downloadActivitiesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setActivityTappedId(id));
    dispatch(downloadActivityAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityPage);
