import {
  addResponse, downloadingDeletingResponse,
} from '../simple-action';
import { downloadResponse } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingResponse(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiResponse;

    const eventCategory = await downloadResponse(tappedId, token);

    dispatch(addResponse(eventCategory));
  } finally {
    dispatch(downloadingDeletingResponse(false));
  }
};
