import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { isEmpty } from 'lodash';
import { FunctionalPage, MapDrawer, SectionTitle } from '../../component';
import {
  DRAW_MODE_MARKER, DRAW_MODE_POLYGON, FILTER_TYPE_DROPDOWN,
  PAGE_MODE_VIEW, PICKER_MODE_TIME, RXFIELD_BRANCH_ADDRESS, RXFIELD_BRANCH_AREA,
  RXFIELD_BRANCH_AUTO_CLOCK_OUT_AFTER_MINUTES, RXFIELD_BRANCH_CITY, RXFIELD_BRANCH_COMPANY,
  RXFIELD_BRANCH_DESCRIPTION, RXFIELD_BRANCH_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES,
  RXFIELD_BRANCH_EMAIL,
  RXFIELD_BRANCH_END_AFTER_MIN, RXFIELD_BRANCH_END_BEFORE_MIN, RXFIELD_BRANCH_END_WORKING_HOUR,
  RXFIELD_BRANCH_GOOGLE_MAP_URL, RXFIELD_BRANCH_LATITUDE, RXFIELD_BRANCH_LONGITUDE,
  RXFIELD_BRANCH_NAME, RXFIELD_BRANCH_PHONE, RXFIELD_BRANCH_POSTAL_CODE,
  RXFIELD_BRANCH_START_AFTER_MIN, RXFIELD_BRANCH_START_BEFORE_MIN,
  RXFIELD_BRANCH_START_WORKING_HOUR, RXFORM_BRANCH, RXSTATE_BRANCHES, RXSTATE_BRANCH_PAGE, status,
  STATUS_DISABLED, STATUS_ENABLED, timezoneOffset, RXFIELD_CREATED_DATE,
  FILTER_TYPE_DATE_RANGE, PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  DATE_TIME_FORMAT_WITHOUT_PIPE, ADVANCED_FILTER_MODE,
} from '../../constant';
import { FormInitialValueShape, PointShape, SimpleDataShape } from '../../type';
import {
  renderReduxFormDateTimePickerField, renderReduxFormEditableTableField,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField,
} from '../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateBranch } from '../../validation';
import { toMoment } from '../../helper';

const useStyles = makeStyles(() => ({
  dialogHeader: {
    marginBottom: 20,
  },
  heading: {
    margin: 0,
  },
}));

const renderDialogContent = (currentFormValues, tappedData, center, marker, defaultAreaValue,
  defaultCityValue, cities, polygon, addingEditing, addingPolygon, loadingCity,
  onAutoClockOutChanged, onChangeCityText, onChangeLatitudeText, onChangeLongitudeText,
  onCityOptionSelected, onDrawingCompleted, onEarliestClockInChanged, onEndWorkingHourChanged,
  onStartWorkingHourChanged, pageMode, classes, companies, defaultCompanyValue,
  loadingCompany, onChangeCompanyText, onCompanyOptionSelected) => (
    <div>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_BRANCH_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.branchScreen.placeholderBranchName}
              label={LocalizedString.branchScreen.placeholderBranchName}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_BRANCH_DESCRIPTION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.branchScreen.placeholderDescription}
              label={LocalizedString.branchScreen.placeholderDescription}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_BRANCH_PHONE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.branchScreen.placeholderPhone}
              label={LocalizedString.branchScreen.placeholderPhone}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_BRANCH_EMAIL}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.branchScreen.placeholderEmail}
              label={LocalizedString.branchScreen.placeholderEmail}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_BRANCH_ADDRESS}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.branchScreen.placeholderAddress}
              label={LocalizedString.branchScreen.placeholderAddress}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              multiline
              required
            />
          </Grid>
          <Grid item container>
            <Grid item sm md>
              <Field
                name={RXFIELD_BRANCH_CITY}
                component={renderReduxFormOutlinedDropdownTextField}
                placeholder={LocalizedString.branchScreen.placeholderCityName}
                label={LocalizedString.branchScreen.placeholderCityName}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                data={cities}
                value={defaultCityValue}
                loading={loadingCity}
                onChangeText={onChangeCityText}
                onOptionSelected={onCityOptionSelected}
                required
              />
            </Grid>
            <Grid item sm md>
              <Field
                name={RXFIELD_BRANCH_POSTAL_CODE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.branchScreen.placeholderPostalCode}
                label={LocalizedString.branchScreen.placeholderPostalCode}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                type="number"
              />
            </Grid>
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_BRANCH_COMPANY}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.branchScreen.placeholderCompanyName}
              label={LocalizedString.branchScreen.placeholderCompanyName}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              data={companies}
              value={defaultCompanyValue}
              loading={loadingCompany}
              onChangeText={onChangeCompanyText}
              onOptionSelected={onCompanyOptionSelected}
              required
            />
          </Grid>
        </Grid>
      </Grid>

      <SectionTitle title={LocalizedString.branchScreen.labelGoogleMap} />

      <Grid container spacing={3}>
        <Grid item sm md>
          <Field
            name={RXFIELD_BRANCH_GOOGLE_MAP_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.branchScreen.placeholderGoogleMapUrl}
            label={LocalizedString.branchScreen.placeholderGoogleMapUrl}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_BRANCH_LATITUDE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.branchScreen.placeholderLatitude}
            label={LocalizedString.branchScreen.placeholderLatitude}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
            type="number"
            onChangeText={(e) => onChangeLatitudeText(e, currentFormValues.longitude)}
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_BRANCH_LONGITUDE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.branchScreen.placeholderLongitude}
            label={LocalizedString.branchScreen.placeholderLongitude}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
            onChangeText={(e) => onChangeLongitudeText(e, currentFormValues.latitude)}
            type="number"
          />
        </Grid>
      </Grid>

      <MapDrawer
        drawingMode={DRAW_MODE_MARKER}
        center={center}
        marker={marker}
        onDrawingCompleted={(data, drawingMode) => onDrawingCompleted(data, drawingMode,
          tappedData)}
        disabled={pageMode === PAGE_MODE_VIEW}
      />

      <SectionTitle title={LocalizedString.branchScreen.labelWorkingHour} />

      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_BRANCH_START_WORKING_HOUR}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.branchScreen.placeholderStartWorkingHour}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              onChangeDate={onStartWorkingHourChanged}
              pickerMode={PICKER_MODE_TIME}
              disableFuture
              required
            />
          </Grid>
          <Grid item container>
            <Grid item sm md>
              <Field
                name={RXFIELD_BRANCH_START_BEFORE_MIN}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.branchScreen.placeholderStartBeforeMin}
                label={LocalizedString.branchScreen.placeholderStartBeforeMin}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                required
                type="number"
              />
            </Grid>
            <Grid item sm md>
              <Field
                name={RXFIELD_BRANCH_START_AFTER_MIN}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.branchScreen.placeholderStartAfterMin}
                label={LocalizedString.branchScreen.placeholderStartAfterMin}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                required
                type="number"
              />
            </Grid>
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_BRANCH_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.branchScreen.placeholderEarliestClockIn}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              onChangeDate={onEarliestClockInChanged}
              pickerMode={PICKER_MODE_TIME}
              disableFuture
              required
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_BRANCH_END_WORKING_HOUR}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.branchScreen.placeholderEndWorkingHour}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              onChangeDate={onEndWorkingHourChanged}
              pickerMode={PICKER_MODE_TIME}
              disableFuture
              required
            />
          </Grid>
          <Grid item container>
            <Grid item sm md>
              <Field
                name={RXFIELD_BRANCH_END_BEFORE_MIN}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.branchScreen.placeholderEndBeforeMin}
                label={LocalizedString.branchScreen.placeholderEndBeforeMin}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                required
                type="number"
              />
            </Grid>
            <Grid item sm md>
              <Field
                name={RXFIELD_BRANCH_END_AFTER_MIN}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.branchScreen.placeholderEndAfterMin}
                label={LocalizedString.branchScreen.placeholderEndAfterMin}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                required
                type="number"
              />
            </Grid>
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_BRANCH_AUTO_CLOCK_OUT_AFTER_MINUTES}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.branchScreen.placeholderAutoClockOut}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              onChangeDate={onAutoClockOutChanged}
              pickerMode={PICKER_MODE_TIME}
              disableFuture
              required
            />
          </Grid>
        </Grid>
      </Grid>

      <SectionTitle
        title={LocalizedString.branchScreen.labelBorderArea}
        textStyle={classes.heading}
      />

      {addingPolygon ? (<CircularProgress />) : (
        <Field
          name={RXFIELD_BRANCH_AREA}
          component={renderReduxFormEditableTableField}
          disabled
          defaultValue={defaultAreaValue}
          tableColumns={[
            {
              title: LocalizedString.branchScreen.labelNo, field: 'no', sorting: false, width: 40,
            },
            { title: LocalizedString.branchScreen.labelLatitude, field: 'lat', sorting: false },
            { title: LocalizedString.branchScreen.labelLongitude, field: 'lng', sorting: false },
          ]}
          disableToolbar
          required
        />
      )}

      <MapDrawer
        drawingMode={DRAW_MODE_POLYGON}
        center={marker}
        marker={isEmpty(polygon) ? marker : null}
        polygon={polygon}
        onDrawingCompleted={(data, drawingMode) => onDrawingCompleted(data, drawingMode,
          tappedData)}
        disabled={pageMode === PAGE_MODE_VIEW}
      />
    </div>
);

const BranchPage = ({
  currentFormValues, tappedData, center, marker, initialValues, cities, polygon, provinces,
  addingEditing, addingPolygon, downloading, enablingDisabling, loadingCity, loadingProvince,
  isResyncAllowed, resyncing,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onAutoClockOutChanged, onCancelPressed, onChangeCityText, onChangeLatitudeText,
  onChangeLongitudeText, onChangePage, onChangePageSize, onChangeProvinceText, onCityOptionSelected,
  onConfirmContextMenuPressed, onCreatePressed, onDownloadPressed, onDrawingCompleted,
  onEarliestClockInChanged, onEditPressed, onContextMenuPressed, onEndWorkingHourChanged,
  onRefresh, onResetAdvancedFilterPressed, onSavePressed, onSearchBarTextChanged, onSortPressed,
  onStartWorkingHourChanged, onSubmitPressed, onViewPressed,
  branches, currentBranchStatus, pageMode, tappedId,
  companies, loadingCompany, onChangeCompanyText, onCompanyOptionSelected, onDuplicatePressed,
  isDuplicateAllowed,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_BRANCHES}
      uiPage={RXSTATE_BRANCH_PAGE}
      moreMenus={[
        {
          caption: LocalizedString.common.buttonCaptionEnable,
          disabled: currentBranchStatus === STATUS_ENABLED || enablingDisabling,
          onPress: () => onContextMenuPressed(tappedId,
            LocalizedString.common.msgEnableConfirmation),
        },
        {
          caption: LocalizedString.common.buttonCaptionDisable,
          disabled: currentBranchStatus === STATUS_DISABLED || enablingDisabling,
          onPress: () => onContextMenuPressed(tappedId,
            LocalizedString.common.msgDisableConfirmation),
        },
        {
          caption: LocalizedString.common.buttonCaptionDuplicate,
          disabled: !isDuplicateAllowed,
          onPress: () => { onEditPressed(tappedId, branches); onDuplicatePressed(); },
        },
        {
          caption: LocalizedString.common.buttonCaptionResync,
          disabled: resyncing || !isResyncAllowed,
          onPress: () => onContextMenuPressed(tappedId,
            LocalizedString.common.msgResyncConfirmation),
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.branchScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.branchScreen.labelName, field: 'name', sorting: !downloading },
        { title: LocalizedString.branchScreen.labelCity, field: 'city.name', sorting: !downloading },
        { title: LocalizedString.branchScreen.labelAddress, field: 'address', sorting: !downloading },
        {
          title: LocalizedString.branchScreen.labelCreatedDate,
          field: 'createdDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        {
          title: LocalizedString.branchScreen.labelLastModifiedDate,
          field: 'lastModifiedDate',
          sorting: !downloading,
          render: ({ lastModifiedDate }) => (lastModifiedDate
            ? toMoment(lastModifiedDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        { title: LocalizedString.branchScreen.labelStatus, field: 'status', sorting: !downloading },
      ]}
      filterColumns={[
        {
          title: LocalizedString.branchScreen.labelTimeZone, field: 'timeZone', type: FILTER_TYPE_DROPDOWN, data: timezoneOffset,
        },
        {
          title: LocalizedString.branchScreen.labelCity,
          field: 'city',
          type: FILTER_TYPE_DROPDOWN,
          data: cities,
          loading: loadingCity,
          onChangeFilterText: (text) => onChangeCityText(text, ADVANCED_FILTER_MODE),
          useDropdownValue: true,
        },
        {
          title: LocalizedString.branchScreen.labelProvince,
          field: 'province',
          type: FILTER_TYPE_DROPDOWN,
          data: provinces,
          loading: loadingProvince,
          onChangeFilterText: (text) => onChangeProvinceText(text, ADVANCED_FILTER_MODE),
          useDropdownValue: true,
        },
        {
          title: LocalizedString.branchScreen.labelCompany,
          field: 'company',
          type: FILTER_TYPE_DROPDOWN,
          data: companies,
          loading: loadingCompany,
          onChangeFilterText: (text) => onChangeCompanyText(text, ADVANCED_FILTER_MODE),
          useDropdownValue: true,
        },
        {
          title: LocalizedString.branchScreen.labelCreatedDate,
          field: RXFIELD_CREATED_DATE,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.branchScreen.labelStatus, field: 'status', type: FILTER_TYPE_DROPDOWN, data: status, useDropdownValue: true,
        },
      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmContextMenuPressed}
      onCreatePressed={onCreatePressed}
      onDownloadPressed={onDownloadPressed}
      onEditPressed={(id) => onEditPressed(id, branches)}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSavePressed={onSavePressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onSubmitPressed={onSubmitPressed}
      onViewPressed={(id) => onViewPressed(id, branches)}
      createNewButtonCaption={LocalizedString.branchScreen.buttonCaptionCreateNewBranch}
      deleteButtonCaption={LocalizedString.branchScreen.buttonCaptionDeleteBranch}
      editButtonCaption={LocalizedString.branchScreen.buttonCaptionEditBranch}
      title={LocalizedString.branchScreen.title}
      addDialogHeaderContainerStyle={classes.dialogHeader}
      disableDelete
      createPermissionName="MDATA_CREATE_BRANCH"
      editPermissionName="MDATA_EDIT_BRANCH"
      savePermissionName="MDATA_GET_BRANCH"
      useFullWidth
    >
      {renderDialogContent(currentFormValues, tappedData, center, marker, initialValues.area,
        initialValues.city.label, cities, polygon, addingEditing, addingPolygon, loadingCity,
        onAutoClockOutChanged, onChangeCityText, onChangeLatitudeText, onChangeLongitudeText,
        onCityOptionSelected, onDrawingCompleted, onEarliestClockInChanged, onEndWorkingHourChanged,
        onStartWorkingHourChanged, pageMode, classes, companies, initialValues.company.label,
        loadingCompany, onChangeCompanyText, onCompanyOptionSelected)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_BRANCH,
  validate: rxformValidateBranch,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(BranchPage);

BranchPage.propTypes = {
  currentFormValues: FormInitialValueShape.isRequired,
  tappedData: FormInitialValueShape,
  center: PointShape,
  marker: PointShape,
  initialValues: FormInitialValueShape.isRequired,
  cities: PropTypes.arrayOf(SimpleDataShape).isRequired,
  companies: PropTypes.arrayOf(SimpleDataShape).isRequired,
  polygon: PropTypes.arrayOf(PointShape).isRequired,
  provinces: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  addingPolygon: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  loadingCity: PropTypes.bool.isRequired,
  loadingCompany: PropTypes.bool.isRequired,
  loadingProvince: PropTypes.bool.isRequired,
  isResyncAllowed: PropTypes.bool.isRequired,
  resyncing: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onAutoClockOutChanged: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeCityText: PropTypes.func.isRequired,
  onChangeCompanyText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeLatitudeText: PropTypes.func.isRequired,
  onChangeLongitudeText: PropTypes.func.isRequired,
  onChangeProvinceText: PropTypes.func.isRequired,
  onCityOptionSelected: PropTypes.func.isRequired,
  onCompanyOptionSelected: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onDrawingCompleted: PropTypes.func.isRequired,
  onDuplicatePressed: PropTypes.func.isRequired,
  onEarliestClockInChanged: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  onEndWorkingHourChanged: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onStartWorkingHourChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  branches: PropTypes.objectOf(PropTypes.object).isRequired,
  currentBranchStatus: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
  isDuplicateAllowed: PropTypes.bool.isRequired,
};

BranchPage.defaultProps = {
  tappedData: null,
  center: null,
  marker: null,
};
