import { sendingSessionNotification } from '../simple-action';
import { sendSessionNotification } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(sendingSessionNotification(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSession;

    await sendSessionNotification(tappedId, token);
  } finally {
    dispatch(sendingSessionNotification(false));
  }
};
