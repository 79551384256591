import { INITIAL_ORDER_BY_SESSION_OPERATOR_INVITATIONS } from '../../constant';
import {
  ADDING_SESSION_OPERATOR_INVITATION, DOWNLOADING_DELETING_SESSION_OPERATOR_INVITATION,
  DOWNLOADING_SESSION_OPERATOR_INVITATIONS, RESENDING_SESSION_OPERATOR_INVITATION,
  SAVING_SESSION_OPERATOR_INVITATIONS,
  SET_SESSION_OPERATOR_INVITATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_SESSION_OPERATOR_INVITATION_SEARCH_TEXT, SET_SESSION_OPERATOR_INVITATION_SELECTED_SESSION_ID,
  SET_SESSION_OPERATOR_INVITATION_SELECTED_ORDER_BY,
  SET_SESSION_OPERATOR_INVITATION_SELECTED_PAGE_SIZE,
  SET_SESSION_OPERATOR_INVITATION_SELECTED_RECIPIENT, SET_SESSION_OPERATOR_INVITATION_TAPPED_ID,
} from '../action/simple-action';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  resending: false,
  saving: false,
  selectedPageSize: 20,
  orderBy: INITIAL_ORDER_BY_SESSION_OPERATOR_INVITATIONS,
  searchBarText: '',
  tappedId: '',
  filterString: '',
  selectedSession: '',
  selectedRecipients: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_SESSION_OPERATOR_INVITATIONS:
      return { ...state, downloading: action.status };
    case ADDING_SESSION_OPERATOR_INVITATION:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_SESSION_OPERATOR_INVITATION:
      return { ...state, downloadingDeleting: action.status };
    case SET_SESSION_OPERATOR_INVITATION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_SESSION_OPERATOR_INVITATION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_SESSION_OPERATOR_INVITATION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_SESSION_OPERATOR_INVITATION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_SESSION_OPERATOR_INVITATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_SESSION_OPERATOR_INVITATION_SELECTED_SESSION_ID:
      return { ...state, selectedSession: action.id };
    case SET_SESSION_OPERATOR_INVITATION_SELECTED_RECIPIENT:
      return { ...state, selectedRecipients: action.data };
    case RESENDING_SESSION_OPERATOR_INVITATION:
      return { ...state, resending: action.status };
    case SAVING_SESSION_OPERATOR_INVITATIONS:
      return { ...state, saving: action.status };
    default: return state;
  }
};
