import { connect } from 'react-redux';
import { setAlertErrorMessage, setImagePickerDialogVisibility, setIsSelfieModalOpen } from '../../redux/action';
import UploadPictureField from './upload-picture-field.presentation';

const mapStateToProps = (state) => ({
  launchingImagePicker: state.uiImagePickerDialog.launching,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setImagePickerDialogVisibility(false));
  },
  onImagePickerPressed: () => {
    dispatch(setImagePickerDialogVisibility(true));
  },
  closeImagePickerDialog: () => {
    dispatch(setImagePickerDialogVisibility(false));
  },
  openSelfieModal: () => {
    dispatch(setIsSelfieModalOpen(true));
  },
  closeSelfieModal: () => {
    dispatch(setIsSelfieModalOpen(false));
  },
  displayAlertErrorDialog: (err) => {
    dispatch(setAlertErrorMessage(err));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadPictureField);
