import {
  DOWNLOADING_FIELDS, ADDING_EDITING_FIELD,
  DOWNLOADING_DELETING_FIELD, SET_FIELD_SELECTED_PAGE_SIZE,
  SET_FIELD_TAPPED_ID, SET_FIELD_SEARCH_TEXT,
  SET_FIELD_SELECTED_ORDER_BY, ENABLING_DISABLING_FIELD,
  SET_FIELD_PARENT_SEARCH_TEXT,
  SET_FIELD_SELECTED_FIELD_TYPE, SET_FIELD_SELECTED_OPTION_MODE,
  SET_FIELD_SELECTED_OPTION_VALUE_SEPARATOR, SET_FIELD_SELECTED_PARENT,
  SET_FIELD_SELECTED_PICKER_MODE, SET_FIELD_SELECTED_TEXT_KEYBOARD_TYPE,
  SET_FIELD_SELECTED_TEXT_MULTILINE, DOWNLOADING_FIELD_PARENTS,
  SET_FIELD_SELECTED_LOGICAL_OPERATOR, SET_FIELD_SELECTED_MUST_USE_CAMERA,
  SET_FIELD_SELECTED_FORM, SET_FIELD_PARENT_FILTER_STRING,
} from '../action';
import { INITIAL_ORDER_BY_FIELDS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  downloadingParents: false,
  enablingDisabling: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  parentSearchText: '',
  parentFilterString: '',
  orderBy: INITIAL_ORDER_BY_FIELDS,
  selectedFieldType: '',
  selectedLogicalOperator: '',
  selectedMustUseCamera: '',
  selectedOptionMode: '',
  selectedOptionValueSeparator: '',
  selectedParent: '',
  selectedPickerMode: '',
  selectedTextKeyboardType: '',
  selectedTextMultiline: '',
  selectedForm: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_FIELDS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_FIELD:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_FIELD:
      return { ...state, downloadingDeleting: action.status };
    case SET_FIELD_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_FIELD_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_FIELD_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_FIELD_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case ENABLING_DISABLING_FIELD:
      return { ...state, enablingDisabling: action.status };
    case SET_FIELD_PARENT_SEARCH_TEXT:
      return { ...state, parentSearchText: action.text };
    case SET_FIELD_PARENT_FILTER_STRING:
      return { ...state, parentFilterString: action.text };
    case SET_FIELD_SELECTED_FIELD_TYPE:
      return { ...state, selectedFieldType: action.option };
    case SET_FIELD_SELECTED_OPTION_MODE:
      return { ...state, selectedOptionMode: action.option };
    case SET_FIELD_SELECTED_OPTION_VALUE_SEPARATOR:
      return { ...state, selectedOptionValueSeparator: action.option };
    case SET_FIELD_SELECTED_PARENT:
      return { ...state, selectedParent: action.option };
    case SET_FIELD_SELECTED_PICKER_MODE:
      return { ...state, selectedPickerMode: action.option };
    case SET_FIELD_SELECTED_TEXT_KEYBOARD_TYPE:
      return { ...state, selectedTextKeyboardType: action.option };
    case SET_FIELD_SELECTED_TEXT_MULTILINE:
      return { ...state, selectedTextMultiline: action.option };
    case DOWNLOADING_FIELD_PARENTS:
      return { ...state, downloadingParents: action.status };
    case SET_FIELD_SELECTED_LOGICAL_OPERATOR:
      return { ...state, selectedLogicalOperator: action.option };
    case SET_FIELD_SELECTED_MUST_USE_CAMERA:
      return { ...state, selectedMustUseCamera: action.option };
    case SET_FIELD_SELECTED_FORM:
      return { ...state, selectedForm: action.option };
    default: return state;
  }
};
