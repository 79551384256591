import { addSessionOperatorRegistration, rejectingSessionOperatorRegistration } from '../simple-action';
import { approveRejectSessionOperatorRegistration, downloadSessionOperatorRegistration } from '../../../helper';
import { REGISTRATION_STATUS_REJECTED } from '../../../constant';

export default (notes) => async (dispatch, getState) => {
  try {
    dispatch(rejectingSessionOperatorRegistration(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSessionOperatorRegistration;

    await approveRejectSessionOperatorRegistration(tappedId, REGISTRATION_STATUS_REJECTED, notes,
      token);
    const result = await downloadSessionOperatorRegistration(tappedId, token);

    dispatch(addSessionOperatorRegistration(result));
  } finally {
    dispatch(rejectingSessionOperatorRegistration(false));
  }
};
