import {
  enablingDisablingVoucher, setAlertErrorMessage, setVoucherSelectedOrderBy,
  setVoucherSelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_VOUCHERS, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableVoucher } from '../../../helper';
import downloadVouchersAsync from './downloadVouchersAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingVoucher(true));

    const { token } = getState().authentication;
    const { data } = getState().vouchers;
    const { tappedId } = getState().uiVoucher;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableVoucher(tappedId, status, token);

    dispatch(setVoucherSelectedPageSize(20));
    dispatch(setVoucherSelectedOrderBy(INITIAL_ORDER_BY_VOUCHERS));
    dispatch(downloadVouchersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingVoucher(false));
  }
};
