import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import lodash from 'lodash';
import PreviewPage from './preview.presentation';
import {
  clearForms, downloadFormsAsync, downloadPreviewFormAsync, setAlertErrorMessage,
  setFormAdvancedFilterDialogSelectedFilterString, setFormSearchText, setFormSelectedOrderBy,
  setFormSelectedPageSize, setFormTappedId, setPreviewFormFieldValue, setPreviewSelectedForm,
  uploadPreviewFormResponseAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_FORMS, RXFIELD_DFORM_PREVIEW_FORM,
  RXFORM_PREVIEW,
} from '../../constant';
import {
  debounceSearch, transformDropdownData,
} from '../../helper';

const transformSelectedFormData = (selectedForm) => {
  if (!selectedForm) return null;

  const transformedData = {
    ...selectedForm,
    fields: selectedForm.fields.map((field) => ({
      ...field,
      optionValues: field.optionValues === null ? null : field.optionValues.split('|').map((option) => ({
        label: option,
        value: option,
      })),
    })),
  };

  return transformedData;
};

const getSortedMyForm = (detail) => {
  if (!detail) return null;

  const myForm = lodash.values(detail.fields).sort((a, b) => a.order - b.order);
  return {
    ...detail,
    fields: myForm,
  };
};

const mapStateToProps = (state) => ({
  forms: transformDropdownData(state.forms.data),
  downloadingForms: state.uiForm.downloading,
  selectedForm: transformSelectedFormData(getSortedMyForm(state.preview.selectedForm)),
  uploadingResponse: state.uiPreview.uploadingResponse,
  downloadingFormDetail: state.uiPreview.downloading,
  formId: state.uiForm?.tappedId || '',
});

const searchFormDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearForms());
    dispatch(downloadFormsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setPreviewFormFieldValue(null));
    dispatch(reset(RXFORM_PREVIEW));
    dispatch(setPreviewSelectedForm(null));
    dispatch(setFormSearchText(''));
    dispatch(setFormTappedId(''));
    dispatch(clearForms());
    dispatch(setFormSelectedPageSize(20));
    dispatch(setFormSelectedOrderBy(INITIAL_ORDER_BY_FORMS));
    dispatch(downloadFormsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onFormOptionSelected: (option) => {
    if (option) {
      dispatch(setPreviewFormFieldValue(null));
      dispatch(reset(RXFORM_PREVIEW));
      dispatch(change(RXFORM_PREVIEW, RXFIELD_DFORM_PREVIEW_FORM, option));
      dispatch(setFormTappedId(option.value));
      dispatch(downloadPreviewFormAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else {
      dispatch(setFormTappedId(''));
      dispatch(change(RXFORM_PREVIEW, RXFIELD_DFORM_PREVIEW_FORM, ''));
    }
  },
  onChangeFormText: async (text) => {
    dispatch(setFormAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setFormSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchFormDebounce(dispatch);
    }
  },
  onSubmitButtonPressed: (values, form) => {
    const referenceData = {
      referenceId: values.find((x) => x.fieldName === 'referenceId')?.value || '',
      referenceLabel: values.find((x) => x.fieldName === 'referenceLabel')?.value || '',
    };
    const fieldValue = values
      .filter((x) => x.fieldName !== 'referenceId'
      && x.fieldName !== 'referenceLabel'
      && x.value !== null);

    dispatch(uploadPreviewFormResponseAsync(fieldValue, form, referenceData))
      .then(() => {
        dispatch(reset(RXFORM_PREVIEW));
        dispatch(change(RXFORM_PREVIEW, RXFIELD_DFORM_PREVIEW_FORM, form?.name));
        dispatch(downloadPreviewFormAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
      })
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPage);
