import moment from 'moment-timezone';
import { reset } from 'redux-form';
import {
  FILTERED_RECIPIENTS_STATUS_ENABLED_TAG, FILTERED_RECIPIENTS_VALID_UNTIL_TAG,
  RECIPIENT_TYPE_PROFILE, RXFORM_NOTIFICATION_TASK,
} from '../../../constant';
import {
  downloadImageAsBase64, sendNotification, toMoment, toUtcMoment,
} from '../../../helper';
import {
  sendingNotificationTaskNotification, setAlertErrorMessage,
  setNotificationTaskDuplicateDialogVisibility, setNotificationTaskTotalPersonCount,
} from '../simple-action';
import downloadNotificationTasksAsync from './downloadNotificationTasksAsync';

export default (values) => async (dispatch, getState) => {
  try {
    dispatch(sendingNotificationTaskNotification(true));

    const { token } = getState().authentication;
    const {
      title, body, url, filteredRecipients, recipientType,
      image: baseImage,
    } = values;

    const currentUtcTime = toUtcMoment(toMoment(), moment.tz.guess());
    const filteredRecipient = recipientType === RECIPIENT_TYPE_PROFILE
      ? `${FILTERED_RECIPIENTS_STATUS_ENABLED_TAG}|${filteredRecipients}`
      : `${FILTERED_RECIPIENTS_STATUS_ENABLED_TAG}${FILTERED_RECIPIENTS_VALID_UNTIL_TAG}${currentUtcTime}|${filteredRecipients}`;

    const image = baseImage.includes('https')
      ? await downloadImageAsBase64(baseImage)
      : baseImage;

    await sendNotification(
      recipientType, filteredRecipient, title, body, url, image, token,
    );

    dispatch(setNotificationTaskDuplicateDialogVisibility(false));
    dispatch(setNotificationTaskTotalPersonCount(0));
    dispatch(reset(RXFORM_NOTIFICATION_TASK));
    dispatch(downloadNotificationTasksAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  } finally {
    dispatch(sendingNotificationTaskNotification(false));
  }
};
