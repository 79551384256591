import { downloadingVoucherCategories, seVoucherCategories } from '../simple-action';
import { downloadVoucherCategories, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, voucherCategories, uiVoucherCategory } = getState();
  const { token } = authentication;
  const { meta } = voucherCategories;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiVoucherCategory;

  const searchText = transformSearchText(searchBarText);

  try {
    dispatch(downloadingVoucherCategories(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const result = await downloadVoucherCategories(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      searchText,
      filterString,
      token,
    );
    dispatch(seVoucherCategories(result));
  } finally {
    dispatch(downloadingVoucherCategories(false));
  }
};
