import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  localDateToUtc, transformDropdownData,
  transformInitialValues,
  toMoment,
  getTimeRegion,
  debounceSearch,
  transformEmailDropdownData,
  getPermission,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import SessionInvitationPage from './session-invitation.presentation';
import {
  INITIAL_ORDER_BY_SESSION_INVITATIONS, MENUID_EVENT_SESSION_INVITATION,
  PAGE_MODE_TABLE, RXFIELD_SESSION_INVITATION_SESSION,
  RXFIELD_SESSION_INVITATION_RECIPIENTS, RXFORM_SESSION_INVITATION,
  DATE_TIME_FORMAT_WITHOUT_PIPE, ADVANCED_FILTER_MODE,
} from '../../constant';
import {
  clearSessionInvitations, clearSessions, clearProfiles, setActiveSideMenuItem,
  setAlertConfirmationMessage,
  setAlertErrorMessage, setSessionAdvancedFilterDialogSelectedFilterString,
  setSessionInvitationAdvancedFilterDialogSelectedFilterString,
  setSessionInvitationSearchText, setSessionInvitationSelectedSessionId,
  setSessionInvitationSelectedOrderBy,
  setSessionInvitationSelectedPageSize, setSessionInvitationSelectedRecipient,
  setSessionInvitationTappedId, setSessionSearchText,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
  addSessionInvitationAsync, downloadDeleteSessionInvitationAsync,
  downloadSessionInvitationsAsync, downloadSessionsAsync,
  resendSessionInvitationAsync, saveSessionInvitationsAsync,
} from '../../redux/action';
import { downloadProfilesAsync } from '../../../../redux/action';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getInitialValues = (state) => {
  const { sessionInvitations, uiSessionInvitation, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiSessionInvitation;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? sessionInvitations.data[tappedId] : {};

  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      {
        endTime: found?.session?.endTime ? `${toMoment(found?.session?.endTime, found.session.room.location.city.province.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE)} ${getTimeRegion(found.session.room.location.city.province.timeZone)}` : '',
        sessionId: found?.session?.id || '',
        fullName: found?.user?.fullName || '',
        location: found?.session?.room?.location?.name || '',
        name: found?.session?.name || '',
        phone: found?.user?.phone || '',
        registeredOn: found?.registeredOn ? localDateToUtc(found?.registeredOn, found.session.room.location.city.province.timeZone) : '',
        shortDescription: found?.session?.shortDescription || '',
        startTime: found?.session?.startTime ? `${toMoment(found?.session?.startTime, found.session.room.location.city.province.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE)} ${getTimeRegion(found.session.room.location.city.province.timeZone)}` : '',
        userId: found?.user?.id || '',
        timeZone: found?.session?.startTime || found?.session?.endTime ? getTimeRegion(found.session.room.location.city.province.timeZone) : '',
      })
    : {
      endTime: '',
      email: '',
      session: { label: '', value: '' },
      sessionId: '',
      fullname: '',
      invitationStatus: { label: '', value: '' },
      name: '',
      phone: '',
      recipients: [],
      resentCount: 0,
      registeredOn: '',
      shortDescription: '',
      startTime: '',
      userId: '',
      timeZone: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  sessions: transformDropdownData(state.sessions.data),
  profiles: transformEmailDropdownData(state.profiles.data),
  sessionInvitations: state.sessionInvitations.data,
  addingEditing: state.uiSessionInvitation.addingEditing,
  downloading: state.uiSessionInvitation.downloading,
  downloadingSessions: state.uiSession.downloading,
  downloadingProfiles: state.uiProfile.downloading,
  resending: state.uiSessionInvitation.resending,
  recipientValue: state.uiSessionInvitation.recipient,
  initialValues: getInitialValues(state),
  isResendAllowed: getPermission(state, 'EVENT_RESEND_SESSION_INVITATION'),
});

const searchProfileDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchSessionDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearSessions());
    dispatch(downloadSessionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setSessionInvitationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_EVENT_SESSION_INVITATION));
    dispatch(setSessionInvitationSearchText(''));
    dispatch(clearSessionInvitations());
    dispatch(setSessionInvitationSelectedPageSize(20));
    dispatch(setSessionInvitationSelectedOrderBy(INITIAL_ORDER_BY_SESSION_INVITATIONS));
    dispatch(downloadSessionInvitationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setSessionAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setSessionSearchText(''));
    dispatch(clearSessions());
    dispatch(downloadSessionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/session/, 'session.id');
    dispatch(setSessionInvitationAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearSessionInvitations());
    dispatch(downloadSessionInvitationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_SESSION_INVITATION));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadSessionInvitationsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setSessionInvitationSelectedPageSize(pageSize));
    dispatch(downloadSessionInvitationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeSessionText: (text, mode) => {
    dispatch(setSessionAdvancedFilterDialogSelectedFilterString(
      mode === ADVANCED_FILTER_MODE ? '' : 'status=enabled',
    ));
    dispatch(setSessionSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchSessionDebounce(dispatch);
    }
  },
  onChangeRecipientText: (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchProfileDebounce(dispatch);
    }
  },
  onConfirmContextMenuPressed: (reason, message) => {
    if (message === GlobalLocalizedString.common.msgDeleteConfirmation) {
      dispatch(downloadDeleteSessionInvitationAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else if (message === LocalizedString.sessionInvitationPage.msgResendConfirmation) {
      dispatch(resendSessionInvitationAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }
  },
  onCreatePressed: () => {
    dispatch(setSessionInvitationTappedId(''));

    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setSessionAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setSessionSearchText(''));
    dispatch(clearSessions());
    dispatch(downloadSessionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onDeletePressed: (id) => {
    dispatch(setSessionInvitationTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onDownloadPressed: () => {
    dispatch(saveSessionInvitationsAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onSessionsTypeSelected: (option) => {
    if (option) {
      dispatch(setSessionInvitationSelectedSessionId(option.value));
      dispatch(change(RXFORM_SESSION_INVITATION, RXFIELD_SESSION_INVITATION_SESSION, option));
    } else {
      dispatch(setSessionInvitationSelectedSessionId(''));
      dispatch(change(RXFORM_SESSION_INVITATION, RXFIELD_SESSION_INVITATION_SESSION, ''));
    }
  },
  onRecipientsTypeSelected: (option) => {
    if (option) {
      dispatch(setSessionInvitationSelectedRecipient(option));
      dispatch(change(RXFORM_SESSION_INVITATION, RXFIELD_SESSION_INVITATION_RECIPIENTS, option));
    } else {
      dispatch(setSessionInvitationSelectedRecipient([]));
      dispatch(change(RXFORM_SESSION_INVITATION, RXFIELD_SESSION_INVITATION_RECIPIENTS, []));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setSessionInvitationSelectedPageSize(pageSize));
    dispatch(clearSessionInvitations());
    dispatch(downloadSessionInvitationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResendPressed: (id) => {
    dispatch(setSessionInvitationTappedId(id));
    dispatch(setAlertConfirmationMessage(
      LocalizedString.sessionInvitationPage.msgResendConfirmation,
    ));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setSessionInvitationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearSessionInvitations());
    dispatch(setSessionInvitationSelectedOrderBy(INITIAL_ORDER_BY_SESSION_INVITATIONS));
    dispatch(downloadSessionInvitationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setSessionInvitationSearchText(text));
      dispatch(clearSessionInvitations());
      await dispatch(downloadSessionInvitationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setSessionInvitationSelectedOrderBy(orderBy));
    dispatch(clearSessionInvitations());
    dispatch(downloadSessionInvitationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ session, recipients }) => {
    await dispatch(addSessionInvitationAsync(session, recipients));
  },
  onViewPressed: (id) => {
    dispatch(setSessionInvitationTappedId(id));
    dispatch(downloadDeleteSessionInvitationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionInvitationPage);
