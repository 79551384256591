import {
  republishingPayment, setAlertErrorMessage, setPaymentSelectedOrderBy, setPaymentSelectedPageSize,
} from '../simple-action';
import { republishPayment } from '../../../helper';
import { INITIAL_ORDER_BY_PAYMENTS } from '../../../constant';
import downloadPaymentsAsync from './downloadPaymentsAsync';

export default () => async (dispatch, getState) => {
  const { authentication, uiPayment } = getState();

  const { token } = authentication;
  const { tappedId } = uiPayment;

  try {
    dispatch(republishingPayment(true));

    await republishPayment(
      tappedId,
      token,
    );

    dispatch(setPaymentSelectedPageSize(20));
    dispatch(setPaymentSelectedOrderBy(INITIAL_ORDER_BY_PAYMENTS));
    dispatch(downloadPaymentsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(republishingPayment(false));
  }
};
