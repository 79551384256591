import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  ADVANCED_FILTER_MODE,
  INITIAL_ORDER_BY_EVENT_OPERATOR_INVITATIONS, MENUID_EVENT_OPERATOR_INVITATION, PAGE_MODE_TABLE,
  RXFIELD_EVENT_OPERATOR_INVITATION_EVENT, RXFIELD_EVENT_OPERATOR_INVITATION_RECIPIENTS,
  RXFORM_EVENT_OPERATOR_INVITATION,
} from '../../constant';
import {
  debounceSearch, getPermission, getTimeRegion, localDateToUtc, toMoment, transformDropdownData,
  transformEmailDropdownData, transformInitialValues,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import {
  clearEventOperatorInvitations, clearProfiles, setActiveSideMenuItem, setAlertConfirmationMessage,
  setAlertErrorMessage, setEventOperatorInvitationAdvancedFilterDialogSelectedFilterString,
  setEventOperatorInvitationSearchText, setEventOperatorInvitationSelectedEventId,
  setEventOperatorInvitationSelectedOrderBy, setEventOperatorInvitationSelectedPageSize,
  setEventOperatorInvitationSelectedRecipient, setEventOperatorInvitationTappedId,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
  addEventOperatorInvitationAsync, downloadDeleteEventOperatorInvitationAsync,
  downloadEventOperatorInvitationsAsync, resendEventOperatorInvitationAsync,
  saveEventOperatorInvitationsAsync,
} from '../../redux/action';
import { downloadProfilesAsync } from '../../../../redux/action';
import EventOperatorInvitationPage from './event-operator-invitation.presentation';
import {
  clearEvents, downloadEventsAsync, setEventAdvancedFilterDialogSelectedFilterString,
  setEventSearchText,
} from '../../../event/redux/action';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getInitialValues = (state) => {
  const { eventOperatorInvitations, uiEventOperatorInvitation, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiEventOperatorInvitation;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? eventOperatorInvitations.data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    endTime: found.event?.endTime ? toMoment(found?.event.endTime, found.event.location.city.province.timeZone) : '',
    eventId: found.event?.id || '',
    fullName: found.user?.fullName || '',
    location: found.event?.location?.name || '',
    name: found.event?.name || '',
    phone: found.user?.phone || '',
    registeredOn: found.registeredOn ? localDateToUtc(found.registeredOn, found.event.location.city.province.timeZone) : '',
    shortDescription: found.event?.shortDescription || '',
    startTime: found.event?.startTime ? toMoment(found.event.startTime, found.event.location.city.province.timeZone) : '',
    userId: found?.user?.id || '',
    timeZone: found.event?.startTime || found.event?.endTime ? getTimeRegion(found.event.location.city.province.timeZone) : '',
  }) : {
    endTime: '',
    email: '',
    event: { label: '', value: '' },
    eventId: '',
    fullname: '',
    invitationStatus: { label: '', value: '' },
    name: '',
    phone: '',
    recipients: [],
    resentCount: 0,
    registeredOn: '',
    shortDescription: '',
    startTime: '',
    userId: '',
    timeZone: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  events: transformDropdownData(state.events.data),
  profiles: transformEmailDropdownData(state.profiles.data),
  addingEditing: state.uiEventOperatorInvitation.addingEditing,
  downloading: state.uiEventOperatorInvitation.downloading,
  downloadingEvents: state.uiEvent.downloading,
  downloadingProfiles: state.uiProfile.downloading,
  isResendAllowed: getPermission(state, 'EVENT_RESEND_EVENT_INVITATION_FOR_OPERATOR'),
  resending: state.uiEventOperatorInvitation.resending,
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
});

const searchEventDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchProfileDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setEventAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setEventSearchText(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearEvents());
    dispatch(clearProfiles());
    dispatch(downloadEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAppear: () => {
    dispatch(setEventOperatorInvitationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_EVENT_OPERATOR_INVITATION));
    dispatch(setEventOperatorInvitationSearchText(''));
    dispatch(clearEventOperatorInvitations());
    dispatch(setEventOperatorInvitationSelectedPageSize(20));
    dispatch(setEventOperatorInvitationSelectedOrderBy(
      INITIAL_ORDER_BY_EVENT_OPERATOR_INVITATIONS,
    ));
    dispatch(downloadEventOperatorInvitationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/event/, 'event.id');
    dispatch(setEventOperatorInvitationAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearEventOperatorInvitations());
    dispatch(downloadEventOperatorInvitationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_EVENT_OPERATOR_INVITATION));
    dispatch(setEventOperatorInvitationSelectedRecipient([]));
    dispatch(setEventOperatorInvitationSelectedEventId(''));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadEventOperatorInvitationsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setEventOperatorInvitationSelectedPageSize(pageSize));
    dispatch(downloadEventOperatorInvitationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeEventText: (text, mode) => {
    dispatch(setEventAdvancedFilterDialogSelectedFilterString(
      mode === ADVANCED_FILTER_MODE
        ? '' : 'status=enabled|visibilityStatus=visible',
    ));
    dispatch(setEventSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchEventDebounce(dispatch, true);
    }
  },
  onChangeRecipientText: (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchProfileDebounce(dispatch, true);
    }
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(downloadDeleteEventOperatorInvitationAsync())
          .catch((error) => dispatch(setAlertErrorMessage(error))); break;
      default:
        dispatch(resendEventOperatorInvitationAsync())
          .catch((error) => dispatch(setAlertErrorMessage(error))); break;
    }
  },
  onCreatePressed: () => {
    dispatch(setEventOperatorInvitationTappedId(''));
    dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled|visibilityStatus=visible'));
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setEventSearchText(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearEvents());
    dispatch(clearProfiles());
    dispatch(downloadEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onDeletePressed: (id) => {
    dispatch(setEventOperatorInvitationTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onDownloadPressed: () => {
    dispatch(saveEventOperatorInvitationsAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onEventOptionSelected: (option) => {
    if (option) {
      dispatch(setEventOperatorInvitationSelectedEventId(option.value));
      dispatch(change(RXFORM_EVENT_OPERATOR_INVITATION, RXFIELD_EVENT_OPERATOR_INVITATION_EVENT,
        option));
    } else {
      dispatch(setEventOperatorInvitationSelectedEventId(''));
      dispatch(change(RXFORM_EVENT_OPERATOR_INVITATION, RXFIELD_EVENT_OPERATOR_INVITATION_EVENT, ''));
    }
  },
  onRecipientsOptionSelected: (option) => {
    if (option) {
      dispatch(setEventOperatorInvitationSelectedRecipient(option));
      dispatch(change(RXFORM_EVENT_OPERATOR_INVITATION,
        RXFIELD_EVENT_OPERATOR_INVITATION_RECIPIENTS, option));
    } else {
      dispatch(setEventOperatorInvitationSelectedRecipient([]));
      dispatch(change(RXFORM_EVENT_OPERATOR_INVITATION,
        RXFIELD_EVENT_OPERATOR_INVITATION_RECIPIENTS, []));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setEventOperatorInvitationSelectedPageSize(pageSize));
    dispatch(clearEventOperatorInvitations());
    dispatch(downloadEventOperatorInvitationsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onResendPressed: (id) => {
    dispatch(setEventOperatorInvitationTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.eventInvitationPage
      .msgResendConfirmation));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setEventOperatorInvitationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearEventOperatorInvitations());
    dispatch(setEventOperatorInvitationSelectedOrderBy(
      INITIAL_ORDER_BY_EVENT_OPERATOR_INVITATIONS,
    ));
    dispatch(downloadEventOperatorInvitationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setEventOperatorInvitationSearchText(text));
      dispatch(clearEventOperatorInvitations());
      await dispatch(downloadEventOperatorInvitationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setEventOperatorInvitationSelectedOrderBy(orderBy));
    dispatch(clearEventOperatorInvitations());
    dispatch(downloadEventOperatorInvitationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ event, recipients }) => {
    await dispatch(addEventOperatorInvitationAsync(event, recipients));
  },
  onViewPressed: (id) => {
    dispatch(setEventOperatorInvitationTappedId(id));
    dispatch(downloadDeleteEventOperatorInvitationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EventOperatorInvitationPage);
