import 'date-fns';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker, KeyboardTimePicker,
} from '@material-ui/pickers';
import { FormControl, FormHelperText, makeStyles } from '@material-ui/core';
import { Lightbulb } from 'mdi-material-ui';
import {
  COLOR_BODY_TEXT, COLOR_DANGEROUS, COLOR_PRIMARY, COLOR_TERTIARY,
  PICKER_DATE_FORMAT, PICKER_DATE_TIME_FORMAT, PICKER_MODE_DATE_TIME, PICKER_MODE_DATE,
  PICKER_MODE_TIME, PICKER_TIME_FORMAT,
} from '../constant';

const SERVER_NULL_DATE_TIME = '0001-01-01T00:00:00';
const SERVER_NULL_TIME = '00:00';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  form: {
    margin: theme.spacing(1),
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_PRIMARY,
      },
    },
    width: '100%',
    minWidth: 250,
  },
  datePicker: {
    margin: 0,
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
    },
  },
  helperContainer: {
    margin: '10px 0px 10px 0px',
  },
  rowHelperContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  goldText: {
    fontWeight: 'bold',
    color: COLOR_TERTIARY,
  },
  blackText: {
    color: COLOR_BODY_TEXT,
    marginLeft: 38,
  },
  greenText: {
    fontStyle: 'italic',
    color: COLOR_DANGEROUS,
    marginLeft: 14,
  },
}));

const isServerNullDateTime = (value) => (moment(value).isSame(SERVER_NULL_DATE_TIME)
  ? null : value);
const isServerNullTime = (value) => (moment(value).isSame(SERVER_NULL_TIME) ? null : value);

const DatePickerField = ({
  disableFuture, editable, error, hidden, useHelperIcon,
  onChangeDate,
  pickerMode,
  format, label, helperText, secondaryHelperText, subHelperText, value, initialValue,
  ...props
}) => {
  const classes = useStyles();
  const [defaultValue, setDefaultValue] = useState(null);

  useEffect(() => {
    if (value && pickerMode !== PICKER_MODE_TIME) {
      setDefaultValue(isServerNullDateTime(value));
    }
    if (value && pickerMode === PICKER_MODE_TIME) {
      setDefaultValue(isServerNullTime(value));
    }
    if (!value && defaultValue) {
      setDefaultValue(null);
    }
  }, [value, pickerMode, defaultValue]);

  const onChange = (dateTime, date) => {
    onChangeDate(date);
    setDefaultValue(isServerNullDateTime(date));
  };

  if (!hidden) {
    let component = '';
    switch (pickerMode) {
      case PICKER_MODE_DATE: {
        component = (
          <KeyboardDatePicker
            disableFuture={disableFuture}
            inputVariant="outlined"
            format={format || PICKER_DATE_FORMAT}
            ampm={false}
            margin="normal"
            label={label}
            value={defaultValue || (initialValue || null)}
            disabled={editable}
            onChange={onChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            className={classes.datePicker}
            size="small"
            InputLabelProps={{
              style: {
                fontSize: '14px',
                '&.MuiOutlinedInput-notchedOutline': { fontSize: '14px' },
              },
            }}
            {...props}
          />
        );
        break;
      }
      case PICKER_MODE_TIME: {
        component = (
          <KeyboardTimePicker
            inputVariant="outlined"
            format={format || PICKER_TIME_FORMAT}
            ampm={false}
            margin="normal"
            label={label}
            value={defaultValue}
            inputValue={defaultValue}
            disabled={editable}
            onChange={onChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            className={classes.datePicker}
            size="small"
            InputLabelProps={{
              style: {
                fontSize: '14px',
                '&.MuiOutlinedInput-notchedOutline': { fontSize: '14px' },
              },
            }}
            {...props}
          />
        );
        break;
      }
      default: {
        component = (
          <KeyboardDateTimePicker
            disableFuture={disableFuture}
            inputVariant="outlined"
            format={format || PICKER_DATE_TIME_FORMAT}
            ampm={false}
            margin="normal"
            label={label}
            value={defaultValue}
            disabled={editable}
            onChange={onChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            className={classes.datePicker}
            size="small"
            InputLabelProps={{
              style: {
                fontSize: '14px',
                '&.MuiOutlinedInput-notchedOutline': { fontSize: '14px' },
              },
            }}
            {...props}
          />
        );
        break;
      }
    }

    return (
      <div className={classes.container}>
        <FormControl className={classes.form} error={error}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {component}
          </MuiPickersUtilsProvider>

          {useHelperIcon ? (
            <div className={classes.helperContainer}>
              <FormHelperText className={classes.greenText}>{helperText}</FormHelperText>
              <div className={classes.rowHelperContainer}>
                <Lightbulb style={{ color: COLOR_TERTIARY }} />
                <FormHelperText className={classes.goldText}>{secondaryHelperText}</FormHelperText>
              </div>
              <FormHelperText className={classes.blackText}>{subHelperText}</FormHelperText>
            </div>
          )
            : (<FormHelperText className={classes.greenText}>{helperText}</FormHelperText>)}
        </FormControl>
      </div>
    );
  }
  return null;
};

export default DatePickerField;

DatePickerField.propTypes = {
  disableFuture: PropTypes.bool,
  editable: PropTypes.bool,
  error: PropTypes.bool,
  hidden: PropTypes.bool,
  useHelperIcon: PropTypes.bool,
  onChangeDate: PropTypes.func,
  pickerMode: PropTypes.oneOf([PICKER_MODE_DATE, PICKER_MODE_DATE_TIME, PICKER_MODE_TIME]),
  format: PropTypes.string,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  secondaryHelperText: PropTypes.string,
  subHelperText: PropTypes.string,
  value: PropTypes.string,
  initialValue: PropTypes.string,
};

DatePickerField.defaultProps = {
  disableFuture: false,
  editable: false,
  error: false,
  hidden: false,
  useHelperIcon: false,
  onChangeDate: () => {},
  pickerMode: PICKER_MODE_DATE_TIME,
  format: null,
  helperText: null,
  secondaryHelperText: null,
  subHelperText: null,
  value: null,
  initialValue: null,
};
