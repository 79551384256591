import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FormInitialValueShape } from '../../type';
import {
  renderReduxFormDateTimePickerField,
  renderReduxFormOutlinedDropdownTextField,
  renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { AccentButton, FunctionalPage, SectionTitle } from '../../component';
import {
  FILTER_TYPE_DROPDOWN,
  PAGE_MODE_VIEW,
  RXFORM_SESSION_REGISTRATION, RXSTATE_SESSION_REGISTRATIONS, RXSTATE_SESSION_REGISTRATION_PAGE,
  status, FILTER_TYPE_DATE_RANGE, PICKER_MODE_DATE_TIME,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  RXFIELD_SESSION_REGISTRATION_SESSION_ID,
  RXFIELD_SESSION_REGISTRATION_USER_ID,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  RXFIELD_SESSION_REGISTRATION_REGISTRATION_CODE,
  RXFIELD_SESSION_REGISTRATION_REGISTRATION_STATUS,
  RXFIELD_SESSION_REGISTRATION_REGISTRATION_STATUS_NOTES,
  RXFIELD_SESSION_REGISTRATION_APPROVED_ON,
  RXFIELD_SESSION_REGISTRATION_REJECTED_ON,
  RXFIELD_SESSION_REGISTRATION_APPROVED_BY,
  RXFIELD_SESSION_REGISTRATION_REJECTED_BY,
  RXFIELD_SESSION_REGISTRATION_CLOCK_IN_TIME,
  RXFIELD_SESSION_REGISTRATION_CLOCK_IN_DRIFT,
  RXFIELD_SESSION_REGISTRATION_CLOCK_IN_LATITUDE,
  RXFIELD_SESSION_REGISTRATION_CLOCK_IN_LONGTITUDE,
  RXFIELD_SESSION_REGISTRATION_CLOCK_IN_LOCATION_STATUS,
  RXFIELD_SESSION_REGISTRATION_USER_FULLNAME,
  RXFIELD_SESSION_REGISTRATION_USER_EMAIL,
  RXFIELD_SESSION_REGISTRATION_USER_PHONE,
  RXFIELD_SESSION_REGISTRATION_SESSION_NAME,
  RXFIELD_SESSION_REGISTRATION_SESSION_SHORT_DESCRIPTION,
  RXFIELD_SESSION_REGISTRATION_SESSION_START_TIME,
  RXFIELD_SESSION_REGISTRATION_SESSION_END_TIME,
  RXFIELD_SESSION_REGISTRATION_SESSION_TIMEZONE,
  RXFIELD_SESSION_REGISTRATION_PAYMENT_ID,
  RXFIELD_SESSION_REGISTRATION_PAYMENT_TRANSACTION_ID,
  RXFIELD_SESSION_REGISTRATION_PAYMENT_PAYMENT_DATE,
  RXFIELD_SESSION_REGISTRATION_PAYMENT_PAYMENT_CODE,
  RXFIELD_SESSION_REGISTRATION_PAYMENT_PAYMENT_CHANNEL,
  RXFIELD_SESSION_REGISTRATION_PAYMENT_PAYMENT_STATUS,
  RXFIELD_SESSION_REGISTRATION_PAYMENT_PAYMENT_STATUS_NOTES,
  RXFIELD_SESSION_REGISTRATION_PAYMENT_LAST_CHANGED_STATUS,
  RXFIELD_SESSION_REGISTRATION_PAYMENT_PRICE,
  RXFIELD_SESSION_REGISTRATION_PAYMENT_TAX,
  RXFIELD_SESSION_REGISTRATION_PAYMENT_SERVICE_FEE,
  RXFIELD_SESSION_REGISTRATION_PAYMENT_DISCOUNT,
  RXFIELD_SESSION_REGISTRATION_PAYMENT_DISCOUNT_NOTES,
  RXFIELD_SESSION_REGISTRATION_PAYMENT_POINT_DISCOUNT,
  RXFIELD_SESSION_REGISTRATION_PAYMENT_POINT_DISCOUNT_ID,
  RXFIELD_SESSION_REGISTRATION_PAYMENT_TOTAL,
  FILTER_TYPE_TEXT,
  REGISTRATION_STATUS_APPROVED,
  REGISTRATION_STATUS_REJECTED,
  REGISTRATION_STATUS_OPTIONS,
  PAYMENT_CHANNEL_OPTIONS,
  PAYMENT_STATUS_OPTIONS,
  CLOCK_IN_LOCATION_STATUS_OPTIONS,
  REGISTRATION_STATUS_WAITING_APPROVAL,
} from '../../constant';
import { toMoment } from '../../helper';

const useStyles = makeStyles(() => ({
  dialogHeader: {
    marginBottom: 20,
  },
  fabContainer: {
    marginRight: 10,
  },
  multipleFabContainer: {
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const renderDialogContent = (
  addingEditing, pageMode, registrationStatus, clockinStatus,
  paymentChannel, paymentStatus, price,
) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_REGISTRATION_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderRegistrationCode}
            label={LocalizedString.sessionRegistrationPage.placeholderRegistrationCode}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_REGISTRATION_STATUS}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderRegistrationStatus}
            label={LocalizedString.sessionRegistrationPage.placeholderRegistrationStatus}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={REGISTRATION_STATUS_OPTIONS}
            value={registrationStatus}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_REGISTRATION_STATUS_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderRegistrationStatusNotes}
            label={LocalizedString.sessionRegistrationPage.placeholderRegistrationStatusNotes}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            multiline
          />
        </Grid>
        {
          registrationStatus === REGISTRATION_STATUS_APPROVED && (
            <Grid>
              <Grid item>
                <Field
                  name={RXFIELD_SESSION_REGISTRATION_APPROVED_ON}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.sessionRegistrationPage.placeholderApprovedOn}
                  label={LocalizedString.sessionRegistrationPage.placeholderApprovedOn}
                  disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_SESSION_REGISTRATION_APPROVED_BY}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.sessionRegistrationPage.placeholderApprovedBy}
                  label={LocalizedString.sessionRegistrationPage.placeholderApprovedBy}
                  disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                />
              </Grid>
            </Grid>
          )
        }
        {
          registrationStatus === REGISTRATION_STATUS_REJECTED && (
            <Grid>
              <Grid item>
                <Field
                  name={RXFIELD_SESSION_REGISTRATION_REJECTED_ON}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.sessionRegistrationPage.placeholderRejectedOn}
                  label={LocalizedString.sessionRegistrationPage.placeholderRejectedOn}
                  disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_SESSION_REGISTRATION_REJECTED_BY}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.sessionRegistrationPage.placeholderRejectedBy}
                  label={LocalizedString.sessionRegistrationPage.placeholderRejectedBy}
                  disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                />
              </Grid>
            </Grid>
          )
        }
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_CLOCK_IN_TIME}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderClockInTime}
            label={LocalizedString.sessionRegistrationPage.placeholderClockInTime}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            pickerMode={PICKER_MODE_DATE_TIME}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_CLOCK_IN_DRIFT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderClockInDrift}
            label={LocalizedString.sessionRegistrationPage.placeholderClockInDrift}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            type="number"
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_CLOCK_IN_LATITUDE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderClockInLatitude}
            label={LocalizedString.sessionRegistrationPage.placeholderClockInLatitude}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_CLOCK_IN_LONGTITUDE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderClockInLongtitude}
            label={LocalizedString.sessionRegistrationPage.placeholderClockInLongtitude}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_CLOCK_IN_LOCATION_STATUS}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderClockInLocationStatus}
            label={LocalizedString.sessionRegistrationPage.placeholderClockInLocationStatus}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={CLOCK_IN_LOCATION_STATUS_OPTIONS}
            value={clockinStatus}
          />
        </Grid>
      </Grid>
    </Grid>
    <SectionTitle title={LocalizedString.sessionRegistrationPage.placeholderUser} />
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_USER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderUserID}
            label={LocalizedString.sessionRegistrationPage.placeholderUserID}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_USER_FULLNAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderUserFullName}
            label={LocalizedString.sessionRegistrationPage.placeholderUserFullName}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_USER_EMAIL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderUserEmail}
            label={LocalizedString.sessionRegistrationPage.placeholderUserEmail}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_USER_PHONE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderUserPhone}
            label={LocalizedString.sessionRegistrationPage.placeholderUserPhone}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            type="number"
          />
        </Grid>
      </Grid>
    </Grid>
    <SectionTitle title={LocalizedString.sessionRegistrationPage.placeholderSession} />
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_SESSION_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderSessionID}
            label={LocalizedString.sessionRegistrationPage.placeholderSessionID}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required={pageMode !== PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_SESSION_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderSessionName}
            label={LocalizedString.sessionRegistrationPage.placeholderSessionName}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_SESSION_SHORT_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderSessionShortDescription}
            label={LocalizedString.sessionRegistrationPage.placeholderSessionShortDescription}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_SESSION_START_TIME}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderSessionStartTime}
            label={LocalizedString.sessionRegistrationPage.placeholderSessionStartTime}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            pickerMode={PICKER_MODE_DATE_TIME}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_SESSION_END_TIME}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderSessionEndTime}
            label={LocalizedString.sessionRegistrationPage.placeholderSessionEndTime}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            pickerMode={PICKER_MODE_DATE_TIME}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_SESSION_TIMEZONE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderSessionTimezone}
            label={LocalizedString.sessionRegistrationPage.placeholderSessionTimezone}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>
    </Grid>
    <SectionTitle title={LocalizedString.sessionRegistrationPage.placeholderPayment} />
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_PAYMENT_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderPaymentID}
            label={LocalizedString.sessionRegistrationPage.placeholderPaymentID}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_PAYMENT_PAYMENT_DATE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderPaymentDate}
            label={LocalizedString.sessionRegistrationPage.placeholderPaymentDate}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_PAYMENT_TRANSACTION_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderTransactionID}
            label={LocalizedString.sessionRegistrationPage.placeholderTransactionID}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_PAYMENT_PAYMENT_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderPaymentCode}
            label={LocalizedString.sessionRegistrationPage.placeholderPaymentCode}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_PAYMENT_PAYMENT_CHANNEL}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderPaymentChannel}
            label={LocalizedString.sessionRegistrationPage.placeholderPaymentChannel}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={PAYMENT_CHANNEL_OPTIONS}
            value={paymentChannel}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_PAYMENT_PAYMENT_STATUS}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderPaymentStatus}
            label={LocalizedString.sessionRegistrationPage.placeholderPaymentStatus}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={PAYMENT_STATUS_OPTIONS}
            value={paymentStatus}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_PAYMENT_PAYMENT_STATUS_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderPaymentStatusNotes}
            label={LocalizedString.sessionRegistrationPage.placeholderPaymentStatusNotes}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_PAYMENT_LAST_CHANGED_STATUS}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderLastChangedStatus}
            label={LocalizedString.sessionRegistrationPage.placeholderLastChangedStatus}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            pickerMode={PICKER_MODE_DATE_TIME}
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_PAYMENT_PRICE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderPrice}
            label={LocalizedString.sessionRegistrationPage.placeholderPrice}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            currencyTextFormat={price !== LocalizedString.sessionRegistrationPage.labelFree}
            onBlur={(e) => e.preventDefault()}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_PAYMENT_TAX}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderTax}
            label={LocalizedString.sessionRegistrationPage.placeholderTax}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            currencyTextFormat
            onBlur={(e) => e.preventDefault()}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_PAYMENT_SERVICE_FEE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderServiceFee}
            label={LocalizedString.sessionRegistrationPage.placeholderServiceFee}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            currencyTextFormat
            onBlur={(e) => e.preventDefault()}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_PAYMENT_DISCOUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderDiscount}
            label={LocalizedString.sessionRegistrationPage.placeholderDiscount}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            currencyTextFormat
            onBlur={(e) => e.preventDefault()}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_PAYMENT_DISCOUNT_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderDiscountNotes}
            label={LocalizedString.sessionRegistrationPage.placeholderDiscountNotes}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_PAYMENT_POINT_DISCOUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderPointDiscount}
            label={LocalizedString.sessionRegistrationPage.placeholderPointDiscount}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            currencyTextFormat
            onBlur={(e) => e.preventDefault()}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_PAYMENT_POINT_DISCOUNT_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderPointDiscountID}
            label={LocalizedString.sessionRegistrationPage.placeholderPointDiscountID}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_REGISTRATION_PAYMENT_TOTAL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.sessionRegistrationPage.placeholderTotal}
            label={LocalizedString.sessionRegistrationPage.placeholderTotal}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            currencyTextFormat
            onBlur={(e) => e.preventDefault()}
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);


const SessionRegistrationPage = ({
  initialValues, onAdvancedFilterPressed, addingEditing, downloading,
  downloadingDeleting, handleSubmit, onAppear, onApplyAdvancedFilterPressed,
  onCancelPressed, onChangePage, onChangePageSize, onConfirmApproveRejectPressed,
  onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed,
  onViewPressed, sessionRegistrations, pageMode, employees, managers,
  sessions, profiles, downloadingProfiles, downloadingSessions,
  onChangeSessionText, onChangeParticipantText, onDownloadPressed,
  approving, rejecting, onApprovePressed, onRejectPressed,
  onChangeApprovedByText, onChangeRejectedByText, downloadingEmployee, downloadingManagers,
}) => {
  const classes = useStyles();

  const renderCustomFabComponent = () => {
    if (
      initialValues.registrationStatus === REGISTRATION_STATUS_WAITING_APPROVAL
    ) {
      return (
        <div className={classes.multipleFabContainer}>
          <div className={classes.fabContainer}>
            <AccentButton
              variant="text"
              disabled={addingEditing || downloadingDeleting || approving}
              loading={rejecting}
              disableElevation
              caption={LocalizedString.sessionRegistrationPage.buttonCaptionReject}
              onClick={onRejectPressed}
            />
          </div>
          <AccentButton
            disabled={addingEditing || downloadingDeleting || rejecting}
            loading={approving}
            disableElevation
            caption={LocalizedString.sessionRegistrationPage.buttonCaptionApprove}
            onClick={onApprovePressed}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <FunctionalPage
      data={RXSTATE_SESSION_REGISTRATIONS}
      uiPage={RXSTATE_SESSION_REGISTRATION_PAGE}
      tableColumns={[
        {
          title: GlobalLocalizedString.common.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.sessionRegistrationPage.labelParticipant, field: 'user.fullName', sorting: !downloading },
        { title: LocalizedString.sessionRegistrationPage.labelSession, field: 'session.name', sorting: !downloading },
        { title: LocalizedString.sessionRegistrationPage.labelRegistrationCode, field: 'registrationCode', sorting: !downloading },
        { title: LocalizedString.sessionRegistrationPage.labelRegistrationStatus, field: 'registrationStatus', sorting: !downloading },
        {
          title: LocalizedString.sessionRegistrationPage.labelApprovedOn,
          field: 'approvedRejectedOn',
          sorting: !downloading,
          render: ({ approvedRejectedOn, registrationStatus }) => (
            registrationStatus === 'Approved' && approvedRejectedOn
              ? toMoment(approvedRejectedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        {
          title: LocalizedString.sessionRegistrationPage.labelRejectedOn,
          field: 'approvedRejectedOn',
          sorting: !downloading,
          render: ({ approvedRejectedOn, registrationStatus }) => (
            registrationStatus !== 'Approved' && approvedRejectedOn
              ? toMoment(approvedRejectedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        { title: LocalizedString.sessionRegistrationPage.labelPaymentStatus, field: 'payment.paymentStatus', sorting: !downloading },
        {
          title: LocalizedString.sessionRegistrationPage.labelCreatedDate,
          field: 'createdDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
      ]}
      filterColumns={[
        {
          title: LocalizedString.sessionRegistrationPage.labelSession,
          field: 'session',
          type: FILTER_TYPE_DROPDOWN,
          data: sessions,
          loading: downloadingSessions,
          onChangeFilterText: onChangeSessionText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sessionRegistrationPage.labelParticipant,
          field: 'participant',
          type: FILTER_TYPE_DROPDOWN,
          data: profiles,
          loading: downloadingProfiles,
          onChangeFilterText: onChangeParticipantText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sessionRegistrationPage.labelCreatedDate,
          field: 'createdDate',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.sessionRegistrationPage.labelRegisteredOn,
          field: 'registeredOn',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.sessionRegistrationPage.labelApprovedOn,
          field: 'approvedRejectedOn',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.sessionRegistrationPage.placeholderClockInTime,
          field: 'clockInTime',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
          useTimeZone: true,
        },
        {
          title: LocalizedString.sessionRegistrationPage.placeholderApprovedBy,
          field: 'approvedBy',
          type: FILTER_TYPE_DROPDOWN,
          data: employees,
          loading: downloadingEmployee,
          onChangeFilterText: onChangeApprovedByText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sessionRegistrationPage.placeholderRejectedBy,
          field: 'rejectedBy',
          type: FILTER_TYPE_DROPDOWN,
          data: managers,
          loading: downloadingManagers,
          onChangeFilterText: onChangeRejectedByText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sessionRegistrationPage.labelRegistrationCode,
          field: 'registrationCode',
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.sessionRegistrationPage.labelRegistrationStatus,
          field: 'registrationStatus',
          type: FILTER_TYPE_DROPDOWN,
          data: REGISTRATION_STATUS_OPTIONS,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sessionRegistrationPage.placeholderPaymentChannel,
          field: 'paymentChannel',
          type: FILTER_TYPE_DROPDOWN,
          data: PAYMENT_CHANNEL_OPTIONS,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sessionRegistrationPage.labelPaymentStatus,
          field: 'paymentStatus',
          type: FILTER_TYPE_DROPDOWN,
          data: PAYMENT_STATUS_OPTIONS,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sessionRegistrationPage.placeholderClockInLocationStatus,
          field: 'clockInLocationStatus',
          type: FILTER_TYPE_DROPDOWN,
          data: CLOCK_IN_LOCATION_STATUS_OPTIONS,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.sessionRegistrationPage.labelStatus,
          field: 'status',
          type: FILTER_TYPE_DROPDOWN,
          data: status,
          useDropdownValue: true,
        },
      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmApproveRejectPressed}
      onDownloadPressed={onDownloadPressed}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={(id) => onViewPressed(id, sessionRegistrations)}
      title={LocalizedString.sessionRegistrationPage.title}
      renderCustomFabComponent={() => renderCustomFabComponent()}
      confirmationReasonRequired
      disableEdit
      disableDelete
      disableCreate
      useFullWidth
      savePermissionName="EVENT_GET_SESSION_REGISTRATION"
    >
      {renderDialogContent(
        addingEditing, pageMode, initialValues.registrationStatus, initialValues.clockinStatus,
        initialValues.paymentChannel, initialValues.paymentStatus, initialValues.price,
      )}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_SESSION_REGISTRATION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SessionRegistrationPage);

SessionRegistrationPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  sessions: PropTypes.arrayOf(PropTypes.object).isRequired,
  sessionRegistrations: PropTypes.objectOf(PropTypes.object).isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  employees: PropTypes.arrayOf(PropTypes.object).isRequired,
  managers: PropTypes.arrayOf(PropTypes.object).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingSessions: PropTypes.bool.isRequired,
  downloadingProfiles: PropTypes.bool.isRequired,
  downloadingEmployee: PropTypes.bool.isRequired,
  downloadingManagers: PropTypes.bool.isRequired,
  approving: PropTypes.bool.isRequired,
  rejecting: PropTypes.bool.isRequired,
  pageMode: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onApprovePressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeSessionText: PropTypes.func.isRequired,
  onChangeParticipantText: PropTypes.func.isRequired,
  onChangeApprovedByText: PropTypes.func.isRequired,
  onChangeRejectedByText: PropTypes.func.isRequired,
  onConfirmApproveRejectPressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onRejectPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
