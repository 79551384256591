import { downloadEventRegistration } from '../../../helper';
import {
  addEventRegistration, downloadingDeletingEventRegistration,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingEventRegistration(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiEventRegistration;

    const eventRegistration = await downloadEventRegistration(tappedId, token);
    dispatch(addEventRegistration(eventRegistration));
  } finally {
    dispatch(downloadingDeletingEventRegistration(false));
  }
};
