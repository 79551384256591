import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import {
  Button, Card, CardMedia, CircularProgress, Container, IconButton, InputAdornment, makeStyles,
  Tooltip, Typography,
} from '@material-ui/core';
import { Person, Refresh } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import { AlertBox, AccentButton } from '../../component';
import {
  COLOR_ICON, COLOR_PRIMARY, COLOR_TEXT_ON_PRIMARY, RXFIELD_USERNAME, RXFIELD_PASSWORD,
  RXFORM_LOGIN_SCREEN, RXFIELD_CAPTCHA_TEXT, OAUTH_PROVIDER_PARAM,
  OAUTH_KEY_CLOAK_REDIRECT_URI, OAUTH_MICROSOFT_REDIRECT_URI,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../redux-form-rendererer';
import { rxformValidateLogin } from '../../validation';

const logo = require('../../asset/logo.png');
const googleIcon = require('../../asset/google-icon.png');
const microsoftIcon = require('../../asset/microsoft-icon.png');
const keyCloakIcon = require('../../asset/key-cloak-icon.png');
const background = require('../../asset/login/background.png');

const useStyles = makeStyles(() => ({
  container: {
    background: `url(${background})`,
    backgroundSize: 'cover',
    alignItems: 'center',
    display: 'flex',
    width: '100vw',
    height: '100vh',
  },
  innerContainer: {
    maxWidth: 500,
    minWidth: 275,
  },
  card: {
    justifyContent: 'center',
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 350,
    maxHeight: 600,
    borderRadius: 10,
    boxShadow: '0px 1px 3px rgba(63, 63, 68, 0.15), 0px 0px 1px rgba(63, 63, 68, 0.05)',
    padding: '40px 0px 40px 0px',
  },
  topField: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
  logo: {
    width: 155,
    marginBottom: 15,
  },
  oAuthImage: {
    width: 30,
    height: 30,
    resizeMode: 'center',
    marginRight: 2,
  },
  form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
    minWidth: 250,
    margin: '15px 0px 10px 0px',
    borderRadius: 50,
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${COLOR_PRIMARY}`,
    },
  },
  oAuthButtonContainer: {
    minWidth: 250,
    borderRadius: 50,
    padding: 4,
    marginTop: 16,
  },
  fieldContainer: {
    minWidth: 250,
    display: 'flex',
    flexDirection: 'column',
  },
  captchaContainer: {
    margin: '0px 8px',
    display: 'flex',
    flexDirection: 'column',
  },
  captchaImageContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  activityIndicator: {
    margin: '11px 0px 11px 0px',
    justifyContent: 'center',
    display: 'flex',
  },
}));

const onOAuthLoginPressed = (url) => {
  window.open(url, '_self');
};

const renderOAuthLoginButton = (item, loggingIn, classes) => {
  const {
    loading, caption, disabled, hidden, onPress, icon,
  } = item;
  if (loading) {
    return (
      <div className={classes.activityIndicator} key={item.caption}>
        <CircularProgress color="inherit" />
      </div>
    );
  }
  if (!hidden) {
    return (
      <Button
        variant="outlined"
        className={classes.oAuthButtonContainer}
        onClick={onPress}
        disabled={loggingIn || disabled}
        key={item.caption}
      >
        <CardMedia
          component="img"
          className={classes.oAuthImage}
          image={icon}
        />
        {caption}
      </Button>
    );
  }
  return null;
};

const LoginScreen = ({
  downloadingCaptcha, downloadingMyConfigItems, loggingIn, loggingInWithGoogle,
  loggingInWithKeyCloak, loggingInWithMicrosoft, oAuthGoogleEnabled, oAuthKeyCloakEnabled,
  oAuthMicrosoftEnabled, useCaptcha,
  createCaptcha, handleSubmit, onAppear, onAppearWithAuthCode, onLoginPressed, onRefreshCaptcha,
  myConfigItems,
  captchaImage, oAuthGoogleUrl, oAuthKeyCloakUrl, oAuthMicrosoftUrl,
}) => {
  const [mount, setMount] = useState(false);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const googleOAuthCode = searchParams.get('code');
  const oAuthProvider = searchParams.get(OAUTH_PROVIDER_PARAM);

  const microsoftSearchParams = new URLSearchParams(window.location.href.replace(OAUTH_MICROSOFT_REDIRECT_URI, ''));
  const microsoftOAuthCode = microsoftSearchParams.get('code');

  const keyCloakSearchParams = new URLSearchParams(window.location.href.replace(OAUTH_KEY_CLOAK_REDIRECT_URI, ''));
  const keyCloakOAuthCode = keyCloakSearchParams.get('code');

  const classes = useStyles();

  useEffect(() => {
    if (!mount) {
      setMount(true);
      onAppear();
      onAppearWithAuthCode(googleOAuthCode, oAuthProvider, microsoftOAuthCode, keyCloakOAuthCode);
    }
  }, [onAppear, onAppearWithAuthCode, mount, googleOAuthCode, oAuthProvider, microsoftOAuthCode,
    keyCloakOAuthCode]);

  useEffect(() => {
    createCaptcha(useCaptcha);
  }, [createCaptcha, useCaptcha]);

  useEffect(() => {
    if (isEmpty(myConfigItems) && !downloadingMyConfigItems) {
      onAppear();
    }
  }, [myConfigItems, downloadingMyConfigItems, onAppear]);

  const oAuthButtons = [
    {
      loading: loggingInWithGoogle,
      caption: LocalizedString.loginScreen.buttonCaptionLoginWithGoogle,
      disabled: loggingInWithMicrosoft || loggingInWithKeyCloak,
      hidden: !oAuthGoogleEnabled,
      onPress: () => onOAuthLoginPressed(oAuthGoogleUrl),
      icon: googleIcon,
    },
    {
      loading: loggingInWithMicrosoft,
      caption: LocalizedString.loginScreen.buttonCaptionLoginWithMicrosoft,
      disabled: loggingInWithGoogle || loggingInWithKeyCloak,
      hidden: !oAuthMicrosoftEnabled,
      onPress: () => onOAuthLoginPressed(oAuthMicrosoftUrl),
      icon: microsoftIcon,
    },
    {
      loading: loggingInWithKeyCloak,
      caption: LocalizedString.loginScreen.buttonCaptionLoginWithKeyCloak,
      disabled: loggingInWithGoogle || loggingInWithMicrosoft,
      hidden: !oAuthKeyCloakEnabled,
      onPress: () => onOAuthLoginPressed(oAuthKeyCloakUrl),
      icon: keyCloakIcon,
    },
  ];

  return (
    <div className={classes.container}>
      <Container className={classes.innerContainer} fixed>
        <Card className={classes.card} raised>
          <span className={classes.topField}>
            <CardMedia
              component="img"
              className={classes.logo}
              image={logo}
            />
            <Typography variant="body1">
              {LocalizedString.loginScreen.labelAdminDashboard}
            </Typography>
          </span>

          <form onSubmit={handleSubmit(onLoginPressed)} className={classes.form}>
            <div className={classes.fieldContainer}>
              <Field
                name={RXFIELD_USERNAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.loginScreen.placeholderUsername}
                label={LocalizedString.loginScreen.labelUsername}
                disabled={loggingIn || loggingInWithGoogle || loggingInWithMicrosoft
                  || loggingInWithKeyCloak}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton disabled edge="end">
                        <Person style={{ color: COLOR_ICON }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Field
                name={RXFIELD_PASSWORD}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.loginScreen.labelPassword}
                label={LocalizedString.loginScreen.labelPassword}
                disabled={loggingIn || loggingInWithGoogle || loggingInWithMicrosoft
                  || loggingInWithKeyCloak}
                secureTextEntry
                required
              />
            </div>
            {useCaptcha && (downloadingCaptcha ? (
              <div>
                <div className={classes.captchaContainer}>
                  <Typography>{LocalizedString.loginScreen.labelCaptcha}</Typography>
                  <div className={classes.form}>
                    <CircularProgress color="inherit" size={20} />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className={classes.captchaContainer}>
                  <Typography>{LocalizedString.loginScreen.labelCaptcha}</Typography>
                  <div className={classes.captchaImageContainer}>
                    <img alt="avatar" src={captchaImage} width={200} height={60} />
                    <Tooltip title={LocalizedString.loginScreen.buttonCaptionRefreshCaptcha}>
                      <IconButton onClick={onRefreshCaptcha} aria-label="text">
                        <Refresh />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>

                <Field
                  name={RXFIELD_CAPTCHA_TEXT}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.loginScreen.placeholderCaptcha}
                  label={LocalizedString.loginScreen.placeholderCaptcha}
                  disabled={loggingIn || loggingInWithGoogle || loggingInWithMicrosoft
                    || loggingInWithKeyCloak}
                  required
                />
              </div>
            )
            )}

            <AccentButton
              type="submit"
              className={classes.button}
              loading={loggingIn}
              disableElevation
              disabled={downloadingCaptcha || loggingInWithGoogle || loggingInWithMicrosoft
                || loggingInWithKeyCloak}
              caption={LocalizedString.loginScreen.buttonCaptionLogin}
            />

            {oAuthButtons.map((button) => renderOAuthLoginButton(button, loggingIn, classes))}
            <AlertBox />
          </form>
        </Card>
      </Container>
    </div>
  );
};

export default reduxForm({
  form: RXFORM_LOGIN_SCREEN,
  validate: rxformValidateLogin,
})(LoginScreen);

LoginScreen.propTypes = {
  downloadingCaptcha: PropTypes.bool.isRequired,
  downloadingMyConfigItems: PropTypes.bool.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  loggingInWithGoogle: PropTypes.bool.isRequired,
  loggingInWithKeyCloak: PropTypes.bool.isRequired,
  loggingInWithMicrosoft: PropTypes.bool.isRequired,
  oAuthGoogleEnabled: PropTypes.bool.isRequired,
  oAuthKeyCloakEnabled: PropTypes.bool.isRequired,
  oAuthMicrosoftEnabled: PropTypes.bool.isRequired,
  useCaptcha: PropTypes.bool.isRequired,
  createCaptcha: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onAppearWithAuthCode: PropTypes.func.isRequired,
  onLoginPressed: PropTypes.func.isRequired,
  onRefreshCaptcha: PropTypes.func.isRequired,
  myConfigItems: PropTypes.object.isRequired,
  captchaImage: PropTypes.string,
  oAuthGoogleUrl: PropTypes.string.isRequired,
  oAuthKeyCloakUrl: PropTypes.string.isRequired,
  oAuthMicrosoftUrl: PropTypes.string.isRequired,
};

LoginScreen.defaultProps = {
  captchaImage: null,
};
