import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';


export const DOWNLOADING_DAILY_CHECKINS = 'DOWNLOADING_DAILY_CHECKINS';
export const downloadingDailyCheckins = makeActionCreator(DOWNLOADING_DAILY_CHECKINS, 'status');

export const SET_DAILY_CHECKINS = 'SET_DAILY_CHECKINS';
export const setDailyCheckins = makeActionCreator(SET_DAILY_CHECKINS, 'data');

export const ADD_DAILY_CHECKIN = 'ADD_DAILY_CHECKIN';
export const addDailyCheckin = makeActionCreator(ADD_DAILY_CHECKIN, 'data');

export const CLEAR_DAILY_CHECKINS = 'CLEAR_DAILY_CHECKINS';
export const clearDailyCheckins = makeActionCreator(
  CLEAR_DAILY_CHECKINS,
);

export const DOWNLOADING_DELETING_DAILY_CHECKIN = 'DOWNLOADING_DELETING_DAILY_CHECKIN';
export const downloadingDeletingDailyCheckin = makeActionCreator(DOWNLOADING_DELETING_DAILY_CHECKIN, 'status');

export const SET_DAILY_CHECKIN_SELECTED_PAGE_SIZE = 'SET_DAILY_CHECKIN_SELECTED_PAGE_SIZE';
export const setDailyCheckinSelectedPageSize = makeActionCreator(SET_DAILY_CHECKIN_SELECTED_PAGE_SIZE, 'size');

export const SET_DAILY_CHECKIN_SELECTED_ORDER_BY = 'SET_DAILY_CHECKIN_SELECTED_ORDER_BY';
export const setDailyCheckinSelectedOrderBy = makeActionCreator(SET_DAILY_CHECKIN_SELECTED_ORDER_BY, 'order');

export const SET_DAILY_CHECKIN_TAPPED_ID = 'SET_DAILY_CHECKIN_TAPPED_ID';
export const setDailyCheckinTappedId = makeActionCreator(SET_DAILY_CHECKIN_TAPPED_ID, 'id');

export const SET_DAILY_CHECKIN_SEARCH_TEXT = 'SET_DAILY_CHECKIN_SEARCH_TEXT';
export const setDailyCheckinSearchText = makeActionCreator(SET_DAILY_CHECKIN_SEARCH_TEXT, 'text');

export const SET_DAILY_CHECKIN_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_DAILY_CHECKIN_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setDailyCheckinAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_DAILY_CHECKIN_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const DOWNLOADING_REGISTRATION_REFERRALS = 'DOWNLOADING_REGISTRATION_REFERRALS';
export const downloadingRegistrationReferrals = makeActionCreator(DOWNLOADING_REGISTRATION_REFERRALS, 'status');

export const SAVING_REGISTRATION_REFERRALS = 'SAVING_REGISTRATION_REFERRALS';
export const savingRegistrationReferrals = makeActionCreator(SAVING_REGISTRATION_REFERRALS, 'status');

export const SET_REGISTRATION_REFERRALS = 'SET_REGISTRATION_REFERRALS';
export const setRegistrationReferrals = makeActionCreator(SET_REGISTRATION_REFERRALS, 'data');

export const ADD_REGISTRATION_REFERRAL = 'ADD_REGISTRATION_REFERRAL';
export const addRegistrationReferral = makeActionCreator(ADD_REGISTRATION_REFERRAL, 'data');

export const CLEAR_REGISTRATION_REFERRALS = 'CLEAR_REGISTRATION_REFERRALS';
export const clearRegistrationReferrals = makeActionCreator(
  CLEAR_REGISTRATION_REFERRALS,
);

export const DOWNLOADING_DELETING_REGISTRATION_REFERRAL = 'DOWNLOADING_DELETING_REGISTRATION_REFERRAL';
export const downloadingDeletingRegistrationReferral = makeActionCreator(DOWNLOADING_DELETING_REGISTRATION_REFERRAL, 'status');

export const SET_REGISTRATION_REFERRAL_SELECTED_PAGE_SIZE = 'SET_REGISTRATION_REFERRAL_SELECTED_PAGE_SIZE';
export const setRegistrationReferralSelectedPageSize = makeActionCreator(SET_REGISTRATION_REFERRAL_SELECTED_PAGE_SIZE, 'size');

export const SET_REGISTRATION_REFERRAL_SELECTED_ORDER_BY = 'SET_REGISTRATION_REFERRAL_SELECTED_ORDER_BY';
export const setRegistrationReferralSelectedOrderBy = makeActionCreator(SET_REGISTRATION_REFERRAL_SELECTED_ORDER_BY, 'order');

export const SET_REGISTRATION_REFERRAL_TAPPED_ID = 'SET_REGISTRATION_REFERRAL_TAPPED_ID';
export const setRegistrationReferralTappedId = makeActionCreator(SET_REGISTRATION_REFERRAL_TAPPED_ID, 'id');

export const SET_REGISTRATION_REFERRAL_SEARCH_TEXT = 'SET_REGISTRATION_REFERRAL_SEARCH_TEXT';
export const setRegistrationReferralSearchText = makeActionCreator(SET_REGISTRATION_REFERRAL_SEARCH_TEXT, 'text');

export const SET_REGISTRATION_REFERRAL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_REGISTRATION_REFERRAL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setRegistrationReferralAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_REGISTRATION_REFERRAL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');
