import { downloadForms, transformSearchText } from '../../../helper';
import { downloadingForms, setForms } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, forms, uiForm } = getState();
  const { token } = authentication;
  const { meta } = forms;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiForm;

  try {
    dispatch(downloadingForms(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadForms(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      searchBarText ? transformSearchText(searchBarText) : '',
      filterString,
      token,
    );

    if (list) {
      dispatch(setForms(list));
    }
  } finally {
    dispatch(downloadingForms(false));
  }
};
