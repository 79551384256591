import { ORDER_SORT_ASCENDING, ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_BASE_URL_SESSION = '/event/api/v1/Session';
export const REST_URL_SESSION_OPERATOR_MAPPINGS = '/event/api/v1/SessionOperator/Mapping?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|session.name|user.fullName';
export const REST_URL_VIEW_DELETE_SESSION_OPERATOR_MAPPING = '/event/api/v1/SessionOperator/Mapping/{id}';
export const REST_URL_ADD_EDIT_SESSION_OPERATOR_MAPPING = '/event/api/v1/SessionOperator/Mapping';
export const REST_URL_ENABLE_DISABLE_SESSION_OPERATOR_MAPPING = '/event/api/v1/SessionOperator/Mapping/Status';
export const REST_URL_SESSION_OPERATOR_INVITATIONS = '/event/api/v1/SessionOperator/SessionInvitation?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|email|session.name|user.fullName';
export const REST_URL_VIEW_DELETE_SESSION_OPERATOR_INVITATION = '/event/api/v1/SessionOperator/SessionInvitation/{id}';
export const REST_URL_ADD_SESSION_OPERATOR_INVITATION = '/event/api/v1/SessionOperator/SessionInvitation';
export const REST_URL_RESEND_SESSION_OPERATOR_INVITATION = '/event/api/v1/SessionOperator/ResendSessionInvitation';
export const REST_URL_SESSION_OPERATOR_SESSIONS = '/event/api/v1/SessionOperator/Session?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|name';
export const REST_URL_VIEW_SESSION_OPERATOR_SESSION = '/event/api/v1/SessionOperator/Session/{id}';
export const REST_URL_SESSION_OPERATOR_REGISTRATIONS = '/event/api/v1/SessionOperator/SessionRegistration?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchStringIncludedFields=id|user.email|registrationCode';
export const REST_URL_VIEW_SESSION_OPERATOR_REGISTRATION = '/event/api/v1/SessionOperator/SessionRegistration/{id}';
export const REST_URL_APPROVE_REJECT_SESSION_OPERATOR_REGISTRATION = '/event/api/v1/SessionOperator/SessionRegistrationApproval';

export const ROUTE_NAME_SESSION_OPERATOR = '/session-operator';
export const ROUTE_NAME_SESSION_OPERATOR_MAPPING = '/session-operator-mapping';
export const ROUTE_NAME_SESSION_OPERATOR_INVITATION = '/session-operator-invitation';
export const ROUTE_NAME_SESSION_OPERATOR_SESSION = '/session-operator-session';
export const ROUTE_NAME_SESSION_OPERATOR_REGISTRATION = '/session-operator-registration';

export const RXFORM_SESSION_OPERATOR_MAPPING = 'sessionOperatorMappingPage';
export const RXFORM_SESSION_OPERATOR_INVITATION = 'sessionOperatorInvitationPage';
export const RXFORM_SESSION_OPERATOR_SESSION = 'sessionOperatorSessionPage';
export const RXFORM_SESSION_OPERATOR_REGISTRATION = 'sessionOperatorRegistrationPage';

export const RXFIELD_SESSION_OPERATOR_MAPPING_USER = 'userId';
export const RXFIELD_SESSION_OPERATOR_MAPPING_USER_FULL_NAME = 'user.fullName';
export const RXFIELD_SESSION_OPERATOR_MAPPING_USER_ID = 'user.id';
export const RXFIELD_SESSION_OPERATOR_MAPPING_USER_EMAIL = 'user.email';
export const RXFIELD_SESSION_OPERATOR_MAPPING_SESSION = 'sessionId';
export const RXFIELD_SESSION_OPERATOR_MAPPING_SESSION_ID = 'session.id';
export const RXFIELD_SESSION_OPERATOR_MAPPING_SESSION_NAME = 'session.name';
export const RXFIELD_SESSION_OPERATOR_MAPPING_VALID_UNTIL = 'validUntil';
export const RXFIELD_SESSION_OPERATOR_INVITATION_SESSION = 'session';
export const RXFIELD_SESSION_OPERATOR_INVITATION_RECIPIENTS = 'recipients';
export const RXFIELD_SESSION_OPERATOR_INVITATION_EMAIL = 'email';
export const RXFIELD_SESSION_OPERATOR_INVITATION_END_TIME = 'endTime';
export const RXFIELD_SESSION_OPERATOR_INVITATION_FULL_NAME = 'fullName';
export const RXFIELD_SESSION_OPERATOR_INVITATION_SESSION_ID = 'sessionId';
export const RXFIELD_SESSION_OPERATOR_INVITATION_INVITATION_STATUS = 'invitationStatus';
export const RXFIELD_SESSION_OPERATOR_INVITATION_LOCATION = 'location';
export const RXFIELD_SESSION_OPERATOR_INVITATION_NAME = 'name';
export const RXFIELD_SESSION_OPERATOR_INVITATION_PHONE = 'phone';
export const RXFIELD_SESSION_OPERATOR_INVITATION_REGISTERED_ON = 'registeredOn';
export const RXFIELD_SESSION_OPERATOR_INVITATION_RESENT_COUNT = 'resentCount';
export const RXFIELD_SESSION_OPERATOR_INVITATION_SHORT_DESCRIPTION = 'shortDescription';
export const RXFIELD_SESSION_OPERATOR_INVITATION_START_TIME = 'startTime';
export const RXFIELD_SESSION_OPERATOR_INVITATION_USER_ID = 'userId';
export const RXFIELD_SESSION_OPERATOR_INVITATION_TIME_ZONE = 'timeZone';
export const RXFIELD_SESSION_OPERATOR_SESSION_NAME = 'name';
export const RXFIELD_SESSION_OPERATOR_SESSION_START_TIME = 'startTime';
export const RXFIELD_SESSION_OPERATOR_SESSION_END_TIME = 'endTime';
export const RXFIELD_SESSION_OPERATOR_SESSION_EARLIEST_CLOCK_IN_BEFORE_MINUTES = 'earliestClockInBeforeMinutes';
export const RXFIELD_SESSION_OPERATOR_SESSION_MAX_CLOCK_IN_MINUTES = 'maxClockInMinutes';
export const RXFIELD_SESSION_OPERATOR_SESSION_IS_FEATURED = 'isFeatured';
export const RXFIELD_SESSION_OPERATOR_SESSION_FEATURED_ORDER = 'featuredOrder';
export const RXFIELD_SESSION_OPERATOR_SESSION_VISIBILITY_STATUS = 'visibilityStatus';
export const RXFIELD_SESSION_OPERATOR_SESSION_REGISTRATION_REWARD_TYPE = 'registrationRewardType';
export const RXFIELD_SESSION_OPERATOR_SESSION_REGISTRATION_REWARD_POINT = 'registrationRewardPoint';
export const RXFIELD_SESSION_OPERATOR_SESSION_REGISTRATION_REWARD_VOUCHERS = 'registrationRewardVouchers';
export const RXFIELD_SESSION_OPERATOR_SESSION_EVENT_NAME = 'event.name';
export const RXFIELD_SESSION_OPERATOR_SESSION_ROOM_NAME = 'room.name';
export const RXFIELD_SESSION_OPERATOR_SESSION_CATEGORY = 'category.name';
export const RXFIELD_SESSION_OPERATOR_SESSION_DESCRIPTION = 'description';
export const RXFIELD_SESSION_OPERATOR_SESSION_SHORT_DESCRIPTION = 'shortDescription';
export const RXFIELD_SESSION_OPERATOR_SESSION_CLOCK_INSIDE_AREA = 'clockInMustBeInsideArea';
export const RXFIELD_SESSION_OPERATOR_SESSION_WHO_CAN_REGISTER = 'whoCanRegister';
export const RXFIELD_SESSION_OPERATOR_SESSION_NEED_APPROVAL = 'needApproval';
export const RXFIELD_SESSION_OPERATOR_SESSION_QUOTA = 'quota';
export const RXFIELD_SESSION_OPERATOR_SESSION_NORMAL_PRICE = 'normalPrice';
export const RXFIELD_SESSION_OPERATOR_SESSION_PRICE = 'price';
export const RXFIELD_SESSION_OPERATOR_SESSION_CAN_PAY_USING_POINT = 'canPayUsingPoint';
export const RXFIELD_SESSION_OPERATOR_SESSION_STATUS = 'status';
export const RXFIELD_SESSION_OPERATOR_SESSION_MEDIA = 'media';
export const RXFIELD_SESSION_OPERATOR_SESSION_SPEAKERS = 'speakers';
export const RXFIELD_SESSION_OPERATOR_SESSION_TYPE = 'type';
export const RXFIELD_SESSION_OPERATOR_SESSION_SURVEY_FORM_ID = 'surveyFormId';
export const RXFIELD_SESSION_OPERATOR_SESSION_ADDITTIONAL_ITEMS = 'additionalItems';
export const RXFIELD_SESSION_OPERATOR_SESSION_ENABLE_COMMENT = 'enableComment';
export const RXFIELD_SESSION_OPERATOR_SESSION_COMMENT_ACCESS = 'commentAccess';
export const RXFIELD_SESSION_OPERATOR_SESSION_DISCUSSION_ID = 'discussionId';
export const RXFIELD_SESSION_OPERATOR_SESSION_CLOCK_IN_QR_CODE = 'clockInQrCode';
export const RXFIELD_SESSION_OPERATOR_SESSION_BANNER = 'banner';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_REGISTRATION_CODE = 'registrationCode';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_REGISTRATION_STATUS = 'registrationStatus';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_REGISTRATION_STATUS_NOTES = 'registrationStatusNotes';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_APPROVED_ON = 'approvedRejectedOn';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_REJECTED_ON = 'approvedRejectedOn';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_APPROVED_BY = 'approvedRejectedBy';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_REJECTED_BY = 'approvedRejectedBy';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_CLOCK_IN_TIME = 'clockInTime';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_CLOCK_IN_DRIFT = 'clockInDriftTimeMinutes';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_CLOCK_IN_LATITUDE = 'clockInLatitude';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_CLOCK_IN_LONGITUDE = 'clockInLongitude';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_CLOCK_IN_LOCATION_STATUS = 'clockInLocationStatus';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_USER_ID = 'user.id';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_USER_FULLNAME = 'user.fullName';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_USER_EMAIL = 'user.email';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_USER_PHONE = 'user.phoneNumber';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_SESSION_ID = 'session.id';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_SESSION_NAME = 'session.name';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_SESSION_SHORT_DESCRIPTION = 'session.shortDescription';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_SESSION_START_TIME = 'sessionStartTime';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_SESSION_END_TIME = 'sessionEndTime';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_SESSION_TIMEZONE = 'sessionTimeZone';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_ID = 'payment.id';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_PRICE = 'price';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_DATE = 'payment.createdDate';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_TAX = 'tax';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_TRANSACTION_ID = 'payment.transactionId';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_SERVICE_FEE = 'serviceFee';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_CODE = 'payment.paymentCode';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_DISCOUNT = 'discount';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_CHANNEL = 'payment.paymentChannel';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_DISCOUNT_NOTES = 'discountNotes';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_STATUS = 'payment.paymentStatus';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_POINT_DISCOUNT = 'pointDiscount';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_PAYMENT_STATUS_NOTES = 'payment.paymentStatusNotes';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_POINT_DISCOUNT_ID = 'pointDiscountId';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_LAST_CHANGED_STATUS = 'payment.lastChangedStatus';
export const RXFIELD_SESSION_OPERATOR_REGISTRATION_PAYMENT_TOTAL = 'total';

export const RXSTATE_SESSION_OPERATOR_MAPPINGS = 'sessionOperatorMappings';
export const RXSTATE_SESSION_OPERATOR_MAPPING_PAGE = 'uiSessionOperatorMapping';
export const RXSTATE_SESSION_OPERATOR_INVITATIONS = 'sessionOperatorInvitations';
export const RXSTATE_SESSION_OPERATOR_INVITATION_PAGE = 'uiSessionOperatorInvitation';
export const RXSTATE_SESSION_OPERATOR_SESSIONS = 'sessionOperatorSessions';
export const RXSTATE_SESSION_OPERATOR_SESSION_PAGE = 'uiSessionOperatorSession';
export const RXSTATE_SESSION_OPERATOR_REGISTRATIONS = 'sessionOperatorRegistrations';
export const RXSTATE_SESSION_OPERATOR_REGISTRATION_PAGE = 'uiSessionOperatorRegistration';

export const INITIAL_ORDER_BY_SESSION_OPERATOR_MAPPINGS = `validUntil ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_SESSION_OPERATOR_INVITATIONS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_SESSION_OPERATOR_SESSIONS = `startTime ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_SESSION_OPERATOR_REGISTRATIONS = `createdDate ${ORDER_SORT_ASCENDING}`;

export const MENUID_SESSION_OPERATOR = 'MENUID_SESSION_OPERATOR';
export const MENUID_SESSION_OPERATOR_MAPPING = 'MENUID_SESSION_OPERATOR_MAPPING';
export const MENUID_SESSION_OPERATOR_INVITATION = 'MENUID_SESSION_OPERATOR_INVITATION';
export const MENUID_SESSION_OPERATOR_SESSION = 'MENUID_SESSION_OPERATOR_SESSION';
export const MENUID_SESSION_OPERATOR_REGISTRATION = 'MENUID_SESSION_OPERATOR_REGISTRATION';

export const SESSION_OPERATOR_INVITATION_STATUS = [
  { label: 'Registered', value: 'Registered' },
  { label: 'Pending', value: 'Pending' },
];

export const SESSION_OPERATOR_SESSION_REWARD_TYPE_VOUCHER = 'Voucher';
export const SESSION_OPERATOR_SESSION_REWARD_TYPE_POINT = 'Point';
export const SESSION_OPERATOR_SESSION_REWARD_TYPE_BOTH = 'Both';

export const SESSION_OPERATOR_SESSION_TYPE_REGULAR = 'Regular';
export const SESSION_OPERATOR_SESSION_TYPE_FREE_TIME = 'FreeTime';

export const REGISTRATION_STATUS_APPROVED = 'Approved';
export const REGISTRATION_STATUS_REJECTED = 'Rejected';
export const REGISTRATION_STATUS_WAITING_APPROVAL = 'WaitingApproval';
